/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';
import { TabOption } from '../../../editor/Viewport/Sidebar/TabOption';

export const PopoverSettings = () => {
  const [activeTab, setActiveTab] = useState("Style Manager");


  return (
    <div className='flex flex-col' style={{display:"flex", height:"100%"}}>
        <div className='w-full'>
          <TabOption activeTab={activeTab} setActiveTab={setActiveTab}/>
        </div>
      
        {activeTab === "Navigation Manager" ? (
           <ToolbarSection title="Navigation" expanded={true} >
           <ToolbarItem
             full={true}
             propKey="href"
             type="select"
             label="href"
           />
         </ToolbarSection>
        ) : null }

        {activeTab === "Style Manager" ? (
          <div className='w-[100%]'>
          <ToolbarSection
            expanded={true}
            title="Appearance"
            props={['background', 'color', 'margin', 'width', 'height']}
            summary={({ background, color, margin, width, height }) => {
              let bg = background || { r: 255, g: 255, b: 255, a:1 }
              let clr = color || { r: 92, g: 90, b: 90, a: 1 }
              let btnWidth = width || '0';
              let btnHeight = height || '0';
              return (
                <div className="flex flex-row-reverse">
                  <div
                    style={{
                      background:
                        bg && `rgba(${Object.values(bg)})`,
                    }}
                    className="shadow-md flex-end w-6 h-6 text-center flex items-center rounded-full bg-black"
                  >
                    <p
                      style={{
                        color: clr && `rgba(${Object.values(clr)})`,
                      }}
                      className="text-white w-full text-center"
                    >
                      T
                    </p>
                  
                    <p>{btnWidth}</p>
                    <p>{btnHeight}</p>
                  </div>
                </div>
              );
            }}
          >
            <ToolbarItem propKey="buttonStyle" type="radio" label="Style">
              <ToolbarRadio value="full" label="Full" />
              <ToolbarRadio value="outline" label="Outline" />
            </ToolbarItem>
            <ToolbarItem
              full={true}
              propKey="background"
              type="bg"
              label="color"
              value={{ r: 255, g: 255, b: 255, a:1 }}
            />
              <div className='mt-2 mb-4 pl-[8px]' style={{fontSize:'22px', fontWeight:'500', fontFamily:'Inter'}}>Margin</div>
            <ToolbarItem propKey="margin" index={0} type="slider"
  label="Top" />
            <ToolbarItem propKey="margin" index={1} type="slider"
  label="Right" />
            <ToolbarItem propKey="margin" index={2} type="slider"
  label="Bottom" />
            <ToolbarItem propKey="margin" index={3} type="slider"
  label="Left" />
            <div className='mt-2 mb-4 pl-[8px]' style={{fontSize:'22px', fontWeight:'500', fontFamily:'Inter'}}>Size</div>
            <ToolbarItem propKey="width" type="slider"
  label="Width" />
            <ToolbarItem propKey="height" type="slider"
  label="Height"  />
          </ToolbarSection>
          </div>
        ) : null}
    </div>
  );
};
