/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/
import  React, {useEffect, useState} from 'react';
import io from "socket.io-client"


export const InnerWidget = ({src, setProp}) => {


  const [tempoSrc, setTempoSrc] = useState("")
  const [socket, setSocket] = useState(null)
  const sendProp = () => { 
    console.log(socket, "from builder")
    alert("hello")
    socket?.emit("new prop", {socket:"mohammedaladdin3laddin1092-plugin", key:"color", value:"red"})
    } 


    useEffect(() => {

      let socket = io("https://admin.allyoucancloud.com", {
        transports: ['polling'],
        })?.emit("setup", "mohammedaladdin3laddin1092-builder")

        console.log(socket, "from effect")
        setSocket(socket)
   
  },[])

    return ( 
      <div onClick={() => sendProp()}>
      {src ? <iframe width={`98%`} height={`100%`} allowfullscreen="allowfullscreen" id="i7fk" src={src}></iframe>
      : ( <input
       placeholder='Please write your plugin url then press Enter'
       className='w-full my-[3vmin] py-[1.5%] px-[2%] flex justify-center items-center my-auto'
       style={{ margin: '3% 0 0 0', width: '50%', border:"1px solid lightgray", borderRadius: '2Vmin', background: 'transparent', color: '#161717', fontSize: '1.9Vmin', fontWeight: '400', fontFamily: 'Inter' }}
       value={tempoSrc}
       onChange={(e) => setTempoSrc(e.target.value)}
       onBlur={() => {
       setProp((prop) => {
         prop[src] = tempoSrc;
       }, 500);
       }}
      />)}
        </div>
      )
};
