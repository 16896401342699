/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import * as React from 'react';

import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

import './style.css';
import { useNode } from '@craftjs/core';
import { WidgetFooter } from '../../editor/Toolbar/widgetFooter';
import { orderBy } from "@progress/kendo-data-query";
import products from "./data.json";

const initialSort = [
  {
    field: "ProductName",
    dir: "asc",
  },
];


export const DataGrid_Widget = ({eventsData}) => {



  const { 
    connectors: {
      connect
    } 
} = useNode();




const applyTrigger = (xs) => {
  xs.map(x => {
  let type = x.eventType
  if(type == "Show Notification") {
    alert(x.eventContent)
  } else if(type == "Navigate") {
  } else if (type == "Filter") {
    // applyFilter && applyFilter(allData, x.node, x.key, inputRef?.current?.value)
    // console.log("filters", inputRef?.current?.value, x)
  } else if(type == "Code") {
    let fun = new Function("inputValue", "applyFilter", "addNode", x.eventContent)
    // fun(inputRef?.current?.value, applyFilter, addNode)
  } else if(type == "Inject") {
    // addNode(x.eventContent)
  }
  })
}


function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[property.replaceAll(' ','')];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}
  const loadEvents = (eventsData) => {
    let newEventsData = groupBy(eventsData, "eventName")
    let allv = Object.assign({}, ...Object.keys(newEventsData).map((key) => {
      let arr = [...newEventsData[key]]
      newEventsData[key] = () => applyTrigger(arr);
      return {[key]: newEventsData[key]}
    }))
    // console.log(allv)
    // let ob =  ...(allv.map(item => ({ [Object.keys(item)[0]] : () => applyTrigger(item) }) )))
    
    return allv
}

const [events, setEvents] = React.useState([])

React.useEffect(() => {
  if(eventsData) {
    let handleEvents = loadEvents(eventsData)
    setEvents(handleEvents)
  }
},[eventsData])

  const functions = {
  onSortChange: (event) => {
    setSort(event.sort);
  }
  }
  function combineFunctionsWithMatchingFunction(obj) {
    const combinedObject = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'function') {
        const fnName = key;
        const matchingFn = functions[fnName];
        if (typeof matchingFn === 'function') {
          combinedObject[key] = ((e) => {
            obj[key]();
            matchingFn(e);
          });
        } else {
          combinedObject[key] = obj[key];
        }
      }
    }
    return combinedObject;
  }
  
  // Call the function to combine functions with matching functions
  const combinedObject = combineFunctionsWithMatchingFunction(events);

  const [sort, setSort] = React.useState(initialSort);


  return <div ref={connect}>

<Grid
{...combinedObject}
      style={{
        height: "600px",
      }}
      data={orderBy(products, sort)}
      sortable={true}
      sort={sort}
    >
      <Column width={"400px"} field="ProductID" />
      <Column width={"400px"} field="ProductName" title="Product Name" />
      <Column width={"400px"} field="UnitPrice" title="Unit Price" />
    </Grid>
    
    </div>;
};





DataGrid_Widget.craft = {
  displayName: "DataGrid",
  props: {
    selected: 1, // here is the default value of the selected style
    iswidget: true,
    event: null,
    action: null,
    eventsData: [],
    dataSources: []

  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: WidgetFooter,
  },
};