import React, { useCallback, useRef, useMemo } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import { SvgIcon } from '../Svg';
import { useInView } from 'react-hook-inview';
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { useOptionsContext } from '../../../../OptionsContext';
import { ButtonSettings } from './ButtonSettings';

const defaultProps = {
  className: '',
  href: null,
  isSvg: false,
  text: 'Type Here',
  combinedStyle: {},
  width:null,
  height:null,
  tagName: 'div',
};

const Button = React.memo(({ href, isSvg, Button, tagName, ...defaultProps }) => {
  const {
    connectors: { connect },
    actions: { setProp },
    selected,
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { state: activeIdState } = useActiveIdContext();
  const activeId = activeIdState.activeId;

  const { state: optionsState } = useOptionsContext();
  const treeData = optionsState.options;

  const [inViewRef] = useInView();
  const mergedRef = useRef(null);

  const setRef = useCallback((node) => {
    if (mergedRef.current !== node) {
      if (node) {
        connect(node);
      }
      mergedRef.current = node;
    }
  }, [connect]);

  // const handleContentChange = useCallback((e) => {
  //   setProp((props) => (props.text = e.target.value), 100);
  // }, [setProp]);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    if (!enabled && href) {
      if (href._id?.includes("www")) {
        window.open("https://" + href.name, "_blank");
      } else if (href._id === 'dom') {
        const element = document.getElementById(href._id);
        element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      // else {
      //   const parentItem = treeData.find((x) => x._id === href._id);
      //   setActiveId(parentItem || treeData.find(parent => parent.children.find(child => child._id === href._id)));
      // }
    }
  }, [enabled, href, treeData]);

  // Memoizing combined style to avoid re-renders on style changes
  const styles = useMemo(() => ({
    ...defaultProps,
    ...defaultProps.style,
    cursor: href && !enabled ? "pointer" : "",
  }), [defaultProps, enabled, href]);

  console.log('Button Component Rendered');

  return (
      <div
    // val={href}
     _id="sidebarItemx"
    // href={href && !enabled && `/${href}`}
    // onClick={(e) => {
    //   e.preventDefault(); // Prevent default action of anchor tag

    //   if (!enabled) {
    //     if (href && href._id && href._id.includes("www")) {
    //     // If href exists and href._id includes "www", open a new tab with the URL
    //     window.open("https://" + href.name, "_blank");
    //   } else if (href && href._id && href.name === 'dom') {
    //     const element = Object.values(nodes).find((node) => node.id === href._id);

    //     if (element) {

    //       if (element.dom) {
    //         element.dom.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //       } else {
    //         console.log("DOM Element not found for ID:", element.id);
    //       }
    //     } else {
    //       console.log("Element with ID not found:", href.id);
    //     }
    //     }
    //     else if (href && href.name !== 'dom') {
    //       // Find the item in treeData based on href._id
    //       const parentItem = treeData.find((x) => x._id === href._id);
    //       if (parentItem) {
    //         // Set activeId to the parent item if found
    //         setActiveId(parentItem);
    //       } else {
    //         // If parent item not found, find the child item in treeData
    //         let childItem = null;
    //         for (const parent of treeData) {
    //           const child = parent.children.find((child) => child._id === href._id);
    //           if (child) {
    //             childItem = child;
    //             break;
    //           }
    //         }
    //         // Set activeId to the child item if found
    //         setActiveId(childItem);
    //       }
    //     }
    //   }
    // }}
    ref={setRef}
    // classn
    // onMouseEnter={() => setHovered(true)}
    // onMouseLeave={() => setHovered(false)}
    // className={`${props.class}`}
    // {...otherProps}
    style={styles}
    > 
     Button
      
    </div>
  );
});

Button.craft = {
  displayName: 'Button',
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: ButtonSettings,
  },
};

export default Button;
