/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useContext } from "react";
import FeedbackContext from "../FeedbackContext";
// import { useDispatch } from "react-redux";
// import { addAge, addName } from "../redux/features/messages-slice";

const ActionProvider = ({
  createChatBotMessage,
  setState,
  children,
}) => {
  const { feedback, setFeedback } = useContext(FeedbackContext);
  // const dispatch = useDispatch();
  const handleGotIt = (handler) => {
    const botMessage = createChatBotMessage(
      handler === 'FeedBack' ? "What is your feedback?" : handler === 'Compliant' ? "What is your Complain?" : handler === 'Bug' ? "What is your bug?" : 'How can I help you..?'
      , {});

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };
  
  const handleUserInput = (age) => {
    setState(
      (prev) => {
        console.log(prev, "fromprev")
        let botMessage;
        let lastMessages = prev.messages[prev.messages.length - 2].message
        if (lastMessages === "") {
          botMessage = createChatBotMessage("this is an empty message, Select your question from the options.", {});
          return {
            ...prev,
            messages: [...prev.messages, botMessage],
          };
        } else if (
          lastMessages === "What is your feedback?"
        ) {
          // dispatch(addName(prev.messages[prev.messages.length - 1].message));
          botMessage = createChatBotMessage("Enter your Email, please", {});
          let answer = prev.messages[prev.messages.length - 1]
          setFeedback({...feedback, type: "feedback", content:answer.message})

          return {
            ...prev,
            messages: [...prev.messages, botMessage],
          };
        } else if (
          lastMessages === "What is your bug?"
        ) {
          // dispatch(addName(prev.messages[prev.messages.length - 1].message));
          botMessage = createChatBotMessage("Enter your Email, please", {});
          let answer = prev.messages[prev.messages.length - 1]
          setFeedback({...feedback, type: "bug", content:answer.message})
          return {
            ...prev,
            messages: [...prev.messages, botMessage],
          };
        } else if (
          lastMessages === "Enter your Email, please"
        ) {
          botMessage = createChatBotMessage(
            "We will contact you, Thanks.",
            {}
          );
          let answer = prev.messages[prev.messages.length - 1]
          setFeedback({...feedback, email:answer.message})

          return {
            ...prev,
            messages: [...prev.messages, botMessage],
          };
        } else if (age) {
          // dispatch(addAge(age.toString()));
          botMessage = createChatBotMessage(
            "Thank you. In 5 seconds, bot will exit.",
            {}
          );
          return {
            ...prev,
            messages: [...prev.messages, botMessage],
          };
        } else {
          return prev;
        }
      }
    );
  };
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleGotIt,
            handleUserInput,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
