import React, { useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import GradientSettings from '../../components/editor/Toolbar/GradientColor';
import { NumberSlider } from '../../components/editor/Toolbar/Toolbar-Types/NumberSlider';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import Draggable from "react-draggable";
import { Resizable } from 're-resizable';


const usePanelStyles = makeStyles((_) => ({
    root: {
        overflowY: 'scroll',
        overflowX: "hidden",
        boxShadow: 'none',
        '&:before': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
        '&.Mui-expanded': {
            margin: '0 0',
            minHeight: '7%',
            '&:before': {
                opacity: '1',
            },
            '& + .MuiExpansionPanel-root:before ': {
                display: 'block'
            },
        },
    },
}));

const useSummaryStyles = makeStyles((_) => ({
    root: {
        'min-height': 'auto',
        padding: 0,
        display: "flex",
        alignItems: "flex-start"
    },
    content: {
        marginLeft: '0px',

    },
}));

const SettingsPanel = ({ id, setStylesRow, currentRow, setClosePanel, setOpen, refs }) => {
    
    const [moving, setMoving] = useState(true)
    const [helberScreen, setHelberScreen] = useState(false)


    const [expandedSections, setExpandedSections] = useState({
        general: true,
        layout: false,
        appearance: false
    });

    const toggleSection = (section) => {
        setExpandedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const [selected, setSelected] = useState(null);


    const prevColors = [
        '#e83030',
        '#5f2b2b',
        '#c31313',
        '#0867fc',
        '#f84173',
        '#ebebeb',
        '#111f3e',
        '#5558b1',
    ];


    const checkSelected = (property) => {

        if (selected) {
            if (typeof property !== 'string') {
                return property;
            }

            if (property.startsWith('#')) {
                return property;
            }

            if (property.startsWith('tr')) {
                return 'Transparent';
            }

            return 'Gradient';
        }
    };

    const handlePropBackground = (property, value) => {
        setStylesRow(property, value)
        console.log(value)
    };

    const handleGradBackground = (value) => {
        setStylesRow('background', value)
    };


    return (
        <>
      {helberScreen && <div className='min-w-[100%] h-[100%] fixed top-0 left-0' style={{ zIndex: 99999, background: 'transparent' }}></div>}

  <Draggable bounds={{ left: '100%', top: 0, right: '100%', bottom: '100%' }} onStop={() => setHelberScreen(false)} onDrag={() => setHelberScreen(true)} disabled={moving? true : false}>
            <div
                id={id}
                ref={refs}
                className='dragdiv shadow-xl rounded-[2.5vmin] fixed top-[30%] right-[40%] '
                style={{ zIndex: 999999 }}
            >
                <Resizable
                onResizeStart={() => setHelberScreen(true)} onResizeStop={() => setHelberScreen(false)}
                    style={{ borderRadius: '2.5vmin', background: '#FFF', overflow: 'hidden' }}
                    minWidth={300}
                    lockAspectRatioExtraWidth
                    lockAspectRatioExtraHeight
                    defaultSize={{ width: 300, height: 480 }}
                    minHeight={350}
                >
                    <div
                        style={{ cursor: "move" }}
                        onMouseLeave={() => setMoving(true)}
                        onMouseOver={() => setMoving(false)}
                        className='w-full flex flex-row py-[1vmin] mx-auto justify-center items-center'
                    >
                        <OpenWithIcon sx={{ color: '#000', fontSize: '4vmin' }} />
                    </div>

                        <div className=" w-full h-full pb-[5.9vmin]">

                            <div className='relative flex h-[90%] w-full flex-column overflow-scroll p-[1vmin] ' style={{ scrollbarWidth: 'none' }}>

                                <h2 className="h-[fit-content] text-[2.2vmin] font-semibold my-[2vmin] text-center uppercase">properties</h2>

                                <div className="space-y-4 h-[100%]" >
                                    {/* General styles */}
                                    <div >
                                        <div
                                            className="w-full border-b text-left cursor-pointer flex justify-between items-center text-left  p-2 font-semibold"
                                            onClick={() => toggleSection('general')}
                                        >
                                            <div
                                                className={` ${expandedSections.general ? 'text-[#161717]' : 'text-[#989191]'}`}
                                                style={{ fontSize: '2.3Vmin', paddingLeft: "2%", fontWeight: '500', paddingBottom: '1vmin', fontFamily: 'Inter', 
                                                    //transition: 'all .5s ease-in',
                                                     width: 'fit-content', height: '100%', alignItems: 'center', alignContent: 'center', lineHeight: '100%' }}
                                                id="ProjectName"
                                            >
                                                General styles
                                            </div>
                                            <div style={{ width: "2Vmin" }}>
                                                <svg style={{ float: 'right', marginLeft: '1vmin', 
                                                    //transition: 'all .3s ease-in',
                                                     transform: expandedSections.general ? 'rotate(90deg)' : '' }} aria-hidden="true" focusable="false" role="img" className="octicon octicon-chevron-right" viewBox="0 0 12 12" width="2Vmin" height="2Vmin" fill={expandedSections.general ? '#161717' : '#989191'}><path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3Z"></path></svg>
                                            </div>
                                        </div>
                                        {expandedSections.general && (
                                            <div className="p-[1vmin]">
                                                <div className='w-full flex flex-column pb-4'>
                                                    <div className='text-[2.1vmin] text-start w-full my-[1vmin] '>Backgroud</div>
                                                    <div className='flex flex-column w-full justify-between'>
                                                        <div className='relative my-[1vmin] min-w-[100%] h-[7vmin] flex justify-center align-items-center rounded-[1vmin] overflow-hidden'>
                                                            <input style={{ cursor: 'pointer', minWidth: '150%', height: '130%' }} type='color' value={currentRow?.background}

                                                                onChange={(e) => {
                                                                    let background = e.target.value
                                                                    handlePropBackground('background', background)
                                                                    setSelected(e.target.value)
                                                                }}
                                                            />
                                                            <div style={{ pointerEvents: 'none' }} className='absolute top-0 left-0 text-[#fff] text-[2vmin] w-full h-full flex align-items-center justify-center text-center'>
                                                                <div>{checkSelected(currentRow?.background) || 'Click Here.'}</div>
                                                            </div>
                                                        </div>

                                                        <ul style={{ width: 'fit-content', padding: '0', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', margin: 'auto auto' }}>
                                                            <li className='cursor-pointer' style={{ background: "url(https://i.pinimg.com/originals/40/8e/28/408e28da9e2773e4b65c9bba40307167.png) center", margin: '.35vmin', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '3vmin', height: '3vmin', borderRadius: '50%', border: '1px solid #0867FC30', }} onClick={() => {
                                                                handlePropBackground('background', 'transparent')
                                                                setSelected('transparent')
                                                            }}>
                                                            </li>
                                                            {prevColors.map((color, index) => (
                                                                <li onClick={(e) => {
                                                                    handlePropBackground('background', color)
                                                                    setSelected(color)
                                                                }}
                                                                    style={{ cursor: 'pointer', margin: '.35vmin', width: '3vmin', height: '3vmin', borderRadius: '50%', border: '1px solid #e9e9e9', background: color }} key={index}></li>
                                                            ))}

                                                        </ul>
                                                        <GradientSettings preview onChange={handleGradBackground} />
                                                    </div>
                                                </div>
                                                <div className='w-full flex flex-column' style={{ borderTop: '.25vmin solid #e9e9e9' }}>
                                                    <div className='text-[2.1vmin] text-start w-full my-[1vmin] '>Color</div>
                                                    <div className='flex flex-column w-full justify-between'>
                                                        <div className='relative my-[1vmin] min-w-[100%] h-[7vmin] flex justify-center align-items-center rounded-[1vmin] overflow-hidden'>
                                                            <input style={{ cursor: 'pointer', minWidth: '150%', height: '130%' }} type='color' value={currentRow?.color}

                                                                onChange={(e) => {
                                                                    let background = e.target.value
                                                                    handlePropBackground('color', background)
                                                                    setSelected(e.target.value)
                                                                }}
                                                            />
                                                            <div style={{ pointerEvents: 'none' }} className='absolute top-0 left-0 text-[#fff] text-[2vmin] w-full h-full flex align-items-center justify-center text-center'>
                                                                <div>{checkSelected(currentRow?.color) || 'Click Here.'}</div>
                                                            </div>
                                                        </div>

                                                        <ul style={{ width: 'fit-content', padding: '0', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', margin: 'auto auto' }}>
                                                            <li className='cursor-pointer' style={{ background: "url(https://i.pinimg.com/originals/40/8e/28/408e28da9e2773e4b65c9bba40307167.png) center", margin: '.35vmin', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '3vmin', height: '3vmin', borderRadius: '50%', border: '1px solid #0867FC30', }} onClick={() => {
                                                                handlePropBackground('color', 'transparent')
                                                                setSelected('transparent')
                                                            }}>
                                                            </li>
                                                            {prevColors.map((color, index) => (
                                                                <li onClick={(e) => {
                                                                    handlePropBackground('color', color)
                                                                    setSelected(color)
                                                                }}
                                                                    style={{ cursor: 'pointer', margin: '.35vmin', width: '3vmin', height: '3vmin', borderRadius: '50%', border: '1px solid #e9e9e9', background: color }} key={index}></li>
                                                            ))}

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* Layout styles */}
                                    <div >
                                        <div
                                            className="w-full border-b text-left cursor-pointer items-center  flex justify-between p-2 font-semibold"
                                            onClick={() => toggleSection('layout')}
                                        >
                                            <div
                                                className={` ${expandedSections.layout ? 'text-[#161717]' : 'text-[#989191]'}`}
                                                style={{ fontSize: '2.3Vmin', paddingLeft: "2%", fontWeight: '500', paddingBottom: '1vmin', fontFamily: 'Inter', 
                                                    //transition: 'all .5s ease-in',
                                                     width: 'fit-content', height: '100%', alignItems: 'center', alignContent: 'center', lineHeight: '100%' }}
                                                id="ProjectName"
                                            >
                                                Layout styles
                                            </div>
                                            <div style={{ width: "2Vmin" }}>
                                                <svg style={{ float: 'right', marginLeft: '1vmin', 
                                                    //transition: 'all .3s ease-in',
                                                     transform: expandedSections.layout ? 'rotate(90deg)' : '' }} aria-hidden="true" focusable="false" role="img" className="octicon octicon-chevron-right" viewBox="0 0 12 12" width="2Vmin" height="2Vmin" fill={expandedSections.layout ? '#161717' : '#989191'}><path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3Z"></path></svg>
                                            </div>
                                        </div>
                                        {expandedSections.layout && <div style={{ textTransform: 'capitalize' }} className="text-start space-y-4 p-[1vmin]">
                                            <NumberSlider unit='px' value={currentRow?.width?.replace('px', '')} props={{ label: 'Width' }} onChange={(newValue) => setStylesRow('width', newValue + 'px')} />
                                            <NumberSlider unit='px' value={currentRow?.height?.replace('px', '')} props={{ label: 'Height' }} onChange={(newValue) => setStylesRow('height', newValue + 'px')} />
                                            <NumberSlider unit='px' value={currentRow?.padding?.replace('px', '')} props={{ label: 'Padding' }} onChange={(newValue) => setStylesRow('padding', newValue + 'px')} />
                                            <NumberSlider unit='px' value={currentRow?.margin?.replace('px', '')} props={{ label: 'Margin' }} onChange={(newValue) => setStylesRow('margin', newValue + 'px')} />
                                            <NumberSlider unit='px' value={currentRow?.borderRadius?.replace('px', '')} props={{ label: 'Rounded' }} onChange={(newValue) => setStylesRow('borderRadius', newValue + 'px')} />

                                        </div>}
                                    </div>
                                </div>


                            </div>

                            <div className='h-[10%] text-[2vmin] cursor-pointer font-bold text-[#FFF] hover:bg-[#0867FC60] bg-[#e9e9e9] items-center w-full text-center flex justify-center' onClick={() => {
                                setClosePanel(false)
                                setOpen('')
                            }} >Close</div>
                        </div>

                    </Resizable>
                </div>
            </Draggable>

        </>

    );
};

export default SettingsPanel;
