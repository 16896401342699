/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import {ToolbarItem, ToolbarSection} from '../../editor/Toolbar'
import { TabOption } from '../../editor/Viewport/Sidebar/TabOption';
import { useEditor, useNode } from '@craftjs/core';

export const NavBarSettings = () => {
  const { nodes, actions: { setProp } } = useEditor((state, query) => ({ nodes: state.nodes }));
  const {
    options,
    node
  } = useNode((node) => ({
    options: node.data.props,
    node: node
  }));

  const [activeState, setActiveState] = useState("Default")
  const [stateClicked, setStateClicked] = useState(false);

  const handleStateChange = (newState) => {
    setActiveState(newState)
    setProp(node.id, (props) => {
      props['actionState'] = newState
    })
    console.log(options['actionState'])
    setStateClicked(false);
  };


const [activeTab, setActiveTab] = useState("Style Manager");

const [customize, setCustomize] = useState({})

  const handleCustomize = (group) => {
    setCustomize((prev) => ({
        ...prev,
        [group]: !prev[group], // Toggle the current group's state
    }));
};

  return (
    <div className='flex flex-col component-settings' style={{ height:'100%'}}>
      <ToolbarItem
          full={true}
           type={null}
        />
        
      <div className='w-full'>
        <TabOption noContent activeTab={activeTab} setActiveTab={setActiveTab}/>
      </div>
      <div style={{overflowY:'scroll', scrollbarWidth:'none', overflowX:'hidden', height:'100%'}}>
      {/* {activeTab === "Content" ? (
      <>

       </>
      ) : null} */}
      
        {activeTab === "Advanced" ? (
        <>
       
       
       <ToolbarItem
          full={true}
          propKey="subMenu"
          type="Toggle"
          action={true}
          label="Sub Menu"
        />
        
        <ToolbarSection
        expanded={true ? true : false}
        title="Items List"
        summary={({ filterOptions }) => {
          return `${filterOptions}`;
        }}
        >
        <ToolbarItem
          full={true}
          propKey="navbarProps"
          type="menulist"
          action={true}
          label="Items"
        />
        
      </ToolbarSection>
      
      
        </>
        ) : null }

        
{activeTab === "Style Manager" ? (
  <>
      <ToolbarSection
        expanded={true ? true : false}
        title="Background"
        props={['background', 'radius']}
        summary={({ background, radius }) => {
          let bg = background || { r: 255, g: 255, b: 255, a: 1 }
          let br = radius
          return (
            <div className="flex flex-row-reverse">
              <div
                style={{
                  background:
                    bg && `rgba(${Object.values(bg)})`,
                }}
                className="shadow-md flex-end w-6 h-6 text-center flex items-center rounded-full bg-black"
              >
              </div>
              <div>{br}</div>
            </div>
          );
        }}
      >
        <ToolbarItem
          full={true}
          propKey="background"
          type="bg"
          action={true}
        />
      </ToolbarSection>
      <ToolbarSection
        expanded={true ? true : false}
        title="Color"
        props={['color', 'radius']}
        summary={({ color, radius }) => {
          let bg = color || { r: 255, g: 255, b: 255, a: 1 }
          let br = radius
          return (
            <div className="flex flex-row-reverse">
              <div
                style={{
                  color:
                    bg && `rgba(${Object.values(color)})`,
                }}
                className="shadow-md flex-end w-6 h-6 text-center flex items-center rounded-full bg-black"
              >
              </div>
              <div>{br}</div>
            </div>
          );
        }}
      >
        <ToolbarItem
          full={true}
          propKey="color"
          type="color"
          action={true}
        />
      </ToolbarSection>
     

      <ToolbarSection
        expanded={true ? true : false}
        title="Extra"
        props={['raduis', 'shadow', 'shadowBackground', 'transition']}
        summary={({ filterOptions }) => {
          return `${filterOptions}`;
        }}
      >

        <ToolbarItem
          full={true}
          propKey="radius"
          type="slider"
          action={true}

          unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
          label="Radius"
        />
        {/* <ToolbarItem
          full={true}
          propKey="shadow" 
          type="slider"
          unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
          label="shadow"
        /> */}
      </ToolbarSection>
      </>
) : null }
</div>

</div>
    
  )
}