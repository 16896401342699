/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


// ActiveIdContext.js
import React, { createContext, useContext, useReducer } from 'react';

// Define your initial state
const initialState = {
  activeId: null, // Set the initial activeId to null or a default value
};

// Create a context
const ActiveIdContext = createContext();

// Define action types
const SET_ACTIVE_ID = 'SET_ACTIVE_ID';

// Create a reducer function to handle state updates
const activeIdReducer = (state, action) => {

      return {
        ...state,
        activeId: action,
      }
};

// Create a context provider component
const ActiveIdContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(activeIdReducer, initialState);

  return (
    <ActiveIdContext.Provider value={{ state, dispatch }}>
      {children}
    </ActiveIdContext.Provider>
  ); 
};

// Create a custom hook to access the context
const useActiveIdContext = () => {
  const context = useContext(ActiveIdContext);
  if (!context) {
    throw new Error('useActiveIdContext must be used within an ActiveIdContextProvider');
  }
  return context;
};

export { ActiveIdContextProvider, useActiveIdContext, SET_ACTIVE_ID };
