/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { useDarkMode } from '../../../../DarkModeContext';

export const TabOption = ({activeTab, setActiveTab, noContent, noAdvanced}) => {
    const { isDarkMode } = useDarkMode();

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
      };

  return (
    <div className="relative w-[100%] mx-auto " style={{ padding: '0.5vmin', zIndex:999999, boxShadow: !isDarkMode? `0 4px 25px rgba(0, 0, 0, 0.2)` : `0 1px 25px rgba(8, 103, 252, 0.2)`, }}>
    <div className="flex w-full justify-around">
        
        {/* Content Tab */}
        <div 
            style={{ height: 'fit-content', flex: 1 }} 
            onClick={() => handleTabClick("Content")} 
            className={`cursor-pointer text-center font-['Inter'] font-bold  rounded-[1.2vmin] p-[.90vmin] text-[2vmin] ${activeTab === "Content" ? "text-[#FFFFFF] bg-[#0867FC]" : isDarkMode ? 'text-gray-300' : "text-gray-800 opacity-60"}`}
        >
            Content
        </div>
        
        {/* Style Manager Tab */}
        <div 
            style={{ height: 'fit-content', flex: 1 }} 
            onClick={() => handleTabClick("Style Manager")} 
            className={`cursor-pointer text-center font-['Inter'] font-bold  rounded-[1.2vmin] p-[.90vmin] text-[2vmin] ${activeTab === "Style Manager" ? "text-[#FFFFFF] bg-[#0867FC]" : isDarkMode ? 'text-gray-300' : "text-gray-800 opacity-60"}`}
        >
            Style
        </div>
        
        {/* Advanced Tab */}
        <div 
            style={{ height: 'fit-content', flex: 1, display: noAdvanced ? 'none' : 'flex' }} 
            onClick={() => handleTabClick("Advanced")} 
            className={`cursor-pointer text-center font-['Inter'] font-bold  rounded-[1.2vmin] p-[.90vmin] text-[2vmin] ${activeTab === "Advanced" ? "text-[#FFFFFF] bg-[#0867FC]" : isDarkMode ? 'text-gray-300' : "text-gray-800 opacity-60"}`}
        >
            Advanced
        </div>
    </div>

</div>
  );
};