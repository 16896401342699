import React, { useCallback, useState } from 'react';
import { RadioButton } from "@progress/kendo-react-inputs";
import { useNode, useEditor } from '@craftjs/core';
import { RadioButtonSettings } from './RadioButtonSettings';


export const RadioButtons = ({
        style = {},
        fontSize = '16px',
        label,
        width,
        className,
        labelPosition = 'after',
        color = '#0867FC',
        backgroundColor= '#989191',
        borderColor = '#0867FC',
        borderWidth,
        borderRadius,
        radioValue= 'Select one',
}) => {

        const {
          connectors: { connect },
          actions: {setProp},
        } = useNode();
      
        const { enabled } = useEditor((state) => ({
          enabled: state.options.enabled,
        }));


        const [selectedValue, setSelectedValue] = useState(radioValue);
        const handleChange = useCallback(
          (e) => {
            setSelectedValue(e.value);
          },
          [setSelectedValue]
        );
        
        const handleProperty = (value, propertyName, split) => {
            if (value !== null && !split) {
            return { [propertyName]: value?.split(" ").join("") }
            }

            if (value !== null && split === 'splitBg') {
            return { [propertyName]:  value !== null ? value : '' }
            } else {
            return;
            }
        };


        const styleProps = {
        
              ...(color !== null && {
                color: color?.startsWith('#') ? color : 'transparent',
                backgroundImage: !color?.startsWith('#') ? color : undefined,
                WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
                backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
              }),
          
            ...handleProperty(width, 'width'),
            ...handleProperty(fontSize, 'fontSize'),
           
          };

    const CustomStyle = `
    background-color: ${backgroundColor} !important;
    border-color: ${borderColor} !important;
    border-radius: ${borderRadius?.split(" ").join("")};
    width: ${fontSize?.split(" ").join("")};
    height: ${fontSize?.split(" ").join("")};
    border: ${borderWidth?.split(" ").join("")} solid;
  `;

  const randomId = Math.floor(Math.random() * 1000);


        
  return ( 
  <div
  ref={connect}
  className={className}
  style={{padding:'10px 0', alignItems:'center', display:'flex'}}
  >
    <RadioButton
        id={`radio${randomId}`} 
        name="group1"
        value={radioValue}
        labelPlacement={labelPosition}
        checked={selectedValue === radioValue}
        onChange={handleChange}
        style={{cssText: CustomStyle, display:'flex', alignContent:'center'}}

      >
        <label
          htmlFor={`radio${randomId}`}
          className={"k-radiobox-label"}
          style={{
            ...styleProps,
            ...style,
            alignItems:'center',
            alignContent:'center',
            display:'flex'
          }}
        >
          {label || 'Select one'}
        </label>
      </RadioButton>
    </div>
    );
};

RadioButtons.craft = {
    displayName: 'Radio Button',
    props: {
      fontSize: '16px',
      label: null,
      color: '#0867FC',
      width: null,
      isRadioButton:true,
      borderRadius:null,
      radioValue: 'Select one',
      labelPosition:'after'
    },
    related: {
      toolbar: RadioButtonSettings,
    },
  };