/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Link } from 'react-router-dom'
import { FaceIcon, FooterIcon, InstaIcon, LinkedIcon } from '../Svg'
import './footer.css'
import { useEffect, useState } from 'react';

export default function Footer ({scrollToRef, featuresRef, pricingRef, howItWorksRef, homeRef, handleItemClick, selectedItem, setSelectedItem}) {
   
    return (
        <div className='w-full h-full'>
            <div className='footer-container py-10 px-20'>
                <div className='first-c'>
                    <div className='logo'>
                        <FooterIcon/>
                    </div>
                    <div className='footer-list'>
                    <div className='item-footer cursor-pointer' id='homeItem' onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}>
                        <div style={{ textDecoration: 'none', color: selectedItem === homeRef ? '#6064D2' : 'inherit', fontWeight: selectedItem === homeRef ? '700' : '400' }}>Home</div>
                    </div>
                    
                    <div className='item-footer cursor-pointer' id='featuresItem' onClick={() => { scrollToRef(featuresRef); handleItemClick(featuresRef); }}>
                        <div style={{ textDecoration: 'none', color: selectedItem === featuresRef ? '#6064D2' : 'inherit', fontWeight: selectedItem === featuresRef ? '700' : '400' }}>Features</div>
                    </div>
                    
                    <div className='item-footer cursor-pointer' id='howItWorksItem' onClick={() => { scrollToRef(howItWorksRef); handleItemClick(howItWorksRef); }}>
                        <div style={{ textDecoration: 'none', color: selectedItem === howItWorksRef ? '#6064D2' : 'inherit', fontWeight: selectedItem === howItWorksRef ? '700' : '400' }}>How it works?</div>
                    </div>
                    
                    <div className='item-footer cursor-pointer' id='pricingItem' onClick={() => { scrollToRef(pricingRef); handleItemClick(pricingRef); }}>
                        <div style={{ textDecoration: 'none', color: selectedItem === pricingRef ? '#6064D2' : 'inherit', fontWeight: selectedItem === pricingRef ? '700' : '400' }}>Pricing</div>
                    </div>
                    </div>
                    <div className="log-footer flex flex-row justify-between">
                        <div className='sign-up-footer'>
                            Sign up
                        </div>
                        <div className='log-in-footer'>
                            Log in
                        </div>
                    </div>
                </div>
                <div className='second-c'>
                    <div className='mx-[8px] cursor-pointer'><LinkedIcon/></div>
                    <div className='mx-[8px] cursor-pointer'><FaceIcon/></div>
                    <div className='mx-[8px] cursor-pointer'><InstaIcon/></div>
                </div>
                <div className='third-c'>
                    <li className='footer-terms' style={{listStyle:'none'}}>Terms of Service</li>
                    <li className='footer-terms'>Privacy Policy</li>
                    <li className='footer-terms'>© All You Can Cloud 2023</li>
                </div>
            </div>
        </div>
    )
}