/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React from 'react';

import { ToolbarSection, ToolbarItem } from '../../../editor/index.js';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio.js';
import { useNode } from '@craftjs/core';

export const ColumnSettings = () => {

  const {
    actions: { setProp },
    propValue,
  } = useNode((node) => ({
    propValue: node.data.props["rows"],
  }));



  return (
    <React.Fragment>
      {propValue.map((x, i) => <ToolbarSection
        title={`Row ${i+1}`}
        props={['columns']}
      > 
        <ToolbarItem onChange={(value) => {

    const updatedRows = [...propValue];
    updatedRows[i] = { ...updatedRows[i], columns: value };

        setProp((prop) => (prop.rows = updatedRows), 500)
        }} propKey="columns" type="radio" label="Number of columns">
          <ToolbarRadio selected={x.columns == 1} value={1} label="One" />
          <ToolbarRadio selected={x.columns == 2} value={2} label="Two" />
          <ToolbarRadio selected={x.columns == 3} value={3} label="Three" />
        </ToolbarItem>
      </ToolbarSection> )}

          <div 
          onClick={() => {
            setProp((prop) => (prop.rows = [...propValue, {columns:1}]), 500)
          }}
          style={{
          width:"100%",
          display:"flex",
          justifyContent:"center",
          alignItems:"center"
          }}>
          <div
          className='button-6'
          >Add New Row</div>
      </div>

    </React.Fragment>
  );
};
