// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dayraS1 {
    width: 200px;
    height: 200px;
    background-color: #2479FD;
    position: relative;
    border-radius: 50%;
}


.dayraS2 {
    width: 200px;
    height: 200px;
    background-color: #FF0060;
    position: relative;
    border-radius: 50%;
}


.dayraS3 {
    width: 200px;
    height: 200px;
    background-color: #F31559;
    position: relative;
    border-radius: 50%;
}


.dayraS4 {
    width: 200px;
    height: 200px;
    background-color: #0C134F;
    position: relative;
    border-radius: 50%;
}

.dayra1 {
    width:100px;
    height:100px;
    clipPath: circle(100px at 0px 0px);
}


.dayra2 {
    width:100px;
    height:100px;
    clipPath: circle(100px at 0px 0px);
}


.dayra3 {
    width:100px;
    height:100px;
    clipPath: circle(100px at 0px 0px);
}
`, "",{"version":3,"sources":["webpack://./src/components/selectors/blocks/Statistics/Stats.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;;;AAGA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;;;AAGA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;;;AAGA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kCAAkC;AACtC;;;AAGA;IACI,WAAW;IACX,YAAY;IACZ,kCAAkC;AACtC;;;AAGA;IACI,WAAW;IACX,YAAY;IACZ,kCAAkC;AACtC","sourcesContent":[".dayraS1 {\n    width: 200px;\n    height: 200px;\n    background-color: #2479FD;\n    position: relative;\n    border-radius: 50%;\n}\n\n\n.dayraS2 {\n    width: 200px;\n    height: 200px;\n    background-color: #FF0060;\n    position: relative;\n    border-radius: 50%;\n}\n\n\n.dayraS3 {\n    width: 200px;\n    height: 200px;\n    background-color: #F31559;\n    position: relative;\n    border-radius: 50%;\n}\n\n\n.dayraS4 {\n    width: 200px;\n    height: 200px;\n    background-color: #0C134F;\n    position: relative;\n    border-radius: 50%;\n}\n\n.dayra1 {\n    width:100px;\n    height:100px;\n    clipPath: circle(100px at 0px 0px);\n}\n\n\n.dayra2 {\n    width:100px;\n    height:100px;\n    clipPath: circle(100px at 0px 0px);\n}\n\n\n.dayra3 {\n    width:100px;\n    height:100px;\n    clipPath: circle(100px at 0px 0px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
