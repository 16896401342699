import React, { useState, useEffect, useRef } from "react";
import "./App.css";

const MikyWidget = () => {
  // State to manage whether the task column is collapsed
  const [isCollapsed, setIsCollapsed] = useState(false);

  // State to hold the list of emails
  const [emails, setEmails] = useState([]);

  // State to manage any errors encountered during fetch operations
  const [error, setError] = useState(null);

  // State to manage the user's authentication status
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // State to store the currently selected email
  const [selectedEmail, setSelectedEmail] = useState(null);

  // State to limit the number of emails fetched at once
  const [emailLimit, setEmailLimit] = useState(8);

  // State to store the next page token for pagination
  const [nextPageToken, setNextPageToken] = useState(null);

  // State to toggle the visibility of filters modal
  const [showFilters, setShowFilters] = useState(false);

  // State to toggle the visibility of providers modal
  const [showProviders, setShowProviders] = useState(false);

  // State to manage selection mode for emails
  const [isSelectMode, setIsSelectMode] = useState(false);

  // State to hold the list of selected emails
  const [selectedEmails, setSelectedEmails] = useState([]);

  // Ref to handle clicks outside the modal
  const modalRef = useRef(null);

  // Function to toggle task column between collapsed and expanded
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Function to authenticate the user using Google OAuth
  const handleGoogleAuth = async () => {
    try {
      const response = await fetch("http://localhost:5000/google-auth");
      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
        setIsAuthenticated(true); // Set the user as authenticated
      } else {
        throw new Error("Error during Google account setup");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // Function to authenticate the user using Facebook OAuth
  const handleFacebookAuth = async () => {
    try {
      const response = await fetch("http://localhost:5000/facebook-auth");
      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
        setIsAuthenticated(true); // Set the user as authenticated
      } else {
        throw new Error("Error during Facebook account setup");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // Function to fetch emails from the server
  const fetchEmails = async (limit, pageToken = null) => {
    try {
      const tokenQuery = pageToken ? `&pageToken=${pageToken}` : "";
      const response = await fetch(
        `http://localhost:5000/fetch-emails?limit=${limit}${tokenQuery}`
      );
      if (!response.ok) {
        throw new Error("Error fetching emails");
      }
      const data = await response.json();
      setEmails((prevEmails) => [...prevEmails, ...data.emails]); // Append new emails to the list
      setNextPageToken(data.nextPageToken); // Store the next page token for pagination
    } catch (error) {
      setError(error.message); // Set error state if any error occurs
    }
  };

  // Function to refresh emails by resetting the list and fetching new emails
  const refreshEmails = () => {
    setEmails([]); // Clear previous emails
    fetchEmails(emailLimit); // Fetch new emails
  };

  // Function to disconnect the user from their account
  const handleDisconnect = async () => {
    try {
      const response = await fetch("http://localhost:5000/disconnect");
      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
        setIsAuthenticated(false); // Set the user as not authenticated
        setEmails([]); // Clear the email list
        setSelectedEmail(null); // Clear the selected email
        setNextPageToken(null); // Reset pagination token
      } else {
        throw new Error("Error during account disconnection");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // Function to handle the click on an email and fetch its full content
  const handleEmailClick = async (id) => {
    try {
      const response = await fetch(`http://localhost:5000/fetch-email/${id}`);
      if (!response.ok) {
        throw new Error("Error fetching email content");
      }
      const data = await response.json();
      setSelectedEmail(data); // Set the clicked email as selected
    } catch (error) {
      console.error(error.message);
    }
  };

  // Function to load more emails when the user scrolls or clicks a button
  const loadMoreEmails = () => {
    fetchEmails(emailLimit, nextPageToken); // Fetch next batch of emails using the next page token
  };

  // Function to toggle email selection mode
  const handleSelectToggle = () => {
    setIsSelectMode(!isSelectMode); // Toggle selection mode
    setSelectedEmails([]); // Reset selected emails when toggling
  };

  // Function to handle email selection/deselection
  const handleEmailSelect = (emailId) => {
    setSelectedEmails(
      (prevSelected) =>
        prevSelected.includes(emailId)
          ? prevSelected.filter((id) => id !== emailId) // Deselect if already selected
          : [...prevSelected, emailId] // Select if not already selected
    );
  };

  // Function to delete selected emails
  const handleDeleteSelected = async () => {
    try {
      await fetch("http://localhost:5000/delete-emails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ emailIds: selectedEmails }), // Send selected emails to the server for deletion
      });
      setEmails(emails.filter((email) => !selectedEmails.includes(email.id))); // Remove deleted emails from the list
      setSelectedEmails([]); // Reset the selected emails
    } catch (error) {
      console.error("Error deleting emails:", error);
    }
  };

  // Fetch emails when the user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      fetchEmails(emailLimit);
    }
  }, [isAuthenticated]);

  // Close modal if a click occurs outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowFilters(false);
        setShowProviders(false); // Close filters/providers modal when clicking outside
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to truncate long text
  const truncateText = (text, maxLength = 50) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "..."; // Add ellipsis if text is too long
    }
    return text;
  };

  return (
    <div className="container">
      <div className="column column1">
        <h2>Connections</h2>
        <div className="button-group">
          <button className="small-button" onClick={() => setShowFilters(true)}>
            Filters
          </button>
          <button
            className="small-button"
            onClick={() => setShowProviders(true)}
          >
            Providers
          </button>
          {/* Button to toggle email selection mode */}
          <button className="small-button" onClick={handleSelectToggle}>
            {isSelectMode ? "Cancel Select" : "Select"}
          </button>
          {/* Refresh button with an icon */}
          <button
            className={`small-button ${isAuthenticated ? "show" : "not-show"}`}
            onClick={refreshEmails}
          >
            <img
              src="https://img.icons8.com/?size=100&id=59872&format=png&color=FFFFFF"
              alt="Refresh"
              className="refresh-icon"
            />
          </button>
        </div>

        {/* Display error if any */}
        {error ? (
          <p>{error}</p>
        ) : (
          <ul className="email-list">
            {emails.map((email) => (
              <li key={email.id} className="email-item">
                {/* Checkbox for selecting emails in selection mode */}
                {isSelectMode && (
                  <input
                    type="checkbox"
                    checked={selectedEmails.includes(email.id)}
                    onChange={() => handleEmailSelect(email.id)}
                  />
                )}
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png"
                  alt="Gmail Icon"
                  className="gmail-icon"
                  onClick={() => !isSelectMode && handleEmailClick(email.id)} // Fetch email content on click
                />
                <span
                  className="email-snippet"
                  onClick={() => !isSelectMode && handleEmailClick(email.id)}
                >
                  {truncateText(email.snippet)}{" "}
                  {/* Display truncated email snippet */}
                </span>
              </li>
            ))}
          </ul>
        )}
        {/* Load more emails if there is a next page */}
        {nextPageToken && <button onClick={loadMoreEmails}>Load More</button>}

        {/* Delete selected emails button */}
        {isSelectMode && selectedEmails.length > 0 && (
          <button className="delete-button" onClick={handleDeleteSelected}>
            Delete Selected
          </button>
        )}
      </div>

      {/* Content column */}
      <div className={`column column2 ${isCollapsed ? "expanded" : ""}`}>
        <h2>Executer</h2>
        {selectedEmail ? (
          <div>
            <h3>Subject: {selectedEmail.subject}</h3>
            <iframe
              srcDoc={selectedEmail.body}
              className="email-iframe"
              title="Email Content"
            />
          </div>
        ) : (
          <p>Select an email to view its content.</p>
        )}
      </div>

      {/* Task column, with expand/collapse functionality */}
      <div className={`column column3 ${isCollapsed ? "collapsed" : ""}`}>
        <h2>Tasks</h2>
        <button onClick={handleToggle}>
          {isCollapsed ? "Expand" : "Collapse"}
        </button>
      </div>

      {/* Modal for filters */}
      {showFilters && (
        <div className="modal">
          <div className="modal-content" ref={modalRef}>
            <h2>Filters</h2>
            <p>Here the future filters will be added to filter emails.</p>
            <button
              className="button-close"
              onClick={() => setShowFilters(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Modal for providers (e.g., Google, Facebook) */}
      {showProviders && (
        <>
          <div
            className="modal-overlay"
            onClick={() => setShowProviders(false)}
          ></div>
          <div className="modal">
            <div className="modal-content" ref={modalRef}>
              <h2>Providers</h2>
              <div className="provider-list">
                <div className="provider-item">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/5968/5968534.png"
                    alt="Gmail Icon"
                    className="provider-icon"
                  />
                  <span className="provider-name">Google</span>
                  {/* Conditionally render connect/disconnect button */}
                  {!isAuthenticated ? (
                    <button
                      className="connect-button"
                      onClick={handleGoogleAuth}
                    >
                      Connect
                    </button>
                  ) : (
                    <button
                      className="connect-button"
                      onClick={handleDisconnect}
                    >
                      Disconnect
                    </button>
                  )}
                </div>

                <div className="provider-item">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                    alt="Facebook Icon"
                    className="provider-icon"
                  />
                  <span className="provider-name">Facebook</span>
                  <button
                    className="connect-button"
                    onClick={handleFacebookAuth}
                  >
                    Connect
                  </button>
                </div>

                <div className="provider-item">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/220/220236.png"
                    alt="WhatsApp Icon"
                    className="provider-icon"
                  />
                  <span className="provider-name">WhatsApp</span>
                  <button className="connect-button" disabled>
                    Connect
                  </button>
                </div>
              </div>
              <button
                className="button-close"
                onClick={() => setShowProviders(false)}
              >
                Close
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MikyWidget;
