import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDarkMode } from '../../../DarkModeContext';
import { CloseIcon2 } from '../Viewport/Pop-up/svgArray';
import { useEditor } from '@craftjs/core';

export * from './ToolbarItem';
export * from './ToolbarSection';
export * from './ToolbarTextInput';
export * from './ToolbarDropdown';


export const Toolbar = () => {
  const { isDarkMode, smartMenu , setSmartMenu} = useDarkMode();  

  // Using useEditor hook to get the active node and related content
  const { active, related, selected} = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent('selected').first();
    return {
      active: currentlySelectedNodeId,
      selected : state.events.selected,
      related:
        currentlySelectedNodeId && state.nodes[currentlySelectedNodeId]?.related,
    };
  });

  // If smartMenu is different from currentId, return null to prevent rendering
  if (smartMenu === null) {
    return null;
  }
  

  return (
    <>
      {/* Render specific content based on whether something is smartMenu */}
      {smartMenu !== null ? (
        related?.toolbar ? (
          React.createElement(related.toolbar)
        ) : (
          <div
            className="flex flex-col my-[1vmin] p-[1vmin] items-center w-[100%] h-[100%] justify-center text-center"
            style={{
              color: 'rgba(0, 0, 0, 0.5607843137254902)',
            }}
          >
            <div className="text-[2.2vmin]">Click on a component to start editing.</div>
          </div>
        )
      ) : null}
    </>
  );
};
