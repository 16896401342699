import React, { useMemo } from 'react';
import { useDarkMode } from '../../../../DarkModeContext';
import { useEditor, useNode } from '@craftjs/core';
import { withStyles } from '@mui/styles';
import { Slider as MSlider } from '@mui/material';
import { Toolbar } from '../../Toolbar';
import SideBarProperties from '../../../selectors/blocks/Header/Side bar/SideBarProperties';

// Styled Slider component
const SliderStyled = withStyles({
  root: {
    color: '#ABE2FE',
    padding: '5% 0',
  },
  thumb: {
    height: '60%',
    width: '8%',
    backgroundColor: '#ffffff',
    border: '2px solid #ABE2FE',
    marginTop: '-0.5%',
  },
  valueLabel: {
    left: 'calc(-50% + 1.1%)',
    top: '-22%',
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    padding: '1.5%',
    height: '2%',
  },
  rail: {
    padding: '1.5%',
    height: '2%',
    opacity: 0.5,
    backgroundColor: '#ABE2FE',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: '8%',
    width: '10%',
    marginTop: '-3%',
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(MSlider);

// Memoize SideBarProperties to avoid unnecessary re-renders
const MemoizedSideBarProperties = React.memo(SideBarProperties);

export const Sidebar = ({
  setMoving,
  setNavigationSide,
  navigationSide,
  setSettings,
  setStylesOn,
  selectNode,
  socialIcons,
  setSocialIcons,
  logoText,
  setLogoIcon,
  setLogoText,
  logoIcon,
  currentStyleIndex,
  handleSliderChange,
  handleSliderBlur,
  handleSliderFocus,
  active,
}) => {
  const { isDarkMode, smartMenu, setSmartMenu } = useDarkMode();

  // Memoize the styles to avoid unnecessary recalculations
  const sliderStyles = useMemo(() => ({
    width: '90%',
    padding: '3% 15%',
    borderRadius: '2.5vmin',
    margin: '1vmin auto',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }), []); // No dependencies, so it will only be computed once

  // Render the sidebar properties and slider when navigationSide is active and smartMenu is null
  if (navigationSide && smartMenu === null) {
    return (
      <>
        <MemoizedSideBarProperties
          setMoving={setMoving}
          setNavigationSide={setNavigationSide}
          navigationSide={navigationSide}
          setSettings={setSettings}
          setStylesOn={setStylesOn}
          selectNode={selectNode}
          enabled={active}
          active={active}
          socialIcons={socialIcons}
          setSocialIcons={setSocialIcons}
          logoText={logoText}
          setLogoIcon={setLogoIcon}
          setLogoText={setLogoText}
          logoIcon={logoIcon}
        />

        <div style={sliderStyles}>
          <SliderStyled
            title="Navigation System Styles"
            min={0}
            max={23}
            value={currentStyleIndex}
            onChange={handleSliderChange}
            onBlur={handleSliderBlur}
            onFocus={handleSliderFocus}
          />
        </div>
      </>
    );
  }

  // Render the toolbar when smartMenu is not null
  if (smartMenu !== null) {
    return <Toolbar />;
  }

  // Return null if neither condition is met
  return null;
};
