/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';

import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';
import { TabOption } from '../../../editor/Viewport/Sidebar/TabOption';
import { Layers } from '@craftjs/layers';
import { ArrowToRight } from '../../../editor/Viewport/Pop-up/svgArray';

export const ButtonSettings = () => {
  const [activeTab, setActiveTab] = useState("Style Manager");


  const [customize, setCustomize] = useState({})

  const handleCustomize = (group) => {
    setCustomize((prev) => ({
      ...prev,
      [group]: !prev[group], // Toggle the current group's state
    }));
  };


  return (
    <div className='flex flex-col component-settings' style={{ height: '100%' }}>

      <TabOption activeTab={activeTab} setActiveTab={setActiveTab} />

      <div style={{ overflowY: 'scroll', scrollbarWidth: 'none', overflowX: 'hidden', height: '100%' }}>
        {activeTab === "Advanced" ? (
          <>
            <ToolbarSection title="Navigation" expanded={true ? true : false} >
              <ToolbarItem
                full={true}
                propKey="href"
                type="select"
                label="href"
              />
            </ToolbarSection>
          </>
        ) : null}

        {activeTab === "Content" ? (
          <>
            <ToolbarSection title="Content" expanded={true ? true : false} >
              <ToolbarItem
                full={true}
                propKey="text"
                type="header"
                label="Text"
              />
            </ToolbarSection>
          </>
        ) : null}

        {activeTab === "Style Manager" ? (
          <div className='w-[100%]'>
             <ToolbarSection
              expanded={true ? true : false}
              title="Size"
              props={['width', 'height']}
              summary={({ width, height }) => {
                return `${width} ${height}`;
              }}
            >
              <ToolbarItem
                full={true}
                propKey="width"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Width"
              />
              <ToolbarItem propKey="minWidth"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Min-Width" />
              <ToolbarItem propKey="maxWidth"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Max-Width" />
              <ToolbarItem
                full={true}
                propKey="height"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Height"
              />
            </ToolbarSection>
            <ToolbarSection
              expanded={true ? true : false}
              title="Appearance"
              props={['background', 'color', 'margin', 'width', 'height', 'shadow']}
              summary={({ background, color, margin, width, height, shadow }) => {
                let bg = background || { r: 255, g: 255, b: 255, a: 1 }
                let clr = color || { r: 92, g: 90, b: 90, a: 1 }
                let btnWidth = width || '0';
                let btnHeight = height || '0';
                let shado = shadow || { r: 92, g: 90, b: 90, a: 1 };

                return (
                  <div className="flex flex-row-reverse">
                    <div
                      style={{
                        background:
                          bg && `rgba(${Object.values(bg)})`,
                      }}
                      className="shadow-md flex-end w-6 h-6 text-center flex items-center rounded-full bg-black"
                    >
                      <p
                        style={{
                          color: clr && `rgba(${Object.values(clr)})`,
                        }}
                        className="text-white w-full text-center"
                      >
                        T
                      </p>
                      <p>{btnWidth}</p>
                      <p>{btnHeight}</p>
                      <p>{shado}</p>

                    </div>
                  </div>
                );
              }}
            >
              <ToolbarItem propKey="buttonStyle" type="radio">
                <ToolbarRadio value="full" label="Filled" />
                <ToolbarRadio value="outline" label="Outline" />
              </ToolbarItem>
              <ToolbarItem
                full={true}
                propKey="background"
                type="bg"
                value={{ r: 255, g: 255, b: 255, a: 1 }}
              />
            </ToolbarSection>

            <ToolbarSection
            expanded={true ? true : false}
            title="Shadow"
            props={['fontSize', 'fontWeight', 'textAlign']}
           
          >
            <ToolbarItem
              full={true}
              propKey="shadowY"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Y offset"
            />

          <ToolbarItem
              full={true}
              propKey="shadowX"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="X offset"
            />

            <ToolbarItem
              full={true}
              propKey="shadowBlur"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Blur"
            />

            <ToolbarItem
              full={true}
              propKey="shadowColor"
              type="color"
              label="Shadow Color"
            />


           
          </ToolbarSection>

            <ToolbarSection

              title="Border"
              props={['border', 'fit']}
              summary={({ border, fit }) => {
                return `${border}px` `${fit}`;
              }}
            >

              <ToolbarItem
                full={true}
                propKey="borderWidth"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Border Width"
              />

              <ToolbarItem
                full={true}
                propKey="borderColor"
                type="bg"
                label="Border Color"
              />

              <ToolbarItem
                full={true}
                propKey="borderRadius"
                type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Rounded"
              />
            </ToolbarSection>


            <ToolbarSection

              title="margin"
              props={['margin']}
              summary={({ }) => {
                return;
              }}
            >
              {customize['margin'] ? (
                <>
                  <ToolbarItem propKey="marginTop" type="slider"
                    unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                    label="Top" />
                  <ToolbarItem propKey="marginRight" type="slider"
                    unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                    label="Right" />
                  <ToolbarItem propKey="marginBottom" type="slider"
                    unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                    label="Bottom" />
                  <ToolbarItem propKey="marginLeft" type="slider"
                    unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                    label="Left" /></>
              ) : (
                <ToolbarItem propKey="margin" type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                  label="Margin" />
              )}

              <div onClick={() => handleCustomize('margin')} style={{ borderRadius: '0 0 1vmin 1vmin', border: '.25vmin solid' }} className='absolute top-[-.15vmin] min-w-[6vmin] flex justify-center items-center right-0 border-color-[#0867FC80] cursor-pointer text-[#0867FC80] p-[.5vmin] hover:text-[#0867FC]'>{customize['margin'] ? <ArrowToRight fill='#0867FC' style={{ transform: 'rotate(180deg)' }} width='3vmin' height='2vmin' /> : 'customize'}</div>

            </ToolbarSection>


            <ToolbarSection

              title="Padding"
              props={['padding']}
              summary={({ }) => {
                return;
              }}
            >
              {customize['padding'] ? (
                <>
                  <ToolbarItem propKey="paddingTop" type="slider"
                    unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                    label="Top" />
                  <ToolbarItem propKey="paddingRight" type="slider"
                    unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                    label="Right" />
                  <ToolbarItem propKey="paddingBottom" type="slider"
                    unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                    label="Bottom" />
                  <ToolbarItem propKey="paddingLeft" type="slider"
                    unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                    label="Left" />
                </>
              ) : (
                <ToolbarItem propKey="padding" type="slider"
                  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                  label="Padding" />
              )}

              <div onClick={() => handleCustomize('padding')} style={{ borderRadius: '0 0 1vmin 1vmin', border: '.25vmin solid' }} className='absolute top-[-.15vmin] min-w-[6vmin] flex justify-center items-center right-0 border-color-[#0867FC80] cursor-pointer text-[#0867FC80] p-[.5vmin] hover:text-[#0867FC]'>{customize['padding'] ? <ArrowToRight fill='#0867FC' style={{ transform: 'rotate(180deg)' }} width='3vmin' height='2vmin' /> : 'customize'}</div>
            </ToolbarSection>
           
          </div>
        ) : null}
      </div>
    </div>
  );
};
