/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Button } from '../Button';
import { Element, useNode } from '@craftjs/core';
import React from 'react';
import { Container } from '../../../Container';
import { ResizableWrapper } from '../../ResizableWrapper';

export const Column1 = ({columns}) => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return (
    <ResizableWrapper>
  <div style={{ display: "flex", height:"100%", flexDirection:'column', border:'1px solid red', width: "100%", background:"white"}} ref={connect}>
    
{[...Array(columns).keys()].map((x,i,a) => (

  <Element
  key={i}
        id={`Container111112i-${i}`}
        canvas
        is={Container}
        child={true}
        background='#e9e9e9'
        color='#000000'
        height="100%"
        width="100%"
        notMain={true}
      >
      </Element>
))}
      </div>
      </ResizableWrapper>
      );
};
Column1.craft = {
  displayName: "Column",
    props: {
    columns: 1,
    isLayout: true
  }
};