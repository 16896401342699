const verifyEmailContents = {
    headerVerifyEmail:{
        content:'Email Verification',
        background:{value:null, type:'bg'},
        fontSize:{value:'24px', type:'Number'},
        lineHeight:{value:null, type:'Number'},
  
        fontWeight :{value:null, type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
  
        textAlign :{value:'center', type:'String'},
        color: {value:null, type:'color'},
  
        width:{value:'null', type:'Number'},
        height:{value:'null', type:'Number'},
        marginTop:{value:null, type:'Number'},
        marginRight:{value:null, type:'Number'},
        marginBottom:{value:null, type:'Number'},
        marginLeft:{value:null, type:'Number'},
        paddingTop:{value:null, type:'Number'},
        paddingRight:{value:null, type:'Number'},
        paddingBottom:{value:null, type:'Number'},
        paddingLeft:{value:null, type:'Number'},
  
        shadow:{value:null, type:'Number'},
        
      },

      firstContent:{
        content:'An email with instructions to verify your email address has been sent to your address ',
        background:{value:null, type:'bg'},
        fontSize:{value:'11px', type:'Number'},
        lineHeight:{value:null, type:'Number'},
  
        fontWeight :{value:null, type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
  
        textAlign :{value:'center', type:'String'},
        color: {value:null, type:'color'},
        borderRadius: {value:'16px', type:'Number'},
  
        width:{value:'null', type:'Number'},
        height:{value:'null', type:'Number'},
        marginTop:{value:null, type:'Number'},
        marginRight:{value:null, type:'Number'},
        marginBottom:{value:null, type:'Number'},
        marginLeft:{value:null, type:'Number'},
        paddingTop:{value:null, type:'Number'},
        paddingRight:{value:null, type:'Number'},
        paddingBottom:{value:null, type:'Number'},
        paddingLeft:{value:null, type:'Number'},
  
        shadow:{value:null, type:'Number'},
        
      },

      middleContent:{
        content:`Haven't received a verification code in your email?`,
        background:{value:null, type:'bg'},
        fontSize:{value:'11px', type:'Number'},
        lineHeight:{value:null, type:'Number'},
  
        fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},

        fontWeight: null,
        color: {value:null, type:'color'},
        textAlign :{value:'center', type:'String'},
        textDecoration: null, 
        fontFamily: null,
        textTransform: null,
  
        width:{value:'null', type:'Number'},
height:{value:'null', type:'Number'},
        marginTop:{value:null, type:'Number'},
        marginRight:{value:null, type:'Number'},
        marginBottom:{value:null, type:'Number'},
        marginLeft:{value:null, type:'Number'},
paddingTop:{value:null, type:'Number'},
paddingRight:{value:null, type:'Number'},
paddingBottom:{value:null, type:'Number'},
paddingLeft:{value:null, type:'Number'},

        shadow:{value:null, type:'Number'},
        
      },

      
      lastContent:{
        content:`to re-send the email`,
        background:{value:null, type:'bg'},
        fontSize:{value:'11px', type:'Number'},
        lineHeight:{value:null, type:'Number'},
  
        fontWeight :{value:null, type:'String'},
        textAlign :{value:'center', type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},

        fontWeight: null,
        color: {value:null, type:'color'},
        textAlign :{value:'center', type:'String'},
        textDecoration: null, 
        fontFamily: null,
        textTransform: null,
  
        width:{value:'null', type:'Number'},
height:{value:'null', type:'Number'},
        marginTop:{value:null, type:'Number'},
        marginRight:{value:null, type:'Number'},
        marginBottom:{value:null, type:'Number'},
        marginLeft:{value:null, type:'Number'},
paddingTop:{value:null, type:'Number'},
paddingRight:{value:null, type:'Number'},
paddingBottom:{value:null, type:'Number'},
paddingLeft:{value:null, type:'Number'},

        shadow:{value:null, type:'Number'},
        
      },
  
}

export default verifyEmailContents;