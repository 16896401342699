import { useState } from "react";
import { EditNameIcon, EyeIcon, LangTool, LogoSite } from "../../components/editor/Viewport/EditorSvg";
import { useOptionsContext } from "../../OptionsContext";
import AreaEl from "../AreaEl";
import { CloseIcon, MapIcon } from "../../components/land/Svg";
import { IconPerson, MenuIcon3, SearchIcon } from "../../components/editor/Viewport/Pop-up/svgArray";
import { SvgIcon } from "../../components/selectors/basic/Svg";
import SmallWidgets from "./TopWidgets";
import { useSidesContext } from "../../SideContext";
import { useActiveIdContext } from "../../ActiveIdContext";

const TopArea = ({
    outStepper,
    styleWrIcon,
    styleRMIcon,
    review,
    selectedItem,
    handleClick,
    closePanel, setClosePanel,
    checkHeader,
    }) => {
    const { 
        appSettings,
        TopProps,
        rows,
        styles,
        setStyles,
        theOptionRow,
        theOptionRow2,
        theOptionRow3,
        contents,
        } = useSidesContext();

    const [open, setOpen] = useState('')


    const { state: idState, dispatch: idDispatch } = useOptionsContext();
    const { options: treeData } = idState;
    const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

    const color = appSettings?.color?.value
    const h1Styles = appSettings?.fonts?.h1
    const h2Styles = appSettings?.fonts?.h2
    const h3Styles = appSettings?.fonts?.h3
    const h4Styles = appSettings?.fonts?.h4
   
      const handleStyleChange = (id, updatedStyle) => {
        setStyles(prevStyles => ({
          ...prevStyles,
          [id]: updatedStyle,
        }));
      };

    if (checkHeader) {
        return (
            <div style={{height:'fit-content'}}>
               { theOptionRow !== '' && (
                <AreaEl review={review}
                open={open}
                setOpen={setOpen}
                closePanel={closePanel}
                setClosePanel={setClosePanel}   
                onClick={() => {
                setClosePanel(true)
                if (!review) {
                    setOpen('row1')
                }}}
                defaultStyle={styles.row1}
                onStyleChange={(newStyle) => handleStyleChange('row1', newStyle)}
                id='row1'
                style={{...styles.row1, display: theOptionRow === '' && 'none', position: 'relative', paddingTop:'1.5vmin', paddingBottom: '1.5vmin', borderBottom:`.25vmin solid ${color + '10'}`, zIndex:99 }}
                className='shadow-sm w-full  mx-auto text-center'
                >
                { theOptionRow === 'Small widgets' ? (
                    <div 
                        style={{
                            ...h4Styles, alignContent: 'center', height: '100%', alignItems: 'center', zIndex: 99, 
                        }} className={`flex items-center`}>
                        <SmallWidgets 
                        h3Styles={h3Styles}
                        h4Styles={h4Styles}
                        color={color}
                        selectedItem={selectedItem}
                        review={review}
                        styleWrIcon={styleWrIcon}
                        styleRMIcon={styleRMIcon}
                        handleClick={handleClick} 
                        outStepper={outStepper}
                        setActiveId={setActiveId}
                        open={open} setOpen={setOpen} 
                        closePanel={closePanel} setClosePanel={setClosePanel}
                        styles={styles}
                        handleStyleChange={handleStyleChange} 
                        checkPos="header" 
                        />
                    </div>
                ) : theOptionRow === 'Basic Header' ? (
                     <div
                        style={{
                            alignContent: 'center', height: '100%', alignItems: 'center', zIndex: 99,
                        }} className={`flex items-center`}>
                        <SmallWidgets 
                        h3Styles={h3Styles}
                        h4Styles={h4Styles}
                        color={color}
                        appSettings={appSettings}
                        selectedItem={selectedItem}
                        review={review}
                        styleWrIcon={styleWrIcon}
                        styleRMIcon={styleRMIcon}
                        handleClick={handleClick} 
                        outStepper={outStepper}
                        setActiveId={setActiveId}
                        open={open} setOpen={setOpen} 
                        closePanel={closePanel} setClosePanel={setClosePanel}
                        styles={styles}
                        checkPos="header" 
                        handleStyleChange={handleStyleChange} 
                        basic
                        />
                    </div>
                ) : theOptionRow === 'Navigation Pages' ? (
                    <div className="sitemap-container">
                            {treeData.map((node, id) => (
                                <div className="flex flex-column h-full w-[fit-content] min-w-[5vmin] max-w-[15vmin]">
                                <div style={{...h3Styles, color: selectedItem === node._id && color, borderBottom: selectedItem === node._id && `.25vmin solid ${color}` }} className={`sitemap-item`} onClick={() => {
                                    handleClick(node._id)
                                    if (outStepper) {
                                        
                                        setActiveId(node._id)
                                    }
                                }} key={id}>
                                    {node.name.length > 11 ? node.name.slice(0, 10) + "..." : node.name} 
                                </div>
                                {node?.children !== 0 && <div>
                                {node?.children?.map(child => {
                                    return (
                                        <div key={child._id} onClick={(e) => {
                                            e.stopPropagation()
                                            handleClick(child._id)

                                            if (outStepper) {
                                                setActiveId(child._id)
                                            }
                                            }} 
                                            className={`sitemap-item text-center my-[1vmin]`}>
                                            <div style={{...h3Styles, color: selectedItem === child._id && color, borderBottom: selectedItem === child._id && `.25vmin solid ${color}` }}>
                                                {child.name.length > 11 ? child.name.slice(0, 10) + "..." : child.name} 
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>}
                                </div>
                            ))}
                        </div>
                ) : (
                <>
                <div style={h3Styles} className="link-container">
                    <div className="content-text">
                        {contents.content1.text || 'Content With a link'}
                    </div>
                    {contents.content1.href !== '' && (
                        <a className="content-link" style={{color: color}} target="_blank" rel="noopener noreferrer" href={contents.content1.href}>
                            Click
                        </a>
                    )}
                </div>
                </>
                )}
            </AreaEl>
               )}
                { rows > '1' && theOptionRow2 !== '' && (
                    <AreaEl review={review} open={open} onClick={() => {
                        setClosePanel(true)
                        if (!review) {
                            setOpen('row2')
                        }}} setOpen={setOpen} closePanel={closePanel} setClosePanel={setClosePanel} id='row2' 
                        defaultStyle={styles.row2}
                        onStyleChange={(newStyle) => handleStyleChange('row2', newStyle)}
                        style={{...styles.row2, position: 'relative', paddingTop:'1.5vmin', paddingBottom: '1.5vmin', borderBottom:`.25vmin solid ${color + '10'}`, display: (rows < 2 || theOptionRow2 === '') && 'none', width: '100%' }}
                        className='shadow-sm w-full mx-auto  text-center'
                    >
                    {theOptionRow2 === 'Small widgets' ? (
                        <div onClick={(e) => e.stopPropagation()}
                            style={{ alignContent: 'center', height: '100%', alignItems: 'center', zIndex: 99, }} className='flex justify-between items-center'>
                            <SmallWidgets check="header" />
                        </div>
                    ) : theOptionRow2 === 'Navigation Pages' ? (
                        <div className="sitemap-container">
                            {treeData.map((node, id) => (
                                <div className="flex flex-column h-full w-[fit-content] min-w-[5vmin] max-w-[15vmin]">
                                <div style={{...h3Styles, color: selectedItem === node._id && color, borderBottom: selectedItem === node._id && `.25vmin solid ${color}` }} className={`sitemap-item`} onClick={() => {
                                    handleClick(node._id)
                                    if (outStepper) {
                                        
                                        setActiveId(node._id)
                                    }
                                }} key={id}>
                                    {node.name.length > 11 ? node.name.slice(0, 10) + "..." : node.name} 
                                </div>
                                {node?.children !== 0 && <div>
                                {node?.children?.map(child => {
                                    return (
                                        <div key={child._id} onClick={(e) => {
                                            e.stopPropagation()
                                            handleClick(child._id)

                                            if (outStepper) {
                                                setActiveId(child._id)
                                            }
                                            }} 
                                            className={`sitemap-item text-center my-[1vmin]`}>
                                            <div style={{...h3Styles, color: selectedItem === child._id && color, borderBottom: selectedItem === child._id && `.25vmin solid ${color}` }}>
                                                {child.name.length > 11 ? child.name.slice(0, 10) + "..." : child.name} 
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            <div style={h3Styles} className="link-container">
                                <div className="content-text">
                                    {contents.content2.text || 'Content With a link'}
                                </div>
                                {contents.content2.href !== '' && (
                                    <a className="content-link" style={{color: color}} target="_blank" rel="noopener noreferrer" href={contents.content2.href}>
                                        Click
                                    </a>
                                )}
                            </div>
                        </>
                    )}
                </AreaEl>
                )}
               { rows === '3' && theOptionRow3 !== '' && (
                <AreaEl review={review} open={open} onClick={() => {
                    setClosePanel(true)
                    if (!review) {
                        setOpen('row3')
                    }}} setOpen={setOpen} closePanel={closePanel} setClosePanel={setClosePanel} id='row3' 
                    defaultStyle={styles.row3}
                    onStyleChange={(newStyle) => handleStyleChange('row3', newStyle)}
                    style={{...styles.row3, position: 'relative', paddingTop:'1.5vmin', paddingBottom: '1.5vmin', borderBottom:`.25vmin solid ${color + '10'}`, display: (rows < 3 || theOptionRow3 === '') && 'none', width: '100%', }}
                    className='shadow-sm w-full  text-center mx-auto'
            >
                { theOptionRow3 === 'Small widgets' ? (
                    <div onClick={(e) => e.stopPropagation()}
                        style={{ alignContent: 'center', height: 'auto', alignItems: 'center', zIndex: 99, }} className='flex justify-between items-center'>
                        <SmallWidgets check="header" />
                    </div>
                ) : theOptionRow3 === 'Navigation Pages' ? (
                    <div className="sitemap-container">
                            {treeData.map((node, id) => (
                                <div className="flex flex-column h-full w-[fit-content] min-w-[5vmin] max-w-[15vmin]">
                                <div style={{...h3Styles, color: selectedItem === node._id && color, borderBottom: selectedItem === node._id && `.25vmin solid ${color}` }} className={`sitemap-item`} onClick={() => {
                                        handleClick(node._id)

                                    if (outStepper) {
                                        setActiveId(node._id)
                                    }
                                }} key={id}>
                                    {node.name.length > 11 ? node.name.slice(0, 10) + "..." : node.name} 
                                </div>
                                {node?.children !== 0 && <div>
                                {node?.children?.map(child => {
                                    return (
                                        <div key={child._id} onClick={(e) => {
                                            e.stopPropagation()
                                            handleClick(child._id)

                                            if (outStepper) {
                                                setActiveId(child._id)
                                            }
                                            }} 
                                            className={`sitemap-item text-center my-[1vmin]`}>
                                            <div style={{...h3Styles, color: selectedItem === child._id && color, borderBottom: selectedItem === child._id && `.25vmin solid ${color}` }}>
                                                {child.name.length > 11 ? child.name.slice(0, 10) + "..." : child.name} 
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>}
                                </div>
                            ))}
                        </div>
                ) : (
                    <>
                        <div style={h3Styles} className="link-container">
                            <div className="content-text">
                                {contents.content3.text || 'Content With a link'}
                            </div>
                            {contents.content3.href !== '' && (
                                <a className="content-link" style={{color: color}} target="_blank" rel="noopener noreferrer" href={contents.content3.href}>
                                    Click
                                </a>
                            )}
                        </div>
                    </>
                )}
            </AreaEl>
               ) }
            </div>
        )
    }
    return null
}

export default TopArea