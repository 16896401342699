/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';

import { Element, useEditor, useNode } from '@craftjs/core';
import {Parent} from '../../selectors/Parent/index.js'
import { DataGrid_Widget } from '.';
import { Wrapper } from '../../selectors/wrapper/index.js';
import { WidgetFooter } from '../../editor/Toolbar/widgetFooter';



const applyTrigger = (xs) => {
  xs.map(x => {
  let type = x.eventType
  if(type == "Alert") {
    alert(x.eventContent)
  } else if(type == "Navigate") {
  } else if (type == "Filter") {
    // applyFilter && applyFilter(allData, x.node, x.key, inputRef?.current?.value)
    // console.log("filters", inputRef?.current?.value, x)
  } else if(type == "Code") {
    let fun = new Function("inputValue", "applyFilter", "addNode", x.eventContent)
    // fun(inputRef?.current?.value, applyFilter, addNode)
  } else if(type == "Inject") {
    // addNode(x.eventContent)
  }
  })
}


function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[property.replaceAll(' ','')];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}




export const DataGridWidget = ({eventsData, here}) => {

  const loadEvents = (eventsData) => {
    let newEventsData = groupBy(eventsData, "eventName")
    let allv = Object.assign({}, ...Object.keys(newEventsData).map((key) => {
      let arr = [...newEventsData[key]]
      newEventsData[key] = () => applyTrigger(arr);
      return {[key]: newEventsData[key]}
    }))
    // console.log(allv)
    // let ob =  ...(allv.map(item => ({ [Object.keys(item)[0]] : () => applyTrigger(item) }) )))
    
    return allv
}

  const [events, setEvents] = useState([])

  useEffect(() => {
    if(eventsData) {
      setEvents(eventsData)
    }
  },[eventsData])

  const {
    enabled,
    node
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
    node: query
  
  }));

  const { 
      connectors: {
        connect
      } 
  } = useNode();

  return (
    <div style={{ padding: enabled? "0px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
        <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1' className="w-[100%] h-auto" row={true} >
        <Wrapper bg={true} canvas id='random-id-901' is={"div"} className='container' style={{maxWidth:"100%"}}>
            <DataGrid_Widget allEvents={loadEvents(events)} />
        </Wrapper>
        </Element>
        </Element>
    </div>
  );
};

DataGridWidget.craft = {
  displayName: "DataGrid",
  props: {
    selected: 1, // here is the default value of the selected style
    iswidget: true,
    event: null,
    action: null,
    eventsData: [],
    dataSources: []
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: WidgetFooter,
  },
};
