const BackgroundBanner = ({appSettings, review}) => {

  const color = appSettings?.color?.value
  const h1Styles = appSettings?.fonts?.h1
  const h2Styles = appSettings?.fonts?.h2
  const h3Styles = appSettings?.fonts?.h3
  const h4Styles = appSettings?.fonts?.h4
 
    return (
        <div className="wrapper-banner"style={{minHeight:!review ? '100%' : '100vh'}} >
      <div className="wrapper-banner">
        <div className=" wrapper-banner bg-[transparent] py-10" >
        <div className='container' style={{userSelect:'none'}}>
          <div className='wrapper-content flex flex-col justify-center text-center' >

            <div className='flex justify-center flex-col pt-20'>
              <div style={h1Styles} className={`inline-block text-[#FFFFFF] justify-center my-4 `}>
              Understand User Flow.
              </div>
              <div style={h2Styles} className={` inline-block text-[${color}] justify-center my-4 `}>
              Increase Conversion.
              </div>
            </div>

            <div className='p-[15px] flex justify-center'>
              <div style={h3Styles} className={`inline-block justify-center text-[24px] text-[#FFFFFF]`} >
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo tenetur fuga ducimus numquam ea!
            </div>
            </div>

            <div className='flex flex-row flex-wrap justify-center p-20 '>
              <div className="m-2" >
                <div style={h4Styles} href={'/'} className={`w-[27vmin] h-[7vmin] p-[2vmin] flex items-center justify-center text-white bg-[${color}]`}>
                  Get started
                </div>
              </div>
              <div className="m-2" >
                <div style={h4Styles} href={'/'} className={`w-[27vmin] h-[7vmin] p-[2vmin] flex items-center justify-center bg-[transparent] border-1 border-solid border-[${color}] text-[${color}] `}>
                  Learn more
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      </div>
    </div>
    )
}

export default BackgroundBanner;