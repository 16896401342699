/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from "react";
import { StarRatingDiv } from "./StarRatingStyles";

export default function StarRating(props) {
  const [rating, setRating] = useState(props.value || 0);
  const [hover, setHover] = useState(null);

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
    if (props.onChange) {
      props.onChange(event, newValue);
    }
  };


  return (
    <StarRatingDiv>
      {[...Array(5)].map((Star, i) => {
        const ratingValue = i + 1;
        return (
          <label>
            <input
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={(event) => handleRatingChange(event, ratingValue)}
            />
            <div
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(null)}
              className={
                ratingValue <= (hover || rating) ? "activeStar" : "star"
              }
            ></div>
          </label>
        );
      })}
    </StarRatingDiv>
  );
}
