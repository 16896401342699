/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useEditor } from '@craftjs/core';
import { Box, Checkbox, Modal } from '@mui/material';
import { browserName, osName } from 'react-device-detect';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useOptionsContext } from '../../../OptionsContext';
import { useDarkMode } from '../../../DarkModeContext';
import { DescDevice, EditorSvg, EditorSvgActive, MopileDevice, TabDevice, UndoIcon, ToggleSun, ToggleMoon, EyeIcon, DownloadIcon, ThreeDots, ResetIcon, ErrorIcon, StepperRunner } from './EditorSvg.js';
import './ToolBox.css'
import { useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook'
import { useMutation } from '@apollo/client';
import addFeedbackMutation from '../../../graphql/mutations/feedback/addFeedbackMutation.js';
import { DownloadModal } from './modals/downloadModal.js';
import { ArrowDDown, ArrowDown, ArrowUpp, BorderUnder } from './Pop-up/svgArray.js';
import { useActiveIdContext } from '../../../ActiveIdContext.js';
import { TreeView } from './TreeView.js';
import { AppIcon, CloseIcon, Creation, LearnIcon,
  MarkIcon } from '../../land/Svg.js';
import MouseOverPopover from '../../../Tutourial/PopOver.js';
import Tuturial from '../../../Tutourial/Tuturial.js';
import { Stepper } from '../../../Tutourial/Stepper.js';
import { useSidesContext } from '../../../SideContext.js';
import { ConfirmModal } from './modals/confirmModal.js';

export const Header = ({preview, view, value, tuturial, setTuturial, setValue, valueSub, setValueSub, auth, buildTheme, renderMarkup, setNavigationSide, setStylesOn, setActive, setSettings, width, setWidth, setWorking, working, settings, active, isLoading }) => {

  const {
    json
  } = useEditor((state, query) => ({
    json: query.serialize(),
  }));

  const [codeContent, setCodeContent] = useState("")

  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

  async function loadPageContent(page) {
    // Simulating loading the content with a delay of 1 second
    setActiveId({ _id: page._id, name: page.name })
    await new Promise(resolve => setTimeout(resolve, 1000));

    // Perform the necessary logic to load the content of the page
    // ...
    return page; // Return the updated page object with loaded content
  }


  async function savePage(page) {
    // Perform the necessary logic to save the page
    // ...

    // Simulating saving the page with a delay of 1 second

    renderMarkup(codeContent, page.name, json)


    await new Promise(resolve => setTimeout(resolve, 1000));

    return page
  }


  async function setActivePageAndSave(page) {
    // Set the active page
    // ...
    // Load the content of the active page
    const pageWithContent = await loadPageContent(page);
    // Save the entire page
    await savePage(pageWithContent);
  }


  async function processPages(pages) {
    for (const page of pages) {
      await setActivePageAndSave(page);
    }
  }

  const [addFeedback, { error: addFeedbackError, data: addFeedbackData, loading: addFeedbackLoading }] = useMutation(addFeedbackMutation)

  const { isDarkMode, toggleDarkMode } = useDarkMode();

  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;

  const { enabled, canUndo, canRedo, actions } = useEditor((state, query) => ({
    enabled: state.options.enabled,
    canUndo: query.history.canUndo(),
    canRedo: query.history.canRedo(),
  }));


  function generateRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function findNodeById(nodeId, nodes) {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node._id === nodeId) {
        return node;
      }
      if (node.children) {
        const childNode = findNodeById(nodeId, node.children);
        if (childNode) {
          return childNode;
        }
      }
    }
    return null;
  }


  const flattenTree = (tree) => {
    const result = [];

    function traverse(node) {
      result.push(node);

      if (node.children && node.children.length > 0) {
        for (const child of node.children) {
          traverse(child);
        }
      }
    }

    for (const node of tree) {
      traverse(node);
    }

    return result;
  }
  const [downloadModal, setDownloadModal] = useState(false);
  const [hiddenAlert, setHiddenAlert] = useState(false)
  const [DotsClicked, setDotsClicked] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [keysInfo, setKeysInfo] = useState(false)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85vmin',
    justifyContent: 'center',
    bgcolor: 'background.paper',
    border: '.25vmin solid #fff',
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    alignContent: 'center',
    p: '1vmin',
    borderRadius: '2.5vmin',
  };

  const [signUp, setSignUp] = useState(false);

  const openDownloadModal = () => setDownloadModal(true);

  const closeDownloadModal = () => setDownloadModal(false);

  const [downloadingProcess, setDownloadingProcess] = useState(false)

  const [IPAdress, setIPAddress] = useState(null)

  const DotsRef = useRef(null)

  const DotsContain = useRef(null)


  // keys Shortcut
  useHotkeys('shift+d', () => setDownloadModal(true))
  useHotkeys('shift+p', () => actions.setOptions((options) => (options.enabled = !enabled)))
  useHotkeys('ctrl+z', () => actions.history.undo())
  useHotkeys('ctrl+y', () => actions.history.redo())
  useHotkeys('shift+m', () => toggleDarkMode())
  useHotkeys('shift+r', () => {
    localStorage.clear()
    window.location.reload(false);
    setDotsClicked(false)
  })

  useHotkeys('shift+c', () => {
    setActive(!active)
  })

  useHotkeys('shift+l', () => {
    setWidth('100%')
    actions.setProp("ROOT", (props) => {
      props["width"] = "100%"
    })
  })

  useHotkeys('shift+t', () => {
    setWidth('800px')
    actions.setProp("ROOT", (props) => {
      props["width"] = "800px"
    })
  })

  useHotkeys('shift+s', () => {
    setWidth('500px')
    actions.setProp("ROOT", (props) => {
      props["width"] = "500px"
    })
  })




  useEffect(() => {
    const handleClickOutside = (event) => {
      if (DotsRef.current && !DotsRef.current.contains(event.target) && DotsContain.current && !DotsContain.current.contains(event.target)) {
        setDotsClicked(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [DotsRef])

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
  }, []);

  const downloading = () => {

    addFeedback({
      variables: {
        rate: `${rateValue}`,
        position: positionValue,
        feature: wishMessage,
        bug: bugsMessage,
        ip: IPAdress,
        device: osName,
        browser: browserName,
      }
    })

    setTimeout(() => {
      setDownloadingProcess(true);
      actions.setOptions((options) => (options.enabled = true));
    }, 200);

    setTimeout(() => {
      setDownloadingProcess(false);
      actions.setOptions((options) => (options.enabled = true));
      setDownloadModal(false);
    }, 4000);

    actions.setOptions((options) => (options.enabled = false));
    processPages(flattenTree([treeData[0]]));
  };

  const [rateValue, setRateValue] = useState(2)
  const [positionValue, setPositionValue] = useState('')
  const [wishMessage, setWishMessage] = useState('')
  const [bugsMessage, setBugsMessage] = useState('')
  const [open, setOpen] = useState(false);


  const { 
    review, setReview
    } = useSidesContext();


  return (
    <>
    {confirmModal ?
                  <ConfirmModal
                open={confirmModal}
                setOpen={setConfirmModal}
                onClose={() => {
                  localStorage.clear()
                  window.location.reload(false);
                   setConfirmModal(false)
                   setDotsClicked(false)
                }}
                text={"Are you sure you want to reset the page ?"}
              /> : null}
      <div style={{ height: tuturial ? '100%' : 'fit-content', opacity: isLoading && '60%', overflow: 'hidden', padding: tuturial? review ? '0' : '1vmin 2vmin' : '3vmin 2vmin', display: !enabled && 'none', }} className=" flex flex-col w-[100%] " id="HeaderRoot" >
        {tuturial ? (
        <div className={`devideItems ${!review && 'px-[2%]'} ${isDarkMode ? 'bg-[#111F3E]' : 'bg-white'} flex flex-row justify-between items-center shadow-md `} style={{alignItems: "center", height: "100%", zIndex: 2, borderRadius: tuturial && '2.5vmin'  }} >
          <div style={{width:'100%', display: "flex", alignItems: "center", justifyContent: 'center', height:'100%' }}>
            <Stepper setTuturial={setTuturial} value={value} setValue={setValue} valueSub={valueSub} setValueSub={setValueSub}/>
          </div>
        </div>
        ) : (
          <div className={`devideItems relative flex flex-row justify-between items-center shadow-md`} 
          style={{alignItems: "center", background: isDarkMode ? 'rgb(29, 43, 76)' : `linear-gradient(50deg, #FFF, #FFF)`, borderRadius: '5vmin', height: "100%", zIndex: 2 }} >
          <div 
          style={{alignItems: "center", 
          borderRadius: '5vmin', height: "100%", zIndex: 2, 
          width:'100%',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          filter: 'blur(1.5px)',
          }} 
          >
          
          </div>

          <div style={{ display: "flex", height: '100%', justifyContent:'space-between', maxWidth:'100%', padding:'0 2%', margin:'0 auto', flexDirection:'row', 
            background: !isDarkMode && '#e9e9e930', borderRadius: '5vmin', alignItems: "center", width:'100%', zIndex:9999}}>
            {enabled ? <>
              <div style={{ height: "100%", justifyContent: 'flex-start', alignItems: 'center', marginRight: '2Vmin', display: 'flex' }}>
               {!auth ? <div style={{ display: "flex", flexDirection: 'column', margin: 'auto 0', width: '100%', alignContent: 'center', alignItems: 'center' }}>
                  <div className='h-[4Vmin] w-[4Vmin]'>
                    <img
                      src="https://file.rendit.io/n/hgQeGwikg5Hh6qeQRkUN.svg"
                      style={{ width: "4Vmin", height: '4Vmin' }}
                      id="Logo"
                    />
                  </div>
                  <div style={{ width: "5Vmin", justifyContent: "center", borderRadius: "1Vmin", background: "lightgray", padding: '0 .5Vmin', fontSize: "1vmin" }}>Dev1.1.0</div>
                </div> : <div onClick={() => {
                  window.location.href = `https://admin.allyoucancloud.com/admin/dashboard`
                }} style={{fontSize:"50px", cursor:"pointer", color:"#404040"}}> {"<"}</div>}
              </div>

              <div className='flex flex-row align-items-center h-full'>
              <div className='flex flex-column align-items-center h-full'>
              <div 
                  className={`${active === 'widgets' ? 'bg-[#0867fc30]' : 'transparent'}`}
                  style={{ cursor: "pointer", width: "100%", height: "100%", padding:'1vmin', borderRadius:'1vmin', minHeight:'100%', justifyContent: 'center', alignContent: 'center' }}
                  onClick={() => {
                    setOpen(false)
                    setActive(!active);
                  }}
                >
                  <div style={{ height: "100%", padding: '.4vmin', paddingBottom: '0' }} className={`my-auto transition-all duration-500 ease-in-out flex flex-column w-[100%] items-center`}>
                    <div
                      title='Component'
                      className="flex flex-col justify-between h-[100%] my-auto w-[100%] items-center"
                      id="EditorRoot"
                    >
                      <div className={`h-[50%] text-[#B0B0B0] text-[2.5vmin]`} style={{ fontWeight: '500', fontFamily: 'Inter' }}>
                      <div className={`text-${active !== 'widgets' ? '[#B0B0B0]' : '[#0867fc]'} w-auto h-full items-center flex`}>

                      <EditorSvgActive fill={active !== 'widgets' ? '#B0B0B0' : `#0867FC`} width={"3.5Vmin"} height={"3.5Vmin"} />
                      <div className='ml-[1vmin]'>{'Component'}</div>

                  </div>
                      </div>
                    </div>
                  </div>

                </div>
              <div>{active === 'widgets' && <BorderUnder width='100%' fill='#0867fc' />}</div>
              </div>
                  <TreeView open={open} setOpen={setOpen} auth={auth} data={treeData} active={active} setActive={setActive} setWorking={setWorking} />
              </div>
            </> : null}

            
          {enabled ? <div className="hideMobileBarAll flex flex-row items-center " style={{ height: '80%', width: '40%', justifyContent: 'space-around', justifyItems: 'space-around' }}>
          <div
  className={`toggle ${isDarkMode ? 'dark-mode' : 'light-mode'} px-[.25vmin]`}
  style={{
    justifyContent: 'space-between',
    background: isDarkMode ? '#0867Fc20' : '#e9e9e960',
    borderColor: isDarkMode ? '#B0B0B0' : '',
    flexShrink: 1,
    borderRadius: '3vmin', // Keeping it rounded
  }}
  onClick={toggleDarkMode}
>
  <div
    style={{
      backgroundColor: isDarkMode ? '#0867FC' : 'transparent',
      borderRadius: '50%', // Rounded for the moon
      height: '3.7vmin',
      padding: '3.5%',
      display: 'flex',
      alignItems: 'center',
      margin: 'auto 1%',
      border: '0.25vmin solid transparent',
    }}
  >
    <ToggleMoon fill={isDarkMode ? '#fff' : '#B0B0B0'} />
  </div>
  <div
    style={{
      backgroundColor: !isDarkMode ? '#0867FC' : 'transparent',
      borderRadius: '50%', // Rounded for the sun
      height: '3.7vmin',
      padding: '3.5%',
      display: 'flex',
      justifyContent:'center',
      alignItems: 'center',
      margin: 'auto 0',
      border: '0.25vmin solid transparent',
    }}
  >
    <ToggleSun width='2.9vmin' height='3vmin' fill={isDarkMode ? '#fff' : '#B0B0B0'} />
  </div>
</div>

            <div style={{ height: "100%", width: '70%', flexShrink:3 }} className=" hideMobileBar flex flex-row justify-center gap-[3%] items-center">
            <div className='cursor-pointer' onClick={() => setTuturial(true)}>
              <MouseOverPopover/>
            </div>

              <div onClick={() => {
                setWidth("100%")


                actions.setProp("ROOT", (props) => {
                  props["width"] = "100%"
                })
              }}
                style={{ cursor: "pointer", margin: '0 .25Vmin', width: "5.2Vmin", height: "5Vmin", borderRadius: "1Vmin" }}
                className={`${isDarkMode ? `${width == "100%" ? "bg-[#0867fc30]" : "bg-[#e9e9ea30]"}` : `${width == "100%" ? "bg-[#0867FC]" : "bg-[#B0B0B060]"}`} flex flex-col justify-center shrink-0  items-center `}>
                <div style={{ width: "100%", height: "100%" }} className='flex justify-center items-center'>{isDarkMode ? width == "100%" ? <DescDevice fill="#0867FC" /> : <DescDevice fill="#B0B0B0" /> : width == "100%" ? <DescDevice fill='#FFF' /> : <DescDevice fill='#FFFFFF' />}</div>
              </div>
              <div onClick={() => {
                setWidth('800px')
                actions.setProp("ROOT", (props) => {
                  props["width"] = "800px"
                })
              }}
                style={{ cursor: "pointer", margin: '0 .25Vmin', width: "5.2Vmin", height: "5Vmin", borderRadius: "1Vmin" }}
                className={`${isDarkMode ? `${width == '800px' ? "bg-[#0867fc30]" : "bg-[#e9e9ea30]"}` : `${width == '800px' ? "bg-[#0867FC]" : "bg-[#B0B0B060]"}`} flex flex-col justify-center w-["10%"] shrink-0 h-["10%"] items-center `}>
                <div className='mx-auto my-auto'>{isDarkMode ? width == '800px' ? <TabDevice fill="#0867FC" /> : <TabDevice fill="#B0B0B0" /> : width == '800px' ? <TabDevice fill='#FFF' /> : <TabDevice fill='#FFFFFF' />}</div>

              </div>
              <div onClick={() => {
                setWidth('500px')
                actions.setProp("ROOT", (props) => {
                  props["width"] = "500px"
                })
              }}
                style={{ cursor: "pointer", margin: '0 .25Vmin', width: "5.2Vmin", height: "5Vmin", borderRadius: "1Vmin" }}
                className={`${isDarkMode ? `${width == '500px' ? "bg-[#0867fc30]" : "bg-[#e9e9ea30]"}` : `${width == '500px' ? "bg-[#0867FC]" : "bg-[#B0B0B060]"}`} flex flex-col justify-center w-["10%"] shrink-0 h-["10%"] items-center `}>
                <div className='mx-auto my-auto'>{isDarkMode ? width == '500px' ? <MopileDevice fill='none' stroke="#0867FC" /> : <MopileDevice fill='none' stroke="#B0B0B0" /> : width == '500px' ? <MopileDevice fill='none' stroke='#FFF' /> : <MopileDevice fill='none' stroke='#FFFFFF' />}</div>

              </div>
              {/* <div onClick={() => alert('there is no language added yet')} className='cursor-pointer'><LangTool /></div> */}
            </div>

            {/* Undo and redo buttons */}
            <div style={{ height: "100%", flexShrink:1 }} className="flex flex-row justify-end items-center">

              <div className='flex flex-row w-[50] h-auto justify-center' style={{ padding: '.5Vmin 0', margin: '0 2Vmin 0 0' }}>
                <div onClick={() => canUndo ? actions.history.undo() : null} style={{ cursor: !canUndo ? "auto" : "pointer", borderRadius: "1Vmin", }} className={`${isDarkMode ? `${canUndo ? 'bg-[#0867fc30]' : 'bg-[#e9e9ea30]'}` : `${!canUndo ? 'bg-[#B0B0B060]' : 'bg-[#0867FC50]'}`} mx-[.5Vmin] flex flex-col justify-center cursor-pointer w-[5.2Vmin] h-[5Vmin] shrink-0 items-center `}>
                  <UndoIcon fill={isDarkMode? canUndo? '#0867FC' : '#B0B0B0' : '#FFFFFF'}/>

                </div>
                <div onClick={() => canRedo ? actions.history.redo() : null} style={{ cursor: !canRedo ? "auto" : "pointer", borderRadius: "1Vmin", transform: 'scaleX(-1)' }} className={`${isDarkMode ? `${canRedo ? 'bg-[#0867fc30]' : 'bg-[#e9e9ea30]'}` : `${!canRedo ? 'bg-[#B0B0B060]' : 'bg-[#0867FC50]'}`}  flex flex-col justify-center cursor-pointer w-[5.2Vmin] h-[5Vmin] shrink-0 items-center `}>
                  <UndoIcon fill={isDarkMode? canRedo? '#0867FC' : '#B0B0B0' : '#FFFFFF'}/>
                </div>
              </div>
            </div>

          </div> : <div className="flex flex-row justify-between w-[`100%`] items-center">
          </div>}

          <div className="wrapper-btns">
            <div
              style={{ borderRadius: '2.5Vmin', 
                backgroundColor:'transparent',
                color:'#0867FC',
                letterSpacing:'.5px',
                width:'fit-content',
                // transition: 'all .3s ease-in-out' 
              }}
              onClick={() => {
                setReview(prevReview => !prevReview);
                actions.setOptions((options) => (options.enabled = !enabled))
                
              }}
              className={`hideMobileBarAll btn-tool cursor-pointer ${isDarkMode ? "button-outline-dark" : 'button-outline-light'} flex flex-col justify-center shrink-0 items-center `}
              id="Button2"
            >
              <div
                className="text-center in-btn font-bold flex justify-center"
                id="Label1"
                style={{ fontFamily: 'Inter' }}
              >
                {enabled ? <div className='mx-auto'><EyeIcon fill='#0867FC'/></div> : null}
                {/* {enabled ? <div className='px-[.5Vmin] text-in-btn'>Preview</div> : "Edit"} */}
              </div>
            </div>
            {enabled ? <> <div

              style={{ borderRadius: "2.5Vmin", cursor:"not-allowed", opacity:'80%',
                letterSpacing:'.5px', 
                background:'transparent',
                border:'.25vmin solid #989191',
                fontWeight:'200',
                width:'fit-content',
              }}
              // onClick={() => {
              //   actions.setOptions((options) => (options.enabled = false))
              //   processPages(flattenTree(treeData))
 
              //   // renderMarkup(codeContent, activeId, treeData)
              // }}
              onClick={() => {
                // auth ? buildTheme() : setDownloadModal(true)
               downloading()
              }}
              className={`download-out-menu btn-tool ${isDarkMode ? 'button-basic-dark' : 'button-basic-light'}  `}
              id="Button1"
            >
              <div
              title={auth ? "Build" : "Save soon"}
                className="text-center in-btn font-bold text-[#989191] flex justify-center"
                style={{ fontFamily: 'Inter', 
                  // transition: 'all .3s ease-in-out' 
                }}
                id="Label"
              >
                {enabled ? <div className='mx-auto' style={{  transform: auth ? "rotate(180deg)" : "" }}><DownloadIcon stroke='#989191' /></div> : null}
                {/* <div className='text-in-btn px-[.5Vmin]' >{auth ? "Build" : "Save soon"}</div> */}
              </div>
            </div>
            
              <div
                ref={DotsContain}
                className={`dots-btn my-auto ${isDarkMode ? "bg-[#E9E9EA30] hover:bg-[#182543] active:bg-[#E9E9EA30]" : 'bg-[#E9E9EA] hover:bg-[#e9ebef] active:bg-[#E9E9EA]'}`}
                style={{ fontWeight: '700', fontFamily: 'Inter', minWidth:'5vmin' }}
                id="Label"

                onClick={() => {
                  setDotsClicked(!DotsClicked)
                }}

              >
                <ThreeDots />
              </div>
              {<div ref={DotsRef} 
                className={`flex flex-col shadow-md px-[2Vmin] py-[1Vmin] rounded-[2.5Vmin] w-[20Vmin] top-[13Vmin] right-[6%] ${isDarkMode? '' : 'light-mode'}`} 
                style={{ background: isDarkMode ? '#111F3E' : '', 
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  position:'fixed',
                  transform: DotsClicked
                    ? 'translate(0, 0) scale(1)' // Full size and positioned at the center
                    : 'translate(30%, -50%) scale(0)', // Initially small and centered
                  background: 'linear-gradient(50deg, #FFFFFF)',
                  transition: 'transform 300ms ease, opacity 100ms ease', // Transition for scaling and fading
                  zIndex: 99999, // Ensure it's above other content
                  opacity: DotsClicked ? 1 : 0, // Fade in and out when DotsClicked
                }}>
                <div
                  style={{ borderRadius: "2.5Vmin", height: '3vmin', width: 'fit-content' }}
                  className={`download-in-menu shadow-sm `}
                  id="Button1"
                >
                  {enabled ? <div className='mx-[.5vmin]'><DownloadIcon stroke={isDarkMode ? '#fff' : '#000'} /></div> : null}
                </div>
                <div style={{ borderTop: `.25vmin solid ${isDarkMode? '#e9e9e930' : '#e9e9e9'}`, margin: '.5vmin 0 0 0', paddingTop: '.5vmin' }}>
                  <div
                    onClick={() => setKeysInfo(true)}
                    className='flex mb-[.5Vmin] flex-row justify-between cursor-pointer'>
                    <div style={{ fontSize: '1.4Vmin', fontFamily: 'Inter', fontWeight: '500', color: isDarkMode ? '#B0B0B0' : '#000' }}>Shortcut Keys</div>
                    <div><ErrorIcon fill={`${isDarkMode ? "#fff" : "#989191"}`} /></div>
                  </div>

                  <div
                    onClick={() => {
                      setConfirmModal(true)
                      // localStorage.clear()
                      // window.location.reload(false);
                      // setDotsClicked(false)
                    }}
                    className='flex mb-[.5Vmin] flex-row justify-between cursor-pointer'>
                    <div style={{ fontSize: '1.4Vmin', fontFamily: 'Inter', fontWeight: '500', color: isDarkMode ? '#B0B0B0' : '#000' }}>Reset</div>
                    <div><ResetIcon fill={`${isDarkMode ? "#fff" : "#989191"}`} /></div>
                  </div>
                </div>
              </div>}


            </> : <div
              className=" flex flex-col justify-center w-[120px] shrink-0 h-8% items-center rounded-[`30%`]"
              id="Button1"
            >

            </div>}
          </div>
          </div>

          
          {keysInfo && <Modal open={keysInfo}
            onClose={() => setKeysInfo(false)}>
            <Box sx={style}>
              <div className='item' style={{ width: '100%', margin: '.5vmin auto', textAlign: 'left', background: '#98919130', padding: '.5vmin 1.5vmin', borderRadius: '1.5vmin' }}>ctrl+z undo</div>
              <div className='item' style={{ width: '100%', margin: '.5vmin auto', textAlign: 'left', background: '#98919130', padding: '.5vmin 1.5vmin', borderRadius: '1.5vmin' }}>ctrl+y redu</div>
              <div className='item' style={{ width: '100%', margin: '.5vmin auto', textAlign: 'left', background: '#98919130', padding: '.5vmin 1.5vmin', borderRadius: '1.5vmin' }}>shift+r reset</div>
              <div className='item' style={{ width: '100%', margin: '.5vmin auto', textAlign: 'left', background: '#98919130', padding: '.5vmin 1.5vmin', borderRadius: '1.5vmin' }}>shift+c open components</div>
              <div className='item' style={{ width: '100%', margin: '.5vmin auto', textAlign: 'left', background: '#98919130', padding: '.5vmin 1.5vmin', borderRadius: '1.5vmin' }}>shift+d download the page</div>
              <div className='item' style={{ width: '100%', margin: '.5vmin auto', textAlign: 'left', background: '#98919130', padding: '.5vmin 1.5vmin', borderRadius: '1.5vmin' }}>shift+t tablet screen size</div>
              <div className='item' style={{ width: '100%', margin: '.5vmin auto', textAlign: 'left', background: '#98919130', padding: '.5vmin 1.5vmin', borderRadius: '1.5vmin' }}>shift+s mobile screen size</div>
              <div className='item' style={{ width: '100%', margin: '.5vmin auto', textAlign: 'left', background: '#98919130', padding: '.5vmin 1.5vmin', borderRadius: '1.5vmin' }}>shift+m change to dark mode</div>
              <div className='item' style={{ width: '100%', margin: '.5vmin auto', textAlign: 'left', background: '#98919130', padding: '.5vmin 1.5vmin', borderRadius: '1.5vmin' }}>shift+l desktop screen size</div>
              <div className='item' style={{ width: '100%', margin: '.5vmin auto', textAlign: 'left', background: '#98919130', padding: '.5vmin 1.5vmin', borderRadius: '1.5vmin' }}>shift+p enable and disabled edit mode</div>
            </Box>
          </Modal>}
          {downloadModal && (
            <>
              <DownloadModal
                open={downloadModal}
                onClose={closeDownloadModal}
                downloadingProcess={downloadingProcess}
                rateValue={rateValue}
                setRateValue={setRateValue}
                positionValue={positionValue}
                setPositionValue={setPositionValue}
                bugsMessage={bugsMessage}
                setBugsMessage={setBugsMessage}
                wishMessage={wishMessage}
                setWishMessage={setWishMessage}
                downloading={downloading}
                setDownloadModal={setDownloadModal}
              />
            </>
          )}
        </div>
        )}
      </div>
      {!enabled && <div onMouseOver={() => setHiddenAlert(true)} onMouseLeave={() => setHiddenAlert(false)} className='h-[2vmin] bg-[transparent] w-full absolute top-0 left-0' style={{ zIndex: 21, height:preview ? "100%":"2vmin" }}>
        {hiddenAlert && <div
          // style={{ transition: 'all 0.3s ease-in-out' }}
          onClick={() => {
            setReview(prevReview => !prevReview);
            {
            if(preview) {
              window.parent.location.href = `https://app.allyoucancloud.com/build/${view}`
            } else {
              actions.setOptions((options) => (options.enabled = !enabled))
            }
          }}}
          className='flex justify-center pt-[2vmin]'>
          <div style={{position:"relative"}} className={`cursor-pointer hover:bg-[#0078D3A1] bg-[#0078d352] border-[.25vmin] border-[#fff] rounded-full p-[2vmin]`}>
            {preview ? <Creation fill='#fff' height='3vmin' width='3vmin' /> : <CloseIcon fill='#fff' height='3vmin' width='3vmin' />}
            <div style={{position:"absolute", width:"100px", display:"flex", justifyContent:"center", alignItems:"center", borderRadius:"8px", padding:"3px 3px", bottom:"-30px", background:"white"}}>Click to edit</div>
          </div>
        </div>
        }
      </div>
      }
    </>
  );
};
