/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useEditor, useNode } from '@craftjs/core';
import React from 'react';
import { VideoSettings } from './VideoSettings'
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { Container } from '../../../Container';
import { useOptionsContext } from "../../../../OptionsContext"; 

export const Videoo = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

  const { 
    background,
    color,
    margin,
    href,
    src,
    controls,
    autoplay,
    loop,
    muted,
    height,
    width,
    ...otherProps
  } = props;
  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;
  return (

    <Container
    val={href?.name} _id="sidebarItemx"
    onClick={() => {
      if(href && !enabled) {
        if(href._id.includes("www")) {
          window.open("https://" + href?.name, "_blank")
        } else {
          const parentItem = treeData.find((x) => x._id === href._id);

          if (parentItem) {
            setActiveId(parentItem);
          } else {
            let childItem = null;
          
            for (const parent of treeData) {
              const child = parent.children.find((child) => child._id === href._id);
          
              if (child) {
                childItem = child;
                break;
              }
            }
          
            setActiveId(childItem);
          }

        }
      } 
    }}
    startImage={false}
    ref={connect}

      {...otherProps}

    style={{
      cursor: href && !enabled ? "pointer" : "auto",
      background,
      color,
      margin: margin ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px` : 0,
      borderRadius:enabled ? '8px' : ''

    }}>

{src ? <iframe style={{padding:"30px"}} width={`98%`} height={`${height}px`} allowfullscreen="allowfullscreen" id="i7fk" src={`https://www.youtube.com/embed/${src}?&autoplay=${autoplay ? "1" : "0"}&loop=${loop ? "1" : "0"}&muted=${muted}&controls=${controls ? "1" : "0"}&showinfo=0&rel=0`}></iframe>
 : null}
  </Container>
  );
};

Videoo.craft = {
  displayName: 'Video',
  props: {
    background: { r: 255, g: 255, b: 255, a: 0.5 },
    color: { r: 92, g: 90, b: 90, a: 1 },
    VideoCStyle: 'full',
    text: 'Video',
    height:"400",
    width:"100%",
    href: null,
    src: "vjChwFdaGy4",
    controls: true,
    autoplay: false,
    loop: false,
    muted: false,
  },
  related: {
    toolbar: VideoSettings,
  },
};
