/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { useNode, useEditor, Element } from '@craftjs/core';
import Text from '../../basic/Text';
import { NewFeaturesSettings } from './newFeaturesSetting'
import { TextField, Typography } from '@mui/material';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { BounceLoader } from 'react-spinners';
import { Wrapper } from '../../wrapper/index.js';
import { SvgIcon } from '../../basic/Svg';
import './Features.css'

export const Features_1 = ({ selected }) => {

    const {
        actions: { setProp },
        connectors: {
            connect
        }
    } = useNode();


    const {
        enabled,
    } = useEditor((state, query) => ({
        enabled: state.options.enabled,

    }));

    const styles = [

        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto bg-[#FFFDFB] py-20" >
            <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-401' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-402' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-403' className='features-card' style={{ border: '1px solid #DBD9D9', }}>
                            <Element is={Parent} canvas id='random-id-404' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-405' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#B20312' }} id='random-id-49'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000000' }} id='random-id-50'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-406' className='features-card' style={{ border: '1px solid #DBD9D9', }}>
                            <Element is={Parent} canvas id='random-id-407' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-408' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#B20312' }} id='random-id-51'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000000' }} id='random-id-52'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-409' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-410' className='features-card' style={{ border: '1px solid #DBD9D9', }}>
                            <Element is={Parent} canvas id='random-id-411' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-412' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>

                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#B20312' }} id='random-id-53'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>


                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000000' }} className='flex my-auto' id='random-id-54'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>

                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-413' className='features-card' style={{ border: '1px solid #DBD9D9', }}>
                            <Element is={Parent} canvas id='random-id-414' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-415' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>

                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#B20312' }} className='flex my-auto' id='random-id-55'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>

                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000000' }} className='flex my-auto' id='random-id-60'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>

                            </Element>
                        </Element>
                    </Element>

                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto bg-[#121826] py-20" >
            <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-417' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-418' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-419' className='features-card' style={{ border: '1px solid #FFFFFF1A',  }}>
                            <Element is={Parent} canvas id='random-id-420' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#4BDBA2' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-421' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>

                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#4BDBA2' }} id='random-id-1'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>


                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFF' }} id='random-id-2'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>

                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-422' className='features-card' style={{ border: '1px solid #FFFFFF1A',  }}>
                            <Element is={Parent} canvas id='random-id-423' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#4BDBA2' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-424' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#4BDBA2' }} id='random-id-3'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>


                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFF' }} id='random-id-4'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>

                            </Element>
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-425' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-426' className='features-card' style={{ border: '1px solid #FFFFFF1A',  }}>
                            <Element is={Parent} canvas id='random-id-427' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#4BDBA2' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-428' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#4BDBA2' }} className='flex my-auto' id='random-id-5'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFF' }} className='flex my-auto' id='random-id-6'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-429' className='features-card' style={{ border: '1px solid #FFFFFF1A',  }}>
                            <Element is={Parent} canvas id='random-id-430' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#4BDBA2' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-431' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#4BDBA2' }} className='flex my-auto' id='random-id-7'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFF' }} className='flex my-auto' id='random-id-8'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                    </Element>

                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto bg-[#213156] py-20" >
            <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-433' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-434' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-435' className='features-card' style={{ border: '1px solid #EA6EF40A', borderRadius: '10px',  }}>
                            <Element is={Parent} canvas id='random-id-436' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#EA6EF4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-437' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>

                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#EA6EF4' }} id='random-id-9'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>

                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#FFF' }} id='random-id-10'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>

                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-438' className='features-card' style={{ border: '1px solid #EA6EF40A', borderRadius: '10px',  }}>
                            <Element is={Parent} canvas id='random-id-439' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#EA6EF4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-440' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>

                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#EA6EF4' }} id='random-id-11'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>

                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#FFF' }} id='random-id-12'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>

                            </Element>
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-441' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-442' className='features-card' style={{ border: '1px solid #EA6EF40A', borderRadius: '10px',  }}>
                            <Element is={Parent} canvas id='random-id-443' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#EA6EF4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-444' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#EA6EF4' }} id='random-id-13'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#FFF' }} id='random-id-14'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-445' className='features-card' style={{ border: '1px solid #EA6EF40A', borderRadius: '10px',  }}>
                            <Element is={Parent} canvas id='random-id-446' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#EA6EF4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-447' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#EA6EF4' }} className='flex my-auto' id='random-id-15'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#FFF' }} className='flex my-auto' id='random-id-16'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto bg-[#DFFFF9] py-20" >
            <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-449' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-450' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-451' className='features-card' style={{ border: '1px solid #31A9931A',  boxShadow: '0px 0px 4px 0px #0825201A', }}>
                            <Element is={Parent} canvas id='random-id-452' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#31A993' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-453' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#31A993' }} className='flex my-auto' id='random-id-17'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#31A993' }} className='flex my-auto' id='random-id-18'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-454' className='features-card' style={{ border: '1px solid #31A9931A',  boxShadow: '0px 0px 4px 0px #0825201A', }}>
                            <Element is={Parent} canvas id='random-id-455' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#31A993' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-456' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#31A993' }} className='flex my-auto' id='random-id-19'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#31A993' }} className='flex my-auto' id='random-id-20'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-457' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-458' className='features-card' style={{ border: '1px solid #31A9931A',  boxShadow: '0px 0px 4px 0px #0825201A', }}>
                            <Element is={Parent} canvas id='random-id-459' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#31A993' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-460' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#31A993' }} className='flex my-auto' id='random-id-21'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#31A993' }} className='flex my-auto' id='random-id-22'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-461' className='features-card' style={{ border: '1px solid #31A9931A',  boxShadow: '0px 0px 4px 0px #0825201A', }}>
                            <Element is={Parent} canvas id='random-id-462' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#31A993' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-463' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Open Sans', color: '#31A993' }} className='flex my-auto' id='random-id-23'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#31A993' }} className='flex my-auto' id='random-id-24'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto bg-[#FFFFFF] py-20" >
            <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto flex justify-center items-center" row={true}>
                <Element is={Parent} canvas id='random-id-465' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-466' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-467' className='features-card' style={{ border: '1px solid #DBD9D9', borderRadius: '4px',  }}>
                            <Element is={Parent} canvas id='random-id-468' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#0078D3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-469' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>

                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Inter', color: '#0078D3' }} id='random-id-25'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Inter', color: '#100103' }} id='random-id-26'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>

                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-470' className='features-card' style={{ border: '1px solid #DBD9D9', borderRadius: '4px',  }}>
                            <Element is={Parent} canvas id='random-id-471' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#0078D3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-472' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>

                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Inter', color: '#0078D3' }} id='random-id-27'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Inter', color: '#100103' }} id='random-id-28'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>

                            </Element>
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-473' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-474' className='features-card' style={{ border: '1px solid #DBD9D9', borderRadius: '4px',  }}>
                            <Element is={Parent} canvas id='random-id-475' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#0078D3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-476' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Inter', color: '#0078D3' }} className='flex my-auto' id='random-id-29'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Inter', color: '#100103' }} className='flex my-auto' id='random-id-30'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-477' className='features-card' style={{ border: '1px solid #DBD9D9', borderRadius: '4px',  }}>
                            <Element is={Parent} canvas id='random-id-478' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#0078D3' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-479' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Inter', color: '#0078D3' }} className='flex my-auto' id='random-id-31'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Inter', color: '#100103' }} className='flex my-auto' id='random-id-32'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                    </Element>

                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto bg-[#FFFFFF] py-20" >
            <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-481' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-482' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-483' className='features-card' style={{ border: '1px solid #02021233', background: '#EBEBEC',  }}>
                            <Element is={Parent} canvas id='random-id-484' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#020212' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-485' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>

                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Voces', color: '#020212' }} id='random-id-33'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212' }} id='random-id-34'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>

                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-486' className='features-card' style={{ border: '1px solid #02021233', background: '#EBEBEC',  }}>
                            <Element is={Parent} canvas id='random-id-487' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#020212' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-488' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>

                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Voces', color: '#020212' }} id='random-id-35'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212' }} id='random-id-36'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>

                            </Element>
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-489' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-490' className='features-card' style={{ border: '1px solid #02021233', background: '#EBEBEC',  }}>
                            <Element is={Parent} canvas id='random-id-491' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#020212' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-492' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Voces', color: '#020212' }} className='flex my-auto' id='random-id-37'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212' }} className='flex my-auto' id='random-id-38'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-493' className='features-card' style={{ border: '1px solid #02021233', background: '#EBEBEC',  }}>
                            <Element is={Parent} canvas id='random-id-494' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#020212' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-495' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Voces', color: '#020212' }} className='flex my-auto' id='random-id-39'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212' }} className='flex my-auto' id='random-id-40'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                    </Element>

                </Element></Element>
        </Element>,




        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto bg-[#FBFAFF] py-20" >
            <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-497' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-498' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-499' className='features-card' style={{ border: '1px solid #D0BCFF66',   }}>
                            <Element is={Parent} canvas id='random-id-500' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#6750A4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-501' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Roboto', color: '#6750A4' }} id='random-id-41'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Roboto', color: '#020212' }} id='random-id-42'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-502' className='features-card' style={{ border: '1px solid #D0BCFF66',   }}>
                            <Element is={Parent} canvas id='random-id-503' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#6750A4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-504' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Roboto', color: '#6750A4' }} id='random-id-43'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Roboto', color: '#020212' }} id='random-id-44'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-505' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-506' className='features-card' style={{ border: '1px solid #D0BCFF66',   }}>
                            <Element is={Parent} canvas id='random-id-507' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#6750A4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-508' className='w-[70%] min-w-[300px] h-auto flex flex-col py-45 px-4 mx-auto items-center text-center '>

                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Roboto', color: '#6750A4' }} className='flex my-auto' id='random-id-5'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Roboto', color: '#020212    ' }} className='flex my-auto' id='random-id-46'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-509' className='features-card' style={{ border: '1px solid #D0BCFF66',   }}>
                            <Element is={Parent} canvas id='random-id-510' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#6750A4' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-511' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Roboto', color: '#6750A4' }} className='flex my-auto' id='random-id-47'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>

                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Roboto', color: '#020212' }} className='flex my-auto' id='random-id-48'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                    </Element>

                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto bg-[#2A2A2A] py-20" >
            <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-513' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-514' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-515' className='features-card' style={{ border: '1px solid #FFD6001A', borderRadius: '10px', boxShadow: '0px 0px 10px 0px #FFFFFF0F', }}>
                            <Element is={Parent} canvas id='random-id-516' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#FFD600' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-517' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFD600' }} id='random-id-49'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFFFFF' }} id='random-id-50'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-518' className='features-card' style={{ border: '1px solid #FFD6001A', borderRadius: '10px', boxShadow: '0px 0px 10px 0px #FFFFFF0F', }}>
                            <Element is={Parent} canvas id='random-id-519' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#FFD600' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-520' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFD600' }} id='random-id-51'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFFFFF' }} id='random-id-52'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>

                            </Element>
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-521' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-522' className='features-card' style={{ border: '1px solid #FFD6001A', borderRadius: '10px', boxShadow: '0px 0px 10px 0px #FFFFFF0F', }}>
                            <Element is={Parent} canvas id='random-id-523' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#FFD600' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-524' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFD600' }} className='flex my-auto' id='random-id-53'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFFFFF' }} className='flex my-auto' id='random-id-54'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-525' className='features-card' style={{ border: '1px solid #FFD6001A', borderRadius: '10px', boxShadow: '0px 0px 10px 0px #FFFFFF0F', }}>
                            <Element is={Parent} canvas id='random-id-526' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#FFD600' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-527' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFD600' }} className='flex my-auto' id='random-id-55'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFFFFF' }} className='flex my-auto' id='random-id-56'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                    </Element>

                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto bg-[#F7FBFE] py-20" >
            <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-401' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-402' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-403' className='features-card' style={{ border: '1px solid rgba(96, 100, 210, 0.20)', }}>
                            <Element is={Parent} canvas id='random-id-404' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-405' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#6064D2' }} id='random-id-49'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000000' }} id='random-id-50'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-406' className='features-card' style={{ border: '1px solid rgba(96, 100, 210, 0.20)', }}>
                            <Element is={Parent} canvas id='random-id-407' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-408' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#6064D2' }} id='random-id-51'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000000' }} id='random-id-52'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-409' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-410' className='features-card' style={{ border: '1px solid rgba(96, 100, 210, 0.20)', }}>
                            <Element is={Parent} canvas id='random-id-411' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-412' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>

                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#6064D2' }} id='random-id-53'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>


                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000000' }} className='flex my-auto' id='random-id-54'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>

                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-413' className='features-card' style={{ border: '1px solid rgba(96, 100, 210, 0.20)', }}>
                            <Element is={Parent} canvas id='random-id-414' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#B20312' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-415' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>

                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#6064D2' }} className='flex my-auto' id='random-id-55'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>

                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000000' }} className='flex my-auto' id='random-id-60'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>

                            </Element>
                        </Element>
                    </Element>

                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto bg-[#FEFBF7] py-20" >
            <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-901' className='max-w-[1300px]'>
                    <Element is={Parent} canvas id='random-id-902' className='w-full h-full flex flex-row flex-wrap  justify-center'>
                        <Element is={Parent} canvas id='random-id-903' className='features-card' style={{ border: '1px solid rgba(162, 87, 56, 0.20)', }}>
                            <Element is={Parent} canvas id='random-id-904' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#A25738' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-905' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#A25738' }} id='random-id-49'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000000' }} id='random-id-50'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-906' className='features-card' style={{ border: '1px solid rgba(162, 87, 56, 0.20)', }}>
                            <Element is={Parent} canvas id='random-id-907' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#A25738' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-908' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#A25738' }} id='random-id-51'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>
                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000000' }} id='random-id-52'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-909' className='w-full h-full flex flex-row flex-wrap justify-center'>
                        <Element is={Parent} canvas id='random-id-910' className='features-card' style={{ border: '1px solid rgba(162, 87, 56, 0.20)', }}>
                            <Element is={Parent} canvas id='random-id-911' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#A25738' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-912' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center '>

                                <Element is={Parent} className='flex my-auto' style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#A25738' }} id='random-id-53'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>


                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000000' }} className='flex my-auto' id='random-id-54'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>

                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-913' className='features-card' style={{ border: '1px solid rgba(162, 87, 56, 0.20)', }}>
                            <Element is={Parent} canvas id='random-id-914' className='w-[30%] h-auto justify-center flex my-auto mx-auto'>
                                <SvgIcon width='70px' height='70px' fill='#A25738' viewBox='0 0 72 72' path='M18.12 23.316c-7.38 1.671-11.07 2.505-11.95 5.328-.875 2.82 1.639 5.763 6.67 11.646l1.302 1.521c1.428 1.671 2.145 2.508 2.466 3.54.32 1.035.213 2.151-.003 4.38l-.198 2.031c-.76 7.851-1.14 11.775 1.158 13.518 2.298 1.746 5.754.153 12.66-3.027l1.79-.822c1.963-.906 2.944-1.356 3.985-1.356s2.022.45 3.987 1.356l1.785.822c6.909 3.18 10.365 4.77 12.66 3.03 2.3-1.746 1.92-5.67 1.16-13.521M59.16 40.29c5.03-5.88 7.545-8.823 6.669-11.646-.88-2.823-4.57-3.66-11.95-5.328l-1.907-.432c-2.097-.474-3.144-.711-3.987-1.35-.84-.639-1.38-1.608-2.46-3.546l-.984-1.764C40.74 9.408 38.84 6 36 6s-4.74 3.408-8.541 10.224' />
                            </Element>
                            <Element is={Parent} canvas id='random-id-915' className='w-[70%] min-w-[300px] h-auto flex flex-col py-4 px-4 mx-auto items-center text-center'>

                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#A25738' }} className='flex my-auto' id='random-id-55'  >
                                    <Text text='Sed do eiusmod' />
                                </Element>

                                <Element is={Parent} style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400px', fontFamily: 'Rubik', color: '#000000' }} className='flex my-auto' id='random-id-60'  >
                                    <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' />
                                </Element>

                            </Element>
                        </Element>
                    </Element>

                </Element></Element>
        </Element>,

    ]


    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
        setProp((prop) => {
            setSel(100);
        }, 1000);

        setTimeout(() => {
            setProp((prop) => {
                setSel(number)
            }, 1000);
        }, 100);



    }

    useEffect(() => {
        if (selected || selected >= 0) {
            makeOdd(selected);
        }
    }, [selected])


    return (
        <div style={{ padding: enabled ? "10px" : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
            {sel == 100 ? (
                <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <BounceLoader
                        size={100}
                        color={"white"}
                        loading={true}
                    />
                </div>
            ) : (
                styles[sel]
            )}
        </div>
    );
}

Features_1.craft = {
    displayName: "Features 1",
    props: {
        selected: 1, // here is the default value of the selected style
        length: 9,
        isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewFeaturesSettings,
    },
}