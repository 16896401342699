// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --main-color : red;
    --main-scale: scale(1, 1);
}

.component {
    color : var(--main-color)
}

.circle-divider {
    width: 100%;
    height: 50px;
    border-radius: 50%;
    background-color: var(--main-color);
    transform: var(--main-scale);
  }

  `, "",{"version":3,"sources":["webpack://./src/components/selectors/wrapper/Wrapper.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI;AACJ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mCAAmC;IACnC,4BAA4B;EAC9B","sourcesContent":[":root {\n    --main-color : red;\n    --main-scale: scale(1, 1);\n}\n\n.component {\n    color : var(--main-color)\n}\n\n.circle-divider {\n    width: 100%;\n    height: 50px;\n    border-radius: 50%;\n    background-color: var(--main-color);\n    transform: var(--main-scale);\n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
