/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useEffect, useState } from 'react';
import './team.css'
export default function Team() {
    const [activeTab, setActiveTab] = useState('one'); // State to track active tab

    // Function to update active tab based on slider movement
    const handleSliderMovement = (newTab) => {
      setActiveTab(newTab);
    };
  
    // Simulated slider movement effect (replace with actual slider logic)
    useEffect(() => {
      // Simulating slider movement every 3 seconds for demonstration
      const interval = setInterval(() => {
        // Logic to detect slider movement and update activeTab accordingly
        // For example, setActiveTab('two') when slider moves to the second card
        // This is a placeholder; replace it with your actual slider logic
        switch (activeTab) {
          case 'one':
            setActiveTab('one');
            break;
          case 'two':
            setActiveTab('two');
            break;
          case 'three':
            setActiveTab('three');
            break;
          default:
            setActiveTab('one');
        }
      }, 1500); // Simulated 3-second interval
  
      return () => clearInterval(interval); // Clear interval on component unmount
    }, [activeTab]);

    return (
        <div className="w-[95%] h-full mx-auto py-20 mb-20 bg-[#F7FBFE] rounded-[30px]">
            <div className="w-full team-container container">
                <div className='team-header pb-[3%]'>Meet the team</div>
                <div className='team-subheader pb-[2%]'>We started in a family living room and today we are proud to have a professional team of individuals who believed in this adventure since day 1, offering their skills and expertise to pursue a new vision for webpages creation.</div>
                <div className='team-partners'>
                    <div className={`team-card one ${activeTab === 'one' ? 'active' : ''}`}>
                        <div className='card-img'>
                            <img src='https://i.ibb.co/X7GMS2P/a952fe4c977aa672c1c77f2a0a537b12.jpg' style={{width:'100%', height:'100%'}} className='object-cover'/>
                        </div>
                        <div className='card-content'>
                        <div className='first '>
                            <div className='card-name'>Mark Twain</div>
                            <div className='card-position'>CEO, AYCC</div>
                            </div>
                            <div className='card-details'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
                        </div>
                    </div>
                    <div className={`team-card two ${activeTab === 'two' ? 'active' : ''}`}>
                        <div className='card-img'>
                            <img src='https://i.ibb.co/X7GMS2P/a952fe4c977aa672c1c77f2a0a537b12.jpg' style={{width:'100%', height:'100%'}} className='object-cover'/>
                        </div>
                        <div className='card-content'>
                            <div className='first '>
                            <div className='card-name'>Mark Twain</div>
                            <div className='card-position'>CEO, AYCC</div>
                            </div>
                            <div className='card-details'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
                        </div>
                    </div>
                    <div className={`team-card three ${activeTab === 'three' ? 'active' : ''}`}>
                        <div className='card-img'>
                            <img src='https://i.ibb.co/X7GMS2P/a952fe4c977aa672c1c77f2a0a537b12.jpg' style={{width:'100%', height:'100%'}} className='object-cover'/>
                        </div>
                        <div className='card-content'>
                        <div className='first '>
                            <div className='card-name'>Mark Twain</div>
                            <div className='card-position'>CEO, AYCC</div>
                            </div>
                            <div className='card-details'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
                        </div>
                    </div>
                    <div className={`team-card four ${activeTab === 'four' ? 'active' : ''}`}>
                        <div className='card-img'>
                            <img src='https://i.ibb.co/X7GMS2P/a952fe4c977aa672c1c77f2a0a537b12.jpg' style={{width:'100%', height:'100%'}} className='object-cover'/>
                        </div>
                        <div className='card-content'>
                        <div className='first '>
                            <div className='card-name'>Mark Twain</div>
                            <div className='card-position'>CEO, AYCC</div>
                            </div>
                            <div className='card-details'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
                        </div>
                    </div>
                    <div className={`team-card five ${activeTab === 'five' ? 'active' : ''}`}>
                        <div className='card-img'>
                            <img src='https://i.ibb.co/X7GMS2P/a952fe4c977aa672c1c77f2a0a537b12.jpg' style={{width:'100%', height:'100%'}} className='object-cover'/>
                        </div>
                        <div className='card-content'>
                        <div className='first '>
                            <div className='card-name'>Mark Twain</div>
                            <div className='card-position'>CEO, AYCC</div>
                            </div>
                            <div className='card-details'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
                        </div>
                    </div>
                </div>
                <div
                className="border-1 border-[#6064D2] bg-[#989191] mx-auto w-[100%] h-[5px] hidden"
                style={{
                    width:  activeTab === 'one' ? '10%' : '20%', // Equal width for each page indicator (1, 2, 3)
                    height: '5px',
                    borderRadius: '3vmin',
                    transform: `translateX(${
                      activeTab === 'one'
                        ? '0%'
                        : activeTab === 'two'
                        ? '20%'
                        : activeTab === 'three'
                        ? '30%'
                        : '0%' // Default position (if activeTab value doesn't match any)
                    })`,
                    // transition: 'transform 0.3s ease',
                  }}
                
            ></div>
            </div>
        </div>
    )
}