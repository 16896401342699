import * as React from "react";
export const Circle1 = (props) => (
    <svg width={'5vmin'}
    height={'5vmin'} viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M128 24a104 104 0 1 0 104 104A104.12 104.12 0 0 0 128 24m12 152a8 8 0 0 1-16 0V98.944l-11.563 7.706a8 8 0 1 1-8.873-13.314l24-15.993A8 8 0 0 1 140 84z" />
  </svg>
);

export const Circle2 = (props) => (
    <svg width={'5vmin'}
    height={'5vmin'} viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M128 24a104 104 0 1 0 104 104A104.12 104.12 0 0 0 128 24m24 143.994a8 8 0 0 1 0 16h-47.683a9 9 0 0 1-.318.007 8.003 8.003 0 0 1-6.302-12.932l43.673-58.275a16.004 16.004 0 1 0-28.116-15.02 8 8 0 1 1-14.736-6.232 32.004 32.004 0 1 1 56.012 30.355 7 7 0 0 1-.227.32l-34.306 45.777z" />
  </svg>
  );

  export const SidesIcon = (props) => (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill='#0867FC'
      {...props}
    >
      <path
        d="M14.75 3a.75.75 0 0 0-.75.75v16.5a.75.75 0 0 0 1.5 0V3.75a.75.75 0 0 0-.75-.75m-4 0a.75.75 0 0 0-.75.75v16.5a.75.75 0 0 0 1.5 0V3.75a.75.75 0 0 0-.75-.75"
        fillRule="nonzero"
      />
    </svg>
  )
  

  export const Circle3 = (props) => (
    <svg viewBox="0 0 256 256" width={'5vmin'}
    height={'5vmin'} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M128 24a104 104 0 1 0 104 104A104.12 104.12 0 0 0 128 24m21.458 153.456a36 36 0 0 1-50.911 0 8 8 0 0 1 11.314-11.314A20 20 0 1 0 124.003 132a8 8 0 0 1-6.555-12.587l19.188-27.42h-32.639a8 8 0 0 1 0-16h48a8 8 0 0 1 6.555 12.586l-21.019 30.04a36.02 36.02 0 0 1 11.925 58.837" />
    </svg>
  );

  export const Circle4 = (props) => (
    <svg viewBox="0 0 256 256" width={'5vmin'}
    height={'5vmin'} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M128 24a104 104 0 1 0 104 104A104.12 104.12 0 0 0 128 24m28 152a8 8 0 0 1-16 0v-24h-40a8 8 0 0 1-7.544-10.662l23.998-68a8 8 0 1 1 15.088 5.324L111.307 136H140v-24a8 8 0 0 1 16 0z" />
    </svg>
  );

  export const TaskIcon = (props) => {
    <svg
    fill="#000"
    height={20}
    viewBox="0 0 20 20"
    width={20}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="#212121">
      <path d="M4 4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9.883l-2.495 2.52-.934-.953a1.5 1.5 0 1 0-2.142 2.1l.441.45H6a2 2 0 0 1-2-2zm5 5.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5M9.5 5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1zM9 13.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5m-2-3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m1-5a1 1 0 1 0-2 0 1 1 0 0 0 2 0m-1 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
      <path d="M17.855 14.852a.5.5 0 0 0-.71-.704l-3.643 3.68-1.645-1.678a.5.5 0 1 0-.714.7l1.929 1.968a.6.6 0 0 0 .855.002z" />
    </g>
  </svg>  }