/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";

const GRAPHQL_ENDPOINT_ONE = "https://api.allyoucancloud.com/graphql";

const httpLinkOne = new HttpLink({
  uri: GRAPHQL_ENDPOINT_ONE,
  credentials: "include",
});

export const apolloClientOne = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLinkOne,
  onError: ({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message }) => console.log(message));
    }
  },
});


const GRAPHQL_ENDPOINT_TWO = "https://authapi.allyoucancloud.com/graphql";

const httpLinkTwo = new HttpLink({
  uri: GRAPHQL_ENDPOINT_TWO,
  credentials: "include",
});

export const apolloClientTwo = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLinkTwo,
  onError: ({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message }) => console.log(message));
    }
  },
});

