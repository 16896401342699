import { gql } from "apollo-boost";

const addThemeMutation = gql`
  mutation(
    $name: String,
    $org: String,
    $default: Boolean
  ) {
    addTheme(
    name:$name, 
    org:$org,
    default: $default
    ) {
    _id
    name,
    org,
    default
    }
  }
`;

export default addThemeMutation;
