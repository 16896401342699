/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useNode, useEditor, Element } from '@craftjs/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { useOptionsContext } from '../../../../OptionsContext';
import { SvgIcon } from '../Svg';
import { Wrapper } from '../../wrapper/index.js';
import Draggable from 'react-draggable';
import { TextSettings } from '../Text/TextSettings.js';
import { useInView } from 'react-hook-inview';


const defaultProps = {
  className: '',
  href: null,
  src: null,
  initialComputed: {}, 
  style: {},
  properties: {
    background: null,
    maxWidth: null,
    minWidth: null,
    display: null,
    color: null,
    margin: null,
    marginTop: null,
    marginBottom: null,
    marginLeft: null,
    marginRight: null,
    padding: null,
    paddingTop: null,
    paddingBottom: null,
    paddingLeft: null,
    paddingRight: null,
    border: null,
    borderWidth: null,
    borderColor: null,
    borderStyle: 'solid',
    borderRadius: null,
    overflow: null,
    text: 'Heading 1',
    fontSize: null,
    textTransform: null,
    fontWeight: null,
    lineHeight: null,
    fontStyle: "normal",
    textDecoration: null,
    fontFamily: null,
    textAlign: null,
    headingNum: 'div',
    shadowX: null,
    shadowY: null,
    shadowColor:null,
    shadowBlur: null,
  transition: null,
    // Add more styles as needed
  },
  isBasic: false,
  convertToTxt: false,
  isText: true,
  isSvg: false,
};



export const Heading = (props) => {
  const {
    connectors: { connect },
    actions: { setProp },
    selected,
  } = useNode((node) => ({
    selected: node.events.selected,
  }));


  const {
    nodes,
    enabled,
  } = useEditor((state) => ({
    enabled: state.options.enabled,
    nodes: state.nodes
  }));

  
  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;
  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;

  const [inViewRef, inView] = useInView()

  const mergedRef = useRef(null)

  const setRef = useCallback((node) => {
    if (mergedRef.current !== node) {
      if (node) {
        connect(node);
        inViewRef(node);
      }
      mergedRef.current = node;
    }
  }, []);

  const {
    className = '',
    href,
    src,
    initialComputed = {}, 
    style= {},
    properties = {
      background: props.style.background || '',
      display: props.style.display || '',
      color: props.style.color || '',
      margin: props.style.margin || '',
      marginTop: props.style.marginTop || '',
      marginBottom: props.style.marginBottom || '',
      marginLeft: props.style.marginLeft || '',
      marginRight: props.style.marginRight || '',
      padding: props.style.padding || '',
      paddingTop: props.style.paddingTop || '',
      paddingBottom: props.style.paddingBottom || '',
      paddingLeft: props.style.paddingLeft || '',
      paddingRight: props.style.paddingRight || '',
      border: props.style.border || '',
      borderWidth: props.style.borderWidth || '',
      borderColor: props.style.borderColor || '',
      borderStyle: 'solid',
      borderRadius: props.style.borderRadius || '',
      overflow: props.style.overflow || '',
      textAlign: props.style.textAlign || '',
      fontSize: props.style.fontSize || "",
      textTransform: props.style.textTransform || '',
      fontWeight: props.style.fontWeight || '',
      lineHeight: props.style.lineHeight || '',
      fontStyle: props.style.fontStyle || "normal",
      textDecoration: props.style.textDecoration || '',
      fontFamily: props.style.fontFamily || '',
      textAlign: props.style.textAlign || '',
      headingNum: 'div',
      shadowX: '',
      shadowY: '',
      shadowColor:'',
      shadowBlur: '',
      // Add more styles as needed
    },
    isBasic= true,
    convertToTxt = false,
    isText= true,
    text= 'Heading 1',

    isSvg=false,
    children,
    ...otherProps
  } = { ...defaultProps, ...props }; // Merge defaultProps with props

  
function rgbToHex(rgb) {
  // Extract the numbers from the RGB string
  const rgbValues = rgb.match(/\d+/g);
  
  // Convert each RGB value to hex and ensure two digits
  const hexValues = rgbValues.map(value => {
    const hex = parseInt(value).toString(16);
    return hex.length === 1 ? '0' + hex : hex; // Ensure two digits
  });
  
  // Combine the hex values and return as a string starting with '#'
  return `#${hexValues.join('')}`;
}

useEffect(() => {
  if (mergedRef.current) {
    const computedStyles = window.getComputedStyle(mergedRef.current);

    const newStyle = { ...properties }; // Create a copy of the existing styles
    const basicStyle = {
      background: null,
      maxWidth: null,
      minWidth: null,
      display: null,
      color: '#0867fc',
      margin: null,
      marginTop: null,
      marginBottom: null,
      marginLeft: null,
      marginRight: null,
      padding: '8px',
      paddingTop: null,
      paddingBottom: null,
      paddingLeft: null,
      paddingRight: null,
      border: null,
      borderWidth: null,
      borderColor: null,
      borderStyle: 'solid',
      borderRadius: null,
      overflow: null,
      text: 'Heading 1',
      fontSize: '36px',
      textTransform: null,
      fontWeight: null,
      lineHeight: null,
      fontStyle: "normal",
      textDecoration: null,
      fontFamily: null,
      textAlign: null,
      headingNum: 'h1',
      shadowX: null,
      shadowY: null,
      shadowColor:null,
      shadowBlur: null,
      transition: null,
    }

    const initProperties = { ...initialComputed };

    const updatedStyle = isBasic ? basicStyle : newStyle



    // Iterate over computed styles
    for (let i = 0; i < computedStyles.length; i++) {
      const key = computedStyles[i]; // Get the key directly
      const camelCaseKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
      const computedValue = computedStyles.getPropertyValue(key); // Use original key for computed value

     
      // Update the style if the computed value exists and is different from current style
      if (computedValue && camelCaseKey in newStyle) {
        if (isBasic) {
          newStyle[camelCaseKey] = basicStyle[camelCaseKey];
          initProperties[camelCaseKey] = newStyle[camelCaseKey]; // Store initial properties
        } else if (computedValue.startsWith('rgb')) {
          newStyle[camelCaseKey] = rgbToHex(computedValue); // Update with the hex value
          initProperties[camelCaseKey] = newStyle[camelCaseKey]; // Store the hex value
        } else if (computedValue) {
          // If it's not RGB, just assign the value directly
          newStyle[camelCaseKey] = computedValue;
          initProperties[camelCaseKey] = computedValue; // Store initial properties
        }
      }
    }

    setProp((props) => {
      props.properties = updatedStyle;
      props.initialComputed = initProperties;
    });
  }
}, []); 

const combinedStyle = {
  ...props.style,
  ...properties,
  boxShadow: properties.shadowY !== null 
  ? `${properties.shadowX} ${properties.shadowY} ${properties.shadowBlur} ${properties.shadowColor}` 
  : ''};


  const headingTags = {
    'Heading one': 'h1',
    'Two': 'h2',
    'Three': 'h3',
    'Four': 'h4',
    'Five': 'h5',
    'Six: Smallest' : 'h6'
};

const tagName = headingTags[properties.headingNum];



  return (
    <>
      
            <ContentEditable
              val={href?.name} _id="sidebarItemx"
              onClick={(e) => {
                e.preventDefault(); // Prevent default action of anchor tag

                if (!enabled) {
                  if (href && href._id && href._id.includes("www")) {
                  // If href exists and href._id includes "www", open a new tab with the URL
                  window.open("https://" + href.name, "_blank");
                } else if (href && href._id && href.name === 'dom') {
                  const element = Object.values(nodes).find((node) => node.id === href._id);

                  if (element) {

                    if (element.dom) {
                      element.dom.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    } else {
                      console.log("DOM Element not found for ID:", element.id);
                    }
                  } else {
                    console.log("Element with ID not found:", href.id);
                  }
                  }
                  else if (href && href.name !== 'dom') {
                    // Find the item in treeData based on href._id
                    const parentItem = treeData.find((x) => x._id === href._id);
                    if (parentItem) {
                      // Set activeId to the parent item if found
                      setActiveId(parentItem);
                    } else {
                      // If parent item not found, find the child item in treeData
                      let childItem = null;
                      for (const parent of treeData) {
                        const child = parent.children.find((child) => child._id === href._id);
                        if (child) {
                          childItem = child;
                          break;
                        }
                      }
                      // Set activeId to the child item if found
                      setActiveId(childItem);
                    }
                  }
                }
              }}
            
            
            innerRef={setRef}
            html={children || text} // innerHTML of the editable div
            disabled={!enabled}
            onChange={(e) => {
              setProp((prop) => (prop.text = e.target.value), 500);
            }}
            tagName={tagName}
            style={{
              ...combinedStyle, cursor: href && !enabled ? "pointer" : "",
              borderRadius: enabled ? '8px' : '', 
            }}
            className={(href && href.name && activeId && activeId?.name && text) && (href.name.toLowerCase() === activeId?.name.toLowerCase() || text.toLowerCase() === activeId?.name.toLowerCase()) ? selected : ""}

          />

    </>
      );
};

Heading.craft = {
  displayName: 'Heading',
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: TextSettings,
  },
};
