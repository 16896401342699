import { ToolbarSection } from "../../Toolbar";

export const Group = ({ key, noWrap, icon, name, components, full, ...props }) => {

    
    const toggleExpanded = () => {
      setExpanded(expanded.includes(name) ? [] : [name]);
    };

    const {
        setExpanded,
        setOver,
        setAddWidgetActive,
        setGroupActive,
        create,
        isDarkMode,
        expanded,
    } = props
    

    const renderComponentItem = (x) => (
      <div
        key={x.title} // Assuming x.title is unique
        className={`widgetItemIn kok ${isDarkMode ? 'border-[.25vmin] border-[#FFFFFF0D]' : 'border-[.25vmin] border-[#E9E9EA]'} hover:border-[#126cff] flex justify-center items-center rounded-[2vmin] overflow-hidden cursor-pointer 
      ${noWrap ? 'w-[45%] h-[13vmin]' : 'min-w-[18vmin] h-[14vmin]'}`}
        onDragStart={(e) => {
          if (x.disabled) {
            e.preventDefault();
          } else {
            setOver(false);
          }
        }}
        onDragEnd={() => {
          setOver(true);
          setAddWidgetActive(false);
          setGroupActive(null);
        }}
        ref={(ref) => create(ref, x.component)}
      >
        <div className=" flex flex-col mb-1 w-full h-full overflow-hidden">
          <div style={{height:name !== 'controls' && '100%', width: name !== 'controls' &&'100%'}} className={`flex flex-col justify-center items-center p-[.5vmin] relative ${x.disabled ? 'opacity-40 cursor-not-allowed' : 'cursor-pointer'}`}>
            <img
              alt='basic'
              src={x.url}
              className={` ${full ? '' : name === 'controls' ? 'basics-width' : ''}  object-fill  ${name === 'Widgets' ? 'h-[8vmin] w-full' : name === 'controls' ? 'h-[4vmin] w-[4vmin]' : 'w-full h-full'} `}
            />
          </div>
          <div title={x.title} className={`text-center text-[2Vmin] text-${isDarkMode ? 'white' : 'gray-800'}`}>
            {x.title}
          </div>
        </div>
      </div>
    );

    return (
      <div key={key} className="w-full mx-auto h-full justify-start">
        {noWrap ? (
          <div className="flex h-full flex-wrap w-[100%]">
            {components.map(renderComponentItem)}
          </div>
        ) : (
          <ToolbarSection
            expanded={expanded.includes(name) || name === "Controls"}
            setExpanded={toggleExpanded}
            toolbox={true}
            title={name}
            icon={icon}
          >
            {components.map(renderComponentItem)}
          </ToolbarSection>
        )}
      </div>
    );
  };



  