/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';

import { ToolbarSection, ToolbarItem } from '../editor/index.js';

export const ContainerSettings = () => {

  const [expanded, setExpanded] = useState(null)


  return (
    <React.Fragment>
      <div className='w-full text-center text-[3vmin] my-[1vmin] text-[#FFF]' style={{fontWeight:'600', userSelect:'none'}}>Customize</div>
      <div style={{display:'flex', margin:'auto auto', justifyContent:'center', alignItems:'center', flexDirection:'row', flexWrap:'wrap', width:'100%', height:'100%'}}>

      <ToolbarSection
      title="Dimensions" expanded={expanded === 'Dimensions'} 
      setExpanded={() => setExpanded(expanded === 'Dimensions' ? null : 'Dimensions')} 
        props={['width', 'height']}
        summary={({ width, height }) => {
          return `${width || 0} x ${height || 0}`;
        }}  
      >
        <ToolbarItem propKey="width" type="text" label="Width" />
        <ToolbarItem propKey="height" type="text" label="Height" />
      </ToolbarSection>
      <ToolbarSection
        title="Background" expanded={expanded === 'Background'} 
        setExpanded={() => setExpanded(expanded === 'Background' ? null : 'Background')} 
        props={['background']}
        summary={({ background }) => {
          return (
            <div className="flex flex-row-reverse">
              <div
                style={{
                  background:
                    background && `rgba(${Object.values(background)})`,
                }}
                className="shadow-md flex-end w-6 h-6 text-center flex items-center rounded-full bg-black"
              >
              </div>
            </div>
          );
        }}
      >

        <ToolbarItem
          full={true}
          propKey="background"
          type="bg"
          label="Background"
        />
        {/* <ToolbarItem full={true} propKey="color" type="color" label="Text" /> */}
      </ToolbarSection>
      </div>
      
    </React.Fragment>
  );
};
