/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import Text from '../../basic/Text';
import { NewBlogSettings } from './newBlogSetting';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import { CarouselItem } from 'react-bootstrap';
import EastIcon from '@mui/icons-material/East';
import './blog3.css'
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { ImageC } from '../../basic/Image';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import Button  from '../../basic/Button';

import { BounceLoader } from 'react-spinners';



export const Blog_3 = ({selected}) => { 

  let containerWidth;
  let containerWidthContent;

  const {
    enabled,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
 
  }));

  const { 
    actions: { setProp },
      connectors: {
        connect
      } 
  } = useNode();


    const styles = [
      <Element canvas is={Parent} id='parentId32221' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId12333' className="w-[100%] h-auto" row={true} >
        <Wrapper canvas id='random-id-10'  is='div' className='py-20 h-full'  style={{width: '100%', background:'#FFFFFF'}}>
            <Wrapper canvas id='random-id-101' is='div' className='container'>
                <Wrapper canvas id='random-id-102' is='div' className={`w-full pb-[5rem] ${containerWidth?  'text-center' : ''}`} style={{fontSize:'46px', fontWeight:'bold', fontFamily:'Poppins', color:'#000000'}}>
            <Element canvas style={{display:'inline-block'}} id='random-id-1' is='div'>
                <Text text='Our latest news'/>
            </Element>
        </Wrapper>
        <Carousel
        className='w-full mx-auto caro2'
        controls={false}
        indicators
        >
        <Wrapper canvas id='random-id-103' is='div' className='w-full h-full flex flex-col'>
          <Wrapper canvas id='random-id-104' is='div' className='w-full h-full flex flex-row flex-wrap justify-content-between'>
            <Wrapper canvas id='random-id-105' is='div' className='flex flex-col mx-4 mb-6' style={{ flex: '65%', height: '100%', background:'#F7F7F9', borderRadius:'20px' }}>
                  <Wrapper canvas id='random-id-106' is='div' className='h-full'>
                      <Wrapper canvas id='random-id-107' is='div' className='w-full h-full flex flex-col'>
                        <Wrapper canvas id='random-id-108' is='div' className='flex' style={{flex:'auto', borderRadius:'20px 20px 0px 0px',}}>
                          <ImageC className='w-full h-full object-cover' style={{maxHeight:'220px', borderRadius:'20px 20px 0px 0px'}} alt='blog-img' src='https://i.ibb.co/6ZK1NcZ/9.jpg' />
                        </Wrapper>
                        <Wrapper canvas id='random-id-109' is='div' className='w-full p-10'>
                        <Wrapper canvas id='random-id-110' is='div' className='blog-header mt-4' style={{fontFamily:'Poppins'}}>
                          <Text text='Team work - waste of time or perfect plan?'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-111' is='div' className='blog-content mt-10' style={{fontFamily:'Poppins'}}>
                          <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-112' is='div' className='blog-button w-full justify-content-end my-4'>
                          <Button  classn='block' class='blog-button'>
                            <Wrapper canvas id='random-id-11'  is='div' style={{color:'#2479FD', fontFamily:'Poppins'}}>
                              <Text text='Learn More'/>
                            </Wrapper>
                          </Button>
                        </Wrapper>
                        </Wrapper>
                      </Wrapper>
                  </Wrapper>

            </Wrapper>
            <Wrapper canvas id='random-id-113' is='div' className='flex flex-col  mx-4' style={{ flex: '30%', height: '100%', justifyItems:'stretch' }}>
                  <Wrapper canvas id='random-id-114' is='div' className='h-[100%]'>
                   
                      <Wrapper canvas id='random-id-115' is='div' className='w-full h-[100%] flex flex-col' style={{alignItems:'flex-start', background:'#F7F7F9', borderRadius:'20px'}}>

                        <Wrapper canvas id='random-id-116' is='div' className='w-full min-h-[248px] h-[100%] flex flex-row flex-wrap'>
                          <Wrapper canvas id='random-id-117' is='div' className='w-full h-auto min-w-[100px]' style={{flex:'35%', borderRadius:'20px', maxHeight:'231px'}}>
                            <ImageC className='w-full h-full object-cover' style={{borderRadius:'20px', maxHeight:'240px'}} alt='blog-img' src='https://i.ibb.co/c661S4T/10.jpg' />
                          </Wrapper>
                          <Wrapper canvas id='random-id-118' is='div' className='flex flex-col w-full px-4 my-auto' style={{flex:'60%'}}>
                            <Wrapper canvas id='random-id-119' is='div' className='blog-header my-4' style={{fontFamily:'Poppins'}}>
                              <Text text='Perfect strategy'/>
                            </Wrapper>
                            <Wrapper canvas id='random-id-120' is='div' className='blog-content my-2' style={{fontFamily:'Poppins'}}>
                              <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut'/>
                            </Wrapper>
                            <Wrapper canvas id='random-id-121' is='div' className='blog-button w-full mb-2'>
                            <Button  classn='block' class='blog-button'>
                            <Wrapper canvas id='random-id-12'  is='div' style={{color:'#2479FD', fontFamily:'Poppins'}}>
                              <Text text='Learn More'/>
                            </Wrapper>
                          </Button>
                            </Wrapper>
                          </Wrapper>
                        </Wrapper>

                      </Wrapper>
                  </Wrapper>
                  <Wrapper canvas id='random-id-122' is='div' className='h-[100%]'>
                   
                   <Wrapper canvas id='random-id-123' is='div' className='w-full h-[100%] flex flex-col mt-6' style={{ background:'#F7F7F9', borderRadius:'20px', alignItems:'flex-end'}}>

                     <Wrapper canvas id='random-id-124' is='div' className='w-full min-h-[248px] h-[100%] flex flex-row flex-wrap'>
                       <Wrapper canvas id='random-id-125' is='div' className='w-full h-auto min-w-[100px]' style={{flex:'35%', borderRadius:'20px', maxHeight:'240px'}}>
                         <ImageC className='w-full h-full object-cover' style={{borderRadius:'20px', maxHeight:'231px'}} alt='blog-img' src='https://i.ibb.co/c661S4T/10.jpg' />
                       </Wrapper>
                       <Wrapper canvas id='random-id-126' is='div' className='flex flex-col w-full px-4 my-auto' style={{flex:'60%'}}>
                         <Wrapper canvas id='random-id-127' is='div' className='blog-header my-4' style={{fontFamily:'Poppins'}}>
                          <Text text='Perfect strategy'/>
                         </Wrapper>
                         <Wrapper canvas id='random-id-128' is='div' className='blog-content my-2' style={{fontFamily:'Poppins'}}>
                         <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut'/>
                         </Wrapper>
                         <Wrapper canvas id='random-id-129' is='div' className='blog-button w-full mb-2'>
                         <Button  classn='block' class='blog-button'>
                            <Wrapper canvas id='random-id-13'  is='div' style={{color:'#2479FD', fontFamily:'Poppins'}}>
                              <Text text='Learn More'/>
                            </Wrapper>
                          </Button>
                         </Wrapper>
                       </Wrapper>
                     </Wrapper>

                   </Wrapper>
               </Wrapper>
            </Wrapper>
            
          </Wrapper>
</Wrapper>
        
    </Carousel>
            </Wrapper>
        </Wrapper>
        </Element>
        </Element>
        ,
        <Element canvas is={Parent} id='parentId322211' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId123331' className="w-[100%] h-auto" row={true} >
        <Wrapper canvas id='random-id-14'  is='div' className='py-20 h-full'  style={{width: '100%', background:'#FFFFFF'}}>
            <Wrapper canvas id='random-id-15'  is='div' className='container'>
                <Wrapper canvas id='random-id-16'  is='div' className={`w-full pb-[5rem] ${containerWidth?  'text-center' : ''}`} style={{fontSize:'46px', fontWeight:'bold', fontFamily:'Prompt', color:'#000000'}}>
            <Element canvas style={{display:'inline-block'}} id='random-id-11' is='div'>
                <Text text='Our latest news'/>
            </Element>
        </Wrapper>
        <Carousel
        className='w-full mx-auto'
        controls={false}
        indicators
        >
        <Wrapper canvas id='random-id-17'  is='div' className='w-full h-full flex flex-col'>
          <Wrapper canvas id='random-id-18'  is='div' className='w-full h-full flex flex-row flex-wrap justify-content-between'>
            <Wrapper canvas id='random-id-19'  is='div' className='flex flex-col mx-4 mb-6' style={{ flex: '65%', height: '100%', background:'#FDFDF3', borderRadius:'20px' }}>
                  <Wrapper canvas id='random-id-20'  is='div' className='h-full'>
                      <Wrapper canvas id='random-id-21'  is='div' className='w-full h-full flex flex-col'>
                        <Wrapper canvas id='random-id-22'  is='div' className='flex' style={{flex:'auto', borderRadius:'20px 20px 0px 0px',}}>
                          <ImageC className='w-full h-full object-cover' style={{maxHeight:'220px', borderRadius:'20px 20px 0px 0px'}} alt='blog-img' src='https://i.ibb.co/6ZK1NcZ/9.jpg' />
                        </Wrapper>
                        <Wrapper canvas id='random-id-23'  is='div' className='w-full p-10'>
                        <Wrapper canvas id='random-id-24'  is='div' className='blog-header mt-4' style={{fontFamily:'Prompt'}}>
                          <Text text='Team work - waste of time or perfect plan?'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-25'  is='div' className='blog-content mt-10' style={{fontFamily:'Prompt'}}>
                          <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-26'  is='div' className='blog-button w-full justify-content-end my-4'>
                          <Button  classn='block' class='blog-button'>
                            <Wrapper canvas id='random-id-27'  is='div' style={{color:'#FF0060', fontFamily:'Prompt'}}>
                              <Text text='Learn More'/>
                            </Wrapper>
                          </Button>
                        </Wrapper>
                        </Wrapper>
                      </Wrapper>
                  </Wrapper>

            </Wrapper>
            <Wrapper canvas id='random-id-28'  is='div' className='flex flex-col  mx-4' style={{ flex: '30%', height: '100%', justifyItems:'stretch' }}>
                  <Wrapper canvas id='random-id-29'  is='div' className='h-[100%]'>
                   
                      <Wrapper canvas id='random-id-30'  is='div' className='w-full h-[100%] flex flex-col' style={{alignItems:'flex-start', background:'#FDFDF3', borderRadius:'20px'}}>

                        <Wrapper canvas id='random-id-31'  is='div' className='w-full min-h-[248px] h-[100%] flex flex-row flex-wrap'>
                          <Wrapper canvas id='random-id-32'  is='div' className='w-full h-auto min-w-[100px]' style={{flex:'35%', borderRadius:'20px', maxHeight:'231px'}}>
                            <ImageC className='w-full h-full object-cover' style={{borderRadius:'20px', maxHeight:'240px'}} alt='blog-img' src='https://i.ibb.co/c661S4T/10.jpg' />
                          </Wrapper>
                          <Wrapper canvas id='random-id-33'  is='div' className='flex flex-col w-full px-4 my-auto' style={{flex:'60%'}}>
                            <Wrapper canvas id='random-id-34'  is='div' className='blog-header my-4' style={{fontFamily:'Prompt'}}>
                              <Text text='Perfect strategy'/>
                            </Wrapper>
                            <Wrapper canvas id='random-id-35'  is='div' className='blog-content my-2' style={{fontFamily:'Prompt'}}>
                              <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut'/>
                            </Wrapper>
                            <Wrapper canvas id='random-id-36'  is='div' className='blog-button w-full mb-2'>
                            <Button  classn='block' class='blog-button'>
                            <Wrapper canvas id='random-id-37'  is='div' style={{color:'#FF0060', fontFamily:'Prompt'}}>
                              <Text text='Learn More'/>
                            </Wrapper>
                          </Button>
                            </Wrapper>
                          </Wrapper>
                        </Wrapper>

                      </Wrapper>
                  </Wrapper>
                  <Wrapper canvas id='random-id-38'  is='div' className='h-[100%]'>
                   
                   <Wrapper canvas id='random-id-39'  is='div' className='w-full h-[100%] flex flex-col mt-6' style={{ background:'#FDFDF3', borderRadius:'20px', alignItems:'flex-end'}}>

                     <Wrapper canvas id='random-id-40'  is='div' className='w-full min-h-[248px] h-[100%] flex flex-row flex-wrap'>
                       <Wrapper canvas id='random-id-41'  is='div' className='w-full h-auto min-w-[100px]' style={{flex:'35%', borderRadius:'20px', maxHeight:'240px'}}>
                         <ImageC className='w-full h-full object-cover' style={{borderRadius:'20px', maxHeight:'231px'}} alt='blog-img' src='https://i.ibb.co/c661S4T/10.jpg' />
                       </Wrapper>
                       <Wrapper canvas id='random-id-42'  is='div' className='flex flex-col w-full px-4 my-auto' style={{flex:'60%'}}>
                         <Wrapper canvas id='random-id-43'  is='div' className='blog-header my-4' style={{fontFamily:'Prompt'}}>
                         <Text text='Perfect strategy'/>
                         </Wrapper>
                         <Wrapper canvas id='random-id-44'  is='div' className='blog-content my-2' style={{fontFamily:'Prompt'}}>
                         <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut'/>
                         </Wrapper>
                         <Wrapper canvas id='random-id-45'  is='div' className='blog-button w-full mb-2'>
                         <Button  classn='block' class='blog-button'>
                            <Wrapper canvas id='random-id-46'  is='div' style={{color:'#FF0060', fontFamily:'Prompt'}}>
                              <Text text='Learn More'/>
                            </Wrapper>
                          </Button>
                         </Wrapper>
                       </Wrapper>
                     </Wrapper>

                   </Wrapper>
               </Wrapper>
            </Wrapper>
            
          </Wrapper>
</Wrapper>    
    </Carousel>
            </Wrapper>
        </Wrapper>
        </Element>
</Element>    
,
<Element canvas is={Parent} id='parentId322210' className="w-[100%] h-auto" >
<Element canvas is={Parent} id='parentId123330' className="w-[100%] h-auto" row={true} >
        <Wrapper canvas id='random-id-47'  is='div' className='py-20 h-full'  style={{width: '100%', background:'#FFFFFF'}}>
            <Wrapper canvas id='random-id-48'  is='div' className='container'>
                <Wrapper canvas id='random-id-49'  is='div' className={`w-full pb-[5rem] ${containerWidth?  'text-center' : ''}`} style={{fontSize:'46px', fontWeight:'bold', fontFamily:'Almarai', color:'#000000'}}>
            <Element canvas style={{display:'inline-block'}} id='random-id-21' is='div'>
                <Text text='Our latest news'/>
            </Element>
        </Wrapper>
        <Carousel
        className='w-full mx-auto caro3'
        controls={false}
        indicators
        >

        <Wrapper canvas id='random-id-50'  is='div' className='w-full h-full flex flex-col'>
          <Wrapper canvas id='random-id-51'  is='div' className='w-full h-full flex flex-row flex-wrap justify-content-between'>
            <Wrapper canvas id='random-id-52'  is='div' className='flex flex-col mx-4 mb-6' style={{ flex: '65%', height: '100%', background:'#FDF4F4', borderRadius:'20px' }}>
                  <Wrapper canvas id='random-id-53'  is='div' className='h-full'>
                      <Wrapper canvas id='random-id-54'  is='div' className='w-full h-full flex flex-col'>
                        <Wrapper canvas id='random-id-55'  is='div' className='flex' style={{flex:'auto', borderRadius:'20px 20px 0px 0px',}}>
                          <ImageC className='w-full h-full object-cover' style={{maxHeight:'220px', borderRadius:'20px 20px 0px 0px'}} alt='blog-img' src='https://i.ibb.co/6ZK1NcZ/9.jpg' />
                        </Wrapper>
                        <Wrapper canvas id='random-id-56'  is='div' className='w-full p-10'>
                        <Wrapper canvas id='random-id-57'  is='div' className='blog-header mt-4' style={{fontFamily:'Almarai'}}>
                          <Text text='Team work - waste of time or perfect plan?'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-58'  is='div' className='blog-content mt-10' style={{fontFamily:'Almarai'}}>
                          <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-59'  is='div' className='blog-button w-full justify-content-end my-4'>
                          <Button  classn='block' class='blog-button'>
                            <Wrapper canvas id='random-id-60'  is='div' style={{color:'#F31559', fontFamily:'Almarai'}}>
                              <Text text='Learn More'/>
                            </Wrapper>
                          </Button>
                        </Wrapper>
                        </Wrapper>
                      </Wrapper>
                  </Wrapper>

            </Wrapper>
            <Wrapper canvas id='random-id-61'  is='div' className='flex flex-col  mx-4' style={{ flex: '30%', height: '100%', justifyItems:'stretch' }}>
                  <Wrapper canvas id='random-id-62'  is='div' className='h-[100%]'>
                   
                      <Wrapper canvas id='random-id-63'  is='div' className='w-full h-[100%] flex flex-col' style={{alignItems:'flex-start', background:'#FDF4F4', borderRadius:'20px'}}>

                        <Wrapper canvas id='random-id-64'  is='div' className='w-full min-h-[248px] h-[100%] flex flex-row flex-wrap'>
                          <Wrapper canvas id='random-id-65'  is='div' className='w-full h-auto min-w-[100px]' style={{flex:'35%', borderRadius:'20px', maxHeight:'231px'}}>
                            <ImageC className='w-full h-full object-cover' style={{borderRadius:'20px', maxHeight:'240px'}} alt='blog-img' src='https://i.ibb.co/c661S4T/10.jpg' />
                          </Wrapper>
                          <Wrapper canvas id='random-id-66'  is='div' className='flex flex-col w-full px-4 my-auto' style={{flex:'60%'}}>
                            <Wrapper canvas id='random-id-67'  is='div' className='blog-header my-4' style={{fontFamily:'Almarai'}}>
                              <Text text='Perfect strategy'/>
                            </Wrapper>
                            <Wrapper canvas id='random-id-68'  is='div' className='blog-content my-2' style={{fontFamily:'Almarai'}}>
                              <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut'/>
                            </Wrapper>
                            <Wrapper canvas id='random-id-69'  is='div' className='blog-button w-full mb-2'>
                            <Button  classn='block' class='blog-button'>
                            <Wrapper canvas id='random-id-70'  is='div' style={{color:'#F31559', fontFamily:'Almarai'}}>
                              <Text text='Learn More'/>
                            </Wrapper>
                          </Button>
                            </Wrapper>
                          </Wrapper>
                        </Wrapper>

                      </Wrapper>
                  </Wrapper>
                  <Wrapper canvas id='random-id-71'  is='div' className='h-[100%]'>
                   
                   <Wrapper canvas id='random-id-72'  is='div' className='w-full h-[100%] flex flex-col mt-6' style={{ background:'#FDF4F4', borderRadius:'20px', alignItems:'flex-end'}}>

                     <Wrapper canvas id='random-id-73'  is='div' className='w-full min-h-[248px] h-[100%] flex flex-row flex-wrap'>
                       <Wrapper canvas id='random-id-74'  is='div' className='w-full h-auto min-w-[100px]' style={{flex:'35%', borderRadius:'20px', maxHeight:'240px'}}>
                         <ImageC className='w-full h-full object-cover' style={{borderRadius:'20px', maxHeight:'231px'}} alt='blog-img' src='https://i.ibb.co/c661S4T/10.jpg' />
                       </Wrapper>
                       <Wrapper canvas id='random-id-75'  is='div' className='flex flex-col w-full px-4 my-auto' style={{flex:'60%'}}>
                         <Wrapper canvas id='random-id-76'  is='div' className='blog-header my-4' style={{fontFamily:'Almarai'}}>
                          <Text text='Perfect strategy'/>
                         </Wrapper>
                         <Wrapper canvas id='random-id-77'  is='div' className='blog-content my-2' style={{fontFamily:'Almarai'}}>
                         <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut'/>
                         </Wrapper>
                         <Wrapper canvas id='random-id-78'  is='div' className='blog-button w-full mb-2'>
                         <Button  classn='block' class='blog-button'>
                            <Wrapper canvas id='random-id-79'  is='div' style={{color:'#F31559', fontFamily:'Almarai'}}>
                              <Text text='Learn More'/>
                            </Wrapper>
                          </Button>
                         </Wrapper>
                       </Wrapper>
                     </Wrapper>

                   </Wrapper>
               </Wrapper>
            </Wrapper>
            
          </Wrapper>
</Wrapper>
        
        
    </Carousel>
            </Wrapper>
        </Wrapper>
        </Element>
        </Element>,
        <Element canvas is={Parent} id='parentId3222111' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId12333000' className="w-[100%] h-auto" row={true} >
        <Wrapper canvas id='random-id-80'  is='div' className='py-20 h-full'  style={{width: '100%', background:'#FFFFFF'}}>
            <Wrapper canvas id='random-id-81'  is='div' className='container'>
                <Wrapper canvas id='random-id-82'  is='div' className={`w-full pb-[5rem] ${containerWidth?  'text-center' : ''}`} style={{fontSize:'46px', fontWeight:'bold', fontFamily:'Chivo', color:'#000000'}}>
            <Element canvas style={{display:'inline-block'}} id='random-id-21' is='div'>
                <Text text='Our latest news'/>
            </Element>
        </Wrapper>
        <Carousel
        className='w-full mx-auto caro4'
        controls={false}
        indicators
        >
        <Wrapper canvas id='random-id-83'  is='div' className='w-full h-full flex flex-col'>
          <Wrapper canvas id='random-id-84'  is='div' className='w-full h-full flex flex-row flex-wrap justify-content-between'>
            <Wrapper canvas id='random-id-85'  is='div' className='flex flex-col mx-4 mb-6' style={{ flex: '65%', height: '100%', background:'#F6F2FA', borderRadius:'20px' }}>
                  <Wrapper canvas id='random-id-86'  is='div' className='h-full'>
                      <Wrapper canvas id='random-id-87'  is='div' className='w-full h-full flex flex-col'>
                        <Wrapper canvas id='random-id-88'  is='div' className='flex' style={{flex:'auto', borderRadius:'20px 20px 0px 0px',}}>
                          <ImageC className='w-full h-full object-cover' style={{maxHeight:'220px', borderRadius:'20px 20px 0px 0px'}} alt='blog-img' src='https://i.ibb.co/6ZK1NcZ/9.jpg' />
                        </Wrapper>
                        <Wrapper canvas id='random-id-89'  is='div' className='w-full p-10'>
                        <Wrapper canvas id='random-id-90'  is='div' className='blog-header mt-4' style={{fontFamily:'Chivo'}}>
                          <Text text='Team work - waste of time or perfect plan?'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-91'  is='div' className='blog-content mt-10' style={{fontFamily:'Chivo'}}>
                          <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'/>
                        </Wrapper>
                        <Wrapper canvas id='random-id-92'  is='div' className='blog-button w-full justify-content-end my-4'>
                          <Button  classn='block' class='blog-button'>
                            <Wrapper canvas id='random-id-93'  is='div' style={{color:'#0C134F', fontFamily:'Chivo'}}>
                              <Text text='Learn More'/>
                            </Wrapper>
                          </Button>
                        </Wrapper>
                        </Wrapper>
                      </Wrapper>
                  </Wrapper>

            </Wrapper>
            <Wrapper canvas id='random-id-94'  is='div' className='flex flex-col  mx-4' style={{ flex: '30%', height: '100%', justifyItems:'stretch' }}>
                  <Wrapper canvas id='random-id-95'  is='div' className='h-[100%]'>
                   
                      <Wrapper canvas id='random-id-96'  is='div' className='w-full h-[100%] flex flex-col' style={{alignItems:'flex-start', background:'#F6F2FA', borderRadius:'20px'}}>

                        <Wrapper canvas id='random-id-97'  is='div' className='w-full min-h-[248px] h-[100%] flex flex-row flex-wrap'>
                          <Wrapper canvas id='random-id-98'  is='div' className='w-full h-auto min-w-[100px]' style={{flex:'35%', borderRadius:'20px', maxHeight:'231px'}}>
                            <ImageC className='w-full h-full object-cover' style={{borderRadius:'20px', maxHeight:'240px'}} alt='blog-img' src='https://i.ibb.co/c661S4T/10.jpg' />
                          </Wrapper>
                          <Wrapper canvas id='random-id-99'  is='div' className='flex flex-col w-full px-4 my-auto' style={{flex:'60%'}}>
                            <Wrapper canvas id='random-id-100'  is='div' className='blog-header my-4' style={{fontFamily:'Chivo'}}>
                              <Text text='Perfect strategy'/>
                            </Wrapper>
                            <Wrapper canvas id='random-id-10100'  is='div' className='blog-content my-2' style={{fontFamily:'Chivo'}}>
                              <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut'/>
                            </Wrapper>
                            <Wrapper canvas id='random-id-102'  is='div' className='blog-button w-full mb-2'>
                            <Button  classn='block' class='blog-button'>
                            <Wrapper canvas id='random-id-103'  is='div' style={{color:'#0C134F', fontFamily:'Chivo'}}>
                              <Text text='Learn More'/>
                            </Wrapper>
                          </Button>
                            </Wrapper>
                          </Wrapper>
                        </Wrapper>

                      </Wrapper>
                  </Wrapper>
                  <Wrapper canvas id='random-id-104'  is='div' className='h-[100%]'>
                   
                   <Wrapper canvas id='random-id-105'  is='div' className='w-full h-[100%] flex flex-col mt-6' style={{ background:'#F6F2FA', borderRadius:'20px', alignItems:'flex-end'}}>

                     <Wrapper canvas id='random-id-106'  is='div' className='w-full min-h-[248px] h-[100%] flex flex-row flex-wrap'>
                       <Wrapper canvas id='random-id-107'  is='div' className='w-full h-auto min-w-[100px]' style={{flex:'35%', borderRadius:'20px', maxHeight:'240px'}}>
                         <ImageC className='w-full h-full object-cover' style={{borderRadius:'20px', maxHeight:'231px'}} alt='blog-img' src='https://i.ibb.co/c661S4T/10.jpg' />
                       </Wrapper>
                       <Wrapper canvas id='random-id-108'  is='div' className='flex flex-col w-full px-4 my-auto' style={{flex:'60%'}}>
                         <Wrapper canvas id='random-id-1090'  is='div' className='blog-header my-4' style={{fontFamily:'Chivo'}}>
                          <Text text='Perfect strategy'/>
                         </Wrapper>
                         <Wrapper canvas id='random-id-1100'  is='div' className='blog-content my-2' style={{fontFamily:'Chivo'}}>
                         <Text text='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut'/>
                         </Wrapper>
                         <Wrapper canvas id='random-id-1101'  is='div' className='blog-button w-full mb-2'>
                         <Button  classn='block' class='blog-button'>
                            <Wrapper canvas id='random-id-1102'  is='div' style={{color:'#0C134F', fontFamily:'Chivo'}}>
                              <Text text='Learn More'/>
                            </Wrapper>
                          </Button>
                         </Wrapper>
                       </Wrapper>
                     </Wrapper>

                   </Wrapper>
               </Wrapper>
            </Wrapper>
            
          </Wrapper>
</Wrapper>
        
        
    </Carousel>
            </Wrapper>
        </Wrapper>
        </Element>
        </Element>,
    ]

    
    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
      setProp((prop) => {
        setSel(100);
      }, 1000);

      setTimeout(() => {
        setProp((prop) => {
          setSel(number)
        }, 1000);
      }, 100);


      
  }

  useEffect(() => {
    if(selected || selected >= 0) {
      makeOdd(selected);
    }
  },[selected])
    
    return (
      <div style={{ padding: enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
       {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
  </div>
  );

}

    Blog_3.craft = {
        displayName: "Blog 3",
        props: {
        selected: 1, // here is the default value of the selected style
        length:9,
isBlock: true
        },
        rules: {
            canDrag: () => true,
            canDrop: () => true,
        },
        related: {
            toolbar: NewBlogSettings,
        },
    };



    