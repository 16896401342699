/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { HerIcon, HisIcon } from '../Svg'
import './whowe.css'

export default function WhoWe() {
    return (
        <div className='w-ful h-full container py-40'>
            <div className='who-we'>
            <div className='header-whowe mb-[5%]'>Who are we?</div>
            <div className='first-col mb-[3%]'>
                <div className='text'>AYCC comes from a team of visionaries who invested in translating complex web structures into accessible and manageable workflows for everyone.
                    We believe that business models can be driven with simple steps and creativity, 
                    delving into a new digital era where barriers are old memories from the past.</div>
                <div className='w-[50%] flex justify-center'><HerIcon/></div>
            </div>
            <div className='second-col'>
                <div className='w-[50%] flex justify-center'><HisIcon/></div>
                <div className='text'>More than 5 years ago we asked ourselves: “How might we empower users to create virtual structures without the need for extensive knowledge of coding and technology degrees?”
                <div className='inline-block'>Today, with patience, motivation, and a bit of madness, we can offer a powerful instrument to write our digital revolution through simple drag-and-drop executions.</div>
                </div>
            </div>
            </div>
        </div>
    )
}