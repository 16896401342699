import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { WrapperSettings } from '../wrapper/WrapperSettings';
import styled, { keyframes, css } from 'styled-components';
import { ROOT_NODE } from '@craftjs/utils';
import { useDarkMode } from '../../../DarkModeContext';
import SidebarContext from '../../../SidebarShown/SidebarContext';
import { SettingsIcon } from '../../editor/Viewport/Pop-up/svgArray';


const defaultProps = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  fillSpace: 'no',
  background: { r: 255, g: 255, b: 255, a: 1 },
  color: { r: 0, g: 0, b: 0, a: 1 },
  shadow: 0,
  radius: 0,
};

const IndicatorDiv = styled.div`
  position: absolute;
  z-index: 0;
  color: white;
  white-space: nowrap;
`;


export const ResizableWrapper = (props) => {

  const { id } = useNode();

  const { actions, query, isActive, selected, enabled, nodeObj, connectors, actions: { setOptions, selectNode }, } = useEditor((state, query) => ({
    isActive: query.getEvent('selected').contains(id),

    nodeObj: state,
    enabled: state.options.enabled,
    selected: state.events.selected,
  }));

  const {
    isHover,
    dom,
    name,
    moveable,
    deletable,
    connectors: { drag, connect },
    parent,
    node,
    actions: { setProp }
  } = useNode((node) => ({
    isHover: node.events.hovered,
    dom: node.dom,
    name: node.data.props.nameOf || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    node: node,
    isHover: node.events.hovered,
  }));


  const combinedProps = { ...defaultProps, ...props };
  const { children, style } = combinedProps;


  const resizableRef = useRef(null);
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState('100%');
  const [activeBackground, setActiveBackground] = useState(false);
  const { isDarkMode, smartMenu, setSmartMenu } = useDarkMode();
  const { setPopUp } = useContext(SidebarContext)

  const [animateBorder, setAnimateBorder] = useState('')

  const setRef = useCallback((node) => {
    if (resizableRef.current !== node) {
      if (node) {
        connect(node);
      }
      resizableRef.current = node;
    }
  }, []);

  // Handle width and height resizing
  const handleResize = (e) => {
    setAnimateBorder(true)

    const rect = resizableRef.current.getBoundingClientRect();
    const newWidth = e.clientX - rect.left;
    const newHeight = e.clientY - rect.top;

    if (newWidth > 0) setWidth(`${newWidth}px`);
    if (newHeight > 0) setHeight(`${newHeight}px`);
  };

  let element = Array.from(node)

  const startCornerResize = (e) => {
    e.stopPropagation()

    setActiveBackground(true);
    const handleMouseMove = (event) => handleResize(event);

    const handleMouseUp = () => {
      setActiveBackground(false);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };


  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };



  return (
    <>

      <div
        className={`${enabled && 'helloworldsParent'}`}
        ref={setRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        id={"helloworldsofpel"}
        style={{
          position: 'relative',
          width: width,
          maxWidth: '100%',
          maxHeight: '100%',
          height: height,
          background: activeBackground ? "green" : "transparent",
          border: isActive && '1px solid #e9e9e9',
        }}
      >
        <IndicatorDiv
          style={{
            display: enabled && isHovered ? 'flex' : isActive ? 'flex' : 'none',
            position: 'absolute',
            top: '-6.8vmin',
            left: '0',
            zIndex: 9999,
            width: 'fit-content'
          }}
        >
          <div
            style={{
              position: "relative",
              padding: '.4vmin',
              maxWidth: '35vmin',
              borderRadius: "1vmin",
              overflow: "hidden"
            }}
            id="EditRoot"
          >
            <div
              style={{
                padding: '0 1vmin',
                borderRadius: '1vmin'
              }}
              className={`border-solid border-[#e9e9ea] shadow-sm ${isDarkMode ? 'bg-[#111F3E]' : 'bg-white'} flex flex-row justify-around gap-2 h-[fit-content] shrink-0 items-center`}
            >
              {name === "Row" && (
                <div
                  className="flex flex-row h-10 justify-center items-center w-[40%]"
                  id="Button1"
                >
                  <div
                    onClick={() => {
                      setProp((prop) => {
                        if (prop.rows > 1) {
                          prop.rows -= 1;
                        } else {
                          prop.rows = 1;
                        }
                      }, 500);
                    }}
                    className={` ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} hover:bg-[#0867fc20] flex flex-col justify-center w-auto shrink-0 h-[fit-content] text-[#989191] flex items-center justify-center text-center cursor-pointer  px-[.5vmin] items-center rounded-[30px]`}
                    id="IconButton"
                    title="Remove Row"
                  >
                    -
                  </div>
                  <div
                    title="Block Name"
                    style={{ width: 'fit-content', fontSize: '2vmin', userSelect: 'none' }}
                    className="py-[5%] rounded-[2.8Vmin] flex mx-auto"
                    id="Label"
                  >
                    <div className={`border-2 border-[#000] item-center justify-center text-center font-['Inter'] leading-[1.5vmin] ${isDarkMode ? 'text-[#FFFFFF]' : 'text-[#989191]'}`}>
                      {name}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setProp((prop) => {
                        if (prop.rows < 6) {
                          prop.rows += 1;
                        } else {
                          prop.rows = 6;
                        }
                      }, 500);
                    }}
                    className={` ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} hover:bg-[#0867fc20] flex flex-col justify-center w-auto shrink-0 h-[fit-content] text-[#989191] flex items-center justify-center text-center cursor-pointer  px-[.5vmin] items-center rounded-[30px]`}
                    id="IconButton"
                    title="Add Row"
                  >
                    +
                  </div>
                </div>
              )}

              {name === "Column" && (
                <div className="flex flex-row justify-between w-[fit-content]">

                  <div
                    className="flex flex-row h-10 justify-center items-center w-[fit-content] mx-[1vmin]"
                    id="Button1"
                  >
                    <div
                      onClick={() => {
                        setProp((prop) => {
                          if (prop.columns > 1) {
                            prop.columns -= 1;
                          } else {
                            prop.columns = 1;
                          }
                        }, 500);
                      }}
                      className={` ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} hover:bg-[#0867fc20] flex flex-col justify-center w-auto shrink-0 h-[fit-content] text-[#989191] flex items-center justify-center text-center cursor-pointer  px-[.5vmin] items-center rounded-[30px]`}
                      id="IconButton"
                      title="Add Column"
                    >
                      -
                    </div>

                    <div
                      title="Block Name"
                      style={{ width: 'fit-content', fontSize: '2vmin', userSelect: 'none' }}
                      className="py-[5%] rounded-[2.8Vmin] flex mx-[.5vmin]"
                      id="Label"
                    >
                      <div className={` item-center justify-center text-center font-['Inter'] leading-[1.5vmin] ${isDarkMode ? 'text-[#FFFFFF]' : 'text-[#989191]'}`}>
                        {name}
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setProp((prop) => {
                          if (prop.columns < 6) {
                            prop.columns += 1;
                          } else {
                            prop.columns = 6;
                          }
                        }, 500);
                      }}
                      className={` ${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} hover:bg-[#0867fc20] flex flex-col justify-center w-auto shrink-0 h-[fit-content] text-[#989191] flex items-center justify-center text-center cursor-pointer  px-[.5vmin] items-center rounded-[30px]`}
                      id="IconButton"
                      title="Add Column"
                    >
                      +
                    </div>
                  </div>
                </div>

              )}
              {name !== 'Row' && name !== 'Column' && <div
                title="Block Name"
                style={{ width: '30%', fontSize: '2vmin', userSelect: 'none' }}
                className="py-[5%] rounded-[2.8Vmin] flex mx-auto"
                id="Label"
              >
                <div className={` item-center mx-auto justify-center text-center font-['Inter'] leading-[1.5vmin] ${isDarkMode ? 'text-[#FFFFFF]' : 'text-[#989191]'}`}>
                  {name}
                </div>
              </div>}

              {id !== ROOT_NODE && (
                <div
                  style={{ cursor: "pointer", margin: '.5vmin' }}
                  className={`${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} hover:bg-[#0867fc20] flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]`}
                  id="IconButton"
                  title="Parent"
                >
                  <img
                    alt="icons"
                    src="https://file.rendit.io/n/mdW1kcmagl4wSDj50KGA.svg"
                    className="w-6"
                    id="Icons"
                  />
                </div>
              )}

              {moveable && (
                <div
                  style={{ cursor: "pointer", margin: '.5vmin' }}
                  ref={drag}
                  className={`${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} hover:bg-[#0867fc20] flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]`}
                  id="IconButton2"
                >
                  <img
                    alt="icons"
                    src="https://file.rendit.io/n/fakTTDLEhANgdwzPj8EN.svg"
                    className="w-6"
                    id="Icons2"
                    title="Move Item"
                  />
                </div>
              )}

              {deletable && (
                <div
                  style={{ cursor: "pointer", margin: '.5vmin' }}
                  onMouseDown={(e) => {
                    actions.delete(id);
                  }}
                  className={`${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} hover:bg-[#0867fc20] flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]`}
                  id="IconButton3"
                  title="Delete"
                >
                  <img
                    alt="icons"
                    src="https://file.rendit.io/n/XM9ibrjLzeBNTJtQgfyk.svg"
                    className="w-6"
                    id="Icons3"
                  />
                </div>
              )}

              {node?.data?.props?.['isNavBar'] && (
                <div
                  style={{ cursor: "pointer", margin: '.5vmin' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setPopUp(true);
                  }}
                  className={`${isDarkMode ? 'bg-[#0867FC4D]' : 'bg-[#e6f0ff]'} hover:bg-[#0867fc20] flex flex-col justify-center w-12 shrink-0 h-12 items-center rounded-[30px]`}
                  id="IconButton3"
                  title="Info"
                >
                  <img
                    alt="icons"
                    src="https://i.ibb.co/7v5sPmm/icons8-info-50.png"
                    className="w-6"
                    id="Icons3"
                  />
                </div>
              )}
            </div>
          </div>
        </IndicatorDiv>

        {/* Corner resize handle */}
        <div
          onMouseDown={startCornerResize}
          style={{
            position: 'absolute',
            right: '0',
            bottom: '0',
            width: '10px',
            height: '10px',
            cursor: 'se-resize',
            zIndex: 3,
            userSelect: 'none',
            background: 'transparent',
          }}
        />

        {/* Corner dots */}
        <div className="corner-dot top-left" style={{ cursor: 'nw-resize' }} onMouseDown={startCornerResize} />
        <div className="corner-dot top-right" style={{ cursor: 'ne-resize' }} onMouseDown={startCornerResize} />
        <div className="corner-dot bottom-left" style={{ cursor: 'sw-resize' }} onMouseDown={startCornerResize} />
        <div className="corner-dot bottom-right" style={{ cursor: 'se-resize', background: 'green' }} onMouseDown={startCornerResize} />



        {React.Children.map(children, (child, index) => 
        React.cloneElement(child, {
          key: index,
          onClick: () => setSmartMenu(id),
        })
      )}
      </div>
    </>
  );
};

ResizableWrapper.craft = {
  displayName: 'Resizable',
  props: defaultProps,
  // rules: {
  //   canDrag: () => true,
  //   canDrop: () => true,
  // },
  // related: {
  //   toolbar: WrapperSettings,
  // },
};
