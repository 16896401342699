/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState, useRef, useEffect } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import Text from '../../basic/Text';
import { NewBannerSettings } from './newBannerSetting';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AbcIcon from '@mui/icons-material/Abc';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive';
import AlbumIcon from '@mui/icons-material/Album';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { ImageC } from '../../basic/Image';
import Button  from '../../basic/Button';

import { AddToPhoto } from '../../../editor/Viewport/EditorSvg';
import { Parent } from '../../Parent/index.js';
import { BounceLoader } from 'react-spinners';
import { Wrapper } from '../../wrapper/index.js';
import { SvgIcon } from '../../basic/Svg';

export const Banner_4 = ({ selected }) => {

    const {
        actions: { setProp },
        connectors: {
            connect
        }
    } = useNode();

    const {
        enabled,

    } = useEditor((state, query) => ({
        enabled: state.options.enabled,

    }));

    const styles = [

        <Element canvas is={Parent} id='parentId2200' className="w-[100%] h-auto bg-[#2479FD] py-10" >
            <Element canvas is={Parent} id='parentId2300' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-400' className='w-[100%] h-full max-w-[1300px] flex flex-row flex-wrap m-4' style={{ borderRadius: '0px 30px 30px 30px' }} >
                    <Element is={Parent} canvas id='random-id-402' style={{ flex: '55%' }} className=' min-w-[330px] h-full flex flex-col'>
                        <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Poppins', color: '#FFFFFF' }} className={`text-[60px] pt-10`} id='random-id-40101'  >
                            <Text text='Understand User Flow' />
                        </Element>
                        <Element is={Parent} canvas id='random-id-403' className={`flex flex-row my-10 `}>
                            <Element is={Parent} canvas id='random-id-404' className={`w-[100px] h-[100px] bg-[#FFC92E] m-2 flex`} style={{ borderRadius: '14px', justifyContent: 'center', alignItems: 'center' }}>
                                <Element is={Parent} canvas id='random-id-405' className='flex justify-center align-items-center' style={{ color: '#312ECB' }}>
                                    <SvgIcon width='60px' height='60px' viewBox='0 0 50 39' fill='#007AFF' path='M16.5 2h21.08L22.083 24.973H1zM17.422 27.102 11.42 36H33.5L49 13.027H32.702l-9.496 14.075z' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-406' className={`w-[100px] h-[100px] bg-[#FFC92E] m-2 flex`} style={{ borderRadius: '14px', justifyContent: 'center', alignItems: 'center' }}>
                                <Element is={Parent} canvas id='random-id-407' className='flex justify-center align-items-center' style={{ color: '#FF7917' }}>
                                    <SvgIcon width='60px' height='60px' viewBox="0 0 73 49" fill='#A992DB' path='M46.867 24c0 12.426-10.073 22.5-22.5 22.5S1.867 36.426 1.867 24s10.074-22.5 22.5-22.5 22.5 10.074 22.5 22.5M71.132 24c0 12.426-9.975 22.5-22.28 22.5-12.304 0-22.279-10.074-22.279-22.5s9.975-22.5 22.28-22.5c12.304 0 22.28 10.074 22.28 22.5M36.67 42.842C42.81 38.824 46.867 31.886 46.867 24S42.811 9.176 36.67 5.159A22.54 22.54 0 0 0 26.573 24 22.54 22.54 0 0 0 36.67 42.842' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-408' className={`w-[100px] h-[100px] bg-[#FFC92E] m-2 flex`} style={{ borderRadius: '14px', justifyContent: 'center', alignItems: 'center' }}>
                                <Element is={Parent} canvas id='random-id-409' className='flex justify-center align-items-center' style={{ color: '#61459C' }}>
                                    <SvgIcon width='60px' height='60px' viewBox="0 0 49 48" fill='#FF7917' path='M1.984 29.29a17.21 17.21 0 0 1 17.21-17.21v17.21zM1.984 29.29a17.21 17.21 0 0 0 17.21 17.21V29.29zM36.404 29.29a17.21 17.21 0 0 1-10.624 15.9 17.2 17.2 0 0 1-6.586 1.31V29.29zM47.016 14.422c0 7.137-5.786 12.922-12.922 12.922H21.172V14.422c0-7.137 5.785-12.922 12.922-12.922 7.136 0 12.922 5.785 12.922 12.922' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-410' className={`w-[100px] h-[100px] bg-[#FFC92E] m-2 flex`} style={{ borderRadius: '14px', justifyContent: 'center', alignItems: 'center' }}>
                                <Element is={Parent} canvas id='random-id-411' className='flex justify-center align-items-center' style={{ color: '#17CF97' }}>
                                    <SvgIcon width='60px' height='60px' viewBox="0 0 49 48" fill='#17CF97' path='M24.5 12.75C24.5 18.963 19.463 24 13.25 24H2V12.75C2 6.537 7.037 1.5 13.25 1.5S24.5 6.537 24.5 12.75m0 22.5C24.5 29.037 29.537 24 35.75 24H47v11.25c0 6.213-5.037 11.25-11.25 11.25S24.5 41.463 24.5 35.25m-22.5 0C2 41.463 7.037 46.5 13.25 46.5H24.5V35.25C24.5 29.037 19.463 24 13.25 24S2 29.037 2 35.25m45-22.5C47 6.537 41.963 1.5 35.75 1.5H24.5v11.25C24.5 18.963 29.537 24 35.75 24S47 18.963 47 12.75' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '400', fontFamily: 'Poppins', color: '#FFFFFF', lineHeight: '28.8px' }} className={`text-[18px]`} id='random-id-53212'  >
                            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-412' className=' py-6 h-[100%]  min-h-[380px] flex flex-col mx-auto relative' style={{ borderRadius: '20px', flex: '40%' }}>
                        <Element is={Parent} canvas style={{ width: '100%', height: '100%', display: 'inline-block' }} id='random-id-122912'  >
                            <ImageC className='w-[100%] h-[100%] object-cover' style={{ borderRadius: '20px' }} src='https://i.ibb.co/gW0hkRZ/sample3banner.jpg' />
                        </Element>
                        <Element is={Parent} canvas id="random-id-201201921" className='w-full h-[fit-content] justify-end items-center absolute right-[5%] justify-end flex items-end mx-auto bottom-[5%]' style={{ borderRadius: '14px' }}>
                            <Button classn='block' class='w-[280px] h-[60px] py-[11px] my-auto bg-[#FFC92E] hover:bg-[#F6FD00] flex justify-center cursor-pointer ' style={{ borderRadius: '14px' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '500px', fontFamily: 'Poppins', color: '#000000' }} id='random-id-61212'  >
                                    <Text text='Get Started' />
                                </Element>
                                {/* <div className='mx-2 my-auto'>
                                <ArrowCircleRightOutlinedIcon sx={{color:'#000000', fontSize:'28px'}}/>
                            </div> */}
                            </Button>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto bg-[#FF0060] py-10" >
            <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-413' className='w-[100%] h-full max-w-[1300px] flex flex-row flex-wrap m-4' style={{ borderRadius: '0px 30px 30px 30px' }} >
                    <Element is={Parent} canvas id='random-id-415' style={{ flex: '55%' }} className=' min-w-[330px] h-full flex flex-col'>
                        <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Poppins', color: '#FFFFFF' }} className={`text-[60px] pt-10`} id='random-id-4'  >
                            <Text text='Understand User Flow' />
                        </Element>
                        <Element is={Parent} canvas id='random-id-416' className={`flex flex-row my-10 `}>
                            <Element is={Parent} canvas id='random-id-417' className={`w-[100px] h-[100px] bg-[#F6FA70] m-2 flex`} style={{ borderRadius: '14px', justifyContent: 'center', alignItems: 'center' }}>
                                <Element is={Parent} canvas id='random-id-418' className='flex justify-center align-items-center' style={{ color: '#312ECB' }}>
                                    <SvgIcon width='60px' height='60px' viewBox='0 0 50 39' fill='#007AFF' path='M16.5 2h21.08L22.083 24.973H1zM17.422 27.102 11.42 36H33.5L49 13.027H32.702l-9.496 14.075z' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-419' className={`w-[100px] h-[100px] bg-[#F6FA70] m-2 flex`} style={{ borderRadius: '14px', justifyContent: 'center', alignItems: 'center' }}>
                                <Element is={Parent} canvas id='random-id-420' className='flex justify-center align-items-center' style={{ color: '#FF7917' }}>
                                    <SvgIcon width='60px' height='60px' viewBox="0 0 73 49" fill='#FF7917' path='M46.867 24c0 12.426-10.073 22.5-22.5 22.5S1.867 36.426 1.867 24s10.074-22.5 22.5-22.5 22.5 10.074 22.5 22.5M71.132 24c0 12.426-9.975 22.5-22.28 22.5-12.304 0-22.279-10.074-22.279-22.5s9.975-22.5 22.28-22.5c12.304 0 22.28 10.074 22.28 22.5M36.67 42.842C42.81 38.824 46.867 31.886 46.867 24S42.811 9.176 36.67 5.159A22.54 22.54 0 0 0 26.573 24 22.54 22.54 0 0 0 36.67 42.842' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-421' className={`w-[100px] h-[100px] bg-[#F6FA70] m-2 flex`} style={{ borderRadius: '14px', justifyContent: 'center', alignItems: 'center' }}>
                                <Element is={Parent} canvas id='random-id-422' className='flex justify-center align-items-center' style={{ color: '#61459C' }}>
                                    <SvgIcon width='60px' height='60px' viewBox="0 0 49 48" fill='#61459C' path='M1.984 29.29a17.21 17.21 0 0 1 17.21-17.21v17.21zM1.984 29.29a17.21 17.21 0 0 0 17.21 17.21V29.29zM36.404 29.29a17.21 17.21 0 0 1-10.624 15.9 17.2 17.2 0 0 1-6.586 1.31V29.29zM47.016 14.422c0 7.137-5.786 12.922-12.922 12.922H21.172V14.422c0-7.137 5.785-12.922 12.922-12.922 7.136 0 12.922 5.785 12.922 12.922' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-423' className={`w-[100px] h-[100px] bg-[#F6FA70] m-2 flex`} style={{ borderRadius: '14px', justifyContent: 'center', alignItems: 'center' }}>
                                <Element is={Parent} canvas id='random-id-424' className='flex justify-center align-items-center' style={{ color: '#17CF97' }}>
                                    <SvgIcon width='60px' height='60px' viewBox="0 0 49 48" fill='#FF7917' path='M24.5 12.75C24.5 18.963 19.463 24 13.25 24H2V12.75C2 6.537 7.037 1.5 13.25 1.5S24.5 6.537 24.5 12.75m0 22.5C24.5 29.037 29.537 24 35.75 24H47v11.25c0 6.213-5.037 11.25-11.25 11.25S24.5 41.463 24.5 35.25m-22.5 0C2 41.463 7.037 46.5 13.25 46.5H24.5V35.25C24.5 29.037 19.463 24 13.25 24S2 29.037 2 35.25m45-22.5C47 6.537 41.963 1.5 35.75 1.5H24.5v11.25C24.5 18.963 29.537 24 35.75 24S47 18.963 47 12.75' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '400', fontFamily: 'Poppins', color: '#FFFFFF', lineHeight: '28.8px' }} className={`text-[18px]`} id='random-id-5'  >
                            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-425' className=' py-6 h-[100%]  min-h-[380px] flex flex-col mx-auto relative' style={{ borderRadius: '20px', flex: '40%' }}>
                        <Element is={Parent} canvas style={{ width: '100%', height: '100%', display: 'inline-block' }} id='random-id-101'  >
                            <ImageC className='w-[100%] h-[100%] object-cover' style={{ borderRadius: '20px' }} src='https://i.ibb.co/Zg8BP8c/unsplash-5a-GUy-CW-PJw.png' />
                        </Element>
                        <Element is={Parent} canvas id="random-id-201" className='w-full h-[fit-content] justify-end items-center absolute right-[5%] justify-end flex items-end mx-auto bottom-[5%]' style={{ borderRadius: '14px' }}>
                            <Button classn='block' class='w-[280px] h-[60px] py-[11px] my-auto bg-[#F6FA70] hover:bg-[#F6FD00] flex justify-center cursor-pointer ' style={{ borderRadius: '14px' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '500px', fontFamily: 'Poppins', color: '#000000' }} id='random-id-6'  >
                                    <Text text='Get Started' />
                                </Element>
                                {/* <div className='mx-2 my-auto'>
                                <ArrowCircleRightOutlinedIcon sx={{color:'#000000', fontSize:'28px'}}/>
                            </div> */}
                            </Button>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto bg-[#FFB7B7] py-10" >
            <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-426' className='w-[100%] h-[100%] max-w-[1300px] flex flex-row flex-wrap  m-4' style={{ borderRadius: '30px 8px 8px 8px' }} >
                    <Element is={Parent} canvas id='random-id-428' style={{ flex: '55%' }} className=' min-w-[330px] h-full flex flex-col'>
                        <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Poppins', color: '#FFFFFF' }} className={`text-[60px] pt-10`} id='random-id-7'  >
                            <Text text='Understand User Flow' />
                        </Element>
                        <Element is={Parent} canvas id='random-id-429' className={`flex flex-row my-10 `}>
                            <Element is={Parent} canvas id='random-id-430' className={`w-[100px] h-[100px] bg-[#F31559] m-2 flex`} style={{ borderRadius: '0px 12px 0px 12px', justifyContent: 'center', alignItems: 'center' }}>
                                <Element is={Parent} canvas id='random-id-431' className='flex justify-center align-items-center' style={{ color: '#A5DEFF' }}>
                                    <SvgIcon width='60px' height='60px' viewBox='0 0 50 39' fill='#A5DEFF' path='M16.5 2h21.08L22.083 24.973H1zM17.422 27.102 11.42 36H33.5L49 13.027H32.702l-9.496 14.075z' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-432' className={`w-[100px] h-[100px] bg-[#F31559] m-2 flex`} style={{ borderRadius: '0px 12px 0px 12px', justifyContent: 'center', alignItems: 'center' }}>
                                <Element is={Parent} canvas id='random-id-433' className='flex justify-center align-items-center' style={{ color: '#FF7917' }}>
                                    <SvgIcon width='60px' height='60px' viewBox="0 0 73 49" fill='#FFFFFF' path='M46.867 24c0 12.426-10.073 22.5-22.5 22.5S1.867 36.426 1.867 24s10.074-22.5 22.5-22.5 22.5 10.074 22.5 22.5M71.132 24c0 12.426-9.975 22.5-22.28 22.5-12.304 0-22.279-10.074-22.279-22.5s9.975-22.5 22.28-22.5c12.304 0 22.28 10.074 22.28 22.5M36.67 42.842C42.81 38.824 46.867 31.886 46.867 24S42.811 9.176 36.67 5.159A22.54 22.54 0 0 0 26.573 24 22.54 22.54 0 0 0 36.67 42.842' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-434' className={`w-[100px] h-[100px] bg-[#F31559] m-2 flex`} style={{ borderRadius: '0px 12px 0px 12px', justifyContent: 'center', alignItems: 'center' }}>
                                <Element is={Parent} canvas id='random-id-435' className='flex justify-center align-items-center' style={{ color: '#A992DB' }}>
                                    <SvgIcon width='60px' height='60px' viewBox="0 0 49 48" fill='#FFDBAA' path='M1.984 29.29a17.21 17.21 0 0 1 17.21-17.21v17.21zM1.984 29.29a17.21 17.21 0 0 0 17.21 17.21V29.29zM36.404 29.29a17.21 17.21 0 0 1-10.624 15.9 17.2 17.2 0 0 1-6.586 1.31V29.29zM47.016 14.422c0 7.137-5.786 12.922-12.922 12.922H21.172V14.422c0-7.137 5.785-12.922 12.922-12.922 7.136 0 12.922 5.785 12.922 12.922' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-436' className={`w-[100px] h-[100px] bg-[#F31559] m-2 flex`} style={{ borderRadius: '0px 12px 0px 12px', justifyContent: 'center', alignItems: 'center' }}>
                                <Element is={Parent} canvas id='random-id-437' className='flex justify-center align-items-center' style={{ color: '#FFDBAA' }}>
                                    <SvgIcon width='60px' height='60px' viewBox="0 0 49 48" fill='#007AFF' path='M24.5 12.75C24.5 18.963 19.463 24 13.25 24H2V12.75C2 6.537 7.037 1.5 13.25 1.5S24.5 6.537 24.5 12.75m0 22.5C24.5 29.037 29.537 24 35.75 24H47v11.25c0 6.213-5.037 11.25-11.25 11.25S24.5 41.463 24.5 35.25m-22.5 0C2 41.463 7.037 46.5 13.25 46.5H24.5V35.25C24.5 29.037 19.463 24 13.25 24S2 29.037 2 35.25m45-22.5C47 6.537 41.963 1.5 35.75 1.5H24.5v11.25C24.5 18.963 29.537 24 35.75 24S47 18.963 47 12.75' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '400', fontFamily: 'Poppins', color: '#FFFFFF', lineHeight: '28.8px' }} className={`text-[18px]`} id='random-id-8'  >
                            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
                        </Element>
                    </Element>
                    <Element is={Parent} canvas id='random-id-438' className=' py-6 h-[100%]  flex flex-col mx-auto relative' style={{ borderRadius: '30px 8px 8px 8px', flex: '40%' }}>
                        <Element is={Parent} canvas style={{ width: '100%', height: '100%', display: 'inline-block' }} id='random-id-102'  >
                            <ImageC className='w-[100%] h-[100%]' style={{ borderRadius: '30px 8px 8px 8px' }} src='https://i.ibb.co/fCwYWXG/unsplash-5a-GUy-CW-PJw.png' />
                        </Element>
                        <Element is={Parent} canvas id='random-id-202' className='w-full h-[fit-content] justify-end items-center absolute right-[5%] justify-end flex items-end mx-auto bottom-[5%]' style={{ borderRadius: '8px' }}>
                            <Button classn='block' class='w-[280px] h-[60px] py-[11px] bg-[#F31559] hover:bg-[#FF5B8D] flex justify-center cursor-pointer ' style={{ borderRadius: '8px' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '700', fontFamily: 'Almarai', color: '#FFFFFF' }} id='random-id-9'  >
                                    <Text text='Get Started' />
                                </Element>
                                {/* <div className='mx-2 my-auto'>
                        <ArrowCircleRightOutlinedIcon sx={{color:'#FFFFFF', fontSize:'28px'}}/>
                    </div> */}
                            </Button>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto bg-[#0C134F] py-10" >
            <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-439' className='w-[100%] h-full max-w-[1300px] flex flex-row flex-wrap  m-4' style={{ borderRadius: '8px' }} >
                    <Element is={Parent} canvas id='random-id-441' style={{ flex: '55%' }} className=' min-w-[330px] h-full flex flex-col'>
                        <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Poppins', color: '#FFFFFF' }} className={`text-[60px] pt-10`} id='random-id-10'  >
                            <Text text='Understand User Flow' />
                        </Element>
                        <Element is={Parent} canvas id='random-id-442' className={`flex flex-row my-10 `}>
                            <Element is={Parent} canvas id='random-id-443' className={`w-[100px] h-[100px] bg-[#D4ADFC] m-2 flex`} style={{ borderRadius: '8px', justifyContent: 'center', alignItems: 'center' }}>
                                <Element is={Parent} canvas id='random-id-444' className='flex justify-center align-items-center' style={{ color: '#007AFF' }}>
                                    <SvgIcon width='60px' height='60px' viewBox='0 0 50 39' fill='#FF7917' path='M16.5 2h21.08L22.083 24.973H1zM17.422 27.102 11.42 36H33.5L49 13.027H32.702l-9.496 14.075z' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-445' className={`w-[100px] h-[100px] bg-[#D4ADFC] m-2 flex`} style={{ borderRadius: '8px', justifyContent: 'center', alignItems: 'center' }}>
                                <Element is={Parent} canvas id='random-id-446' className='flex justify-center align-items-center' style={{ color: '#FF7917' }}>
                                    <SvgIcon width='60px' height='60px' viewBox="0 0 73 49" fill='#A5DEFF' path='M46.867 24c0 12.426-10.073 22.5-22.5 22.5S1.867 36.426 1.867 24s10.074-22.5 22.5-22.5 22.5 10.074 22.5 22.5M71.132 24c0 12.426-9.975 22.5-22.28 22.5-12.304 0-22.279-10.074-22.279-22.5s9.975-22.5 22.28-22.5c12.304 0 22.28 10.074 22.28 22.5M36.67 42.842C42.81 38.824 46.867 31.886 46.867 24S42.811 9.176 36.67 5.159A22.54 22.54 0 0 0 26.573 24 22.54 22.54 0 0 0 36.67 42.842' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-447' className={`w-[100px] h-[100px] bg-[#D4ADFC] m-2 flex`} style={{ borderRadius: '8px', justifyContent: 'center', alignItems: 'center' }}>
                                <Element is={Parent} canvas id='random-id-448' className='flex justify-center align-items-center' style={{ color: '#442781' }}>
                                    <SvgIcon width='60px' height='60px' viewBox="0 0 49 48" fill='#442781' path='M1.984 29.29a17.21 17.21 0 0 1 17.21-17.21v17.21zM1.984 29.29a17.21 17.21 0 0 0 17.21 17.21V29.29zM36.404 29.29a17.21 17.21 0 0 1-10.624 15.9 17.2 17.2 0 0 1-6.586 1.31V29.29zM47.016 14.422c0 7.137-5.786 12.922-12.922 12.922H21.172V14.422c0-7.137 5.785-12.922 12.922-12.922 7.136 0 12.922 5.785 12.922 12.922' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-449' className={`w-[100px] h-[100px] bg-[#D4ADFC] m-2 flex`} style={{ borderRadius: '8px', justifyContent: 'center', alignItems: 'center' }}>
                                <Element is={Parent} canvas id='random-id-450' className='flex justify-center align-items-center' style={{ color: '#FFDBAA' }}>
                                    <SvgIcon width='60px' height='60px' viewBox="0 0 49 48" fill='#FFDBAA' path='M24.5 12.75C24.5 18.963 19.463 24 13.25 24H2V12.75C2 6.537 7.037 1.5 13.25 1.5S24.5 6.537 24.5 12.75m0 22.5C24.5 29.037 29.537 24 35.75 24H47v11.25c0 6.213-5.037 11.25-11.25 11.25S24.5 41.463 24.5 35.25m-22.5 0C2 41.463 7.037 46.5 13.25 46.5H24.5V35.25C24.5 29.037 19.463 24 13.25 24S2 29.037 2 35.25m45-22.5C47 6.537 41.963 1.5 35.75 1.5H24.5v11.25C24.5 18.963 29.537 24 35.75 24S47 18.963 47 12.75' />
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '400', fontFamily: 'Poppins', color: '#FFFFFF', lineHeight: '28.8px' }} className={`text-[18px]`} id='random-id-11'  >
                            <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.' />
                        </Element>
                    </Element>

                    <Element is={Parent} canvas id='random-id-451' className=' py-6 h-[100%]  min-h-[380px] flex flex-col mx-auto relative' style={{ borderRadius: '8px', flex: '40%' }}>
                        <Element is={Parent} canvas style={{ width: '100%', height: '100%', display: 'inline-block' }} id='random-id-101'  >
                            <ImageC className='w-[100%] h-[100%] object-cover' style={{ borderRadius: '30px' }} src='https://i.ibb.co/dK2P1v9/unsplash-5a-GUy-CW-PJw.png' />
                        </Element>
                        <Element is={Parent} canvas id='random-id-203' className='w-[280px] h-[60px]  absolute right-[5%] justify-end flex items-end mx-auto bottom-[5%]' style={{ borderRadius: '8px' }}>
                            <Button classn='block' class='w-[280px] h-[60px] py-[11px] bg-[#D4ADFC] hover:bg-[#C68EFF] flex justify-center cursor-pointer ' style={{ borderRadius: '8px' }}>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '700', fontFamily: 'Almarai', color: '#0C134F' }} id='random-id-12'  >
                                    <Text text='Get Started' />
                                </Element>
                                {/* <div className='mx-2 my-auto'>
                        <ArrowCircleRightOutlinedIcon sx={{color:'#0C134F', fontSize:'28px'}}/>
                    </div> */}
                            </Button>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

    ]

    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
        setProp((prop) => {
            setSel(100);
        }, 1000);

        setTimeout(() => {
            setProp((prop) => {
                setSel(number)
            }, 1000);
        }, 100);



    }

    useEffect(() => {
        if (selected || selected >= 0) {
            makeOdd(selected);
        }
    }, [selected])

    return (<div style={{ padding: enabled ? '10px' : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
        {sel == 100 ? (
            <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center", width: '100%' }}>
                <BounceLoader
                    size={100}
                    color={"white"}
                    loading={true}
                />
            </div>
        ) : (
            styles[sel]
        )}
    </div>
    );
};

Banner_4.craft = {
    displayName: "Banner 4",
    props: {
        selected: 1, // here is the default value of the selected style
        isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewBannerSettings,
    },
};