/****************************
*  Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR falseS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import { InputSettings } from './InputSettings';
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { useInView } from 'react-hook-inview';


const defaultProps = {
  className : '',
  initialComputed : {}, 
  style: {},
  properties : {
    width:null,
    maxWidth:null,
    minWidth:null,
    height:null,
    background:  null,
    display: null,
    color: null,
    margin:  null,
    marginTop: null,
    marginBottom:null,
    marginLeft:  null,
    marginRight: null,
    padding:null ,
    paddingTop:  null,
    paddingBottom: null,
    paddingLeft: null,
    paddingRight:null,
    border: null,
    borderWidth: null,
    borderColor: null,
    borderStyle: 'solid',
    borderRadius:null,
    overflow: null,
    textAlign: null,
    fontSize: null,
    textTransform: null,
    fontWeight:  null,
    lineHeight:  null,
    fontStyle: "normal",
    textDecoration: null,
    fontFamily:  null,
    textAlign: null,
    shadowX: null,
    shadowY: null,
    shadowColor:null,
    shadowBlur: null,
    // Add more styles as needed
    
  },
  isBasic: false,
  isInput: true,
  placeholder: null,
  readOnly:'false',
  inputValue: null,
  name:null,
  type: null,
}

export const Input = (props) => {
  const {
    connectors: { connect },
    actions: {setProp},
  } = useNode();

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const [inViewRef, inView] = useInView()

  const mergedRef = useRef(null)

  const setRef = useCallback((node) => {
    if (mergedRef.current !== node) {
      if (node) {
        connect(node);
        inViewRef(node);
      }
      mergedRef.current = node;
    }
  }, []);



  const {
    className = '',
    initialComputed = {}, 
    style= {},
    properties = {
      width: props?.style?.width || '',
      maxWidth: props?.style?.maxWidth || '',
      minWidth: props?.style?.minWidth || '',
      height: props?.style?.height || '',

      background: props?.style?.background || '',
      display: props?.style?.display || '',
      color: props?.style?.color || '',
      margin: props?.style?.margin || '',
      marginTop: props?.style?.marginTop || '',
      marginBottom: props?.style?.marginBottom || '',
      marginLeft: props?.style?.marginLeft || '',
      marginRight: props?.style?.marginRight || '',
      padding: props?.style?.padding || '',
      paddingTop: props?.style?.paddingTop || '',
      paddingBottom: props?.style?.paddingBottom || '',
      paddingLeft: props?.style?.paddingLeft || '',
      paddingRight: props?.style?.paddingRight || '',
      border: props?.style?.border || '',
      borderWidth: props?.style?.borderWidth || '',
      borderColor: props?.style?.borderColor || '',
      borderStyle: 'solid',
      borderRadius: props?.style?.borderRadius || '',
      overflow: props?.style?.overflow || '',
      textAlign: props?.style?.textAlign || '',
      fontSize: props?.style?.fontSize || "",
      textTransform: props?.style?.textTransform || '',
      fontWeight: props?.style?.fontWeight || '',
      lineHeight: props?.style?.lineHeight || '',
      fontStyle: props?.style?.fontStyle || "normal",
      textDecoration: props?.style?.textDecoration || '',
      fontFamily: props?.style?.fontFamily || '',
      textAlign: props?.style?.textAlign || '',
      shadowX: '',
      shadowY: '',
      shadowColor:'',
      shadowBlur: '',
      // Add more styles as needed
     
    },
    placeholder= null,
    readOnly='false',
    inputValue= null,
    name= null,
    type= null,
    isBasic= false,
    isInput= true,

    ...otherProps
  } = { ...defaultProps, ...props }; // Merge defaultProps with props

  
function rgbToHex(rgb) {
  // Extract the numbers from the RGB string
  const rgbValues = rgb.match(/\d+/g);
  
  // Convert each RGB value to hex and ensure two digits
  const hexValues = rgbValues.map(value => {
    const hex = parseInt(value).toString(16);
    return hex.length === 1 ? '0' + hex : hex; // Ensure two digits
  });
  
  // Combine the hex values and return as a string starting with '#'
  return `#${hexValues.join('')}`;
}

useEffect(() => {
  if (mergedRef.current) {
    const computedStyles = window.getComputedStyle(mergedRef.current);

    const newStyle = { ...properties }; // Create a copy of the existing styles
    const basicStyle = {
      width:'500px',
      maxWidth:null,
      minWidth:null,
      height:'50px',
      background:  null,
      display: null,
      color: '#000000',
      margin: '8px',
      marginTop: null,
      marginBottom:null,
      marginLeft:  null,
      marginRight: null,
      padding:'4px' ,
      paddingTop:  null,
      paddingBottom: null,
      paddingLeft: null,
      paddingRight:null,
      border: null,
      borderWidth: '2px',
      borderColor: '#e9e9e9',
      borderStyle: 'solid',
      borderRadius:null,
      overflow: null,
      textAlign: null,
      fontSize: '24px',
      textTransform: null,
      fontWeight:  null,
      lineHeight:  null,
      fontStyle: "normal",
      textDecoration: null,
      fontFamily:  null,
      textAlign: null,
      shadowX: null,
      shadowY: null,
      shadowColor:null,
      shadowBlur: null,
      // Add more styles as needed
      placeholder: 'This an Input',
      readOnly:'false',
      inputValue: null,
      name:null,
      type: null,
    }

    const initProperties = { ...initialComputed };

    const updatedStyle = isBasic ? basicStyle : newStyle



    // Iterate over computed styles
    for (let i = 0; i < computedStyles.length; i++) {
      const key = computedStyles[i]; // Get the key directly
      const camelCaseKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
      const computedValue = computedStyles.getPropertyValue(key); // Use original key for computed value

     
      // Update the style if the computed value exists and is different from current style
      if (computedValue && camelCaseKey in newStyle) {
        if (isBasic) {
          newStyle[camelCaseKey] = basicStyle[camelCaseKey];
          initProperties[camelCaseKey] = newStyle[camelCaseKey]; // Store initial properties
        } else if (computedValue.startsWith('rgb')) {
          newStyle[camelCaseKey] = rgbToHex(computedValue); // Update with the hex value
          initProperties[camelCaseKey] = newStyle[camelCaseKey]; // Store the hex value
        } else if (computedValue) {
          // If it's not RGB, just assign the value directly
          newStyle[camelCaseKey] = computedValue;
          initProperties[camelCaseKey] = computedValue; // Store initial properties
        }
      }
    }


    setProp((props) => {
      props.properties = basicStyle;
      props.initialComputed = initProperties;
    });

    console.log(computedStyles, updatedStyle, 'style')
  }
}, [mergedRef]); 

const combinedStyle = {
  ...props.style,
  ...properties,
}




  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

  return (
    
    <input
    ref={setRef}
    {...props}
      value={inputValue}
      className={className}
      placeholder={properties.placeholder}
      name={properties.name}
      type={properties.type}
      readOnly={properties.readOnly === 'true' ? true : false}
      onChange={(e) => {
        setProp((prop) => (prop.inputValue = e.target.value), 500);
      }}
      style={{
        ...combinedStyle,
        cursor: 'auto',
      }}
    />
  );
};

Input.craft = {
  displayName: 'Input',
  props: defaultProps,
  related: {
    toolbar: InputSettings,
  },
};
