/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/



import { Element } from '@craftjs/core';

import { useNode } from '@craftjs/core';
import React, { useEffect, useState } from 'react';
import { Container } from  '../../../Container/index.js';
import { ColumnSettings } from './ColumnSettings';
export const Row1 = ({columns}) => {
  const { 
    connectors: {
      connect
    },
    active
  } = useNode(node => ({
    selected: node.events.selected,
    active: node.events.selected,
  }));
  return (
    <div style={{display:"flex", margin:"10px 0",width:"100%", flexDirection:"row", border: active ? "5px solid #3b97e3" : ""}} ref={connect}>
   
   {[...Array(columns).keys()].map((x,i,a)=> ( <div style={{ margin:"10px", color:"white", display:"flex", justifyContent:"center", border:"1px dashed gray", height:"100px", alignItems:"center", width:`calc(100%/${a.length}`}}>column</div>))}
    </div>
  )
}

Row1.craft = {
  displayName: "Row",
  props: {
    columns: 2,
    isLayout: true
  },
  // related: {
  //   toolbar: ColumnSettings,
  // },
};