import React, { useState, useEffect, useRef } from "react";
import Keycloak from "keycloak-js";

const client = new Keycloak({
  url: "https://sso.allyoucancloud.com",
  realm: "aycc",
  clientId: "pagebuilder_client",
});


// process.env.REACT_KEYCLOAK_URL || 
// process.env.REACT_KEYCLOAK_REALM || 
// process.env.REACT_KEYCLOAK_CLIENT || 

const useAuth = () => {
  const isRun = useRef(false);
  const [token, setToken] = useState(null);
  const [isLogin, setLogin] = useState(false);

  useEffect(() => {
    if (isRun.current) return;

    isRun.current = true;
    client
      .init({
        onLoad: "login-required",
      })
      .then((res) => {
        setLogin(res);
        setToken(client.token);
      });
  }, []);

  return [isLogin, token];
};

export default useAuth;