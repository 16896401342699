/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ImageCSettings } from './ImageSettings';
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { useOptionsContext } from '../../../../OptionsContext';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import Text from '../Text';
import { useInView } from 'react-hook-inview';
import { ResizableWrapper } from '../../ResizableWrapper/index.js';

const defaultProps = {
  className: '',
  href: null,
  src: null,
  
    style: {},
    height: null,
    width: null,
    background: null,
    objectFit: null,
    maxWidth: null,
    minWidth: null,
    display: null,
    color: null,
    opacity: null,
    margin: null,
    marginTop: null,
    marginBottom: null,
    marginLeft: null,
    marginRight: null,
    padding: null,
    paddingTop: null,
    paddingBottom: null,
    paddingLeft: null,
    paddingRight: null,
    border: null,
    borderWidth: null,
    borderColor: null,
    borderStyle: 'solid',
    borderRadius: null,
    overflow: null,
    textAlign: null,
    shadowX: null,
    shadowY: null,
    shadowColor:null,
    shadowBlur: null,
    // Add more styles as needed
  isBasic: false,
  convertToTxt: false,
};


export const ImageC = ({href, className, src, isBasic = false, convertToTxt = false, isSvg, text, tagName, ...defaultProps}) => {
 
  
  const {
    connectors: { connect, drag },
    node,
    actions: { setProp }
  } = useNode((node) => ({
    selected: node.events.selected,
    props: node.data.props,
    node: node,
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;

  const [hovered, setHovered] = useState(false)
  const [inViewRef, inView] = useInView()

  const mergedRef = useRef(null)


const setRef = useCallback((node) => {
  if (mergedRef.current !== node) {
    if (node) {
      connect(node);
      inViewRef(node);
    }
    mergedRef.current = node;
  }
}, []);


const memoizedProps = useMemo(() => ({
  ...defaultProps,
  ...defaultProps.style,
  width: isBasic ? defaultProps.width? defaultProps.width : '100%' : '',
  height: isBasic ? defaultProps.height? defaultProps.height : '100%' : '',
  lineHeight: 'auto',
  cursor: href && !enabled ? 'pointer' : '',
  // boxShadow: shadowY !== null 
  // ? `${shadowX} ${shadowY} ${shadowBlur} ${shadowColor}` 
  // : ''
}), [defaultProps, enabled, href]);
 
  return (
    <>
    {convertToTxt? (
    <Element canvas is={Parent} id='parentId12text' className="w-auto h-auto">
      <Element canvas id='random-id-400text' is={Parent} width={'100%'} height={'100%'}>
        <Text text='Text'/>
      </Element>
    </Element>
    ) : (
      <ResizableWrapper>

    <img
    alt='img'
      val={href?.name} _id="sidebarItemx"
      onClick={() => {
        if(href && !enabled) {
          if(href._id.includes("www")) {
            window.open("https://" + href?.name, "_blank")
          } else {
            const parentItem = treeData.find((x) => x._id === href._id);

            if (parentItem) {
              setActiveId(parentItem);
            } else {
              let childItem = null;
            
              for (const parent of treeData) {
                const child = parent.children.find((child) => child._id === href._id);
            
                if (child) {
                  childItem = child;
                  break;
                }
              }
            
              setActiveId(childItem);
            }

          }
        } 
      }}
      className={className}
      ref={setRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      src={src || 'https://i.ibb.co/72vs9kd/2.jpg'}
      style={memoizedProps}
      // {...otherProps}
    />   
    </ResizableWrapper> 
    )}
    </>
  );
};

ImageC.craft = {
  displayName: 'Image',
  props: defaultProps,
  related: {
    toolbar: ImageCSettings,
  },
};