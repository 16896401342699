/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useEffect } from 'react'
import {HeaderLogo, IconPerson, LogoHeaders, MenuComplex, MenuIcon3, SearchIcon } from '../../../editor/Viewport/Pop-up/svgArray'
import './newHeader.css'
import { useState } from 'react'
import { useRef } from 'react'
import { useOptionsContext } from '../../../../OptionsContext'
import { useActiveIdContext } from '../../../../ActiveIdContext'
import { style } from '@mui/system'
import { Button, Slider } from '@mui/material'
import { useDarkMode } from '../../../../DarkModeContext'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';


export default function NewHeader({width, navigationSide, setNavigationSide, enabled, setSettings, currentStyleIndex, stylesOn, setStylesOn, logoText, logoIcon, socialIcons }) {
    const { isDarkMode } = useDarkMode();
    const [childrenArray, setChildrenArray] = useState(false)
    const [show, setShow] = useState(false)
    const { state: idState, dispatch: idDispatch } = useOptionsContext();
    const { options: treeDatas } = idState;
    const { state, dispatch: setActiveId } = useActiveIdContext();
    const { activeId: activeId } = state;
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState('homeItem');

    const anchorRef = useRef(null);

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const homeRef = useRef(null)
    const aboutusRef = useRef(null)
    const pricingRef = useRef(null)
    const faqRef = useRef(null)
    const contactRef = useRef(null)
    const componentRef = useRef(null);

    const items = [
        { ref: homeRef, id: 'homeItem' },
        { ref: aboutusRef, id: 'aboutusItem' },
        { ref: pricingRef, id: 'pricingItem' },
        { ref: faqRef, id: 'faqItem' },
        { ref: contactRef, id: 'contactItem' },
    ];

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            setOpen(!open);
        }

    };

    useEffect(() => {
        return () => {
            setSelectedItem(homeRef);
        };
    }, []);

    const handleItemClick = (ref) => {

        setSelectedItem(ref);
        items.forEach((item) => {
            document.getElementById(item.id).style.color = item.ref === ref ? '#B20312' : 'inherit';
        });
    };

    const header = [
        <div className="header w-full h-auto py-2" style={{ borderBottom: '1px solid rgba(178, 3, 18, 0.20)' }}>
            <div className="container">
                <div className='flex flex-col'>
                    <div className="w-full header-wrapper">
                        <div className='left-header'>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#510B11'}><path d={logoIcon?.path} /></svg>
                        </div>
                            <div className='logo-content'>{logoText || 'Logo'}</div>
                        </div>
                        <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>

                            {treeDatas.map(x => (
                                <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                    <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'

                                    >
                                        <div className='flex flex-row'>
                                            <div className='dropbtn'
                                                id='homeItem'
                                                val={x.name}
                                                _id="sidebarItemx"
                                                style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#100103' : '#100103', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                                }}>
                                                {
                                                    (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                }

                                            </div>
                                            {x.children.length > 0 && <div className='Arrow my-auto'>

                                                <KeyboardArrowDownIcon sx={{ color: '#100103' }} />
                                            </div>

                                            }
                                        </div>
                                        <div className='dropdown-content flex flex-row justify-center' style={{ borderRadius: '4px', background:'#fff' }}>

                                            {x.children ? x.children.map(child => (

                                                <div key={child._id} _id={"sidebarItemx"} val={child.name} >
                                                    <div
                                                        className='item text-center '
                                                        style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#100103', fontWeight: '400' }}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                        }}>
                                                        {
                                                            activeId?._id === child?._id
                                                                ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                                : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                        }
                                                    </div>
                                                </div>


                                            )) : null}                    </div>

                                    </div>
                                </div>
                            ))}


                        </div>
                        <div className='right-header' style={{display:width === '500px' ? 'none' : 'inline-flex'}}>
                        {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#510B11' : icon?.fill ? icon?.fill : '#510B11'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#510B11' : icon?.fill ? icon?.fill : '#510B11'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                        </div>
                        <div className='menu-hidden' style={{display:width === '500px' ? 'inline-flex' : null}} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon sx={{ color: '#510B11', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#510B11', fontSize: '40px' }} />}
                        </div>
                    </div>

                </div>

                {show && <div className='items-hidden' style={{display:width === '500px' ? 'inline-flex' : null}}>
                    {treeDatas.map(x => (
                        <div key={x._id} style={{ width: "100%" }} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#510B11' : 'inherit',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children.length || x.children.some(y => y._id === activeId._id) ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: isDarkMode ? '#510B11' : '#510B11' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: isDarkMode ? '#510B11' : '#510B11' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#100103', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}
                    <div className='w-full flex flex-row justify-center align-items-center'> 
                    {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#510B11' : icon?.fill ? icon?.fill : '#510B11'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#510B11' : icon?.fill ? icon?.fill : '#510B11'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                    </div>
                </div>}
            </div>
        </div>,

        <div className="header w-full h-auto py-2" style={{ borderBottom: '1px solid #4BDBA2', background: '#121826', borderRadius: '0px' }}>
            <div className="container">
                <div className="w-full header-wrapper">
                    <div className='left-header'>
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#4BDBA2'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content' style={{ color: '#4BDBA2' }}>{logoText || 'Logo'}</div>
                    </div>
                    <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>

                        {treeDatas.map(x => (
                            <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'

                                >
                                    <div className='flex flex-row'>
                                        <div className='dropbtn'
                                            id='homeItem'
                                            val={x.name}
                                            _id="sidebarItemx"
                                            style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#4BDBA2' : '#fff', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                            }}>
                                            {
                                                (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                            }

                                        </div>
                                        {x.children.length > 0 && <div className='Arrow my-auto'>
                                            <KeyboardArrowDownIcon sx={{ color: '#4BDBA2' }} />
                                        </div>}
                                    </div>
                                    <div className='dropdown-content flex flex-row justify-center' style={{ borderRadius: '6px', background:'#121826',  }}>
                                        {x.children ? x.children.map(child => (

                                            <div key={child._id}>
                                                <div
                                                    className='item text-center '
                                                    style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#fff', fontWeight: '400' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                    }}>
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>


                                        )) : null}                    </div>

                                </div>
                            </div>
                        ))}


                    </div>
                    <div className='right-header' style={{display:width === '500px' ? 'none' : 'inline-flex'}}>
                    {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#EA6EA4' : icon?.fill ? icon?.fill : '#EA6EA4'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#EA6EA4' : icon?.fill ? icon?.fill : '#EA6EA4'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                    </div>
                    <div className='menu-hidden' style={{display:width === '500px' ? 'inline-flex' : null}} onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#4BDBA2', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#4BDBA2', fontSize: '40px' }} />}
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px' ? 'inline-flex' : null}}>
                    <>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#4BDBA2' : '#fff',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#4BDBA2' : '#fff' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#4BDBA2' : '#fff' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#fff', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }



                        </div>

                    ))}
                    <div className='w-full flex flex-row justify-center align-items-center'> 
                    {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#31A993' : icon?.fill ? icon?.fill : '#31A993'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#31A993' : icon?.fill ? icon?.fill : '#31A993'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                    </div>
                    </>
                </div>}
            </div>
        </div>,

        <div className="header w-full h-auto py-2" style={{ borderBottom: '1px solid #EA6EF4', background: '#213156', borderRadius: '0px 0px 10px 10px', boxShadow: ' 0px 4px 10px 0px rgba(255, 255, 255, 0.06)' }}>
            <div className="container">
                <div className="w-full header-wrapper">
                    <div className='left-header' >
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#EA6EF4'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content' style={{ color: '#EA6EF4' }}>{logoText || 'Logo'}</div>
                    </div>
                    <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>

                        {treeDatas.map(x => (
                            <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'

                                >
                                    <div className='flex flex-row'>
                                        <div className='dropbtn'
                                            id='homeItem'
                                            val={x.name}
                                            _id="sidebarItemx"
                                            style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#EA6EF4' : '#fff', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                            }}>
                                            {
                                                (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                            }

                                        </div>
                                        {x.children.length > 0 && <div className='Arrow my-auto'>
                                            <KeyboardArrowDownIcon sx={{ color: '#EA6EF4' }} />
                                        </div>}
                                    </div>
                                    <div className='dropdown-content flex flex-row justify-center' style={{ borderRadius: '18px', background:'#213156' }}>
                                        {x.children ? x.children.map(child => (

                                            <div key={child._id} _id={"sidebarItemx"} val={child.name} >
                                                <div
                                                    className='item text-center '
                                                    style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#fff', fontWeight: '400' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                    }}>
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>


                                        )) : null}                    </div>

                                </div>
                            </div>
                        ))}


                    </div>


                    <div className='header-button  right-header uppercase bg-[#EA6EF4] hover:bg-[#E111F2]' style={{ width: '240px', height: '50px', borderRadius: '40px', display:width === '500px' ? 'none' : 'inline-flex' }}>
                        <div className='mx-auto my-auto text-[#ffffff] text-[22px] font-bold'> get started </div>
                    </div>
                    <div className='menu-hidden' style={{display:width === '500px' ? 'inline-flex' : null}} onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#EA6EF4', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#EA6EF4', fontSize: '40px' }} />}
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px' ? 'inline-flex' : null}}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#EA6EF4' : '#fff',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#EA6EF4' : '#fff' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#EA6EF4' : '#fff' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#fff', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}
                    <div className='w-100'>
                        <div className=' uppercase flex mb-2 mx-auto bg-[#EA6EF4] hover:bg-[#E111F2]' style={{ width: '240px', height: '50px', borderRadius: '40px' }}>
                            <div className='mx-auto my-auto text-[#ffffff] text-[22px] font-bold'> get started </div>
                        </div>
                    </div>
                    </div>}
            </div>
        </div>,

        <div className="header w-full h-auto py-2" style={{ borderBottom: '1px solid #4BDBA2', background: '#DFFFF9', borderRadius: '0px 0px 10px 10px', boxShadow: ' 0px 4px 10px 0px rgba(255, 255, 255, 0.06)' }}>
            <div className="container">
                <div className="w-full header-wrapper">
                    <div className='left-header' >
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#31A993'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content' style={{ color: '#31A993' }}>{logoText || 'Logo'}</div>
                    </div>
                    <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                        {treeDatas.map(x => (
                            <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                    onMouseMove={() => setChildrenArray(true)}
                                    onMouseOutCapture={() => setChildrenArray(true)}
                                >
                                    <div className='flex flex-row'>
                                        <div className='dropbtn'
                                            id='homeItem'
                                            val={x.name}
                                            _id="sidebarItemx"
                                            style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#31A993' : '#031815', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                            }}>
                                            {
                                                (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                            }

                                        </div>
                                        {x.children.length > 0 && <div className='Arrow my-auto'>
                                            <KeyboardArrowDownIcon sx={{ color: '#31A993' }} />
                                        </div>}
                                    </div>
                                    <div className=' dropdown-content flex flex-row justify-center' style={{ borderRadius: '18px', background:'#DFFFF9' }}>
                                        {x.children ? x.children.map(child => (

                                            <div key={child._id} _id={"sidebarItemx"} val={child.name} >
                                                <div
                                                    className='item text-center '
                                                    style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#000', fontWeight: '400' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                    }}>
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>


                                        )) : null}                    </div>

                                </div>
                            </div>
                        ))}

                    </div>

                    <div className='header-button  right-header uppercase flex bg-[#31A993] hover:bg-[#11816C]' style={{ width: '240px', height: '50px', borderRadius: '40px', display:width === '500px' ? 'none' : 'inline-flex'}}>
                        <div className='mx-auto my-auto text-[#ffffff] text-[22px] font-bold'> get started </div>
                    </div>
                    <div className='menu-hidden' style={{display:width === '500px' ? 'inline-flex' : null}} onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#31A993', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#31A993', fontSize: '40px' }} />}
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px' ? 'inline-flex' : null}}>
                    
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#31A993' : 'inherit',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#31A993' : 'inherit' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#31A993' : 'inherit' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#100103', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}
                    <div className='w-100'>
                        <div className=' uppercase flex mb-2 mx-auto bg-[#31A993] hover:bg-[#11816C]' style={{ width: '240px', height: '50px', borderRadius: '40px'}}>
                            <div className='mx-auto my-auto text-[#ffffff] text-[22px] font-bold'> get started </div>
                        </div>
                    </div>
                    </div>}
            </div>
        </div>,


        <div className="header w-full h-auto py-2" style={{ borderBottom: '1px solid #0078D3', background: '#FFF', borderRadius: '0px' }}>
            <div className="container">
                <div className="w-full header-wrapper">
                    <div className='left-header' >
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#0078D3'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content' style={{ color: '#0078D3' }}>{logoText || 'Logo'}</div>
                    </div>
                    <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                        {treeDatas.map(x => (
                            <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                    onMouseMove={() => setChildrenArray(true)}
                                    onMouseOutCapture={() => setChildrenArray(true)}
                                >
                                    <div className='flex flex-row'>
                                        <div className='dropbtn'
                                            id='homeItem'
                                            val={x.name}
                                            _id="sidebarItemx"
                                            style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#0078D3' : '#020E17', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                            }}>
                                            {
                                                (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                            }

                                        </div>
                                        {x.children.length > 0 && <div className='Arrow my-auto'>
                                            <KeyboardArrowDownIcon sx={{ color: '#0078D3' }} />
                                        </div>}
                                    </div>
                                    <div className=' dropdown-content flex flex-row justify-center' style={{ borderRadius: '4px', background:'#FFF' }}>
                                        {x.children ? x.children.map(child => (

                                            <div key={child._id} _id={"sidebarItemx"} val={child.name} >
                                                <div
                                                    className='item text-center '
                                                    style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#000', fontWeight: '400' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                    }}>
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>

                                        )) : null}                    </div>


                                </div>
                            </div>
                        ))}

                    </div>

                    <div className='header-button  right-header uppercase flex bg-[#0078D3] hover:bg-[#11578B]' style={{ width: '240px', height: '50px', borderRadius: '6px', display:width === '500px' ? 'none' : 'inline-flex', boxShadow: ' 0px 4px 10px 0px rgba(0, 0, 0, 0.10)' }}>
                        <div className='mx-auto my-auto text-[#ffffff] text-[22px] font-bold'> get started </div>
                    </div>
                    <div className='menu-hidden' style={{display:width === '500px' ? 'inline-flex' : null}} onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#007BD3', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#007BD3', fontSize: '40px' }} />}
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px' ? 'inline-flex' : null}}>
                    
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#0078D3' : '#020E17',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#0078D3' : '#020E17' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#0078D3' : '#020E17' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>

                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#020E17', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}
                    <div className='w-100'>
                        <div className=' uppercase flex mb-2 mx-auto bg-[#0078D3] hover:bg-[#11578B]' style={{ width: '240px', height: '50px', borderRadius: '6px', boxShadow: ' 0px 4px 10px 0px rgba(0, 0, 0, 0.10)' }}>
                            <div className='mx-auto my-auto text-[#ffffff] text-[22px] font-bold'> get started </div>
                        </div>
                    </div>
                    </div>}
            </div>
        </div>,



        <div className="header w-full h-auto py-2" style={{ background: '#404046', borderRadius: '0px' }}>
            <div className="container">
                <div className="w-full header-wrapper">
                    <div className='left-header' >
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#FFFFFF'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content' style={{ color: '#FFFFFF' }}>{logoText || 'Logo'}</div>
                    </div>
                    <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                        {treeDatas.map(x => (
                            <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                    onMouseMove={() => setChildrenArray(true)}
                                    onMouseOutCapture={() => setChildrenArray(true)}
                                >
                                    <div className='flex flex-row'>
                                        <div className='dropbtn'
                                            id='homeItem'
                                            val={x.name}
                                            _id="sidebarItemx"
                                            style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#FFFFFF' : '#FFFFFF', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                            }}>
                                            {
                                                (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                            }

                                        </div>
                                        {x.children.length > 0 && <div className='Arrow my-auto'>
                                            <KeyboardArrowDownIcon sx={{ color: '#FFFFFF' }} />
                                        </div>}
                                    </div>
                                    <div className='dropdown-content flex flex-row justify-center' style={{ borderRadius: '4px', background:'#404046', border:'1px solid #e9e9e930' }}>
                                        {x.children ? x.children.map(child => (

                                            <div key={child._id}>
                                                <div
                                                    className='item text-center '
                                                    style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#fff', fontWeight: '400' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                    }}>
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>

                                        )) : null}                    </div>


                                </div>
                            </div>
                        ))}


                    </div>
{/* 
                    <div className='my-auto mx-4 align-center'>
                        <SearchIcon />
                    </div> */}
                    <div className='menu-hidden' style={{display:width === '500px' ? 'inline-flex' : null}} onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#FFFFFF', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#FFFFFF', fontSize: '40px' }} />}
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px' ? 'inline-flex' : null}}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#fff', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}</div>}
            </div>
        </div>,


        <div className="header w-full h-auto py-2" style={{ borderBottom: '1px solid #6750A4', background: '#FBFAFF', borderRadius: '0px 0px 30px 30px', boxShadow: ' 0px 4px 10px 0px rgba(255, 255, 255, 0.06)' }}>
            <div className="container">
                <div className="w-full header-wrapper" style={{ justifyContent: 'space-between' }}>
                    <div className='left-header' style={{ justifyContent: 'left' }}>
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#6750A4'}><path d={logoIcon?.path} /></svg>
                        </div>
                    </div>
                    <div className='item-list-header' style={{ justifyContent: 'right' }}>
                        {treeDatas.map(x => (
                            <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                    onMouseMove={() => setChildrenArray(true)}
                                    onMouseOutCapture={() => setChildrenArray(true)}
                                >
                                    <div className='flex flex-row'>
                                        <div className='dropbtn'
                                            id='homeItem'
                                            val={x.name}
                                            _id="sidebarItemx"
                                            style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#6750A4' : '#100103', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                            }}>
                                            {
                                                (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                            }

                                        </div>
                                        {x.children.length > 0 && <div className='Arrow my-auto'>
                                            <KeyboardArrowDownIcon sx={{ color: '#6750A4' }} />
                                        </div>}
                                    </div>
                                    <div className='dropdown-content flex flex-row justify-center' style={{ borderRadius: '13px', background:'#6750A4' }}>
                                        {x.children ? x.children.map(child => (
                                            <div key={child._id} _id={"sidebarItemx"} val={child.name} >
                                                <div
                                                    className='item text-center '
                                                    style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#FFF', fontWeight: '400' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                    }}>
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>

                                        )) : null}                    </div>

                                </div>
                            </div>
                        ))}


                    </div>
                    <div className='menu-hidden' style={{display:width === '500px' ? 'inline-flex' : null}} onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#6750A4', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#6750A4', fontSize: '40px' }} />}
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px' ? 'inline-flex' : null}}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#6750A4' : '#100103',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#6750A4' : '#100103' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#6750A4' : '#100103' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#000', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}</div>}
            </div>
        </div>,



        <div className="header w-full h-auto py-2" style={{ borderBottom: '1px solid #FFD600', background: '#2A2A2A', borderRadius: '0px 0px 10px 10px' }}>
            <div className="container">
                <div className="w-full header-wrapper">
                    <div className='left-header' style={{ justifyContent: 'left' }}>
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#FFD600'}><path d={logoIcon?.path} /></svg>
                        </div>
                    </div>
                    <div className='item-list-header' style={{ justifyContent: 'right' }}>
                        {treeDatas.map(x => (
                            <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                    onMouseMove={() => setChildrenArray(true)}
                                    onMouseOutCapture={() => setChildrenArray(true)}
                                >
                                    <div className='flex flex-row'>
                                        <div className='dropbtn'
                                            id='homeItem'
                                            val={x.name}
                                            _id="sidebarItemx"
                                            style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#FFD600' : '#fff', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                            }}>
                                            {
                                                (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                            }

                                        </div>
                                        {x.children.length > 0 && <div className='Arrow my-auto'>
                                            <KeyboardArrowDownIcon sx={{ color: '#FFD600' }} />
                                        </div>}
                                    </div>
                                    <div className='dropdown-content flex flex-row justify-center' style={{ borderRadius: '10px' , background:'#2A2A2A', border:'1px solid #FFD60030'}}>
                                        {x.children ? x.children.map(child => (
                                            <div key={child._id} _id={"sidebarItemx"} val={child.name} >
                                                <div
                                                    className='item text-center '
                                                    style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#FFF', fontWeight: '400' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                    }}>
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>

                                        )) : null}                    </div>

                                </div>
                            </div>
                        ))}


                    </div>


                    <div className='menu-hidden' style={{display:width === '500px' ? 'inline-flex' : null}} onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#FFD600', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#FFD600', fontSize: '40px' }} />}
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px' ? 'inline-flex' : null}}>

                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#FFD600' : '#fff',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#FFD600' : '#FFF' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#FFD600' : '#FFF' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#fff', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}   </div>}
            </div>
        </div>,


        <div className="header w-full h-auto" style={{ background: '#F7FBFE', borderBottom: '1px solid #464883', borderRadius: '0px 0px 10px 10px',}}>
            <div className="container">
                <div className="w-full header-wrapper">
                    <div className='left-header' >
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#464883'}><path d={logoIcon?.path} /></svg>
                        </div>
                    </div>
                    <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                        {treeDatas.map(x => (
                            <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                    onMouseMove={() => setChildrenArray(true)}
                                    onMouseOutCapture={() => setChildrenArray(true)}
                                >
                                    <div className='flex flex-row'>
                                        <div className='dropbtn'
                                            id='homeItem'
                                            val={x.name}
                                            _id="sidebarItemx"
                                            style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#464883' : '#000', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                            }}>
                                            {
                                                (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                            }

                                        </div>
                                        {x.children.length > 0 && <div className='Arrow my-auto'>
                                            <KeyboardArrowDownIcon sx={{ color: '#464883' }} />
                                        </div>}
                                    </div>
                                    <div className='dropdown-content flex flex-row justify-center' style={{ borderRadius: '10px', background:'#464883' }}>
                                        {x.children ? x.children.map(child => (
                                            <div key={child._id} _id={"sidebarItemx"} val={child.name} >
                                                <div
                                                    className='item text-center '
                                                    style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#FFF', fontWeight: '400' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                    }}>
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>


                                        )) : null}</div>

                                </div>
                            </div>
                        ))}


                    </div>
                    <div className='menu-hidden' style={{display:width === '500px' ? 'inline-flex' : null}} onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#464883', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#464883', fontSize: '40px' }} />}
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px' ? 'inline-flex' : null}}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#464883' : '#000000',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#464883' : '#000000' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#464883' : '#000000' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#000', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}</div>}
            </div>
        </div>,


        <div className="header w-full h-auto" style={{ background: '#A25738', borderRadius: '0px 0px 10px 10px', borderBottom: '1px solid #FEFBF7' }}>
            <div className="container">
                <div className="w-full header-wrapper">
                    <div className='left-header' >
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#FEFBF7'}><path d={logoIcon?.path} /></svg>
                        </div>
                    </div>
                    <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                        {treeDatas.map(x => (
                            <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                    onMouseMove={() => setChildrenArray(true)}
                                    onMouseOutCapture={() => setChildrenArray(true)}
                                >
                                    <div className='flex flex-row'>
                                        <div className='dropbtn'
                                            id='homeItem'
                                            val={x.name}
                                            _id="sidebarItemx"
                                            style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                            }}>
                                            {
                                                (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                            }

                                        </div>
                                        {x.children.length > 0 && <div className='Arrow my-auto'>
                                            <KeyboardArrowDownIcon sx={{ color: '#fff' }} />
                                        </div>}
                                    </div>
                                    <div className='dropdown-content flex flex-row justify-center' style={{ borderRadius: '10px', background:'#FFFFFF', border:'1px solid #A2573830' }}>
                                        {x.children ? x.children.map(child => (
                                            <div key={child._id} _id={"sidebarItemx"} val={child.name} >
                                                <div
                                                    className='item text-center '
                                                    style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#000', fontWeight: '400' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                    }}>
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>


                                        )) : null}</div>

                                </div>
                            </div>
                        ))}


                    </div>
                    <div className='menu-hidden' style={{display:width === '500px' ? 'inline-flex' : null}} onClick={() => setShow(!show)}>
                        {!show ? <MenuIcon sx={{ color: '#FFF', fontSize: '40px' }} /> : <CloseIcon sx={{ color: '#FFF', fontSize: '40px' }} />}
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px' ? 'inline-flex' : null}}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#FFFFFF' : '#ffffff',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#FFFFFF' : '#FFFFFF' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#FFFFFF' : '#FFFFFF' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#FFFFFF', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}</div>}
            </div>
        </div>,


        <div className="header w-full h-auto py-2" style={{ borderBottom: '1px solid #0078D3', background: '#FFF', borderRadius: '0px' }}>
            <div className="container">
                <div className="w-full header-wrapper">
                    <div className='left-header' style={{ justifyContent: 'space-between' }}>
                        <div className='logo-aria'>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#2479FD'}><path d={logoIcon?.path} /></svg>
                        </div>
                            <div className='logo-content  my-auto' style={{ color: '#2479FD' }}>
                                {logoText || 'Logo'}
                            </div>
                        </div>
                    </div>
                    <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                        {treeDatas.map(x => (
                            <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                    onMouseMove={() => setChildrenArray(true)}
                                    onMouseOutCapture={() => setChildrenArray(true)}
                                >
                                    <div className='flex flex-row'>
                                        <div className='dropbtn'
                                            id='homeItem'
                                            val={x.name}
                                            _id="sidebarItemx"
                                            style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#020E17', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                            }}>
                                            {
                                                (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                            }

                                        </div>
                                        {x.children.length > 0 && <div className='Arrow my-auto'>
                                            <KeyboardArrowDownIcon sx={{ color: '#FFC92E' }} />
                                        </div>}
                                    </div>
                                    <div className=' dropdown-content flex flex-row justify-center' style={{ borderRadius: '4px', background:'#2479FD' }}>
                                        {x.children ? x.children.map(child => (

                                            <div key={child._id} _id={"sidebarItemx"} val={child.name} >
                                                <div
                                                    className='item text-center '
                                                    style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#fff', fontWeight: '400' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                    }}>
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>

                                        )) : null}                    </div>


                                </div>
                            </div>
                        ))}

                    </div>

                    <div className='header-button right-header uppercase flex bg-[#FFC92E] hover:bg-[#FFF5D7] border-1 hover:border-[#FFB800]' style={{display:width === '500px' ? 'none' : 'inline-flex', width: '226px', height: '60px', borderRadius: '14px' }}>
                        <div className='mx-auto my-auto text-[#000] text-[22px] font-bold'> get started </div>
                    </div>
                    <div className='menu-hidden' style={{display:width === '500px' ? 'inline-flex' : null}} onClick={() => setShow(!show)}>
                        {!show ? <MenuComplex fill='#007BD3' /> : <CloseIcon sx={{ color: '#007BD3', fontSize: '40px' }} />}
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px' ? 'inline-flex' : null}}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#020E17',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#0078D3' : '#020E17' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#FFC92E' : '#020E17' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>

                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#020E17', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}
                    <div className='w-100 mt-8'>
                        <div className=' uppercase flex bg-[#FFC92E] hover:bg-[#FFF5D7] border-1 hover:border-[#FFB800] mb-2 mx-auto' style={{ width: '226px', height: '60px', borderRadius: '14px' }}>
                            <div className='mx-auto my-auto text-[#000] text-[22px] font-bold'> get started </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>,



        <div className="header w-full h-auto py-2" style={{ borderBottom: '1px solid #F6FA70', background: '#FFF', borderRadius: '0px' }}>
            <div className="container">
                <div className="w-full header-wrapper">
                    <div className='left-header' style={{ justifyContent: 'space-between' }}>
                        <div className='logo-aria'>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#FF0060'}><path d={logoIcon?.path} /></svg>
                        </div>
                            <div className='logo-content  my-auto' style={{ color: '#FF0060' }}>
                                {logoText || 'Logo'}
                            </div>
                        </div>


                    </div>
                    <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                        {treeDatas.map(x => (
                            <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                    onMouseMove={() => setChildrenArray(true)}
                                    onMouseOutCapture={() => setChildrenArray(true)}
                                >
                                    <div className='flex flex-row'>
                                        <div className='dropbtn'
                                            id='homeItem'
                                            val={x.name}
                                            _id="sidebarItemx"
                                            style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#020E17', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                            }}>
                                            {
                                                (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                            }

                                        </div>
                                        {x.children.length > 0 && <div className='Arrow my-auto'>
                                            <KeyboardArrowDownIcon sx={{ color: '#FF0060' }} />
                                        </div>}
                                    </div>
                                    <div className=' dropdown-content flex flex-row justify-center' style={{ borderRadius: '4px', background:'#FF0060' }}>
                                        {x.children ? x.children.map(child => (

                                            <div key={child._id} _id={"sidebarItemx"} val={child.name} >
                                                <div
                                                    className='item text-center '
                                                    style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#fff', fontWeight: '400' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                    }}>
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>

                                        )) : null}                    </div>


                                </div>
                            </div>
                        ))}

                    </div>

                    <div className='header-button  right-header uppercase flex text-[#000000] hover:text-[#FF0060] bg-[transparent] ' style={{ width: '226px', height: '60px', display:width === '500px' ? 'none' : 'inline-flex', borderRadius: '30px', border: '2px solid #FF0060' }}>
                        <div className='mx-auto my-auto  text-[22px] font-bold'> get started </div>
                    </div>
                    <div className='menu-hidden' style={{display:width === '500px' ? 'inline-flex' : null}} onClick={() => setShow(!show)}>
                        {!show ? <MenuComplex fill='#FF0060' /> : <CloseIcon sx={{ color: '#FF0060', fontSize: '40px' }} />}
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px' ? 'inline-flex' : null}}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#020E17',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#FF0060' : '#020E17' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#FF0060' : '#020E17' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>

                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#020E17', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}
                    <div className='w-100 mt-8'>
                        <div className=' uppercase flex text-[#000000] hover:text-[#FF0060] bg-[transparent] mb-2 mx-auto' style={{ width: '226px', height: '60px', borderRadius: '30px', border: '2px solid #FF0060' }}>
                            <div className='mx-auto my-auto text-[22px] font-bold'> get started </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>,


        <div className="header w-full h-auto py-2" style={{ borderBottom: '1px solid #F31559', background: '#FFF', borderRadius: '0px' }}>
            <div className="container">
                <div className="w-full header-wrapper">
                    <div className='left-header' style={{ justifyContent: 'space-between' }}>
                        <div className='logo-aria'>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#F31559'}><path d={logoIcon?.path} /></svg>
                        </div>
                            <div className='logo-content  my-auto' style={{ color: '#F31559' }}>
                                {logoText || 'Logo'}
                            </div>
                        </div>


                    </div>
                    <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                        {treeDatas.map(x => (
                            <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                    onMouseMove={() => setChildrenArray(true)}
                                    onMouseOutCapture={() => setChildrenArray(true)}
                                >
                                    <div className='flex flex-row'>
                                        <div className='dropbtn'
                                            id='homeItem'
                                            val={x.name}
                                            _id="sidebarItemx"
                                            style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#020E17', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                            }}>
                                            {
                                                (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                            }

                                        </div>
                                        {x.children.length > 0 && <div className='Arrow my-auto'>
                                            <KeyboardArrowDownIcon sx={{ color: '#F31559' }} />
                                        </div>}
                                    </div>
                                    <div className=' dropdown-content flex flex-row justify-center' style={{ borderRadius: '4px', background:'#F31559' }}>
                                        {x.children ? x.children.map(child => (

                                            <div key={child._id} _id={"sidebarItemx"} val={child.name} >
                                                <div
                                                    className='item text-center '
                                                    style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#fff', fontWeight: '400' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                    }}>
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>

                                        )) : null}                    </div>


                                </div>
                            </div>
                        ))}

                    </div>

                    <div className='header-button  right-header uppercase flex bg-[#F31559]  hover:bg-[#FF5B8D] ' style={{ width: '226px', height: '60px', display:width === '500px' ? 'none' : 'inline-flex', borderRadius: '8px' }}>
                        <div className='mx-auto my-auto text-[#fff] text-[22px] font-bold'> get started </div>
                    </div>
                    <div className='menu-hidden' style={{display:width === '500px' ? 'inline-flex' : null}} onClick={() => setShow(!show)}>
                        {!show ? <MenuComplex fill='#F31559' /> : <CloseIcon sx={{ color: '#F31559', fontSize: '40px' }} />}
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px' ? 'inline-flex' : null}}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#020E17',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#F31559' : '#020E17' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#F31559' : '#020E17' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>

                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#020E17', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}
                    <div className='w-100 mt-8'>
                        <div className=' uppercase flex bg-[#F31559]  hover:bg-[#FF5B8D] mb-2 mx-auto' style={{ width: '226px', height: '60px', borderRadius: '8px' }}>
                            <div className='mx-auto my-auto text-[#fff] text-[22px] font-bold'> get started </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>,


        <div className="header w-full h-auto py-2" style={{ borderBottom: '1px solid #D4ADFC', background: '#FFF', borderRadius: '0px' }}>
            <div className="container">
                <div className="w-full header-wrapper">
                    <div className='left-header' style={{ justifyContent: 'space-between' }}>
                        <div className='logo-aria'>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#0C134F'}><path d={logoIcon?.path} /></svg>
                        </div>
                            <div className='logo-content  my-auto' style={{ color: '#0C134F' }}>
                                {logoText || 'Logo'}
                            </div>
                        </div>


                    </div>
                    <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                        {treeDatas.map(x => (
                            <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                    onMouseMove={() => setChildrenArray(true)}
                                    onMouseOutCapture={() => setChildrenArray(true)}
                                >
                                    <div className='flex flex-row'>
                                        <div className='dropbtn'
                                            id='homeItem'
                                            val={x.name}
                                            _id="sidebarItemx"
                                            style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#020E17', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                            }}>
                                            {
                                                (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                            }

                                        </div>
                                        {x.children.length > 0 && <div className='Arrow my-auto'>
                                            <KeyboardArrowDownIcon sx={{ color: '#0C134F' }} />
                                        </div>}
                                    </div>
                                    <div className=' dropdown-content flex flex-row justify-center' style={{ borderRadius: '4px', background:'#0C134F' }}>
                                        {x.children ? x.children.map(child => (

                                            <div key={child._id} _id={"sidebarItemx"} val={child.name} >
                                                <div
                                                    className='item text-center '
                                                    style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#fff', fontWeight: '400' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                    }}>
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>

                                        )) : null}                    </div>


                                </div>
                            </div>
                        ))}

                    </div>

                    <div className='header-button  right-header uppercase flex bg-[#D4ADFC] hover:bg-[#C68EFF] ' style={{ width: '226px', display:width === '500px' ? 'none' : 'inline-flex', height: '60px', borderRadius: '8px' }}>
                        <div className='mx-auto my-auto text-[#0C134F] text-[22px] font-bold'> get started </div>
                    </div>
                    <div className='menu-hidden' style={{display:width === '500px' ? 'inline-flex' : null}} onClick={() => setShow(!show)}>
                        {!show ? <MenuComplex fill='#0C134F' /> : <CloseIcon sx={{ color: '#0C134F', fontSize: '40px' }} />}
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px' ? 'inline-flex' : null}}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#020E17',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#0C134F' : '#020E17' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#0C134F' : '#020E17' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>

                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#020E17', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}
                    <div className='w-100 mt-8'>
                        <div className=' uppercase flex bg-[#D4ADFC] hover:bg-[#C68EFF] mb-2 mx-auto' style={{ width: '226px', height: '60px', borderRadius: '8px' }}>
                            <div className='mx-auto my-auto text-[#0C134F] text-[22px] font-bold'> get started </div>
                        </div>
                    </div>
                </div>}
            </div> 
        </div>,

        <div className='header2' >
            <div className='flex flex-col'>
                <div className='wrapper-header2' style={{ background: '#E5E5E5', borderRadius: '40px', padding: '12px 32px', margin: '10px 0', }}>
                    <div className='left-header'>
                    <div className='logo-aria'>
                        <div className='w-[40px] h-[40px] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                        </div>
                        <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>

                            {treeDatas.map(x => (
                                <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                    <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                        onMouseMove={() => setChildrenArray(true)}
                                        onMouseOutCapture={() => setChildrenArray(true)}
                                    >
                                        <div className='flex flex-row'>
                                            <div className='dropbtn'
                                                id='homeItem'
                                                val={x.name}
                                                _id="sidebarItemx"
                                                style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000000' : '#00000099', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                                }}>
                                                {
                                                    (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                }

                                            </div>
                                            {x.children.length > 0 && <div className='Arrow my-auto'>
                                                <KeyboardArrowDownIcon sx={{ color: '#000' }} />
                                            </div>}
                                        </div>
                                        <div className='dropdown-content style-1 flex flex-row justify-center' style={{ borderRadius: '4px' }}>
                                            {x.children ? x.children.map(child => (

                                                <div key={child._id}>
                                                    <div
                                                        className='item text-center '
                                                        style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: 'inherit', fontWeight: '400' }}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                        }}>
                                                        {
                                                            activeId?._id === child?._id
                                                                ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                                : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                        }
                                                    </div>
                                                </div>

                                            )) : null}                    </div>


                                    </div>
                                </div>
                            ))}


                        </div>

                    </div>


                    <div className='buttons-aria'>
                        <div className='d-none button-outline'>
                            <div className='button-txt'>Register</div>
                        </div>
                        <div className='button-full'>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Log in</div>
                        </div>
                        <div className='menu-hidden' style={{display:width === '500px'? 'inline-flex' : null, marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#000' /> : <CloseIcon sx={{ color: '#000', fontSize: '40px' }} />}
                        </div>
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px'? 'inline-flex' : null, background: '#E5E5E5', borderRadius: '40px', margin: '0 0 10px 0', padding: '24px' }}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#000',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#000' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#fff', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>

                    ))}
                    <div className='d-none button-outline-sm' style={{ borderColor: 'black' }}>
                        <div className='button-txt' style={{ color: '#000' }}>Register</div>
                    </div>
                </div>}

            </div>
        </div>,


        <div className='header2' >
            <div className='flex flex-col'>
                <div className='wrapper-header2' style={{ background: '#424146', borderRadius: '40px', padding: '12px 32px', margin: '10px 0' }}>
                    <div className='left-header'>
                     <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#FFFFFF'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                            {treeDatas.map(x => (
                                <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                    <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                        onMouseMove={() => setChildrenArray(true)}
                                        onMouseOutCapture={() => setChildrenArray(true)}
                                    >
                                        <div className='flex flex-row'>
                                            <div className='dropbtn'
                                                id='homeItem'
                                                val={x.name}
                                                _id="sidebarItemx"
                                                style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                                }}>
                                                {
                                                    (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                }

                                            </div>
                                            {x.children.length > 0 && <div className='Arrow my-auto'>
                                                <KeyboardArrowDownIcon sx={{ color: '#fff' }} />
                                            </div>}
                                        </div>
                                        <div className='dropdown-content style-2 flex flex-row justify-center' style={{ borderRadius: '4px' }}>
                                            {x.children ? x.children.map(child => (

                                                <div key={child._id}>
                                                    <div
                                                        className='item text-center '
                                                        style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#fff', fontWeight: '400' }}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                        }}>
                                                        {
                                                            activeId?._id === child?._id
                                                                ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                                : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                        }
                                                    </div>
                                                </div>

                                            )) : null}                    </div>


                                    </div>
                                </div>
                            ))}


                        </div>

                    </div>

                    <div className='buttons-aria'>
                        <div className='d-none button-outline' style={{ borderColor: 'white' }}>
                            <div className='button-txt' style={{ color: '#fff' }}>Register</div>
                        </div>
                        <div className='button-full' style={{ background: '#9007D2' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Log in</div>
                        </div>

                        <div className='menu-hidden' style={{display:width === '500px'? 'inline-flex' : null, marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#fff' /> : <CloseIcon sx={{ color: '#fff', fontSize: '40px' }} />}
                        </div>
                    </div>

                </div>
                {show && <div className='items-hidden' style={{display:width === '500px'? 'inline-flex' : null, background: '#424146', borderRadius: '20px', margin: '0 0 10px 0', padding: '24px' }}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#fff', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}
                    <div className='d-none button-outline-sm' style={{ borderColor: 'white' }}>
                        <div className='button-txt' style={{ color: '#fff' }}>Register</div>
                    </div>
                </div>}
            </div>
        </div>,


        <div className='header2' >
            <div className='flex flex-col'>
                <div className='wrapper-header2' style={{ background: '#DDEDFB', borderRadius: '40px', padding: '12px 32px', margin: '10px 0' }}>
                    <div className='left-header'>
                     <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                            {treeDatas.map(x => (
                                <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                    <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                        onMouseMove={() => setChildrenArray(true)}
                                        onMouseOutCapture={() => setChildrenArray(true)}
                                    >
                                        <div className='flex flex-row'>
                                            <div className='dropbtn'
                                                id='homeItem'
                                                val={x.name}
                                                _id="sidebarItemx"
                                                style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000000' : '#00000099', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                                }}>
                                                {
                                                    (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                }

                                            </div>
                                            {x.children.length > 0 && <div className='Arrow my-auto'>
                                                <KeyboardArrowDownIcon sx={{ color: '#000' }} />
                                            </div>}
                                        </div>
                                        <div className='dropdown-content style-3 flex flex-row justify-center' style={{ borderRadius: '4px' }}>
                                            {x.children ? x.children.map(child => (

                                                <div key={child._id}>
                                                    <div
                                                        className='item text-center '
                                                        style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: 'inherit', fontWeight: '400' }}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                        }}>
                                                        {
                                                            activeId?._id === child?._id
                                                                ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                                : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                        }
                                                    </div>
                                                </div>

                                            )) : null}                    </div>


                                    </div>
                                </div>
                            ))}


                        </div>

                    </div>


                    <div className='buttons-aria'>
                        <div className='d-none button-outline'>
                            <div className='button-txt'>Register</div>
                        </div>
                        <div className='button-full'>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Log in</div>
                        </div>
                        <div className='menu-hidden' style={{display:width === '500px'? 'inline-flex' : null, marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#000' /> : <CloseIcon sx={{ color: '#000', fontSize: '40px' }} />}
                        </div>
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px'? 'inline-flex' : null, background: '#DDEDFB', borderRadius: '40px', margin: '0 0 10px 0', padding: '24px' }}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#000',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#000' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#fff', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>

                    ))}
                    <div className='d-none button-outline-sm' style={{ borderColor: 'black' }}>
                        <div className='button-txt' style={{ color: '#000' }}>Register</div>
                    </div>
                </div>}
            </div>
        </div>,



        <div className='header2' >
            <div className='flex flex-col'>
                <div className='wrapper-header2' style={{ background: '#CADAD4', borderRadius: '40px', padding: '12px 32px', margin: '10px 0' }}>
                    <div className='left-header'>
                     <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                            {treeDatas.map(x => (
                                <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                    <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                        onMouseMove={() => setChildrenArray(true)}
                                        onMouseOutCapture={() => setChildrenArray(true)}
                                    >
                                        <div className='flex flex-row'>
                                            <div className='dropbtn'
                                                id='homeItem'
                                                val={x.name}
                                                _id="sidebarItemx"
                                                style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000000' : '#00000099', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                                }}>
                                                {
                                                    (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                }

                                            </div>
                                            {x.children.length > 0 && <div className='Arrow my-auto'>
                                                <KeyboardArrowDownIcon sx={{ color: '#000' }} />
                                            </div>}
                                        </div>
                                        <div className='dropdown-content style-4 flex flex-row justify-center' style={{ borderRadius: '4px' }}>
                                            {x.children ? x.children.map(child => (

                                                <div key={child._id}>
                                                    <div
                                                        className='item text-center '
                                                        style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: 'inherit', fontWeight: '400' }}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                        }}>
                                                        {
                                                            activeId?._id === child?._id
                                                                ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                                : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                        }
                                                    </div>
                                                </div>

                                            )) : null}                    </div>


                                    </div>
                                </div>
                            ))}


                        </div>

                    </div>


                    <div className='buttons-aria'>
                        <div className='d-none button-outline'>
                            <div className='button-txt'>Register</div>
                        </div>
                        <div className='button-full' style={{ background: '#3DA169' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Log in</div>
                        </div>
                        <div className='menu-hidden' style={{display:width === '500px'? 'inline-flex' : null, marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#000' /> : <CloseIcon sx={{ color: '#000', fontSize: '40px' }} />}
                        </div>
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px'? 'inline-flex' : null, background: '#CADAD4', borderRadius: '20px', margin: '0 0 10px 0', padding: '24px' }}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#000',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#000' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#fff', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>

                    ))}
                    <div className='d-none button-outline-sm' style={{ borderColor: 'black' }}>
                        <div className='button-txt' style={{ color: '#000' }}>Register</div>
                    </div>
                </div>}
            </div>
        </div>,


        <div className='header2' >
            <div className='flex flex-col'>
                <div className='wrapper-header2' style={{ background: '#FFFFFF', borderRadius: '40px', padding: '12px 32px', margin: '10px 0' }}>
                    <div className='left-header'>
                     <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                            {treeDatas.map(x => (
                                <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                    <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                        onMouseMove={() => setChildrenArray(true)}
                                        onMouseOutCapture={() => setChildrenArray(true)}
                                    >
                                        <div className='flex flex-row'>
                                            <div className='dropbtn'
                                                id='homeItem'
                                                val={x.name}
                                                _id="sidebarItemx"
                                                style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000000' : '#00000099', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                                }}>
                                                {
                                                    (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                }

                                            </div>
                                            {x.children.length > 0 && <div className='Arrow my-auto'>
                                                <KeyboardArrowDownIcon sx={{ color: '#000' }} />
                                            </div>}
                                        </div>
                                        <div className='dropdown-content style-5 flex flex-row justify-center' style={{ borderRadius: '4px' }}>
                                            {x.children ? x.children.map(child => (

                                                <div key={child._id}>
                                                    <div
                                                        className='item text-center '
                                                        style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: 'inherit', fontWeight: '400' }}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                        }}>
                                                        {
                                                            activeId?._id === child?._id
                                                                ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                                : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                        }
                                                    </div>
                                                </div>

                                            )) : null}                    </div>


                                    </div>
                                </div>
                            ))}


                        </div>

                    </div>


                    <div className='buttons-aria'>
                        <div className='d-none button-outline'>
                            <div className='button-txt'>Register</div>
                        </div>
                        <div className='button-full' style={{ background: '#FF0560' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Log in</div>
                        </div>
                        <div className='menu-hidden' style={{display:width === '500px'? 'inline-flex' : null, marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#000' /> : <CloseIcon sx={{ color: '#000', fontSize: '40px' }} />}
                        </div>
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px'? 'inline-flex' : null, background: '#FFFFFF', borderRadius: '20px', margin: '0 0 10px 0', padding: '24px' }}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#000',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#000' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#fff', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>

                    ))}
                    <div className='d-none button-outline-sm' style={{ borderColor: 'black' }}>
                        <div className='button-txt' style={{ color: '#000' }}>Register</div>
                    </div>
                </div>}
            </div>
        </div>,


        <div className='header2' >
            <div className='flex flex-col'>
                <div className='wrapper-header2' style={{ background: '#4C3856', borderRadius: '40px', padding: '12px 32px', margin: '10px 0' }}>
                    <div className='left-header'>
                     <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#ffffff'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                            {treeDatas.map(x => (
                                <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                    <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                        onMouseMove={() => setChildrenArray(true)}
                                        onMouseOutCapture={() => setChildrenArray(true)}
                                    >
                                        <div className='flex flex-row'>
                                            <div className='dropbtn'
                                                id='homeItem'
                                                val={x.name}
                                                _id="sidebarItemx"
                                                style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                                }}>
                                                {
                                                    (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                }

                                            </div>
                                            {x.children.length > 0 && <div className='Arrow my-auto'>
                                                <KeyboardArrowDownIcon sx={{ color: '#fff' }} />
                                            </div>}
                                        </div>
                                        <div className='dropdown-content style-6 flex flex-row justify-center' style={{ borderRadius: '4px' }}>
                                            {x.children ? x.children.map(child => (

                                                <div key={child._id}>
                                                    <div
                                                        className='item text-center '
                                                        style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#fff', fontWeight: '400' }}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                        }}>
                                                        {
                                                            activeId?._id === child?._id
                                                                ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                                : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                        }
                                                    </div>
                                                </div>

                                            )) : null}                    </div>


                                    </div>
                                </div>
                            ))}


                        </div>

                    </div>

                    <div className='buttons-aria'>
                        <div className='d-none button-outline' style={{ borderColor: 'white' }}>
                            <div className='button-txt' style={{ color: '#fff' }}>Register</div>
                        </div>
                        <div className='button-full' style={{ background: '#950B67' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Log in</div>
                        </div>

                        <div className='menu-hidden' style={{display:width === '500px'? 'inline-flex' : null, marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#fff' /> : <CloseIcon sx={{ color: '#fff', fontSize: '40px' }} />}
                        </div>
                    </div>

                </div>
                {show && <div className='items-hidden' style={{display:width === '500px'? 'inline-flex' : null, background: '#4C3856', borderRadius: '20px', margin: '0 0 10px 0', padding: '24px' }}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#fff', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}
                    <div className='d-none button-outline-sm' style={{ borderColor: 'white' }}>
                        <div className='button-txt' style={{ color: '#fff' }}>Register</div>
                    </div>
                </div>}
            </div>
        </div>,


        <div className='header2' >
            <div className='flex flex-col'>
                <div className='wrapper-header2' style={{ background: '#CCF2FA', borderRadius: '40px', padding: '12px 32px', margin: '10px 0' }}>
                    <div className='left-header'>
                     <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                            {treeDatas.map(x => (
                                <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                    <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                        onMouseMove={() => setChildrenArray(true)}
                                        onMouseOutCapture={() => setChildrenArray(true)}
                                    >
                                        <div className='flex flex-row'>
                                            <div className='dropbtn'
                                                id='homeItem'
                                                val={x.name}
                                                _id="sidebarItemx"
                                                style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000000' : '#00000099', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                                }}>
                                                {
                                                    (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                }

                                            </div>
                                            {x.children.length > 0 && <div className='Arrow my-auto'>
                                                <KeyboardArrowDownIcon sx={{ color: '#000' }} />
                                            </div>}
                                        </div>
                                        <div className='dropdown-content style-7 flex flex-row justify-center' style={{ borderRadius: '4px' }}>
                                            {x.children ? x.children.map(child => (

                                                <div key={child._id}>
                                                    <div
                                                        className='item text-center '
                                                        style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: 'inherit', fontWeight: '400' }}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                        }}>
                                                        {
                                                            activeId?._id === child?._id
                                                                ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                                : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                        }
                                                    </div>
                                                </div>

                                            )) : null}                    </div>


                                    </div>
                                </div>
                            ))}


                        </div>

                    </div>


                    <div className='buttons-aria'>
                        <div className='d-none button-outline'>
                            <div className='button-txt'>Register</div>
                        </div>
                        <div className='button-full' style={{ background: '#FE490B' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Log in</div>
                        </div>
                        <div className='menu-hidden' style={{display:width === '500px'? 'inline-flex' : null, marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#000' /> : <CloseIcon sx={{ color: '#000', fontSize: '40px' }} />}
                        </div>
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px'? 'inline-flex' : null, background: '#CCF2FA', borderRadius: '20px', margin: '0 0 10px 0', padding: '24px' }}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#000',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#000' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#fff', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>

                    ))}
                    <div className='d-none button-outline-sm' style={{ borderColor: 'black' }}>
                        <div className='button-txt' style={{ color: '#000' }}>Register</div>
                    </div>
                </div>}
            </div>
        </div>,


        <div className='header2' >
            <div className='flex flex-col'>
                <div className='wrapper-header2' style={{ background: '#311043', borderRadius: '40px', padding: '12px 32px', margin: '10px 0' }}>
                    <div className='left-header'>
                     <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#ffffff'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                            {treeDatas.map(x => (
                                <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                    <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                        onMouseMove={() => setChildrenArray(true)}
                                        onMouseOutCapture={() => setChildrenArray(true)}
                                    >
                                        <div className='flex flex-row'>
                                            <div className='dropbtn'
                                                id='homeItem'
                                                val={x.name}
                                                _id="sidebarItemx"
                                                style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                                }}>
                                                {
                                                    (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                }

                                            </div>
                                            {x.children.length > 0 && <div className='Arrow my-auto'>
                                                <KeyboardArrowDownIcon sx={{ color: '#fff' }} />
                                            </div>}
                                        </div>
                                        <div className='dropdown-content style-8 flex flex-row justify-center' style={{ borderRadius: '4px' }}>
                                            {x.children ? x.children.map(child => (

                                                <div key={child._id}>
                                                    <div
                                                        className='item text-center '
                                                        style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#fff', fontWeight: '400' }}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                        }}>
                                                        {
                                                            activeId?._id === child?._id
                                                                ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                                : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                        }
                                                    </div>
                                                </div>

                                            )) : null}                    </div>


                                    </div>
                                </div>
                            ))}


                        </div>

                    </div>

                    <div className='buttons-aria'>
                        <div className='d-none button-outline' style={{ borderColor: 'white' }}>
                            <div className='button-txt' style={{ color: '#fff' }}>Register</div>
                        </div>
                        <div className='button-full' style={{ background: '#D423A5' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Log in</div>
                        </div>

                        <div className='menu-hidden' style={{display:width === '500px'? 'inline-flex' : null, marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#fff' /> : <CloseIcon sx={{ color: '#fff', fontSize: '40px' }} />}
                        </div>
                    </div>

                </div>
                {show && <div className='items-hidden' style={{display:width === '500px'? 'inline-flex' : null, background: '#311043', borderRadius: '20px', margin: '0 0 10px 0', padding: '24px' }}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#fff', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}
                    <div className='d-none button-outline-sm' style={{ borderColor: 'white' }}>
                        <div className='button-txt' style={{ color: '#fff' }}>Register</div>
                    </div>
                </div>}
            </div>
        </div>,



        <div className='header2'>
            <div className='flex flex-col'>
                <div className='wrapper-header2' style={{ background: '#BFF0F0', borderRadius: '40px', padding: '12px 32px', margin: '10px 0' }}>
                    <div className='left-header'>
                     <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                            {treeDatas.map(x => (
                                <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                    <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                        onMouseMove={() => setChildrenArray(true)}
                                        onMouseOutCapture={() => setChildrenArray(true)}
                                    >
                                        <div className='flex flex-row'>
                                            <div className='dropbtn'
                                                id='homeItem'
                                                val={x.name}
                                                _id="sidebarItemx"
                                                style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000000' : '#00000099', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                                }}>
                                                {
                                                    (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                }

                                            </div>
                                            {x.children.length > 0 && <div className='Arrow my-auto'>
                                                <KeyboardArrowDownIcon sx={{ color: '#000' }} />
                                            </div>}
                                        </div>
                                        <div className='dropdown-content style-9 flex flex-row justify-center' style={{ borderRadius: '4px' }}>
                                            {x.children ? x.children.map(child => (

                                                <div key={child._id}>
                                                    <div
                                                        className='item text-center '
                                                        style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: 'inherit', fontWeight: '400' }}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                        }}>
                                                        {
                                                            activeId?._id === child?._id
                                                                ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                                : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                        }
                                                    </div>
                                                </div>

                                            )) : null}                    </div>


                                    </div>
                                </div>
                            ))}


                        </div>

                    </div>


                    <div className='buttons-aria'>
                        <div className='d-none button-outline'>
                            <div className='button-txt'>Register</div>
                        </div>
                        <div className='button-full' style={{ background: '#02C8B4' }}>
                            <div><IconPerson fill='#000' /></div>
                            <div className='button-txt' style={{ color: '#000' }}>Log in</div>
                        </div>
                        <div className='menu-hidden' style={{display:width === '500px'? 'inline-flex' : null, marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#000' /> : <CloseIcon sx={{ color: '#000', fontSize: '40px' }} />}
                        </div>
                    </div>
                </div>
                {show && <div className='items-hidden' style={{display:width === '500px'? 'inline-flex' : null, background: '#BFF0F0', borderRadius: '20px', margin: '0 0 10px 0', padding: '24px' }}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#000',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#000' : '#000' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#fff', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>

                    ))}
                    <div className='d-none button-outline-sm' style={{ borderColor: 'black' }}>
                        <div className='button-txt' style={{ color: '#000' }}>Register</div>
                    </div>
                </div>}
            </div>
        </div>,



        <div className='header2' >
            <div className='flex flex-col'>
                <div className='wrapper-header2' style={{ background: '#78838B', borderRadius: '40px', padding: '12px 32px', margin: '10px 0' }}>
                    <div className='left-header'>
                     <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill || '#ffffff'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='item-list-header' style={{display:width === '500px' ? 'none' : null}}>
                            {treeDatas.map(x => (
                                <div key={x._id} style={{ width: "7vw" }} className='my-auto itemtohover'>
                                    <div style={{ width: "100%" }} className='flex flex-col align-items dropdown'
                                        onMouseMove={() => setChildrenArray(true)}
                                        onMouseOutCapture={() => setChildrenArray(true)}
                                    >
                                        <div className='flex flex-row'>
                                            <div className='dropbtn'
                                                id='homeItem'
                                                val={x.name}
                                                _id="sidebarItemx"
                                                style={{ width: "100%", textDecoration: 'none', cursor: "pointer", color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff', fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400' }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                                }}>
                                                {
                                                    (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                }

                                            </div>
                                            {x.children.length > 0 && <div className='Arrow my-auto'>
                                                <KeyboardArrowDownIcon sx={{ color: '#fff' }} />
                                            </div>}
                                        </div>
                                        <div className='dropdown-content style-10 flex flex-row justify-center' style={{ borderRadius: '4px' }}>
                                            {x.children ? x.children.map(child => (

                                                <div key={child._id}>
                                                    <div
                                                        className='item text-center '
                                                        style={{ textDecoration: 'none', width: 'auto', fontSize: '13px', cursor: "pointer", color: '#fff', fontWeight: '400' }}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            activeId._id == child._id ? setActiveId(x) : setActiveId(child)

                                                        }}>
                                                        {
                                                            activeId?._id === child?._id
                                                                ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                                : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                        }
                                                    </div>
                                                </div>

                                            )) : null}
                                        </div>
                                    </div>
                                </div>
                            ))}


                        </div>

                    </div>

                    <div className='buttons-aria'>
                        <div className='d-none button-outline' style={{ borderColor: 'white' }}>
                            <div className='button-txt' style={{ color: '#fff' }}>Register</div>
                        </div>
                        <div className='button-full' style={{ background: '#C7D9E3' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Log in</div>
                        </div>

                        <div className='menu-hidden' style={{display:width === '500px'? 'inline-flex' : null, marginLeft: '8px' }} onClick={() => setShow(!show)}>
                            {!show ? <MenuIcon3 fill='#fff' /> : <CloseIcon sx={{ color: '#fff', fontSize: '40px' }} />}
                        </div>
                    </div>

                </div>
                {show && <div className='items-hidden' style={{display:width === '500px'? 'inline-flex' : null, background: '#78838B', borderRadius: '20px', margin: '0 0 10px 0', padding: '24px' }}>
                    {treeDatas.map(x => (
                        <div key={x._id} className='w-full mx-2 my-2 min-w-[250px] flex flex-col align-items'>
                            <div className='flex flex-row'>
                                <div
                                    className='item'
                                    id='homeItem'
                                    val={x.name}
                                    _id="sidebarItemx"
                                    style={{
                                        textAlign: 'left',
                                        width: "100%",
                                        textDecoration: 'none',
                                        cursor: "pointer",
                                        color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff',
                                        fontWeight: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '700' : '400'
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        x.children.some(z => z._id == activeId._id) ? setActiveId(x.children.find(z => z._id == activeId._id)) : setActiveId(x)
                                    }}
                                >
                                    {
                                        (x?.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                    }

                                </div>
                                {x.children?.length ? (
                                    <div className='my-auto' onClick={() => (activeId._id === x._id || x.children.some(y => y._id === activeId._id)) && x.children && setOpen(!open)}>
                                        {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? <KeyboardArrowUpIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff' }} /> :
                                            <KeyboardArrowDownIcon sx={{ fontSize: '16px', color: activeId?._id === x._id || x.children?.some((y) => y?._id == activeId?._id) ? '#fff' : '#fff' }} />
                                        }
                                    </div>
                                ) : null}

                            </div>


                            {open && (x?.children?.some(y => y._id == activeId._id) || (activeId?._id === x._id && activeId.children)) ? (
                                <div className='dropdown my-2 flex align-center'>
                                    <div style={{ width: "auto", borderRadius: '4px' }}>
                                        {x.children.map(child => (
                                            <div key={child._id}>
                                                <div
                                                    className='item'
                                                    style={{ padding: '0 0 0 10px', textDecoration: 'none', fontSize: '12px', cursor: "pointer", color: '#fff', fontWeight: '400', textAlign: 'left' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (activeId?._id === child?._id) {
                                                            setActiveId(x);
                                                        } else {
                                                            setActiveId(child);
                                                        }
                                                        setOpen(false);
                                                    }}
                                                >
                                                    {
                                                        activeId?._id === child?._id
                                                            ? (x.name?.length > 11 ? x.name.slice(0, 10) + "..." : x?.name)
                                                            : (child?.name?.length > 11 ? x.name.slice(0, 10) + "..." : child?.name)
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null
                            }

                        </div>
                    ))}
                    <div className='d-none button-outline-sm' style={{ borderColor: 'white' }}>
                        <div className='button-txt' style={{ color: '#fff' }}>Register</div>
                    </div>
                </div>}
            </div>
        </div>,



    ]

    return (
        <div className={`w-full h-full relative autowhen ${navigationSide && enabled ? "bordered" : ""}`} ref={componentRef} onClick={(e) => {
            e.stopPropagation()
            setNavigationSide(true)

        }}>

            {header.map((content, index) => (
                <div
                    key={index}
                    style={{ display: index === currentStyleIndex ? 'block' : 'none' }}
                >
                    {content}
                </div>
            ))}
        </div>
    )
}