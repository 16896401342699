/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import { withStyles } from '@mui/styles';
// import { UndoIcon } from '../../Viewport/EditorSvg';
import { Slider as Small } from '@mui/material';
import { UndoIcon } from '../../../Viewport/EditorSvg';
import { useState } from 'react';


const SliderStyled = withStyles({
    root: {
        margin: 'auto auto',
        width: '33%',
        color: '#3880ff',
        height: '3vmin',
        padding: '5% 0',
    },
    thumb: {
        height: '2vmin',
        width: '2vmin',
        backgroundColor: '#0867FC',
    },

    active: {},
    valueLabel: {
        left: 'calc(-50% + 1.1%)',
        top: '-22%',
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },

    rail: {
        height: '2%',
        opselectedIconacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: '8%',
        width: '10%',
        marginTop: '-3%',
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Small);

export const SliderType = ({
    has,
    isDarkMode,
    property,
    el,
    setProp,
    node,
    propKey,
    shadow,
    value2,
    value,
    propValue,

    options
}) => {

    
    const unitOptions = ['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']
    const [unit, setUnit] = useState('em')

   const handleUnit = (unit) => {
      setUnit(unit)
    }

    const handleSliderChange = (el, has, newValue, unit) => {
        setProp(node.id, (props) => {
            props[propKey][el][has].value = `${newValue}px`;
        });
      };

      const handleInputChange = (el, has, newValue) => {
        setProp(node.id, (props) => {
          props[propKey][el][has].value = `${newValue}px`;
        });
      };
      
      const handleReset = (el, has) => {
        setProp(node.id, (props) => {
          props[propKey][el][has].value = null;
        });
        console.log('reset')
      };


    return (
        <div
            className='w-[100%] flex flex-column wrapper-slide justify-between my-[2vmin] rounded-[2vmin]'>
            <div className={` items-center flex justify-start text-left w-[fit-content]`}
                style={{ fontSize: '2.2vmin', fontWeight: '500', fontFamily: 'Inter', marginBottom:'1vmin' }}>{has}</div>
                {/* {shadow && (
                    <div style={{border:'.25vmin solid #e9e9e9', padding:'.5vmin', borderRadius:'16px', display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
                        <div style={{flex:'1', textAlign:'center'}}>Px 1</div>
                        <div style={{flex:'1', textAlign:'center'}}>Px 2</div>
                        <div style={{flex:'1', textAlign:'center'}}>Px 3</div>
                        <div style={{flex:'1', textAlign:'center'}}>Color</div>
                    </div>
                )} */}
            {!shadow && <div className='px-[1.5Vmin] mx-auto flex flex-row justify-between flex-wrap' style={{ width: '100%' }}>

                <SliderStyled
                    className='mx-auto my-auto justify-center flex flex-1 max-w-[70%] items-center'
                    style={{ border: '.25vmin solid transparent' }}
                    min={0}
                    max={1000}
                    value={parseInt(property.value) || 0}
                    onChange={(e) => {
                        let newValue = e.target.value
                        handleSliderChange(el, has, newValue)
                      }}
                    // onChange={
                    //     ((_, value) => {
                    //         let numberValue = value || propValue?.split(" ")[0]
                    //         let unitValue = propValue?.split(" ")[1] || 'px'

                    //         let finalvalue = [numberValue, unitValue]

                    //         let valueWithUnit = activeState === 'Hover' ? finalvalue.join("") : finalvalue.join(" ")

                    //         console.log(valueWithUnit)
                           
                    //         setProp(node.id, (props) => {
                    //             if ( node?.data?.props["isWrapper"] && !props.actionSelect[propKey]) {
                    //                 props.actionSelect[propKey] = {};
                    //               }
                                  
                    //               if (activeState == "Default") { 
                    //                 props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                    //               } else if (activeState == "Hover") { 
                    //                 props.actionSelect[propKey].hover = onChange ? onChange(valueWithUnit) : valueWithUnit;
                    //               } else if (activeState == "Scroll") { 
                    //                 props.actionSelect[propKey].scroll = onChange ? onChange(valueWithUnit) : valueWithUnit;
                    //               } else if (Array.isArray(propValue)) {
                    //                 props[propKey][index] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                    //               } else {
                    //                 props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                    //               }

                    //         }, 1000)
                    //     })
                    // }

                />
                <div className='value-props shadow-md p-[.5vmin] rounded-[2.5vmin]' style={{ color: isDarkMode ? '#fff' : '#000' }}>
                    <div className='my-auto mx-auto px-[1vmin] text-center flex-1 text-center' style={{ justifyContent: 'center', minWidth: '5vmin' }}>
                        <input style={{ color: isDarkMode ? '#fff' : '#000', fontSize: '2vmin', textAlign: 'center' }}
                        value={parseInt(property.value) || 0}

                            placeholder='0' 
                            onChange={(e) => {
                                let newValue = e.target.value
                                handleInputChange(el, has, newValue)
                            }}
                            
                            // onChange={ 
                            //     ((e, value) => {
                            //         value = e.target.value
                            //         let numberValue = value || propValue?.split(" ")[0]
                            //         let unitValue = propValue?.split(" ")[1] || "px"
                            //         let finalvalue = [numberValue, unitValue]
                            //         let valueWithUnit = finalvalue.join(" ")
                            //         setProp(node.id, (props) => {
                            //             if (!props.actionSelect[propKey]) {
                            //                 props.actionSelect[propKey] = {};
                            //               }
                                          
                            //               if (activeState == "Default") { 
                            //                 props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //               } else if (activeState == "Hover") { 
                            //                 props.actionSelect[propKey].hover = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //               } else if (activeState == "Scroll") { 
                            //                 props.actionSelect[propKey].scroll = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //               } else if (Array.isArray(propValue)) {
                            //                 props[propKey][index] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //               } else {
                            //                 props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //               }
        
                            //         }, 1000)
                            //     })
                            // }
                        />
                    </div>

                    <div className='flex flex-1 cursor-pointer flex-row' onClick={() => handleReset(el, has)}>
                        <UndoIcon fill={`${(options[propKey][el][has].value && options[propKey][el][has].value !== null) ? '#0867FC' : '#989191'}`} />
                        <div className={`${(options[propKey][el][has].value && options[propKey][el][has].value !== null)  ? 'text-[#0867FC]' : 'text-[#989191]'} text-[2vmin] my-auto flex`} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Reset</div>
                    </div>


                    
                </div>
            </div>}
        </div>
    )
}

