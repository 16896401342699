import React from "react"
import useAuth from "./hooks/useAuth";


export const SSO = () => {


    const [isLogin, token] = useAuth();


    return (
        <div>
            {  isLogin ? "hello" : "noooo"}
        </div>
    )
}