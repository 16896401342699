/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useNode, useEditor } from '@craftjs/core';
import React from 'react';
import ContentEditable from 'react-contenteditable';
import { QuoteSettings } from './QuoteSettings';
import { useActiveIdContext } from '../../../../ActiveIdContext';
import { useOptionsContext } from '../../../../OptionsContext';

export const Quote = ({
  fontSize,
  textAlign,
  fontWeight,
  color,
  shadow,
  text,
  margin,
  href,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  selected,
  textDecoration,
  fontStyle, 
  fontFamily,
  textTransform,
  width,
  unit,
  height,
}) => {
  const {
    connectors: { connect },
    actions: {setProp},
  } = useNode();
  
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const styleProps = {
    ...(width !== null ? { width: `${width?.split(" ")?.join("")}` } : {width: '485px'}),
    ...(marginTop !== null && { marginTop: `${marginTop?.split(" ")?.join("")}` }),
    ...(marginRight !== null && { marginRight: `${marginRight?.split(" ")?.join("")}` }),
    ...(marginBottom !== null && { marginBottom: `${marginBottom?.split(" ")?.join("")}` }),
    ...(marginLeft !== null && { marginLeft: `${marginLeft?.split(" ")?.join("")}` }),
    ...(color !== null && { color: `rgba(${Object.values(color)})` }),
    ...(fontSize ? { fontSize: `${fontSize?.split(" ")?.join("")}`} : {fontSize: `20px`}),
    ...(shadow !== null && {
      textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
    }),
    ...(height !== null && { height: `${height?.split(" ")?.join("")}` }),
    ...(fontWeight !== null && { fontWeight: fontWeight }),
    ...(textAlign !== null && { textAlign: textAlign }),
    ...(fontStyle !== null) && { fontStyle: fontStyle },
    ...(textDecoration !== null) && { textDecoration: textDecoration },
    ...(fontFamily !== null) && { fontFamily: fontFamily },
    ...(textTransform !== null) && { textTransform : textTransform },
  };
  

  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;
  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;
  return (
    
    <ContentEditable
    val={href?.name} _id="sidebarItemx"
      onClick={() => {
        if(href && !enabled) {
          if(href._id.includes("www")) {
            window.open("https://" + href?.name, "_blank")
          } else {
            const parentItem = treeData.find((x) => x._id === href._id);

            if (parentItem) {
              setActiveId(parentItem);
            } else {
              let childItem = null;
            
              for (const parent of treeData) {
                const child = parent.children.find((child) => child._id === href._id);
            
                if (child) {
                  childItem = child;
                  break;
                }
              }
            
              setActiveId(childItem);
            }

          }
        } 
      }}
      innerRef={connect}
      html={text} // innerHTML of the editable div
      disabled={!enabled}
      onChange={(e) => {
        setProp((prop) => (prop.text = e.target.value), 500);
      }} // use true to disable editing
      tagName="div" // Use a custom HTML tag (uses a div by default)
      style={{...styleProps, cursor: href && !enabled ? "pointer" : "auto", borderRadius:enabled ? '8px' : '', padding:'4px', opacity:'80%'}}
      className={href?.name?.toLowerCase() == activeId?.name?.toLowerCase() || text?.toLowerCase() == activeId?.name?.toLowerCase() ? selected : "" }
    />
  );
};

Quote.craft = {
  displayName: 'Quote',
  props: {
    fontSize: null,
    textAlign: null,
    fontWeight: null,
    color: null,
    margin: null,
    shadow: null,
    marginTop: null,
    marginRight: null,
    marginBottom: null,
    marginLeft: null,
    text: `"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ipsum dolor sit"`,
    href: null,
    width: null,
    height: null,
    textTransform:null,
    fontFamily:null,
    fontStyle:"normal",
    textDecoration: "none",
    isText: true
  },
  related: {
    toolbar: QuoteSettings,
  },
};
