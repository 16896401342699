import React, { useCallback, useRef, useState } from "react"
import { lazy } from "react";
import { Login } from "./Login";
import { LoginSettings } from "./LoginSettings";
import { useInView } from "react-hook-inview";
import logoutContentsVar from "./AuthProps/LogoutContents";
import loginContentsVar from "./AuthProps/LoginContents";
import registerContentsVar from "./AuthProps/RegisterContents";
import registerUserContentsVar from "./AuthProps/RegisterUserContents";
import errorMessageContentsVar from "./AuthProps/ErrorMessageContents";
import resetPasswordContentsVar from "./AuthProps/ResetPasswordContents";
import verifyEmailContentsVar from "./AuthProps/VerifyEmailContents";
import loginVerifyUserCodeContentsVar from "./AuthProps/LoginVerifyUserCodeContents";
import loginAuthGrantContentsVar from "./AuthProps/LoginAuthGrant";
import loginOtpContentsVar from "./AuthProps/LoginOtpContents";
import loginUsernamecontentsVar from "./AuthProps/LoginUsernameContents";
import loginPasswordContentsVar from "./AuthProps/LoginPasswordContents";
import loginUpdatePasswordContnetsVar from "./AuthProps/LoginUpdatePasswordContents";
import loginLinkConfirmVar from "./AuthProps/LoginLinkConfirmContents";
import loginpageExpiredContentsVar from "./AuthProps/LoginPageExpiredContents";
import loginConfigTotpContentsVar from "./AuthProps/LoginConfigTotpContents";
import loginUpdateProfileContentsVar from "./AuthProps/LoginUpdateProfileContents";
import updateUserProfileVar from "./AuthProps/UpdateUserProfileContents";
import reviewUserProfileVar from "./AuthProps/ReviewUserProfileContents";
import updateEmailContentsVar from "./AuthProps/UpdateEmailContents";
import welcomeHeaderVar from "./AuthProps/WelcomeMessageContents";
import { LoginCraft } from "./LoginCraft";

export const Log = (props) => {


  const defaultPropsIn = {
    logoutContents: logoutContentsVar,
    loginContents: loginContentsVar,
    registerContents: registerContentsVar,
    registerUserContents: registerUserContentsVar,
    errorMessageContents: errorMessageContentsVar,
    resetPasswordContents: resetPasswordContentsVar,
    verifyEmailContents: verifyEmailContentsVar,
    loginVerifyUserCodeContents: loginVerifyUserCodeContentsVar,
    loginAuthGrantContents: loginAuthGrantContentsVar,
    loginOtpContents: loginOtpContentsVar,
    loginUsernamecontents: loginUsernamecontentsVar,
    loginPasswordContents: loginPasswordContentsVar,
    loginUpdatePasswordContnets: loginUpdatePasswordContnetsVar,
    loginLinkConfirm: loginLinkConfirmVar,
    loginpageExpiredContents: loginpageExpiredContentsVar,
    loginConfigTotpContents: loginConfigTotpContentsVar,
    loginUpdateProfileContents: loginUpdateProfileContentsVar,
    updateUserProfile: updateUserProfileVar,
    reviewUserProfile: reviewUserProfileVar,
    updateEmailContents: updateEmailContentsVar,
    welcomeHeader: welcomeHeaderVar,
    src: null,
    icon:null
  }
  

  const {
    loginContents,
    logoutContents,
    registerContents,
    registerUserContents,
    errorMessageContents,
    resetPasswordContents,
    verifyEmailContents,
    loginVerifyUserCodeContents,
    loginAuthGrantContents,
    loginOtpContents,
    loginUsernamecontents,
    loginPasswordContents,
    loginUpdatePasswordContnets,
    loginLinkConfirm,
    loginpageExpiredContents,
    loginConfigTotpContents,
    loginUpdateProfileContents,
    updateUserProfile,
    reviewUserProfile,
    updateEmailContents,
    welcomeHeader,
    src,
    icon
    
  } = defaultPropsIn;



  const templateProps = {
    logoutContents,
    loginContents,
    registerContents,
    registerUserContents,
    errorMessageContents,
    resetPasswordContents,
    verifyEmailContents,
    loginVerifyUserCodeContents,
    loginAuthGrantContents,
    loginOtpContents,
    loginUsernamecontents,
    loginPasswordContents,
    loginUpdatePasswordContnets,
    loginLinkConfirm,
    loginpageExpiredContents,
    loginConfigTotpContents,
    loginUpdateProfileContents,
    updateUserProfile,
    reviewUserProfile,
    updateEmailContents,
    welcomeHeader,
    src,
    icon,
  };



  let extraProps = {
    welcomeHeader,
    src,
    icon,
  }

    return  <Login extraProps={extraProps} templateProps={templateProps}/>
}
