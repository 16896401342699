/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { OptionsContextProvider } from './OptionsContext';
import { ActiveIdContextProvider } from './ActiveIdContext';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory
} from "react-router-dom";
import { DarkModeProvider } from './DarkModeContext';
import { ApolloProvider } from '@apollo/client';
import { StrictMode, lazy, Suspense } from "react";
import { WidthProvider } from './WidthContext/WidthContext';
import { SidebarProvider } from './SidebarShown/SidebarContext';
import { SidesProvider } from './SideContext';
import { FeedbackProvider } from './FeedbackContext';




const root = ReactDOM.createRoot(document.getElementById('root'));

// ReactDOM.render(, document.getElementById("app"));


root.render(
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <OptionsContextProvider>
          <ActiveIdContextProvider>
            <DarkModeProvider>
              <FeedbackProvider>
                <WidthProvider>
                  <SidebarProvider>
                    <SidesProvider>
                      <App />
                    </SidesProvider>
                  </SidebarProvider>
                </WidthProvider>
              </FeedbackProvider>
            </DarkModeProvider>
          </ActiveIdContextProvider>
        </OptionsContextProvider>
      </Router>
    </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
