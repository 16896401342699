/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState, useRef, useEffect, useCallback, useMemo } from "react"
import { ADD_OPTION, useOptionsContext } from "../../../OptionsContext";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { AddPageIcon, DeleteNameIcon, Doublicate } from "./EditorSvg";
import { BorderUnder, PenEditIcon } from "./Pop-up/svgArray";
import { useActiveIdContext } from "../../../ActiveIdContext";
import { useDarkMode } from "../../../DarkModeContext";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { CloseIcon } from "../../land/Svg";
import { category } from '../../selectors/basic/Image/ImageSettings.js';
import { ArrowDDown, ArrowUpp } from "./Pop-up/svgArray";
import SelectionBox from "./Pop-up/PopUp.js";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme } from '@mui/material/styles';
import { useHotkeys } from "react-hotkeys-hook";

export const TreeView = ({ auth, data, setActive, setWorking, active, open, setOpen }) => {

  const { isDarkMode, toggleDarkMode } = useDarkMode();


  let pages = [
    "login.ftl",
    "register.ftl",
    "register-user-profile.ftl",
    "info.ftl",
    "error.ftl",
    "login-reset-password.ftl",
    "login-verify-email.ftl",
    "terms.ftl",
    "login-oauth2-device-verify-user-code.ftl",
    "login-oauth-grant.ftl",
    "login-otp.ftl",
    "login-username.ftl",
    "login-password.ftl",
    "webauthn-authenticate.ftl",
    "login-update-password.ftl",
    "login-update-profile.ftl",
    "login-idp-link-confirm.ftl",
    "login-idp-link-email.ftl",
    "login-page-expired.ftl",
    "login-config-totp.ftl",
    "logout-confirm.ftl",
    "update-user-profile.ftl",
    "idp-review-user-profile.ftl",
    "update-email.ftl",
    "select-authenticator.ftl",
    "saml-post-form.ftl",
    "my-extra-page-1.ftl",
    "my-extra-page-2.ftl",
    "info.ftl"
  ];

   const authData = useMemo(() => {
    return pages.map(page => {
      let name = page.replace('.ftl', '').replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
      return {
        _id: page,
        name: name,
        children: []
      };
    });
  }, [pages]);

  const newData = auth ? authData : data;

  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId } = state;

  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;

  const [dialog, setDialog] = useState(false);
  const [childEdit, setChildEdit] = useState(null);
  const [childEditName, setChildEditName] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [newActionName, setNewActionName] = useState('');
  const [focused, setFocused] = useState(false);
  const [name, setName] = useState('');
  const [activeEdit, setActiveEdit] = useState(null);
  const [childId, setChildId] = useState(null);
  const [iconsSquare, setIconsSquare] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState({
    name: 'default icon',
    path: "M16.5 4.5C16.5 5.17031 16.3547 5.80781 16.0875 6.38437L18.5625 10.6594C17.4516 11.8453 16.0219 12.7266 14.4141 13.1719L12 9L8.8125 14.5078C9.82031 14.8266 10.8891 15 12.0047 15C15.3188 15 18.2766 13.4672 20.2031 11.0625C20.7234 10.4156 21.6656 10.3125 22.3125 10.8281C22.9594 11.3438 23.0625 12.2906 22.5469 12.9375C20.0672 16.0219 16.2656 18 12 18C10.3406 18 8.74688 17.7 7.27969 17.1516L4.62656 21.7359C4.40625 22.1156 4.07812 22.425 3.68438 22.6219L1.0875 23.9203C0.853125 24.0375 0.576563 24.0234 0.35625 23.8875C0.135938 23.7516 0 23.5078 0 23.25V20.6531C0 20.2594 0.103125 19.8703 0.304688 19.5234L3.11719 14.6625C2.51719 14.1375 1.95938 13.5609 1.4625 12.9375C0.942188 12.2906 1.05 11.3484 1.69688 10.8281C2.34375 10.3078 3.28594 10.4156 3.80625 11.0625C4.07344 11.3953 4.35938 11.7094 4.65938 12.0047L7.9125 6.38437C7.65 5.8125 7.5 5.175 7.5 4.5C7.5 2.01562 9.51563 0 12 0C14.4844 0 16.5 2.01562 16.5 4.5ZM17.4844 18.4641C19.0125 17.8641 20.4141 17.0203 21.6516 15.9844L23.7 19.5234C23.8969 19.8656 24.0047 20.2547 24.0047 20.6531V23.25C24.0047 23.5078 23.8688 23.7516 23.6484 23.8875C23.4281 24.0234 23.1516 24.0375 22.9172 23.9203L20.3203 22.6219C19.9266 22.425 19.5984 22.1156 19.3781 21.7359L17.4844 18.4641ZM12 6C12.3978 6 12.7794 5.84196 13.0607 5.56066C13.342 5.27936 13.5 4.89782 13.5 4.5C13.5 4.10218 13.342 3.72064 13.0607 3.43934C12.7794 3.15804 12.3978 3 12 3C11.6022 3 11.2206 3.15804 10.9393 3.43934C10.658 3.72064 10.5 4.10218 10.5 4.5C10.5 4.89782 10.658 5.27936 10.9393 5.56066C11.2206 5.84196 11.6022 6 12 6Z",
    width: '24',
    height: '24',
    viewBox: '0 0 24 24'
  });

  const [expandedNodes, setExpandedNodes] = useState([]);
  const menuRef = useRef(null);
  const downRef = useRef(null);
  const currentActiveId = useRef(null);

  // Optimized random string generator using useCallback
  const generateRandomString = useCallback((length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
  }, []);

  const newChildId = generateRandomString(5);



  // Memoized effect to store tree data in localStorage
  useEffect(() => {
    if (treeData.length) {
      localStorage.setItem("treeData", JSON.stringify({ treeData }));
    }
  }, [treeData]);

  // Effect to set the activeId when newData is available
  useEffect(() => {
    if (!activeId && newData) {
      setActiveId({ _id: newData[0]?._id, name: newData[0]?.name });
    }
  }, [activeId, newData, setActiveId]);

  // Optimized scrolling effect
  useEffect(() => {
    const scrollToActive = () => {
      if (currentActiveId.current) {
        currentActiveId.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }
    };

    if (open) {
      scrollToActive();
    }
  }, [open]);

  const changeOptions = useCallback((options) => {
    idDispatch({ type: ADD_OPTION, payload: options });
  }, [idDispatch]);

  const addItemToChildren = useCallback((parentId, newItem) => {
    const parentObject = treeData.find(obj => obj._id === parentId);

    if (parentObject && parentObject.children) {
      const updatedTreeData = treeData.map(obj => {
        if (obj._id === parentId) {
          return {
            ...obj,
            children: [...obj.children, newItem],
          };
        }
        return obj;
      });

      changeOptions(updatedTreeData);
      setDialog(false);
      setChildId(null);
    }
  }, [treeData, changeOptions]);

  const handleDeleteChild = useCallback((parentId, childId) => {
    const updatedTreeData = treeData.map(obj => {
      if (obj._id === parentId && obj.children) {
        const updatedChildren = obj.children.filter(child => child._id !== childId);
        return { ...obj, children: updatedChildren };
      }
      return obj;
    });
    changeOptions(updatedTreeData);
  }, [treeData, changeOptions]);

  const handleEditChildName = useCallback((parentId, childId, newName) => {
    const updatedTreeData = treeData.map(obj => {
      if (obj._id === parentId && obj.children) {
        const updatedChildren = obj.children.map(child => {
          if (child._id === childId) {
            return { ...child, name: newName };
          }
          return child;
        });
        return { ...obj, children: updatedChildren };
      }
      return obj;
    });
    changeOptions(updatedTreeData);
  }, [treeData, changeOptions]);

  const handleAddAction = useCallback((name, icon, parentId) => {
    const duplicates = treeData.filter(page => page.name.toLowerCase().replace(/\d+/g, '').trim() === name.toLowerCase().replace(/\d+/g, '').trim());
    const count = duplicates.length;

    const newActionId = generateRandomString(5);
    const newAction = {
      _id: newActionId,
      name: name ? `${name.toLowerCase().replace(/\d+/g, '').trim()} ${count + 1}` : `New Page ${count + 1}`,
      icon,
      children: [],
    };

    const updatedTreeData = [...treeData, newAction];
    changeOptions(updatedTreeData);
    setDialog(false);
    setOpen(false);
  }, [treeData, changeOptions, generateRandomString]);

  const handleDeleteNode = useCallback((id, nodeName, e) => {
    setName(nodeName);
    setDeleteId(id);
    setDialog(true);
  }, []);

  const handleDeleteNodeFunc = useCallback((id) => {
    const filteredTreeData = treeData.filter(x => x._id !== id);
    changeOptions(filteredTreeData);
    setActiveId(filteredTreeData[0]);
    setDialog(false);
    setOpen(false);
    setDeleteId(null);
  }, [treeData, changeOptions, setActiveId]);

  const handleChangeName = useCallback((id, node, icon) => {
    idDispatch({ type: 'CHANGE_NAME', payload: { _id: id, name: node, icon } });
    setActiveId({ _id: id, name: node });
    setDialog(false);
    setOpen(false);
  }, [idDispatch, setActiveId]);

  const duplicatePage = useCallback((id) => {
    let json = localStorage.getItem(id);
    const item = treeData.find(x => x._id === id);
    const newItem = { ...item };

    const duplicates = treeData.filter(page => page.name.replace(/\d+/g, '').trim() === item.name.replace(/\d+/g, '').trim());
    const count = duplicates.length;

    newItem._id = generateRandomString(5);
    newItem.name = `${newItem.name.replace(/\d+/g, '').trim()} ${count + 1}`;
    localStorage.setItem(newItem._id, json);

    changeOptions([...treeData, newItem]);
    setActiveId({ _id: newItem._id, name: newItem.name });
  }, [treeData, generateRandomString, changeOptions, setActiveId]);

  const handleIconImageSelect = useCallback((selectedUrl) => {
    if (Array.isArray(selectedUrl) && selectedUrl.length) {
      setSelectedIcon(selectedUrl[0]);
    } else {
      setSelectedIcon(selectedUrl);
    }

    setIconsSquare(false);
  }, []);

  const changeIndexUp = useCallback((node) => {
    let json = localStorage.getItem(node._id);
    const nodeIndex = treeData.findIndex((x) => x._id === node._id);
    
    if (nodeIndex > 0 && nodeIndex !== -1) {
      const temp = treeData[nodeIndex];
      treeData[nodeIndex] = treeData[nodeIndex - 1];
      treeData[nodeIndex - 1] = temp;
      localStorage.setItem(temp._id, json);
      changeOptions([...treeData]);
      setOpen(false);
    } else {
      alert("You can't move up the node.");
    }
  }, [treeData, changeOptions]);
  
  const changeIndex = useCallback((node) => {
    let json = localStorage.getItem(node._id);
  
    if (json !== null) {
      const nodeIndex = treeData.findIndex((x) => x._id === node._id);
      if (nodeIndex >= 0 && nodeIndex < treeData.length - 1) {
        const temp = treeData[nodeIndex];
        treeData[nodeIndex] = treeData[nodeIndex + 1];
        treeData[nodeIndex + 1] = temp;
        localStorage.setItem(temp._id, json);
        changeOptions([...treeData]);
        setOpen(false);
      } else {
        alert("You can't move down the node.");
      }
    } else {
      alert("Item does not exist in localStorage.");
    }
  }, [treeData, changeOptions]);
  
  const handleDialog = useCallback((id, name, icon) => {
    // Set the action name for new item creation
    const updatedActionName = name || ""; // If name is empty or undefined, set to an empty string.
    setNewActionName(updatedActionName);
  
    // Open the dialog
    setDialog(true);
  
    if (childEditName && id && name && icon) {
      // If we're editing a child, call the edit handler
      handleEditChildName(childId, id, updatedActionName);
    } else if (!childEditName && id && name) {
      // If we're adding a new child, handle the logic for the new child.
      setActiveEdit(id);
  
      if (updatedActionName.trim() !== "") {
        // Ensure no duplicates and handle new child creation
        const duplicates = treeData.find(x => x._id === id)?.children?.filter(
          page => page.name.toLowerCase().replace(/\d+/g, '').trim() === updatedActionName.toLowerCase().replace(/\d+/g, '').trim()
        );
  
        const count = duplicates ? duplicates.length : 0;
        const newChildId = generateRandomString(5);
  
        // Create new child
        addItemToChildren(id, {
          _id: newChildId,
          name: updatedActionName.trim() !== "" ? `${updatedActionName.trim()} ${count + 1}` : "New Page",
          icon: icon || "default-icon", // Ensure there's a fallback for `icon`
        });
      }
    } else if (id && !name) {
      // If no name is provided, set childId for further handling
      setChildId(id);
    } else {
      // Close the dialog if no valid conditions are met
      setOpen(false);
    }
  }, [treeData, generateRandomString, addItemToChildren, childEditName, childId]);
  
  const toggleNode = useCallback((nodeId) => {
    if (expandedNodes.includes(nodeId)) {
      setExpandedNodes(expandedNodes.filter(id => id !== nodeId));
    } else {
      setExpandedNodes([...expandedNodes, nodeId]);
    }
  }, [expandedNodes]);
  
  const isNodeExpanded = useCallback((nodeId) => {
    return expandedNodes.includes(nodeId);
  }, [expandedNodes]);
  
  const dupSubPage = useCallback((parentId, childId) => {
    let json = localStorage.getItem(childId);
    const parentItem = treeData.find(item => item._id === parentId);
    const childIndex = parentItem.children.findIndex(child => child._id === childId);
    const childItem = parentItem.children[childIndex];
    const newChildItem = { ...childItem };
  
    const duplicates = parentItem.children.filter(child => child.name.replace(/\d+/g, '').trim() === childItem.name.replace(/\d+/g, '').trim());
    const count = duplicates.length;
  
    newChildItem._id = generateRandomString(5);
    newChildItem.name = count === 0 ? `${newChildItem.name.replace(/\d+/g, '').trim()} 1` : `${newChildItem.name.replace(/\d+/g, '').trim()} ${count + 1}`;
  
    const newTreeData = treeData.map(item => {
      if (item._id === parentId) {
        return { ...item, children: [...item.children.slice(0, childIndex + 1), newChildItem, ...item.children.slice(childIndex + 1)] };
      }
      return item;
    });
  
    localStorage.setItem(newChildItem._id, json);
    changeOptions(newTreeData);
    setActiveId({ _id: newChildItem._id, name: newChildItem.name });
  }, [treeData, generateRandomString, changeOptions]);

  useHotkeys('shift+a', (e) => {

    handleDialog()
    setSelectedIcon(selectedIcon)
    setOpen(false);
    e.stopPropagation()

  })

  const [select, setSelected] = useState(null)

  const renderTree = (nodes, child, level = 0) => {

    return (
      <>

        <div
          style={{ cursor: "pointer", width: "100%", height: "100%", padding: '1vmin', borderRadius: '1vmin', minHeight: '100%', justifyContent: 'center', alignContent: 'center' }}
          onClick={(e) => {
            e.stopPropagation()
            setOpen(!open)
            setActive(false)
          }}
        >
          <div style={{ height: "100%", padding: '.4vmin', paddingBottom: '0' }} className={`my-auto transition-all duration-500 ease-in-out flex flex-column w-[100%] items-center`}>
            <div
              title='Component'
              className="flex flex-col justify-between h-[100%] my-auto w-[100%] items-center"
              id="EditorRoot"
            >
              <div className={`h-[50%] text-[2.5vmin]`} style={{ fontWeight: '500', color: '#0867FC', letterSpacing: '1px', fontFamily: 'Inter' }}>
                <div className="w-auto h-full items-center flex">
                  {nodes
                    ?.filter(node => node?.name === activeId?.name) // Filter the nodes first
                    .map((node, i) => (
                      node?.icon && (
                        <div key={i} className="w-[3Vmin] h-[3Vmin] mr-[1Vmin] logo-sidee items-center justify-center flex">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            role="img"
                            viewBox={node.icon.viewBox}
                            width='3.5vmin'
                            height='3.5vmin'
                            fill={node.icon.fill || '#0867FC'}
                          >
                            <path d={node.icon.path}></path>
                          </svg>
                        </div>
                      )
                    ))}
                  {!auth ? activeId?.name?.length > 8 ? `${activeId?.name?.slice(0, 5)}...` : activeId?.name : activeId?.name}

                  {!open ?
                    <KeyboardArrowDownIcon sx={{ margin: 'auto .5vmin', fontSize: '2.5vmin', color: '#0867fc' }} />
                    : <KeyboardArrowUpIcon sx={{ margin: 'auto .5vmin', fontSize: '2.5vmin', color: '#0867fc' }} />
                  }
                </div>
              </div>
            </div>
          </div>

        </div>
        <div>{<BorderUnder width='100%' fill={'#0867fc'} />}</div>
        {<div
            ref={downRef}
            className={` ${isDarkMode ? 'dark-mode' : 'light-mode'} shadow-md`}
            style={{
              background: isDarkMode ? "#111F3E" : "#F8F8F8",
              width: '34.5Vmin',
              maxHeight: '40Vmin',
              padding: '1Vmin',
              top: '13Vmin',
              left: '14%',
              zIndex: '99999',
              borderRadius: '2.5vmin',
              position: 'fixed',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              transform: open
                ? 'translate(0, 0) scale(1)' // Full size and positioned at the center
                : 'translate(30%, -50%) scale(0)', // Initially small and centered
              transition: 'transform 300ms ease, opacity 100ms ease', // Transition for scaling and fading
              zIndex: 99999, // Ensure it's above other content
              opacity: open ? 1 : 0, // Fade in and out when open
            }}
            id="dropdown-menu"

          >
            <div className="flex flex-col">
              <div className="max-h-[24vmin] w-full" style={{ overflow: 'scroll' }}>
                {nodes?.map((node, i, a) => {
                  return (<div key={i} ref={activeId?._id === node?._id ? currentActiveId : null}
                    style={{ margin: "1Vmin 0", borderRadius: '1.8Vmin', padding: '1Vmin' }} className={`hover-menu-item ${activeId?._id === node?._id ? 'bg-[#0867fc40]' : ''} `}>
                    <div
                      onKeyDown={(event) => {
                        event.stopPropagation();
                      }}
                      key={node._id}
                      disableRipple={true}
                      sx={{ width: '100%' }}
                      className='h-[100%]'
                    >
                      <div
                        onClick={() => {
                          // Handle setActiveId, setDeleteId, setOpen as needed
                          setSelected(node._id)
                          setActiveId(node);
                          setOpen(false);
                        }}
                        style={{
                          height: '100%',
                          width: "100%",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          fontSize: '1.8vmin',
                          fontWeight: '400',
                          fontFamily: 'Inter',

                        }}
                      >
                        <div style={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          width: '100%',
                          justifyItems: 'center',
                          margin: 'auto 0',
                        }}>
                          <div className='flex flex-row justify-center align-content-center align-items-center' >
                            {treeData.length > 1 && !auth &&
                              <div>
                                <div className={`flex flex-col`}>
                                  {treeData.findIndex((x) => x._id === node._id) !== 0 ?
                                    <div className='transition-transform border-none iconspage duration-300 transform-gpu hover:scale-125'
                                      onClick={(e) => {
                                        changeIndexUp(node)
                                        e.stopPropagation()
                                      }}>
                                      <ArrowUpp stroke={`${isDarkMode ? '#fff' : '#000'}`} width='2Vmin' height='2Vmin' /></div>
                                    : <div><ArrowUpp stroke='#989191' width='2Vmin' height='2Vmin' /></div>
                                  }
                                  {treeData.findIndex((x) => x._id === node._id) !== treeData.length - 1 ?
                                    <div className='transition-transform border-none iconspage duration-300 transform-gpu hover:scale-125'
                                      onClick={(e) => {
                                        changeIndex(node)
                                        e.stopPropagation()
                                      }}>
                                      <ArrowDDown stroke={`${isDarkMode ? '#fff' : '#000'}`} width='2Vmin' height='2Vmin' /></div>
                                    : <div><ArrowDDown stroke='#989191' width='2Vmin' height='2Vmin' /></div>
                                  }
                                </div>
                              </div>
                            }

                            {node?.icon && <div className="w-[3Vmin] h-[3Vmin] mr-[1Vmin] logo-sidee items-center justify-center flex">
                              <svg aria-hidden="true" focusable="false" role="img" viewBox={node.icon.viewBox} width='100%' height='100%' fill={isDarkMode ? node.icon.fill ? node.icon.fill : '#B0B0B0' : node.icon.fill ? node.icon.fill : '#100103A1'}><path d={node.icon.path}></path></svg>
                            </div>}
                            <div
                              title={node.name}
                              className='flex items-center justify-center'
                              style={{
                                borderRadius: "100%",
                                display: "flex",
                                color: isDarkMode ? '#B0B0B0' : '#0F172A',
                                justifyContent: "start",
                                alignItems: "center",
                                textAlign: "center",
                                fontWeight: "400",
                                textTransform: 'capitalize'
                              }}
                              id="BasicInput"
                            >
                              {!auth ? node?.name?.length > 10 ? `${node?.name?.slice(0, 10)}...` : node?.name : node?.name}
                            </div>
                          </div>
                          {!auth ? <div style={{ width: '100%', maxWidth: '40%' }}>

                            <div className='w-[100%] h-[100%]' style={{
                              display: "flex", justifyContent: "end", alignItems: "center", textAlign: "right", flexDirection: 'row'
                            }}>
                              <div className={`flex ${a.length > 1 ? 'flex-col' : 'flex-row'} align-items-center`}>

                                <div
                                  className="iconspage"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setOpen(false);
                                    duplicatePage(node._id)
                                  }}
                                >
                                  <div className='transition-transform duration-300 transform-gpu hover:scale-125'>
                                    {isDarkMode ? (
                                      <Doublicate />
                                    ) : <Doublicate />}
                                  </div>
                                </div>
                                <div className='iconspage'
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleDialog(node._id)
                                    setOpen(false);
                                  }}
                                >
                                  <div className='transition-transform border-none iconspage duration-300 transform-gpu hover:scale-125 my-auto align-center'>
                                    {isDarkMode ? (
                                      <AddBoxIcon sx={{ fontSize: '2.3Vmin', color: '#989191' }} />
                                    ) : <AddBoxIcon sx={{ fontSize: '2.3Vmin', color: '#989191' }} />}
                                  </div>
                                </div>


                              </div>

                              <div className={`flex flex-col align-items-center`}>
                                <div
                                  className="iconspage"
                                  style={{ margin: '.25Vmin' }}

                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setOpen(false);
                                    handleDialog(node._id, node.name)
                                  }}
                                >

                                  <div onClick={() => setSelectedIcon(node.icon)} className='transition-transform duration-300 transform-gpu hover:scale-125'>
                                    {isDarkMode ? (
                                      <PenEditIcon />
                                    ) : <PenEditIcon fill='none' stroke='#161717' />}
                                  </div>
                                  <div>
                                  </div>
                                </div>
                                {a.length > 1 ? <div
                                  className="iconspage"
                                  style={{ margin: '.25Vmin' }}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setOpen(false)
                                    handleDeleteNode(node._id, node.name)

                                  }}
                                >
                                  <div className='transition-transform duration-300 transform-gpu hover:scale-125'>
                                    {isDarkMode ? (
                                      <DeleteNameIcon fill='#989191' />
                                    ) : <DeleteNameIcon fill='#989191' />}
                                  </div>
                                </div> : null}
                              </div>
                              <div style={{ display: node.children && node.children.length > 0 ? "inline-flex" : 'none' }}>
                                {node.children && node.children.length > 0 ? (
                                  <div
                                    style={{ width: "1vw" }}
                                    className='transition-transform duration-300 transform-gpu hover:scale-125 my-auto cursor-pointer mx-[1Vmin]'
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      toggleNode(node._id)
                                    }}
                                  >
                                    {node.children && node.children.length > 0 ? isNodeExpanded(node._id) ? (
                                      <svg aria-hidden="true" focusable="false" role="img" className="octicon octicon-chevron-down" viewBox="0 0 12 12" width="2Vmin" height="2Vmin" fill={isDarkMode ? '#FFFFFF' : '#100103A1'}><path d="M6 8.825c-.2 0-.4-.1-.5-.2l-3.3-3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l2.7 2.7 2.7-2.7c.3-.3.8-.3 1.1 0 .3.3.3.8 0 1.1l-3.2 3.2c-.2.2-.4.3-.6.3Z"></path></svg>
                                    ) : (
                                      <svg aria-hidden="true" focusable="false" role="img" className="octicon octicon-chevron-right" viewBox="0 0 12 12" width="2Vmin" height="2Vmin" fill={isDarkMode ? '#FFFFFF' : '#100103A1'}><path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3Z"></path></svg>
                                    ) : ''}
                                  </div>
                                ) : (
                                  <div

                                    style={{ width: "1vw" }}
                                    className='transition-transform duration-300 transform-gpu hover:scale-125 my-auto cursor-pointer mx-[1Vmin]'
                                  >
                                  </div>
                                )
                                }
                              </div>
                            </div>
                          </div> : null}
                        </div>
                      </div>
                    </div>

                    {isNodeExpanded(node._id) && node.children && node.children.length > 0 && (
                      <div
                        className='ml-[3Vmin]'>
                        {node.children.map(childNode => (
                          <div
                            autoFocus={false}
                            onKeyDown={(event) => {
                              event.stopPropagation();
                            }}
                            key={childNode._id}
                            disableRipple={true}
                            sx={{ width: '100%', paddingLeft: "2vmin" }}
                            onClick={() => {
                              // Handle setActiveId, setDeleteId, setOpen as needed
                              setActiveId(childNode);
                              setOpen(false);
                            }}
                            style={{
                              width: "100%",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              fontSize: '1.6vmin',
                              fontWeight: '400',
                              fontFamily: 'Inter',
                              margin: '1.5Vmin 0 0 0',
                            }}
                          >
                            <div
                              className={`bg-[transparent] hover:bg-${isDarkMode ? '[#111F3E]' : '[#FFF]'} p-[1.3Vmin]`}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%',
                                borderRadius: '1.8Vmin',
                                border: '1px solid transparent'
                              }}>

                              <div className='flex flex-row'>
                                {childNode?.icon && <div className="w-[2.2Vmin] h-[2.2Vmin] logo-sidee items-center justify-center flex mr-[1.5vmin]">
                                  <svg aria-hidden="true" focusable="false" role="img" viewBox={childNode.icon.viewBox} width='100%' height="100%" fill={isDarkMode ? childNode.icon.fill ? childNode.icon.fill : '#FFFFFF' : childNode.icon.fill ? childNode.icon.fill : '#100103A1'}><path d={childNode.icon.path}></path></svg>

                                </div>}

                                <div
                                  title={childNode.name}
                                  className='flex items-center justify-center'
                                  style={{
                                    borderRadius: "100%",
                                    display: "flex",
                                    color: isDarkMode ? '#fff' : '#0F172A',
                                    justifyContent: "start",
                                    alignItems: "center",
                                    textAlign: "center",
                                    fontWeight: "600",
                                    minWidth: '4vmin',
                                    textTransform: 'capitalize'
                                  }}
                                  id="BasicInput"
                                >
                                  {childNode?.name?.length > 10 ? `${childNode?.name?.slice(0, 10)}...` : childNode?.name}
                                </div>
                              </div>
                              {!auth ? <div style={{ width: 'auto' }}>
                                <div className='w-[100%] h-[100%]' style={{
                                  display: "flex", justifyContent: "end", alignItems: "center", textAlign: "right", flexDirection: 'row'
                                }}>

                                  <div
                                    className="iconspage"
                                    style={{ margin: "1%", margin: "0 .3vw", padding: ".4% .3%", borderRadius: "4px" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setChildEditName(true);

                                      // handleDialog(node._id, childNode.name);
                                      handleEditChildName(node._id, childNode._id, childNode.name)
                                    }}
                                  >
                                    {/* handleEditChildName(node._id, childNode.name); */}

                                    <div className='transition-transform duration-300 transform-gpu hover:scale-125'>
                                      {isDarkMode ? (
                                        <PenEditIcon />
                                      ) : <PenEditIcon fill='none' stroke='#161717' />}
                                    </div>
                                    <div>
                                    </div>
                                  </div>
                                  <div
                                    className="iconspage"
                                    style={{ margin: "0 .3vw", borderRadius: "4px" }}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      dupSubPage(node._id, childNode._id)
                                    }}
                                  >
                                    <div className='transition-transform duration-300 transform-gpu hover:scale-125'>
                                      {isDarkMode ? (
                                        <Doublicate />
                                      ) : <Doublicate />}
                                    </div>
                                  </div>

                                  {a.length > 1 ? <div
                                    className="iconspage"
                                    style={{ margin: "1%", margin: "0 .3vw", padding: ".4% .3%", borderRadius: "4px" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setOpen(false)
                                      handleDeleteChild(node._id, childNode._id); // Update the function to handle child deletion
                                    }}
                                  >
                                    <div className='transition-transform duration-300 transform-gpu hover:scale-125'>
                                      {isDarkMode ? (
                                        <DeleteNameIcon fill='#989191' />
                                      ) : <DeleteNameIcon fill='#989191' />}
                                    </div>
                                  </div> : null}
                                </div>
                              </div> : null}
                            </div>

                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  );
                })}
              </div>
              {!auth ? <div className='w-[100%] py-[.6Vmin]' custom>
                <div className={` ${isDarkMode ? "text-[#3C90F2] border-[.25Vmin] border-solid border-[#3C90F2] hover:bg-[#182543] active:bg-[#2c6bf30e]" : 'text-[#0671E0] border-[#0867FC] hover:bg-[#0866fc11] border-[.25Vmin] active:bg-[#2c6bf30e]'} pb-[.8Vmin]`} style={{ borderRadius: '2.5Vmin' }}>
                  {level === 0 && (
                    <div
                      onClick={(e) => {
                        handleDialog()
                        setSelectedIcon(selectedIcon)
                        setOpen(false);
                        e.stopPropagation()
                      }}
                      style={{ cursor: "pointer" }}
                      className={`w-[100%] flex pt-[3%] h-[13%] text-center justify-center items-center`}
                    >
                      <div className='mx-[2%] py-[.5%] items-center'>
                        <AddPageIcon />
                      </div>
                      <div
                        id='add-action'
                        style={{ fontSize: '2vmin', fontWeight: '600', fontFamily: 'Inter' }}
                        className="text-[#0867FC] flex items-center justify-center text-center">
                        Add New Page
                      </div>
                    </div>
                  )}
                </div>
              </div> : null}
            </div>
          </div>}

        <Dialog
          PaperProps={{ sx: { borderRadius: "3.5Vmin", overflow: 'hidden', height: '31.125Vmin', zIndex: 0, width: '67.8Vmin', maxWidth: '64.8Vmin', padding: '0 3.5Vmin 2Vmin 3.5Vmin' } }}
          disablePortal // Disables rendering in a portal
          container={() => document.body} // Renders within the body
          sx={{ zIndex: 999 }}
          open={dialog ? true : false}
          onClose={() => setDialog(false)}
        >
          <DialogTitle className='mx-auto' sx={{fontSize: '4Vmin', fontWeight: '700', color: '#161717', fontFamily: 'Inter', width: '100%', padding: '3Vmin 0'
          }}>
            {deleteId !== null ? "Delete the page" : (activeEdit ? "Change the name of the page" : (childId ? "Add nested child" : childEdit ? 'change name of child' : "Create new page"))}
          </DialogTitle>
          <DialogContent sx={{ padding: '0', height: '15Vmin' }}>
            {deleteId !== null ? (
              <div className='my-auto mx-auto text-center' style={{ fontSize: '2Vmin', fontWeight: '400', fontFamily: 'Rubik', }}>
                Are you really sure you want to delete the <span style={{ fontWeight: 'bold' }}>"{name}"</span> page?
              </div>
            ) : (
              <>
                {childId ? (
                  <div className='w-full flex flex-row justify-between items-center'>
                    <input
                      onBlur={() => setFocused(false)}
                      onFocus={() => setFocused(true)}
                      style={{ fontSize: '2.67Vmin', fontWeight: '400', fontFamily: 'Rubik', borderRadius: '3.5Vmin', height: '7Vmin', width: '41Vmin' }}
                      className={`transition-all duration-200 ease-in ${focused ? 'border-[.25Vmin] border-solid text-[#000] border-[#0867FC]' : isDarkMode ? "border-[.25Vmin] border-solid text-[#fff] border-[#E9E9EA30]" : 'border-[.25Vmin] border-solid text-[#000] border-[#E9E9EA]'} text-[#989191] mx-auto px-[1.5Vmin]`}
                      id="InputText"
                      value={newActionName}
                      placeholder="Enter the name of the child"
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setNewActionName(e.target.value);
                      }}

                    />
                    <div className='w-[15.125Vmin] h-[7Vmin] mx-auto justify-center flex flex-row' style={{ borderRadius: '3.5Vmin', border: '.25Vmin solid #E9E9EA' }}>
                      <div className="w-full flex items-center">
                        {iconsSquare ? <div className='cursor-pointer' onClick={() => setIconsSquare(!iconsSquare)}>
                          <CloseIcon fill='#989191' width='3Vmin' height='3Vmin' /></div> :
                          selectedIcon ? (
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={selectedIcon.viewBox} width="100%" height="50%" fill={!isDarkMode ? selectedIcon.fill ? selectedIcon.fill : '#000' : selectedIcon.fill ? selectedIcon.fill : '#000'}><path d={selectedIcon.path}></path></svg>
                          ) : (
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={selectedIcon.viewBox} width="100%" height="50%" fill={!isDarkMode ? selectedIcon.fill ? selectedIcon.fill : '#000' : selectedIcon.fill ? selectedIcon.fill : '#000'}><path d={selectedIcon.path}></path></svg>

                          )
                        }
                      </div>
                      <div className='cursor-pointer mx-[1Vmin] flex items-center' onClick={() => setIconsSquare(!iconsSquare)}>
                        <PenEditIcon fill='none' stroke='#989191' width='3Vmin' height='3Vmin' />
                      </div>

                    </div>

                  </div>
                ) : (
                  <div className='w-full flex flex-row justify-between items-center my-auto' style={{ alignItems: 'center', alignContent: 'center' }}>
                    <input
                      onBlur={() => setFocused(false)}
                      onFocus={() => setFocused(true)}
                      style={{ fontSize: '2.67Vmin', fontWeight: '400', fontFamily: 'Rubik', borderRadius: '3.5Vmin', height: '7Vmin', width: '41Vmin' }}
                      className={`transition-all duration-200 ease-in ${focused ? 'border-[.25Vmin] border-solid text-[#000] border-[#0867FC]' : isDarkMode ? "border-[.25Vmin] border-solid text-[#fff] border-[#E9E9EA30]" : 'border-[.25Vmin] border-solid text-[#000] border-[#E9E9EA]'} text-[#989191] mx-auto px-[1.5Vmin]`}
                      id="InputText"
                      value={newActionName}
                      placeholder="Enter the name of the page"
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setNewActionName(e.target.value);
                      }}
                    />

                    <div className='w-[15.125Vmin] h-[7Vmin] mx-auto justify-center flex flex-row' style={{ borderRadius: '3.5Vmin', border: '.25Vmin solid #E9E9EA' }}>
                      <div className="w-full flex items-center">
                        {iconsSquare ? <div className='cursor-pointer' onClick={() => setIconsSquare(!iconsSquare)}>
                          <CloseIcon fill='#989191' width='3Vmin' height='3Vmin' /></div> : selectedIcon ? (
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={selectedIcon.viewBox} width='100%' height='50%' fill={!isDarkMode ? selectedIcon.fill ? selectedIcon.fill : '#000' : selectedIcon.fill ? selectedIcon.fill : '#000'}><path d={selectedIcon.path}></path></svg>
                          ) : (
                          <svg aria-hidden="true" focusable="false" role="img" viewBox={selectedIcon.viewBox} width='100%' height='50%' fill={!isDarkMode ? selectedIcon.fill ? selectedIcon.fill : '#000' : selectedIcon.fill ? selectedIcon.fill : '#000'}><path d={selectedIcon.path}></path></svg>
                        )
                        }
                      </div>
                      <div className='cursor-pointer mx-[1Vmin] flex items-center' onClick={() => setIconsSquare(!iconsSquare)}>
                        <PenEditIcon fill='none' stroke='#989191' width='3Vmin' height='3Vmin' />
                      </div>

                    </div>
                  </div>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions sx={{ margin: 'auto auto', padding: '0', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <div onClick={() => setDialog(false)} className={`flex button-outline-light`} style={{ cursor: "pointer", width: '14.8Vmin', height: '5Vmin', margin: '0 1.5Vmin', borderRadius: '2.5Vmin' }}>
              <div className='mx-auto my-auto text-[2Vmin] ' style={{ fontWeight: '400' }}>Cancel</div>
            </div>
            {deleteId !== null ? (
              <div onClick={(e) => handleDeleteNodeFunc(deleteId)} className={`flex button-basic-light`} style={{ cursor: "pointer", width: '14.8Vmin', height: '5Vmin', margin: '0 1.5Vmin', borderRadius: '2.5Vmin' }}>
                <div className='mx-auto my-auto text-[2Vmin] text-[#fff]' style={{ fontWeight: '400' }}>Confirm</div>
              </div>
            ) : (
              <div onClick={(e) => {
                if (activeEdit) {
                  handleChangeName(activeEdit, newActionName, selectedIcon);
                } else if (childId) {
                  addItemToChildren(childId, { _id: newChildId, name: newActionName, icon: selectedIcon });
                } else {
                  handleAddAction(newActionName, selectedIcon);
                }
              }} className={`flex button-basic-light`} style={{ cursor: "pointer", width: '14.8Vmin', height: '5Vmin', margin: '0 1.5Vmin', borderRadius: '2.5Vmin' }}>
                <div className='mx-auto my-auto text-[2Vmin] text-[#fff]' style={{ fontWeight: '400' }}>
                  {activeEdit || childEdit ? "Save" : "Create"}
                </div>
              </div>
            )}
          </DialogActions>
          {iconsSquare && (
            <SelectionBox
              pozition='absolute top-0 right-0'
              category={category}
              setIconsSquare={setIconsSquare}
              iconsSquare={iconsSquare}
              onSelect={handleIconImageSelect}
            />
          )}
        </Dialog>
      </>
    );
  };

  return <div className='w-auto my-auto ml-[2vmin] h-full'> {renderTree(newData)}</div>;
}
