/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useEditor } from '@craftjs/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ToolbarSection } from '../Toolbar';
import { ADD_OPTION, useOptionsContext } from '../../../OptionsContext';
import { useActiveIdContext } from '../../../ActiveIdContext'
import { useDarkMode } from '../../../DarkModeContext';
import { components, parents } from './ToolBoxData/components.js';
import { Group } from './ToolBoxData/Group.js';

const ToolboxDiv = styled.div`
  // transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  height: 100%;
  ${(props) => (`width: 100%;`)}
  // ${(props) => (!props.active ? `` : 'width: 100%; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;')}
  ${(props) => (!props.enabled ? `opacity: 0;` : '')}
`;

const Item = styled.a` 
  svg {
    width: 4%;
    height: 4%;
    fill: #707070;
  }
  ${(props) =>
    props.move &&
    `
    cursor: move;
  `}
`;

const getTabStyle = (tab, activeTab, isDarkMode) => {
  const isActive = activeTab === tab;
  const textColor = isActive ? '#0867fc' : isDarkMode ? '#E9E9EA' : '#161717';
  const textOpacity = isActive ? 1 : 0.6;

  return {
    cursor: 'pointer',
    fontWeight: 'medium',
    display: 'flex',
    fontSize:'1.8vmin',
    justifyContent: 'center',
    color: textColor,
    opacity: textOpacity,
  };
};



export const Toolbox = ({ auth, active }) => {
  const { isDarkMode } = useDarkMode();
  
  const [filterComponents, setFilterComponents] = useState(null)
  const [addWidgetActive, setAddWidgetActive] = useState(false)
  const [groupActive, setGroupActive] = useState(null)
  const [over, setOver] = useState(false)
  const [activeTab, setActiveTab] = useState("Basic")
  const [allComponents, setAllComponents] = useState({})
  const [expanded, setExpanded] = useState([]);
  const [search, setSearch] = useState("")

  const {
    enabled,
    connectors: { create },
    json,
    actions,

  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
    json: query.serialize(),

  }));

  useEffect(() => {
    if (search) {
      setFilterComponents(([...components].filter((x) => {
        return (
          new RegExp(search, "gi").test(x.name)
        )
      })))
    }
  }, [search])

  const [allNodes, setAllNodes] = useState([])
  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

  // const options = useSelector((state) => state.options.options); 

  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;

  const changeOptions = (options) => {

    idDispatch({ type: ADD_OPTION, payload: options });
  };

  useEffect(() => {
    // Handle the case when activeId exists and auth is false
    if (activeId && !auth) {
      actions.deserialize(
        {
          "ROOT": {
            "type": { "resolvedName": "Container" },
            "isCanvas": true,
            "props": {
              "isContainer": true,
              "flexDirection": "column",
              "alignItems": "flex-start",
              "justifyContent": "flex-start",
              "fillSpace": "no",
              "background": { "r": 255, "g": 255, "b": 255, "a": 0.708 },
              "startImage": false,
              "color": { "r": 0, "g": 0, "b": 0, "a": 1 },
              "shadow": 0,
              "radius": 0,
              "width": "100%",
              "child": false,
              "height": "auto",
              "minHeight": "500px",
              "isApp": true
            },
            "displayName": "Container",
            "custom": { "displayName": "App" },
            "hidden": false,
            "nodes": [],
            "linkedNodes": {}
          },
        }
      );

    }
  }, [activeId, auth]);

  useEffect(() => {
    if (json && !auth) {
      let jsoned = JSON.parse(json);
      if (jsoned) {
        let length = Object.keys(jsoned).length;
        if (length > 0) {
          localStorage.setItem(activeId._id, json);
          setAllNodes([...allNodes.filter((x) => x._id !== activeId._id), { name: activeId?.name, json: JSON.parse(json) }]);
        }
      }
    }
  }, [json, activeId, auth]);

  // useEffect(() => {
  //   if (json) {
  //     let jsoned = JSON.parse(json)
  //     if (jsoned) {
  //       let length = Object.keys(jsoned)
  //       if (length?.length) {
  //         console.log(json, 99999)
  //         // localStorage.setItem(activeId._id, json);
  //         // setAllNodes([...allNodes.filter(x => x._id !== activeId._id), { name: activeId?.name, json: JSON.parse(json) }])
  //       }
  //     }
  //   }
  // }, [json]) 
  

  useEffect(() => {
    let newCom = search && filterComponents?.length ? filterComponents : components
    let type = search && filterComponents?.length ? "all" : "some"
    console.log(newCom)
    const fetchImages = async () => {
      const urls = await Promise.all(
        newCom.filter(x => type == "all" ? x : x.block === true).map(async (imageObj) => {
          const { name } = imageObj;
          const imagePath = imageObj.block === true && imageObj.url;
          // const { default: imageUrl } = imagePath ? await import(`${imagePath}`) : { default: null }

          return {
            name: imageObj.name,
            component: imageObj.component,
            url: imagePath,

          };
        })
      );

      const groupedComponents = {};

      urls.forEach((x) => {
        const groupName = x.name.replace(/\d+$/, ''); // Remove the index at the end

        if (!groupedComponents[groupName]) {
          groupedComponents[groupName] = [];
        }

        x.parentIcon = <div style={{ padding: "20%" }}>{parents[groupName]}</div>
        groupedComponents[groupName].push(x);
      });

      setAllComponents(groupedComponents);

    };

    fetchImages();
  }, [search]);

  return (
    <>
      {(
       <div
       className={`sidebarApp ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
       style={{
         position: 'fixed',
         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
         top: '13vmin', // Center vertically in the viewport
         left: '20%', // Center horizontally in the viewport
         transform: active
           ? 'translate(-75%, 0) scale(1)' // Full size and positioned at the center
           : 'translate(-50%, -50%) scale(0)', // Initially small and centered
         width: '30vmin', // Adjust sidebar width
         height: '70vmin', // Adjust sidebar height
         paddingTop: '3vmin',
         background: 'linear-gradient(50deg, #FFFFFF)',
         transition: 'transform 300ms ease, opacity 100ms ease', // Transition for scaling and fading
         zIndex: 99999, // Ensure it's above other content
         opacity: active ? 1 : 0, // Fade in and out when active
       }}
     >
            <ToolboxDiv
              active
              enabled={enabled}
              className="flex flex-col w-full h-full max-h-full min-h-full overflow-auto user-select-none transition"
            >
                <div className="flex flex-col items-start w-full">
                        <div className="w-full flex flex-col user-select-none" id="SidebarComponentRoot" onClick={(event) => event.stopPropagation()}>
                            <div className={`sticky top-0 z-[999999999] `}>
                              <div className="w-full flex flex-col" style={{ zIndex: 9999999 }}>
                                <div className={`self-center py-[3%] ${isDarkMode ? 'text-white' : 'text-[#111F3E]'} flex items-center justify-between font-medium text-[2.5Vmin]`}>
                                  COMPONENTS
                                </div>
                              </div>
                              <div className="relative w-full">
                                <div className="flex w-full justify-around items-start border-b border-b-[0.25vmin] ${isDarkMode ? 'border-opacity-40' : 'border-[#E9E9EA]'} pb-[.5Vmin] rounded-[0.15Vmin]">
                                <div onMouseDown={() => setActiveTab("Basic")}
                                    style={getTabStyle("Basic", activeTab, isDarkMode)}>
                                  Basic
                                </div>

                                {/* <div onMouseDown={() => setActiveTab("Blocks")}
                                    style={getTabStyle("Blocks", activeTab, isDarkMode)}>
                                  Blocks
                                </div> */}
                                </div>
                              </div>
                            </div>
                            <div className="containerList" >
                              {activeTab === "Blocks" ? (
                                <div className="flex flex-col items-end w-full h-full" >
                                  <div className="flex flex-col items-end w-full h-full">
                              {Object.entries(allComponents)
                                .filter(([groupName]) => groupName !== "Header" && groupName !== "Footer")
                                .map(([groupName, components], index) => (
                                  <Group
                                  setExpanded={setExpanded}
                                  setOver={setOver}
                                  setAddWidgetActive={setAddWidgetActive}
                                  setGroupActive={setGroupActive}
                                  create={create}
                                  isDarkMode={isDarkMode}
                                  ToolbarSection={ToolbarSection}
                                  expanded={expanded}
                                  key={groupName}  // Better key: groupName for more stable rendering
                                  icon={components[0]?.parentIcon}
                                  name={groupName}
                                  components={components}
                                  />
                                ))}
                            </div>
                                </div>
                              ) : activeTab === "Basic" ? (
                                <Group
                                setExpanded={setExpanded}
                                setOver={setOver}
                                setAddWidgetActive={setAddWidgetActive}
                                setGroupActive={setGroupActive}
                                create={create}
                                isDarkMode={isDarkMode}
                                ToolbarSection={ToolbarSection}
                                expanded={expanded}
                                  noWrap
                                  name={"controls"}
                                  icon={<img alt='controls' src='https://i.ibb.co/TwmhTKW/iconmonstr-dashboard-lined-240.png' />}
                                  components={components.filter(x => x.type === "controls")}
                                />
                              ) : activeTab === "Widgets" ? (
                                <Group
                                setExpanded={setExpanded}
                                setOver={setOver}
                                setAddWidgetActive={setAddWidgetActive}
                                setGroupActive={setGroupActive}
                                create={create}
                                isDarkMode={isDarkMode}
                                ToolbarSection={ToolbarSection}
                                expanded={expanded}
                                  noWrap
                                  name={"Widgets"}
                                  full={true}
                                  icon={<img alt='controls' src='https://i.ibb.co/TwmhTKW/iconmonstr-dashboard-lined-240.png' />}
                                  components={components.filter(x => x.type === "widgets")}
                                />
                              ) : null}
                            </div>
                          </div>
                </div>
            </ToolboxDiv>
          </div>
      )}


    </>
  );
};

