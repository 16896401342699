// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parentWrapper {
    padding: 15px 0;
    width: 100%;
    height:100%
}

.parentWrapper2 {
    width: 100%;
    height:100%
}

.wrapperContainer {
    max-width: 1300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.columnContainer {
    border:1px solid blue;
    display: flex;
    flex-direction: column;
    width: 50%;
    flex: 50% 1; 
    align-content: center; 
    align-items: center; 
    justify-content: center; 
    margin: 20px 0;
}
  
  .banner-img {
    width: 100%;
    border: 1px solid greenyellow;
    border-radius: 30px;
  }
  
  .text-absolute {
    position: absolute;
    top: 3vmin;
    right: 3vmin;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .img-text {
    display: inline-block;
    margin: auto 10px;
  }
  
  .button-text {
    display: inline-block;
    color: #fff;
    cursor: pointer;
  }
  
  .img-button {
    display: block;
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    border-radius: 33px;
  }`, "",{"version":3,"sources":["webpack://./src/components/selectors/blocks/Banner/Banner3.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX;AACJ;;AAEA;IACI,WAAW;IACX;AACJ;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,WAAS;IACT,qBAAqB;IACrB,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;AAClB;;EAEE;IACE,WAAW;IACX,6BAA6B;IAC7B,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,qBAAqB;IACrB,iBAAiB;EACnB;;EAEA;IACE,qBAAqB;IACrB,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,8BAA8B;IAC9B,eAAe;IACf,mBAAmB;EACrB","sourcesContent":[".parentWrapper {\n    padding: 15px 0;\n    width: 100%;\n    height:100%\n}\n\n.parentWrapper2 {\n    width: 100%;\n    height:100%\n}\n\n.wrapperContainer {\n    max-width: 1300px;\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n\n.columnContainer {\n    border:1px solid blue;\n    display: flex;\n    flex-direction: column;\n    width: 50%;\n    flex: 50%; \n    align-content: center; \n    align-items: center; \n    justify-content: center; \n    margin: 20px 0;\n}\n  \n  .banner-img {\n    width: 100%;\n    border: 1px solid greenyellow;\n    border-radius: 30px;\n  }\n  \n  .text-absolute {\n    position: absolute;\n    top: 3vmin;\n    right: 3vmin;\n    width: 100%;\n    height: fit-content;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n  }\n  \n  .img-text {\n    display: inline-block;\n    margin: auto 10px;\n  }\n  \n  .button-text {\n    display: inline-block;\n    color: #fff;\n    cursor: pointer;\n  }\n  \n  .img-button {\n    display: block;\n    background: rgba(0, 0, 0, 0.6);\n    cursor: pointer;\n    border-radius: 33px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
