import React, { useState } from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useNode, useEditor } from '@craftjs/core';
import { CheckBoxSettings } from './CheckBoxSettings';


export const CheckBoxInput = ({
        style = {},
        fontSize = '16px',
        label,
        width,
        className,
        labelPosition = 'after',
        color = '#0867FC',
        backgroundColor= '#989191',
        borderColor = '#0867FC',
        borderWidth,
        borderRadius,
        isCheckBox,
}) => {

        const {
          connectors: { connect },
          actions: {setProp},
        } = useNode();
      
        const { enabled } = useEditor((state) => ({
          enabled: state.options.enabled,
        }));


        const [checked, setChecked] = useState(true);

        const handleChange = (event) => {
          setChecked(event.target.checked);
        };
        
        const handleProperty = (value, propertyName, split) => {
            if (value !== null && !split) {
            return { [propertyName]: value?.split(" ").join("") }
            }

            if (value !== null && split === 'splitBg') {
            return { [propertyName]:  value !== null ? value : '' }
            } else {
            return;
            }
        };


        const styleProps = {
        
              ...(color !== null && {
                color: color?.startsWith('#') ? color : 'transparent',
                backgroundImage: !color?.startsWith('#') ? color : undefined,
                WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
                backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
              }),
          
            ...handleProperty(width, 'width'),
            ...handleProperty(fontSize, 'fontSize'),

           
          };

    const CustomStyle = `
    background-color: ${backgroundColor} !important;
    border-color: ${borderColor} !important;
    border-radius: ${borderRadius?.split(" ").join("")};
    width: ${fontSize?.split(" ").join("")};
    height: ${fontSize?.split(" ").join("")};
    border: ${borderWidth?.split(" ").join("")} solid;
  `;

  const randomId = Math.floor(Math.random() * 1000);

        
  return ( 
  <div
  ref={connect}
  className={className}
  style={{padding:'10px 0', alignItems:'center', display:'flex'}}
  >
    <Checkbox
    defaultChecked={checked}
    onChange={handleChange}
    labelPlacement={labelPosition}
        id={`checkbox${randomId}`} 
    value={checked}
    style={{cssText: CustomStyle, display:'flex', alignContent:'center'}}
    >
    <label
          htmlFor={`checkbox${randomId}`}
          className={"k-checkbox-label"}
          style={{
            ...styleProps,
            ...style,
            alignItems:'center',
            alignContent:'center',
            display:'flex'
          }}
        >
          {label || 'CheckBox'}
        </label>
 </Checkbox>
    </div>
    );
};

CheckBoxInput.craft = {
    displayName: 'CheckBox',
    props: {
      fontSize: '16px',
      label:'CheckBox',
      color: '#0867FC',
      width: null,
      isCheckBox:true,
      labelPosition:'after',
      borderRadius: null
    },
    related: {
      toolbar: CheckBoxSettings,
    },
  };