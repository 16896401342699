/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Element, useEditor, useNode } from '@craftjs/core';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Text from '../Text';
import { Card } from '../Card';
import { ButtonSettings } from '../Button/ButtonSettings';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';

  
export const Blog = (props) => {
  const {
    actions: { setProp },
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));
  

  const { enabled } = useEditor((state, query) => ({
    enabled: state.options.enabled,

  }));

  const {
    selected,
    ...otherProps 
  } = props;

const styles = [ 

  
<Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId20010' className="w-[100%] h-auto" row={true} >
  <div
    
    className={` w-full bg-[#FFF]`}
    
  >
    <Wrapper canvas id='random-id-1' is='div' className='container flex flex-col py-4'>

      <Wrapper canvas id='random-id-2' is='div' className='w-full flex flex-start' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Rubik', color:'#000'}}>
        <Text text='Blog.'/>
      </Wrapper>

      <Wrapper canvas id='random-id-3' is='div' className='w-full flex flex-row justify-content-between my-4'>
        <Wrapper canvas id='random-id-4' is='div'>
        <input className="inpute my-2 form-control bg-transparent text-[#000]" style={{border:'1px solid rgba(178, 3, 18, 0.20)', height:'50px', fontSize:'18px'}} placeholder="Search request"/>
        </Wrapper>
        <div className='w-[auto] h-[50px] flex flex-row justify-between my-auto border-none' style={{border:'1px solid rgba(178, 3, 18, 0.20)'}}>
                    <label className='flex my-auto text-[#000] text-[12px] mx-4' style={{fontWeight:'600', fontFamily:'Rubik'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-5' is='div'>
                    <Text text='Sort by:'/>
                    </Wrapper>
                    </label>
                    <select className='w-[150px] flex align-items-center border-none text-[14px] font-semibold text-[#B20312] bg-transparent' aria-label="Default select example"> 
                        <option className=' bg-[#fff]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="Recent date">
                        <Wrapper canvas style={{display:'inline-block', color:'#B20312'}} id='random-id-6' is='div'>
                    <Text text='Recent date'/>
                    </Wrapper>
                          </option>
                        <option className=' bg-[#fff]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="a-z">A - Z</option>
                        <option className=' bg-[#fff]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="z-a">Z - A</option>
                    </select>
                </div>
      </Wrapper>

      <Element canvas is={Parent} id='parentId101' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId20' style={{overflow:'scroll', maxWidth:'100%'}} className="w-[100%] h-auto justify-content-between" row={true} >
          <Card/>
        </Element>
      </Element>
    </Wrapper>
  </div>
  </Element>
  </Element>,
   
<Element canvas is={Parent} id='parentId19001' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId20101' className="w-[100%] h-auto" row={true} >
  <div
    
    className={` w-full bg-[#121826]`}
    
  >
    <Wrapper canvas id='random-id-7' is='div' className='container flex flex-col py-4'>

      <Wrapper canvas id='random-id-8' is='div' className='w-full flex flex-start IncreaseConversion2' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Rubik'}}>
        <Text text='Blog.'/>
      </Wrapper>

      <Wrapper canvas id='random-id-9' is='div' className='w-full flex flex-row justify-content-between my-4' >
        <Wrapper canvas id='random-id-10' is='div'>
        <input className="inpute my-2 form-control bg-transparent text-white" style={{border:'1px solid #3265E366', height:'50px', fontSize:'18px'}} placeholder="Search request"/>
        </Wrapper>
        <div className='w-[auto] h-[50px] flex flex-row justify-between my-auto border-none' style={{border:'1px solid #3265E366',}}>
                    <label className='flex my-auto text-[#FFFFFF] text-[12px] mx-4' style={{fontWeight:'600', fontFamily:'Rubik'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-11' is='div'>
                    <Text text='Sort by:'/>
                    </Wrapper>
                    </label>
                    <select className='w-[150px] flex align-items-center border-none text-[14px] font-semibold text-[#4BDBA2] bg-transparent' aria-label="Default select example"> 
                        <option className=' bg-[#121826]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="Recent date">
                        <Wrapper canvas style={{display:'inline-block', color:'#4BDBA2'}} id='random-id-12' is='div'>
                    <Text text='Recent date'/>
                    </Wrapper>
                          </option>
                        <option className=' bg-[#121826]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="a-z">A - Z</option>
                        <option className=' bg-[#121826]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="z-a">Z - A</option>
                    </select>
                </div>
      </Wrapper>

      <Element canvas is={Parent} id='parentId102' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId202' style={{overflow:'scroll', maxWidth:'100%'}} className="w-[100%] h-auto justify-content-between" row={true} >
          <Card/>
        </Element>
      </Element>
    </Wrapper>
  </div>
  </Element>
  </Element>,

  
<Element canvas is={Parent} id='parentId19002' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId20302' className="w-[100%] h-auto" row={true} >
  <div
    
    className={` w-full bg-[#213156]`}
    
  >
    <Wrapper canvas id='random-id-13' is='div' className='container flex flex-col py-4'>

      <Wrapper canvas id='random-id-14' is='div' className='w-full flex flex-start' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', fontFamily:'Times New Roman', color:'#EA6EF4'}}>
        <Text text='Blog.'/>
      </Wrapper>

      <Wrapper canvas id='random-id-15' is='div' className='w-full flex flex-row justify-content-between my-4'>
        <Wrapper canvas id='random-id-16' is='div'>
        <input className="inpute my-2 form-control bg-transparent text-white" style={{border:'1px solid rgba(234, 110, 244, 0.40)', height:'50px', fontSize:'18px', borderRadius:'10px'}} placeholder="Search request"/>
        </Wrapper>
        <div className='w-[auto] h-[50px] flex flex-row justify-between my-auto border-none' style={{border:'1px solid rgba(234, 110, 244, 0.40)', borderRadius:'10px'}}>
                    <label className='flex my-auto text-[#FFFFFF] text-[12px] mx-4' style={{fontWeight:'600', fontFamily:'Rubik'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-17' is='div'>
                    <Text text='Sort by:'/>
                    </Wrapper>
                    </label>
                    <select className='w-[150px] flex align-items-center border-none text-[14px] font-semibold text-[#EA6EF4] bg-transparent' aria-label="Default select example"> 
                        <option className=' bg-[#213156]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="Recent date">
                        <Wrapper canvas style={{display:'inline-block', color:'#FFF'}} id='random-id-18' is='div'>
                    <Text text='Recent date'/>
                    </Wrapper>
                          </option>
                        <option className=' bg-[#213156]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="a-z">A - Z</option>
                        <option className=' bg-[#213156]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="z-a">Z - A</option>
                    </select>
                </div>
      </Wrapper>

      <Element canvas is={Parent} id='parentId103' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId204' style={{overflow:'scroll', maxWidth:'100%'}} className="w-[100%] h-auto justify-content-between" row={true} >
          <Card/>
        </Element>
      </Element>
    </Wrapper>
  </div>
  </Element>
  </Element>,

  
<Element canvas is={Parent} id='parentId19003' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId20503' className="w-[100%] h-auto" row={true} >
  <div
    
    className={` w-full bg-[#DFFFF9]`}
    
  >
    <Wrapper canvas id='random-id-19' is='div' className='container flex flex-col py-4'>

      <Wrapper canvas id='random-id-20' is='div' className='w-full flex flex-start' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', color:'#31A993', fontFamily:'Zing Script Rust SemiBold Demo'}}>
        <Text text='Blog.'/>
      </Wrapper>

      <Wrapper canvas id='random-id-21' is='div' className='w-full flex flex-row justify-content-between my-4'>
        <Wrapper canvas id='random-id-22' is='div'>
        <input className="input-4 my-2 form-control bg-transparent text-[#000]" style={{border:'1px solid rgba(49, 169, 147, 0.40)', height:'50px', fontSize:'18px', borderRadius:'30px'}} placeholder="Search request"/>
        </Wrapper>
        <div className='w-[auto] h-[50px] flex flex-row justify-between my-auto border-none' style={{borderRadius:'30px', border:'1px solid rgba(49, 169, 147, 0.40)'}}>
                    <label className='flex my-auto text-[#000] text-[12px] mx-4' style={{fontWeight:'600', fontFamily:'Rubik'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-23' is='div'>
                    <Text text='Sort by:'/>
                    </Wrapper>
                    </label>
                    <select className='w-[150px] flex align-items-center border-none text-[14px] font-semibold text-[#31A993] bg-transparent' aria-label="Default select example"> 
                        <option className=' bg-[#DFFFF9]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="Recent date">
                        <Wrapper canvas style={{display:'inline-block', color:'#000'}} id='random-id-24' is='div'>
                    <Text text='Recent date'/>
                    </Wrapper>
                          </option>
                        <option className=' bg-[#DFFFF9]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="a-z">A - Z</option>
                        <option className=' bg-[#DFFFF9]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="z-a">Z - A</option>
                    </select>
                </div>
      </Wrapper>

      <Element canvas is={Parent} id='parentId104' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId206' style={{overflow:'scroll', maxWidth:'100%'}} className="w-[100%] h-auto justify-content-between" row={true} >
          <Card/>
        </Element>
      </Element>
    </Wrapper>
  </div>
  </Element>
  </Element>,
  
  
<Element canvas is={Parent} id='parentId19004' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId20704' className="w-[100%] h-auto" row={true} >
  <div
    
    className={` w-full bg-[#FFF]`}
    
  >
    <Wrapper canvas id='random-id-25' is='div' className='container flex flex-col py-4'>

      <Wrapper canvas id='random-id-26' is='div' className='w-full flex flex-start' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', color:'#0078D3', fontFamily:'Zing Script Rust SemiBold Demo'}}>
        <Text text='Blog.'/>
      </Wrapper>

      <Wrapper canvas id='random-id-27' is='div' className='w-full flex flex-row justify-content-between my-4'>
        <Wrapper canvas id='random-id-28' is='div'>
        <input className="input-4 my-2 form-control bg-transparent text-[#000]" style={{border:'1px solid rgba(0, 120, 211, 0.20)', height:'50px', fontSize:'18px', borderRadius:'4px'}} placeholder="Search request"/>
        </Wrapper>
        <div className='w-[auto] h-[50px] flex flex-row justify-between my-auto border-none' style={{borderRadius:'4px', border:'1px solid rgba(0, 120, 211, 0.20)'}}>
                    <label className='flex my-auto text-[#000] text-[12px] mx-4' style={{fontWeight:'600', fontFamily:'Rubik'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-29' is='div'>
                    <Text text='Sort by:'/>
                    </Wrapper>
                    </label>
                    <select className='w-[150px] flex align-items-center border-none text-[14px] font-semibold text-[#0078D3] bg-transparent' aria-label="Default select example"> 
                        <option className=' bg-[#FFF]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="Recent date">
                        <Wrapper canvas style={{display:'inline-block', color:'#0078D3'}} id='random-id-30' is='div'>
                    <Text text='Recent date'/>
                    </Wrapper>
                          </option>
                        <option className=' bg-[#FFF]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="a-z">A - Z</option>
                        <option className=' bg-[#FFF]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="z-a">Z - A</option>
                    </select>
                </div>
      </Wrapper>

      <Element canvas is={Parent} id='parentId105' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId208' style={{overflow:'scroll', maxWidth:'100%'}} className="w-[100%] h-auto justify-content-between" row={true} >
          <Card/>
        </Element>
      </Element>
    </Wrapper>
  </div>
  </Element>
  </Element>,
  

  
<Element canvas is={Parent} id='parentId19005' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId20905' className="w-[100%] h-auto" row={true} >
  <div
    
    className={` w-full bg-[#FFF]`}
    
  >
    <Wrapper canvas id='random-id-31' is='div' className='container flex flex-col py-4'>

      <Wrapper canvas id='random-id-32' is='div' className='w-full flex flex-start' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', color:'#020212', fontFamily:'Inter'}}>
        <Text text='Blog.'/>
      </Wrapper>

      <Wrapper canvas id='random-id-33' is='div' className='w-full flex flex-row justify-content-between my-4'>
        <Wrapper canvas id='random-id-34' is='div'>
        <input className="input-4 my-2 form-control bg-transparent text-[#000]" style={{border:'1px solid rgba(2, 2, 18, 0.40)', height:'50px', fontSize:'18px'}} placeholder="Search request"/>
        </Wrapper>
        <div className='w-[auto] h-[50px] flex flex-row justify-between my-auto border-none' style={{ border:'1px solid rgba(2, 2, 18, 0.40)'}}>
                    <label className='flex my-auto text-[#000] text-[12px] mx-4' style={{fontWeight:'600', fontFamily:'Rubik'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-35' is='div'>
                    <Text text='Sort by:'/>
                    </Wrapper>
                    </label>
                    <select className='w-[150px] flex align-items-center border-none text-[14px] font-semibold text-[#020212] bg-transparent' aria-label="Default select example"> 
                        <option className=' bg-[#FFF]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="Recent date">
                        <Wrapper canvas style={{display:'inline-block', color:'#020212'}} id='random-id-36' is='div'>
                    <Text text='Recent date'/>
                    </Wrapper>
                          </option>
                        <option className=' bg-[#FFF]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="a-z">A - Z</option>
                        <option className=' bg-[#FFF]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="z-a">Z - A</option>
                    </select>
                </div>
      </Wrapper>

      <Element canvas is={Parent} id='parentId106' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId200' style={{overflow:'scroll', maxWidth:'100%'}} className="w-[100%] h-auto justify-content-between" row={true} >
          <Card/>
        </Element>
      </Element>
    </Wrapper>
  </div>
  </Element>
  </Element>,

  
<Element canvas is={Parent} id='parentId19006' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId21006' className="w-[100%] h-auto" row={true} >
  <div
    
    className={` w-full bg-[#FBFAFF]`}
    
  >
    <Wrapper canvas id='random-id-37' is='div' className='container flex flex-col py-4'>

      <Wrapper canvas id='random-id-38' is='div' className='w-full flex flex-start' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', color:'#6750A4', fontFamily:'Roboto'}}>
        <Text text='Blog.'/>
      </Wrapper>

      <Wrapper canvas id='random-id-39' is='div' className='w-full flex flex-row justify-content-between my-4'>
        <Wrapper canvas id='random-id-40' is='div'>
        <input className="input-4 my-2 form-control bg-transparent text-[#000]" style={{border:'1px solid rgba(103, 80, 164, 0.40)', borderRadius:'40px', height:'50px', fontSize:'18px'}} placeholder="Search request"/>
        </Wrapper>
        <div className='w-[auto] h-[50px] flex flex-row justify-between my-auto border-none' style={{ border:'1px solid rgba(103, 80, 164, 0.40)', borderRadius:'40px'}}>
                    <label className='flex my-auto text-[#000] text-[12px] mx-4' style={{fontWeight:'600', fontFamily:'Roboto'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-41' is='div'>
                    <Text text='Sort by:'/>
                    </Wrapper>
                    </label>
                    <select className='w-[150px] flex align-items-center border-none text-[14px] font-semibold text-[#6750A4] bg-transparent' aria-label="Default select example"> 
                        <option className=' bg-[#FBFAFF]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Roboto'}} value="Recent date">
                        <Wrapper canvas style={{display:'inline-block', color:'#6750A4'}} id='random-id-42' is='div'>
                    <Text text='Recent date'/>
                    </Wrapper>
                          </option>
                        <option className=' bg-[#FBFAFF]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Roboto'}} value="a-z">A - Z</option>
                        <option className=' bg-[#FBFAFF]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Roboto'}} value="z-a">Z - A</option>
                    </select>
                </div>
      </Wrapper>

      <Element canvas is={Parent} id='parentId107' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId211' style={{overflow:'scroll', maxWidth:'100%'}} className="w-[100%] h-auto justify-content-between" row={true} >
          <Card/>
        </Element>
      </Element>
    </Wrapper>
  </div>
  </Element>
  </Element>,

  
<Element canvas is={Parent} id='parentId19007' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId21207' className="w-[100%] h-auto" row={true} >
  <div
    
    className={` w-full bg-[#2A2A2A]`}
    
  >
    <Wrapper canvas id='random-id-43' is='div' className='container flex flex-col py-4'>

      <Wrapper canvas id='random-id-44' is='div' className='w-full flex flex-start' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', color:'#FFD600', fontFamily:'Rum Raisin'}}>
        <Text text='Blog.'/>
      </Wrapper>

      <Wrapper canvas id='random-id-45' is='div' className='w-full flex flex-row justify-content-between my-4'>
        <Wrapper canvas id='random-id-46' is='div'>
        <input className="input-4 my-2 form-control bg-transparent text-[#000]" style={{border:' 1px solid rgba(255, 214, 0, 0.40)', borderRadius:'8px', height:'50px', fontSize:'18px'}} placeholder="Search request"/>
        </Wrapper>
        <div className='w-[auto] h-[50px] flex flex-row justify-between my-auto border-none' style={{ border:' 1px solid rgba(255, 214, 0, 0.40)', borderRadius:'8px'}}>
                    <label className='flex my-auto text-[#000] text-[12px] mx-4' style={{fontWeight:'600', fontFamily:'Rubik'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-47' is='div'>
                    <Text text='Sort by:'/>
                    </Wrapper>
                    </label>
                    <select className='w-[150px] flex align-items-center border-none text-[14px] font-semibold text-[#FFD600] bg-transparent' aria-label="Default select example"> 
                        <option className=' bg-[#2A2A2A]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="Recent date">
                        <Wrapper canvas style={{display:'inline-block', color:'#FFD600'}} id='random-id-48' is='div'>
                    <Text text='Recent date'/>
                    </Wrapper>
                          </option>
                        <option className=' bg-[#2A2A2A]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="a-z">A - Z</option>
                        <option className=' bg-[#2A2A2A]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="z-a">Z - A</option>
                    </select>
                </div>
      </Wrapper>

      <Element canvas is={Parent} id='parentId108' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId213' style={{overflow:'scroll', maxWidth:'100%'}} className="w-[100%] h-auto justify-content-between" row={true} >
          <Card/>
        </Element>
      </Element>
    </Wrapper>
  </div>
  </Element>
  </Element>,

  
<Element canvas is={Parent} id='parentId19008' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId21408' className="w-[100%] h-auto" row={true} >
  <div
    
    className={` w-full bg-[#F7FBFE]`}
    
  >
    <Wrapper canvas id='random-id-49' is='div' className='container flex flex-col py-4'>

      <Wrapper canvas id='random-id-50' is='div' className='w-full flex flex-start' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', color:'#6064D2', fontFamily:'Rubik'}}>
        <Text text='Blog.'/>
      </Wrapper>

      <Wrapper canvas id='random-id-51' is='div' className='w-full flex flex-row justify-content-between my-4'>
        <Wrapper canvas id='random-id-52' is='div'>
        <input className="input-4 my-2 form-control bg-transparent text-[#000]" style={{border:' 1px solid var(--Grey-light, #ABB0B9)', borderRadius:'8px', height:'50px', fontSize:'18px'}} placeholder="Search request"/>
        </Wrapper>
        <div className='w-[auto] h-[50px] flex flex-row justify-between my-auto border-none' style={{ border:' 1px solid var(--Grey-light, #ABB0B9)', borderRadius:'8px'}}>
                    <label className='flex my-auto text-[#000] text-[12px] mx-4' style={{fontWeight:'600', fontFamily:'Rubik'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-53' is='div'>
                    <Text text='Sort by:'/>
                    </Wrapper>
                    </label>
                    <select className='w-[150px] flex align-items-center border-none text-[14px] font-semibold text-[#6064D2] bg-transparent' aria-label="Default select example"> 
                        <option className=' bg-[#F7FBFE]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="Recent date">
                        <Wrapper canvas style={{display:'inline-block', color:'#6064D2'}} id='random-id-54' is='div'>
                    <Text text='Recent date'/>
                    </Wrapper>
                          </option>
                        <option className=' bg-[#F7FBFE]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="a-z">A - Z</option>
                        <option className=' bg-[#F7FBFE]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Rubik'}} value="z-a">Z - A</option>
                    </select>
                </div>
      </Wrapper>

      <Element canvas is={Parent} id='parentId109' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId215' style={{overflow:'scroll', maxWidth:'100%'}} className="w-[100%] h-auto justify-content-between" row={true} >
          <Card/>
        </Element>
      </Element>
    </Wrapper>
  </div>
  </Element>
  </Element>,

  
<Element canvas is={Parent} id='parentId19009' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId21609' className="w-[100%] h-auto" row={true} >
  <div
    
    className={` w-full bg-[#FEFBF7]`}
    
  >
    <Wrapper canvas id='random-id-55' is='div' className='container flex flex-col py-4'>

      <Wrapper canvas id='random-id-56' is='div' className='w-full flex flex-start' style={{display:'inline-block', fontSize:'46px', fontWeight:'bold', color:'#A25738', fontFamily:'Ruluko'}}>
        <Text text='Blog.'/>
      </Wrapper>

      <Wrapper canvas id='random-id-57' is='div' className='w-full flex flex-row justify-content-between my-4'>
        <Wrapper canvas id='random-id-58' is='div'>
        <input className="input-4 my-2 form-control bg-transparent text-[#000]" style={{border:'1px solid rgba(162, 87, 56, 0.20)', borderRadius:'8px', height:'50px', fontSize:'18px'}} placeholder="Search request"/>
        </Wrapper>
        <div className='w-[auto] h-[50px] flex flex-row justify-between my-auto border-none' style={{ border:'1px solid rgba(162, 87, 56, 0.20)', borderRadius:'8px'}}>
                    <label className='flex my-auto text-[#000] text-[12px] mx-4' style={{fontWeight:'600', fontFamily:'Sansation'}}>
                    <Wrapper canvas style={{display:'inline-block'}} id='random-id-59' is='div'>
                    <Text text='Sort by:'/>
                    </Wrapper>
                    </label>
                    <select className='w-[150px] flex align-items-center border-none text-[14px] font-semibold text-[#A25738] bg-transparent' aria-label="Default select example"> 
                        <option className=' bg-[#FEFBF7]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Sansation'}} value="Recent date">
                        <Wrapper canvas style={{display:'inline-block', color:'#A25738'}} id='random-id-60' is='div'>
                    <Text text='Recent date'/>
                    </Wrapper>
                          </option>
                        <option className=' bg-[#FEFBF7]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Sansation'}} value="a-z">A - Z</option>
                        <option className=' bg-[#FEFBF7]' style={{fontSize:'20px', fontWeight:'400', fontFamily:'Sansation'}} value="z-a">Z - A</option>
                    </select>
                </div>
      </Wrapper>

      <Element canvas is={Parent} id='parentId1010' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId217' style={{overflow:'scroll', maxWidth:'100%'}} className="w-[100%] h-auto justify-content-between" row={true} >
          <Card/>
        </Element>
      </Element>
    </Wrapper>
  </div>
  </Element>
  </Element>,
  
]


const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected > 0) {
  makeOdd(selected);
}
},[selected])

  return (
    <div style={{ padding: enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
          
          {sel == 100 ? (
            <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <BounceLoader
                    size={100}
                    color={"white"}
                    loading={true}
                  />
            </div>
           ) : (
            styles[sel]
           )} 
      </div>
  )
};

Blog.craft = {
  displayName: "Blog",
        props: {
          selected: 1,
          length:9,
          isBlock:true
        },
        rules: {
            canDrag: () => true,
            canDrop: () => true,
        },
        related: {
            toolbar: ButtonSettings,
        },
};
