import React, { useState } from "react";
import GradientSettings from "../../components/editor/Toolbar/GradientColor"
import { NumberSlider } from "../../components/editor/Toolbar/Toolbar-Types/NumberSlider";
import ToolbarSelectDrop from "../../components/editor/Toolbar/ToolbarSelectDrop";
import { Circle3 } from "../iconsSvg"
import NextButton from "./NextButton";

const ThemeStep = ({
    appSettings,
    expandedFonts,
    setExpandedFonts,
    fontTypes,
    setStart, setAppSettings, setWalksFor, setStepper, select, stepper}) => {

    const [selected, setSelected] = useState(null);
    const handleSectionToggle = (sectionName) => {
        setExpandedFonts(prevSection =>
            prevSection === sectionName ? null : sectionName
        );
    };

    const handleFonts = (type, property, value) => {
        setAppSettings((prev) => ({
            ...prev,
            fonts: {
                ...prev.fonts,
                [type]: {
                    ...prev.fonts[type],
                    [property]:  value
                }
            }
        }));
    };


    const handleColorAndBackground = (type, property) => {
        setAppSettings((prev) => ({
            ...prev,
            [type]: { value: property }
        }));
    };

    const prevColors = [
        '#e83030',
        '#5f2b2b',
        '#c31313',
        '#0867fc',
        '#f84173',
        '#ebebeb',
        '#111f3e',
        '#5558b1',
    ];

    const checkSelected = () => {

        if (selected) {
            if (typeof selected !== 'string') {
                return selected;
            }

            if (selected.startsWith('#')) {
                return selected;
            }

            if (selected.startsWith('tr')) {
                return 'Transparent';
            }

            return 'Gradient';
        }
    };

    const [expandedSections, setExpandedSections] = useState({
        MainColor: false,
        MainFont: true
    });

    const toggleSection = (section) => {
        setExpandedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    return (
        <>
            <div className='w-full h-full flex flex-column relative'>
                <div className="w-full h-[80%] max-h-[50vmin] flex flex-column" style={{overflow:'scroll', scrollbarWidth:'none'}}>
                <div className='w-full'>
                    <div
                        style={{borderBottom:'.25vmin solid #e9e9e9'}}
                        className="w-full text-left items-center  flex justify-between p-[1vmin] font-semibold"
                        onClick={() => toggleSection('MainColor')}
                    >
                        <div
                            className={` cursor-pointer`}
                            style={{ fontSize: '2.5Vmin', color: expandedSections.MainColor ? '#161717' : '#989191', paddingLeft: "2%", fontWeight: '500', paddingBottom: '1vmin', fontFamily: 'Inter', 
                                //transition: 'color .2s ease-in',
                                 width: 'fit-content', height: '100%', alignItems: 'center', alignContent: 'center', lineHeight: '100%' }}
                            id="ProjectName"
                        >
                            Main Color
                        </div>
                        <div style={{ width: "2Vmin" }}>
                            <svg style={{ float: 'right', marginLeft: '1vmin', 
//                                 transition: 'all .3s ease-in',
                                 transform: expandedSections.MainColor ? 'rotate(90deg)' : '' }} aria-hidden="true" focusable="false" role="img" className="octicon octicon-chevron-right" viewBox="0 0 12 12" width="2Vmin" height="2Vmin" fill={expandedSections.MainColor ? '#161717' : '#989191'}><path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3Z"></path></svg>
                        </div>
                    </div>
                    {expandedSections.MainColor && <div style={{ textTransform: 'capitalize' }} className="w-full h-full text-start space-y-[.5vmin] px-[1.5vmin] py-[.5vmin]">
                        <div className='h-full flex flex-col p-[1vmin]'  >
                            <div className='flex flex-col'>
                                <div className='flex flex-column w-full justify-between'>
                                    <div className='relative my-[1vmin] min-w-[100%] h-[7vmin] flex justify-center align-items-center rounded-[1vmin] overflow-hidden'>
                                        <input style={{ cursor: 'pointer', minWidth: '150%', height: '130%' }} type='color' value={selected}

                                            onChange={(e) => {
                                                let background = e.target.value
                                                setAppSettings((prev) => ({
                                                    ...prev,
                                                    color : {value: background}
                                                }))
                                                setSelected(e.target.value)
                                            }}
                                        />
                                        <div style={{ pointerEvents: 'none' }} className='absolute top-0 left-0 text-[#fff] text-[2.5vmin] w-full h-full flex align-items-center justify-center text-center'>
                                            <div>{checkSelected() || 'Click Here.'}</div>
                                        </div>
                                    </div>

                                    <ul style={{ width: '100%', padding: '0', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', margin: 'auto auto' }}>
                                        <li className='cursor-pointer' style={{ background: "url(https://i.pinimg.com/originals/40/8e/28/408e28da9e2773e4b65c9bba40307167.png) center", margin: '.35vmin', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '3vmin', height: '3vmin', borderRadius: '50%', border: '.25vmin solid #0867FC30', }} onClick={() => {
                                            setAppSettings((prev) => ({
                                                ...prev,
                                                color : {value: 'transparent'}
                                            }))
                                            setSelected('transparent')
                                        }}>
                                        </li>
                                        {prevColors.map((color, index) => (
                                            <li onClick={(e) => {
                                                setAppSettings((prev) => ({
                                                    ...prev,
                                                    color : {value: color}
                                                }))
                                                setSelected(color)
                                            }}
                                                style={{ cursor: 'pointer', margin: '.35vmin', width: '3vmin', height: '3vmin', borderRadius: '50%', border: '.25vmin solid #e9e9e9', background: color }} key={index}></li>
                                        ))}

                                    </ul>
                                    <GradientSettings preview onChange={() => handleColorAndBackground('color')} />
                                    </div>
                            </div>
                        </div>
                    </div>}
                </div>

                <div className='w-full my-[1vmin]'>
                    <div
                        style={{borderBottom:'.25vmin solid #e9e9e9'}}
                        className="w-full text-left items-center  flex justify-between p-[1vmin] font-semibold"
                        onClick={() => toggleSection('MainFont')}
                    >
                        <div
                            className={` cursor-pointer`}
                            style={{ fontSize: '2.5Vmin', paddingLeft: "2%", color: expandedSections.MainFont? '#161717' : '989191' , fontWeight: '500', paddingBottom: '1vmin', fontFamily: 'Inter', 
                                //transition: 'color .5s ease-in',
                                 width: 'fit-content', height: '100%', alignItems: 'center', alignContent: 'center', lineHeight: '100%' }}
                            id="ProjectName"
                        >
                            Main Fonts
                        </div>
                        <div style={{ width: "2Vmin" }}>
                            <svg style={{ float: 'right', marginLeft: '1vmin', 
//                                 transition: 'all .3s ease-in',
                                 transform: expandedSections.MainFont ? 'rotate(90deg)' : '' }} aria-hidden="true" focusable="false" role="img" className="octicon octicon-chevron-right" viewBox="0 0 12 12" width="2Vmin" height="2Vmin" fill={expandedSections.MainFont ? '#161717' : '#989191'}><path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3Z"></path></svg>
                        </div>
                    </div>
                    {expandedSections.MainFont && <div style={{ textTransform: 'capitalize', color: '#0867FC' }} className="text-[2vmin] text-center w-full space-y-[.5vmin] px-[1vmin] py-[.5vmin]">
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(20vmin, 1fr))', gap: '2vmin' }}>
                {fontTypes.map((type) => (
                    <div key={type} className='shadow-md h-[fit-content]' style={{border: '.25vmin solid #e9e9e9', borderRadius: '1vmin', overflow: 'hidden', fontSize:'3vmin' }}>
                        <div 
                            onClick={() => handleSectionToggle(type)} 
                            className={`bg-[#f0f0f0] hover:text-[#0867FC] text-[#0867FC90]`}
                            style={{ cursor: 'pointer', padding: '1vmin', borderBottom: '.25vmin solid #e9e9e9', }}
                        >
                            <div style={{ margin: 0 }}>{type.toUpperCase()}</div>
                        </div>
                        {expandedFonts === type && (
                            <div style={{ padding: '2vmin' }}>
                                <NumberSlider
                                    props={{ label: 'Font Size' }}
                                    value={appSettings.fonts[type].fontSize}
                                    min={0}
                                    max={50}
                                    onChange={(value) => handleFonts(type, 'fontSize', value)}
                                />
                                <ToolbarSelectDrop
                                    optionsDrop={['normal', 'bold', 'bolder', 'lighter']}
                                    props={{ label: 'Font Weight' }}
                                    value={appSettings.fonts[type].fontWeight}
                                    onChange={(value) => handleFonts(type, 'fontWeight', value)}
                                />
                                <ToolbarSelectDrop
                                    optionsDrop={['Rubik', 'Open Sans', 'Inter', 'Roboto', 'Voces', 'Poppins', 'Sansation', 'Prompt', 'Almarai', 'Chivo',]}
                                    props={{ label: 'Font Family' }}
                                    value={appSettings.fonts[type].fontFamily}
                                    onChange={(value) => handleFonts(type, 'fontFamily', value)}
                                />
                                <ToolbarSelectDrop
                                    optionsDrop={['uppercase', 'lowercase', 'capitalize']}
                                    props={{ label: 'Text Transform' }}
                                    value={appSettings.fonts[type].textTransform}
                                    onChange={(value) => handleFonts(type, 'textTransform', value)}
                                />
                            </div>
                        )}
                    </div>
                ))}
            </div>
                    </div>}
                </div>
                </div>

                <NextButton setStart={setStart} onClick={() => {
                        setStepper(3)
                        setWalksFor((prev) => ({
                            ...prev,
                            main: { value: 0.750 },
                            mainTheme: { value: 3 }
                        }))
                    }}/>
                <div>
                {select !== null && <div className='absolute top-[0] right-[-5vmin]'>
                    <Circle3 fill={stepper >= 3 ? 'green' : ''} />
                </div>}
                </div>
            </div>
        </>
    )
}

export default ThemeStep;