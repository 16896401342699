/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { MarkIcon } from '../Svg'
import './benefits.css'
export default function Benefits() {
    return (
        <div className="w-full h-full container pt-40 pb-24">
            <div className="paper">
                <div className="benefits-header" style={{width:'100%'}}>No matter what goals you have AYCC helps you to achieve them on time</div>
                <div className='banner-benefits relative'>
                    <div className='mark-fits absolute top-[80px] left-[-120px] flex flex-row items-center justify-center'>
                        <div><MarkIcon/></div>
                        <div className='mark-content'>Intuitive Drag-and-Drop Interface</div>
                    </div>
                    <div className='mark-fits absolute top-60 right-[-120px] flex flex-row items-center justify-center'>
                        <div><MarkIcon/></div>
                        <div className='mark-content'>Customizable Templates</div>
                    </div>
                    <div className='mark-fits absolute bottom-[100px] right-[-200px] flex flex-row items-center justify-center'>
                        <div><MarkIcon/></div>
                        <div className='mark-content'>Collaboration Tools for Teams</div>
                    </div>
                    <div className='mark-fits absolute bottom-[90px] left-[-180px] flex flex-row items-center justify-center'>
                        <div><MarkIcon/></div>
                        <div className='mark-content'>Responsive Design Options</div>
                    </div>
                </div>
                <div className='mark-fits-hidden mt-4'>
                    <div className='mark-fits-under float-left'>
                        <div><MarkIcon/></div>
                        <div className='mark-content'>Intuitive Drag-and-Drop Interface</div>
                    </div>
                    <div className='mark-fits-under float-right'>
                        <div><MarkIcon/></div>
                        <div className='mark-content'>Customizable Templates</div>
                    </div>
                    <div className='mark-fits-under float-left'>
                        <div><MarkIcon/></div>
                        <div className='mark-content'>Responsive Design Options</div>
                    </div>
                    <div className='mark-fits-under float-right'>
                        <div><MarkIcon/></div>
                        <div className='mark-content'>Collaboration Tools for Teams</div>
                    </div>
                </div>
            </div>
        </div>
    )
}