/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { ToolbarSection, ToolbarItem } from '../../editor';
import { TabOption } from '../../editor/Viewport/Sidebar/TabOption';
import { useNode } from '@craftjs/core';
import { ArrowToRight } from '../../editor/Viewport/Pop-up/svgArray';

export const DividerSettings = () => {
    const [activeTab, setActiveTab] = useState("Style Manager");


    const {
        options,
        node,
    } = useNode((node) => ({
        options: node.data.props,
    }));

    
  const [customize, setCustomize] = useState({})

  const handleCustomize = (group) => {
    setCustomize((prev) => ({
        ...prev,
        [group]: !prev[group], // Toggle the current group's state
    }));
};

    return (
        <div className='flex flex-col component-settings' style={{ height: '100%' }}>
            <ToolbarItem
                full={true}
                type={null}
            />
            <div className='w-full'>
                <TabOption noAdvanced noContent activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>

            <div style={{ overflowY: 'scroll', scrollbarWidth: 'none', overflowX: 'hidden', }}>
               

                {activeTab === "Style Manager" ? (
                    <div style={{ width: "100%" }}>

                        <ToolbarSection
                            expanded={false}
                            title="Size"
                            props={['fontSize', 'fontWeight', 'textAlign']}
                        >
                            <ToolbarItem
                                full={true}
                                propKey="width"
                                type="slider"
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                label="Width"
                            />

                            <ToolbarItem
                                full={true}
                                propKey="height"
                                type="slider"
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                label="Height"
                            />


                        </ToolbarSection>
                        <ToolbarSection
                            expanded={false}
                            title="Appearance"
                            props={['fontSize', 'fontWeight', 'textAlign']}

                        >
                            <ToolbarItem
                                full={true}
                                propKey="background"
                                type="bg"
                                label="Background"
                            />

                        </ToolbarSection>
                        <ToolbarSection
                            expanded={false}
                            title="Shadow"
                            props={['fontSize', 'fontWeight', 'textAlign']}

                        >
                            <ToolbarItem
                                full={true}
                                propKey="shadowY"
                                type="slider"
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                label="Y offset"
                            />

                            <ToolbarItem
                                full={true}
                                propKey="shadowX"
                                type="slider"
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                label="X offset"
                            />

                            <ToolbarItem
                                full={true}
                                propKey="shadowBlur"
                                type="slider"
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                label="Blur"
                            />

                            <ToolbarItem
                                full={true}
                                propKey="shadowColor"
                                type="color"
                                label="Shadow Color"
                            />



                        </ToolbarSection>

                        <ToolbarSection
                            expanded={true}
                            title="Border"
                            props={['width', 'height', 'minWidth', 'maxWidth']}
                            summary={({ width, height, minWidth, maxWidth }) => {
                                return `Width: ${width || 'Auto'}, Height: ${height || 'Auto'}, Min Width: ${minWidth || 'None'}, Max Width: ${maxWidth || 'None'}`;
                            }}
                        >
                            <ToolbarItem
                                full={true}
                                propKey="borderWidth"
                                type="slider"
                                max
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                label="Border Width"
                            />


                            <ToolbarItem
                                full={true}
                                propKey="borderRadius"
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                type="slider"
                                label="Rounded"
                            />

                            <ToolbarItem
                                full={true}
                                propKey="borderColor"
                                type="color"
                                label="Border Color"
                            />

                        </ToolbarSection>

                        <ToolbarSection

              title="margin"
              props={['margin']}
              summary={({ }) => {
                return;
              }}
            >
             {customize['margin'] ? (
              <>
              <ToolbarItem propKey="marginTop" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Top" />
            <ToolbarItem propKey="marginRight" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Right" />
            <ToolbarItem propKey="marginBottom" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Bottom" />
            <ToolbarItem propKey="marginLeft" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Left" /></>
             ) : (
              <ToolbarItem propKey="margin" type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Margin" />
             ) } 

            <div onClick={() => handleCustomize('margin')} style={{borderRadius:'0 0 1vmin 1vmin', border:'.25vmin solid'}} className='absolute top-[-.15vmin] min-w-[6vmin] flex justify-center items-center right-0 border-color-[#0867FC80] cursor-pointer text-[#0867FC80] p-[.5vmin] hover:text-[#0867FC]'>{customize['margin'] ? <ArrowToRight fill='#0867FC' style={{transform:'rotate(180deg)'}} width='3vmin' height='2vmin'/> : 'customize' }</div>

            </ToolbarSection>


            <ToolbarSection

              title="Padding"
              props={['padding']}
              summary={({ }) => {
                return;
              }}
            >
              {customize['padding'] ? (
                <>
                <ToolbarItem propKey="paddingTop" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Top" />
                <ToolbarItem propKey="paddingRight" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Right" />
                <ToolbarItem propKey="paddingBottom" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Bottom" />
                <ToolbarItem propKey="paddingLeft" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Left" /> 
                </>
              ) : (
                <ToolbarItem propKey="padding" type="slider"
                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                label="Padding" />
              )}
                
                <div onClick={() => handleCustomize('padding')} style={{borderRadius:'0 0 1vmin 1vmin', border:'.25vmin solid'}} className='absolute top-[-.15vmin] min-w-[6vmin] flex justify-center items-center right-0 border-color-[#0867FC80] cursor-pointer text-[#0867FC80] p-[.5vmin] hover:text-[#0867FC]'>{customize['padding'] ? <ArrowToRight fill='#0867FC' style={{transform:'rotate(180deg)'}} width='3vmin' height='2vmin'/> : 'customize' }</div>
            </ToolbarSection>

                        <ToolbarSection
                            expanded={true}
                            title="Border"
                            props={['width', 'height', 'minWidth', 'maxWidth']}
                            summary={({ width, height, minWidth, maxWidth }) => {
                                return `Width: ${width || 'Auto'}, Height: ${height || 'Auto'}, Min Width: ${minWidth || 'None'}, Max Width: ${maxWidth || 'None'}`;
                            }}
                        >
                            <ToolbarItem
                                full={true}
                                propKey="borderWidth"
                                type="slider"
                                max
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                label="Border Width"
                            />


                            <ToolbarItem
                                full={true}
                                propKey="borderRadius"
                                unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
                                type="slider"
                                label="Rounded"
                            />

                            <ToolbarItem
                                full={true}
                                propKey="borderColor"
                                type="color"
                                label="Border Color"
                            />

                        </ToolbarSection>

                    </div>
                ) :  null}
            </div>
        </div>
    );
};
