// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.solution-card {
    display: flex;
    flex-direction: column;
    max-width: 380px;
    min-width: 324px;
    flex:29% 1;
    min-height: 260px;
    text-align: center;
    justify-content: space-between;
    padding: 20px 0;
    margin: 10px;
}

.solution-card2 {
    min-height: 276px;
    max-width: 350px;
    min-width: 330px;
    flex:28px 1;
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    text-align: center;
    justify-content: space-between;
    padding: 20px 0;

}
`, "",{"version":3,"sources":["webpack://./src/components/selectors/blocks/Solution/Solution.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,gBAAgB;IAChB,UAAQ;IACR,iBAAiB;IACjB,kBAAkB;IAClB,8BAA8B;IAC9B,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,WAAS;IACT,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,8BAA8B;IAC9B,eAAe;;AAEnB","sourcesContent":[".solution-card {\n    display: flex;\n    flex-direction: column;\n    max-width: 380px;\n    min-width: 324px;\n    flex:29%;\n    min-height: 260px;\n    text-align: center;\n    justify-content: space-between;\n    padding: 20px 0;\n    margin: 10px;\n}\n\n.solution-card2 {\n    min-height: 276px;\n    max-width: 350px;\n    min-width: 330px;\n    flex:28px;\n    display: flex;\n    flex-direction: column;\n    border-radius: 14px;\n    text-align: center;\n    justify-content: space-between;\n    padding: 20px 0;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
