import React, { useState, useRef } from 'react';
import Dropdown from './DropDownComponent'; // Ensure this path is correct

const SpecificArea = () => {
  const [startCoords, setStartCoords] = useState(null);
  const [endCoords, setEndCoords] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [rectangles, setRectangles] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentRectIndex, setCurrentRectIndex] = useState(null);
  const containerRef = useRef(null);

  // Handle mouse down event
  const handleMouseDown = (e) => {
    const { left, top } = containerRef.current.getBoundingClientRect();
    setStartCoords({ x: e.clientX - left, y: e.clientY - top });
    setIsDragging(true);
  };

  // Handle mouse move event
  const handleMouseMove = (e) => {
    if (isDragging && startCoords) {
      const { left, top } = containerRef.current.getBoundingClientRect();
      setEndCoords({ x: e.clientX - left, y: e.clientY - top });
    }
  };

  // Handle mouse up event
  const handleMouseUp = () => {
    if (startCoords && endCoords) {
      const width = endCoords.x - startCoords.x;
      const height = endCoords.y - startCoords.y;
      setRectangles([...rectangles, {
        x: Math.min(startCoords.x, endCoords.x),
        y: Math.min(startCoords.y, endCoords.y),
        width: Math.abs(width),
        height: Math.abs(height),
        content: null // Initialize with no content
      }]);
      setShowDropdown(true);
      setCurrentRectIndex(rectangles.length); // Set the index of the newly created rectangle
    }
    setStartCoords(null);
    setEndCoords(null);
    setIsDragging(false);
  };

  // Handle rectangle deletion
  const handleDelete = (index) => {
    setRectangles((prevRects) => prevRects.filter((_, i) => i !== index));
  };

  // Handle option selection from the dropdown
  const handleOptionSelect = (content) => {
    if (currentRectIndex !== null) {
      setRectangles(rectangles.map((rect, i) =>
        i === currentRectIndex ? { ...rect, content } : rect
      ));
    }
    setShowDropdown(false);
  };

  // Calculate the dropdown position
  const dropdownPosition = startCoords && endCoords ? {
    top: `${Math.min(startCoords.y, endCoords.y)}px`,
    left: `${Math.min(startCoords.x, endCoords.x)}px`,
  } : { top: '0px', left: '0px' };

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        width: '100%',
        height: '500px',
        border: '1px solid black',
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap'
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      {rectangles.map((rect, index) => (
        <div
          key={index}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(index);
          }}
          style={{
            margin: `${'5'}px`,
            width: `${rect.width}px`,
            height: `${rect.height}px`,
            backgroundColor: 'rgba(0, 150, 255, 0.5)',
            border: '1px solid blue',
            overflow: 'hidden',
            color:'#000',
            fontSize:'2em'
          }}
        >
          {rect.content}
        </div>
      ))}

      {startCoords && endCoords && isDragging && (
        <div
          style={{
            position: 'absolute',
            top: `${Math.min(startCoords.y, endCoords.y)}px`,
            left: `${Math.min(startCoords.x, endCoords.x)}px`,
            width: `${Math.abs(endCoords.x - startCoords.x)}px`,
            height: `${Math.abs(endCoords.y - startCoords.y)}px`,
            backgroundColor: 'rgba(0, 150, 255, 0.3)',
            border: '1px solid blue',
          }}
        />
      )}

      {showDropdown && (
        <div
          style={{
            position: 'absolute',
            ...dropdownPosition,
            zIndex: 1000,
          }}
        >
          <Dropdown onOptionSelect={handleOptionSelect} />
        </div>
      )}
    </div>
  );
};

export default SpecificArea;
