/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { CardIcon } from '../Svg'
import './banner.css'
export default function Banner() {
    return (
        <div className='w-full h-full container'>
            <div className="flex-container py-[40px]">
                <div className="left-content h-full" style={{flex:'50%'}}>
                    <div className='content-1 my-4'>
                    Bring your Organization
                    into the <span>Digital Era</span>
                    </div>
                    <div className='content-2 my-4'>
                    Solutions are built digitally with All You Can Cloud, 
                    transcending boundaries and levels of experience. 
                    With our tools, we want you to write the next Digital Revolution and reach new milestones with the challenges of this century.
                    </div>
                    <div className='content-button'>
                        <div className='button-text'>
                        start Building Now
                        </div>
                    </div>
                </div>
                <div className="right-content h-full" style={{flex:'50%'}}>
                    <img alt='header ' src='https://i.ibb.co/Qmk1pPy/download-1.png'/>
                </div>
            </div>
    </div>
    )
}