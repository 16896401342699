/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React from 'react';

import { ToolbarItem, ToolbarSection } from '../../../editor/index.js';

export const NewContactSettings = () => {

  return (
    <div style={{height:"100%"}}>
       <ToolbarSection
       expanded={true}

        title="Appearance"
        props={['color', 'shadow']}
        summary={({ color, shadow }) => {
          return (
            <div className="text-right">
              <p
                style={{
                  color: color && `rgba(${Object.values(color)})`,
                  textShadow: shadow && `0px 0px 2px rgba(0, 0, 0, ${shadow / 100})`,
                }}
                className="text-white text-right"
              >
                T
              </p>
            </div>
          );
        }}
      >
       
        <ToolbarItem
          full={true}
          propKey="selected"
          type="vslider"
          label="Styling"
        />

      </ToolbarSection>
    </div>
  );
}; 