/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { Parent } from '../../selectors/Parent';
import { Wrapper } from '../../selectors/wrapper';
import Text from '../../selectors/basic/Text';
import { Element, useEditor, useNode } from '@craftjs/core';
import { SvgIcon } from '../../selectors/basic/Svg';
import { CloseIcon2 } from '../../editor/Viewport/Pop-up/svgArray';
import NavbarItems from './NavbarList';
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  Avatar,
} from "@progress/kendo-react-layout";
// import MenuItems from './MenuItems';

const TheMenu = ({ navbarProps, onClose, logoIcon, menu, color, type }) => {

  const [clicked, setClicked] = useState(null)

  let userLogo =
    `M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z`

  let closeIcon =
    `M11.9999 13.4141L17.6569 19.0711C17.8455 19.2533 18.0982 19.3541 18.3603 19.3518C18.6225 19.3495 18.8734 19.2444 19.0588 19.059C19.2442 18.8736 19.3493 18.6227 19.3516 18.3606C19.3539 18.0984 19.2531 17.8458 19.0709 17.6571L13.4139 12.0001L19.0709 6.34315C19.2531 6.15455 19.3539 5.90194 19.3516 5.63975C19.3493 5.37755 19.2442 5.12674 19.0588 4.94133C18.8734 4.75592 18.6225 4.65075 18.3603 4.64848C18.0982 4.6462 17.8455 4.74699 17.6569 4.92915L11.9999 10.5861L6.34295 4.92915C6.15349 4.75149 5.90236 4.65451 5.64268 4.65873C5.38299 4.66295 5.13514 4.76803 4.95155 4.95174C4.76797 5.13546 4.66307 5.38339 4.65903 5.64307C4.655 5.90276 4.75216 6.15382 4.92995 6.34315L10.5859 12.0001L4.92895 17.6571C4.83343 17.7494 4.75725 17.8597 4.70484 17.9817C4.65244 18.1037 4.62485 18.235 4.62369 18.3677C4.62254 18.5005 4.64784 18.6322 4.69812 18.7551C4.7484 18.878 4.82266 18.9897 4.91655 19.0835C5.01044 19.1774 5.1221 19.2517 5.24499 19.302C5.36789 19.3523 5.49957 19.3776 5.63235 19.3764C5.76513 19.3752 5.89635 19.3477 6.01835 19.2953C6.14035 19.2428 6.2507 19.1667 6.34295 19.0711L11.9999 13.4141Z`

  let notice =
    `m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 6.5c-.414 0-.75.336-.75.75v5.5c0 .414.336.75.75.75s.75-.336.75-.75v-5.5c0-.414-.336-.75-.75-.75zm-.002-3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z`

  let eyeNotSeen =
    `m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm8.413 7c-1.837 2.878-4.897 5.5-8.413 5.5-3.465 0-6.532-2.632-8.404-5.5 1.871-2.868 4.939-5.5 8.404-5.5 3.518 0 6.579 2.624 8.413 5.5zm-8.411-4c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5 2.5-1.12 2.5-2.5-1.12-2.5-2.5-2.5z`

  let eyeSeen =
    `m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm.002 3c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z`

  const handleClose = () => {
    onClose()
  }



  let loginSuccess = false
  let name = 'name'
  let notify = [
    {
      message: `You've a new Message`,
      name: name,
    },

  ]

  return (
    <>
      {/* Logo and Close button */}
      <div onClick={(e) => e.stopPropagation()} className='w-full flex flex-column'>
        {type === 'Menu' ?
          (<>
            <div className={`min-w-[100%] shadow-md flex justify-between items-center py-[15.5px] px-8 `} >
              <div className="w-[fit-content] flex items-center">
                <Element none canvas is={Parent} id={`parentId-12`} className="w-[fit-content] h-auto ">
                  <Wrapper canvas id='random-id-10' is='div' className="cursor-pointer w-[fit-content]">
                    <SvgIcon nav viewBox='0 0 24 24' path={logoIcon} />
                  </Wrapper>
                </Element>
                <Element none canvas is={Parent} id={`parentId-1`} className="w-[fit-content] h-auto ml-[4px]">
                  <Wrapper canvas className='flex-1 w-[fit-content] text-center' id={`random-id-5`} is='div'>
                    <Text text={'AllYouCanCloud'} />
                  </Wrapper>
                </Element>
              </div>
              <Element none canvas is={Parent} id={`parentId-1223`} className="w-[fit-content] h-auto ">
                <Wrapper canvas id='random-id-12' is='div' onClick={handleClose} className="cursor-pointer w-[fit-content]">
                  <SvgIcon nav viewBox='0 0 24 24' path={closeIcon} />
                </Wrapper>
              </Element>
            </div>

            <div className='container py-[6px]'>
              <NavbarItems color={color} menuFrom menu={menu} navbarProps={navbarProps} />
            </div>
          </>) : type === 'Login' ? (
            <div className='w-full h-full justify-center align-items-center flex flex-column text-[11px]'>
              <div style={{ alignContent: 'center', alignItems: 'center', height: '100%', padding: '.5vmin 0 1vmin 0' }} className=' w-[100%] flex justify-around my-auto'>
                <Element none canvas is={Parent} id={`parentId-1928`} className="w-[fit-content] h-auto ">
                  <Wrapper canvas id='random-id-144' is='div' className="cursor-pointer w-[fit-content]">
                    <SvgIcon nav viewBox='0 0 24 24' fill='#fff' path={userLogo} />
                  </Wrapper>
                </Element>
                <AppBarSection>
                  <span className="k-appbar-separator" />
                </AppBarSection>
                {!loginSuccess && <div className='mx-[5px]' style={{ textTransform: 'capitalize' }}>{name}</div>}
              </div>
              <div style={{ textTransform: 'capitalize' }} className='container flex flex-column h-full'>
                <Element id='random-id-111' is={Parent} canvas className='cursor-pointer w-[fit-content] my-[4px]'>
                  <Wrapper is='div' canvas id='random-id-0111'>
                    <Text text='Login' />
                  </Wrapper> </Element>
                <Element id='random-id-122' is={Parent} canvas className='cursor-pointer w-[fit-content] my-[4px]'>
                  <Wrapper is='div' canvas id='random-id-022'>
                    <Text text='Sign up' />
                  </Wrapper> </Element>
                <Element id='random-id-144' is={Parent} canvas className='cursor-pointer w-[fit-content] my-[4px]'>
                  <Wrapper is='div' canvas id='random-id-033'>
                    <Text text='Forget Account' />
                  </Wrapper> </Element>
                <Element id='random-id-133' is={Parent} canvas className='cursor-pointer w-[fit-content] my-[4px]'>
                  <Wrapper is='div' canvas id='random-id-044'>
                    <Text text='Forget Password' />
                  </Wrapper> </Element>
              </div>
            </div>
          ) : type === 'Notify' ? (
            <div className='w-full h-[max-content] text-[11px] container py-[5px]'>
              {notify.length >= 0 ? (
                notify.map((notification, i) => (
                  <>
                    <div key={i} className='w-full h-[fit-content] my-[4px] flex justify-between text-[#000]'
                      style={{
                        background: (i === clicked && clicked !== null) ? '#e9e9e930' : '',
                        border: '1px solid #e9e9e930',
                        padding: '3px',
                        borderRadius: '.5vmin',
                        fontSize: '8px'
                      }}>
                      <Element is={Parent} id='random-id-4012' canvas>
                        <Wrapper is='div' id='random-id-3211' canvas>
                          <Text text={`*${notification.message || "You've a new message"}!!`} />
                        </Wrapper>
                      </Element>
                      <Element none id='random-id-1010' is={Parent} canvas className="w-[fit-content] h-auto ">
                        <Wrapper id='random-id-3222' is='div' canvas onClick={() => setClicked(i) }>
                          <SvgIcon width='10px' height='10px' viewBox='0 0 24 24' fill='#FFF'
                            path={i === clicked && clicked !== null ? eyeSeen : eyeNotSeen} />
                        </Wrapper>
                      </Element>
                    </div>
                    <div style={{
                      fontSize: '6px',
                      height: '100%'
                    }}>There's {notify.length} messages in the box</div>
                  </>
                ))
              ) : (
                <div className='w-full h-full flex-column'>
                  <Element is={Parent} id='random-id-411012' canvas>
                    <Wrapper is='div' id='random-id-322211' canvas>
                      <Text text={'Not notification yet.'} />
                    </Wrapper>
                  </Element>
                  <Element none canvas is={Parent} id={`parentId-1922228`} className="w-[fit-content] h-auto p-[10px] flex justify-center ">
                    <Wrapper canvas id='random-id-14234' is='div' className="cursor-pointer w-[fit-content]">
                      <SvgIcon nav viewBox='0 0 24 24' fill='#fff' path={notice} />
                    </Wrapper>
                  </Element>

                </div>
              )}
            </div>
          ) : null}
      </div>
    </>

  );
};

export default TheMenu;
