/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useRef, useState, useLayoutEffect, useContext } from 'react';


import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";


import Land from './components/land/index.js';

import MessageParser from './bot/MessageParser.js';
import ActionProvider from './bot/ActionProvider.js';
import config from './bot/config.js'
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import "./App.css"



import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { CloseIcon } from './components/land/Svg.js';
import { Page_Editor } from './components/index.js';
import { SSO } from './components/sso/index.js';
import { Login } from './login/Login.js';
import { apolloClientOne, apolloClientTwo } from './apolloClient';
import { ApolloProvider, useMutation } from '@apollo/client';
import { Log } from './login/Log.js';
import SpecificArea from './Tutourial/ViewArea/SpecificArea.js';
import { useDarkMode } from './DarkModeContext.js';


function App() {

  const location = useLocation();
  const client = location.pathname.startsWith('/auth') || location.pathname.startsWith('/build') ?  apolloClientOne : apolloClientTwo ;

  const chatbotRef = useRef(null);
  const [opened, setOpened] = useState(true)
  const {isDarkMode} = useDarkMode()


  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (chatbotRef.current && !chatbotRef.current.contains(event.target)) {
  //       setOpened(false);
  //     }
  //   };
  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };

  // }, [chatbotRef])

  // const app = useRef(null);

  // gsap.registerPlugin(Draggable);

  
  // useLayoutEffect(() => {
  //   let ctx = gsap.context(() => {
  //     // use scoped selectors
  //     Draggable.create(".box", {
  //     });

  //   }, app);
    
  //   return () => ctx.revert();
  // }, []);

  const [focused, setFocused] = useState(false)

  const [appActive, setAppActive] = useState(false)


  // console.log(process.env.REACT_APP_AUTH, 100)

  // useEffect(() => {
  //   if(process.env.REACT_APP_AUTH == "true") {
  //     setAppActive(true)
  //   }
  // },[process.env.REACT_APP_AUTH])


 if(window.location.hostname === "sso.allyoucancloud.com") {
  return <Log direct={true} />
 }



  return (
  <ApolloProvider client={client}>

    <div className="h-full h-screen">
      <div style={{
        background:isDarkMode ? 'linear-gradient(100deg,#111F3E, #111F3E)' : 'linear-gradient(50deg, #FFFFFF, #1E90FF)',
        filter:'blur(2px)',
        zIndex: 0,
      }} className='bg-red w-full h-full absolute top-0 left-0'></div>
      {/* <div 
      className='absolute box m-[3vmin] cursor-pointer border-[.25Vmin] bg-white border-[rgba(233, 233, 234, 0.4)] hover:border-[#0867FC] rounded-full w-[8.5Vmin] align-items-center justify-center flex min-h-[8.5Vmin] border-4' 
      style={{
        transition: 'all .2s ease-in-out',
        boxShadow: '0 6px 24px rgba(0, 0, 0, 0.1)',
        bottom: '3.5vmin',
        left: '3.5vmin',
        zIndex:1,
      }}
      // ref={app}
      >
        <div className='items-center justify-center flex h-full' onClick={() => setOpened(!opened)}>
          {!opened ? (
            <img
            src="https://file.rendit.io/n/hgQeGwikg5Hh6qeQRkUN.svg"
            style={{ width: "5Vmin", height: '5Vmin' }}
            id="Logo"
          />
          ) : <CloseIcon width='3.5vmin' height='3.5vmin' fill={`${focused? "#0867FC" : '#989191'}`}/>}
        </div>
        {opened && <div ref={chatbotRef}
      style={{
        transition: 'all .3s ease-in-out',
        position: "absolute",
        zIndex: "99999",
        left:  '8.3vmin',
        bottom: '8vmin',

      }}>
        <Chatbot
          config={config}
          messageParser={MessageParser}
          actionProvider={ActionProvider}
          placeholderText='Write your message here...'

        />
      </div>}
     </div> */}

      {/*  </div> */}
      
      <Switch>

<Route
  exact path="/"
  render={() => (
    <Page_Editor />         
 )} />


<Route
  exact path="/preview/:id"
  render={(props) => {
    const { id } = props.match.params;
    if(id) {
      return(
    <Page_Editor preview view={id} />   
      )      
  } else {
    return ""
  }
  }} />


<Route
  exact path="/build/:id"
  render={(props) => {
    const { id } = props.match.params;
    if(id) {
      return(
    <Page_Editor view={id} />   
      )      
  } else {
    return ""
  }
  }} />



<Route
  exact path="/auth/:id"
  render={(props) => {
    const { id } = props.match.params;
    if(id) {
      return(
        <Page_Editor orgId={id} auth={true} />
        )
    } else {
      return ""
    }
    }} />

<Route 
  exact path="/sso"
  render={() => (
      <SSO />
 )} />

<Route
  exact path="/home"
  render={() => (
    <Land />
  )} />

<Route
  exact path="/test"
  render={() => (
    <SpecificArea />
  )} />

</Switch>
    </div>
        </ApolloProvider>

  );
}

export default App;