/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { createChatBotMessage } from "react-chatbot-kit";
import GotIt from "./widgets/options/GotIt";
import AgeDropdown from "./widgets/options/AgeDropdown";

const config = {
  botName: "All you can cloud",
  initialMessages: [
    createChatBotMessage(`Hello, welcome to the All You Can Cloud Page Builder! We would love to hear your feedback on using version 1 of our page builder.`, {
      widget: "Hello",
    }),
  ],
  customStyles: {
    botMessageBox: {
      backgroundColor: "transparent",
    },
    chatButton: {
      backgroundColor: "#376B7E",
    },
  },
  customComponents: {
    botAvatar: (props) => <div style={{display:'none'}}><img src={"https://cdn-icons-png.flaticon.com/128/7979/7979868.png"} alt="bot" className="w-[7Vmin] h-[7Vmin]" /></div>,
  },
  widgets: [
    {
      widgetName: "Hello",
      widgetFunc: (props) => <GotIt {...props} />,
    },
    {
      widgetName: "ageDropdown",
      widgetFunc: (props) => <AgeDropdown {...props} />,
    },
  ],
};

export default config;
