/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useState } from 'react';
import './contact.css'
import { ContactImg } from '../Svg';

export default function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        role: '',
        company: '',
        message: ''
      });

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };

    return (
        <div className='w-full h-full container pb-20'>
            <div className='contact-container'>
                <div className='contact-content pl-2' style={{flex:'49%'}}>
                    <div className='contact-header mb-[3%]'>Let’s get in Touch</div>
                    <div className='contact-subheader mb-[3%]'>Have questions, feedback, or just want to say hello? 
                    Fill out the form below, and we'll get back to you as soon as possible.</div>
                    <div className='contact-form'>
                        <form>
                        <div style={{ display: 'flex', flexDirection: 'column', }}>
                        <div style={{ position: 'relative', margin:'10px 0' }}>
                            <input
                            value={formData.name}
                            onChange={handleChange}
                            className='text-[#000000] my-2'
                            type="text"
                            name="name"
                            placeholder="Your name"
                            />
                            <label className='positioned-label'>
                            Name <span className='text-[#B20312]'>*</span>
                            </label>
                        </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', }}>
                        <div style={{ position: 'relative', margin:'10px 0' }}>
                            <input
                            value={formData.email}
                            onChange={handleChange}
                            className='text-[#000000] my-2'
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            />
                            <label className='positioned-label'>
                            Email <span className='text-[#B20312]'>*</span>
                            </label>
                        </div>
                        </div>                           
                        <div style={{ display: 'flex', flexDirection: 'row', width:'100%', margin:'0 0 10px 0'}}>
                        <div style={{ display: 'flex', flexDirection: 'column', width:'50%', justifyContent:'center'}}>
                        <div style={{ position: 'relative', margin:'10px 10px 0 0' }}>
                            <input
                            value={formData.role}
                            onChange={handleChange}
                            className='text-[#000000] my-2'
                            type="text"
                            name="role"
                            placeholder="Your Role"
                            />
                            <label className='positioned-label'>
                            Role <span className='text-[#B20312]'>*</span>
                            </label>
                        </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width:'50%', justifyContent:'center'}}>
                        <div style={{ position: 'relative', margin:'10px 0 0 10px' }}>
                            <input
                            value={formData.company}
                            onChange={handleChange}
                            className='text-[#000000] my-2'
                            type="text"
                            name="company"
                            placeholder="Company name"
                            />
                            <label className='positioned-label'>
                            Company name <span className='text-[#B20312]'>*</span>
                            </label>
                        </div>
                        </div>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ position: 'relative', margin:'10px 0' }}>
                            <textarea
                            value={formData.message}
                            onChange={handleChange}
                            className='text-[#000000] my-2'
                            rows={5}
                            cols={20}
                            style={{
                                paddingTop: '20px',
                                border: '1px solid rgba(102, 106, 227, 0.2)',
                                fontSize: '14px',
                                fontFamily: 'Rubik',
                                borderRadius: '8px',
                                width: '100%', // Make textarea full-width
                                backgroundColor: 'transparent' // Set textarea background as transparent
                            }}
                            type="text"
                            name="Message"
                            placeholder="Message.."
                            ></textarea>
                            <label className='positioned-label'>
                            Message <span className='text-[#B20312]'>*</span>
                            </label>
                        </div>
                        </div>        
                        </form>
                    </div>
                    <div className='contact-button'>Let's Talk</div>
                </div>
                <div className='contact-img' style={{flex:'49%'}}>
                    <ContactImg/>
                </div>
            </div>
        </div>
    )
}