/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import Dropdown from "react-dropdown";
import React, { useEffect, useRef, useState } from 'react';
import 'react-dropdown/style.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useOptionsContext } from '../../../OptionsContext'; // Import your options context and action types
import { useActiveIdContext } from "../../../ActiveIdContext";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDarkMode } from '../../../DarkModeContext';

import { DeleteNameIcon } from '../Viewport/EditorSvg';
import { SearchIcon } from "../Viewport/Pop-up/svgArray";
import Search from "@mui/icons-material/Search";


export const ToolbarDropdown = ({ isEvents, placeholder, title, value, onChange, children, sm, nodes }) => {

  // const options = useSelector((state) => state.options.options); 

  const { state } = useOptionsContext();
  const { options } = state;

  const { state: statee, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = statee;

  // const [newOptionName, setNewOptionName] = useState('');

  // const addOption = () => {
  //   const newOption = {
  //     _id: Date.now(), // You can use a unique ID generation method
  //     name: newOptionName,
  //     children: [],
  //   };
  //   dispatch({ type: ADD_OPTION, payload: newOption });
  //   setNewOptionName('');
  // };

  // const changeOptionName = (id, newName) => {
  //   dispatch({ type: CHANGE_OPTION, payload: { _id: id, name: newName } });
  // };
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(value);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const { isDarkMode } = useDarkMode()
  const [insidePage, setInsidePage] = useState(false)



  const filterOptions = (items, searchTerm) => {
    if (searchTerm) {
      return items.reduce((acc, item) => {
        const nameMatch =
          item.name?.toLowerCase().includes(searchTerm.toLowerCase()) &&
          item.name?.toLowerCase() !== activeId?.name?.toLowerCase();

        const childrenMatch =
          item.children &&
          item.children.length > 0 &&
          filterOptions(item.children, searchTerm);

        if (nameMatch) {
          acc.push(item);
        }

        if (childrenMatch?.length > 0) {
          acc.push(...childrenMatch);
        }
        return acc;
      }, [])
    } else {
      return items
    }
  };

  const handleSearch = (inputValue) => {


    setSearchTerm(inputValue);


    const filtered = isEvents ? filterOptions(isEvents, inputValue) : filterOptions(options, inputValue);

    setFilteredOptions(filtered);
    // setIsOpen(true)
  };

  useEffect(() => {
    if (options?.length) {
      const filtered = isEvents || filterOptions(options, activeId?.name);
      setFilteredOptions(filtered);
    }
  }, [options])

  const handleSelect = (selectedValue) => {
    setSelectedItem(selectedValue);
    onChange(selectedValue);
    console.log(selectedValue)
    handleSearch("")
    setIsOpen(false)
  };

  const handleReset = () => {
    setSelectedItem(null);
    onChange("");
    handleSearch("")
    // setIsOpen(false);
  };


  return (
    <div>
      {selectedItem ? <div style={{ display: "flex", justifyContent: "center", width: "100%"}}>
        <div style={{ display: "flex", border: "0.25Vmin solid #e9e9e9", borderRadius: "1.5vmin", width: "100%", justifyContent: "space-between", alignItems:'center', padding: "0 1vmin" }}>
          <div className="flex items-center flex-1" style={{ alignItems: "center", color: "black", fontSize: "1.5Vmin", width: '80%' }}>{selectedItem?.name || selectedItem}</div>
          <div
            className='flex-1 justify-end items-center flex transition-transform duration-300 transform-gpu hover:scale-125 py-[.5vmin]'
            style={{ maxWidth: '20%' }}
            onClick={(e) => {
              e.stopPropagation()
              // setOpen(false)
              handleReset()
              onChange("")

            }}
          >
            {isDarkMode ? (
              <DeleteNameIcon width='1.7vmin' height='1.7vmin' fill="black" />
            ) : <DeleteNameIcon width='1.7vmin' height='1.7vmin' fill='#161717' />}
          </div>
        </div>
      </div> : null}

      {!selectedItem ? (
        <>
          <div
            style={{ width: sm ? "50%" : "100%" }}
            className={`shadow-sm h-full mx-auto rounded-[2.5Vmin]  flex flex-row justify-between w-[100%] flex justify-center text-[#989191] ${isFocused
                ? "border-[0.25vmin] border-[#0867FC] text-[#0867FC]"
                : isDarkMode
                  ? "border-[0.25Vmin] border-[#E9E9EA30]"
                  : "border-[0.25Vmin] border-[#E9E9EA]"
              }`}
            tabIndex={0}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          >
            <div
              className="h-full w-full flex flex-column items-center py-[.5vmin] px-[1vmin]"
              style={{ background: "#e9e9e930", borderRadius: "2.4vmin" }}
            >
              <div className="flex w-full flex-row my-[.25vmin]">
                <div className="flex align-items-center justify-center mr-[.6vmin]">
                  <Search sx={{ color: isFocused ? "#0867FC" : "#989191" }} />
                </div>
                <input
                  onFocus={() => setIsOpen(true)}
                  // onBlur={() => setIsOpen(false)}
                  placeholder={placeholder || "Move to Page/Link"}
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  className={`text-[#989191] ${isFocused ? "text-[#0867FC]" : ""} `}
                  style={{ fontSize: "2Vmin", fontFamily: "Inter", fontWeight: "400" }}
                />
              </div>
              {isOpen ? (
                <>
                  <div
                    style={{
                      background: "#e9e9e980",
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 1px 2px",
                      overflow: 'hidden'
                    }}
                    className="my-[1vmin] w-[90%] mx-auto justify-center flex-column flex-wrap flex border-[0.25vmin] border-[#E9E9EA] rounded-[2Vmin]"
                  >
                    {searchTerm && !isEvents ? (
                      <div
                        style={{ fontSize: "1.5Vmin", borderRadius: "1.8vmin 1.8vmin 0 0" }}
                        className="w-[100%] h-[2vh] text-[#161717] bg-[transparent] hover:bg-[#0867FC1A] cursor-pointer flex"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleSelect({ name: "www." + searchTerm, _id: "www." + searchTerm })
                        }}
                      >
                        <div className="flex items-center px-[1Vmin]">www.{searchTerm}</div>
                      </div>
                    ) : null}
                    {filteredOptions.length > 0 ? (
                      filteredOptions.map((x) => (
                        <div
                          key={x.name}
                          className="w-[100%] text-[#161717] bg-[transparent] hover:bg-[#0867FC1A]  cursor-pointer  flex"
                          onClick={(e) => {
                            e.stopPropagation()
                            handleSelect(x)
                          }}
                        >
                          <div className="flex items-center text-[1.7vmin] py-[.5vmin] px-[1Vmin]">{x.name}</div>
                        </div>
                      ))
                    ) : (
                      <div style={{ fontSize: "1.7Vmin" }} className="px-[1Vmin] py-[.5Vmin] text-gray-500">
                        No results found
                      </div>
                    )}

                  </div>
                  {!sm && <div style={{ display: 'block', cursor: 'pointer', color: insidePage ? '#0867FC' : isDarkMode ? '#fff' : '#989191' }} onClick={() => setInsidePage(!insidePage)}>Within Page?</div>}
                  {insidePage && (

                      <div className="w-full mx-auto h-auto mt-[1vmin] p-[1vmin] shadow-2xl" style={{ borderRadius: '1.5vmin' }}>
                        {nodes && typeof nodes === 'object' && Object.keys(nodes).length > 0 ? (
                          Object.keys(nodes).filter((x) => nodes[x].dom !== null).map((nodeId) => {
                            const node = nodes[nodeId];

                            if (!node || !node.data || !node.id || node.dom === null) {
                              return null;
                            }

                            if (node.data.name === 'Parent' && node.data.props.row !== true && !node.data.props.none && node?.data?.nodes?.length > 0) {
                              return (
                                <div key={nodeId}>
                                  {/* Render parent node */}
                                  <div
                                    style={{ fontSize: "1.5vmin", display: 'flex', flexDirection: 'column', height: 'max-content' }}
                                    className="w-[100%]  text-[#161717]  rounded-[1.5vmin] cursor-pointer flex"

                                  >
                                    <div
                                      onClick={(e) => {
                                        console.log(node)
                                        e.stopPropagation();
                                        handleSelect({ _id: node?.id, name: 'dom' });
                                      }}
                                      className="w-full text-center text-[2vmin] p-[1vmin] my-[1vmin] text-[#161717] bg-[#e9e9e9] rounded-[1.5vmin] cursor-pointer">{node?.data?.name}</div>
                                  </div>

                                </div>
                              );
                            } else {
                              return null;
                            }
                          })
                        ) : (
                          <div style={{ fontSize: "2vmin" }} className="px-[1vmin] py-[1vmin] text-gray-500">
                            No results found
                          </div>
                        )}
                      </div>
                    )
                  }
                </>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};
