import '@progress/kendo-theme-default/dist/all.css';
import './Stepper.css';
import Tuturial from './Tuturial';
import { useCallback, useState } from 'react';
import { DescDevice, EyeIcon, MopileDevice, TabDevice } from '../components/editor/Viewport/EditorSvg';
import { useDarkMode } from '../DarkModeContext';
import Settings from './SettingsArea/Index';
import { AppSetup } from './ViewArea/OriginalView';
import { useSidesContext } from '../SideContext';

export const Stepper = ({ setTuturial }) => {
  const { 
    walksFor,
    appSettings,
    selectedFile,
    width,
    handleWidth,
    show,
    review, setReview
     } = useSidesContext();

     const enable = () => {
      setReview(prevReview => !prevReview);
    };
     
    const {isDarkMode} = useDarkMode()


  return (

    <div className="w-full h-full flex flex-col relative">
      {!review && <Tuturial setTuturial={setTuturial} setReview={setReview}/>}

      <div className={`flex justify-between items-center ${!review? 'm-[1vmin] pt-[6vmin] pb-[4vmin] px-[4vmin] rounded-[1vmin]' : ''} h-[100%]`}>

        <div className='relative mx-auto' style={{maxHeight: !review ? !show? '62vmin' : '70vmin' : '', width: review? '100%' : width, display: 'flex', flexDirection: 'column', height: '100%'}}>

          {walksFor.main.value !== null && (
            <div className='w-full h-full' >
            
             {!review && walksFor.mainTheme.value >= '0' && appSettings.appTitle.value !== '' &&
             <div className='absolute top-[-4.3vmin] left-[0] flex justify-start w-full h-[fit-content]'
             style={{ borderRadius: '2.5vmin 2.5vmin 0 0', marginLeft: '3vmin', minWidth: '20vmin',
             width: 'fit-content', padding: '.5vmin 1vmin', border: `.25vmin solid #0867FC60` }}
             >
              {walksFor.mainTheme.value >= '1' && (appSettings.siteLogo.value !== null || selectedFile !== null) &&
                <div className='flex justify-center items-center mx-[1vmin]'>
                  
                  {selectedFile !== null && appSettings.siteLogo.value === null ? 

                   (<img
                          src={URL.createObjectURL(selectedFile)}
                          alt="Preview"
                          style={{ width: '2.2vmin', height: '2.2vmin', position:'relative', borderRadius:'.5vmin', objectFit:'cover'}}
                    />)

                    : selectedFile === null &&  appSettings.siteLogo.value !== null ? 
                    
                    (<svg aria-hidden="true" focusable="false" role="img"
                    viewBox={appSettings?.siteLogo?.value?.viewBox} width={'2.2vmin'} height={'2.2vmin'} fill={appSettings?.siteLogo?.value?.fill || '#0867FC'}>
                    <path d={appSettings?.siteLogo?.value?.path}
                    />
                    </svg>)
                    
                    : null}

                  

                </div>}
                <div className='text-[2vmin] flex items-center' style={{ color: '#0867FC60' }}>{appSettings.appTitle.value}</div>
              </div>}

              <AppSetup setReview={setReview} enable={enable} review={review} />
            </div>
          )}
        </div>


        <div style={{display: review && 'none' , width: '25%', height: '100%', position: 'relative', fontFamily:'Rubik, sans-serif' }}>
          <div className='text-[2vmin] font-["Roboto"] text-center bg-[#0867FC] font-bold text-[#FFF] py-[1vmin] mb-[2vmin] border-b rounded-[2.5vmin]'>
            {
               walksFor?.main?.value < 1 ? (
                walksFor?.mainTheme?.value === 0 ? ' App Title' :
                walksFor?.mainTheme?.value === 1 ? " Site's Logo" :
                walksFor?.mainTheme?.value === 2 ? ' Main Theme' :
                walksFor?.mainTheme?.value === 3 ? ' Background' : null
              ) : walksFor?.main?.value < 2 && walksFor?.main?.value >= 1 ? (
                walksFor?.mainApp?.value === 0 ? ' Top Area' :
                walksFor?.mainApp?.value === 1 ? ' Left Side' :
                walksFor?.mainApp?.value === 2 ? ' Bottom Area' :
                walksFor?.mainApp?.value === 3 ? ' Right Side' :
                walksFor?.mainApp?.value === 4 ? ' Pages Count' :
                null
              ) : (
                'Final Step'
              )
            }
            </div>
          <div style={{ height: '100%' }} className='px-[3vmin] w-full rounded-[1.5vmin]'>
            <Settings setTuturial={setTuturial} />
          </div>
        </div>
          
        {!review && <div className='absolute top-0 bottom-0 left-0 flex flex-column justify-center'>

              <div onClick={() => handleWidth('70%')}
                style={{ cursor: "pointer", margin: '.5vmin 0', width: "5.2Vmin", height: "5Vmin", borderRadius: "1Vmin" }}
                className={`${isDarkMode ? `${width == "70%" ? "bg-[#0867fc30]" : "bg-[#e9e9ea30]"}` : `${width == "70%" ? "bg-[#0867FC]" : "bg-[#e9e9ea]"}`} flex flex-col justify-center shrink-0  items-center `}>
                <div style={{ width: "100%", height: "100%" }} className='flex justify-center items-center'>{isDarkMode ? width == "70%" ? <DescDevice fill="#0867FC" /> : <DescDevice fill="#FFF" /> : width == "70%" ? <DescDevice fill='#FFF' /> : <DescDevice fill='#989191' />}</div>
              </div>
              <div onClick={() => handleWidth('800px')}
                style={{ cursor: "pointer", margin: '.5vmin 0', width: "5.2Vmin", height: "5Vmin", borderRadius: "1Vmin" }}
                className={`${isDarkMode ? `${width == '800px' ? "bg-[#0867fc30]" : "bg-[#e9e9ea30]"}` : `${width == '800px' ? "bg-[#0867FC]" : "bg-[#e9e9ea]"}`} flex flex-col justify-center w-["10%"] shrink-0 h-["10%"] items-center `}>
                <div className='mx-auto my-auto'>{isDarkMode ? width == '800px' ? <TabDevice fill="#0867FC" /> : <TabDevice fill="#FFF" /> : width == '800px' ? <TabDevice fill='#FFF' /> : <TabDevice fill='#989191' />}</div>

              </div>
              <div onClick={() => handleWidth('500px')}
                style={{ cursor: "pointer", margin: '.5vmin 0', width: "5.2Vmin", height: "5Vmin", borderRadius: "1Vmin" }}
                className={`${isDarkMode ? `${width == '500px' ? "bg-[#0867fc30]" : "bg-[#e9e9ea30]"}` : `${width == '500px' ? "bg-[#0867FC]" : "bg-[#e9e9ea]"}`} flex flex-col justify-center w-["10%"] shrink-0 h-["10%"] items-center `}>
                <div className='mx-auto my-auto'>{isDarkMode ? width == '500px' ? <MopileDevice fill='none' stroke="#0867FC" /> : <MopileDevice fill='none' stroke="#FFF" /> : width == '500px' ? <MopileDevice fill='none' stroke='#FFF' /> : <MopileDevice fill='none' stroke='#989191' />}</div>

              </div>

              {walksFor.main.value >= 1 && 
              <div title='View' onClick={enable}
              style={{ cursor: "pointer", margin: '.5vmin 0', width: "5.2Vmin", height: "5Vmin", borderRadius: "1Vmin" }}
              className={`${isDarkMode ? `${review ? "bg-[#0867fc30]" : "bg-[#e9e9ea30]"}` : `${review ? "bg-[#0867FC]" : "bg-[#e9e9ea]"}`} flex flex-col justify-center w-["10%"] shrink-0 h-["10%"] items-center `}>
                <EyeIcon/>
            </div>
              }

          </div>}
          </div>
            {/* <SpecificArea/> */}

    </div>
  );
}
