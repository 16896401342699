/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import * as React from "react";
import * as ReactDOM from "react-dom";
import { TaskBoard, TaskBoardToolbar } from '@progress/kendo-react-taskboard';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { filterBy } from '@progress/kendo-data-query';
import { cards } from './cards';
const tasks = cards.map(c => ({
  id: c.id,
  title: c.title,
  description: c.description,
  status: c.status,
  priority: c.priority
}));
const columns = [{
  id: 1,
  title: 'To-Do',
  status: 'todo'
}, {
  id: 2,
  title: 'In Progress',
  status: 'inProgress'
}, {
  id: 3,
  title: 'Done',
  status: 'done'
}];
const priorities = [{
  priority: 'Low Priority',
  color: 'green'
}, {
  priority: 'High Priority',
  color: 'blue'
}, {
  priority: 'Urgent',
  color: 'orange'
}];
export const Tasks_Widget = () => {
  const [filter, setFilter] = React.useState('');
  const [taskData, setTaskData] = React.useState(tasks);
  const [columnsData, setColumnsData] = React.useState(columns);
  const onSearchChange = React.useCallback(event => {
    setFilter(event.value);
  }, []);
  const resultTasks = React.useMemo(() => {
    const filterDesc = {
      logic: 'and',
      filters: [{
        field: 'title',
        operator: 'contains',
        value: filter
      }]
    };
    return filter ? filterBy(taskData, filterDesc) : taskData;
  }, [filter, taskData]);
  const onChangeHandler = React.useCallback(event => {
    if (event.type === 'column') {
      setColumnsData(event.data);
    } else {
      // New Task has been added.
      if (event.item && event.item.id === undefined) {
        event.item.id = event.data.length + 1;
      }
      setTaskData(event.data);
    }
  }, []);
  const onAddColumn = () => {
    const newColumn = {
      id: columnsData.length + 1,
      title: 'New Column',
      status: 'new',
      edit: true
    };
    setColumnsData([...columnsData, newColumn]);
  };
  return <TaskBoard columnData={columnsData} taskData={resultTasks} priorities={priorities} onChange={onChangeHandler} style={{
    height: '700px',
    width:"100%"
  }} tabIndex={0}>
        <TaskBoardToolbar>
          <Button icon="add" onClick={onAddColumn}>Add Column</Button>
          <span className="k-spacer" />
          <Input placeholder="Search..." onChange={onSearchChange} value={filter} />
        </TaskBoardToolbar>
      </TaskBoard>;
};
