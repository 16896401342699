/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useEditor, useNode } from '@craftjs/core';
import React, { useEffect, useState } from 'react';
import { PluginSettings } from './PluginSettings'
import { useActiveIdContext } from '../../../ActiveIdContext';
import { Container } from '../../Container';
import { useOptionsContext } from "../../../OptionsContext"; 
import io from "socket.io-client"
export const Plugin = (props) => {
  const {
    actions: { setProp },
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const [tempoSrc, setTempoSrc] = useState("")
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { state, dispatch: setActiveId } = useActiveIdContext();
  const { activeId: activeId } = state;

  const { 
    background,
    color,
    margin,
    href,
    src,
    controls,
    autoplay,
    loop,
    muted,
    height,
    width,
    children,
    ...otherProps
  } = props;
  const { state: idState, dispatch: idDispatch } = useOptionsContext();
  const { options: treeData } = idState;

  const [socket, setSocket] = useState(null)
  const sendProp = () => { 
console.log(socket, "from builder")
  alert("hello")
   socket?.emit("new prop", {socket:"mohammedaladdin3laddin1092-plugin", key:"color", value:"red"})
    } 


    useEffect(() => {

      let socket = io("http://localhost:4000", {
        transports: ['polling'],
        })?.emit("setup", "mohammedaladdin3laddin1092-builder")

        console.log(socket, "from effect")
        setSocket(socket)
   
  },[])

return (
<div     
onClick={() => sendProp()}
ref={connect}
 style={{ backgroundColor:"red", height:"500px", background:"white", display:"flex",justifyContent:"center", alignItems:"center", width:"100%",}}>
  {children ? children : (
src ? <iframe width={`98%`} height={`${height}px`} allowfullscreen="allowfullscreen" id="i7fk" src={src}></iframe>
: ( <input
 placeholder='Please write your plugin url then press Enter'
 className='w-full my-[3vmin] py-[1.5%] px-[2%] flex justify-center items-center my-auto'
 style={{ margin: '3% 0 0 0', width: '50%', border:"1px solid lightgray", borderRadius: '2Vmin', background: 'transparent', color: '#161717', fontSize: '1.9Vmin', fontWeight: '400', fontFamily: 'Inter' }}
 value={tempoSrc}
 onChange={(e) => setTempoSrc(e.target.value)}
 onBlur={() => {
 setProp((prop) => {
   prop[src] = tempoSrc;
 }, 500);
 }}
/>)
  )}
</div>
  );
};

Plugin.craft = {
  displayName: 'pluginWidget',
  props: {
    background: { r: 255, g: 255, b: 255, a: 0.5 },
    color: { r: 92, g: 90, b: 90, a: 1 },
    PluginCStyle: 'full',
    text: 'Plugin',
    height:"100%",
    width:"100%",
    href: null,
    src: "https://www.mantine-react-table.com/",
    controls: true,
    autoplay: false,
    loop: false,
    muted: false,
    isPlugin: true,
    socket:null
  },
  related: {
    toolbar: PluginSettings,
  },
};
