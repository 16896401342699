/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Button } from '../Button';


import { Element, useNode } from '@craftjs/core';
import React from 'react';
import Text from '../Text';
import { Parent } from '../Parent';
import { Container } from  '../../../Container/index.js';
import { Column3 } from '../Column/Column3';
export const Layout1 = props => {
  const {
    connectors: {
      connect
    }
  } = useNode(node => ({
    selected: node.events.selected
  }));
  return <div style={{display:"flex", flexDirection:"column", width:"100%"}} ref={connect}>
    <div style={{width:"100%", display:"flex"}}>
    <Element
    id="Container11111i-1110109019"
    canvas
    is={Column3}    
    >
    </Element>
    </div>
      </div>;
};
Layout1.craft = {
  displayName: "Layout 3x3x3"
};