import { useState } from "react";
import { useOptionsContext } from "../../OptionsContext";
import { EditNameIcon } from "../../components/editor/Viewport/EditorSvg";
import AreaEl from "../AreaEl";
import { useSidesContext } from "../../SideContext";

const Footer = ({
    checkFooter,
    styleWrIcon,
    styleRMIcon,
    review,
    handleClick,
    selectedItem,
    open, setOpen,
    closePanel, setClosePanel,
}) => {

    const { 
        walksFor, 
        appSettings, 
        pageSettings, 
        BottomProps,
        styles,
        setStyles,
        } = useSidesContext();

    const color = appSettings?.color?.value
    const h1Styles = appSettings?.fonts?.h1
    const h2Styles = appSettings?.fonts?.h2
    const h3Styles = appSettings?.fonts?.h3
    const h4Styles = appSettings?.fonts?.h4

    const {
        bottomContents,
        bottomRows,
        theBottomOptionRow2,
        theBottomOptionRow3,
        theBottomOptionRow,
        setNavbarProps,
        footerProps,
        setFooterProps,
    } = BottomProps

    const { state: idState, dispatch: idDispatch } = useOptionsContext();
    const { options: treeData } = idState;


    const handleStyleChange = (id, updatedStyle) => {
        setStyles(prevStyles => ({
            ...prevStyles,
            [id]: updatedStyle,
        }));
    };

    const SmallWidgets = ({ check }) => {
        const handleReset = (name, e) => {
            e.stopPropagation();
           
            if(check==='footer') {
                setFooterProps(prev =>
                    prev.map(widget =>
                        widget.name.toLowerCase() === name.toLowerCase() ?
                            { ...widget, selected: false } :
                            widget
                    )
                );
            } else {
                setNavbarProps(prev =>
                    prev.map(widget =>
                        widget.name.toLowerCase() === name.toLowerCase() ?
                            { ...widget, selected: false } :
                            widget
                    )
                );
            }
            
        };


        const widgetComponents = (widget) => {
            return (
                <svg aria-hidden="true" focusable="false" role="img" viewBox={widget?.icon?.viewBox} width="4vmin" height="4vmin" fill={color || widget?.icon?.fill}>
                    <path d={widget?.icon?.path} />
                </svg>
            );
        };

        const renderIcon = (widget) => widgetComponents(widget);

        const widgets = footerProps.filter(widget => widget.selected)


        return (
            <>
                <Msg check={check} />
                <div style={{...h3Styles, display: 'flex', width: '100%', justifyContent: 'center', flexWrap:'wrap' }}>
                    {widgets.map((widget, index) => (
                        <div style={{ display: 'flex', justifyContent: 'center', width: 'fit-content', margin: '0 2px' }} className={styleWrIcon} key={widget.name}>
                            {!review && <div
                                onClick={(e) => handleReset(widget.name, e)}
                                className={styleRMIcon(check)}
                            >
                                ❌
                            </div>}
                            <a href={review && widget?.url} target='_blank'>
                            <div style={{ display: widget.widgetdisplay !== 'icon' && widget.widgetdisplay !== 'both' && 'none' }}>
                                {renderIcon(widget)}
                            </div>
                            </a>
                            <div style={{ margin: '0 5px', display: widget.widgetdisplay !== 'name' && widget.widgetdisplay !== 'both' && 'none' }} className="mx-[.5vmin]">{widget.name.replace('Vis', '')}</div>
                        </div>
                    ))}
                </div>
            </>
        );
    }

    

    const Msg = (check) => {
        const isSelected = footerProps.every(widget => widget.selected !== true)

        if (isSelected) {
            return <div className='min-w-[100%] text-center'>
                <p>You must choose even one widget to appear here!</p>
            </div>
        }
        return null;
    };

    if (checkFooter) {
        return (
            <>
                {bottomRows === '3' && theBottomOptionRow3 !== '' && <AreaEl review={review} open={open}
                    defaultStyle={styles.bottom3}
                    onStyleChange={(newStyle) => handleStyleChange('bottom3', newStyle)}
                    onClick={() => {
                        setClosePanel(true)
                        if (!review) {
                            setOpen('bottom3')
                        }
                        
                    }} setClosePanel={setClosePanel} closePanel={closePanel} setOpen={setOpen} id='bottom3'
                    style={{ ...styles.bottom3, position: 'relative', borderTop: `.25vmin solid ${color + '10'}`, }}
                    className='shadow-sm  p-[1vmin] text-center w-full mx-auto'
                >
                    {theBottomOptionRow3 === 'Small widgets' ? (
                        <div 
                            style={{ alignContent: 'center', height: 'auto', alignItems: 'center', zIndex: 9999, width:'100%'}} className='flex justify-between items-center'>
                            <SmallWidgets check="footer" />
                        </div>
                    ) : theBottomOptionRow3 === 'Navigation Pages' ? (
                        <div className="sitemap-container">
                            {treeData.map((node, id) => (
                                <div className="flex flex-column h-full w-[fit-content] min-w-[5vmin] max-w-[15vmin]">
                                <div style={{...h3Styles, color: selectedItem === node._id && color, borderBottom: selectedItem === node._id && `.25vmin solid ${color}` }} className={` sitemap-item`} onClick={() => handleClick(node._id)} key={id}>
                                    {node.name.length > 11 ? node.name.slice(0, 10) + "..." : node.name} 
                                </div>
                                {node?.children !== 0 && <div>
                                {node?.children?.map(child => {
                                    return (
                                        <div key={child._id} onClick={(e) => {
                                            e.stopPropagation()
                                            handleClick(child._id)
                                            }} 
                                            className={`sitemap-item text-center my-[1vmin]`}>
                                            <div style={{...h3Styles, color: selectedItem === child._id && color, borderBottom: selectedItem === child._id && `.25vmin solid ${color}` }}>
                                                {child.name.length > 11 ? child.name.slice(0, 10) + "..." : child.name} 
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div
                            style={{...h3Styles, alignContent: 'center', height: 'auto', alignItems: 'center', zIndex: 9999 }} className='flex justify-center items-center'>
                            <div className='text-center'>{bottomContents.content3.text || 'Content With a link'}</div>
                            {bottomContents.content3.href !== '' && <div style={{ zIndex: 9999 }}>
                                <a style={{textDecoration:'none', color: color}} target="_blank" href={bottomContents.content3.href}>
                                    <div className="w-[17vmin] h-[6vmin] flex items-center justify-center rounded-[5vmin] ml-[2vmin]" style={{border:`.25vmin solid ${color}`, background:'transparent'}}>
                                    Click
                                    </div>
                                </a>
                            </div>}
                        </div>
                    )}
                </AreaEl>}

                {bottomRows > '1' && theBottomOptionRow2 !== '' && <AreaEl review={review} open={open}
                    defaultStyle={styles.bottom2}
                    onStyleChange={(newStyle) => handleStyleChange('bottom2', newStyle)}
                    onClick={() => {
                        setClosePanel(true)
                        if (!review) {
                            setOpen('bottom2')
                        }                        
                    }} setClosePanel={setClosePanel} closePanel={closePanel} setOpen={setOpen} id='bottom2' style={{ ...styles.bottom2, position: 'relative', borderTop: `.25vmin solid ${color + '10'}`, width: '100%', }}
                    className='shadow-sm  p-[1vmin] text-center w-full mx-auto'
                >
                    {theBottomOptionRow2 === 'Small widgets' ? (
                        <div 
                            style={{ alignContent: 'center', height: 'auto', alignItems: 'center', zIndex: 9999,  }} className='flex justify-between items-center'>
                            <SmallWidgets check="footer" />
                        </div>
                    ) : theBottomOptionRow2 === 'Navigation Pages' ? (
                        <div className="sitemap-container">
                            {treeData.map((node, id) => (
                                <div className="flex flex-column h-full w-[fit-content] min-w-[5vmin] max-w-[15vmin]">
                                <div style={{...h3Styles, color: selectedItem === node._id && color, borderBottom: selectedItem === node._id && `.25vmin solid ${color}` }} className={`sitemap-item`} onClick={() => handleClick(node._id)} key={id}>
                                    {node.name.length > 11 ? node.name.slice(0, 10) + "..." : node.name} 
                                </div>
                                {node?.children !== 0 && <div>
                                {node?.children?.map(child => {
                                    return (
                                        <div key={child._id} onClick={(e) => {
                                            e.stopPropagation()
                                            handleClick(child._id)
                                            }} 
                                            className={`sitemap-item text-center my-[1vmin]`}>
                                            <div style={{...h3Styles, color: selectedItem === child._id && color, borderBottom: selectedItem === child._id && `.25vmin solid ${color}` }}>
                                                {child.name.length > 11 ? child.name.slice(0, 10) + "..." : child.name} 
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div
                            style={{...h3Styles, alignContent: 'center', height: 'auto', alignItems: 'center', zIndex: 9999 }} className='flex justify-center items-center'>
                            <div className='text-center'>{bottomContents.content2.text || 'Content With a link'}</div>
                            {bottomContents.content2.href !== '' && <div style={{ zIndex: 9999 }}>
                                <a style={{textDecoration:'none', color: color}} target="_blank" href={bottomContents.content2.href}>
                                    <div className="w-[17vmin] h-[6vmin] flex items-center justify-center rounded-[5vmin] ml-[2vmin]" style={{border:`.25vmin solid ${color}`, background:'transparent'}}>
                                    Click
                                    </div>
                                </a>
                            </div>}
                        </div>
                    )}
                </AreaEl>}

                {theBottomOptionRow !== '' && <AreaEl review={review} open={open}
                    defaultStyle={styles.bottom1}
                    onStyleChange={(newStyle) => handleStyleChange('bottom1', newStyle)}
                    onClick={() => {
                        setClosePanel(true)
                        if (!review) {
                            setOpen('bottom1')
                        }
                    }} setClosePanel={setClosePanel} closePanel={closePanel} setOpen={setOpen} id='bottom1' style={{ ...styles.bottom1, position: 'relative', borderTop: `.25vmin solid ${color + '10'}`, }}
                    className='shadow-sm  p-[1vmin] text-center w-full mx-auto' >

                    {theBottomOptionRow === 'Small widgets' ? (
                        <div
                            style={{ alignContent: 'center', height: 'auto', alignItems: 'center', zIndex: 9999,  }} className='flex justify-between items-center'>
                            <SmallWidgets check="footer" />
                        </div>
                    ) : theBottomOptionRow === 'Navigation Pages' ? (
                        <div className="sitemap-container">
                            {treeData.map((node, id) => (
                                <div className="flex flex-column h-full w-[fit-content] min-w-[5vmin] max-w-[15vmin]">
                                <div style={{...h3Styles, color: selectedItem === node._id && color, borderBottom: selectedItem === node._id && `.25vmin solid ${color}` }} className={`sitemap-item`} onClick={() => handleClick(node._id)} key={id}>
                                    {node.name.length > 11 ? node.name.slice(0, 10) + "..." : node.name} 
                                </div>
                                {node?.children !== 0 && <div>
                                {node?.children?.map(child => {
                                    return (
                                        <div key={child._id} onClick={(e) => {
                                            e.stopPropagation()
                                            handleClick(child._id)
                                            }} 
                                            className={`sitemap-item text-center my-[1vmin]`}>
                                            <div style={{...h3Styles, color: selectedItem === child._id && color, borderBottom: selectedItem === child._id && `.25vmin solid ${color}` }}>
                                                {child.name.length > 11 ? child.name.slice(0, 10) + "..." : child.name} 
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div
                            style={{...h3Styles, alignContent: 'center', height: 'auto', alignItems: 'center', zIndex: 9999 }} className='flex justify-center items-center'>
                            <div className='text-center'>{bottomContents.content1.text || 'Content With a link'}</div>
                            {bottomContents.content1.href !== '' && <div style={{ zIndex: 9999 }}>
                                <a style={{textDecoration:'none', color: color}} target="_blank" href={bottomContents.content1.href}>
                                    <div className="w-[17vmin] h-[6vmin] flex items-center justify-center rounded-[5vmin] ml-[2vmin]" style={{border:`.25vmin solid ${color}`, background:'transparent'}}>
                                    Click
                                    </div>
                                </a>
                            </div>}
                        </div>
                    )}
                </AreaEl>}

                {pageSettings?.copyright?.visibility &&
                <AreaEl review={review} open={open}
                    defaultStyle={styles.proud}
                    onStyleChange={(newStyle) => handleStyleChange('proud', newStyle)}
                    onClick={() => {
                        setClosePanel(true)
                        if (!review) {
                            setOpen('proud')
                        }
                        
                    }}
                    setClosePanel={setClosePanel} closePanel={closePanel} setOpen={setOpen} id='proud'
                    style={{...h4Styles, position: 'relative', borderTop: pageSettings?.copyright?.visibility && `.25vmin solid ${color + '10'}`}}
                    className=' w-full py-[1vmin] text-center text-[#989191] flex items-center justify-center'>
                    {pageSettings?.copyright?.content || '© 2024 AllYouCanCloud.com'}
                    <div
                        style={{ zIndex: 1, display: !review ? walksFor.mainApp.value !== 2 && 'none' : 'none' }}
                        className='absolute top-0 right-[-3vmin] cursor-pointer'
                    >
                        <EditNameIcon />

                    </div>
                </AreaEl>}
            </>
        )
    }
    return null
}

export default Footer;