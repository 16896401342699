/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useRef, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { NewStatisticsSettings } from './newStatisticsSetting';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import Text from '../../basic/Text';
import './Sta.css'
import { BounceLoader } from 'react-spinners';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';

export const Statistics_2 = ({ selected }) => {
    const { 
      actions: { setProp },
        connectors: {
          connect
        } 
    } = useNode();
  
    
    const {
      enabled,
    } = useEditor((state, query) => ({
      enabled: state.options.enabled,
   
    }));

    const styles = [
      <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full py-20 border'>
        <Wrapper canvas id='random-id-401' is='container' className='container'>
          <Wrapper canvas id='random-id-402' is='div' className='w-[100%] h-full'>
          <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Poppins'}} className={`w-full h-full text-center mb-10 text-[46px]`} id='random-id-1' is='div'>
            <Text text='Our work in numbers'/>
          </Wrapper>
          <Wrapper canvas id='random-id-403' is='wrapper' className='w-[100%] flex flex-row flex-wrap py-10' style={{background:'#F7F7F9', borderRadius:'30px'}}>
            <Wrapper canvas id='random-id-404' is='div' className='w-[50%] flex flex-row mx-auto flex-wrap'>
            <Wrapper canvas id='random-id-405' is='div' className='w-[50%] flex flex-col min-w-[140px] my-2 mx-auto'>
            <Wrapper canvas id='random-id-406' is='div' className="pieContainer10 mx-auto my-auto">
            <Wrapper canvas id='random-id-407' is='div' className="pieBackground10">
            </Wrapper>
            <Wrapper canvas is='div' id="pieSlice7510_1" className="hold">
                <Wrapper canvas id='random-id-408' is='div' className="pie10 slice active">
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-409' is='div' className="stat-circle-cover10">  
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-410' is='div' className='flex flex-col text-center'>
              <Wrapper canvas className='mt-4' style={{display:'inline-block', fontSize:'24px', fontWeight:'400', fontFamily:'Poppins', color:'#100103'}} id='random-id-2' is='div'>
            <Text text='Customers'/>
          </Wrapper>
              <Wrapper canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontFamily:'Poppins', fontWeight:'700', color:'#2479FD'}} id='random-id-3' is='div'>
            <Text text='2,524'/>
          </Wrapper>
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-411' is='div' className='w-[50%] flex flex-col min-w-[140px] my-2 mx-auto'>
            <Wrapper canvas id='random-id-412' is='div' className="pieContainer10 mx-auto my-auto">
            <Wrapper canvas id='random-id-413' is='div' className="pieBackground11">
            </Wrapper>
            <Wrapper canvas is='div' id="pieSlice7511_1" className="hold">
                <Wrapper canvas id='random-id-414' is='div' className="pie11 slice active">
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-415' is='div' className="stat-circle-cover11">  
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-416' is='div' className='flex flex-col  text-center'>
              <Wrapper canvas className='mt-4' style={{display:'inline-block', fontSize:'24px', fontWeight:'400', fontFamily:'Poppins', color:'#100103'}} id='random-id-4' is='div'>
            <Text text='Customers'/>
          </Wrapper>
              <Wrapper canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontFamily:'Poppins', fontWeight:'700', color:'#FFC92E'}} id='random-id-5' is='div'>
            <Text text='2,524'/>
          </Wrapper>
            </Wrapper>
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-417' is='div' className='w-[50%] flex flex-row mx-auto flex-wrap'>
            <Wrapper canvas id='random-id-418' is='div' className='w-[50%] flex flex-col min-w-[140px] my-2 mx-auto'>
            <Wrapper canvas id='random-id-419' is='div' className="pieContainer10 mx-auto my-auto">
            <Wrapper canvas id='random-id-420' is='div' className="pieBackground10">
            </Wrapper>
            <Wrapper canvas is='div' id="pieSlice7510_1" className="hold">
                <Wrapper canvas id='random-id-421' is='div' className="pie10 slice active">
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-422' is='div' className="stat-circle-cover10">  
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-423' is='div' className='flex flex-col  text-center'>
                <Wrapper canvas className='mt-4' style={{display:'inline-block', fontSize:'24px', fontWeight:'400', fontFamily:'Poppins', color:'#100103'}} id='random-id-6' is='div'>
            <Text text='customers'/>
          </Wrapper>
                <Wrapper canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontFamily:'Poppins', fontWeight:'700', color:'#2479FD'}} id='random-id-7' is='div'>
            <Text text='2,524'/>
          </Wrapper>
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-424' is='div' className='w-[50%] flex flex-col min-w-[140px] my-2 mx-auto'>
            <Wrapper canvas id='random-id-425' is='div' className="pieContainer10 mx-auto my-auto">
            <Wrapper canvas id='random-id-426' is='div' className="pieBackground11">
            </Wrapper>
            <Wrapper canvas is='div' id="pieSlice7511_1" className="hold">
                <Wrapper canvas id='random-id-427' is='div' className="pie11 slice active">
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-428' is='div' className="stat-circle-cover11">  
            </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-429' is='div' className='flex flex-col  text-center'>
                <Wrapper canvas className='mt-4' style={{display:'inline-block', fontSize:'24px', fontWeight:'400', fontFamily:'Poppins', color:'#100103'}} id='random-id-8' is='div'>
            <Text text='customers'/>
          </Wrapper>
                <Wrapper canvas className='my-2'  style={{display:'inline-block', fontSize:'18px', fontFamily:'Poppins', fontWeight:'700', color:'#FFC92E'}} id='random-id-9' is='div'>
            <Text text='2,524'/>
          </Wrapper>
            </Wrapper>
            </Wrapper>
            </Wrapper>
            
          </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper></Element>
        </Element>,

      <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-430' is='parent' className='w-[100%] h-full py-20 border'>
      <Wrapper canvas id='random-id-431' is='container' className='container'>
        <Wrapper canvas id='random-id-432' is='div' className='w-[100%] h-full'>
       
        <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Poppins'}} className={`w-full h-full text-center mb-10 text-[46px]`} id='random-id-10' is='div'>
            <Text text='Our work in numbers'/>
          </Wrapper>
          
        <Wrapper canvas id='random-id-433' is='wrapper' className='w-[100%] flex flex-row flex-wrap py-10' style={{background:'#FDFDF3', borderRadius:'30px'}}>
          <Wrapper canvas id='random-id-434' is='div' className='w-[50%] flex flex-row mx-auto flex-wrap'>
          <Wrapper canvas id='random-id-435' is='div' className='w-[50%] flex flex-col min-w-[140px] my-2 mx-auto'>
          <Wrapper canvas id='random-id-436' is='div' className="pieContainer10 mx-auto my-auto">
          <Wrapper canvas id='random-id-437' is='div' className="pieBackground12">
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7512_1" className="hold">
              <Wrapper canvas id='random-id-438' is='div' className="pie12 slice active">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7512_2" className="hold">
              <Wrapper canvas id='random-id-439' is='div' className="pie12">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-440' is='div' className="stat-circle-cover12">  
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-441' is='div' className='flex flex-col  text-center'>
           
              <Wrapper canvas style={{display:'inline-block', fontSize:'24px', fontWeight:'400', fontFamily:'Poppins', color:'#100103'}} className='mt-4'  id='random-id-11' is='div'>
            <Text text='customers'/>
          </Wrapper>
           
              <Wrapper canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontFamily:'Poppins', fontWeight:'700', color:'#FF0060'}} id='random-id-12' is='div'>
            <Text text='2,524'/>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-442' is='div' className='w-[50%] flex flex-col min-w-[140px] my-2 mx-auto'>
          <Wrapper canvas id='random-id-443' is='div' className="pieContainer10 mx-auto my-auto">
          <Wrapper canvas id='random-id-444' is='div' className="pieBackground13">
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7513_1" className="hold">
              <Wrapper canvas id='random-id-445' is='div' className="pie13 slice active">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-446' is='div' className="stat-circle-cover13">  
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-447' is='div' className='flex flex-col  text-center'>
            
              <Wrapper canvas className='mt-4' style={{display:'inline-block', fontSize:'24px', fontWeight:'400', fontFamily:'Poppins', color:'#100103'}} id='random-id-13' is='div'>
            <Text text='customers'/>
          </Wrapper>
           
              <Wrapper canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontFamily:'Poppins', fontWeight:'700', color:'#AB0242'}} id='random-id-14' is='div'>
            <Text text='2,524'/>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-448' is='div' className='w-[50%] flex flex-row mx-auto flex-wrap'>
          <Wrapper canvas id='random-id-449' is='div' className='w-[50%] flex flex-col min-w-[140px] my-2 mx-auto'>
          <Wrapper canvas id='random-id-450' is='div' className="pieContainer10 mx-auto my-auto">
          <Wrapper canvas id='random-id-451' is='div' className="pieBackground12">
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7512_1" className="hold">
              <Wrapper canvas id='random-id-452' is='div' className="pie12 slice active">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7512_2" className="hold">
              <Wrapper canvas id='random-id-453' is='div' className="pie12">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-454' is='div' className="stat-circle-cover12">  
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-455' is='div' className='flex flex-col  text-center'>
           
              <Wrapper canvas className='mt-4' style={{display:'inline-block', fontSize:'24px', fontWeight:'400', fontFamily:'Poppins', color:'#100103'}} id='random-id-15' is='div'>
            <Text text='customers'/>
          </Wrapper>
           
              <Wrapper canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontFamily:'Poppins', fontWeight:'700', color:'#FF0060'}} id='random-id-16' is='div'>
            <Text text='2,524'/>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-456' is='div' className='w-[50%] flex flex-col min-w-[140px] my-2 mx-auto'>
          <Wrapper canvas id='random-id-457' is='div' className="pieContainer10 mx-auto my-auto">
          <Wrapper canvas id='random-id-458' is='div' className="pieBackground13">
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7513_1" className="hold">
              <Wrapper canvas id='random-id-459' is='div' className="pie13 slice active">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-460' is='div' className="stat-circle-cover13">  
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-461' is='div' className='flex flex-col  text-center'>
           
              <Wrapper canvas className='mt-4' style={{display:'inline-block', fontSize:'24px', fontWeight:'400', fontFamily:'Poppins', color:'#100103'}} id='random-id-17' is='div'>
            <Text text='customers'/>
          </Wrapper>
           
              <Wrapper canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontFamily:'Poppins', fontWeight:'700', color:'#AB0242'}} id='random-id-18' is='div'>
            <Text text='2,524'/>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          
        </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper></Element>
        </Element>,

    <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-462' is='parent' className='w-[100%] h-full py-20 border'>
      <Wrapper canvas id='random-id-463' is='container' className='container'>
        <Wrapper canvas id='random-id-464' is='div' className='w-[100%] h-full'>
        <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Poppins'}} className={`w-full h-full text-center mb-10 text-[46px]`} id='random-id-19' is='div'>
            <Text text='Our work in numbers'/>
          </Wrapper>
        <Wrapper canvas id='random-id-465' is='wrapper' className='w-[100%] flex flex-row flex-wrap py-10' style={{background:'#FDFDF3', borderRadius:'30px'}}>
          <Wrapper canvas id='random-id-466' is='div' className='w-[50%] flex flex-row mx-auto flex-wrap'>
          <Wrapper canvas id='random-id-467' is='div' className='w-[50%] flex flex-col min-w-[140px] my-2 mx-auto'>
          <Wrapper canvas id='random-id-468' is='div' className="pieContainer10 mx-auto my-auto">
          <Wrapper canvas id='random-id-469' is='div' className="pieBackground14">
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7514_1" className="hold">
              <Wrapper canvas id='random-id-470' is='div' className="pie14 slice active">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7514_2" className="hold">
              <Wrapper canvas id='random-id-471' is='div' className="pie14">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-472' is='div' className="stat-circle-cover14">  
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-473' is='div' className='flex flex-col  text-center'>
              <Wrapper canvas className='mt-4'  style={{display:'inline-block', fontSize:'24px', fontWeight:'400', fontFamily:'Poppins', color:'#100103'}} id='random-id-20' is='div'>
            <Text text='customers'/>
          </Wrapper>
              <Wrapper canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontFamily:'Poppins', fontWeight:'700', color:'#FF0060'}} id='random-id-21' is='div'>
            <Text text='2,524'/>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-474' is='div' className='w-[50%] flex flex-col min-w-[140px] my-2 mx-auto'>
          <Wrapper canvas id='random-id-475' is='div' className="pieContainer10 mx-auto my-auto">
          <Wrapper canvas id='random-id-476' is='div' className="pieBackground15">
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7515_1" className="hold">
              <Wrapper canvas id='random-id-477' is='div' className="pie15 slice active">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-478' is='div' className="stat-circle-cover15">  
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-479' is='div' className='flex flex-col  text-center'>
              <Wrapper canvas className='mt-4'  style={{display:'inline-block', fontSize:'24px', fontWeight:'400', fontFamily:'Poppins', color:'#100103'}} id='random-id-22' is='div'>
            <Text text='customers'/>
          </Wrapper>
              <Wrapper canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontFamily:'Poppins', fontWeight:'700', color:'#FFB7B7'}} id='random-id-23' is='div'>
            <Text text='2,524'/>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-480' is='div' className='w-[50%] flex flex-row mx-auto flex-wrap'>
          <Wrapper canvas id='random-id-481' is='div' className='w-[50%] flex flex-col min-w-[140px] my-2 mx-auto'>
          <Wrapper canvas id='random-id-482' is='div' className="pieContainer10 mx-auto my-auto">
          <Wrapper canvas id='random-id-483' is='div' className="pieBackground14">
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7514_1" className="hold">
              <Wrapper canvas id='random-id-484' is='div' className="pie14 slice active">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7514_2" className="hold">
              <Wrapper canvas id='random-id-485' is='div' className="pie14">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-486' is='div' className="stat-circle-cover14">  
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-487' is='div' className='flex flex-col  text-center'>
              <Wrapper canvas className='mt-4'  style={{display:'inline-block', fontSize:'24px', fontWeight:'400', fontFamily:'Poppins', color:'#100103'}} id='random-id-24' is='div'>
            <Text text='customers'/>
          </Wrapper>
              <Wrapper canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontFamily:'Poppins', fontWeight:'700', color:'#FF0060'}} id='random-id-25' is='div'>
            <Text text='2,524'/>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-488' is='div' className='w-[50%] flex flex-col min-w-[140px] my-2 mx-auto'>
          <Wrapper canvas id='random-id-489' is='div' className="pieContainer10 mx-auto my-auto">
          <Wrapper canvas id='random-id-490' is='div' className="pieBackground15">
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7515_1" className="hold">
              <Wrapper canvas id='random-id-491' is='div' className="pie15 slice active">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-492' is='div' className="stat-circle-cover15">  
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-493' is='div' className='flex flex-col  text-center'>
              <Wrapper canvas className='mt-4'  style={{display:'inline-block', fontSize:'24px', fontWeight:'400', fontFamily:'Poppins', color:'#100103'}} id='random-id-26' is='div'>
            <Text text='customers'/>
          </Wrapper>
              <Wrapper canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontFamily:'Poppins', fontWeight:'700', color:'#FFB7B7'}} id='random-id-27' is='div'>
            <Text text='2,524'/>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          
        </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper></Element>
        </Element>,

    <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-494' is='parent' className='w-[100%] h-full py-20 border'>
      <Wrapper canvas id='random-id-495' is='container' className='container'>
        <Wrapper canvas id='random-id-496' is='div' className='w-[100%] h-full'>
        <Wrapper canvas style={{display:'inline-block', fontWeight:'700', fontFamily:'Poppins'}} className={`w-full h-full text-center mb-10 text-[46px]`} id='random-id-28' is='div'>
            <Text text='Our work in numbers'/>
          </Wrapper>
        <Wrapper canvas id='random-id-497' is='wrapper' className='w-[100%] flex flex-row flex-wrap py-10' style={{background:'#FDFDF3', borderRadius:'30px'}}>
          <Wrapper canvas id='random-id-498' is='div' className='w-[50%] flex flex-row mx-auto flex-wrap'>
          <Wrapper canvas id='random-id-499' is='div' className='w-[50%] flex flex-col min-w-[140px] my-2 mx-auto'>
          <Wrapper canvas id='random-id-500' is='div' className="pieContainer10 mx-auto my-auto">
          <Wrapper canvas id='random-id-501' is='div' className="pieBackground16">
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7516_1" className="hold">
              <Wrapper canvas id='random-id-502' is='div' className="pie16 slice active">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7516_2" className="hold">
              <Wrapper canvas id='random-id-503' is='div' className="pie16">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-504' is='div' className="stat-circle-cover16">  
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-505' is='div' className='flex flex-col  text-center'>
              <Wrapper canvas className='mt-4' style={{display:'inline-block', fontSize:'24px', fontWeight:'400', fontFamily:'Poppins', color:'#100103'}} id='random-id-29' is='div'>
            <Text text='customers'/>
          </Wrapper>
              <Wrapper canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontFamily:'Poppins', fontWeight:'700', color:'#0C134F'}} id='random-id-30' is='div'>
            <Text text='2,524'/>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-506' is='div' className='w-[50%] flex flex-col min-w-[140px] my-2 mx-auto'>
          <Wrapper canvas id='random-id-507' is='div' className="pieContainer10 mx-auto my-auto">
          <Wrapper canvas id='random-id-508' is='div' className="pieBackground17">
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7517_1" className="hold">
              <Wrapper canvas id='random-id-509' is='div' className="pie17 slice active">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-510' is='div' className="stat-circle-cover17">  
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-511' is='div' className='flex flex-col  text-center'>
              <Wrapper canvas className='mt-4' style={{display:'inline-block', fontSize:'24px', fontWeight:'400', fontFamily:'Poppins', color:'#100103'}} id='random-id-31' is='div'>
            <Text text='customers'/>
          </Wrapper>
              <Wrapper canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontFamily:'Poppins', fontWeight:'700', color:'#D4ADFC'}} id='random-id-32' is='div'>
            <Text text='2,524'/>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-512' is='div' className='w-[50%] flex flex-row mx-auto flex-wrap'>
          <Wrapper canvas id='random-id-513' is='div' className='w-[50%] flex flex-col min-w-[140px] my-2 mx-auto'>
          <Wrapper canvas id='random-id-514' is='div' className="pieContainer10 mx-auto my-auto">
          <Wrapper canvas id='random-id-515' is='div' className="pieBackground16">
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7516_1" className="hold">
              <Wrapper canvas id='random-id-516' is='div' className="pie16 slice active">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7516_2" className="hold">
              <Wrapper canvas id='random-id-517' is='div' className="pie16">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-518' is='div' className="stat-circle-cover16">  
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-519' is='div' className='flex flex-col  text-center'>
              <Wrapper canvas className='mt-4' style={{display:'inline-block', fontSize:'24px', fontWeight:'400', fontFamily:'Poppins', color:'#100103'}} id='random-id-33' is='div'>
            <Text text='customers'/>
          </Wrapper>
              <Wrapper canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontFamily:'Poppins', fontWeight:'700', color:'#0C134F'}} id='random-id-34' is='div'>
            <Text text='2,524'/>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-520' is='div' className='w-[50%] flex flex-col min-w-[140px] my-2 mx-auto'>
          <Wrapper canvas id='random-id-521' is='div' className="pieContainer10 mx-auto my-auto">
          <Wrapper canvas id='random-id-522' is='div' className="pieBackground17">
          </Wrapper>
          <Wrapper canvas is='div' id="pieSlice7517_1" className="hold">
              <Wrapper canvas id='random-id-523' is='div' className="pie17 slice active">
              </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-524' is='div' className="stat-circle-cover17">  
          </Wrapper>
          </Wrapper>
          <Wrapper canvas id='random-id-525' is='div' className='flex flex-col  text-center'>
              <Wrapper canvas className='mt-4' style={{display:'inline-block', fontSize:'24px', fontWeight:'400', fontFamily:'Poppins', color:'#100103'}} id='random-id-35' is='div'>
            <Text text='customers'/>
          </Wrapper>
              <Wrapper canvas className='my-2' style={{display:'inline-block', fontSize:'18px', fontFamily:'Poppins', fontWeight:'700', color:'#D4ADFC'}} id='random-id-36' is='div'>
            <Text text='2,524'/>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          </Wrapper>
          
        </Wrapper>
        </Wrapper>
      </Wrapper>
    </Wrapper></Element>
        </Element>
    ]

    
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])
      
  return (
    <div style={{display:"flex", width:"100%", justifyContent:"center", alignItems:"center", height:'100%'}} ref={connect}>
      <div  style={{display:"flex", width:"100%", justifyContent:"center", alignItems:"center", height:'100%'}} >
      <div style={{ padding: enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
      {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
    </div>
      </div>
    </div>
);
};

Statistics_2.craft = {
displayName: "Statistics 2",
props: {
   selected: 1, // here is the default value of the selected style
   length:3,
isBlock: true
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: NewStatisticsSettings,
  },
};

