/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState, useEffect, useRef } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { NewEcommerceSettings } from './newEcommerceSetting'
import Text from '../../basic/Text';
import { ImageC } from '../../basic/Image';
import Button  from '../../basic/Button';

import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';


export const Ecommerce_1 = ({ selected }) => {


    const {
        actions: { setProp },
        connectors: {
            connect
        }
    } = useNode();


    const {
        enabled,
    } = useEditor((state, query) => ({
        enabled: state.options.enabled,

    }));


    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto bg-[#FFFFFF] py-10" >
            <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-401' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Rubik' }} className={`text-[#100103] my-4 text-[46px] pl-6`} id='random-id-1'  >
                        <Text text='Our best products' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-402' className='w-full h-full flex flex-row flex-wrap justify-center px-4'>
                        <Element is={Parent} canvas id='random-id-403' className=' h-full flex flex-col text-center px-4 mx-auto my-2 ' style={{ border: '1px solid #DBD9D9', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-404' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-100'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-405' className='flex flex-col text-[#100103] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Rubik', letterSpacing: '0.5px' }} className='my-4' id='random-id-3'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlignLast: 'center' }} id='random-id-4'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#B20312', fontSize: '34px', fontWeight: '700' }} className='my-4 ' id='random-id-5'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-200' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#B20312] hover:bg-[#87000C] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A', boxShadow: '0px 4px 10px 0px #FFFFFF0D' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '600', fontFamily: 'Rubik', }} className='mx-auto my-auto' id='random-id-6'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-406' className='h-full flex flex-col text-center px-4 mx-auto my-2' style={{ border: '1px solid #DBD9D9', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-407' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-101'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-408' className='flex flex-col text-[#100103] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Rubik', letterSpacing: '0.5px' }} className='my-4' id='random-id-7'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlignLast: 'center' }} id='random-id-8'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#B20312', fontSize: '34px', fontWeight: '700' }} className='my-4  ' id='random-id-9'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-201' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#B20312] hover:bg-[#87000C] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A', boxShadow: '0px 4px 10px 0px #FFFFFF0D' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '600', fontFamily: 'Rubik', }} className='mx-auto my-auto' id='random-id-10'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-409' className='h-full flex flex-col text-center px-4 mx-auto my-2' style={{ border: '1px solid #DBD9D9', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-410' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-102'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-411' className='flex flex-col text-[#100103] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Rubik', letterSpacing: '0.5px' }} className='my-4' id='random-id-11'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlignLast: 'center' }} id='random-id-12'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#B20312', fontSize: '34px', fontWeight: '700' }} className='my-4  ' id='random-id-13'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-202' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#B20312] hover:bg-[#87000C] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A', boxShadow: '0px 4px 10px 0px #FFFFFF0D' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '600', fontFamily: 'Rubik', }} className='mx-auto my-auto' id='random-id-14'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,

        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto bg-[#121826] py-10" >
            <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-401' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Rubik' }} className={`text-[#3265E3] my-4 text-[46px] pl-6`} id='random-id-1'  >
                        <Text text='Our best products' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-402' className='w-full h-full flex flex-row flex-wrap justify-center px-4'>
                        <Element is={Parent} canvas id='random-id-403' className=' h-full flex flex-col text-center px-4 mx-auto my-2 ' style={{ border: '1px solid #ffffff43', flex: '27%', }}>
                            <Element is={Parent} canvas id='random-id-404' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-100'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-405' className='flex flex-col text-[#FFFFFF] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Rubik', letterSpacing: '0.5px' }} className='my-4' id='random-id-3'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlignLast: 'center' }} id='random-id-4'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#4BDBA2', fontSize: '34px', fontWeight: '700' }} className='my-4 ' id='random-id-5'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-200' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#3265E3] hover:bg-[#0049FF] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A', boxShadow: '0px 4px 10px 0px #FFFFFF0D' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '600', fontFamily: 'Rubik', }} className='mx-auto my-auto' id='random-id-6'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-406' className='h-full flex flex-col text-center px-4 mx-auto my-2' style={{ border: '1px solid #ffffff43', flex: '27%', }}>
                            <Element is={Parent} canvas id='random-id-407' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-101'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-408' className='flex flex-col text-[#FFFFFF] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Rubik', letterSpacing: '0.5px' }} className='my-4' id='random-id-7'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlignLast: 'center' }} id='random-id-8'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#4BDBA2', fontSize: '34px', fontWeight: '700' }} className='my-4  ' id='random-id-9'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-201' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#3265E3] hover:bg-[#0049FF] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A', boxShadow: '0px 4px 10px 0px #FFFFFF0D' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '600', fontFamily: 'Rubik', }} className='mx-auto my-auto' id='random-id-10'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-409' className='h-full flex flex-col text-center px-4 mx-auto my-2' style={{ border: '1px solid #ffffff43', flex: '27%', }}>
                            <Element is={Parent} canvas id='random-id-410' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-102'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-411' className='flex flex-col text-[#FFFFFF] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Rubik', letterSpacing: '0.5px' }} className='my-4' id='random-id-11'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlignLast: 'center' }} id='random-id-12'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#4BDBA2', fontSize: '34px', fontWeight: '700' }} className='my-4  ' id='random-id-13'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-202' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#3265E3] hover:bg-[#0049FF] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A', boxShadow: '0px 4px 10px 0px #FFFFFF0D' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '600', fontFamily: 'Rubik', }} className='mx-auto my-auto' id='random-id-14'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,
        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto bg-[#213156] py-10" >
            <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-413' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Open Sans' }} className={`text-[#EA6EF4]  my-4 text-[46px] pl-6`} id='random-id-28'  >
                        <Text text='Our best products' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-414' className='w-full h-full flex flex-row flex-wrap justify-center px-4'>
                        <Element is={Parent} canvas id='random-id-415' className=' h-full flex flex-col text-center px-4 my-2 mx-auto ' style={{ border: '1px solid #EA6EF41A', boxShadow: '0px 0px 4px 0px #FFFFFF1A', borderRadius: '10px', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-416' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-106'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-417' className='flex flex-col text-white items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Open Sans', letterSpacing: '0.5px' }} className='my-4' id='random-id-29'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', textAlignLast: 'center' }} id='random-id-30'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#EA6EF4', fontSize: '34px', fontWeight: '700', fontFamily: 'Times New Roman' }} className='my-4  ' id='random-id-31'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} id='random-id-206' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#EA6EF4] hover:bg-[#E111F2] text-[#FFF] uppercase' style={{ borderRadius: '40px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '600', fontFamily: 'Open Sans', }} className='mx-auto my-auto' id='random-id-32'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-418' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{ border: '1px solid #EA6EF41A', boxShadow: '0px 0px 4px 0px #FFFFFF1A', borderRadius: '10px', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-419' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-107'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-420' className='flex flex-col text-white items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Open Sans', letterSpacing: '0.5px' }} className='my-4' id='random-id-33'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', textAlignLast: 'center' }} id='random-id-34'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#EA6EF4', fontSize: '34px', fontWeight: '700', fontFamily: 'Times New Roman' }} className='my-4  ' id='random-id-35'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} id='random-id-207' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#EA6EF4] hover:bg-[#E111F2] text-[#FFF] uppercase' style={{ borderRadius: '40px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '600', fontFamily: 'Open Sans', }} className='mx-auto my-auto' id='random-id-36'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-421' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{ border: '1px solid #EA6EF41A', boxShadow: '0px 0px 4px 0px #FFFFFF1A', borderRadius: '10px', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-422' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-108'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-423' className='flex flex-col text-white items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Open Sans', letterSpacing: '0.5px' }} className='my-4' id='random-id-37'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Open Sans', textAlignLast: 'center' }} id='random-id-38'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#EA6EF4', fontSize: '34px', fontWeight: '700', fontFamily: 'Times New Roman' }} className='my-4  ' id='random-id-39'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} id='random-id-208' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#EA6EF4] hover:bg-[#E111F2] text-[#FFF] uppercase' style={{ borderRadius: '40px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '600', fontFamily: 'Open Sans', }} className='mx-auto my-auto' id='random-id-40'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto bg-[#DFFFF9] py-10" >
            <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-425' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Zing Script Rust SemiBold Demo', fontStyle: 'italic' }} className={`text-[#31A993]  my-4 text-[46px] pl-6`} id='random-id-41'  >
                        <Text text='Our best products' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-426' className='w-full h-full flex flex-row flex-wrap justify-center px-4'>
                        <Element is={Parent} canvas id='random-id-427' className=' h-full flex flex-col text-center px-4 my-2 mx-auto ' style={{ border: '1px solid #31A9931A', boxShadow: '0px 0px 4px 0px #0825201A', borderRadius: '30px', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-428' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-109'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-429' className='flex flex-col text-[#031815] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Montserrat', letterSpacing: '0.5px' }} className='my-4' id='random-id-42'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Montserrat', textAlignLast: 'center' }} id='random-id-43'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#31A993', fontSize: '34px', fontWeight: '700', fontFamily: 'Zing Script Rust SemiBold Demo', fontStyle: 'italic' }} className='my-4  ' id='random-id-44'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-209' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#31a993] hover:bg-[#11816C] text-[#FFF] uppercase' style={{ borderRadius: '40px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '600', fontFamily: 'Open Sans', }} className='mx-auto my-auto' id='random-id-45'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-430' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{ border: '1px solid #31A9931A', boxShadow: '0px 0px 4px 0px #0825201A', borderRadius: '30px', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-431' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-110'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-432' className='flex flex-col text-[#031815] items-center '>
                                <Element is={Parent} canvas className='my-4' style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Montserrat', letterSpacing: '0.5px' }} id='random-id-46'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Montserrat', textAlignLast: 'center' }} id='random-id-47'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#31A993', fontSize: '34px', fontWeight: '700', fontFamily: 'Zing Script Rust SemiBold Demo', fontStyle: 'italic' }} className='my-4  ' id='random-id-48'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-210' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#31a993] hover:bg-[#11816C] text-[#FFF] uppercase' style={{ borderRadius: '40px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '600', fontFamily: 'Open Sans', }} className='mx-auto my-auto' id='random-id-50'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-433' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{ border: '1px solid #31A9931A', boxShadow: '0px 0px 4px 0px #0825201A', borderRadius: '30px', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-434' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-111'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-435' className='flex flex-col text-[#031815] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Montserrat', letterSpacing: '0.5px' }} className='my-4' id='random-id-50'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Montserrat', textAlignLast: 'center' }} id='random-id-51'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#31A993', fontSize: '34px', fontWeight: '700', fontFamily: 'Zing Script Rust SemiBold Demo', fontStyle: 'italic' }} className='my-4  ' id='random-id-52'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-211' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#31a993] hover:bg-[#11816C] text-[#FFF] uppercase' style={{ borderRadius: '40px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '600', fontFamily: 'Open Sans', }} className='mx-auto my-auto' id='random-id-53'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto bg-[#FFFFFF] py-10" >
            <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-437' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Inter' }} className={`text-[#0078D3]  my-4 text-[46px] pl-6`} id='random-id-54'  >
                        <Text text='Our best products.' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-438' className='w-full h-full flex flex-row flex-wrap justify-center px-4'>
                        <Element is={Parent} canvas id='random-id-439' className=' h-full flex flex-col text-center px-4 my-2 mx-auto ' style={{ border: '1px solid #DBD9D9', borderRadius: '4px', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-440' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-112'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-441' className='flex flex-col text-[#100103] items-center '>
                                <Element is={Parent} canvas className='my-4' style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Inter', letterSpacing: '0.5px' }} id='random-id-56'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', textAlignLast: 'center' }} id='random-id-57'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#0078D3', fontSize: '34px', fontWeight: '700' }} className='my-4  ' id='random-id-58'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-211' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#0078d3] hover:bg-[#11578B] text-[#FFF] uppercase' style={{ boxShadow: '0px 4px 10px 0px #0000001A' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '600', fontFamily: 'Inter', }} className='mx-auto my-auto' id='random-id-59'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-442' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{ border: '1px solid #DBD9D9', borderRadius: '4px', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-443' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-113'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-444' className='flex flex-col text-[#100103] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Inter', letterSpacing: '0.5px' }} className='my-4' id='random-id-60'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', textAlignLast: 'center' }} id='random-id-61'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#0078D3', fontSize: '34px', fontWeight: '700' }} className='my-4  ' id='random-id-62'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-212' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#0078d3] hover:bg-[#11578B] text-[#FFF] uppercase' style={{ boxShadow: '0px 4px 10px 0px #0000001A' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '600', fontFamily: 'Inter', }} className='mx-auto my-auto' id='random-id-63'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-445' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{ border: '1px solid #DBD9D9', borderRadius: '4px', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-446' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-114'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-447' className='flex flex-col text-[#100103] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Inter', letterSpacing: '0.5px' }} className='my-4' id='random-id-64'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Inter', textAlignLast: 'center' }} id='random-id-65'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#0078D3', fontSize: '34px', fontWeight: '700' }} className='my-4  ' id='random-id-66'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-213' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#0078d3] hover:bg-[#11578B] text-[#FFF] uppercase' style={{ boxShadow: '0px 4px 10px 0px #0000001A' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '600', fontFamily: 'Inter', }} className='mx-auto my-auto' id='random-id-67'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto bg-[#FFFFFF] py-10" >
            <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-449' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Voces' }} className={`text-[#020212]  my-4 text-[46px] pl-6`} id='random-id-68'  >
                        <Text text='Our best products' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-450' className='w-full h-full flex flex-row flex-wrap justify-center px-4'>
                        <Element is={Parent} canvas id='random-id-451' className=' h-full flex flex-col text-center px-4 my-2 mx-auto ' style={{ border: '1px solid #0202120A', background: '#F8F8F8', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-452' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-115'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-453' className='flex flex-col text-[#020212] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Voces', letterSpacing: '0.5px' }} className='my-4' id='random-id-69'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Voces', textAlignLast: 'center' }} id='random-id-70'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#020212', fontSize: '32px', fontWeight: '700' }} className='my-4  ' id='random-id-71'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-214' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#FFFFFFA1] hover:bg-[#020212] text-[#000000] hover:text-[#FFFFFF] uppercase ' style={{ border: '2px solid #020212', borderRadius: '10px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '600', fontFamily: 'Voces', }} className='mx-auto my-auto' id='random-id-72'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-454' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{ border: '1px solid #0202120A', background: '#F8F8F8', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-455' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-116'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-456' className='flex flex-col text-[#020212] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Voces', letterSpacing: '0.5px' }} className='my-4' id='random-id-73'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Voces', textAlignLast: 'center' }} id='random-id-74'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#020212', fontSize: '32px', fontWeight: '700' }} className='my-4  ' id='random-id-75'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-215' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#FFFFFFA1] hover:bg-[#020212] text-[#000000] hover:text-[#FFFFFF] uppercase ' style={{ border: '2px solid #020212', borderRadius: '10px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '600', fontFamily: 'Voces', }} className='mx-auto my-auto' id='random-id-76'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-457' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{ border: '1px solid #0202120A', background: '#F8F8F8', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-458' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-117'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-459' className='flex flex-col text-[#020212] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Voces', letterSpacing: '0.5px' }} className='my-4' id='random-id-77'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Voces', textAlignLast: 'center' }} id='random-id-78'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#020212', fontSize: '32px', fontWeight: '700' }} className='my-4  ' id='random-id-79'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-216' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#FFFFFFA1] hover:bg-[#020212] text-[#000000] hover:text-[#FFFFFF] uppercase ' style={{ border: '2px solid #020212', borderRadius: '10px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '22px', fontWeight: '600', fontFamily: 'Voces', }} className='mx-auto my-auto' id='random-id-80'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                    </Element>

                </Element></Element>
        </Element>
        ,


        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto bg-[#FBFAFF] py-10" >
            <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-461' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Roboto' }} className={`text-[#6750A4]  my-4 text-[46px] pl-6`} id='random-id-81'  >
                        <Text text='Our best products' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-462' className='w-full h-full flex flex-row flex-wrap justify-center px-4'>
                        <Element is={Parent} canvas id='random-id-463' className=' h-full flex flex-col text-center px-4 my-2 mx-auto ' style={{ border: '1px solid #D0BCFF66', borderRadius: '30px', background: '#FFFFFF', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-464' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-118'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-465' className='flex flex-col text-[#020009] items-center '>
                                <Element is={Parent} canvas className='my-4' style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Roboto', letterSpacing: '0.5px', color: '#6750A4' }} id='random-id-82'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Roboto', textAlignLast: 'center' }} id='random-id-83'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#6750A4', fontSize: '32px', fontWeight: '700' }} className='my-4  ' id='random-id-84'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-217' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[220px] h-[40px] cursor-pointer mb-4 bg-[#D0BCFF] border-1 hover:border-[#381E72] uppercase text-[#6750A4]' style={{ borderRadius: '100px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '500px', fontFamily: 'Roboto', }} className='mx-auto my-auto' id='random-id-85'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-466' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{ border: '1px solid #D0BCFF66', borderRadius: '30px', background: '#FFFFFF', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-467' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-119'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-468' className='flex flex-col text-[#020009] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Roboto', letterSpacing: '0.5px', color: '#6750A4' }} className='my-4' id='random-id-86'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Roboto', textAlignLast: 'center' }} id='random-id-87'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas className='my-4  ' style={{ display: 'inline-block', color: '#6750A4', fontSize: '32px', fontWeight: '700' }} id='random-id-88'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-218' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[220px] h-[40px] cursor-pointer mb-4 bg-[#D0BCFF] border-1 hover:border-[#381E72] uppercase text-[#6750A4]' style={{ borderRadius: '100px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '500px', fontFamily: 'Roboto', }} className='mx-auto my-auto' id='random-id-89'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-469' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{ border: '1px solid #D0BCFF66', borderRadius: '30px', background: '#FFFFFF', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-470' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-120'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-471' className='flex flex-col text-[#020009] items-center '>
                                <Element is={Parent} canvas className='my-4' style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Roboto', letterSpacing: '0.5px', color: '#6750A4' }} id='random-id-90'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Roboto', textAlignLast: 'center' }} id='random-id-91'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas className='my-4  ' style={{ display: 'inline-block', color: '#6750A4', fontSize: '32px', fontWeight: '700' }} id='random-id-92'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-219' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[220px] h-[40px] cursor-pointer mb-4 bg-[#D0BCFF] border-1 hover:border-[#381E72] uppercase text-[#6750A4]' style={{ borderRadius: '100px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '500px', fontFamily: 'Roboto', }} className='mx-auto my-auto' id='random-id-93'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,

        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto bg-[#2A2A2A] py-10" >
            <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-473' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Inter' }} className={`text-[#FFD600]  my-4 text-[46px] pl-6 `} id='random-id-94'  >
                        <Text text='Our best products.' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-474' className='w-full h-full flex flex-row flex-wrap justify-center px-4'>
                        <Element is={Parent} canvas id='random-id-475' className=' h-full flex flex-col text-center px-4 my-2 mx-auto ' style={{ border: '1px solid #FFD6001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F', borderRadius: '4px', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-476' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-121'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-477' className='flex flex-col text-[#FFFFFF] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Rubik', letterSpacing: '0.5px' }} className='my-4' id='random-id-95'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlignLast: 'center' }} id='random-id-96'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas className='my-4  ' style={{ display: 'inline-block', color: '#FFD600', fontSize: '34px', fontWeight: '700' }} id='random-id-97'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-220' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#FFD600] hover:bg-[#FFD600A1] text-[#2A2A2A] ' style={{ borderRadius: '10px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Inter', }} className='mx-auto my-auto' id='random-id-98'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-478' className='  h-full flex flex-col text-center px-4 my-2 mx-auto' style={{ border: '1px solid #FFD6001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F', borderRadius: '4px', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-479' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-122'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-480' className='flex flex-col text-[#FFFFFF] items-center '>
                                <Element is={Parent} canvas className='my-4' style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Rubik', letterSpacing: '0.5px' }} id='random-id-99'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlignLast: 'center' }} id='random-id-1-100'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas className='my-4  ' style={{ display: 'inline-block', color: '#FFD600', fontSize: '34px', fontWeight: '700' }} id='random-id-1-101'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-221' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#FFD600] hover:bg-[#FFD600A1] text-[#2A2A2A] ' style={{ borderRadius: '10px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Inter', }} className='mx-auto my-auto' id='random-id-102'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-481' className='h-full flex flex-col text-center px-4 my-2 mx-auto' style={{ border: '1px solid #FFD6001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F', borderRadius: '4px', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-482' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-123'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-483' className='flex flex-col text-[#FFFFFF] items-center '>
                                <Element is={Parent} canvas className='my-4' style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Rubik', letterSpacing: '0.5px' }} id='random-id-1-103'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlignLast: 'center' }} id='random-id-1-104'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas className='my-4  ' style={{ display: 'inline-block', color: '#FFD600', fontSize: '34px', fontWeight: '700' }} id='random-id-1-105'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-222' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[260px] h-[70px] cursor-pointer mb-4 bg-[#FFD600] hover:bg-[#FFD600A1] text-[#2A2A2A] ' style={{ borderRadius: '10px' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Inter', }} className='mx-auto my-auto' id='random-id-1-106'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                    </Element>

                </Element></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto bg-[#F7FBFE] py-10" >
            <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-485' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Rubik' }} className={`text-[#6750A4] my-4 text-[46px] pl-6`} id='random-id-1'  >
                        <Text text='Our best products' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-486' className='w-full h-full flex flex-row flex-wrap justify-center px-4'>
                        <Element is={Parent} canvas id='random-id-487' className=' h-full flex flex-col text-center px-4 mx-auto my-2 ' style={{ border: '1px solid rgba(96, 100, 210, 0.10)', borderRadius: '10px', background: '#fff', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-488' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-100'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-489' className='flex flex-col text-[#100103] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Rubik', letterSpacing: '0.5px' }} className='my-4' id='random-id-3'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlignLast: 'center' }} id='random-id-4'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#6064D2', fontSize: '32px', fontWeight: '700' }} className='my-4 ' id='random-id-5'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-200' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[320px] h-[50px] cursor-pointer mb-4 bg-[#B20312] hover:bg-[#87000C] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', borderRadius: '10px', fontWeight: '600', fontFamily: 'Rubik', }} className='mx-auto my-auto' id='random-id-6'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-490' className='h-full flex flex-col text-center px-4 mx-auto my-2' style={{ border: '1px solid rgba(96, 100, 210, 0.10)', borderRadius: '10px', background: '#fff', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-491' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-101'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-492' className='flex flex-col text-[#100103] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Rubik', letterSpacing: '0.5px' }} className='my-4' id='random-id-7'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlignLast: 'center' }} id='random-id-8'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#6064D2', fontSize: '32px', fontWeight: '700' }} className='my-4  ' id='random-id-9'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-201' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[320px] h-[50px] cursor-pointer mb-4 bg-[#B20312] hover:bg-[#87000C] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', borderRadius: '10px', fontWeight: '600', fontFamily: 'Rubik', }} className='mx-auto my-auto' id='random-id-10'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-493' className='h-full flex flex-col text-center px-4 mx-auto my-2' style={{ border: '1px solid rgba(96, 100, 210, 0.10)', borderRadius: '10px', background: '#fff', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-494' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-102'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-495' className='flex flex-col text-[#100103] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Rubik', letterSpacing: '0.5px' }} className='my-4' id='random-id-11'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlignLast: 'center' }} id='random-id-12'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#6064D2', fontSize: '32px', fontWeight: '700' }} className='my-4  ' id='random-id-13'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-202' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[320px] h-[50px] cursor-pointer mb-4 bg-[#B20312] hover:bg-[#87000C] uppercase text-[#FFFFFF]' style={{ border: '1px solid #FFFFFF1A' }}>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', borderRadius: '10px', fontWeight: '600', fontFamily: 'Rubik', }} className='mx-auto my-auto' id='random-id-14'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,


        <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto bg-[#FEFBF7] py-10" >
            <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto flex justify-center items-center" row={true} >
                <Element is={Parent} canvas id='random-id-497' className='max-w-[1300px]'>
                    <Element is={Parent} canvas style={{ display: 'inline-block', fontWeight: '700', fontFamily: 'Rubik' }} className={`text-[#A25738] my-4 text-[46px] pl-6`} id='random-id-1'  >
                        <Text text='Our best products' />
                    </Element>
                    <Element is={Parent} canvas id='random-id-498' className='w-full h-full flex flex-row flex-wrap justify-center px-4'>
                        <Element is={Parent} canvas id='random-id-499' className=' h-full flex flex-col text-center px-4 mx-auto my-2 ' style={{ border: '1px solid rgba(162, 87, 56, 0.10)', borderRadius: '10px', background: '#fff', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-500' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-100'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-501' className='flex flex-col text-[#100103] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Rubik', letterSpacing: '0.5px' }} className='my-4' id='random-id-3'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlignLast: 'center' }} id='random-id-4'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#A25738', fontSize: '32px', fontWeight: '700' }} className='my-4 ' id='random-id-5'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-200' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[320px] h-[50px] cursor-pointer mb-4 bg-[#A25738] uppercase text-[#FFFFFF]'>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', borderRadius: '10px', fontWeight: '600', fontFamily: 'Rubik' }} className='mx-auto my-auto' id='random-id-6'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-502' className='h-full flex flex-col text-center px-4 mx-auto my-2' style={{ border: '1px solid rgba(162, 87, 56, 0.10)', borderRadius: '10px', background: '#fff', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-503' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-101'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-504' className='flex flex-col text-[#100103] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Rubik', letterSpacing: '0.5px' }} className='my-4' id='random-id-7'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlignLast: 'center' }} id='random-id-8'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#A25738', fontSize: '32px', fontWeight: '700' }} className='my-4  ' id='random-id-9'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-201' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[320px] h-[50px] cursor-pointer mb-4 bg-[#A25738] uppercase text-[#FFFFFF]'>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', borderRadius: '10px', fontWeight: '600', fontFamily: 'Rubik' }} className='mx-auto my-auto' id='random-id-10'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                        <Element is={Parent} canvas id='random-id-505' className='h-full flex flex-col text-center px-4 mx-auto my-2' style={{ border: '1px solid rgba(162, 87, 56, 0.10)', borderRadius: '10px', background: '#fff', flex: '27%' }}>
                            <Element is={Parent} canvas id='random-id-506' className='w-full h-[207px] my-4 flex mx-auto justify-center'>
                                <Element is={Parent} canvas style={{ display: 'inline-block', width: '100%', height: '100%' }} id='random-id-102'  >
                                    <ImageC className='w-[100%] h-[100%] object-cover' src='https://i.ibb.co/fx9FXrV/Ecommerce.png' />
                                </Element>
                            </Element>
                            <Element is={Parent} canvas id='random-id-507' className='flex flex-col text-[#100103] items-center '>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', fontWeight: '700', fontFamily: 'Rubik', letterSpacing: '0.5px' }} className='my-4' id='random-id-11'  >
                                    <Text text='Product name' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '18px', fontWeight: '400', fontFamily: 'Rubik', textAlignLast: 'center' }} id='random-id-12'  >
                                    <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                                </Element>
                                <Element is={Parent} canvas style={{ display: 'inline-block', color: '#A25738', fontSize: '32px', fontWeight: '700' }} className='my-4  ' id='random-id-13'  >
                                    <Text text='$299' />
                                </Element>
                                <Element is={Parent} canvas id='random-id-202' className='mx-auto mb-4'>
                                    <Button classn='block' class='flex w-[320px] h-[50px] cursor-pointer mb-4 bg-[#A25738] uppercase text-[#FFFFFF]'>
                                        <Element is={Parent} canvas style={{ display: 'inline-block', fontSize: '24px', borderRadius: '10px', fontWeight: '600', fontFamily: 'Rubik' }} className='mx-auto my-auto' id='random-id-14'  >
                                            <Text text='Learn more' />
                                        </Element>
                                    </Button>
                                </Element>
                            </Element>
                        </Element>
                    </Element>
                </Element></Element>
        </Element>
        ,
    ]


    const [sel, setSel] = useState(selected)

    function makeOdd(number) {
        setProp((prop) => {
            setSel(100);
        }, 1000);

        setTimeout(() => {
            setProp((prop) => {
                setSel(number)
            }, 1000);
        }, 100);



    }

    useEffect(() => {
        if (selected || selected >= 0) {
            makeOdd(selected);
        }
    }, [selected])



    return (
        <div style={{ padding: enabled ? "10px" : "0px", display: "flex", justifyContent: "center", alignItems: "center", width: '100%', height: '100%' }} ref={connect}>
            {sel == 100 ? (
                <div style={{ height: "85vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <BounceLoader
                        size={100}
                        color={"white"}
                        loading={true}
                    />
                </div>
            ) : (
                styles[sel]
            )}
        </div>
    );
}

Ecommerce_1.craft = {
    displayName: "Ecommerce 1",
    props: {
        selected: 1, // here is the default value of the selected style
        length: 9,
        isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewEcommerceSettings,
    },
};
