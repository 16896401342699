/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import Text from '../../basic/Text';
import { NewStatisticsSettings } from './newStatisticsSetting'
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';


export const Statistics_3 = ({ selected }) => {

    
    const { 
        actions: { setProp },
          connectors: {
            connect
          } 
      } = useNode();
    
      
      const {
        enabled,
      } = useEditor((state, query) => ({
        enabled: state.options.enabled,
     
      }));

    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[#FFFDFB] py-20'>
            <Wrapper canvas id='random-id-401' is='container' className='container flex flex-col'>
                <Wrapper canvas id='random-id-402' is='div' className='w-[100%] flex flex-col justify-center text-center'>
                    <Wrapper style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} className='text-[#100103] my-6' id='random-id-1' is='div'>
                        <Text text='Trusted by our partners' />
                    </Wrapper>

                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Rubik', color: '#100103' }} className='w-auto px-20 mx-auto mb-6' id='random-id-2' is='div'>
                        <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                    </Wrapper>

                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-403' is='wrapper' className='w-full flex flex-row flex-wrap justify-center'>
                <Wrapper canvas id='random-id-404' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{ border: '1px solid #DBD9D9', flex:'20%' }}>

                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Rubik', color: '#B20312' }} id='random-id-3' is='div'>
                        <Text text='1,524' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Rubik', color: '#100103' }} id='random-id-4' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>

                </Wrapper>
                <Wrapper canvas id='random-id-405' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{ border: '1px solid #DBD9D9', flex:'20%' }}>

                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Rubik', color: '#B20312' }} id='random-id-5' is='div'>
                        <Text text='624' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Rubik', color: '#100103' }} id='random-id-6' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>

                </Wrapper>
                <Wrapper canvas id='random-id-406' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{ border: '1px solid #DBD9D9', flex:'20%' }}>

                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Rubik', color: '#B20312' }} id='random-id-7' is='div'>
                        <Text text='10' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Rubik', color: '#100103' }} id='random-id-8' is='div'>
                        <Text text='total Sales' />
                    </Wrapper>

                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
        ,
        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-407' is='parent' className='w-[100%] h-full bg-[#121826] py-20'>
            <Wrapper canvas id='random-id-408' is='container' className='container flex flex-col'>
                <Wrapper canvas id='random-id-409' is='div' className='w-[100%] flex flex-col justify-center text-center'>
                    <Wrapper style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} className='IncreaseConversion2 my-6' id='random-id-1' is='div'>
                        <Text text='Trusted by our partners' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFF' }} className='w-auto px-20 mx-auto mb-6' id='random-id-2' is='div'>
                        <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-410' is='wrapper' className='w-full flex flex-row flex-wrap justify-center'>
                <Wrapper canvas id='random-id-411' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #FFFFFF1A', boxShadow: '0px 0px 4px 0px #FFFFFF1A' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Rubik', color: '#4BDBA2' }} id='random-id-3' is='div'>
                        <Text text='1,524' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFF' }} id='random-id-4' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-412' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #FFFFFF1A', boxShadow: '0px 0px 4px 0px #FFFFFF1A' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Rubik', color: '#4BDBA2' }} id='random-id-5' is='div'>
                        <Text text='624' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFF' }} id='random-id-6' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-413' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #FFFFFF1A', boxShadow: '0px 0px 4px 0px #FFFFFF1A' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Rubik', color: '#4BDBA2' }} id='random-id-7' is='div'>
                        <Text text='10' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFF' }} id='random-id-8' is='div'>
                        <Text text='total Sales' />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-414' is='parent' className='w-[100%] h-full bg-[#213156] py-20'>
            <Wrapper canvas id='random-id-415' is='container' className='container flex flex-col'>
                <Wrapper canvas id='random-id-416' is='div' className='w-[100%] flex flex-col justify-center text-center'>
                    <Wrapper style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Times New Roman' }} className='text-[#EA6EF4] my-6' id='random-id-9' is='div'>
                        <Text text='Trusted by our partners' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#FFF' }} className='w-auto px-20 mx-auto mb-6' id='random-id-10' is='div'>
                        <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-417' is='wrapper' className='w-full flex flex-row flex-wrap justify-center'>
                <Wrapper canvas id='random-id-418' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #EA6EF40A', borderRadius: '10px', boxShadow: '0px 0px 10px 0px #FFFFFF1A' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Open Sans', color: '#EA6EF4' }} id='random-id-11' is='div'>
                        <Text text='1,524' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#FFF' }} id='random-id-12' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-419' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #EA6EF40A', borderRadius: '10px', boxShadow: '0px 0px 10px 0px #FFFFFF1A' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Open Sans', color: '#EA6EF4' }} id='random-id-13' is='div'>
                        <Text text='624' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#FFF' }} id='random-id-14' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-420' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #EA6EF40A', borderRadius: '10px', boxShadow: '0px 0px 10px 0px #FFFFFF1A' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Open Sans', color: '#EA6EF4' }} id='random-id-15' is='div'>
                        <Text text='10' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Open Sans', color: '#FFF' }} id='random-id-16' is='div'>
                        <Text text='total Sales' />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-421' is='parent' className='w-[100%] h-full bg-[#DFFFF9] py-20'>
            <Wrapper canvas id='random-id-422' is='container' className='container flex flex-col'>
                <Wrapper canvas id='random-id-423' is='div' className='w-[100%] flex flex-col justify-center text-center'>
                    <Wrapper style={{ display: 'inline-block', fontSize: '46px', fontWeight: '100px', fontFamily: 'Trusted by our partners', fontStyle: 'italic' }} className='text-[#31A993] my-6' id='random-id-17' is='div'>
                        <Text text='Trusted by our partners' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Montserrat', color: '#031815' }} className='w-auto px-20 mx-auto mb-6' id='random-id-18' is='div'>
                        <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-424' is='wrapper' className='w-full flex flex-row flex-wrap justify-center'>
                <Wrapper canvas id='random-id-425' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #31A9931A', borderRadius: '30px', boxShadow: '0px 0px 10px 0px #0825201A' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Montserrat', color: '#31A993' }} id='random-id-19' is='div'>
                        <Text text='1,524' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Montserrat', color: '#031815' }} id='random-id-20' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-426' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #31A9931A', borderRadius: '30px', boxShadow: '0px 0px 10px 0px #0825201A' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Montserrat', color: '#31A993' }} id='random-id-21' is='div'>
                        <Text text='624' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Montserrat', color: '#031815' }} id='random-id-22' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-427' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #31A9931A', borderRadius: '30px', boxShadow: '0px 0px 10px 0px #0825201A' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Montserrat', color: '#31A993' }} id='random-id-23' is='div'>
                        <Text text='10' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Montserrat', color: '#031815' }} id='random-id-24' is='div'>
                        <Text text='total Sales' />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-428' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-20'>
            <Wrapper canvas id='random-id-429' is='container' className='container flex flex-col'>
                <Wrapper canvas id='random-id-430' is='div' className='w-[100%] flex flex-col justify-center text-center'>
                    <Wrapper style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Inter' }} className='text-[#0078D3] my-6' id='random-id-25' is='div'>
                        <Text text='Trusted by our partners' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Inter', color: '#020212' }} className='w-auto px-20 mx-auto mb-6' id='random-id-26' is='div'>
                        <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-431' is='wrapper' className='w-full flex flex-row flex-wrap justify-center'>
                <Wrapper canvas id='random-id-432' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #DBD9D9', borderRadius: '4px' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Inter', color: '#0078D3' }} id='random-id-27' is='div'>
                        <Text text='1,524' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Inter', color: '#020212' }} id='random-id-28' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-433' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #DBD9D9', borderRadius: '4px' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Inter', color: '#0078D3' }} id='random-id-29' is='div'>
                        <Text text='624' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Inter', color: '#020212' }} id='random-id-30' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-434' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #DBD9D9', borderRadius: '4px' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Inter', color: '#0078D3' }} id='random-id-31' is='div'>
                        <Text text='10' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Inter', color: '#020212' }} id='random-id-32' is='div'>
                        <Text text='total Sales' />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-435' is='parent' className='w-[100%] h-full bg-[#FFFFFF] py-20'>
            <Wrapper canvas id='random-id-436' is='container' className='container flex flex-col'>
                <Wrapper canvas id='random-id-437' is='div' className='w-[100%] flex flex-col justify-center text-center'>
                    <Wrapper style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Voces' }} className='text-[#020212] my-6' id='random-id-33' is='div'>
                        <Text text='Trusted by our partners' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212' }} className='w-auto px-20 mx-auto mb-6' id='random-id-34' is='div'>
                        <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-438' is='wrapper' className='w-full flex flex-row flex-wrap justify-center'>
                <Wrapper canvas id='random-id-439' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #0202121A', borderRadius: '4px' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Voces', color: '#020212' }} id='random-id-35' is='div'>
                        <Text text='1,524' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212' }} id='random-id-36' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-440' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #0202121A', borderRadius: '4px' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Voces', color: '#020212' }} id='random-id-37' is='div'>
                        <Text text='624' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212' }} id='random-id-38' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-441' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #0202121A', borderRadius: '4px' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Voces', color: '#020212' }} id='random-id-39' is='div'>
                        <Text text='10' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Voces', color: '#020212' }} id='random-id-40' is='div'>
                        <Text text='total Sales' />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,



        <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-442' is='parent' className='w-[100%] h-full bg-[#FBFAFF] py-20'>
            <Wrapper canvas id='random-id-443' is='container' className='container flex flex-col'>
                <Wrapper canvas id='random-id-444' is='div' className='w-[100%] flex flex-col justify-center text-center'>
                    <Wrapper style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Roboto' }} className='text-[#6750A4] my-6' id='random-id-41' is='div'>
                        <Text text='Trusted by our partners' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Roboto', color: '#031815' }} className='w-auto px-20 mx-auto mb-6' id='random-id-42' is='div'>
                        <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-445' is='Wrapper' className='w-full flex flex-row flex-wrap justify-center'>
                <Wrapper canvas id='random-id-446' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #D0BCFF66', boxShadow: '0px 0px 4px 0px #0825201A', borderRadius: '30px', background: '#FFFFFF' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Roboto', color: '#6750A4' }} id='random-id-43' is='div'>
                        <Text text='1,524' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Roboto', color: '#031815' }} id='random-id-44' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-447' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #D0BCFF66', boxShadow: '0px 0px 4px 0px #0825201A', borderRadius: '30px', background: '#FFFFFF' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Roboto', color: '#6750A4' }} id='random-id-45' is='div'>
                        <Text text='624' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Roboto', color: '#031815' }} id='random-id-46' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-448' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #D0BCFF66', boxShadow: '0px 0px 4px 0px #0825201A', borderRadius: '30px', background: '#FFFFFF' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Roboto', color: '#6750A4' }} id='random-id-47' is='div'>
                        <Text text='10' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Roboto', color: '#031815' }} id='random-id-48' is='div'>
                        <Text text='total Sales' />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,

        <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-449' is='parent' className='w-[100%] h-full bg-[#2A2A2A] py-20'>
            <Wrapper canvas id='random-id-450' is='container' className='container flex flex-col'>
                <Wrapper canvas id='random-id-451' is='div' className='w-[100%] flex flex-col justify-center text-center'>
                    <Wrapper style={{ display: 'inline-block', fontSize: '46px', fontWeight: '400px', fontFamily: 'Rum Raisin' }} className='text-[#FFD600] my-6' id='random-id-49' is='div'>
                        <Text text='Trusted by our partners' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFFFFF' }} className='w-auto px-20 mx-auto mb-6' id='random-id-50' is='div'>
                        <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-452' is='wrapper' className='w-full flex flex-row flex-wrap justify-center'>
                <Wrapper canvas id='random-id-453' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #FFD6001A', borderRadius: '10px', boxShadow: '0px 0px 10px 0px #FFFFFF0F' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFD600' }} id='random-id-51' is='div'>
                        <Text text='1,524' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFFFFF' }} id='random-id-52' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-454' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #FFD6001A', borderRadius: '10px', boxShadow: '0px 0px 10px 0px #FFFFFF0F' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFD600' }} id='random-id-53' is='div'>
                        <Text text='624' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFFFFF' }} id='random-id-54' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>
                </Wrapper>
                <Wrapper canvas id='random-id-455' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid #FFD6001A', borderRadius: '10px', boxShadow: '0px 0px 10px 0px #FFFFFF0F' }}>
                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#FFD600' }} id='random-id-55' is='div'>
                        <Text text='10' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Rubik', color: '#FFFFFF' }} id='random-id-56' is='div'>
                        <Text text='total Sales' />
                    </Wrapper>
                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>,


        <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-900' is='parent' className='w-[100%] h-full bg-[#F7FBFE] py-20'>
            <Wrapper canvas id='random-id-901' is='container' className='container flex flex-col'>
                <Wrapper canvas id='random-id-902' is='div' className='w-[100%] flex flex-col justify-center text-center'>
                    <Wrapper style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Rubik' }} className='text-[#6064D2] my-6' id='random-id-970' is='div'>
                        <Text text='Trusted by our partners' />
                    </Wrapper>

                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Inter', color: '#020212' }} className='w-auto px-20 mx-auto mb-6' id='random-id-971' is='div'>
                        <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                    </Wrapper>

                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-903' is='wrapper' className='w-full flex flex-row flex-wrap justify-center'>
                <Wrapper canvas id='random-id-904' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid rgba(96, 100, 210, 0.10)', borderRadius: '10px', background: '#FFF' }}>

                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Inter', color: '#B20312' }} id='random-id-972' is='div'>
                        <Text text='1,524' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Inter', color: '#020212' }} id='random-id-973' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>

                </Wrapper>
                <Wrapper canvas id='random-id-905' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid rgba(96, 100, 210, 0.10)', borderRadius: '10px', background: '#FFF' }}>

                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Inter', color: '#B20312' }} id='random-id-974' is='div'>
                        <Text text='624' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Inter', color: '#020212' }} id='random-id-975' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>

                </Wrapper>
                <Wrapper canvas id='random-id-906' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid rgba(96, 100, 210, 0.10)', borderRadius: '10px', background: '#FFF' }}>

                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Inter', color: '#B20312' }} id='random-id-976' is='div'>
                        <Text text='10' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Inter', color: '#020212' }} id='random-id-977' is='div'>
                        <Text text='total Sales' />
                    </Wrapper>

                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
        ,


        <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-950' is='parent' className='w-[100%] h-full bg-[#FEFBF7] py-20'>
            <Wrapper canvas id='random-id-951' is='container' className='container flex flex-col'>
                <Wrapper canvas id='random-id-952' is='div' className='w-[100%] flex flex-col justify-center text-center'>
                    <Wrapper style={{ display: 'inline-block', fontSize: '46px', fontWeight: 'bold', fontFamily: 'Ruluko' }} className='text-[#A25738] my-6' id='random-id-978' is='div'>
                        <Text text='Trusted by our partners' />
                    </Wrapper>

                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Inter', color: '#020212' }} className='w-auto px-20 mx-auto mb-6' id='random-id-979' is='div'>
                        <Text text='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium...' />
                    </Wrapper>

                </Wrapper>
            </Wrapper>
            <Wrapper canvas id='random-id-953' is='wrapper' className='w-full flex flex-row flex-wrap justify-center'>
                <Wrapper canvas id='random-id-954' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid rgba(162, 87, 56, 0.10)', borderRadius: '10px', background: '#FFF' }}>

                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Inter', color: '#A25738' }} id='random-id-980' is='div'>
                        <Text text='1,524' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Inter', color: '#020212' }} id='random-id-981' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>

                </Wrapper>
                <Wrapper canvas id='random-id-955' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid rgba(162, 87, 56, 0.10)', borderRadius: '10px', background: '#FFF' }}>

                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Inter', color: '#A25738' }} id='random-id-982' is='div'>
                        <Text text='624' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Inter', color: '#020212' }} id='random-id-983' is='div'>
                        <Text text='Total Sales' />
                    </Wrapper>

                </Wrapper>
                <Wrapper canvas id='random-id-956' is='div' className='min-w-[300px] max-w-[350px] h-[160px] flex flex-col justify-center text-center m-4' style={{flex:'20%', border: '1px solid rgba(162, 87, 56, 0.10)', borderRadius: '10px', background: '#FFF' }}>

                    <Wrapper style={{ display: 'inline-block', fontSize: '52px', fontWeight: '700px', fontFamily: 'Inter', color: '#A25738' }} id='random-id-984' is='div'>
                        <Text text='10' />
                    </Wrapper>
                    <Wrapper style={{ display: 'inline-block', fontSize: '24px', fontWeight: '400px', fontFamily: 'Inter', color: '#020212' }} id='random-id-985' is='div'>
                        <Text text='total Sales' />
                    </Wrapper>

                </Wrapper>
            </Wrapper>
        </Wrapper></Element>
        </Element>
    ]

    const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])


    return (
        <div style={{ padding:enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
       {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
    </div>
    );
}

Statistics_3.craft = {
    displayName: "Statistics 3",
    props: {
        selected: 1, // here is the default value of the selected style
        length:9,
        isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewStatisticsSettings,
    },
}