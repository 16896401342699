/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useRef, useState } from 'react';
import './land.css'
import Header from './header';
import Banner from './banner';
import Benefits from './benefits'
import Perks from './perks';
import WhoWe from './whoWe';
import Cards from './banner/cards';
import Team from './team';
import Priring from './pricing';
import Contact from './contact';
import Footer from './footer';

export default function Land () {
    const [selectedItem, setSelectedItem] = useState('home');

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      };
    
  const featuresRef = useRef(null);
  const howItWorksRef = useRef(null);
  const pricingRef = useRef(null);
  const homeRef = useRef(null);

  const items = [
    { ref: homeRef, id: 'homeItem' },
    { ref: featuresRef, id: 'featuresItem' },
    { ref: howItWorksRef, id: 'howItWorksItem' },
    { ref: pricingRef, id: 'pricingItem' },
  ];

  useEffect(() => {
      return () => {
        setSelectedItem(homeRef);
      };
    }, []);

  const handleItemClick = (ref) => {
     
      setSelectedItem(ref);
      items.forEach((item) => {
        document.getElementById(item.id).style.color = item.ref === ref ? '#6064D2' : 'inherit';
      });
    };


    return (
        <div>
            <div className='w-full h-screen overflow-scroll bg-[#fff]'>
                <div ref={homeRef} className='bg-[#F7FBFE] pt-[8px] pb-10'>
                    <Header scrollToRef={scrollToRef} selectedItem={selectedItem} setSelectedItem={setSelectedItem} handleItemClick={handleItemClick} homeRef={homeRef} featuresRef={featuresRef} howItWorksRef={howItWorksRef} pricingRef={pricingRef}/>
                    <Banner/>
                    <Cards/>
                </div>
                <div ref={howItWorksRef}>
                <Benefits/>
                </div>
                <div ref={featuresRef}>
                <Perks/>
                </div>
                <div>
                    <WhoWe/>
                </div>
                <div>
                    <Team/>
                </div>
                <div ref={pricingRef}>
                    <Priring/>
                </div>
                <div>
                    <Contact/>
                </div>
                <div>
                    <Footer scrollToRef={scrollToRef} selectedItem={selectedItem} setSelectedItem={setSelectedItem} handleItemClick={handleItemClick} homeRef={homeRef} featuresRef={featuresRef} howItWorksRef={howItWorksRef} pricingRef={pricingRef}/>
                </div>
            </div>
        </div>
    )
};

