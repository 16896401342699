/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { PivotGrid, PivotGridContainer, PivotGridConfigurator, usePivotOLAPService, PivotGridConfiguratorButton } from '@progress/kendo-react-pivotgrid';
import { Loader } from '@progress/kendo-react-indicators';
import { WideColumn } from './wide-column';
const defaultColumnAxes = [{
  name: ['[Date].[Calendar]'],
  expand: true
}, {
  name: ['[Product].[Category]']
}];
const defaultRowAxes = [{
  name: ['[Geography].[City]']
}];
const defaultMeasureAxes = [{
  name: ['[Measures].[Reseller Freight Cost]']
}];
const catalog = 'Adventure Works DW 2008R2';
const cube = 'Adventure Works';
const url = 'https://demos.telerik.com/olap/msmdpump.dll';
export const Pivot_Widget = () => {
  const [show, setShow] = React.useState(false);
  const {
    pivotProps,
    configuratorProps,
    state
  } = usePivotOLAPService({
    catalog,
    cube,
    url,
    defaultRowAxes,
    defaultColumnAxes,
    defaultMeasureAxes
  });
  const handleButtonClick = React.useCallback(() => {
    setShow(!show);
  }, [show]);
  return <PivotGridContainer>
      <PivotGrid {...pivotProps} style={{
      height: 700
    }} column={WideColumn} />
      {show && <PivotGridConfigurator {...configuratorProps} />}
      <PivotGridConfiguratorButton onClick={handleButtonClick} />
      {state.loading && <Loader style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)'
    }} size="large" type={'converging-spinner'} />}
    </PivotGridContainer>;
};
