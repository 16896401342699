import React, { useState } from 'react';

const Dropdown = ({ onOptionSelect }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [displayText, setDisplayText] = useState('');

  const handleOptionChange = (e, option) => {
    e.stopPropagation()

    setSelectedOption(option);
    setInputValue('');
    setDisplayText('');
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setDisplayText(inputValue);
      setInputValue('');
    }
  };

  const handleSelect = (e) => {
    e.stopPropagation()
    let content;
    if (selectedOption === 'text') {
      content = displayText ? <p>{displayText}</p> : null;
    } else if (selectedOption === 'button') {
      content = <button style={styles.styledButton}>Styled Button</button>;
    } else if (selectedOption === 'image') {
      content = <img src="https://file.rendit.io/n/hgQeGwikg5Hh6qeQRkUN.svg" alt="Placeholder" style={styles.image} />;
    }
    onOptionSelect(content);
  };

  return (
    <div style={styles.dropdownContainer}>
      <div style={styles.dropdownButtonContainer}>
        <button onClick={(e) => handleOptionChange(e, 'text')} style={styles.optionButton}>
          Text
        </button>
        <button onClick={(e) => handleOptionChange(e, 'button')} style={styles.optionButton}>
          Button
        </button>
        <button onClick={(e) => handleOptionChange(e, 'image')} style={styles.optionButton}>
          Image
        </button>
      </div>
      <div style={styles.dropdownContent}>
        {selectedOption === 'text' && (
          <>
            {displayText ? (
              <p>{displayText}</p>
            ) : (
              <input
                type="text"
                className='text-[#000]'
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                placeholder="Enter text and press Enter"
                style={styles.input}
              />
            )}
          </>
        )}
        {selectedOption && (
          <button onClick={(e) => handleSelect(e)} style={styles.selectButton}>Select</button>
        )}
      </div>
    </div>
  );
};

const styles = {
  dropdownContainer: {
    position: 'relative',
    display: 'inline-block',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '10px',
  },
  dropdownButtonContainer: {
    display: 'flex',
    gap: '10px',
    marginBottom: '10px',
  },
  optionButton: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  dropdownContent: {
    display: 'block',
  },
  input: {
    width: '100%',
    padding: '8px',
    fontSize: '14px',
  },
  styledButton: {
    display: 'block',
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  selectButton: {
    display: 'block',
    marginTop: '10px',
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  }
};

export default Dropdown;
