/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import { UndoIcon } from '../../../Viewport/EditorSvg';




export const DropDown = ({
    has,
    isDarkMode,
    property,
    el,
    setProp,
    node,
    propKey,
    options
}) => {

    const textTransform = ['uppercase', 'lowercase', 'capitalize']

    const textDecoration = ['underline', 'overline', 'line-through', 'blink']

    const textAlign = ['left', 'center', 'right']

    const fontWegiht = ['normal', 'bold', 'bolder', 'lighter']

    const fontFamily = ['Rubik', 'Open Sans', 'Inter', 'Roboto', 'Voces', 'Poppins', 'Sansation', 'Prompt', 'Almarai', 'Chivo',]

    const handleSliderChange = (el, has, newValue) => {
        setProp(node.id, (props) => {
          props[propKey][el][has].value = `${newValue}px`;
        });
      };

      const handleInputChange = (el, has, newValue) => {
        setProp(node.id, (props) => {
          props[propKey][el][has].value = `${newValue}px`;
        });
      };
      
      const handleReset = (el, has) => {
        setProp(node.id, (props) => {
          props[propKey][el][has].value = null;
        });
      };

    return (
        <div
            className='w-[100%] flex flex-column wrapper-slide justify-between my-[2vmin] rounded-[2vmin]'>
            <div className={` items-center mb-[1vmin] flex justify-start text-left w-[fit-content]`}
                style={{ fontSize: '2.2vmin', fontWeight: '500', fontFamily: 'Inter' }}>{has}</div>
            <div className='px-[1.5Vmin] mx-auto flex flex-row justify-between flex-wrap' style={{ width: '100%' }}>
            <select
              style={{
                color: isDarkMode ? '#fff' : '#000',
                fontFamily: 'Inter',
                fontWeight: '500',
                padding: '.5Vmin 1Vmin',
                fontSize: '2vmin',
                border: isDarkMode ? '.25vmin solid #e9e9e930' : '.25Vmin solid #E9E9EA',
                borderRadius: '2.5Vmin',
                backgroundColor: 'transparent',
                margin: '.5vmin 0',
                lineHeight: '1.2em',
                // transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
              }}

              value={property.value || ''}
              onChange={(e) => setProp(node.id, (props) => {
                let newValue = e.target.value
                props[propKey][el][has].value = newValue
              })}
            >
              <option value="">default</option>
                {has === 'textTransform' ? textTransform.map((textTr) => (
                        <option
                        style={{
                          fontFamily: 'Inter',
                          fontWeight: '400',
                          fontSize: '2.2Vmin',
                          color: 'black',
                          lineHeight: '2%',
                        }}
                        key={textTr} value={textTr}>
                            {textTr}
                      </option>
                )) : has === 'textAlign' ? textAlign.map((textAl) => (
                    <option
                        style={{
                          fontFamily: 'Inter',
                          fontWeight: '400',
                          fontSize: '2.2Vmin',
                          color: 'black',
                          lineHeight: '2%',
                        }}
                        key={textAl} value={textAl}>
                            {textAl}
                      </option>
                )) : has === 'fontFamily' ? fontFamily.map((fontFa) => (
                    <option
                        style={{
                          fontFamily: 'Inter',
                          fontWeight: '400',
                          fontSize: '2.2Vmin',
                          color: 'black',
                          lineHeight: '2%',
                        }}
                        key={fontFa} value={fontFa}>
                            {fontFa}
                      </option>
                )) : has === 'textDecoration' ? textDecoration.map((textDec) => (
                    <option
                        style={{
                          fontFamily: 'Inter',
                          fontWeight: '400',
                          fontSize: '2.2Vmin',
                          color: 'black',
                          lineHeight: '2%',
                        }}
                        key={textDec} value={textDec}>
                            {textDec}
                      </option>
                )) : has === 'fontWeight' ? fontWegiht.map((fontWe) => (
                    <option
                        style={{
                          fontFamily: 'Inter',
                          fontWeight: '400',
                          fontSize: '2.2Vmin',
                          color: 'black',
                          lineHeight: '2%',
                        }}
                        key={fontWe} value={fontWe}>
                            {fontWe}
                      </option>
                )) : null}
            </select>
                {/* <SliderStyled
                    className='mx-auto my-auto justify-center flex flex-1 max-w-[70%] items-center'
                    style={{ border: '.25vmin solid transparent' }}
                    min={0}
                    max={1000}
                    value={parseInt(property.value) || 0}
                    onChange={(e) => {
                        let newValue = e.target.value
                        handleSliderChange(el, has, newValue)
                      }}
                    // onChange={
                    //     ((_, value) => {
                    //         let numberValue = value || propValue?.split(" ")[0]
                    //         let unitValue = propValue?.split(" ")[1] || 'px'

                    //         let finalvalue = [numberValue, unitValue]

                    //         let valueWithUnit = activeState === 'Hover' ? finalvalue.join("") : finalvalue.join(" ")

                    //         console.log(valueWithUnit)
                           
                    //         setProp(node.id, (props) => {
                    //             if ( node?.data?.props["isWrapper"] && !props.actionSelect[propKey]) {
                    //                 props.actionSelect[propKey] = {};
                    //               }
                                  
                    //               if (activeState == "Default") { 
                    //                 props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                    //               } else if (activeState == "Hover") { 
                    //                 props.actionSelect[propKey].hover = onChange ? onChange(valueWithUnit) : valueWithUnit;
                    //               } else if (activeState == "Scroll") { 
                    //                 props.actionSelect[propKey].scroll = onChange ? onChange(valueWithUnit) : valueWithUnit;
                    //               } else if (Array.isArray(propValue)) {
                    //                 props[propKey][index] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                    //               } else {
                    //                 props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                    //               }

                    //         }, 1000)
                    //     })
                    // }

                />
                <div className='value-props shadow-md p-[.5vmin] rounded-[2.5vmin]' style={{ color: isDarkMode ? '#fff' : '#000' }}>
                    <div className='my-auto mx-auto px-[1vmin] text-center flex-1 text-center' style={{ justifyContent: 'center', minWidth: '5vmin' }}>
                        <input style={{ color: isDarkMode ? '#fff' : '#000', fontSize: '2vmin', textAlign: 'center' }}
                        value={parseInt(property.value) || 0}

                            placeholder='0' 
                            onChange={(e) => {
                                let newValue = e.target.value
                                handleInputChange(el, has, newValue)
                            }}
                            // onChange={ 
                            //     ((e, value) => {
                            //         value = e.target.value
                            //         let numberValue = value || propValue?.split(" ")[0]
                            //         let unitValue = propValue?.split(" ")[1] || "px"
                            //         let finalvalue = [numberValue, unitValue]
                            //         let valueWithUnit = finalvalue.join(" ")
                            //         setProp(node.id, (props) => {
                            //             if (!props.actionSelect[propKey]) {
                            //                 props.actionSelect[propKey] = {};
                            //               }
                                          
                            //               if (activeState == "Default") { 
                            //                 props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //               } else if (activeState == "Hover") { 
                            //                 props.actionSelect[propKey].hover = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //               } else if (activeState == "Scroll") { 
                            //                 props.actionSelect[propKey].scroll = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //               } else if (Array.isArray(propValue)) {
                            //                 props[propKey][index] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //               } else {
                            //                 props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //               }
        
                            //         }, 1000)
                            //     })
                            // }
                        />
                    </div>

                    <div className='flex flex-1 cursor-pointer flex-row' onClick={() => handleReset(el, has)}>
                        <UndoIcon fill={`${(options[propKey][el][has].value && options[propKey][el][has].value !== null) ? '#0867FC' : '#989191'}`} />
                        <div className={`${(options[propKey][el][has].value && options[propKey][el][has].value !== null)  ? 'text-[#0867FC]' : 'text-[#989191]'} text-[2vmin] my-auto flex`} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Reset</div>
                    </div>

                    {/* <div className='w-full h-full flex-1 flex'>
                        <select
                            value={0}
                            // onChange={(e) => {
                            //     const newValue = e.target.value;

                            //     let numberValue = propValue?.split(" ")[0]
                            //     let unitValue = newValue

                            //     let finalvalue = [numberValue, unitValue]

                            //     let valueWithUnit = finalvalue.join(" ")


                            //     setProp(node.id, (props) => {
                            //         props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //     });
                            // }}
                        >
                            {unitOptions?.map((option) => (
                                <option
                                    style={{
                                        fontFamily: 'Inter',
                                        fontWeight: '400',
                                        fontSize: '2.2Vmin',
                                        color: 'black',
                                    }}
                                    key={option} value={option}>
                                    {option}
                                </option>
                            ))} 
                        </select>
                    </div>
                </div>
                 */}
            </div>
        </div>
    )
}