import React, { useState, useMemo } from 'react';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { Button } from '@progress/kendo-react-buttons';
import { useNode, useEditor } from '@craftjs/core';
import { NotifySettings } from './NotifySettings';

export const BasicNotify = ({
    style = {},
    text = 'Click here',
    href,
    color = '#000',
    width,
    height,
    background,
    fontSize = '16px',
    borderColor,
    borderWidth,
    borderRadius,
    textAlign,
    shadowX,
    shadowY,
    shadowBlur,
    shadowColor,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,

   paddingTop,
   paddingRight,
   paddingBottom,
   paddingLeft,
   margin,
   padding,

fontWeight,
fontStyle,
textDecoration,
textTransform,
fontFamily,
lineHeight,
    isNotify,
}) => {
    const {
        connectors: { connect },
        actions: { setProp },
    } = useNode();

    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));

    
  const handleProperty = (value, propertyName, split) => {
    if (value !== null && !split) {
      return { [propertyName]: value?.split(" ").join("") }
    }

    if (value !== null && split === 'splitBg') {
      return { [propertyName]:  value !== null ? value : '' }
    } else {
      return;
    }
  };


    const styleProps = useMemo(() => ({
      ...(color !== null && {
        color: color?.startsWith('#') ? color : 'transparent',
        backgroundImage: !color?.startsWith('#') ? color : undefined,
        WebkitBackgroundClip: !color?.startsWith('#') ? 'text' : undefined,
        backgroundClip: !color?.startsWith('#') ? 'text' : undefined,
      }),

        boxShadow: `${(shadowX?.split(" ").join(""))} ${(shadowY?.split(" ").join(""))} ${(shadowBlur?.split(" ").join(""))} ${shadowColor || `rgba(255, 255, 255)`}`
    ,
    
    ...handleProperty(width, 'width'),
    ...handleProperty(height, 'height'),

    ...handleProperty(background, 'background', 'splitBg'),

    ...handleProperty(fontSize, 'fontSize'),
    ...handleProperty(textTransform, 'textTransform'),
    ...handleProperty(marginTop, 'marginTop'),
    ...handleProperty(marginRight, 'marginRight'),
    ...handleProperty(marginBottom, 'marginBottom'),
    ...handleProperty(marginLeft, 'marginLeft'),

    ...handleProperty(paddingTop, 'paddingTop'),
    ...handleProperty(paddingRight, 'paddingRight'),
    ...handleProperty(paddingBottom, 'paddingBottom'),
    ...handleProperty(paddingLeft, 'paddingLeft'),

    ...handleProperty(padding, 'padding'),
    ...handleProperty(margin, 'margin'),


    ...handleProperty(fontFamily, 'fontFamily'),
    ...handleProperty(textDecoration, 'textDecoration'),
    ...handleProperty(fontStyle, 'fontStyle'),
    ...handleProperty(textAlign, 'textAlign'),
    ...handleProperty(fontWeight, 'fontWeight'),
    ...handleProperty(lineHeight, 'lineHeight'),
    ...handleProperty(borderColor, 'borderColor'),
    ...handleProperty(borderWidth, 'borderWidth'),
    ...handleProperty(borderRadius, 'borderRadius'),

    }), [
      color, fontSize, fontWeight, width, height,
      shadowX,
      shadowY,
      shadowColor,
      shadowBlur, fontStyle, fontFamily, padding, margin, background, borderRadius, borderColor, borderWidth, lineHeight, textDecoration, textTransform, textAlign, marginTop, marginRight, marginBottom, marginLeft, paddingTop, paddingRight, paddingBottom, paddingLeft]);

  const [state, setState] = useState({
    success: false,
    error: false,
    warning: false,
    info: false,
    none: false
  });
  const onToggle = flag => setState({
    ...state,
    [flag]: !state[flag]
  });
  const {
    success,
    error,
    warning,
    info,
    none
  } = state;
  return (
  <>
       <div ref={connect} style={{ display: 'inline-block' }}>
      <div style={{...styleProps, ...style}} type="button" onClick={() => onToggle('success')}>
        {(success ? 'hide ' : 'show ') + 'Success'}
      </div>
      <div style={{...styleProps, ...style}} type="button" onClick={() => onToggle('error')}>
        {(error ? 'hide ' : 'show ') + 'Error'}
      </div>
      <div style={{...styleProps, ...style}} type="button" onClick={() => onToggle('warning')}>
        {(warning ? 'hide ' : 'show ') + 'Warning'}
      </div>
      <div style={{...styleProps, ...style}} type="button" onClick={() => onToggle('info')}>
        {(info ? 'hide ' : 'show ') + 'Info'}
      </div>
      <div style={{...styleProps, ...style}} type="button" onClick={() => onToggle('none')}>
        {(none ? 'hide ' : 'show ') + 'Unstyled'}
      </div>
      
      </div>

      <NotificationGroup style={{
      right: 0,
      bottom: 0,
      alignItems: 'flex-start',
      flexWrap: 'wrap-reverse'
    }}>
        <Fade>
          {success && <Notification type={{
          style: 'success',
          icon: true
        }} closable={true} onClose={() => setState({
          ...state,
          success: false
        })}>
            <span>Your data has been saved.</span>
          </Notification>}
        </Fade>
        <Fade>
          {error && <Notification type={{
          style: 'error',
          icon: true
        }} closable={true} onClose={() => setState({
          ...state,
          error: false
        })}>
            <span>Oops! Something went wrong ...</span>
          </Notification>}
        </Fade>
        <Fade>
          {warning && <Notification type={{
          style: 'warning',
          icon: true
        }} closable={true} onClose={() => setState({
          ...state,
          warning: false
        })}>
            <span>Your password will expire in 2 days!</span>
          </Notification>}
        </Fade>
        <Fade>
          {info && <Notification type={{
          style: 'info',
          icon: true
        }} closable={true} onClose={() => setState({
          ...state,
          info: false
        })}>
            <span>You have 1 new message!</span>
          </Notification>}
        </Fade>
        <Fade>
          {none && <Notification type={{
          style: 'none',
          icon: false
        }} closable={true} onClose={() => setState({
          ...state,
          none: false
        })} style={{
          overflow: 'visible'
        }}>
            <span>Hanna Moos likes your status.</span>
          </Notification>}
        </Fade>
      </NotificationGroup>
    </>);
};


BasicNotify.craft = {
    displayName: 'Notification',
    props: {
        text: 'Click here',
        href: null,
        color: '#000',
        width:null,
        height:null,
        background:null,
        fontSize: '16px',
        shadowX: null,
        shadowY: null,
        shadowColor:null,
        shadowBlur: null,
        marginTop: null,
        marginRight: null,
        marginBottom: null,
        marginLeft: null,

       paddingTop: null,
       paddingRight: null,
       paddingBottom: null,
       paddingLeft: null,
       padding:null,
       margin:null,

  borderRadius: null,
        background:null,
        borderColor:null,
        borderWidth:null,
        textAlign:null,
        fontWeight:null,
        textTransform:null,
        fontStyle:null,
        textDecoration:null,
        fontFamily:null,
        textTransform:null,
        lineHeight:null,
        isNotify: true,
    },
    related: {
        toolbar: NotifySettings,
    },
};
