import { FormControlLabel, Radio } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';

const useStyles = makeStyles({
  icon: {
    borderRadius: '100%',
    minWidth: '.5rem', // Adjusted for better visibility
    minHeight: '.5rem',
    margin:0,
    background: 'transparent',
    position: 'relative',
    border: '0.2vmin solid #0867FC60',
    transition: 'border-color 0.3s ease, background 0.3s ease',
    '&:hover': {
      borderColor: '#0867FC', // Change border color on hover
    },
  },
  checkedIcon: {
    background: 'white',
    borderColor: 'rgb(19, 115, 230)',

    '&:before': {
      content: '""',
      width: '100%',
      height: '100%',
      borderRadius: '100%',
      background: '#000000',
      transition: 'transform 0.3s ease', // Smooth scaling effect
      transform: 'scale(0.85)', // Slightly smaller when checked
    },
  },
  
});

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      disableRipple 
      color="default"
      checkedIcon={
        <span className={classnames(classes.icon, classes.checkedIcon)}>
          <span className={classnames(classes.mark, { visible: props.checked })} />
        </span>
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const useLabelStyles = makeStyles({
  label: ({ selected }) => ({
    fontSize: '1.3Vmin',
    fontFamily: 'Inter',
    fontWeight: '300',
    color: selected ? '#0867FC' : '#0867FC60', // Black when selected, otherwise the default color
    letterSpacing: '1px',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: selected ? 'black' : '#0867FC', // Change color on hover
    },
  }),
});

export const ToolbarRadio = ({ selected, value, label }) => {
  const classes = useLabelStyles({ label, value, selected }); // Pass selected state
  
  return (
    <FormControlLabel
      className='flex rounded-full justify-start items-center shadow-md lg:flex-nowrap w-full md:flex-wrap sm:flex-wrap'
      style={{ border: '.25vmin solid #0867FC60', position: 'relative' }}
      checked={selected}
      classes={{ label: classes.label }}
      value={value}
      control={<StyledRadio checked={selected} />}
      label={label}
    />
  );
};

// Example usage:
// <ToolbarRadio
//   selected={selectedValue === 'option1'}
//   value='option1'
//   label='Option 1'
// />
