/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { CloudIcon, CloudIcon2 } from '../Svg'
import './perks.css'
export default function Perks() {
    return (
        <div className="w-full h-full sm:container sm:mx-auto">
            <div className='perks items-center'>
                <div className="header-perks mb-14">What are <span>the perks?</span></div>
                <div className='perks-options'>
                    <div className='option-perks'>
                        <div><CloudIcon/></div>
                        <div className='perks-content'>Effortless Integration</div>
                        <div className='md:hidden content-hidden'>No need for extensive coding or database management skills. Our platform simplifies the process, allowing you to effortlessly connect your web pages to databases.</div>
                    </div>
                    <div className='option-perks'>
                        <div><CloudIcon2/></div>
                        <div className='perks-content'>Dynamic Content Management</div>
                        <div className='md:hidden content-hidden'>Bring your web pages to life with dynamic content that adapts to user interactions. All You Can Cloud's intuitive database connection empowers you to manage and display information dynamically.</div>
                    </div>
                    <div className='option-perks'>
                        <div><CloudIcon/></div>
                        <div className='perks-content'>Effortless Integration</div>
                        <div className='md:hidden content-hidden'>Forget about the complexities of database management. With All You Can Cloud, connecting databases is straightforward, making it accessible to users with varying levels of technical expertise.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}