/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Link } from 'react-router-dom'
import './header.css'
import { AppIcon, CloseIcon, Menu } from '../Svg'
import { useEffect, useState } from 'react';
export default function Header({scrollToRef, featuresRef, pricingRef, howItWorksRef, homeRef, handleItemClick, selectedItem, setSelectedItem}) {

    const [open, setOpen] = useState(false)

    const items = [
      { ref: homeRef, id: 'homeItem' },
      { ref: featuresRef, id: 'featuresItem' },
      { ref: howItWorksRef, id: 'howItWorksItem' },
      { ref: pricingRef, id: 'pricingItem' },
    ];

    useEffect(() => {
        return () => {
          setSelectedItem(homeRef);
        };
      }, []);

  
    return (
        <>
        <div className="w-[98%] header mx-auto">
            <div className="container">
                <div className="item-list w-full h-full flex flex-row justify-between items-center">
                    <div className="w-[50%] min-w-[400px] justify-start h-full flex flex-row justify-start" style={{justifyContent:'flex-start'}}>
                    <div className='item' id='homeItem' onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}>
                        <div style={{ textDecoration: 'none', color: selectedItem === homeRef ? '#6064D2' : 'inherit', fontWeight: selectedItem === homeRef ? '700' : '400' }}>Home</div>
                    </div>
                    
                    <div className='item' id='featuresItem' onClick={() => { scrollToRef(featuresRef); handleItemClick(featuresRef); }}>
                        <div style={{ textDecoration: 'none', color: selectedItem === featuresRef ? '#6064D2' : 'inherit', fontWeight: selectedItem === featuresRef ? '700' : '400' }}>Features</div>
                    </div>
                    
                    <div className='item' id='howItWorksItem' onClick={() => { scrollToRef(howItWorksRef); handleItemClick(howItWorksRef); }}>
                        <div style={{ textDecoration: 'none', color: selectedItem === howItWorksRef ? '#6064D2' : 'inherit', fontWeight: selectedItem === howItWorksRef ? '700' : '400' }}>How it works?</div>
                    </div>
                    
                    <div className='item' id='pricingItem' onClick={() => { scrollToRef(pricingRef); handleItemClick(pricingRef); }}>
                        <div style={{ textDecoration: 'none', color: selectedItem === pricingRef ? '#6064D2' : 'inherit', fontWeight: selectedItem === pricingRef ? '700' : '400' }}>Pricing</div>
                    </div>
                    </div>
                    <div className='w-[20%] app-header justify-center flex-auto'>
                        <AppIcon/>
                        <div className='menu cursor-pointer' style={{justifyItems:'flex-end'}} onClick={() => setOpen(true)}><Menu/></div>
                    </div>
                    <div className="w-[30%] log flex flex-row justify-end">
                        <div className='log-in'>
                            Log in
                        </div>
                        <div className='sign-up'>
                            Sign up
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {open?
        <div className='menu-float' style={{zIndex:100}}>
        <div className='container mx-auto'>
        <div className='w-auto flex flex-col p-2'>
        <div className='mb-4 flex flex-row justify-between align-center'>
            <div className='my-auto'>
            <AppIcon/>
            </div>
            <div className='my-auto cursor-pointer' onClick={() => setOpen(false)}>
                <CloseIcon/>
            </div>
        </div>
        <div>
        <div className='my-4 item' id='homeItem' onClick={() => { scrollToRef(homeRef); handleItemClick(homeRef); }}>
            <div style={{ textDecoration: 'none', color: selectedItem === homeRef ? '#6064D2' : 'inherit', fontWeight: selectedItem === homeRef ? '700' : '400' }}>Home</div>
        </div>
        
        <div className='my-4 item' id='featuresItem' onClick={() => { scrollToRef(featuresRef); handleItemClick(featuresRef); }}>
            <div style={{ textDecoration: 'none', color: selectedItem === featuresRef ? '#6064D2' : 'inherit', fontWeight: selectedItem === featuresRef ? '700' : '400' }}>Features</div>
        </div>
        
        <div className='my-4 item' id='howItWorksItem' onClick={() => { scrollToRef(howItWorksRef); handleItemClick(howItWorksRef); }}>
            <div style={{ textDecoration: 'none', color: selectedItem === howItWorksRef ? '#6064D2' : 'inherit', fontWeight: selectedItem === howItWorksRef ? '700' : '400' }}>How it works?</div>
        </div>
        
        <div className='my-4 item' id='pricingItem' onClick={() => { scrollToRef(pricingRef); handleItemClick(pricingRef); }}>
            <div style={{ textDecoration: 'none', color: selectedItem === pricingRef ? '#6064D2' : 'inherit', fontWeight: selectedItem === pricingRef ? '700' : '400' }}>Pricing</div>
        </div>
        </div>
        <div className='mx-auto w-full justify-center flex my-4'>
        <div className="log flex flex-row justify-between">
            <div className='log-in'>
                Log in
            </div>
            <div className='sign-up'>
                Sign up
            </div>
        </div>
        </div>
    </div>
    </div>
    </div> : null}
        
    </>
    )
}