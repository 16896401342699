/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { Creation, GroupIcon, LearnIcon } from "../../Svg";
import './cards.css'
export default function Cards() {
    return (
        <div className="w-full h-full sm:container sm:mx-auto">
        <div className='cardss'>
        <div className='card'>
            <div className='flex-row flex'>
            <div><Creation/></div>
            <div className='card-h mx-2'>Intuitive Creation</div>
            </div>
            <div className='card-subheader'>Design web pages with our intuitive drag-and-drop interface. No need to delve into intricate frameworks, unleash your creativity effortlessly.</div>
        </div>
        <div className='card'>
            <div className='flex-row flex'>
            <div><LearnIcon/></div>
            <div className='card-h mx-2'>Accelerated Learning Curve</div>
            </div>
            <div className='card-subheader'>All You Can Cloud streamlines the page creation journey, allowing full focus on your content and placing you in the driver's seat.</div>
        </div>
        <div className='card'>
            <div className='flex-row flex'>
            <div><GroupIcon/></div>
            <div className='card-h mx-2'>Accessible to All Skill Levels</div>
            </div>
            <div className='card-subheader'>Whether you're a seasoned developer, a beginner, or a novice: our platform is designed for everyone.</div>
        </div>
        </div>
        </div>
    )
}
