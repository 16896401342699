/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';

import { Element, useEditor, useNode } from '@craftjs/core';
import { Parent } from '../../selectors/Parent/index.js';
import { ActionSheet_Widget } from '.';
import { Wrapper } from '../../selectors/wrapper/index.js';

export const ActionSheetWidget = () => {


  const {
    enabled,
    json,
  } = useEditor((state, query) => ({
    enabled: state.options.enabled,
    json : query.serialize()
  }));

  const { 
      connectors: {
        connect
      } 
  } = useNode();

  console.log(json.node)
  return (
    <div style={{ padding: enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
        <Element canvas is={Parent} id='parentId1900' className="w-[100%] h-[100%]" >
        <Element canvas is={Parent} id='parentId1' className="w-[100%] h-[100%]" row={true} >
        <Wrapper bg={true} canvas id='random-id-901' is={"div"} className='container' style={{maxWidth:"100%", height:'100%', padding:'1vmin 0'}}>
            <ActionSheet_Widget />
        </Wrapper>
        </Element>
        </Element>
    </div>
  );
};

ActionSheetWidget.craft = {
  displayName: "Action Sheet",
  props: {
    selected: 1, // here is the default value of the selected style
    iswidget: true,
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  // related: {
  //   toolbar: WidgetSettings,
  // },
};
