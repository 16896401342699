/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { capitalize, weightDescription } from '../Text/text';
import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import { TabOption } from '../../../editor/Viewport/Sidebar/TabOption';

export const QuoteSettings = () => {
  const [activeTab, setActiveTab] = useState("Style Manager");

  return (
    <div className='w-[100%] flex flex-col' style={{ display: "flex", height: "100%" }}>
    <ToolbarItem
          full={true}
          propKey="text"
          type="header"
          label="Text"
        />

    <div className='w-[100%]'>
      <TabOption activeTab={activeTab} setActiveTab={setActiveTab} />
    </div>

    {activeTab === "Navigation Manager" ? (
      <ToolbarSection
        expanded={true}

        title="Navigation"
      >
        <ToolbarItem
          full={true}
          propKey="href"
          type="select"
          label="href"
        />
      </ToolbarSection>
    ) : null}

    {activeTab === "Style Manager" ? (
      <div style={{ width: "100%" }}>
        <ToolbarSection
          expanded={true}
          title="Typography"
          props={['fontSize', 'fontWeight', 'textAlign']}
          summary={({ fontSize, fontWeight, textAlign }) => {
            return `${fontSize || ''}, ${fontWeight ? weightDescription(
              fontWeight
            ) : ""}, ${textAlign ? capitalize(textAlign) : ""}`;
          }}
        >
          <ToolbarItem
            full={true}
            propKey="fontSize"
            type="slider"
            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

            label="Font Size"
          />
          <ToolbarItem
            full={true}
            propKey="lineHeight"
            type="slider"
          unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}

            label="Line Height"
          />
          <ToolbarItem
            full={true}
            propKey="fontFamily"
            optionsDrop={['Rubik', 'Open Sans', 'Inter', 'Roboto', 'Voces', 'Poppins', 'Sansation', 'Prompt', 'Almarai', 'Chivo',]}
            type="select-drop"
            label="Font Family"
          />
          <ToolbarItem
            full={true}
            propKey="fontWeight"
            optionsDrop={['normal', 'bold', 'bolder', 'lighter']}
            type="select-drop"
            label="Font Weight"
          />
          <ToolbarItem
            full={true}
            propKey="textTransform"
            optionsDrop={['uppercase', 'lowercase', 'capitalize']}
            type="select-drop"
            label="Text Transform"
          />
          <ToolbarItem
            full={true}
            propKey="textDecoration"
            optionsDrop={['underline', 'overline', 'line-through', 'blink']}
            type="select-drop"
            label="Text Decoration"
          />
          <ToolbarItem
            full={true}
            propKey="textAlign"
            optionsDrop={['left', 'center', 'right']}
            type="select-drop"
            label="Text Align"
          />
        </ToolbarSection>

        <ToolbarSection
          expanded={true}
          title="Appearance"
          props={['color', 'shadow', 'transition']}
          summary={({ color, shadow, transition }) => {
            return (
              <div className="fletext-right">
                <p
                  style={{
                    border: '0.25Vmin solid red',
                    color: color && `rgba(${Object.values(color)})`,
                    textShadow: shadow && `0px 0px 2px rgba(0, 0, 0, ${shadow / 100})`,
                  }}
                  className="text-white text-right"
                >
                  T
                </p>
                <p>{transition}</p>
              </div>
            );
          }}
        >
          <ToolbarItem
            full={true}
            propKey="shadow"
            type="slider"
            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
            label="shadow"
          />
          <ToolbarItem full={true} propKey="color" type="bg" label="Color" />

        </ToolbarSection>

        <ToolbarSection
          expanded={true}
          title="margin"
          props={['margin']}
          summary={({ }) => {
            return;
          }}
        >
          <ToolbarItem propKey="marginTop" 
          unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
          type="slider" label="Top" />
          <ToolbarItem propKey="marginRight"
          unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
          type="slider" label="Right" />
          <ToolbarItem propKey="marginBottom"
          unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
          type="slider" label="Bottom" />
          <ToolbarItem propKey="marginLeft"
          unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
          type="slider" label="Left" />
        </ToolbarSection>
      </div>
    ) : null}


  </div>
  );
};