/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/

import { withStyles } from '@mui/styles';
import { useState } from 'react';
import { UndoIcon } from '../../Viewport/EditorSvg';
import { Slider as Small } from '@mui/material';


const SliderStyled = withStyles({
    root: {
        margin: 'auto auto',
        width: '33%',
        color: '#3880ff',
        height: '3vmin',
        padding: '5% 0',
    },
    thumb: {
        height: '2vmin',
        width: '2vmin',
        backgroundColor: '#0867FC',
    },

    active: {},
    valueLabel: {
        left: 'calc(-50% + 1.1%)',
        top: '-22%',
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },

    rail: {
        height: '2%',
        opselectedIconacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: '8%',
        width: '10%',
        marginTop: '-3%',
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Small);

export const SliderType = ({
    value,
    node,
    setProp,
    isDarkMode,
    propKey,
    unitOptions,
    value2,
    onChange,
    propValue,
    index,
    activeState,
    options,
    hoverV,
    scrollV,
    props,
    max,
}) => {

    const [currentUnit, setCurrentUnit] = useState('')
    const handleReset = (activeState) => {
        
        if (activeState === 'Hover') {
          setProp(node.id, (props) => {
            props['actionSelect'][propKey] = { hover: null, scroll: null };
            return { ...props };
          });           
        } else if (activeState === 'Default') {
          setProp(node.id, (props) => {
            props[propKey] = null;
            return { ...props };
          });
        } else {
            setProp(node.id, (props) => {
                props[propKey] = null; // Reset to the initial computed value or null
              });
              setCurrentUnit('px')
        }
      }

    return (
        <div
            className='w-[100%] py-[1.5vmin] border-b border-[#e9e9e930] m-[1vmin] flex flex-column wrapper-slide container justify-between  rounded-[2vmin]'>
            <div className={` items-center flex justify-start text-left w-[fit-content]`}
                style={{ fontSize: '1.8vmin', fontWeight: '500', fontFamily: 'Inter', color:'#FFF' }}>{props.label}</div>
            <div className=' mx-auto flex flex-row justify-between flex-wrap' style={{ width: '100%' }}>

                <div className='flex flex-row justify-around px-[1vmin] items-center w-full flex-1'>
                <SliderStyled
                onMouseDown={(e) => {
                        e.stopPropagation();  // Prevents dragging when interacting with the slider                  
                }}
                    className='mx-auto my-auto justify-center flex flex-1 max-w-[70%] items-center'
                    style={{ border: '.25vmin solid transparent', paddingLeft:'.5vmin' }}
                    min={props.label === 'Transform value' ? -100 : 1}
                    max={max || props.label === 'Transform value' ? 100 : 100}
                    value={
                        parseInt(value) || 0
                    }
                    onChange={(e) => {
                        const valueWithUnit = `${e.target.value}${currentUnit || 'px'}`; // Combine the slider value with the unit
                        
                        setProp(node.id, (props) => {
                            props[propKey] = valueWithUnit; // Update style with value and unit
                        });
                    }}

                />

                {activeState === 'Default' ? (
                        <div className=' items-center flex flex-1 cursor-pointer justify-center flex-row' onClick={() => handleReset('Default')}>
                        <UndoIcon width='2.5vmin' height='2.5vmin' fill={`${(value && value !== 0 && value !== null) ? '#0867FC' : '#FFFFFF'}`} />
                        <div className={`${(value && value !== 0 && value !== null) ? 'text-[#0867FC]' : 'text-[#FFFFFF]'} text-[2vmin] my-auto flex`} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Reset</div>
                    </div>
                    ) : activeState === 'Hover' ? (
                        <div className=' items-center flex flex-1 cursor-pointer justify-center flex-row' onClick={() => handleReset('Hover')}>
                        <UndoIcon width='2.5vmin' height='2.5vmin' fill={`${(hoverV && hoverV !== 0 && hoverV !== null) ? '#0867FC' : '#FFFFFF'}`} />
                        <div className={`${(hoverV && hoverV !== 0 && hoverV !== null) ? 'text-[#0867FC]' : 'text-[#FFFFFF]'} text-[2vmin] my-auto flex`} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Reset</div>
                    </div>
                    ) : activeState === 'Scroll'? (
                        <div className=' items-center flex flex-1 cursor-pointer justify-center flex-row' onClick={() => handleReset('Scroll')}>
                        <UndoIcon width='2.5vmin' height='2.5vmin' fill={`${(hoverV && hoverV !== 0 && hoverV !== null) ? '#0867FC' : '#FFFFFF'}`} />
                        <div className={`${(hoverV && hoverV !== 0 && hoverV !== null) ? 'text-[#0867FC]' : 'text-[#FFFFFF]'} text-[2vmin] my-auto flex`} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Reset</div>
                    </div>
                    ) :  <div className=' items-center flex flex-1 cursor-pointer justify-center flex-row' onClick={() => handleReset(propKey)}>
                    <UndoIcon width='2.5vmin' height='2.5vmin' fill={`${options[propKey] !== options[propKey] ? '#0867FC' : '#FFFFFF'}`} />
                    <div className={`${options[propKey] !== options[propKey] ? 'text-[#0867FC]' : 'text-[#FFFFFF]'} text-[2vmin] my-auto flex`} style={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Reset</div>
                </div>}

                </div>
                 

                <div className='value-props' >
                        <input style={{
                             alignContent: 'center',
                             alignItems: 'center',
                             display: 'flex',
                             padding: '.7vmin 1.4vmin',
                             fontFamily: 'Inter, sans-serif',
                             fontWeight: '400',
                             fontSize: '1.5vmin',
                             color: '#FFFFFF',
                             backgroundColor: 'transparent',
                             border: '.25vmin solid #FFFFFF',
                             borderRadius: '2vmin',
                             outline: 'none',
                            //  transition: 'border-color 0.2s ease, box-shadow 0.2s ease',
                        }}
                            value={
                                activeState == "Hover" ?
                                parseInt(hoverV) || 0 : activeState == "Scroll" ?
                                parseInt(scrollV) || 0 :
                                parseInt(value) || 0
                            }

                            placeholder='0' 
                            onFocus={(e) => {
                                e.target.style.borderColor = '#007bff';
                                e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
                            }}
                            onBlur={(e) => {
                                e.target.style.borderColor = '#ccc';
                                e.target.style.boxShadow = 'none';
                            }}
                            onChange={(e) => {
                                const numberValue = e.target.value; // Get the number from the input                                
                                // Combine the new number with the current unit
                                const updatedValueWithUnit = `${numberValue}${currentUnit || 'px'}`; 
                            
                                setProp(node.id, (props) => {
                                    props[propKey] = updatedValueWithUnit; // Update the style
                                });
                            }}
                            // onChange={ 
                            //     ((e, value) => {

                            //         value = e.target.value
                            //         let numberValue = value || propValue?.split(" ")[0]
                            //         let unitValue = propValue?.split("")[1] || 'px'
        
                            //         let finalvalue = [numberValue, unitValue]
        
                            //         let valueWithUnit = activeState === 'Hover' ? finalvalue.join("") : finalvalue.join(" ")
        
                            //         console.log(valueWithUnit)
                            //         setProp(node.id, (props) => {
                            //             props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit; // Update width directly in the style

                            //             //     if (node?.data?.props['actionSelect'] === undefined) {
                            //             //         props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //             //     } else {
                            //             //     if (!props.actionSelect[propKey]) {
                            //             //         props.actionSelect[propKey] = {};
                            //             //       }
                            //             //       if (activeState == "Default") { 
                            //             //         props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //             //       } else if (activeState == "Hover") { 
                            //             //         props.actionSelect[propKey].hover = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //             //       } else if (activeState == "Scroll") { 
                            //             //         props.actionSelect[propKey].scroll = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //             //       } else if (Array.isArray(propValue)) {
                            //             //         props[propKey][index] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //             //       } else {
                            //             //         props[propKey] = onChange ? onChange(valueWithUnit) : valueWithUnit;
                            //             //       }
                            //             // }
        
                            //         }, 1000)
                            //     })
                            // }
                        />

                 
                    <div className='w-full h-full flex-1 items-center flex'>
                    <select
                        style={{
                            alignContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            padding: '.7vmin 1.4vmin',
                            fontFamily: 'Inter, sans-serif',
                            fontWeight: '400',
                            fontSize: '1.5vmin',
                            color: '#FFFFFF',
                            backgroundColor: 'transparent',
                            border: '.25vmin solid #FFFFFF',
                            borderRadius: '2vmin',
                            outline: 'none',
                            // transition: 'border-color 0.2s ease, box-shadow 0.2s ease',
                            cursor: 'pointer',
                        }}
                        value={propValue ? propValue.split(" ")[1] : 'px'} // Set selected unit based on current property value
                        onChange={(e) => {
                            const newUnit = e.target.value; // Get the selected unit
                            setCurrentUnit(newUnit)
                            const currentValue = propValue.match(/(\d+(\.\d+)?)([a-zA-Z%]+)/); // Extract the number and unit
                        
                            // Check if the currentValue was matched successfully
                            if (currentValue) {
                                const numericValue = currentValue[1]; // Get the numeric part
                                // Combine the numeric value with the new unit
                                const updatedValueWithUnit = `${numericValue}${newUnit}`; 
                        
                                setProp(node.id, (props) => {
                                    props[propKey] = updatedValueWithUnit; // Update the style with the new value and unit
                                });
                        
                                console.log(updatedValueWithUnit); // Log for debugging
                            }
                        }}
                        onFocus={(e) => {
                            e.target.style.borderColor = '#007bff';
                            e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
                        }}
                        onBlur={(e) => {
                            e.target.style.borderColor = '#ccc';
                            e.target.style.boxShadow = 'none';
                        }}
                    >
                        {unitOptions?.map((option) => (
                            <option
                                style={{
                                    fontFamily: 'Inter, sans-serif',
                                    fontWeight: '400',
                                    fontSize: '1.4rem',
                                    color: '#333',
                                }}
                                key={option} 
                                value={option}
                            >
                                {option}
                            </option>
                        ))}
                    </select>

                    </div>
                </div>
            </div>
        </div>
    )
}