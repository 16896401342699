/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useEditor, useNode } from '@craftjs/core';
import {
  Grid,
  Divider,
  Tooltip,
  Typography,
} from '@mui/material';
import { Transition } from 'react-transition-group';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useDarkMode } from '../../../DarkModeContext';
import { makeStyles } from '@mui/styles';
import React, { useMemo, useState, useCallback } from 'react';

const usePanelStyles = makeStyles((_) => ({
  root: {
    boxShadow: 'none',
    '&:before': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    '&.Mui-expanded': {
      margin: '0 0',
      minHeight: '7%',
      '&:before': {
        opacity: '1',
      },
    },
  },
}));

const useSummaryStyles = makeStyles((_) => ({
  root: {
    minHeight: 'auto',
    padding: 0,
    display: "flex",
    alignItems: "flex-start",
  },
  content: {
    marginLeft: '0px',
  },
}));

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: '1.5rem',
    backgroundColor: '#0867FC50',
    color: '#fff',
    padding: '0.5rem 1rem',
    borderRadius: '8px',
  },
  arrow: {
    color: '#fff',
  },
}));

export const ToolbarSection = ({
  searchTerm = "",
  title = "",
  big,
  children,
  props,
  toolbox,
  expanded,
  setExpanded,
  summary,
  icon,
}) => {
  const panelClasses = usePanelStyles({});
  const summaryClasses = useSummaryStyles({});
  const classes = useStyles();
  const { isDarkMode } = useDarkMode();
  const [expandedd, setExpandedd] = useState(false);
  const [show, setShow] = useState(false);

  const childrenArray = React.Children.toArray(children);

  // Memoize filtered results to avoid recalculating on each render
  const filteredResults = useMemo(() => {
    return childrenArray.filter((child) =>
      child.props?.label?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [childrenArray, searchTerm]);

  const isTitleMatch = useMemo(() => 
    title?.toLowerCase().startsWith(searchTerm.toLowerCase()), 
    [title, searchTerm]
  );
  
  const showSection = useMemo(() => isTitleMatch || searchTerm === '', [isTitleMatch, searchTerm]);


  return (
    <div style={{ display: "flex",  flexDirection: 'column', justifyContent: "start", margin:'.7vmin', alignItems: "center", width: 'fit-content', }}>
      {
        <>
         {!toolbox && showSection ? (
        <div
          onClick={setExpanded}  // Apply onClick directly to the outer container
          style={{
            position: 'relative',
            width: 'fit-content',
            cursor: 'pointer',
            height: '100%',
            padding: '1.5vmin',
            display: 'flex',
            alignItems: 'start',
            flexDirection:'row',
            flexWrap:'wrap',
            justifyContent: 'start',
            borderRadius: '8px', // Optional, to make the corners rounded
            overflow: 'hidden',  // Ensures no overflow beyond the container
          }}
        >
          <div
          className={`bluredContent bg-[#0867FC30] hover:bg-[#0867FC80]`}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0, // Ensures the background stays behind everything
          }}
        />
        <div
          style={{
            fontSize: '2.5vmin', // Responsive font size (adjust as needed)
            color: isDarkMode ? '#e9e9e990' : '#fff', // Text color
            fontWeight: 500, // Adjust text weight if needed
            textAlign: 'center',
            textTransform: 'capitalize',
            width:'fit-content',
            userSelect:'none',
            pointerEvents:'none',
            zIndex: 1, // Ensures the title stays on top of the background
            borderRadius: '8px', // Optional: Rounds the title background for better aesthetics
          }}
        >
          {title}
        </div>
        </div>
      ) : (
        
        filteredResults.map((child, index) => (
          <div
          key={index}
          onClick={setExpanded}  // Apply onClick directly to the outer container
          style={{
            position: 'relative',
            width: '90%',
            cursor: 'pointer',
            height: '100%',
            padding: '1.5vmin',
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
            borderRadius: '8px', // Optional, to make the corners rounded
            overflow: 'hidden',  // Ensures no overflow beyond the container
          }}
        >
          <div
          className={`bluredContent bg-[#0867FC30] hover:bg-[#0867FC80]`}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0, // Ensures the background stays behind everything
          }}
        />
        <div
          style={{
            fontSize: '2.5vmin', // Responsive font size (adjust as needed)
            color: isDarkMode ? '#e9e9e990' : '#fff', // Text color
            fontWeight: 500, // Adjust text weight if needed
            textAlign: 'center',
            textTransform: 'capitalize',
            userSelect:'none',
            pointerEvents:'none',
            zIndex: 1, // Ensures the title stays on top of the background
            borderRadius: '8px', // Optional: Rounds the title background for better aesthetics
          }}
        >
          {child.props.label}
        </div>
        </div>
        ))
      )}
      </>
      }


      <Accordion style={{
        minWidth: "0px", width: "95%", overflow: "visible",  padding: "0px", paddingRight: "0px", height: 'fit-content', background:isDarkMode? '#111F3E' : '#FFF',

      }} expanded={true || expanded || expandedd} classes={panelClasses}>

        <AccordionSummary style={{
          width: "100%", minHeight: "0px", height: toolbox ? "fit-content" : "0vh", marginTop: "0px", margin: "0px", padding: "0px",

        }} classes={summaryClasses}>
          {!toolbox ? null : (
            <div
              style={{ width: "100%", display: "flex", marginBottom: '1Vmin' }}
              className="flex flex-row justify-start items-center"
              id="BannerRoot"
              onClick={() => {
                setExpanded ? setExpanded(!expanded) : setExpandedd(!expandedd)
              }}
            >


              <div style={{ width: "2.5Vmin" }}>
              <svg style={{float:'right', marginLeft:'1vmin', transform: expanded || expandedd ? 'rotate(90deg)' : ''}} aria-hidden="true" focusable="false" role="img" className="octicon octicon-chevron-right" viewBox="0 0 12 12" width="2.5Vmin" height="2.5Vmin" fill={isDarkMode ? expanded || expandedd ? '#FFFFFF' : '#FFFFFFA1' :  expanded || expandedd ? '#161717' : '#989191'}><path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3Z"></path></svg>
              </div>


              <div style={{ width: "3.5Vmin",  }} className={` ${isDarkMode ? 'text-[#FFFFFF]' : 'text-[#161717]'}`}>
                {icon ? icon : null}
              </div>
              <div style={{ fontSize: '2.2Vmin' }} className={`font-['Inter'] ${isDarkMode ? 'text-[#FFFFFF]' : 'text-[#989191]'}`}> {title}</div>
              
            </div>


          )}
        </AccordionSummary>
              <AccordionDetails style={{
                padding: '0Vmin',
                margin:0,
                height: '100%',
                color: isDarkMode ? '#FFFFFF' : '#161717',
                display: 'flex',
                flexDirection: 'column',
                background:'transparent',
              }}>
                 {!toolbox ? (
            <Grid
              className='blured'
              container
              style={{
                width: '100%',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                justifyContent: 'center',
                alignItems: 'center',
                padding: expanded ? '1vmin .15vmin' : '0',
                position: 'absolute',
                top: '-7vmin',
                background: '#0867FC40',
                borderRadius: '1vmin',
                right: '13vmin',
                minWidth: '150px',
                zIndex: 999999999999,
                visibility: expanded ? 'visible' : 'hidden',
                opacity: expanded ? 1 : 0,
                transform: expanded ? 'scale(1)' : 'scale(0.8)',
                transition: 'opacity 0.2s ease, transform 0.3s ease, visibility .3s ease',
              }}
            >
              <div
                style={{
                  overflowY: 'scroll',
                  maxHeight: '30vmin',
                  padding: '.5vmin',
                  position:'relative',
                }}
              >
                {filteredResults.map((child, index) => (
                  <React.Fragment key={index}>{child}</React.Fragment>
                ))}
                {filteredResults.length < 1 && React.Children.map(children, (child, index) => (
                  <React.Fragment key={index}>{child}</React.Fragment>
                ))}
              </div>
            </Grid>
          ) : (
            <Grid container>{children}</Grid>
          )}
                {/* {!toolbox && !big && <div className='shadow-2xl' style={{ borderBottom: '0.1Vmin solid #e9e9e9', margin: 'auto auto' }}></div>} */}
              </AccordionDetails>
        </Accordion>

        
    </div>
  );
};