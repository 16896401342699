import React, { createContext, useState } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    const [showSideBar, setShowSideBar] = useState(true)
    const [popUp, setPopUp] = useState(false)


  return (
    <SidebarContext.Provider value={{ showSideBar, setShowSideBar, popUp, setPopUp }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
