/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import SelectionBox from '../../../editor/Viewport/Pop-up/PopUp';
import { TabOption } from '../../../editor/Viewport/Sidebar/TabOption';
import { ToolbarSection, ToolbarItem } from '../../../editor';
import { category } from '../Image/ImageSettings';
import { useNode } from '@craftjs/core';
import { useDarkMode } from '../../../../DarkModeContext';
import Toggle from '../../../editor/Toolbar/Toggle';
import { ArrowToRight } from '../../../editor/Viewport/Pop-up/svgArray';

export const SvgIconSettings = () => {


  const {
    node,
    actions: { setProp }
  } = useNode((node) => ({
    props: node.data.props,
    node: node,
  }));

  const [img, setImg] = useState(false)


  const toggleIsImg = () => {
    setProp((prop) => {
      prop.isImg = !prop.isImg;
    })
    setImg((prevImg) => !prevImg);
  };

  const [iconsSquare, setIconsSquare] = useState(false)
  const [selectedIcon, setSelectedIcon] = useState({});

  const handleIconImageSelect = (selectedUrl) => {

    if (Array.isArray(selectedUrl) && selectedUrl.length) {
      setSelectedIcon(selectedUrl[0]);
    } else {
      setSelectedIcon(selectedUrl)
    }

    setIconsSquare(false);
  };

  const [activeTab, setActiveTab] = useState("Style Manager");
  
  const [customize, setCustomize] = useState({})

  const handleCustomize = (group) => {
    setCustomize((prev) => ({
        ...prev,
        [group]: !prev[group], // Toggle the current group's state
    }));
};

  return (
    <div className='flex flex-col component-settings' style={{ height:'100%'}}>
      <ToolbarItem
            full={true}
             type={null}
          />
      <div className='w-[100%]'>
        <TabOption activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
    <div style={{overflowY:'scroll', scrollbarWidth:'none', overflowX:'hidden', height:'100%', }}>
      {activeTab === "Navigation Manager" ? (

            <ToolbarSection
              

              title="Navigation"
            >
              <ToolbarItem
                full={true}
                propKey="href"
                type="select"
                label="href"
              />
            </ToolbarSection>
      ) : null}



      {activeTab === "Style Manager" ? (
        <>
          <Toggle toggleIsImg={toggleIsImg} on={img} second={'Svg'} first={'Image'} />

          <ToolbarSection title="Source" >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className='w-[100%] text-center flex-col items-center justify-center mb-[.5vmin]'>
              <div style={{ width: "95%",}}>
                <ToolbarItem
                  selectedIcon={selectedIcon}
                  IconsSquare={iconsSquare}
                  setIconsSquare={setIconsSquare}
                  full={true}
                  propKey="src"
                  type="src"
                  iconSearch
                  noinput
                  placeholder='Put the Svg Path'
                />
                {iconsSquare && (
                  <SelectionBox
                    category={category}
                    setIconsSquare={setIconsSquare}
                    iconsSquare={iconsSquare}
                    onSelect={handleIconImageSelect}
                  />
                )}
              </div>
            </div>
          </ToolbarSection>
            <ToolbarSection
              
              title="Colors"
              props={['fillColor']}
              summary={({ fillColor }) => {
                return (
                  <div className="flex flex-row-reverse">
                    <div
                      style={{
                        background:
                          fillColor && `rgba(${Object.values(fillColor)})`,
                      }}
                      className="shadow-md flex-end w-[6%] h-[6%] text-center flex items-center rounded-[0.25Vmin] bg-black"
                    >
                    </div>
                  </div>
                );
              }}
            >
              <ToolbarItem
                full={true}
                propKey="fillColor"
                type="bg"
                preview={false}
                label="Svg's fill color"
              />
            </ToolbarSection>

          <ToolbarSection
            
            title="Size"
            props={['width', 'height']}
            summary={({ width, height, minWidth, maxWidth }) => {
              return `Width: ${width || ''}, Height: ${height || ''}, Min Width: ${minWidth || ''}, Max Width: ${maxWidth || ''}`;
            }}
          >
            <ToolbarItem
              full={true}
              propKey="scale"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Scale SVG"
            />
          </ToolbarSection>

          <ToolbarSection

title="margin"
props={['margin']}
summary={({ }) => {
  return;
}}
>
{customize['margin'] ? (
<>
<ToolbarItem propKey="marginTop" type="slider"
unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
label="Top" />
<ToolbarItem propKey="marginRight" type="slider"
unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
label="Right" />
<ToolbarItem propKey="marginBottom" type="slider"
unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
label="Bottom" />
<ToolbarItem propKey="marginLeft" type="slider"
unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
label="Left" /></>
) : (
<ToolbarItem propKey="margin" type="slider"
unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
label="Margin" />
) } 

<div onClick={() => handleCustomize('margin')} style={{borderRadius:'0 0 1vmin 1vmin', border:'.25vmin solid'}} className='absolute top-[-.15vmin] min-w-[6vmin] flex justify-center items-center right-0 border-color-[#0867FC80] cursor-pointer text-[#0867FC80] p-[.5vmin] hover:text-[#0867FC]'>{customize['margin'] ? <ArrowToRight fill='#0867FC' style={{transform:'rotate(180deg)'}} width='3vmin' height='2vmin'/> : 'customize' }</div>

</ToolbarSection>


<ToolbarSection

title="Padding"
props={['padding']}
summary={({ }) => {
  return;
}}
>
{customize['padding'] ? (
  <>
  <ToolbarItem propKey="paddingTop" type="slider"
  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
  label="Top" />
  <ToolbarItem propKey="paddingRight" type="slider"
  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
  label="Right" />
  <ToolbarItem propKey="paddingBottom" type="slider"
  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
  label="Bottom" />
  <ToolbarItem propKey="paddingLeft" type="slider"
  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
  label="Left" /> 
  </>
) : (
  <ToolbarItem propKey="padding" type="slider"
  unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
  label="Padding" />
)}
  
  <div onClick={() => handleCustomize('padding')} style={{borderRadius:'0 0 1vmin 1vmin', border:'.25vmin solid'}} className='absolute top-[-.15vmin] min-w-[6vmin] flex justify-center items-center right-0 border-color-[#0867FC80] cursor-pointer text-[#0867FC80] p-[.5vmin] hover:text-[#0867FC]'>{customize['padding'] ? <ArrowToRight fill='#0867FC' style={{transform:'rotate(180deg)'}} width='3vmin' height='2vmin'/> : 'customize' }</div>
</ToolbarSection>
</>
      ) : null}
      </div>
    </div>
  )
}