// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.features-card {
margin:8px;
min-width: 277px;
min-height: 152px;
max-height: auto;
display: flex;
flex-direction: row;
flex-wrap: wrap;
flex: 40% 1;
border-radius: 30px;
box-shadow: 0px 0px 10px 0px #FFFFFF1A;
}
`, "",{"version":3,"sources":["webpack://./src/components/selectors/blocks/Features/Features.css"],"names":[],"mappings":"AAAA;AACA,UAAU;AACV,gBAAgB;AAChB,iBAAiB;AACjB,gBAAgB;AAChB,aAAa;AACb,mBAAmB;AACnB,eAAe;AACf,WAAS;AACT,mBAAmB;AACnB,sCAAsC;AACtC","sourcesContent":[".features-card {\nmargin:8px;\nmin-width: 277px;\nmin-height: 152px;\nmax-height: auto;\ndisplay: flex;\nflex-direction: row;\nflex-wrap: wrap;\nflex: 40%;\nborder-radius: 30px;\nbox-shadow: 0px 0px 10px 0px #FFFFFF1A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
