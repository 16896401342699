import { useEffect } from "react";

export const useOnClickOutside = (selectedRef, selected, handler) => {
    useEffect(() => {
      const listener = (event) => {
        if (selected && !selectedRef.current || selectedRef.current.contains(event.target)) {
          return ;
        }
        handler();
      };
  
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
  
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }, [selected, selectedRef, handler]);
  };
