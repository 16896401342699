/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


export const cards = [{
    "id": 1,
    "title": "Campaigns",
    "order": 0,
    "description": "Create a new landing page for campaign",
    "status": "todo",
    "priority": {
      "color": "orange",
      "priority": "Urgent"
    },
    "image": "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-01.png"
  }, {
    "id": 2,
    "title": "Newsletters",
    "order": 2,
    "description": "Send newsletter",
    "status": "todo",
    "priority": {
      "color": "orange",
      "priority": "Urgent"
    },
    "image": "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-02.png"
  }, {
    "id": 3,
    "title": "Ads Analytics",
    "order": 1,
    "description": "Review ads performance",
    "status": "todo",
    "priority": {
      "color": "orange",
      "priority": "Urgent"
    },
    "image": "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-03.png"
  }, {
    "id": 4,
    "title": "SEO Analytics",
    "order": 10,
    "description": "Review SEO results",
    "status": "inProgress",
    "priority": {
      "color": "orange",
      "priority": "Urgent"
    },
    "image": "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-04.png"
  }, {
    "id": 5,
    "title": "Customer Research",
    "order": 5,
    "description": "Interview focus groups",
    "status": "todo",
    "priority": {
      "color": "orange",
      "priority": "Urgent"
    },
    "image": "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-05.png"
  }, {
    "id": 6,
    "title": "Testimonials & Case Studies",
    "order": 6,
    "description": "Publish new case study",
    "status": "todo",
    "priority": {
      "color": "orange",
      "priority": "Urgent"
    },
    "image": "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-06.png"
  }, {
    "id": 7,
    "title": "Content",
    "order": 7,
    "description": "Plan content for podcasts",
    "status": "todo",
    "priority": {
      "color": "orange",
      "priority": "Urgent"
    },
    "image": "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-07.png"
  }, {
    "id": 8,
    "title": "Customer Journey",
    "order": 8,
    "description": "Update virtual classrooms' experience",
    "status": "todo",
    "priority": {
      "color": "orange",
      "priority": "Urgent"
    },
    "image": "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-08.png"
  }, {
    "id": 9,
    "title": "Funnel Analytics",
    "order": 9,
    "description": "Funnel analysis",
    "status": "inProgress",
    "priority": {
      "color": "blue",
      "priority": "High Priority"
    },
    "image": "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-09.png"
  }, {
    "id": 10,
    "title": "Customer Research",
    "order": 11,
    "description": "Refine feedback from user interviews",
    "status": "inProgress",
    "priority": {
      "color": "blue",
      "priority": "High Priority"
    },
    "image": "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-10.png"
  }, {
    "id": 11,
    "title": "Campaigns",
    "order": 12,
    "description": "Collaborate with designers on new banners",
    "status": "inProgress",
    "priority": {
      "color": "blue",
      "priority": "High Priority"
    },
    "image": "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-11.png"
  }, {
    "id": 12,
    "title": "Campaigns",
    "order": 13,
    "description": "Schedule social media for release",
    "status": "inProgress",
    "priority": {
      "color": "blue",
      "priority": "High Priority"
    },
    "image": "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-12.png"
  }, {
    "id": 13,
    "title": "Customer Journey",
    "order": 13,
    "description": "Review shopping cart experience",
    "status": "done",
    "priority": {
      "color": "green",
      "priority": "Low Priority"
    },
    "image": "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-13.png"
  }, {
    "id": 14,
    "title": "Content",
    "order": 14,
    "description": "Publish new blogpost",
    "status": "done",
    "priority": {
      "color": "green",
      "priority": "Low Priority"
    },
    "image": "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-14.png"
  }, {
    "id": 15,
    "title": "Post-Release Party",
    "order": 15,
    "description": "Plan new release celebration",
    "status": "done",
    "priority": {
      "color": "green",
      "priority": "Low Priority"
    },
    "image": "https://demos.telerik.com/kendo-ui/content/web/taskboard/taskboard-demo-illustrations-15.png"
  }];