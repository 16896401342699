/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { ToolbarSection, ToolbarItem } from '../../../editor';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import { TabOption } from '../../../editor/Viewport/Sidebar/TabOption';
import { useNode } from '@craftjs/core';
import Toggle from '../../../editor/Toolbar/Toggle';

export const CheckBoxSettings = () => {
  const [activeTab, setActiveTab] = useState("Style Manager");


  return (
    <div className='flex flex-col component-settings' style={{ height:'100%'}}>
      <ToolbarItem
            full={true}
             type={null}
          />
      <div className='w-full'>
        <TabOption activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>

<div style={{overflowY:'scroll', scrollbarWidth:'none', overflowX:'hidden',}}>
      {activeTab === "Advanced" ? (
        <ToolbarSection
          expanded={true}
          title="Actions"
        > 
          

        </ToolbarSection>
      ) : null}

      {activeTab === "Style Manager" ? (
        <div style={{ width: "100%" }}>
          <ToolbarSection
            expanded={true}
            title="Checkbox"
            props={['fontSize', 'fontWeight', 'textAlign', 'placeholder']}
            summary={({ fontSize, fontWeight, textAlign, placeholder, }) => {
              return `${fontSize || ''}, ${fontWeight || ''}, ${textAlign || ''}, ${placeholder || ''}`;
            }}
          >
            <ToolbarItem
              full={true}
              propKey="fontSize"
              type="slider"
              unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
              label="Size"
            />

            <ToolbarItem
            full={true}
            propKey="backgroundColor"
            type="color"
            label='Checkbox Color'
            />
            
            

          </ToolbarSection>
          <ToolbarSection
          expanded={true}
          title="Checkbox Border"
          props={['width', 'height', 'minWidth', 'maxWidth']}
          summary={({ width, height, minWidth, maxWidth }) => {
            return `Width: ${width || 'Auto'}, Height: ${height || 'Auto'}, Min Width: ${minWidth || 'None'}, Max Width: ${maxWidth || 'None'}`;
          }}
        >
          <ToolbarItem
            full={true}
            propKey="borderWidth"
            type="slider"
            max
            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
            label="Border Width"
          />

            <ToolbarItem
            full={true}
            propKey="borderColor"
            type="color"
            label="Border Color"
          />
        </ToolbarSection>

        </div>
      ) : activeTab === "Content" ? (
        <div style={{ width: "100%" }}>

<ToolbarSection
          expanded={true}
          title="Content"
          props={['fontSize', 'fontWeight', 'textAlign', 'placeholder']}
          summary={({ fontSize, fontWeight, textAlign, placeholder, }) => {
            return `${fontSize || ''}, ${fontWeight || ''}, ${textAlign || ''}, ${placeholder || ''}`;
          }}
        >
        
        <ToolbarItem
            full={true}
            propKey="fontSize"
            type="slider"
            unitOptions={['px', '%', 'em', 'rem', 'vh', 'vw', 'vmin', 'vmax']}
            label="Size"
          />

        <ToolbarItem
            full={true}
            propKey="label"
            type="text"
            label="Label"
          />

        <ToolbarItem
            full={true}
            propKey="labelPosition"
            optionsDrop={['before', 'after']}
            type="select-drop"
            label="Label Position"
          />

          <ToolbarItem
          full={true}
          propKey="color"
          type="color"
          label='Label Color'
          />

          </ToolbarSection>


        

      </div>
      ): null}
      </div>
    </div>
  );
};
