/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { Collapse, Button, CardBody, Card } from 'reactstrap';

import { Element, useEditor, useNode } from '@craftjs/core';
import { NewFaqSettings } from './newFaqSetting';
import {AccordionSummary, AccordionDetails, Box, Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import Text from '../../basic/Text';
import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';

export const Accordion = styled(MuiAccordion)(({ theme }) => ({ 
    '&:before': {
        display: 'none',
    },
}));


export const FAQ_1 = ({selected}) => {
  
  const { 
    actions: { setProp },
    connectors: { 
      connect 
    },
    open
  } = useNode((node) => ({
    open: node.data.props.open
  }));
  
      
      const {
        enabled,
      } = useEditor((state, query) => ({
        enabled: state.options.enabled,
     
      }));
    
      const toggleOpen = () => {
        setProp((props) => {
          props.open = !props.open;
          return props;
        });
      };

    const styles = [
//       <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
//       <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} ><Element canvas id='random-id-8080' is='parent' className='w-[100%] h-full' style={{background:'#FFFFFF'}}>
//           <Box sx={{textAlign:'center', padding:'0px 2em'}}>
//             <Typography className='text-[#100103] my-4' sx={{fontSize:'46px', fontWeight:'700px', fontFamily:'Rubik'}}>
//             <Wrapper canvas style={{ display: "inline-block" }} id="random-id-73" is="div">
//             <Text text="Frequently asked questions" />
//             </Wrapper>
//             </Typography>
//             <Typography className='mx-auto my-4' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik', color:'[#100103]', maxWidth:'584px'}}>
//             <Wrapper canvas style={{ display: "inline-block" }} id="random-id-75" is="div">
//             <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
//             </Wrapper>
//             </Typography>
//           </Box>
//         <Box className='mx-auto' sx={{padding:'1em', width:'100%', maxWidth:'1170px'}}>
//         <Accordion  sx={{background:'transparent', color:'#000000', padding:'5px', border:'1px solid #0000001A', margin:'10px'}}>
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon sx={{color:'#B20312', fontSize:'23px'}} />}
//             aria-controls="panel1a-content"
//             id="panel1a-header"
//           >
//             <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-76" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//             </Typography>
//           </AccordionSummary> 
//           <AccordionDetails>
//             <Typography sx={{fontSize:'16px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #D9D9D9', padding:'10px 0px 0px 0px'}}>
              
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-77" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//         <Accordion  sx={{background:'transparent', color:'#000000', padding:'5px', border:'1px solid #0000001A', margin:'10px'}}>
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon sx={{color:'#B20312', fontSize:'23px'}} />}
//             aria-controls="panel2a-content"
//             id="panel2a-header"
//           >
//             <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-78" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #D9D9D9', padding:'10px 0px 0px 0px'}}>
              
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-79" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//         <Accordion  sx={{background:'transparent', color:'#000000', padding:'5px', border:'1px solid #0000001A', margin:'10px'}}>
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon sx={{color:'#B20312', fontSize:'23px'}} />}
//             aria-controls="panel2a-content"
//             id="panel2a-header"
//           >
//             <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-80" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #D9D9D9', padding:'10px 0px 0px 0px'}}>
              
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-81" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//         <Accordion  sx={{background:'transparent', color:'#000000', padding:'5px', border:'1px solid #0000001A', margin:'10px'}}>
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon sx={{color:'#B20312', fontSize:'23px'}} />}
//             aria-controls="panel2a-content"
//             id="panel2a-header"
//           >
//             <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-82" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #D9D9D9', padding:'10px 0px 0px 0px'}}>
              
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-83" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//         <Accordion  sx={{background:'transparent', color:'#000000', padding:'5px', border:'1px solid #0000001A', margin:'10px'}}>
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon sx={{color:'#B20312', fontSize:'23px'}} />}
//             aria-controls="panel2a-content"
//             id="panel2a-header"
//           >
//             <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-84" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #D9D9D9', padding:'10px 0px 0px 0px'}}>
              
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-85" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//       </Box>
//       </Element></Element>
//       </Element>
//       ,
//         <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
//       <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Element canvas id='random-id-8090' is='parent' className='w-[100%] h-full' style={{background:'#121826'}}>
//           <Box sx={{textAlign:'center', padding:'0px 2em'}}>
//             <Typography className='IncreaseConversion2 my-4' sx={{fontSize:'46px', fontWeight:'700px', fontFamily:'Rubik'}}>
//             <Wrapper canvas style={{ display: "inline-block" }} id="random-id-1" is="div">
//             <Text text="Frequently asked questions." />
//             </Wrapper>
//             </Typography>
//             <Typography className='mx-auto my-4' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik', color:'white', maxWidth:'584px'}}>
//             <Wrapper canvas style={{ display: "inline-block" }} id="random-id-2" is="div">
//             <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
//             </Wrapper>
//             </Typography>
//           </Box>
//         <Box className='mx-auto' sx={{padding:'1em', width:'100%', maxWidth:'1170px'}}>
//         <Accordion  sx={{background:'#121826', color:'white', padding:'5px', border:'1px solid #FFFFFF1A', margin:'10px'}}>
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon sx={{color:'#4BDBA2', fontSize:'23px'}} />}
//             aria-controls="panel1a-content"
//             id="panel1a-header"
//           >
//             <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-3" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//             </Typography>
//           </AccordionSummary> 
//           <AccordionDetails>
//             <Typography sx={{fontSize:'16px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #4BDBA233', padding:'10px 0px 0px 0px'}}>
              
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-4" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//         <Accordion  sx={{background:'#121826', color:'white', padding:'5px', border:'1px solid #FFFFFF1A', margin:'10px'}}>
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon sx={{color:'#4BDBA2', fontSize:'23px'}} />}
//             aria-controls="panel2a-content"
//             id="panel2a-header"
//           >
//             <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-5" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #4BDBA233', padding:'10px 0px 0px 0px'}}>
              
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-6" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//         <Accordion  sx={{background:'#121826', color:'white', padding:'5px', border:'1px solid #FFFFFF1A', margin:'10px'}}>
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon sx={{color:'#4BDBA2', fontSize:'23px'}} />}
//             aria-controls="panel2a-content"
//             id="panel2a-header"
//           >
//             <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-7" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #4BDBA233', padding:'10px 0px 0px 0px'}}>
              
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-8" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//         <Accordion  sx={{background:'#121826', color:'white', padding:'5px', border:'1px solid #FFFFFF1A', margin:'10px'}}>
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon sx={{color:'#4BDBA2', fontSize:'23px'}} />}
//             aria-controls="panel2a-content"
//             id="panel2a-header"
//           >
//             <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-9" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #4BDBA233', padding:'10px 0px 0px 0px'}}>
              
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-10" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//         <Accordion  sx={{background:'#121826', color:'white', padding:'5px', border:'1px solid #FFFFFF1A', margin:'10px'}}>
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon sx={{color:'#4BDBA2', fontSize:'23px'}} />}
//             aria-controls="panel2a-content"
//             id="panel2a-header"
//           >
//             <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-11" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//             </Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #4BDBA233', padding:'10px 0px 0px 0px'}}>
              
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-12" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//             </Typography>
//           </AccordionDetails>
//         </Accordion>
//       </Box>
//       </Element></Element>
//       </Element>,

      
//       <Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
//       <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Element canvas id='random-id-8100' is='parent' className='w-[100%] h-full' style={{background:'#213156'}}>
//       <Box sx={{textAlign:'center', padding:'0px 2em'}}>
//         <Typography className='text-[#EA6EF4] my-4' sx={{fontSize:'46px', fontWeight:'700px', fontFamily:'Times New Roman'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-13" is="div">
// <Text text="Frequently asked questions." />
// </Wrapper>
//         </Typography>
//         <Typography className='mx-auto my-4' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Open Sans', color:'#FFFFFF', maxWidth:'584px'}}>
        
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-14" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
// </Wrapper>
//         </Typography>
//       </Box>
//     <Box className='mx-auto' sx={{padding:'1em', width:'100%', maxWidth:'1170px'}}>
//     <Accordion disableGutters elevation={0} square sx={{background:'#213156', color:'#FFFFFF', padding:'5px', border:'1px solid #EA6EF433', borderRadius:'10px', margin:'10px'}}>
//       <AccordionSummary
//         expandIcon={<ExpandMoreIcon sx={{color:'#EA6EF4', fontSize:'23px'}} />}
//         aria-controls="panel1a-content"
//         id="panel1a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Open Sans'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-15" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Open Sans', borderTop:'1px solid #EA6EF433', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-16" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'#213156', color:'#FFFFFF', padding:'5px', border:'1px solid #EA6EF433', borderRadius:'10px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#EA6EF4', fontSize:'23px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Open Sans'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-17" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Open Sans', borderTop:'1px solid #EA6EF433', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-18" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'#213156', color:'#FFFFFF', padding:'5px', border:'1px solid #EA6EF433', borderRadius:'10px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#EA6EF4', fontSize:'23px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Open Sans'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-19" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Open Sans', borderTop:'1px solid #EA6EF433', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-20" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'#213156', color:'#FFFFFF', padding:'5px', border:'1px solid #EA6EF433', borderRadius:'10px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#EA6EF4', fontSize:'23px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Open Sans'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-21" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Open Sans', borderTop:'1px solid #EA6EF433', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-22" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'#213156', color:'#FFFFFF', padding:'5px', border:'1px solid #EA6EF433', borderRadius:'10px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#EA6EF4', fontSize:'23px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Open Sans'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-23" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Open Sans', borderTop:'1px solid #EA6EF433', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-24" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//   </Box>
//   </Element></Element>
//       </Element>,

      
//       <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
//       <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Element canvas id='random-id-8200' is='parent' className='w-[100%] h-full' style={{background:'#DFFFF9'}}>
//       <Box sx={{textAlign:'center', padding:'0px 2em'}}>
//         <Typography className='text-[#31A993] my-4' sx={{fontSize:'46px', fontWeight:'100px', fontFamily:'Zing Script Rust SemiBold Demo', fontStyle:'italic'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-25" is="div">
// <Text text="Frequently asked questions." />
// </Wrapper>
//         </Typography>
//         <Typography className='mx-auto my-4' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Montserrat', color:'#031815', maxWidth:'584px'}}>
        
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-26" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
// </Wrapper>
//         </Typography>
//       </Box>
//     <Box className='mx-auto' sx={{padding:'1em', width:'100%', maxWidth:'1170px'}}>
//     <Accordion disableGutters elevation={0} square sx={{background:'#DFFFF9', color:'#031815', padding:'5px', border:'1px solid #31A99333', borderRadius:'30px', margin:'10px'}}>
//       <AccordionSummary
//         expandIcon={<ExpandMoreIcon sx={{color:'#31A993', fontSize:'33px'}} />}
//         aria-controls="panel1a-content"
//         id="panel1a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Montserrat'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-27" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Montserrat', borderTop:'1px solid #31A99333', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-28" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'#DFFFF9', color:'#031815', padding:'5px', border:'1px solid #31A99333', borderRadius:'30px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#31A993', fontSize:'33px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Montserrat'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-29" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Montserrat', borderTop:'1px solid #31A99333', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-30" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'#DFFFF9', color:'#031815', padding:'5px', border:'1px solid #31A99333', borderRadius:'30px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#31A993', fontSize:'33px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Montserrat'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-31" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Montserrat', borderTop:'1px solid #31A99333', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-32" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'#DFFFF9', color:'#031815', padding:'5px', border:'1px solid #31A99333', borderRadius:'30px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#31A993', fontSize:'33px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Montserrat'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-33" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails>
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Montserrat', borderTop:'1px solid #31A99333', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-34" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square  sx={{background:'#DFFFF9', color:'#031815', border:'1px solid #31A99333', borderRadius:'30px', padding:'5px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#31A993', fontSize:'33px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Montserrat'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-35" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails>
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Montserrat', borderTop:'1px solid #31A99333', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-36" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//   </Box>
//   </Element></Element>
//       </Element>,
  

      
//       <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
//       <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Element canvas id='random-id-8300' is='parent' className='w-[100%] h-full' style={{background:'white'}}>
//       <Box sx={{textAlign:'center', padding:'0px 2em'}}>
//         <Typography className='text-[#0078D3] my-4' sx={{fontSize:'46px', fontWeight:'bold', fontFamily:'Inter'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-37" is="div">
// <Text text="Frequently asked questions." />
// </Wrapper>
//         </Typography>
//         <Typography className='mx-auto my-4' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Inter', color:'#000000', maxWidth:'584px'}}>
        
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-38" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
// </Wrapper>
//         </Typography>
//       </Box>
//     <Box className='mx-auto' sx={{padding:'1em', width:'100%', maxWidth:'1170px'}}>
//     <Accordion disableGutters elevation={0} square sx={{background:'transparent', color:'#000000', padding:'5px', border:'1px solid #0000000A', borderRadius:'10px', margin:'10px'}}>
//       <AccordionSummary
//         expandIcon={<ExpandMoreIcon sx={{color:'#0078D3', fontSize:'33px'}} />}
//         aria-controls="panel1a-content"
//         id="panel1a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Inter'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-39" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Inter', borderTop:'1px solid #4BDBA233', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-40" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'transparent', color:'#000000', padding:'5px', border:'1px solid #0000000A', borderRadius:'10px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#0078D3', fontSize:'33px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Inter'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-41" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Inter', borderTop:'1px solid #4BDBA233', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-42" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'transparent', color:'#000000', padding:'5px', border:'1px solid #0000000A', borderRadius:'10px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#0078D3', fontSize:'33px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Inter'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-43" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Inter', borderTop:'1px solid #4BDBA233', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-44" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'transparent', color:'#000000', padding:'5px', border:'1px solid #0000000A', borderRadius:'10px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#0078D3', fontSize:'33px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Inter'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-45" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Inter', borderTop:'1px solid #4BDBA233', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-46" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'transparent', color:'#000000', padding:'5px', border:'1px solid #0000000A', borderRadius:'10px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#0078D3', fontSize:'33px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Inter'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-47" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Inter', borderTop:'1px solid #4BDBA233', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-48" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//   </Box>
//   </Element></Element>
//       </Element>,
  
  

      
//       <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
//       <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Element canvas id='random-id-8400' is='parent' className='w-[100%] h-full' style={{background:'white'}}>
//       <Box sx={{textAlign:'center', padding:'0px 2em'}}>
//         <Typography className='text-[#020212] my-4' sx={{fontSize:'50px', fontWeight:'400px', fontFamily:'Voces'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-49" is="div">
// <Text text="Frequently asked questions." />
// </Wrapper>
//         </Typography>
//         <Typography className='mx-auto my-4' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Voces', color:'#020212', maxWidth:'584px'}}>
        
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-50" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
// </Wrapper>
//         </Typography>
//       </Box>
//     <Box className='mx-auto' sx={{padding:'1em', width:'100%', maxWidth:'1170px'}}>
//     <Accordion disableGutters elevation={0} square sx={{background:'#F8F8F8', color:'#020212', padding:'5px', border:'1px solid #0000000A', margin:'10px'}}>
//       <AccordionSummary
//         expandIcon={<ExpandMoreIcon sx={{color:'#020212', fontSize:'35px'}} />}
//         aria-controls="panel1a-content"
//         id="panel1a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Voces'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-51" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Voces', borderTop:'1px solid #CBCBCB', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-52" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'#F8F8F8', color:'#020212', padding:'5px', border:'1px solid #0000000A', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#020212', fontSize:'35px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Voces'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-53" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Voces', borderTop:'1px solid #CBCBCB', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-54" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'#F8F8F8', color:'#020212', padding:'5px', border:'1px solid #0000000A', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#020212', fontSize:'35px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Voces'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-55" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Voces', borderTop:'1px solid #CBCBCB', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-56" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'#F8F8F8', color:'#020212', padding:'5px', border:'1px solid #0000000A', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#020212', fontSize:'35px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Voces'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-57" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Voces', borderTop:'1px solid #CBCBCB', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-58" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'#F8F8F8', color:'#020212', padding:'5px', border:'1px solid #0000000A', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#020212', fontSize:'35px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Voces'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-59" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Voces', borderTop:'1px solid #CBCBCB', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-60" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//   </Box>
//   </Element></Element>
//       </Element>,
  
  
  

      
//       <Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
//       <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Element canvas id='random-id-8500' is='parent' className='w-[100%] h-full' style={{background:'#FBFAFF'}}>
//       <Box sx={{textAlign:'center', padding:'0px 2em'}}>
//         <Typography className='text-[#6750A4] my-4' sx={{fontSize:'46px', fontWeight:'bold', fontFamily:'Roboto'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-61" is="div">
// <Text text="Frequently asked questions." />
// </Wrapper>
//         </Typography>
//         <Typography className='mx-auto my-4' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Roboto', color:'#020212', maxWidth:'584px'}}>
        
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-62" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
// </Wrapper>
//         </Typography>
//       </Box>
//     <Box className='mx-auto' sx={{padding:'1em', width:'100%', maxWidth:'1170px'}}>
//     <Accordion disableGutters elevation={0} square sx={{background:'#FFFFFF', color:'#020212', padding:'5px', border:'1px solid #D0BCFF66', borderRadius:'30px', margin:'10px'}}>
//       <AccordionSummary
//         expandIcon={<ExpandMoreIcon sx={{color:'#020212', fontSize:'35px'}} />}
//         aria-controls="panel1a-content"
//         id="panel1a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Roboto'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-63" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Roboto', borderTop:'1px solid #6750A433', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-64" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'#FFFFFF', color:'#020212', padding:'5px', border:'1px solid #D0BCFF66', borderRadius:'30px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#020212', fontSize:'35px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Roboto'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-65" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Roboto', borderTop:'1px solid #6750A433', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-66" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'#FFFFFF', color:'#020212', padding:'5px', border:'1px solid #D0BCFF66', borderRadius:'30px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#020212', fontSize:'35px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Roboto'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-67" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Roboto', borderTop:'1px solid #6750A433', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-68" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'#FFFFFF', color:'#020212', padding:'5px', border:'1px solid #D0BCFF66', borderRadius:'30px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#020212', fontSize:'35px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Roboto'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-69" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Roboto', borderTop:'1px solid #6750A433', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-70" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'#FFFFFF', color:'#020212', padding:'5px', border:'1px solid #D0BCFF66', borderRadius:'30px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#020212', fontSize:'35px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Roboto'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-71" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Roboto', borderTop:'1px solid #6750A433', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-72" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//   </Box>
//   </Element></Element>
//       </Element>,

//   <Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
//       <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Element canvas id='random-id-8600' is='parent' className='w-[100%] h-full' style={{background:'#2A2A2A'}}>
//       <Box sx={{textAlign:'center', padding:'0px 2em'}}>
//         <Typography className='text-[#FFD600] my-4' sx={{fontSize:'46px', fontWeight:'400px', fontFamily:'Rum Raisin'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-86" is="div">
// <Text text="Frequently asked questions." />
// </Wrapper>
//         </Typography>
//         <Typography className='mx-auto my-4' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik', color:'#FFFFFF', maxWidth:'584px'}}>
        
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-87" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
// </Wrapper>
//         </Typography>
//       </Box>
//     <Box className='mx-auto' sx={{padding:'1em', width:'100%', maxWidth:'1170px'}}>
//     <Accordion disableGutters elevation={0} square sx={{background:'transparent', color:'#FFFFFF', padding:'5px', border:'1px solid #FFD6001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F', borderRadius:'10px', margin:'10px'}}>
//       <AccordionSummary
//         expandIcon={<ExpandMoreIcon sx={{color:'#FFD600', fontSize:'33px'}} />}
//         aria-controls="panel1a-content"
//         id="panel1a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Inter'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-88" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Inter', borderTop:'1px solid #FFD60033', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-89" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'transparent', color:'#FFFFFF', padding:'5px', border:'1px solid #FFD6001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F', borderRadius:'10px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#FFD600', fontSize:'33px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Inter'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-90" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Inter', borderTop:'1px solid #FFD60033', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-91" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'transparent', color:'#FFFFFF', padding:'5px', border:'1px solid #FFD6001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F', borderRadius:'10px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#FFD600', fontSize:'33px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Inter'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-92" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Inter', borderTop:'1px solid #FFD60033', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-93" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'transparent', color:'#FFFFFF', padding:'5px', border:'1px solid #FFD6001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F', borderRadius:'10px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#FFD600', fontSize:'33px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Inter'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-94" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Inter', borderTop:'1px solid #FFD60033', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-95" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//     <Accordion disableGutters elevation={0} square sx={{background:'transparent', color:'#FFFFFF', padding:'5px', border:'1px solid #FFD6001A', boxShadow: '0px 0px 10px 0px #FFFFFF0F', borderRadius:'10px', margin:'10px'}}>
//       <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{color:'#FFD600', fontSize:'33px'}} />}
//         aria-controls="panel2a-content"
//         id="panel2a-header"
//       >
//         <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Inter'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-96" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails >
//         <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Inter', borderTop:'1px solid #FFD60033', padding:'10px 0px 0px 0px'}}>
          
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-97" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//         </Typography>
//       </AccordionDetails>
//     </Accordion>
//   </Box>
//   </Element></Element>
//       </Element>,

// <Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
//       <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Element canvas id='random-id-8700' is='parent' className='w-[100%] h-full' style={{background:'#F7FBFE'}}>
// <Box sx={{textAlign:'center', padding:'0px 2em'}}>
//   <Typography className='text-[#6064D2] my-4' sx={{fontSize:'46px', fontWeight:'700px', fontFamily:'Rubik'}}>
//   <Wrapper canvas style={{ display: "inline-block" }} id="random-id-900" is="div">
//   <Text text="Frequently asked questions" />
//   </Wrapper>
 
//   </Typography>
//   <Typography className='mx-auto my-4' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik', color:'[#100103]', maxWidth:'584px'}}>
//   <Wrapper canvas style={{ display: "inline-block" }} id="random-id-901" is="div">
//   <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
//   </Wrapper>
//   </Typography>
// </Box>
// <Box className='mx-auto' sx={{padding:'1em', width:'100%', maxWidth:'1170px'}}>
// <Accordion  sx={{background:'#FFFFFF', color:'#000000', padding:'5px', border:'1px solid #6064D21A', borderRadius:'10px', margin:'10px'}}>
// <AccordionSummary
//   expandIcon={<ExpandMoreIcon sx={{color:'#6064D2', fontSize:'23px'}} />}
//   aria-controls="panel1a-content"
//   id="panel1a-header"
// >
//   <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-902" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//   </Typography>
// </AccordionSummary> 
// <AccordionDetails>
//   <Typography sx={{fontSize:'16px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #6064D233', padding:'10px 0px 0px 0px'}}>
    
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-903" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//   </Typography>
// </AccordionDetails>
// </Accordion>
// <Accordion  sx={{background:'#FFFFFF', color:'#000000', padding:'5px', border:'1px solid #6064D21A', borderRadius:'10px', margin:'10px'}}>
// <AccordionSummary
//   expandIcon={<ExpandMoreIcon sx={{color:'#6064D2', fontSize:'23px'}} />}
//   aria-controls="panel2a-content"
//   id="panel2a-header"
// >
//   <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-904" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//   </Typography>
// </AccordionSummary>
// <AccordionDetails>
//   <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #6064D233', padding:'10px 0px 0px 0px'}}>
    
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-905" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//   </Typography>
// </AccordionDetails>
// </Accordion>
// <Accordion  sx={{background:'#FFFFFF', color:'#000000', padding:'5px', border:'1px solid #6064D21A', borderRadius:'10px', margin:'10px'}}>
// <AccordionSummary
//   expandIcon={<ExpandMoreIcon sx={{color:'#6064D2', fontSize:'23px'}} />}
//   aria-controls="panel2a-content"
//   id="panel2a-header"
// >
//   <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-906" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//   </Typography>
// </AccordionSummary>
// <AccordionDetails>
//   <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #6064D233', padding:'10px 0px 0px 0px'}}>
    
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-907" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//   </Typography>
// </AccordionDetails>
// </Accordion>
// <Accordion  sx={{background:'#FFFFFF', color:'#000000', padding:'5px', border:'1px solid #6064D21A', borderRadius:'10px', margin:'10px'}}>
// <AccordionSummary
//   expandIcon={<ExpandMoreIcon sx={{color:'#6064D2', fontSize:'23px'}} />}
//   aria-controls="panel2a-content"
//   id="panel2a-header"
// >
//   <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-908" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//   </Typography>
// </AccordionSummary>
// <AccordionDetails>
//   <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #6064D233', padding:'10px 0px 0px 0px'}}>
    
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-909" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//   </Typography>
// </AccordionDetails>
// </Accordion>
// <Accordion  sx={{background:'#FFFFFF', color:'#000000', padding:'5px', border:'1px solid #6064D21A', borderRadius:'10px', margin:'10px'}}>
// <AccordionSummary
//   expandIcon={<ExpandMoreIcon sx={{color:'#6064D2', fontSize:'23px'}} />}
//   aria-controls="panel2a-content"
//   id="panel2a-header"
// >
//   <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-910" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
// </Wrapper>
//   </Typography>
// </AccordionSummary>
// <AccordionDetails>
//   <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #6064D233', padding:'10px 0px 0px 0px'}}>
    
// <Wrapper canvas style={{ display: "inline-block" }} id="random-id-911" is="div">
// <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
// </Wrapper>
//   </Typography>
// </AccordionDetails>
// </Accordion>
// </Box>
// </Element></Element>
//       </Element>,
<div>hi</div>,

<Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
      <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto" row={true} >

        {/* <Element canvas id='random-id-8900' is='parent' className='w-[100%] h-full' style={{background:'#FEFBF7'}}>
<Box sx={{textAlign:'center', padding:'0px 2em'}}>
  <Typography className='text-[#A25738] my-4' sx={{fontSize:'46px', fontWeight:'700px', fontFamily:'Ruluko'}}>
  <Wrapper canvas style={{ display: "inline-block" }} id="random-id-912" is="div">
  <Text text="Frequently asked questions" />
  </Wrapper>
  </Typography>
  <Typography className='mx-auto my-4' sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik', color:'[#100103]', maxWidth:'584px'}}>
  <Wrapper canvas style={{ display: "inline-block" }} id="random-id-913" is="div">
  <Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." />
  </Wrapper>
  </Typography>
</Box>
<Box className='mx-auto' sx={{padding:'1em', width:'100%', maxWidth:'1170px'}}>
<Accordion  sx={{background:'#FFFFFF', color:'#000000', padding:'5px', border:'1px solid #A2573833', borderRadius:'10px', margin:'10px'}}>
<AccordionSummary
  expandIcon={<ExpandMoreIcon sx={{color:'#A25738', fontSize:'23px'}} />}
  aria-controls="panel1a-content"
  id="panel1a-header"
>
  <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

<Wrapper canvas style={{ display: "inline-block" }} id="random-id-914" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
</Wrapper>
  </Typography>
</AccordionSummary> 
<AccordionDetails>
  <Typography sx={{fontSize:'16px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #A2573833', padding:'10px 0px 0px 0px'}}>
    
<Wrapper canvas style={{ display: "inline-block" }} id="random-id-915" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
</Wrapper>
  </Typography>
</AccordionDetails>
</Accordion>
<Accordion  sx={{background:'#FFFFFF', color:'#000000', padding:'5px', border:'1px solid #A2573833', borderRadius:'10px', margin:'10px'}}>
<AccordionSummary
  expandIcon={<ExpandMoreIcon sx={{color:'#A25738', fontSize:'23px'}} />}
  aria-controls="panel2a-content"
  id="panel2a-header"
>
  <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

<Wrapper canvas style={{ display: "inline-block" }} id="random-id-916" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
</Wrapper>
  </Typography>
</AccordionSummary>
<AccordionDetails>
  <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #A2573833', padding:'10px 0px 0px 0px'}}>
    
<Wrapper canvas style={{ display: "inline-block" }} id="random-id-917" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
</Wrapper>
  </Typography>
</AccordionDetails>
</Accordion>
<Accordion  sx={{background:'#FFFFFF', color:'#000000', padding:'5px', border:'1px solid #A2573833', borderRadius:'10px', margin:'10px'}}>
<AccordionSummary
  expandIcon={<ExpandMoreIcon sx={{color:'#A25738', fontSize:'23px'}} />}
  aria-controls="panel2a-content"
  id="panel2a-header"
>
  <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

<Wrapper canvas style={{ display: "inline-block" }} id="random-id-918" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
</Wrapper>
  </Typography>
</AccordionSummary>
<AccordionDetails>
  <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #A2573833', padding:'10px 0px 0px 0px'}}>
    
<Wrapper canvas style={{ display: "inline-block" }} id="random-id-919" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
</Wrapper>
  </Typography>
</AccordionDetails>
</Accordion>
<Accordion  sx={{background:'#FFFFFF', color:'#000000', padding:'5px', border:'1px solid #A2573833', borderRadius:'10px', margin:'10px'}}>
<AccordionSummary
  expandIcon={<ExpandMoreIcon sx={{color:'#A25738', fontSize:'23px'}} />}
  aria-controls="panel2a-content"
  id="panel2a-header"
>
  <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

<Wrapper canvas style={{ display: "inline-block" }} id="random-id-920" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
</Wrapper>
  </Typography>
</AccordionSummary>
<AccordionDetails>
  <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #A2573833', padding:'10px 0px 0px 0px'}}>
    
<Wrapper canvas style={{ display: "inline-block" }} id="random-id-921" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
</Wrapper>
  </Typography>
</AccordionDetails>
</Accordion>
<Accordion  sx={{background:'#FFFFFF', color:'#000000', padding:'5px', border:'1px solid #A2573833', borderRadius:'10px', margin:'10px'}}>
<AccordionSummary
  expandIcon={<ExpandMoreIcon sx={{color:'#A25738', fontSize:'23px'}} />}
  aria-controls="panel2a-content"
  id="panel2a-header"
>
  <Typography sx={{fontSize:'22px', fontWeight:'400px', fontFamily:'Rubik'}}>

<Wrapper canvas style={{ display: "inline-block" }} id="random-id-922" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?" />
</Wrapper>
  </Typography>
</AccordionSummary>
<AccordionDetails>
  <Typography sx={{fontSize:'16px', lineHeight:'25.6px', letterSpacing:'1.5px', fontWeight:'400px', fontFamily:'Rubik', borderTop:'1px solid #A2573833', padding:'10px 0px 0px 0px'}}>
    
<Wrapper canvas style={{ display: "inline-block" }} id="random-id-923" is="div">
<Text text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." />
</Wrapper>
  </Typography>
</AccordionDetails>
</Accordion>
</Box>
</Element> */}
 <Button color="primary" onClick={toggleOpen} style={{ marginBottom: '1rem' }}>
        Toggle
      </Button>
     {open && <div>
        <Card>
          <CardBody>
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
            terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer
            labore wes anderson cred nesciunt sapiente ea proident.
          </CardBody>
        </Card>
      </div>}
</Element>
      </Element>
    ]

const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])

    

    return (
      <div style={{ padding: enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
       {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
                />
          </div>
         ) : (
          styles[sel]
         )} 
  </div>
  );
}

FAQ_1.craft = {
    displayName: "Faq 1",
    props: {
      open:false,
      selected: 1, // here is the default value of the selected style
      length:9,
      isBlock: true
      },
      rules: {
        canDrag: () => true,
        canDrop: () => true,
      },
      related: {
        toolbar: NewFaqSettings,
      },
  };