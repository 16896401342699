/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React from "react";
// import { useDispatch } from "react-redux";
// import { styled } from "styled-components";
// import { startCount } from "../../../redux/features/messages-slice";

// const StyledSelect = styled.select`
//   position: relative;
//   padding: 0.5rem 1rem;
//   border-radius: 5rem;
//   appearance: none;
// `;

const AgeDropdown = (props) => {
  // const dispatch = useDispatch();
  const handleAge = (e) => {
    props.actionProvider.handleUserInput(parseInt(e.target.value));
    setTimeout(() => {
      // dispatch(startCount());
    }, 5000);
  };
  return (
    <select onChange={handleAge} title="Enter your Age">
      <option>Select an Option</option>
      {Array.from({ length: 24 }, (_, i) => i + 18).map((age) => (
        <option key={age} value={age}>
          {age}
        </option>
      ))}
    </select>
  );
};

export default AgeDropdown;
