/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { useEditor, useNode } from '@craftjs/core';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Dropdown from "react-dropdown";
import Editor from "@monaco-editor/react";

import { Input } from 'reactstrap';
import { PopupModal } from './popup';
import allDataBasesQuery from '../../../graphql/queries/allDataBaseQuery';
import {useLazyQuery} from "@apollo/client"
import { makeUniqueId } from '@apollo/client/utilities';


const ToolboxDiv = styled.div`
  // transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  ${(props) => (!props.enabled ? `width: 0;` : '')}
  ${(props) => (!props.enabled ? `opacity: 0;` : '')}
`;

export const WidgetFooter = () => {
  const {
    propValue,
    dataSources,
    node,
    actions: {setProp}
  } = useNode((node) => ({
    propValue: node.data.props["eventsData"],
    dataSources: node.data.props["dataSources"],
    node: node
  }));
  
  const [expanded, setExpanded] = useState([])
  const [codeContent, setCodeContent] = useState("")
  const [eventContent, setEventContent] = useState("")
  
  const monacoRef = useRef(null);

  function handleEditorWillMount(monaco) {
    // here is the monaco instance
    // do something before editor is mounted
    // monaco.languages.typescript.javascriptDefaults.addExtraLib("declare const testVar : () => 3")
    // monaco.languages.typescript.javascriptDefaults.addExtraLib(`declare const allNodesArr: ${JSON.stringify(allNodes)}`)
    // monaco.languages.typescript.javascriptDefaults.addExtraLib(`declare const allKeysArr: ${JSON.stringify(allKeys)}`)
  }

  function handleEditorDidMount(editor, monaco) {
    // here is another way to get monaco instance
    // you can also store it in `useRef` for further usage
    monacoRef.current = editor;
  }


  const [codeError, setCodeError] = useState("")
  function handleEditorValidation(markers) {
    // model markers
    markers?.length ? markers.forEach((marker) => setCodeError(marker.message)) : setCodeError("")
  }

  const {
    enabled,
  } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const [activeId, setActiveId]  = useState(null)
  const [actionHeader, setActionHeader] = useState("")
  const [selectedAction, setSelectedAction] = useState(null)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [selectedOrder, setSelectedOrder] = useState("")
  const [selectedDirection, setSelectedDirection] = useState("")
  const [selectedMethod, setSelectedMethod] = useState("")
  const [selectedDatasource, setSelectedDatasource] = useState(null)
  const [headers, setHeaders] = useState([{id: "9381938917739279381a79897", label: "", value:""}])
  const [filters, setFilters] = useState([{id: "93819389177392793817s9897", label: "", value:""}])
  const [variables, setVariables] = useState([{id: "93819389177392793s8179897", label: "", value:""}])
  const [headerName, setHeaderName] = useState("")
  const [headerValue, setHeaderValue] = useState("")
  const [paramName, setParamName] = useState("")
  const [paramValue, setParamValue] = useState("")
  const [body, setBody] = useState("")
  const [params, setParams] = useState([{id: "938193891773927938179897", label: "", value:""}])
  


  const [activeHeader, setActiveHeader] = useState("Actions")
  const [activeTab, setActiveTab] = useState("header")
  const [toolOpen, setToolOpen] = useState(false)
  const [treeData, setTreeData] = useState([{_id:2,name:"Widget Actions"}]) 

  const [datasources, setDatasources] = useState([{_id:2,name:"Tables"}]) 

  function generateRandomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return makeUniqueId();
  }

  useEffect(() => {
    if(propValue) {
      setTreeData([{_id:2,name:"Widget Actions"}, ...propValue])
    }
  },[propValue])



  useEffect(() => {
    if(dataSources) {
      setDatasources([{_id:2,name:"Tables"}, ...dataSources.map(x => {
        return {
          ...x,
          done:true
        }
      })])
    }
  },[dataSources])


  useEffect(() => {
    if(activeHeader) {
      let selected = datasources.find((x,i)=>i == 1)
      if(selected) {
        setSelectedDatasource(selected.datasource)
        setconnectionString(selected.url)
        setTableName(selected.table)
        setDbName(selected.db)
      }
    }
  },[activeHeader])


  useEffect(() => {
    if(activeId) {
      let run = treeData.find(x=>x._id == activeId)
      
      if(run) {
        setSelectedEvent(run.eventName)
        setSelectedAction({label: run.eventType, value: run.eventType})
        setEventContent(run.eventContent)
      }
      
    }
  },[activeId])


  useEffect(() => {
    if(activeId) {
      let selected = datasources.find(x=>x._id == activeId)
      if(selected) {
        setSelectedDatasource(selected.datasource)
        setconnectionString(selected.url)
        setTableName(selected.table)
        setDbName(selected.db)
      }
    }
  },[activeId])


  function TreeView({ data, datasource }) {
  

    const handleToggle = (nodeId) => {
      setActiveId(nodeId)
      setTableName(null)
      setDbName(null)
    }

    const [isVisible, setIsVisible] = useState(false)

    function findNodeById(nodeId, nodes) {
      return nodes.find(x => x._id == nodeId)
    }
  
    const handleDoubleClick = (nodeId) => {
      handleEditNode(nodeId)
    }; 
  
    const handleEditNode = (nodeId, newName) => {
      let name = prompt(`New ${datasource ? "Datasource" : "Action"} Name`, "")
      if (name) {
        const nodeToEdit = findNodeById(nodeId, data);
        nodeToEdit.name = newName;
        let newData = [...data.map((x,i) => {
          if(x._id == nodeId) {
            return nodeToEdit
          } else {
            return x
          }
        })]
        datasource ? setDatasources(newData) : setTreeData(newData)
        setExpanded(expanded);
      }      

    }
  
    const handleAddAction = () => {
      // generate a unique ID for the new action
      const newActionId = generateRandomString(15);
  
      let name = prompt(`New ${datasource ? "Datasource" : "Action"} Name`, "")

      if (name) {

      // create a new action object with default values
      const newAction = {
        _id: newActionId,
        name: name,
        children: [],
      };
    setActiveId(newActionId)
    setTableName(null)
    setDbName(null)
     datasource ? setDatasources([...data, newAction]) : setTreeData([...data, newAction])     
    } 
    };

    const renderTree = (nodes, child, level = 0) => {

      return (
        <ul style={{ padding: 0, width: "100%" }}>
          {nodes.map((node, i) => {
            
            return (
              <li key={node._id}>
                <div
                  style={{
                    width: "100%",
                    borderTop: i == 0 ? "1px solid #2d3038" : "0px",
                    cursor: "pointer",
                    background: "#959dad14",
                    color: activeId == node._id ? "#6db290" : "white"
                  }}
                  className="viewNC"
                  onClick={() => {
                    i == 0 ? setIsVisible(true) : handleToggle(node._id)
                  }}
                  onDoubleClick={() => { 
                    handleDoubleClick(node._id);
                  }}
                >
                <div style={{width:"1.9rem", height:"1.6rem", background: !child ? "rgba(171, 176, 185, .6)" : "transparent", borderRadius:"3px"}}>{!child ? <svg width="100%" height="100%" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.794 12.104a.644.644 0 00.393-.524 4.904 4.904 0 014.884-4.372 4.91 4.91 0 014.834 4.026.643.643 0 00.626.527 4.53 4.53 0 014.47 4.515 4.521 4.521 0 01-4.517 4.516H9.516a4.514 4.514 0 01-1.722-8.689z" fill="#959dad"></path></svg>: null}</div> 
  
                  <span className="viewWN" style={{width:"100%", padding:".125rem .625rem", marginLeft:`${level * 5}px`}}>{node.name}</span>
                  {i == 0 ? <PopupModal
                  isVisible={isVisible}
                  setIsVisible={setIsVisible}
                  onClick={(e) => e.stopPropagation()} onButtonclicked={() => handleToggle(node._id)}  >
                  <div className='viewNC buttonNC dmColor' style={{margin:"10px auto"}} onClick={() => {
                    
                    handleAddAction(node._id)
                    
                    }}>Add {datasource ? "Datasource" : "Action" }</div>
                </PopupModal> : null}
                 </div>  
              </li>
            );
          })}
        </ul>
      );
    };
  
    return <div style={{width:"100%"}}>{renderTree(data)}</div>;
  }

  const addEvent = (id) => {
    let event = {}
    event._id =  treeData.find(x => x._id ==  id)?._id || generateRandomString(15)
    event.name = treeData.find(x => x._id ==  id)?.name || "New Run" 
    event.eventName = selectedEvent
    event.eventType = selectedAction?.value
    event.eventContent = eventContent

    setProp((prop) => {
      prop.eventsData = [...prop.eventsData, event]
    }, 500)
    setExpanded([])
    setToolOpen(false)
  }

  
  const [allDataBases, { error: dataBaseError, data: dataBasesData, loading: dataBasesLoading, refetch: dataBasesRefetch }] = useLazyQuery(allDataBasesQuery , {
    fetchPolicy: "no-cache",
  });


  const [ dataBaseAllDb, setDataBaseAllDb ] = useState(null)
  const [ dataBaseAllTable, setDataBaseAllTable ] = useState(null)


  const [connectionString, setconnectionString] = useState("")
  const [dbName, setDbName] = useState(null)
  const [tableName, setTableName] = useState(null)
  const [logs, setLogs] = useState([])
  let load = connectionString && tableName && dbName ? true : false

  const testConnection = (id) => {

    let datasourceOption = {}
    datasourceOption.url = connectionString
    datasourceOption.table = tableName
    datasourceOption.db = dbName?.value
    datasourceOption.load = load
 
    allDataBases({
      variables: { 
        ...datasourceOption  
      }
    })       
    let newDatasource = {...datasourceOption}
    newDatasource._id =  datasources.find(x => x._id ==  id)?._id || generateRandomString(15)
    newDatasource.name = datasources.find(x => x._id ==  id)?.name || "New Connection" 
    newDatasource.datasource = selectedDatasource
    setSelectedDatasource(newDatasource)

  }


  useEffect(() => {
    if(dataBasesData) {
    if(!dataBasesData?.allDataBases?.done) {
      if(dataBasesData?.allDataBases?.db && !dataBasesData?.allDataBases?.table) {
          setDataBaseAllDb(JSON.parse(dataBasesData?.allDataBases?.db))
      }
      if(dataBasesData?.allDataBases?.table) {
          setDataBaseAllTable(JSON.parse(dataBasesData?.allDataBases?.table))
      }
    } else {
      let response = dataBasesData.allDataBases

      setProp((prop) => {
        prop.dataSources = [...prop.dataSources, {...selectedDatasource, done: true}]
      }, 500)

      setTableName(null)
      setDbName(null)
      setLogs([
        ...logs,
        { type: 'info', message: 'Database reponsess', details: JSON.stringify(response) || connectionString },
      ])
    }
  }
  },[dataBasesData])


  function formatJSONString(jsonString) {
    // Parse JSON string
    const data = JSON.parse(jsonString);
    
    // Format each object into a new line
    const formattedString = data.map(obj => JSON.stringify(obj, null, 4)).join('\n');
    
    return formattedString;
}

  useEffect(() => {
    if(logs) {
      setActiveHeader("Logs")
    }
  },[logs])

  if(enabled) { 
    return (
    <ToolboxDiv
      enabled={true}
      className="toolbox transition w-12 h-full flex flex-row spaceBetween"
      style={{ width:`100vw`, fontSize:"1.3vmin", padding:"7px", position:"relative", minHeight:toolOpen ? "fit-content" : "2.5rem", boxShadow: "0 4px 1rem #101426b3",
      backgroundColor:"#1f2026"}}
    >

{
      toolOpen ? (
        activeHeader == "Actions" ? (
          <div style={{height:"500px", width:"100%", display:"flex"}}>
             <div style={{width:"15%" , height:"100%", borderRight:"1px solid #2d3038"}}>
              <TreeView data={treeData} />
             </div>
             {activeId ? <div style={{width:"85%" , height:"100%", height:"100%", overflow:"auto",  background:"#16171c", display:"flex", justifyContent:"flex-start", alignItems:"center", flexDirection:"column" }}>
<div style={{height:"fit-content", display:"flex", width:"99%", alignItems:"flex-start", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<Dropdown                    
controlClassName="auth-input-dm-top half"
placeholder="Select Event"
arrowOpen={<div></div>}
arrowClosed={<div></div>}
value={selectedEvent}
onChange={(e) => {
  setSelectedEvent(e.value)
}}
options={[
  {
    type: 'group', label: 'Events', items: [
        {label:"onColumnReorder", value:"onColumnReorder", type:"DataGrid"},
        {label:"onColumnResize", value:"onColumnResize", type:"DataGrids"},
        {label:"onContextMenu", value:"onContextMenu", type:"DataGrids"},
        {label:"onDataStateChange", value:"onDataStateChange", type:"DataGrids"},
        {label:"onFilterChange", value:"onFilterChange", type:"DataGrids"},
        {label:"onHeaderSelectionChange", value:"onHeaderSelectionChange", type:"DataGrids"},
        {label:"onItemChange", value:"onItemChange", type:"DataGrids"},
        {label:"GridKeyDownEvent", value:"GridKeyDownEvent", type:"DataGrids"},
        {label:"onPageChange", value:"onPageChange", type:"DataGrids"},
        {label:"onRowClick", value:"onRowClick", type:"DataGrids"},
        {label:"onRowDoubleClick", value:"onRowDoubleClick", type:"DataGrids"},
        {label:"onSelectionChange", value:"onSelectionChange", type:"DataGrids"},
        {label:"onSortChange", value:"onSortChange", type:"DataGrids"}
    ]
   }
]} menuClassName="menu-dm-top colored" 
/>

          

{selectedEvent ? (
    <>
<Dropdown                                   
controlClassName="auth-input-dm-top half"
placeholder="Select Action"
arrowOpen={<div></div>}
arrowClosed={<div></div>}
value={selectedAction}
onChange={(e) => {
  setSelectedAction(e)
}}
options={[
  {
    type: 'group', label: 'Data', items: [
      {value:"HTTP Request", label:"HTTP Request"},
      {value:"SQL Query", label:"SQL Query"},
      {value:"GraphQL Query", label:"GraphQL Query"},
      {value:"Load Table", label:"Load Table"},
      {value:"Load Row", label:"Load Row"},
      {value:"Create Row", label:"Create Row"},
      {value:"Update Row", label:"Update Row"},
      {value:"Delete Row", label:"Delete Row"},
      {value:"DynamoDB Request", label:"DynamoDB Request"},
      {value:"Redis Command", label:"Redis Command"},
      {value:"S3 Query", label:"S3 Query"},
      {value:"Firebase Query", label:"Firebase Query"},
      {value:"Slack Message", label:"Slack Message"},
    ]},
    {
    type: 'group', label: 'Operations', items: [
      {value:"Code",label:"Code"},
      {value:"Execute Action",label:"Execute Action"},
      {value:"Loop Action",label:"Loop Action"},
      {value:"Interval",label:"Interval"},
      {value:"Save to State",label:"Save to State"},
      {value:"Save to Local Storage",label:"Save to Local Storage"},
      {value:"Navigate",label:"Navigate"},
      {value:"Generate File",label:"Generate File"},
      {value:"Show Notification",label:"Show Notification"},
    ]
    },
]} 
menuClassName="menu-dm-top colored" 
/>
</>
): null}

</div>
<div style={{width:"100%", padding:"10px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
{selectedAction?.value == "HTTP Request" ? (
  <>
  
  <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}>Request</div>

  <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
            <Dropdown
            baseClassName="pxMiniWidth Dropdown"
            controlClassName="pxMini auth-input-dm-top half"
            placeholder="Method"
            arrowOpen={<div></div>}
            arrowClosed={<div></div>}
            value={selectedMethod}
            menuClassName="pxMini menu-dm-top colored" 
            onChange={(e) => {
              setSelectedMethod(e)
            }}
            options={[
            {value:"GET", label:"GET"}, 
            {value:"POST", label:"POST"},
            {value:"DELETE", label:"DELETE"},
            {value:"PUT", label:"PUT"},
            {value:"HEAD", label:"HEAD"},
            {value:"OPTIONS", label:"Create Row"},
            {value:"PATCH", label:"PATCH"},
                ] 
            }/>

        <Input
        style={{width:"130px",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Base URL"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />

<Input
        style={{width:"100%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="URL"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />

  </div>
  <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", color:"#959dad"}}>
    
 
      <div className={`tab-dm ${activeTab == "header" ? "activeHeaderForDm" : ""}`} onClick={() => {
        
        setActiveTab("header")
        }}>Header</div>
      <div className={`tab-dm ${activeTab == "body" ? "activeHeaderForDm" : ""}`} onClick={() => {
        
        setActiveTab("body")
        }}>Body</div>
      <div className={`tab-dm ${activeTab == "params" ? "activeHeaderForDm" : ""}`} onClick={() => {
        setActiveTab("params")
        }}>Params</div>
    </div>

 

 { activeTab == "header" ?  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{headers.map(header => (
  <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
      <Dropdown
            baseClassName="pxMiniWidth ten Dropdown"
            controlClassName="pxMini auth-input-dm-top half"
            placeholder="Header"
            arrowOpen={<div></div>}
            arrowClosed={<div></div>}
            value={header?.name ? {value: header.name, label: header.name} : ""}
            menuClassName="pxMini menu-dm-top colored" 
            onChange={(e) => {
              let newHeader = header
              const newHeaderId = generateRandomString(15)
              header.id = header.id || newHeaderId
              header.name = e.value
              setHeaders(headers.map(x => x.id == header.id ? newHeader : x))
            }}
            options={[

{value:"Accept-Charset", label:"Accept-Charset"},
{value:"Accept-Encoding", label:"Accept-Encoding"},
{value:"Accept-Language", label:"Accept-Language"},
{value:"Accept-Datetime", label:"Accept-Datetime"},
{value:"Access-Control-Request-Method", label:"Access-Control-Request-Method"},
{value:"Access-Control-Request-Headers", label:"Access-Control-Request-Headers"},
{value:"Authorization", label:"Authorization"},
{value:"Cache-Control", label:"Cache-Control"},
{value:"Connection", label:"Connection"},
{value:"Content-Length", label:"Content-Length"},
{value:"Content-Type", label:"Content-Type"},
{value:"Cookie", label:"Cookie"},
{value:"Date", label:"Date"},
{value:"Expect", label:"Expect"},
{value:"Forwarded", label:"Forwarded"},
{value:"From", label:"From"},
{value:"Host", label:"Host"},
{value:"If-Match", label:"If-Match"},
{value:"If-Modified-Since", label:"If-Modified-Since"},
{value:"If-None-Match", label:"If-None-Match"},
{value:"If-Range", label:"If-Range"},
{value:"If-Unmodified-Since", label:"If-Unmodified-Since"},
{value:"Max-Forwards", label:"Max-Forwards"},
{value:"Origin", label:"Origin"},
{value:"Proxy-Authorization", label:"Proxy-Authorization"},
{value:"Referer", label:"Referer"},
{value:"User-Agent", label:"User-Agent"},
{value:"Upgrade", label:"Upgrade"},
                ] 
            }/>
<Input
        style={{width:"90%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="URL"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
       {/* <img 
       style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
       className="actionHover" 
       onClick={() => {
        setHeaders(headers.filter(x => x.id !== header.id))
       }} 
       src={trash}/> */}
  </div>

))}
</div>
<div onClick={() => {
  setHeaders([...headers, {id: generateRandomString(15), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add header</div>

</div> : activeTab == "body" ? (
  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>

</div>
</div>
) : activeTab == "params" ? (
<div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{params.map(param => (
  <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
     <Input
        style={{width:"10%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Param"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
<Input
        style={{width:"90%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Param Value"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
       {/* <img 
       style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
       className="actionHover" 
       onClick={() => {
        setParams(params.filter(x => x.id !== param.id))
       }} 
       src={trash}/> */}
  </div>

))}
</div>
<div onClick={() => {
  setParams([...params, {id: generateRandomString(15), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add param</div>

</div>
) : null}

</>
) : selectedAction?.value == "SQL Query" ? (

  <>

<div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}>SQL QUERY
</div>


<div style={{color:"white", fontSize:"10px",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}>
Load your data with SQL query. Use component references like {`{{ui.input.value}}`}to send values from forms and inputs.
</div>


  <div style={{ display:"flex", justifyContent:"center", alignItems:"center" ,width:"100%", height:"200px"}}>
  <Editor
  height="90%"
  width="95%"
  defaultLanguage="mysql"
  onValidate={handleEditorValidation}
  onChange={(e) => setCodeContent(e)}
  beforeMount={handleEditorWillMount}
  onMount={handleEditorDidMount}
  />
  </div>
  </>
) : selectedAction?.value == "GraphQL Query" ? (
  <>
  <Input
        style={{width:"98%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="URL"
        className="auth-input-dm-class"
  />
<div style={{marginTop:"20px", height:"200px", width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
<Editor
  height="90%"
  width="95%"
  defaultLanguage="mysql"
  onValidate={handleEditorValidation}
  onChange={(e) => setCodeContent(e)}
  beforeMount={handleEditorWillMount}
  onMount={handleEditorDidMount}
  />
  </div>

  <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", color:"#959dad"}}>
    
 
    <div className={`tab-dm ${activeTab == "header" ? "activeHeaderForDm" : ""}`} onClick={() => {
      
      setActiveTab("header")
      }}>Header</div>
    <div className={`tab-dm ${activeTab == "variables" ? "activeHeaderForDm" : ""}`} onClick={() => {
      
      setActiveTab("variables")
      }}>Variables</div>
    <div className={`tab-dm ${activeTab == "params" ? "activeHeaderForDm" : ""}`} onClick={() => {
      setActiveTab("params")
      }}>Params</div>
  </div>



{ activeTab == "header" ?  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{headers.map(header => (
<div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
    <Dropdown
          baseClassName="pxMiniWidth ten Dropdown"
          controlClassName="pxMini auth-input-dm-top half auth-input-dm-class"
          placeholder="Header"
          arrowOpen={<div></div>}
          arrowClosed={<div></div>}
          value={header?.name ? {value: header.name, label: header.name} : ""}
          menuClassName="pxMini menu-dm-top colored" 
          onChange={(e) => {
            let newHeader = header
            const newHeaderId = generateRandomString(15)
            header.id = header.id || newHeaderId
            header.name = e.value
            setHeaders(headers.map(x => x.id == header.id ? newHeader : x))
          }}
          options={[

{value:"Accept-Charset", label:"Accept-Charset"},
{value:"Accept-Encoding", label:"Accept-Encoding"},
{value:"Accept-Language", label:"Accept-Language"},
{value:"Accept-Datetime", label:"Accept-Datetime"},
{value:"Access-Control-Request-Method", label:"Access-Control-Request-Method"},
{value:"Access-Control-Request-Headers", label:"Access-Control-Request-Headers"},
{value:"Authorization", label:"Authorization"},
{value:"Cache-Control", label:"Cache-Control"},
{value:"Connection", label:"Connection"},
{value:"Content-Length", label:"Content-Length"},
{value:"Content-Type", label:"Content-Type"},
{value:"Cookie", label:"Cookie"},
{value:"Date", label:"Date"},
{value:"Expect", label:"Expect"},
{value:"Forwarded", label:"Forwarded"},
{value:"From", label:"From"},
{value:"Host", label:"Host"},
{value:"If-Match", label:"If-Match"},
{value:"If-Modified-Since", label:"If-Modified-Since"},
{value:"If-None-Match", label:"If-None-Match"},
{value:"If-Range", label:"If-Range"},
{value:"If-Unmodified-Since", label:"If-Unmodified-Since"},
{value:"Max-Forwards", label:"Max-Forwards"},
{value:"Origin", label:"Origin"},
{value:"Proxy-Authorization", label:"Proxy-Authorization"},
{value:"Referer", label:"Referer"},
{value:"User-Agent", label:"User-Agent"},
{value:"Upgrade", label:"Upgrade"},
              ] 
          }/>
<Input
      style={{width:"90%",margin:"1px 5px"}}
      type={"text"}
      name="styling"
      defaultValue={""}
      maxLength={128}
      placeholder="URL"
      // onKeyDown={(e) => {
      //   if (e.key === "Enter") {
      //     handleLoginClick();
      //   }
      // }}
      className="auth-input-dm-class"
    />
     {/* <img 
     style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
     className="actionHover" 
     onClick={() => {
      setHeaders(headers.filter(x => x.id !== header.id))
     }} 
     src={trash}/> */}
</div>

))}
</div>
<div onClick={() => {
setHeaders([...headers, {id: generateRandomString(15), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add header</div>

</div> : activeTab == "variables" ? (
  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{variables.map(variable => (
<div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
   <Input
      style={{width:"10%",margin:"1px 5px"}}
      type={"text"}
      name="styling"
      defaultValue={""}
      maxLength={128}
      placeholder="Variable"
      // onKeyDown={(e) => {
      //   if (e.key === "Enter") {
      //     handleLoginClick();
      //   }
      // }}
      className="auth-input-dm-class"
    />
<Input
      style={{width:"90%",margin:"1px 5px"}}
      type={"text"}
      name="styling"
      defaultValue={""}
      maxLength={128}
      placeholder="Variable Value"
      // onKeyDown={(e) => {
      //   if (e.key === "Enter") {
      //     handleLoginClick();
      //   }
      // }}
      className="auth-input-dm-class"
    />
     {/* <img 
     style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
     className="actionHover" 
     onClick={() => {
      setVariables(variables.filter(x => x.id !== variable.id))
     }} 
     src={trash}/> */}
</div>

))}
</div>
<div onClick={() => {
setVariables([...variables, {id: generateRandomString(15), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add variable</div>

</div>
) : activeTab == "params" ? (
<div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{params.map(param => (
<div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
   <Input
      style={{width:"10%",margin:"1px 5px"}}
      type={"text"}
      name="styling"
      defaultValue={""}
      maxLength={128}
      placeholder="Param"
      // onKeyDown={(e) => {
      //   if (e.key === "Enter") {
      //     handleLoginClick();
      //   }
      // }}
      className="auth-input-dm-class"
    />
<Input
      style={{width:"90%",margin:"1px 5px"}}
      type={"text"}
      name="styling"
      defaultValue={""}
      maxLength={128}
      placeholder="Param Value"
      // onKeyDown={(e) => {
      //   if (e.key === "Enter") {
      //     handleLoginClick();
      //   }
      // }}
      className="auth-input-dm-class"
    />
     {/* <img 
     style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
     className="actionHover" 
     onClick={() => {
      setParams(params.filter(x => x.id !== param.id))
     }} 
     src={trash}/> */}
</div>

))}
</div>
<div onClick={() => {
setParams([...params, {id: generateRandomString(15), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add param</div>

</div>
) : null}
    </>
) : selectedAction?.value == "Load Table" ? (
<>
<div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"0px", color:"#959dad"}}>Filters</div>

<div style={{color:"white",marginTop:"20px", fontSize:"10px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}><span style={{color:"white"}}>Configure filter to retrieve records.</span> &nbsp; Use component references like {`{{ui.input.value}}`} to send values from forms and inputs.</div>

<div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{filters.map(filter => (
  <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
      <Dropdown
                  baseClassName="pxMiniWidth Dropdown"
                  controlClassName="pxMini auth-input-dm-top half"
                  placeholder="Key"
                  arrowOpen={<div></div>}
                  arrowClosed={<div></div>}
                  value={filter?.name ? {value: filter.name, label: filter.name} : ""}
                  menuClassName="pxMini menu-dm-top colored" 
                  onChange={(e) => {
                    let newFilter = filter
                    const newFilterId = generateRandomString(15)
                    filter.id = filter.id || newFilterId
                    filter.name = e.value
                    setFilters(filters.map(x => x.id == filter.id ? newFilter : x))
                  }}
                  options={[]} 
      />
      <Dropdown
            baseClassName="pxMiniWidth Dropdown"
            controlClassName="pxMini auth-input-dm-top half"
            placeholder="operator"
            arrowOpen={<div></div>}
            arrowClosed={<div></div>}
            value={filter?.name ? {value: filter.name, label: filter.name} : ""}
            menuClassName="pxMini menu-dm-top colored" 
            onChange={(e) => {
              let newFilter = filter
              const newFilterId = generateRandomString(15)
              filter.id = filter.id || newFilterId
              filter.name = e.value
              setFilters(headers.map(x => x.id == filter.id ? newFilter : x))
            }}
            options={[
              {label:"=", value:"="},
              {label:"!=", value:"!="},
              {label:"<=", value:"<="},
              {label:"<", value:"<"},
              {label:">", value:">"},
              {label:">=", value:">="},
              {label:"like", value:"like"},
              {label:"in", value:"in"},

                ] 
            }/>
<Input
        style={{width:"90%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="{{ui.input.value}}"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
       {/* <img 
       style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
       className="actionHover" 
       onClick={() => {
        setFilters(filters.filter(x => x.id !== filter.id))
       }} 
       src={trash}/> */}
  </div>
))}
</div>
<div onClick={() => {
  setFilters([...filters, {id: generateRandomString(15), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add filter</div>
</div>

<div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"0px", color:"#959dad"}}>LIMITS & ORDER</div>
<div style={{color:"white",marginTop:"20px", fontSize:"10px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}>Configure how your data will be limited and sorted.</div>

  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{display:"flex", width:"100%", justifyContent:"center", margin:"4px 0"}}>
  <Input
        style={{width:"50%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Limit"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
      <Input
      style={{width:"50%",margin:"1px 5px"}}
      type={"text"}
      name="styling"
      defaultValue={""}
      maxLength={128}
      placeholder="Offset"
      // onKeyDown={(e) => {
      //   if (e.key === "Enter") {
      //     handleLoginClick();
      //   }
      // }}
      className="auth-input-dm-class"
    />
    </div>
    <div style={{display:"flex", width:"100%", justifyContent:"center", margin:"4px 0"}}>
    <Dropdown                                
controlClassName="auth-input-dm-top half"
placeholder="Order by"
arrowOpen={<div></div>}
arrowClosed={<div></div>}
value={selectedOrder}
menuClassName="menu-dm-top colored" 
onChange={(e) => {
  setSelectedOrder(e.value)
}}
options={[
]}
/>
  
  <Dropdown                                
controlClassName="auth-input-dm-top half"
placeholder="Direction"
arrowOpen={<div></div>}
arrowClosed={<div></div>}
value={selectedDirection}
menuClassName="menu-dm-top colored" 
onChange={(e) => {
  setSelectedDirection(e.value)
}}
options={[
  {label:"ASC", value:"ASC"},
  {label:"DESC", value:"DESC"},
]}
/>
  
</div>
</div>

<div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"0px", color:"#959dad"}}>ADDITIONAL PARAMS</div>
<div style={{color:"white",marginTop:"20px", fontSize:"10px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}>Configure additional API parameters (Airtable only).</div>

  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
  <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{params.map(param => (
  <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
     <Input
        style={{width:"10%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Param"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
<Input
        style={{width:"90%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Param Value"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
       {/* <img 
       style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
       className="actionHover" 
       onClick={() => {
        setParams(params.filter(x => x.id !== param.id))
       }} 
       src={trash}/> */}
  </div>

))}
</div>
<div onClick={() => {
  setParams([...params, {id: generateRandomString(15), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add param</div>

</div>

</>

) : selectedAction?.value == "Load Row" ? (
  <>
  <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"0px", color:"#959dad"}}>Filters</div>
  
  <div style={{color:"white",marginTop:"20px", fontSize:"10px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}><span style={{color:"white"}}>Configure filter to retrieve records.</span> &nbsp; Use component references like {`{{ui.input.value}}`} to send values from forms and inputs.</div>
  
  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
  <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
  {filters.map(filter => (
    <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
        <Dropdown
                    baseClassName="pxMiniWidth Dropdown"
                    controlClassName="pxMini auth-input-dm-top half"
                    placeholder="Key"
                    arrowOpen={<div></div>}
                    arrowClosed={<div></div>}
                    value={filter?.name ? {value: filter.name, label: filter.name} : ""}
                    menuClassName="pxMini menu-dm-top colored" 
                    onChange={(e) => {
                      let newFilter = filter
                      const newFilterId = generateRandomString(15)
                      filter.id = filter.id || newFilterId
                      filter.name = e.value
                      setFilters(filters.map(x => x.id == filter.id ? newFilter : x))
                    }}
                    options={[]} 
        />
        <Dropdown
              baseClassName="pxMiniWidth Dropdown"
              controlClassName="pxMini auth-input-dm-top half"
              placeholder="operator"
              arrowOpen={<div></div>}
              arrowClosed={<div></div>}
              value={filter?.name ? {value: filter.name, label: filter.name} : ""}
              menuClassName="pxMini menu-dm-top colored" 
              onChange={(e) => {
                let newFilter = filter
                const newFilterId = generateRandomString(15)
                filter.id = filter.id || newFilterId
                filter.name = e.value
                setFilters(headers.map(x => x.id == filter.id ? newFilter : x))
              }}
              options={[
                {label:"=", value:"="},
                {label:"!=", value:"!="},
                {label:"<=", value:"<="},
                {label:"<", value:"<"},
                {label:">", value:">"},
                {label:">=", value:">="},
                {label:"like", value:"like"},
                {label:"in", value:"in"},
  
                  ] 
              }/>
  <Input
          style={{width:"90%",margin:"1px 5px"}}
          type={"text"}
          name="styling"
          defaultValue={""}
          maxLength={128}
          placeholder="{{ui.input.value}}"
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     handleLoginClick();
          //   }
          // }}
          className="auth-input-dm-class"
        />
         {/* <img 
         style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
         className="actionHover" 
         onClick={() => {
          setFilters(filters.filter(x => x.id !== filter.id))
         }} 
         src={trash}/> */}
    </div>
  ))}
  </div>
  <div onClick={() => {
    setFilters([...filters, {id: generateRandomString(15), label:"", value:""}])
  }} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add filter</div>
    
  </div>
  
  </>
  
  ) : selectedAction?.value == "Create Row" ? (
    <>
    <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"0px", color:"#959dad"}}>CONFIGURE FIELDS
</div>
    
    <div style={{color:"white",marginTop:"20px", fontSize:"10px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}><span style={{color:"white"}}>Configure new record fields. </span> &nbsp; Use component references like {`{{ui.input.value}}`} to send values from forms and inputs.</div>
    
    <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
    <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
    <div style={{ display:"flex", justifyContent:"center", alignItems:"center" ,width:"100%", height:"200px"}}>
  <Editor
  height="90%"
  width="95%"
  defaultLanguage="mysql"
  onValidate={handleEditorValidation}
  onChange={(e) => setCodeContent(e)}
  beforeMount={handleEditorWillMount}
  onMount={handleEditorDidMount}
  />
  </div>
    </div>
    </div>
    
    </>
    
    ) : selectedAction?.value == "Update Row" ? (
      <>
      <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"0px", color:"#959dad"}}>CONFIGURE FIELDS
  </div>
      
      <div style={{color:"white",marginTop:"20px", fontSize:"10px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}><span style={{color:"white"}}>1. Configure filter to update record(s).</span> &nbsp; Use component references like {`{{ui.input.value}}`} to send values from forms and inputs.</div>
      
      <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
  {filters.map(filter => (
    <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
        <Dropdown
                    baseClassName="pxMiniWidth Dropdown"
                    controlClassName="pxMini auth-input-dm-top half"
                    placeholder="Key"
                    arrowOpen={<div></div>}
                    arrowClosed={<div></div>}
                    value={filter?.name ? {value: filter.name, label: filter.name} : ""}
                    menuClassName="pxMini menu-dm-top colored" 
                    onChange={(e) => {
                      let newFilter = filter
                      const newFilterId = generateRandomString(15)
                      filter.id = filter.id || newFilterId
                      filter.name = e.value
                      setFilters(filters.map(x => x.id == filter.id ? newFilter : x))
                    }}
                    options={[]} 
        />
        <Dropdown
              baseClassName="pxMiniWidth Dropdown"
              controlClassName="pxMini auth-input-dm-top half"
              placeholder="operator"
              arrowOpen={<div></div>}
              arrowClosed={<div></div>}
              value={filter?.name ? {value: filter.name, label: filter.name} : ""}
              menuClassName="pxMini menu-dm-top colored" 
              onChange={(e) => {
                let newFilter = filter
                const newFilterId = generateRandomString(15)
                filter.id = filter.id || newFilterId
                filter.name = e.value
                setFilters(headers.map(x => x.id == filter.id ? newFilter : x))
              }}
              options={[
                {label:"=", value:"="},
                {label:"!=", value:"!="},
                {label:"<=", value:"<="},
                {label:"<", value:"<"},
                {label:">", value:">"},
                {label:">=", value:">="},
                {label:"like", value:"like"},
                {label:"in", value:"in"},
  
                  ] 
              }/>
  <Input
          style={{width:"90%",margin:"1px 5px"}}
          type={"text"}
          name="styling"
          defaultValue={""}
          maxLength={128}
          placeholder="{{ui.input.value}}"
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     handleLoginClick();
          //   }
          // }}
          className="auth-input-dm-class"
        />
         {/* <img 
         style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
         className="actionHover" 
         onClick={() => {
          setFilters(filters.filter(x => x.id !== filter.id))
         }} 
         src={trash}/> */}
    </div>
  ))}
  </div>
  <div onClick={() => {
    setFilters([...filters, {id: generateRandomString(15), label:"", value:""}])
  }} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add filter</div>
    
      
      <div style={{color:"white",marginTop:"20px", fontSize:"10px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}><span style={{color:"white"}}>2. Configure record fields to update. </span> &nbsp; Use component references like {`{{ui.input.value}}`} to send values from forms and inputs.</div>

      <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
      <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
      <div style={{ display:"flex", justifyContent:"center", alignItems:"center" ,width:"100%", height:"200px"}}>
    <Editor
    height="90%"
    width="95%"
    defaultLanguage="mysql"
    onValidate={handleEditorValidation}
    onChange={(e) => setCodeContent(e)}
    beforeMount={handleEditorWillMount}
    onMount={handleEditorDidMount}
    />
    </div>
      </div>
      </div>
      
      </>
      
      ) : selectedAction?.value == "Delete Row" ? (
        <>
        <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"0px", color:"#959dad"}}>Filters</div>
        
        <div style={{color:"white",marginTop:"20px", fontSize:"10px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}><span style={{color:"white"}}>Configure filter to delete record(s).</span> &nbsp; Use component references like {`{{ui.input.value}}`} to send values from forms and inputs.</div>
        
        <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
        <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
        {filters.map(filter => (
          <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
              <Dropdown
                          baseClassName="pxMiniWidth Dropdown"
                          controlClassName="pxMini auth-input-dm-top half"
                          placeholder="Key"
                          arrowOpen={<div></div>}
                          arrowClosed={<div></div>}
                          value={filter?.name ? {value: filter.name, label: filter.name} : ""}
                          menuClassName="pxMini menu-dm-top colored" 
                          onChange={(e) => {
                            let newFilter = filter
                            const newFilterId = generateRandomString(15)
                            filter.id = filter.id || newFilterId
                            filter.name = e.value
                            setFilters(filters.map(x => x.id == filter.id ? newFilter : x))
                          }}
                          options={[]} 
              />
              <Dropdown
                    baseClassName="pxMiniWidth Dropdown"
                    controlClassName="pxMini auth-input-dm-top half"
                    placeholder="operator"
                    arrowOpen={<div></div>}
                    arrowClosed={<div></div>}
                    value={filter?.name ? {value: filter.name, label: filter.name} : ""}
                    menuClassName="pxMini menu-dm-top colored" 
                    onChange={(e) => {
                      let newFilter = filter
                      const newFilterId = generateRandomString(15)
                      filter.id = filter.id || newFilterId
                      filter.name = e.value
                      setFilters(headers.map(x => x.id == filter.id ? newFilter : x))
                    }}
                    options={[
                      {label:"=", value:"="},
                      {label:"!=", value:"!="},
                      {label:"<=", value:"<="},
                      {label:"<", value:"<"},
                      {label:">", value:">"},
                      {label:">=", value:">="},
                      {label:"like", value:"like"},
                      {label:"in", value:"in"},
        
                        ] 
                    }/>
        <Input
                style={{width:"90%",margin:"1px 5px"}}
                type={"text"}
                name="styling"
                defaultValue={""}
                maxLength={128}
                placeholder="{{ui.input.value}}"
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") {
                //     handleLoginClick();
                //   }
                // }}
                className="auth-input-dm-class"
              />
               {/* <img 
               style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
               className="actionHover" 
               onClick={() => {
                setFilters(filters.filter(x => x.id !== filter.id))
               }} 
               src={trash}/> */}
          </div>
        ))}
        </div>
        <div onClick={() => {
          setFilters([...filters, {id: generateRandomString(15), label:"", value:""}])
        }} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add filter</div>
        </div>
        </>
        
        ) :  selectedAction?.value == "Show Notification"  ?  (

          <>
        
        <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}>Show Notification
        </div>
        
        
        <div style={{color:"white", fontSize:"10px",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}>
        Notification content
        </div>

          <div style={{ display:"flex", justifyContent:"center", alignItems:"center" ,width:"100%", height:"200px"}}>
          <Editor
          height="90%"
          width="95%"
          value={eventContent}
          defaultLanguage="mysql"
          onValidate={handleEditorValidation}
          onChange={(e) => setEventContent(e)}
          beforeMount={handleEditorWillMount}
          onMount={handleEditorDidMount}
          />
          </div>
          <div onClick={() => {
            addEvent(activeId)
          }} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>Save</div>
          </>
        ) :  null}
</div>
             </div> : null}
          
          </div>
        ) : activeHeader == "Datasource" ? (
          <div style={{height:"500px", width:"100%", display:"flex"}}>
             <div style={{width:"15%" , height:"100%", borderRight:"1px solid #2d3038"}}>
              <TreeView datasource data={datasources} />
             </div>
             {activeId ? <div style={{width:"85%" , height:"100%", height:"100%", overflow:"auto",  background:"#16171c", display:"flex", justifyContent:"flex-start", alignItems:"center", flexDirection:"column" }}>
<div style={{height:"fit-content", display:"flex", width:"99%", alignItems:"flex-start", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<Dropdown                    
controlClassName="auth-input-dm-top half"
placeholder="Select Datasource"
arrowOpen={<div></div>}
arrowClosed={<div></div>}
value={selectedDatasource}
onChange={(e) => {
  setSelectedDatasource(e.value)
}}
options={[
  {
    type: 'group', label: 'Events', items: [
        {label:"PostgreSQL", value:"PostgreSQL", type:"DataGrid"},
        {label:"MySQL", value:"MySQL", type:"DataGrids"},
        {label:"TextDB", value:"TextDB", type:"DataGrids"},
        {label:"MongoDB", value:"MongoDB", type:"DataGrids"},
    ]
   }
]} menuClassName="menu-dm-top colored" 
/>


</div>
<div style={{width:"100%",  height:"100%", padding:"10px", display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
{selectedDatasource == "MongoDB" ? (
  <>
  
  <div style={{color:"white", marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}>CONNECTION SETTINGS</div>

  <div style={{height:"70%", display:"flex", width:"100%", alignItems:"center", gap:"20px", flexDirection:"column", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
  <div style={{width:"80%"}}>
    <Input
           type={"text"}
           name="styling"
           defaultValue={""}
           maxLength={128} 
           placeholder="Connection string"
           value={connectionString}
           onChange={(e) => setconnectionString(e.target.value)}
           // onKeyDown={(e) => {
           //   if (e.key === "Enter") {
           //     handleLoginClick();
           //   }
           // }}
           className="auth-input-dm-top half"
         />
         </div>
         <div style={{width:"80%"}}>

  {dataBaseAllDb?.databases?.length || dbName ? <Dropdown
            baseClassName="Dropdown"
            controlClassName="auth-input-dm-top half"
            placeholder="Select Datasource"
            arrowOpen={<div></div>}
            arrowClosed={<div></div>}
            value={dbName}
            menuClassName="menu-dm-top colored" 
            onChange={(e) => {
              setDbName(e.value)
            }}
            options={dataBaseAllDb?.databases?.map(x => {
              return {
                label:x.name, value:x.name
              }
            }) || []}/> : null }
         </div>

         <div style={{width:"80%"}}>

            {dataBaseAllTable?.length || tableName ? (
                <Dropdown
                baseClassName="Dropdown"
                controlClassName="auth-input-dm-top half"
                placeholder="Select Table"
                arrowOpen={<div></div>}
                arrowClosed={<div></div>}
                value={tableName}
                menuClassName="menu-dm-top colored" 
                onChange={(e) => {
                  setTableName(e)
                }}
                options={dataBaseAllTable?.map(x => {
                  return {
                    label:x.name, value:x.name
                  }
                }) || []}/>
              ) : null}
              
              </div>
           





  </div>
  <div onClick={() => {
testConnection(activeId)
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>{load ? "Test connection" : "Next"}</div>

</>
) : selectedDatasource == "TextDB" ? (
  <>
  
  <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}>Request</div>

  <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
            <Dropdown
            baseClassName="pxMiniWidth Dropdown"
            controlClassName="pxMini auth-input-dm-top half"
            placeholder="Method"
            arrowOpen={<div></div>}
            arrowClosed={<div></div>}
            value={selectedMethod}
            menuClassName="pxMini menu-dm-top colored" 
            onChange={(e) => {
              setSelectedMethod(e)
            }}
            options={[
            {value:"GET", label:"GET"}, 
            {value:"POST", label:"POST"},
            {value:"DELETE", label:"DELETE"},
            {value:"PUT", label:"PUT"},
            {value:"HEAD", label:"HEAD"},
            {value:"OPTIONS", label:"Create Row"},
            {value:"PATCH", label:"PATCH"},
                ] 
            }/>

        <Input
        style={{width:"130px",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Base URL"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />

<Input
        style={{width:"100%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="URL"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />

  </div>
  <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", color:"#959dad"}}>
    
 
      <div className={`tab-dm ${activeTab == "header" ? "activeHeaderForDm" : ""}`} onClick={() => {
        
        setActiveTab("header")
        }}>Header</div>
      <div className={`tab-dm ${activeTab == "body" ? "activeHeaderForDm" : ""}`} onClick={() => {
        
        setActiveTab("body")
        }}>Body</div>
      <div className={`tab-dm ${activeTab == "params" ? "activeHeaderForDm" : ""}`} onClick={() => {
        setActiveTab("params")
        }}>Params</div>
    </div>

 

 { activeTab == "header" ?  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{headers.map(header => (
  <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
      <Dropdown
            baseClassName="pxMiniWidth ten Dropdown"
            controlClassName="pxMini auth-input-dm-top half"
            placeholder="Header"
            arrowOpen={<div></div>}
            arrowClosed={<div></div>}
            value={header?.name ? {value: header.name, label: header.name} : ""}
            menuClassName="pxMini menu-dm-top colored" 
            onChange={(e) => {
              let newHeader = header
              const newHeaderId = generateRandomString(15)
              header.id = header.id || newHeaderId
              header.name = e.value
              setHeaders(headers.map(x => x.id == header.id ? newHeader : x))
            }}
            options={[

{value:"Accept-Charset", label:"Accept-Charset"},
{value:"Accept-Encoding", label:"Accept-Encoding"},
{value:"Accept-Language", label:"Accept-Language"},
{value:"Accept-Datetime", label:"Accept-Datetime"},
{value:"Access-Control-Request-Method", label:"Access-Control-Request-Method"},
{value:"Access-Control-Request-Headers", label:"Access-Control-Request-Headers"},
{value:"Authorization", label:"Authorization"},
{value:"Cache-Control", label:"Cache-Control"},
{value:"Connection", label:"Connection"},
{value:"Content-Length", label:"Content-Length"},
{value:"Content-Type", label:"Content-Type"},
{value:"Cookie", label:"Cookie"},
{value:"Date", label:"Date"},
{value:"Expect", label:"Expect"},
{value:"Forwarded", label:"Forwarded"},
{value:"From", label:"From"},
{value:"Host", label:"Host"},
{value:"If-Match", label:"If-Match"},
{value:"If-Modified-Since", label:"If-Modified-Since"},
{value:"If-None-Match", label:"If-None-Match"},
{value:"If-Range", label:"If-Range"},
{value:"If-Unmodified-Since", label:"If-Unmodified-Since"},
{value:"Max-Forwards", label:"Max-Forwards"},
{value:"Origin", label:"Origin"},
{value:"Proxy-Authorization", label:"Proxy-Authorization"},
{value:"Referer", label:"Referer"},
{value:"User-Agent", label:"User-Agent"},
{value:"Upgrade", label:"Upgrade"},
                ] 
            }/>
<Input
        style={{width:"90%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="URL"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
       {/* <img 
       style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
       className="actionHover" 
       onClick={() => {
        setHeaders(headers.filter(x => x.id !== header.id))
       }} 
       src={trash}/> */}
  </div>

))}
</div>
<div onClick={() => {
  setHeaders([...headers, {id: generateRandomString(15), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add header</div>

</div> : activeTab == "body" ? (
  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>

</div>
</div>
) : activeTab == "params" ? (
<div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{params.map(param => (
  <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
     <Input
        style={{width:"10%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Param"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
<Input
        style={{width:"90%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Param Value"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
       {/* <img 
       style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
       className="actionHover" 
       onClick={() => {
        setParams(params.filter(x => x.id !== param.id))
       }} 
       src={trash}/> */}
  </div>

))}
</div>
<div onClick={() => {
  setParams([...params, {id: generateRandomString(15), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add param</div>

</div>
) : null}

</>
) : selectedDatasource == "MySQL" ? (
  <>
  
  <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}>Request</div>

  <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
            <Dropdown
            baseClassName="pxMiniWidth Dropdown"
            controlClassName="pxMini auth-input-dm-top half"
            placeholder="Method"
            arrowOpen={<div></div>}
            arrowClosed={<div></div>}
            value={selectedMethod}
            menuClassName="pxMini menu-dm-top colored" 
            onChange={(e) => {
              setSelectedMethod(e)
            }}
            options={[
            {value:"GET", label:"GET"}, 
            {value:"POST", label:"POST"},
            {value:"DELETE", label:"DELETE"},
            {value:"PUT", label:"PUT"},
            {value:"HEAD", label:"HEAD"},
            {value:"OPTIONS", label:"Create Row"},
            {value:"PATCH", label:"PATCH"},
                ] 
            }/>

        <Input
        style={{width:"130px",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Base URL"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />

<Input
        style={{width:"100%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="URL"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />

  </div>
  <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", color:"#959dad"}}>
    
 
      <div className={`tab-dm ${activeTab == "header" ? "activeHeaderForDm" : ""}`} onClick={() => {
        
        setActiveTab("header")
        }}>Header</div>
      <div className={`tab-dm ${activeTab == "body" ? "activeHeaderForDm" : ""}`} onClick={() => {
        
        setActiveTab("body")
        }}>Body</div>
      <div className={`tab-dm ${activeTab == "params" ? "activeHeaderForDm" : ""}`} onClick={() => {
        setActiveTab("params")
        }}>Params</div>
    </div>

 

 { activeTab == "header" ?  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{headers.map(header => (
  <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
      <Dropdown
            baseClassName="pxMiniWidth ten Dropdown"
            controlClassName="pxMini auth-input-dm-top half"
            placeholder="Header"
            arrowOpen={<div></div>}
            arrowClosed={<div></div>}
            value={header?.name ? {value: header.name, label: header.name} : ""}
            menuClassName="pxMini menu-dm-top colored" 
            onChange={(e) => {
              let newHeader = header
              const newHeaderId = generateRandomString(15)
              header.id = header.id || newHeaderId
              header.name = e.value
              setHeaders(headers.map(x => x.id == header.id ? newHeader : x))
            }}
            options={[

{value:"Accept-Charset", label:"Accept-Charset"},
{value:"Accept-Encoding", label:"Accept-Encoding"},
{value:"Accept-Language", label:"Accept-Language"},
{value:"Accept-Datetime", label:"Accept-Datetime"},
{value:"Access-Control-Request-Method", label:"Access-Control-Request-Method"},
{value:"Access-Control-Request-Headers", label:"Access-Control-Request-Headers"},
{value:"Authorization", label:"Authorization"},
{value:"Cache-Control", label:"Cache-Control"},
{value:"Connection", label:"Connection"},
{value:"Content-Length", label:"Content-Length"},
{value:"Content-Type", label:"Content-Type"},
{value:"Cookie", label:"Cookie"},
{value:"Date", label:"Date"},
{value:"Expect", label:"Expect"},
{value:"Forwarded", label:"Forwarded"},
{value:"From", label:"From"},
{value:"Host", label:"Host"},
{value:"If-Match", label:"If-Match"},
{value:"If-Modified-Since", label:"If-Modified-Since"},
{value:"If-None-Match", label:"If-None-Match"},
{value:"If-Range", label:"If-Range"},
{value:"If-Unmodified-Since", label:"If-Unmodified-Since"},
{value:"Max-Forwards", label:"Max-Forwards"},
{value:"Origin", label:"Origin"},
{value:"Proxy-Authorization", label:"Proxy-Authorization"},
{value:"Referer", label:"Referer"},
{value:"User-Agent", label:"User-Agent"},
{value:"Upgrade", label:"Upgrade"},
                ] 
            }/>
<Input
        style={{width:"90%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="URL"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
       {/* <img 
       style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
       className="actionHover" 
       onClick={() => {
        setHeaders(headers.filter(x => x.id !== header.id))
       }} 
       src={trash}/> */}
  </div>

))}
</div>
<div onClick={() => {
  setHeaders([...headers, {id: generateRandomString(15), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add header</div>

</div> : activeTab == "body" ? (
  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>

</div>
</div>
) : activeTab == "params" ? (
<div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{params.map(param => (
  <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
     <Input
        style={{width:"10%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Param"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
<Input
        style={{width:"90%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Param Value"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
       {/* <img 
       style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
       className="actionHover" 
       onClick={() => {
        setParams(params.filter(x => x.id !== param.id))
       }} 
       src={trash}/> */}
  </div>

))}
</div>
<div onClick={() => {
  setParams([...params, {id: generateRandomString(15), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add param</div>

</div>
) : null}

</>
) : selectedDatasource == "PostgreSQL" ? (
  <>
  
  <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", paddingLeft:"15px", color:"#959dad"}}>Request</div>

  <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
            <Dropdown
            baseClassName="pxMiniWidth Dropdown"
            controlClassName="pxMini auth-input-dm-top half"
            placeholder="Method"
            arrowOpen={<div></div>}
            arrowClosed={<div></div>}
            value={selectedMethod}
            menuClassName="pxMini menu-dm-top colored" 
            onChange={(e) => {
              setSelectedMethod(e)
            }}
            options={[
            {value:"GET", label:"GET"}, 
            {value:"POST", label:"POST"},
            {value:"DELETE", label:"DELETE"},
            {value:"PUT", label:"PUT"},
            {value:"HEAD", label:"HEAD"},
            {value:"OPTIONS", label:"Create Row"},
            {value:"PATCH", label:"PATCH"},
                ] 
            }/>

        <Input
        style={{width:"130px",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Base URL"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />

<Input
        style={{width:"100%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="URL"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />

  </div>
  <div style={{color:"white",marginTop:"20px", width:"100%", display:"flex", justifyContent:"flex-start", color:"#959dad"}}>
    
 
      <div className={`tab-dm ${activeTab == "header" ? "activeHeaderForDm" : ""}`} onClick={() => {
        
        setActiveTab("header")
        }}>Header</div>
      <div className={`tab-dm ${activeTab == "body" ? "activeHeaderForDm" : ""}`} onClick={() => {
        
        setActiveTab("body")
        }}>Body</div>
      <div className={`tab-dm ${activeTab == "params" ? "activeHeaderForDm" : ""}`} onClick={() => {
        setActiveTab("params")
        }}>Params</div>
    </div>

 

 { activeTab == "header" ?  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{headers.map(header => (
  <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
      <Dropdown
            baseClassName="pxMiniWidth ten Dropdown"
            controlClassName="pxMini auth-input-dm-top half"
            placeholder="Header"
            arrowOpen={<div></div>}
            arrowClosed={<div></div>}
            value={header?.name ? {value: header.name, label: header.name} : ""}
            menuClassName="pxMini menu-dm-top colored" 
            onChange={(e) => {
              let newHeader = header
              const newHeaderId = generateRandomString(15)
              header.id = header.id || newHeaderId
              header.name = e.value
              setHeaders(headers.map(x => x.id == header.id ? newHeader : x))
            }}
            options={[

{value:"Accept-Charset", label:"Accept-Charset"},
{value:"Accept-Encoding", label:"Accept-Encoding"},
{value:"Accept-Language", label:"Accept-Language"},
{value:"Accept-Datetime", label:"Accept-Datetime"},
{value:"Access-Control-Request-Method", label:"Access-Control-Request-Method"},
{value:"Access-Control-Request-Headers", label:"Access-Control-Request-Headers"},
{value:"Authorization", label:"Authorization"},
{value:"Cache-Control", label:"Cache-Control"},
{value:"Connection", label:"Connection"},
{value:"Content-Length", label:"Content-Length"},
{value:"Content-Type", label:"Content-Type"},
{value:"Cookie", label:"Cookie"},
{value:"Date", label:"Date"},
{value:"Expect", label:"Expect"},
{value:"Forwarded", label:"Forwarded"},
{value:"From", label:"From"},
{value:"Host", label:"Host"},
{value:"If-Match", label:"If-Match"},
{value:"If-Modified-Since", label:"If-Modified-Since"},
{value:"If-None-Match", label:"If-None-Match"},
{value:"If-Range", label:"If-Range"},
{value:"If-Unmodified-Since", label:"If-Unmodified-Since"},
{value:"Max-Forwards", label:"Max-Forwards"},
{value:"Origin", label:"Origin"},
{value:"Proxy-Authorization", label:"Proxy-Authorization"},
{value:"Referer", label:"Referer"},
{value:"User-Agent", label:"User-Agent"},
{value:"Upgrade", label:"Upgrade"},
                ] 
            }/>
<Input
        style={{width:"90%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="URL"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
       {/* <img 
       style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
       className="actionHover" 
       onClick={() => {
        setHeaders(headers.filter(x => x.id !== header.id))
       }} 
       src={trash}/> */}
  </div>

))}
</div>
<div onClick={() => {
  setHeaders([...headers, {id: generateRandomString(15), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add header</div>

</div> : activeTab == "body" ? (
  <div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>

</div>
</div>
) : activeTab == "params" ? (
<div style={{height:"fit-content", display:"flex", flexDirection:"column", width:"100%", alignItems:"center", border:".1px solid gray", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
<div style={{width:"100%", display:"flex", flexDirection:"column"}}>
{params.map(param => (
  <div style={{height:"fit-content", display:"flex", width:"100%", alignItems:"center", padding:"10px 0", borderTop:"0px", borderLeft:"0px", borderRight:"0px"}}>
     <Input
        style={{width:"10%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Param"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
<Input
        style={{width:"90%",margin:"1px 5px"}}
        type={"text"}
        name="styling"
        defaultValue={""}
        maxLength={128}
        placeholder="Param Value"
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleLoginClick();
        //   }
        // }}
        className="auth-input-dm-class"
      />
       {/* <img 
       style={{marginLeft:".4rem", color:"#EF1111", width:"20px", cursor:"pointer"}} 
       className="actionHover" 
       onClick={() => {
        setParams(params.filter(x => x.id !== param.id))
       }} 
       src={trash}/> */}
  </div>

))}
</div>
<div onClick={() => {
  setParams([...params, {id: generateRandomString(15), label:"", value:""}])
}} className='viewNC buttonNC dmColor' style={{marginTop:"0px",width:"100%"}}>+ add param</div>

</div>
) : null}

</>
) :   null}
</div>
             </div> : null}
          
          </div>
        ) : activeHeader == "Logs" ? (
          <div style={{height:"500px", maxHeight:"500px", margin:"30px 20px", overflow:"auto"}}>
          
          {/* <ConsoleUI style={{border:"2px solid red"}} logs={logs} /> */}

          </div>
        ) : activeHeader == "Custom code" ? ( 
          <div style={{height:"500px"}}></div>
        ) : null
      ) : <div></div>
    }


    <div className='header-dm-left flex flex-row'>
      <div className={`tab-dm ${toolOpen && activeHeader == "Actions" ? "activeHeaderForDm" : ""}`} onClick={() => {
        setActiveHeader("Actions")
        setToolOpen(true)
        }}>Actions</div>
      <div className={`tab-dm ${toolOpen && activeHeader == "Datasource" ? "activeHeaderForDm" : ""}`} onClick={() => {
        setActiveHeader("Datasource")
        setToolOpen(true)
        }}>Datasource</div>
      <div className={`tab-dm ${toolOpen && activeHeader == "Logs" ? "activeHeaderForDm" : ""}`} onClick={() => {
        setActiveHeader("Logs")
        setToolOpen(true)
        }}>Logs</div>
      <div className={`tab-dm ${toolOpen && activeHeader == "Custom code" ? "activeHeaderForDm" : ""}`} onClick={() => {
        setActiveHeader("Custom code")
        setToolOpen(true)
        }}>Custom code</div>
    </div>
   
    <div className='header-dm-right activeHeaderDm' style={{display:"flex", cursor:"pointer", alignItems:"center"}}>
    {!toolOpen ? <><div onClick={() => setToolOpen(true)} style={{cursor:"pointer", width:"15px", height:"15px", marginRight:"7px"}}><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-diagonal-arrow-right-up-outline" fill="#959dad"><g data-name="Layer 2"><g data-name="diagonal-arrow-right-up"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect><path d="M18 7.05a1 1 0 0 0-1-1L9 6a1 1 0 0 0 0 2h5.56l-8.27 8.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0L16 9.42V15a1 1 0 0 0 1 1 1 1 0 0 0 1-1z"></path></g></g></svg></div><div onClick={() => setToolOpen(true)} className={`tab-dm`}>{activeHeader}</div></> : <><div onClick={() => {
      setToolOpen(false)
    }} style={{width:"15px", height:"15px", marginRight:"7px"}}><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" className="eva eva-minus-outline" fill="currentColor"><g data-name="Layer 2"><g data-name="minus"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect><path d="M19 13H5a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2z"></path></g></g></svg></div><div onClick={() => {
      setToolOpen(false)
    }} className={`tab-dm`}>Hide</div></>}
    </div>
    </ToolboxDiv>
  )
  } else {
    return <div></div>
  }
}
