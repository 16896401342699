/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import * as React from 'react';
import { Timeline, sortEventList } from '@progress/kendo-react-layout';
import { events } from './events';
const sortedEvents = sortEventList(events);
export const Timeline_Widget = () => {
  const onActionClick = e => {
    const event = e.syntheticEvent;
    event.preventDefault();
    window.open(event.target.getAttribute('href'));
  };
  return <div className='app-wrapper'>
        <Timeline events={sortedEvents} alterMode={true} collapsibleEvents={true} onActionClick={onActionClick} />
      </div>;
};
