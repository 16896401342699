const loginpageExpiredContents = {
    headerPageExpired:{
        content:'Page has expired',
        background:{value:null, type:'bg'},
        fontSize:{value:'24px', type:'Number'},
        lineHeight:{value:null, type:'Number'},
  
        fontWeight :{value:null, type:'String'},
        textDecoration :{value:null, type:'String'}, 
        fontFamily :{value:null, type:'String'},
        textTransform :{value:null, type:'String'},
  
        textAlign :{value:'center', type:'String'},
        color: {value:null, type:'color'},
  
        width:{value:'null', type:'Number'},
        height:{value:'null', type:'Number'},
        marginTop:{value:null, type:'Number'},
        marginRight:{value:null, type:'Number'},
        marginBottom:{value:null, type:'Number'},
        marginLeft:{value:null, type:'Number'},
        paddingTop:{value:null, type:'Number'},
        paddingRight:{value:null, type:'Number'},
        paddingBottom:{value:null, type:'Number'},
        paddingLeft:{value:null, type:'Number'},
  
        shadow:{value:null, type:'Number'},
        
      },

      firstContent:{
        content:'To restart the login process',
        background:{value:null, type:'bg'},
        fontSize:{value:'11px', type:'Number'},
      lineHeight:{value:null, type:'Number'},

      fontWeight :{value:null, type:'String'},
      textDecoration :{value:null, type:'String'}, 
      fontFamily :{value:null, type:'String'},
      textTransform :{value:null, type:'String'},

      textAlign :{value:'center', type:'String'},
      color: {value:null, type:'color'},
      borderRadius: {value:'16px', type:'Number'},

      width:{value:'null', type:'Number'},
      height:{value:'null', type:'Number'},
      marginTop:{value:null, type:'Number'},
      marginRight:{value:null, type:'Number'},
      marginBottom:{value:null, type:'Number'},
      marginLeft:{value:null, type:'Number'},
      paddingTop:{value:null, type:'Number'},
      paddingRight:{value:null, type:'Number'},
      paddingBottom:{value:null, type:'Number'},
      paddingLeft:{value:null, type:'Number'},

      shadow:{value:null, type:'Number'},
        
      },
      
      lastContent:{
        content:`To continue the login process`,
        background:{value:null, type:'bg'},
        fontSize:{value:'11px', type:'Number'},
      lineHeight:{value:null, type:'Number'},

      fontWeight :{value:null, type:'String'},
      textDecoration :{value:null, type:'String'}, 
      fontFamily :{value:null, type:'String'},
      textTransform :{value:null, type:'String'},

      textAlign :{value:'center', type:'String'},
      color: {value:null, type:'color'},
      borderRadius: {value:'16px', type:'Number'},

      width:{value:'null', type:'Number'},
      height:{value:'null', type:'Number'},
      marginTop:{value:null, type:'Number'},
      marginRight:{value:null, type:'Number'},
      marginBottom:{value:null, type:'Number'},
      marginLeft:{value:null, type:'Number'},
      paddingTop:{value:null, type:'Number'},
      paddingRight:{value:null, type:'Number'},
      paddingBottom:{value:null, type:'Number'},
      paddingLeft:{value:null, type:'Number'},

      shadow:{value:null, type:'Number'},
        
      },
  
}

export default loginpageExpiredContents;