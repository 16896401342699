/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { MarkIconBlue } from '../Svg';
import './pricing.css'

export default function Priring() {

    const pricingCard = [
        {
            title: "Free",
            desc: "Our free plan is designed for individuals and small projects, offering essential features to kickstart your web-building journey.", 
            value: "0",
            cardNotice: 'no card needed',
            signButton:"Sign Up",
            features: ["Intuitive Page Builder", "Basic Templates", "Limited Database Integration", "Responsive Design"],
        }, 
        {
            title: "Business",
            desc: "Unlock the full potential of All You Can Cloud to scale your web presence and achieve your business goals.",
            value: "8",
            cardNotice: 'Save 20% anually',
            signButton:"Sign Up",
            features: ["Advanced Page Builder", "Custom Templates", "Unlimited Database Integration", "Advanced Collaboration", "Custom Branding"],
            plus: 'Everything in Standart, plus:',
            pop: 'most popular',
        },
        {
            title: "Standart",
            desc: " Ideal for growing projects and businesses, the Standard Plan includes advanced features to elevate your online presence.",
            value: "5",
            cardNotice: 'Save 20% anually',
            signButton:"Sign Up",
            features: ["Add more than 5 users", "User Roles (admin, editor, user)", "App and data source Environments", "Release history"],
            plus: 'Everything in Free, plus:',
        }, 
    ]

    return (
        <div className='w-full h-full container py-40'>
          <div className='pricing-container'>
            <div className='pricing-header md:mb-[3%]'>Pricing</div>
            <div className='pricing-subheader'>In AYCC we want our customers to feel free and pick the subscription that works best for them. No matter how many accounts you have, you only pay for what you use. Customer fidelity is the foundation of our product, and we will be delivering new updates to every subscriber regardless of their plan.</div>
            <div className='center-card-container flex flex-row w-full md:my-[3%]'>
              {pricingCard.map((card, index) => (
                <div className={`pricing-cards relative ${card.pop? 'border-1 border-[#666AE3]' : 'border-1 border-[#666ae333]'}`} key={index}>
                {card.pop? <div className='pop pt-[2.5px] text-center'>{card.pop}</div> : null}
                  <div className='price-title'>{card.title}</div>
                  <div className='price-value'>
                    <div className='card-notice'>{card.cardNotice}</div>
                    <div className='card-price'>
                        <div className='price'>${card.value}</div>
                        <div className='flex flex-col mx-2 my-auto'>
                            <div style={{fontSize:'12px', fontFamily:'Rubik', fontWeight:'400', lineHeight:'140%', color:'#000'}}>month</div>
                            <div style={{fontSize:'12px', fontFamily:'Rubik', fontWeight:'400', lineHeight:'140%', color:'#000'}}>per user</div>
                        </div>
                       </div>
                  </div>
                  <div className='price-desc'>{card.desc}</div>
                  <div className='plus'>{card.plus}</div>
                  <div className='price-features'>
                    <ul>
                      {card.features.map((feature, featureIndex) => (
                        <li className='md:my-2 my-[2px]' key={featureIndex}>
                            <div className='flex flex-row align-center'>
                                <MarkIconBlue/>
                                <div className='points my-auto pl-2'>{feature}</div>
                            </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='price-signup'>
                    <div>{card.signButton}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className='pricing-foot'>Feel free to reach out for any inquiries you might have regarding the product or share your experience with it and help us build the best tool for you. At All You Can Cloud we want to provide a consistent service where users’ requests are at the forefront of what we do.</div>
          </div>
        </div>
      );
}