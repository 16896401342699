import { useState } from "react";
import { useOptionsContext } from "../../OptionsContext";
import { Circle3 } from "../iconsSvg"
import { PenEditIcon } from "../../components/editor/Viewport/Pop-up/svgArray";
import { category } from "../../components/selectors/basic/Image/ImageSettings";
import SelectionBox from "../../components/editor/Viewport/Pop-up/PopUp";
import { EyeIcon } from "../../components/editor/Viewport/EditorSvg";
import SmallWidgets from "./HandleWidgets";
import NextButton from "../SettingsArea/NextButton";

const BottomArea = ({ BottomProps }) => {

    const {
        footerProps,
        setStart,
        setFooterProps,
        bottomContents,
        setBottomContents,
        pageSettings,
        setPageSettings,
        stepper2,
        setStepper2,
        setWalksFor,
        select,
        bottomRows,
        setbottomRows,
        theBottomOptionRow2,
        setTheBottomOptionRow2,
        theBottomOptionRow3,
        setTheBottomOptionRow3,
        theBottomOptionRow,
        setTheBottomOptionRow,
    } = BottomProps

    const [expandedRow, setExpandedRow] = useState(null);
    const [editNodeId, setEditNodeId] = useState(false)
    const [newNodeName, setNewNodeName] = useState('')
    const [iconsSquare, setIconsSquare] = useState(false);
    const { state: idState, dispatch: idDispatch } = useOptionsContext();
    const { options: treeData } = idState;


    
    const TreeView = ({ treeData, idDispatch }) => {
        const [editNodeId, setEditNodeId] = useState(null);
        const [newNodeName, setNewNodeName] = useState('');
        const [iconsSquare, setIconsSquare] = useState(false);

    
        const handleChangeName = (id, node, icon) => {
            const updatedName = newNodeName.trim() !== '' ? newNodeName : node;
            idDispatch({
                type: 'CHANGE_NAME',
                payload: { _id: id, name: updatedName, icon: icon },
            });
    
            setEditNodeId(null);
            setNewNodeName('');
        };

        const handleEditChildIcon = (selectedIcon) => {
            if (!selectedIcon) {
                return null;
            }
                if (selectedIcon) {
                    idDispatch({
                        type: 'CHANGE_NAME',
                        payload: { _id: editNodeId._id, name: editNodeId.name, icon: selectedIcon },
                      });
                }
        };


        const handleIconImageSelect = (selectedUrl) => {

            if (Array.isArray(selectedUrl) && selectedUrl.length) {
                handleEditChildIcon(selectedUrl)
            } else {
                handleEditChildIcon(selectedUrl)
            }
            setIconsSquare(false);
        };
    
        return (
            <div>
                {treeData.map((node) => {
                    const isEditing = editNodeId?._id === node?._id;
    
                    return (
                        <div className='flex w-full px-[1vmin] justify-between text-[1.6vmin]' key={node?._id}>
                            {isEditing ? (
                                <div className="flex w-full items-center">
                                <div className='w-[fit-content] cursor-pointer' onClick={(e) => {
                                    e.stopPropagation();
                                    setIconsSquare(true)
                                    }}>
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        role="img"
                                        viewBox={node?.icon?.viewBox}
                                        width={'1.5vmin'}
                                        height={'1.5vmin'}
                                        fill={node?.icon?.fill || '#0867FC'}
                                    >
                                        <path d={node?.icon?.path} />
                                    </svg>
                                    {iconsSquare && (
                                        <SelectionBox
                                            category={category}
                                            setIconsSquare={setIconsSquare}
                                            iconsSquare={iconsSquare}
                                            onSelect={handleIconImageSelect}
                                        />
                                    )}
                                </div>
                                <input
                                    className='ml-[3%] text-[#000]'
                                    onChange={(e) => setNewNodeName(e.target.value)}
                                    placeholder='New name'
                                    value={newNodeName}
                                    autoFocus
                                />
                                </div>
                            ) : (
                                <div className="w-full flex items-center">
                                    <div className='w-[fit-content] cursor-pointer' onClick={(e) => {
                                            e.stopPropagation();
                                            }}>
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                role="img"
                                                viewBox={node?.icon?.viewBox}
                                                width={'1.5vmin'}
                                                height={'1.5vmin'}
                                                fill={node?.icon?.fill || '#0867FC'}
                                            >
                                                <path d={node?.icon?.path} />
                                            </svg>
                                        </div>
                                        <div className="ml-[3%]">{node?.name}</div>

                                </div>
                            )}
                            <div
                                className="iconspage"
                                style={{ margin: '.25Vmin' }}
                            >
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (isEditing) {
                                            handleChangeName(node?._id, node?.name, node?.icon);
                                        } else {
                                            setEditNodeId(node);
                                            setNewNodeName(node?.name);
                                        }
                                    }}
                                    className='cursor-pointer transition-transform duration-300 transform-gpu hover:scale-125'
                                >
                                    {isEditing ? (
                                        <div
                                            style={{ color: newNodeName.trim() === '' ? 'gray' : '#0867FC' }}
                                            className='cursor-pointer text-[1.3vmin]'
                                        >
                                            {newNodeName.trim() === '' ? 'Cancel' : 'OK!'}
                                        </div>
                                    ) : (
                                        <PenEditIcon fill='none' stroke='#161717' />
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const handleRowVisible = (rowId) => {
        setExpandedRow(prevRow => (prevRow === rowId ? null : rowId));
    };

    const handleBottomArea = (value) => {
        setbottomRows(value)
    }

    const FirstRow = ({setBottomContents, bottomContents, treeData, idDispatch}) => {

        const handleContents = (contentNum, e) => {
            let newContent = e.target.value

            setBottomContents((prev) => ({
                ...prev,
                [contentNum]: { ...prev[contentNum], text: newContent }
            }))
        }

        const handleHrefs = (contentNum, e) => {
            let newHref = e.target.value

            setBottomContents((prev) => ({
                ...prev,
                [contentNum]: { ...prev[contentNum], href: newHref }
            }))
        }

        return (
            theBottomOptionRow === '' ?
                <>
                    <ol style={{ listStyle: 'outside', fontSize: '1.5vmin', width: '100%', }}>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheBottomOptionRow('Navigation Pages')}>Navigation Pages!</li>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheBottomOptionRow('Small widgets')}>Small widgets!</li>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheBottomOptionRow('Content with a Link')}>Content with a Link!</li>
                    </ol>
                </> : theBottomOptionRow === 'Small widgets' ? (
                    <SmallWidgets
                    check='bottom'
                    data={footerProps}
                    setData={setFooterProps}
                    />
                ) : theBottomOptionRow === 'Navigation Pages' ? (
                    <div className='flex flex-column w-full h-full p-[1vmin]' onClick={() => console.log(treeData)}>
                        <TreeView treeData={treeData} idDispatch={idDispatch}/>
                    </div>
                ) : (
                    <div className='flex flex-column w-full h-[100%] px-[1vmin]'>
                        <div className='w-full flex-row flex'>
                            <div>Content:</div>
                            <input placeholder='Type someting here!' className='ml-[1.5vmin] text-[#000] resize-none overflow-hidden' value={bottomContents.content1.text} onChange={(e) => handleContents('content1', e)} />
                        </div>              <div className='flex flex-row h-full w-full justify-center items-center'>
                            <div>Href:</div>
                            <input placeholder='Leads to..' className='ml-[1.5vmin] text-[#000] resize-none overflow-hidden' value={bottomContents.content1.href} onChange={(e) => handleHrefs('content1', e)} />
                        </div>

                    </div>
                )
        )
    }

    const SecondRow = ({setBottomContents, bottomContents, treeData, idDispatch}) => {

        const handleContents = (contentNum, e) => {
            let newContent = e.target.value

            setBottomContents((prev) => ({
                ...prev,
                [contentNum]: { ...prev[contentNum], text: newContent }
            }))
        }

        const handleHrefs = (contentNum, e) => {
            let newHref = e.target.value

            setBottomContents((prev) => ({
                ...prev,
                [contentNum]: { ...prev[contentNum], href: newHref }
            }))
        }

        return (
            theBottomOptionRow2 === '' ?
                <>
                    <ol style={{ listStyle: 'outside', fontSize: '1.5vmin', width: '100%', }}>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheBottomOptionRow2('Navigation Pages')}>Navigation Pages!</li>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheBottomOptionRow2('Small widgets')}>Small widgets!</li>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheBottomOptionRow2('Content with a Link')}>Content with a Link!</li>
                    </ol>
                </> : theBottomOptionRow2 === 'Small widgets' ? (
                     <SmallWidgets
                     check='bottom'
                     data={footerProps}
                     setData={setFooterProps}
                     />
                ) : theBottomOptionRow2 === 'Navigation Pages' ? (
                    <div className='flex flex-column w-full h-full p-[1vmin]' onClick={() => console.log(treeData)}>
                        <TreeView treeData={treeData} idDispatch={idDispatch}/>
                    </div>
                ) : (
                    <div className='flex flex-column w-full h-[100%] px-[1vmin]'>
                        <div className='w-full flex-row flex'>
                            <div>Content:</div>
                            <input placeholder='Type someting here!' className='ml-[1.5vmin] text-[#000] resize-none overflow-hidden' value={bottomContents.content2.text} onChange={(e) => handleContents('content2', e)} />
                        </div>          <div className='flex flex-row h-full w-full justify-center items-center'>
                            <div>Href:</div>
                            <input placeholder='Leads to..' className='ml-[1.5vmin] text-[#000] resize-none overflow-hidden' value={bottomContents.content2.href} onChange={(e) => handleHrefs('content2', e)} />
                        </div>

                    </div>
                )
        )
    }

    const RowThree = ({setBottomContents, bottomContents, treeData, idDispatch}) => {

        const handleContents = (contentNum, e) => {
            let newContent = e.target.value

            setBottomContents((prev) => ({
                ...prev,
                [contentNum]: { ...prev[contentNum], text: newContent }
            }))
        }

        const handleHrefs = (contentNum, e) => {
            let newHref = e.target.value

            setBottomContents((prev) => ({
                ...prev,
                [contentNum]: { ...prev[contentNum], href: newHref }
            }))
        }

        return (
            theBottomOptionRow3 === '' ?
                <>
                    <ol style={{ listStyle: 'outside', fontSize: '1.5vmin', width: '100%', }}>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheBottomOptionRow3('Navigation Pages')}>Navigation Pages!</li>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheBottomOptionRow3('Small widgets')}>Small widgets!</li>
                        <li style={{ cursor: 'pointer' }} onClick={() => setTheBottomOptionRow3('Content with a Link')}>Content with a Link!</li>
                    </ol>
                </> : theBottomOptionRow3 === 'Small widgets' ? (
                     <SmallWidgets
                     check='bottom'
                     data={footerProps}
                     setData={setFooterProps}
                     />
                ) : theBottomOptionRow3 === 'Navigation Pages' ? (
                    <div className='flex flex-column w-full h-full p-[1vmin]' onClick={() => console.log(treeData)}>
                        <TreeView treeData={treeData} idDispatch={idDispatch}/>
                    </div>
                ) : (
                    <div className='flex flex-column w-full h-[100%] px-[1vmin]'>
                        <div className='w-full flex-row flex'>
                            <div>Content:</div>
                            <input placeholder='Type someting here!' className='ml-[1.5vmin] text-[#000] resize-none overflow-hidden' value={bottomContents.content3.text} onChange={(e) => handleContents('content3', e)} />
                        </div>
                        <div className='flex flex-row h-full w-full justify-center items-center'>
                            <div>Href:</div>
                            <input placeholder='Leads to..' className='ml-[1.5vmin] text-[#000] resize-none overflow-hidden' value={bottomContents.content3.href} onChange={(e) => handleHrefs('content3', e)} />
                        </div>

                    </div>
                )
        )
    }

    const BottomRows = ({setPageSettings, setBottomContents, bottomContents, treeData, idDispatch}) => {
        const [editArea, setEditArea] = useState(false)
        const [copyright, setCopyRight] = useState('')
    
        const confirmCopyRightContent = () => {
            setPageSettings(prev => ({
                ...prev,
                copyright: {
                    ...prev.copyright,
                    content: copyright !== '' ? copyright : prev.copyright.content
                }
            }))
        }
    
        return (

            <div className='relative w-full h-full flex-col flex py-[4vmin] text-[1.5vmin]'>
                <div className="w-full h-full flex flex-column">
                <ol style={{ listStyle: 'outside', fontSize: '1.5vmin', padding:'0', width:'100%' }}>
                    <div className='flex flex-column justify-between w-full'>
                        <div className='flex justify-between w-full'>
                            <li>
                                Footer Visibility
                            </li>
                            <div onClick={(e) => {
                                e.stopPropagation()
                                setPageSettings((prev) => ({
                                    ...prev,
                                    bottomSide: {
                                        ...prev.bottomSide,
                                        visibility: !prev.bottomSide.visibility
                                    },
                                }))
                            }} style={{ color: '#0867FC', cursor: 'pointer' }}>{!pageSettings.bottomSide.visibility ? 'OFF' : 'ON'}</div>
                        </div>
                        <div style={{color: !pageSettings.bottomSide.visibility && '#989191'}} className='flex justify-between w-full'>
                            <div className="flex">
                            <li>
                            Copyright footer
                            </li>
                            <div className="cursor-pointer mx-[.5vmin]" onClick={() => setEditArea(!editArea)}>
                                <PenEditIcon/>
                            </div>
                            </div>
                            <div onClick={(e) => {
                                e.stopPropagation()
                                setPageSettings((prev) => ({
                                    ...prev,
                                    copyright: {
                                        ...prev.copyright,
                                        visibility: !prev.copyright.visibility,
                                    }
                                }))
                            }} style={{ color: pageSettings.bottomSide.visibility? '#0867FC' : '#989191', cursor: 'pointer' }}>{!pageSettings.copyright.visibility ? 'OFF' : 'ON'}</div>
                        </div>
                        {editArea && (
                                <div className="w-full bg-[#e9e9e9] items-center flex rounded-[1vmin] my-[1.5vmin] mx-auto" style={{border:'.25vmin solid #e9e9e9'}}>
                                    <input className="w-[85%] text-[#000] pl-[.5vmin] p-[.5vmin] rounded-[1vmin]" placeholder="Change the copyright content.." value={copyright} onChange={(e) => setCopyRight(e.target.value)}/>
                                    <div className="w-[fit-content] mx-[1vmin] cursor-pointer" style={{color: copyright !== '' ? '#0867FC' : '#989191'}} onClick={confirmCopyRightContent}>{copyright !== '' ? 'OK!' : (
                                        <div onClick={() => setEditArea(false)}>Cancel</div>
                                    )}</div>
                                </div>
                            )}
                    </div>
                </ol>

                {pageSettings.bottomSide.visibility && <div className="w-full h-full flex flex-column justify-start">
                    <div className="w-full h-[fit-content] flex flex-column">
                <div className='w-full h-full flex justify-center items-center' style={{ fontSize: '1.7vmin', marginBottom: '2vmin' }}>How many rows you need in the Bottom?</div>
                    <div className="justify-center items-center w-full">
                    <div className='w-[6vmin] mx-auto rounded-[1.5vmin] border flex items-center justify-center h-full flex-row flex-wrap'>
                        <select
                            className='custom-select mx-auto'
                            value={bottomRows}
                            onChange={(e) => handleBottomArea(e.target.value)}
                        >
                            <option value={'1'}>1</option>
                            <option value={'2'}>2</option>
                            <option value={'3'}>3</option>

                        </select>
                    </div>
                </div>
                </div>
                    <div className="w-full h-full text-[1.7vmin]">

                        {bottomRows !== '0' && (
                            <div className='w-full h-full flex-column flex'>
                                <div className='my-[1vmin] border-[.25vmin] border-solid rounded-[1vmin] border-[#e9e9e930] shadow-md justify-center h-[fit-content] flex flex-column p-[1vmin]'>
                                    <div className='h-auto rounded-[.7vmin] flex items-center justify-between'>
                                        <div >First Row</div>
                                        {theBottomOptionRow !== '' && <div style={{ color: '#0867FC', fontSize: '1.5vmin', cursor: 'pointer' }}
                                         onClick={() => {
                                            setTheBottomOptionRow('')
                                            handleRowVisible('1')
                                         }}>{theBottomOptionRow}</div>}
                                        <div className='cursor-pointer' onClick={() => handleRowVisible('1')}>
                                        <div style={{ width: "2Vmin" }}>
                                        <svg style={{ float: 'right', marginLeft: '1vmin', 
                                        // 
                                        // transition: 'all .1s ease-in',

                                        transform: expandedRow === '1' ? 'rotate(90deg)' : '' }}
                                        aria-hidden="true" focusable="false" role="img" className="octicon octicon-chevron-right" viewBox="0 0 12 12" width="2Vmin" height="2Vmin"
                                        fill={expandedRow === '1' ? '#161717' : '#989191'}>
                                            <path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3Z"></path></svg>
                                    </div>
                                        </div>
                                    </div>
                                    
                                    {expandedRow === '1' && <div className="mt-[1vmin]"> 
                                        <FirstRow
                                        treeData={treeData}
                                        idDispatch={idDispatch}
                                        setBottomContents={setBottomContents}
                                        bottomContents={bottomContents}
                                        /> 
                                        </div>}
                                </div>
                                {bottomRows > '1' && (
                                    <div className='my-[1vmin] border-[.25vmin] border-solid rounded-[1vmin] border-[#e9e9e930] shadow-md justify-center h-[fit-content] flex flex-column p-[1vmin]'>
                                        <div className='rounded-[.7vmin] flex items-center justify-between'>
                                            <div >Second Row</div>
                                            {theBottomOptionRow2 !== '' && <div style={{ color: '#0867FC', fontSize: '1.5vmin', cursor: 'pointer' }} 
                                            onClick={() => {
                                                setTheBottomOptionRow2('')
                                                handleRowVisible('2')
                                            }}>{theBottomOptionRow2}</div>}
                                            <div className='cursor-pointer' onClick={() => handleRowVisible('2')}>
                                            <div style={{ width: "2Vmin" }}>
                                            <svg style={{ float: 'right', marginLeft: '1vmin', 
                                            // transition: 'all .1s ease-in',
                                             transform: expandedRow === '2' ? 'rotate(90deg)' : '' }}
                                                aria-hidden="true" focusable="false" role="img" className="octicon octicon-chevron-right" viewBox="0 0 12 12" width="2Vmin" height="2Vmin"
                                                fill={expandedRow === '2' ? '#161717' : '#989191'}>
                                                <path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3Z"></path></svg>
                                            </div>
                                            </div>
                                        </div>
                                        {expandedRow === '2' && <div className="mt-[1vmin]">
                                        <SecondRow
                                        treeData={treeData}
                                        idDispatch={idDispatch}
                                         setBottomContents={setBottomContents}
                                         bottomContents={bottomContents}
                                         /> </div>}
                                    </div>
                                )}
                                {bottomRows === '3' && (
                                    <div className='my-[1vmin] border-[.25vmin] border-solid rounded-[1vmin] border-[#e9e9e930] shadow-md justify-center h-[fit-content] flex flex-column p-[1vmin]'>
                                        <div className='rounded-[.7vmin] flex items-center justify-between'>
                                            <div >Third Row</div>
                                            {theBottomOptionRow3 !== '' && <div style={{ color: '#0867FC', fontSize: '1.5vmin', cursor: 'pointer' }} 
                                            onClick={() => {
                                                setTheBottomOptionRow3('')
                                                handleRowVisible('3')
                                            }}>{theBottomOptionRow3}</div>}
                                            <div className='cursor-pointer' onClick={() => handleRowVisible('3')}>
                                            <div style={{ width: "2Vmin" }}>
                                                <svg style={{ float: 'right', marginLeft: '1vmin', 
                                                // transition: 'all .1s ease-in',
                                                 transform: expandedRow === '3' ? 'rotate(90deg)' : '' }}
                                                aria-hidden="true" focusable="false" role="img" className="octicon octicon-chevron-right" viewBox="0 0 12 12" width="2Vmin" height="2Vmin"
                                                fill={expandedRow === '3' ? '#161717' : '#989191'}>
                                            <path d="M4.7 10c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L6.9 6 4.2 3.3c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l3.3 3.2c.3.3.3.8 0 1.1L5.3 9.7c-.2.2-.4.3-.6.3Z"></path></svg>
                                            </div>
                                            </div>
                                        </div>
                                        {expandedRow === '3' && <div className="mt-[1vmin]">
                                        <RowThree
                                        treeData={treeData}
                                        idDispatch={idDispatch}
                                         setBottomContents={setBottomContents}
                                         bottomContents={bottomContents}
                                        /> </div>}
                                    </div>
                                )}


                            </div>

                        )}

                    </div>
                    
                </div>}
                </div>
                <NextButton setStart={setStart}
                onClick={() => {
                    setStepper2(3)
                    setWalksFor((prev) => ({
                        ...prev,
                        main: { value: 1.600 },
                        mainApp: { value: 3 }
                    }))
                }}/>
                {select !== null && <div className='absolute top-[0] right-[-5vmin]'>
                    <Circle3 fill={stepper2 >= 3 ? 'green' : ''} />
                </div>}
            </div>
        )
    }

    return (
        <BottomRows treeData={treeData} idDispatch={idDispatch} setBottomContents={setBottomContents} bottomContents={bottomContents} setPageSettings={setPageSettings}/>
    )
}

export default BottomArea;