/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect, useState } from 'react';
import { Modal, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CloseIcon } from '../../../land/Svg';
import {  ImageIcon,  SearchBox, } from './svgArray';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './popUp.css'
import { useDarkMode } from '../../../../DarkModeContext';
import { icons } from './IconArray';
import { imagesArr } from './ImageArray';
import { imgsCate } from '../../../selectors/basic/Image/ImageSettings';

const useStyles = makeStyles({
  modalContent: {
    padding: '1Vmin 3Vmin',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vmin',
    height: '80vmin',
    zIndex: 999999999999999,
    backgroundColor: '#F0F1F3',
    border: '1px solid #E9E9EA',
    boxShadow: '0px 10px 14px rgba(255, 255, 255, 0.1)', // Corrected box-shadow
    borderRadius: '1.6Vmin',
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'start',
    justifyItems: 'flex-start',
    scrollbarWidth: 'none',
    scrollbarColor: 'transparent transparent',
  },

  categoryItem: {
    cursor: 'pointer',
    width: '100%',
    margin: '.5Vmin auto',
    fontSize: '2Vmin',
    fontFamily: 'sans-serif',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: '1.2Vmin',
    scrollbarWidth: 'none',
    scrollbarColor: 'transparent transparent',
    // transition: 'all 0.3s', // Adding transition for smooth effect
  },
  mainArea: {
    width: '100%',
    opacity: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    overflow: 'scroll',
    maxHeight:'38vmin',
    scrollbarWidth: 'none',
    scrollbarColor: 'transparent transparent',
    // transition: 'all 0.3s ease-in-out'
  },
  icon: {
    cursor: 'pointer',
    padding: '2Vmin',
    minWidth: '2Vmin',
    height: '4Vmin',
    alignContent: 'center',
    display: 'flex',
    margin: '.5rem',
    border: '.25Vmin solid red',
    background: '#fff',
    borderRadius: '1Vmin',
    // transition: 'background-color 0.3s', // Adding transition for smooth effect
    '&:hover': {
      background: '#000',
    },
  },
  iconImg: {
    width: '2.5Vmin',
    height: 'fit-content',
    margin: 'auto 0',
  },
  closeButton: {
    width: 'auto',
    justifyItems: 'flex-end', justifyContent: 'flex-end',
    // transition: 'background-color 0.3s', // Adding transition for smooth effect
    '&:hover': {
      background: 'transparent',
    },
  },
});

const SelectionBox = ({category, onSelect, setIconsSquare, iconsSquare, images }) => {
  const classes = useStyles();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [success, setSuccess] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const { isDarkMode } = useDarkMode();
  const [activeWall, setActiveWall] = useState(null);
  const [focused, setFocused] = useState(false);
  const [iconSelected, setIconSelected] = useState(null)
  const [searchIcon, setSearchIcon] = useState('')
  const [searchImage, setSearchImage] = useState('')
  const [files, setFiles] = useState([])
  const [imagesArray, setImages] = useState(imagesArr)
  const [filterImages, setFilterImages] = useState(imagesArray)
  const [iconsArr, setIcons] = useState(icons)
  const [filterIcons, setFilterIcons] = useState(iconsArr)

  const imgsCategory = imgsCate

  const colors = [
    {name: 'Red', color: '#ff0000' },
    {color:'#FFFFFF'},
    {color:'#000000'},
    {color:'#100103'},
    {color:'#0C134F'},
    {color:'#2A2A2A'},
    {color:'#01060E'},
    {color:'#D0BCFF'},
    {color:'#020212'},
    {color:'#0078D3'},
    {color:'#31A993'},
    {color:'#6750A4'},
    {color:'#EA6EF4'},
    {color:'#F31559'},
    {color:'#FF0060'},
    {color:'#FFD600'},
    {color:'#121826'},
    {color:'#2479FD'},
    {color:'#DFFFF9'},
    {color:'#02C8B4'},
    {color:'#035CFB'},
    {color:'#343CE8'},
    {color:'#381E72'},
    {color:'#3DA169'},
    {color:'#9007D2'},
    {color:'#950B67'},
    {color:'#C7D9E3'},
    {color:'#D423A5'},
    {color:'#FE490B'},
    {color:'#FF0560'},
    {color:'#010CFF'},
    {color:'#0440AD'},
    {color:'#080B2B'},
    {color:'#186B3C'},
    {color:'#1F062C'},
    {color:'#1FEBD6'},
    {color:'#213156'},
    {color:'#444A50'},
    {color:'#7A1037'},
    {color:'#85C9EF'},
    {color:'#87000C'},
    {color:'#C03EFF'},
    {color:'#CA0A89'},
    {color:'#B20312'},
    {color:'#D4ADFC'},
    {color:'#F6FA70'},
  ];

  const handleClose = () => {
    setIconsSquare(false)
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handelFilesArray = (e) => {
    const fileList = e.target.files;
    const fileUrls = Array.from(fileList).map((file) => URL.createObjectURL(file));
    setFiles(fileUrls);
  };

  useEffect(() => {  
    if (searchIcon !== '') {
      const filtered = iconsArr.filter(icon => icon.name.toLowerCase().includes(searchIcon.toLowerCase()));
      setFilterIcons(filtered);
    } 
    else if (selectedCategory !== '') {
      const filtered = iconsArr.filter(icon => icon.category === selectedCategory);
      setFilterIcons(filtered);
    }
    else {
      setFilterIcons(iconsArr);
    }
  }, [iconsArr, searchIcon, selectedCategory]);


  const handleColorFill = (color) => {
    const newArr = iconsArr.map((icon) => ({
        ...icon,
        fill: color.color
    }))
    setIcons(newArr);

  };

  const handleSaveIconSelected = () => {
    if (iconSelected) {
      onSelect(iconSelected);
      setIconSelected(null);
    }
  };

  const handleSelectedIcon = (icon) => {
    const newArrWithSelectedProp = iconsArr.map((x) => ({
      ...x,
      selected: x === icon ? true : false
    }))
    setIconSelected(icon)
    setIcons(newArrWithSelectedProp)
  }

  const handleActiveWall = (index) => {
    setActiveWall(prevActiveWall => prevActiveWall === index ? null : index);
  }

  const pushImgToExArray = () => {
    const updatedImages = [...imagesArray];
    files.forEach((fileUrl, index) => {
      const uniqueId = `image_${index}_${Date.now()}`;
      const newImage = { name: uniqueId, url: fileUrl, category: 'Uplouded Images' };
      updatedImages.push(newImage);
    });
    setImages(updatedImages);
    setSuccess(true);

    setTimeout(() => {
      setSuccess(false);
      onSelect(files);
    }, 3000);
  };
  

  const [selectedCategoryImages, setSelectedCategoryImages] = useState([]);

  const handleCategoryChange = (category) => {
    setSelectedCategoryImages(prevSelectedImages => {
      if (prevSelectedImages.length === 0 || prevSelectedImages[0].category !== category) {
        return imagesArray.filter((image) => image.category === category);
      } else {
        return [];
      }
    });
  };

  const filteredCategory = selectedCategoryImages
  
  useEffect(() => {
    if (searchImage !== '') {
      const filtered = (imagesArray.filter(img => img.category.toLowerCase().includes(searchImage.toLowerCase())))
      setFilterImages(filtered)
    } else {
      setFilterImages(imagesArray)
    }
  }, [imagesArray, searchImage])


  return (
    <>
      <Modal open={iconsSquare ? true : false} onClose={handleClose}>
        <Box className={classes.modalContent} sx={{ flexDirection: images ? 'column' : 'row', justifyItems: 'center', justifyContent: 'center' }}>
          <Box style={{ overflow: images ? "hidden" : "hidden"}} className={`${images ? 'w-full flex-col' : 'w-[100%] flex-col'} flex mb-[1.5Vmin]`}>
            <Box sx={{ width: '100%', padding: '0', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0 0 1Vmin 0'}}>
              <div className='text-[3Vmin] text-[#000]' style={{ fontWeight: '600' }}>{images ? 'Add image' : 'Add icon'}</div>
              {!images && <div className='max-w-[40Vmin] my-auto flex justify-center px-[1Vmin] mx-auto overflow-x-auto' >
                    {colors.map((color, index) => (
                      <div className={`min-w-[2Vmin] border-[.6vmin] mx-[.25Vmin] min-h-[2Vmin] max-h-[2Vmin] max-w-[2Vmin] rounded-full bg-[${color.color}]`}
                      onClick={() => handleColorFill(color)}
                      key={index}>

                      </div>
                    ))}
              </div>}
              {<div className='flex items-center' style={{ justifyItems: 'flex-end', justifyContent: 'flex-end' }}>
                <div className={`${classes.closeButton} cursor-pointer`} onClick={handleClose}>
                  <CloseIcon width='1.5Vmin' height='1.5Vmin' fill='#000' />
                </div>
              </div>}
            </Box>

            {images ? <Box className='flex h-full' sx={{ borderRadius: '2Vmin', flexDirection: images ? 'column' : 'row', border: '.25Vmin solid #E9E9EA', margin: '.7Vmin auto auto auto', width: '100%', padding: "5Vmin 0", minHeight: "38vmin" }}>
              <Box sx={{height:'100%'}}>
                <Box className='flex justify-center w-full'>
                  <ImageIcon width='4Vmin' height='4Vmin' fill='#989191' />
                </Box>
                <div style={{ fontSize: '1.5Vmin', fontWeight: '500', fontFamily: 'Rubik', color: '#000' }} align="center">
                  Drag and drop image here or upload from computer
                </div>
                <Box className='max-w-[20Vmin] mx-auto flex flex-col justify-center'>
                  <label className="custom-file-upload">
                    <input
                      type="file"
                      accept=".jpg,.png,.jpeg"
                      id="contained-button-file2"
                      multiple
                      onChange={handelFilesArray}
                    />
                    Choose File
                  </label>
                </Box>

              </Box>

            </Box> : null}
            {!images && <><Box className='w-full flex-row flex h-[64vmin]'>
              <Box className='w-[44%] mr-[1%] h-full' sx={{ overflow: 'scroll', paddingBottom: '1Vmin' }}>
                <div
                  style={{ height: '4Vmin', margin: '1Vmin auto 2Vmin auto', alignItems: 'center', alignContent: 'center' }}
                  className={`transition-all duration-200 ease-in ${focused ? 'border-[0.1Vmin] border-[#0867FC]' : isDarkMode ? "border-[0.1Vmin] border-[#E9E9EA30]" : 'border-[0.1Vmin] border-[#E9E9EA]'} flex flex-row gap-[.25%] h-[1%] justify-center px-[1Vmin] py-[.5Vmin] rounded-[3Vmin]`}
                  id="BasicInput"
                >
                  <SearchBox width='2.4Vmin' height='2.4Vmin' fill={`${focused ? '#0867FC' : isDarkMode ? '#E9E9EA30' : '#989191'}`} />

                  <input
                    onBlur={() => setFocused(false)}
                    onFocus={() => setFocused(true)}
                    onChange={(e) => setSearchIcon(e.target.value)}
                    value={searchIcon}
                    style={{ width: "100%", height: "100%", fontSize: '2.2Vmin', fontWeight: '400', fontFamily: 'Inter', margin: 'auto 0 0 .5Vmin' }}
                    className={`${isDarkMode ? 'text-[#fff]' : 'text-[#000]'}`}
                    id="InputText"
                    placeholder='Search for the icon'
                  />
                </div>
                {category.map((category, index) => (
                  <Box
                    key={index}
                    onClick={() => handleCategorySelect(category.tap)}
                    className={classes.categoryItem}
                    style={{
                      padding: '1Vmin .8Vmin',
                      backgroundColor:
                        selectedCategory === category.tap
                          ? '#0078D3'
                          : '',
                      color: selectedCategory === category.tap ? '#fff' : '#696F80',
                      fontWeight:
                        selectedCategory === category.tap ? '600' : '500',
                    }}
                  >
                    <Box className={`flex flex-row`}>
                      <div 
                      // style={{ transition: 'all .2s ease-in-out' }}
                      >
                        <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 18 18" width={`2.5Vmin`} height={`2.5Vmin`} 
                        // style={{transition:'all .3s ease-in-out'}} 
                        fill={selectedCategory === category.tap? '#fff' : '#000'}><path d={"M3.75 12.75a1.5 1.5 0 0 0 1.5 1.5h7.5a1.5 1.5 0 0 0 1.5-1.5V12a.75.75 0 0 1 1.5 0v.75a3 3 0 0 1-3 3h-7.5a3 3 0 0 1-3-3V12a.75.75 0 0 1 1.5 0zm1.72-4.28a.75.75 0 0 1 1.06 0l1.72 1.72V3a.75.75 0 1 1 1.5 0v7.19l1.72-1.72a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 0 1 0-1.06"}></path></svg>
                      </div>
                      <div className='text-icon' style={{ fontWeight: '600', margin: 'auto .7Vmin' }}>
                        {category.tap}
                      </div>
                    </Box>

                  </Box>
                ))}
              </Box>
              <div style={{ width: '70%', maxHeight: '84Vmin', overflow: 'scroll' }}>
                <div className='flex-row flex flex-wrap'>
                {filterIcons.map((icon, index) => (
                      <div
                        key={index}
                        className={`cursor-pointer flex-row flex w-[10Vmin] h-[10Vmin] align-items-center justify-center`}
                        onClick={() => {
                          handleSelectedIcon(icon)
                        }}
                        title={icon.name}
                        style={{ margin: '.5Vmin auto', 
                          // transition:'border 0.1s ease-in-out', 
                          border:icon.selected === true? `.25Vmin dashed ${icon.fill || '#000'}` : '', borderRadius:'1.2Vmin'}}
                      >
                        <svg aria-hidden="true" focusable="false" role="img" viewBox={icon.viewBox} width={`100%`} height={`50%`} fill={icon.fill || '#000'}><path d={icon.path}></path></svg>
                      </div>
                    ))}
                </div>
              </div>
            </Box>
              <div className='w-full h-full flex justify-center align-items-center bg-[transparent] min-h-[6vmin]'>
                <div className={`${iconSelected? 'bg-[transparent] cursor-pointer hover:bg-[#0867FC] text-[#0867FC] hover:text-[#fff]' : 'bg-[transparent] cursor-pointer text-[#989191]'} transition-all ease-in-out duration-300 `}
                  onClick={() => {
                    let newArr = iconsArr.map((icon) => ({
                      ...icon,
                      selected:null
                    }))
                    setIcons(newArr)
                  }}
                  style={{ border: iconSelected ? '.25Vmin solid #0867FC' : '.25Vmin solid #989191', width: '13Vmin', height: '4Vmin', margin: '1.5Vmin', display: 'flex', borderRadius: '3Vmin' }}>
                  <div className='text-[2Vmin]' style={{ fontWeight: '600', margin: 'auto auto' }}>Reset</div>
                </div>
                <div 
                onClick={handleSaveIconSelected}
                className={`bg-[#0867FC] cursor-pointer hover:bg-[#0156DC] text-[#FFF] transition-all ease-in-out duration-300`} style={{ width: '15Vmin', height: '4Vmin', margin: '1.5Vmin', display: 'flex', borderRadius: '3Vmin' }}>
                  <div className='text-[2Vmin]'
                    style={{ fontWeight: '600', margin: 'auto auto' }}>Save</div>
                </div>
              </div>
            </>}
          </Box>
          


          {images && <div style={{ height: '100%' }}>
            {images && <div className="horizontal-line-container">
              <div className='centered-line'></div>
              <div className="centered-text">or select from our image library</div>
            </div>}
            <Box className={classes.mainArea}>
              {success && <Box className='mb-[.5Vmin]'>
                <CheckCircleIcon className='mr-[.5Vmin] text-[green]' fontSize="medium" />
                Success! Image added here successfully.
                </Box>}
              {images ?
                <Box className='flex flex-col w-full'>
                  <Box className={`flex flex-row justify-start flex-wrap w-full ${showMore ? 'h-[fit-content]' : 'h-[13Vmin]'}`} sx={{ overflow: showMore ? 'scroll' : 'hidden', }}>
                  <input
                    onBlur={() => setFocused(false)}
                    onFocus={() => setFocused(true)}
                    onChange={(e) => {
                      setSearchImage(e.target.value)
                      // console.log(filterImages.length > 1 ? '201' : 'No Photo')
                    }}
                    value={searchImage}
                    style={{width:'27vmin', borderRadius:'2.4vmin', height:'4.8vmin', padding:'.2vmin 1.2vmin', fontSize: '2.2Vmin', fontWeight: '400', fontFamily: 'Inter' }}
                    className={`${isDarkMode ? 'text-[#fff]' : 'text-[#000]'} ${focused? 'border-[#0867FC]' : 'border-[#989191]'} border-[.25vmin] my-auto border-solid border-[#989191] hover:border-[#0867FC]`}
                    id="InputText"
                    placeholder='Search for the image'
                  />
                    {imgsCategory.map((wall, i) => (
                      i <= 4 && (
                        <Box
                          onClick={() => {
                            handleActiveWall(i);
                            handleCategoryChange(wall.name);
                          }}
                          className={` ${activeWall === i ? "border-[#0867FC] text-[#0867FC]" : 'border-[#989191] text-[#989191]'} bg-[transparent] cursor-pointer rounded-[2.5Vmin] m-[.8Vmin] flex border-solid border-[.2Vmin] px-[1.6Vmin] py-[.5Vmin]`} key={i}>
                          <div className='my-auto mx-auto text-[2.2Vmin]'>{wall.name}</div>
                        </Box>
                      )
                    ))}
                    {showMore && imgsCategory.map((wall, i) => (
                      i > 4 && (
                        <Box
                          onClick={() => {
                            handleActiveWall(i);
                            handleCategoryChange(wall.name);
                          }}
                          className={` ${activeWall === i ? "border-[#0867FC] text-[#0867FC]" : 'border-[#989191] text-[#989191]'} bg-[transparent] cursor-pointer rounded-[2.5Vmin] m-[.8Vmin] flex border-solid border-[.2Vmin] px-[1.6Vmin] py-[.5Vmin]`} key={i}>
                          <div className='my-auto mx-auto text-[2.2Vmin]'>{wall.name}</div>
                        </Box>
                      )
                    ))}
                    <div
                      onClick={() => setShowMore(!showMore)}
                      className={`border-[#989191] text-[#989191] bg-[transparent] cursor-pointer rounded-[2.5Vmin] m-[.8Vmin] flex border-solid border-[.2Vmin] px-[1.6Vmin] py-[.5Vmin]`}
                    >
                      <div className='flex my-auto mx-auto text-[2.2Vmin]'>
                        <div>{showMore ? 'Less -' : 'More +'}</div>
                      </div>
                    </div>

                  </Box>
                  <Box className='w-full h-full flex-wrap flex-row flex'>
                    {selectedCategoryImages.length > 1 ?
                      <>
                        {filterImages.length !== 0 ?
                        filteredCategory.map((img, i) => ( 
                          <Box key={i} onClick={() => {
                            onSelect(img.url);
                          }}
                            className='w-[24vmin] h-[19Vmin] duration-300 hover:scale-95' 
                            style={{
                              //  transition: 'All .3 ease-in-out', 
                            margin: '1Vmin auto', borderRadius: '1.4Vmin' }}>
                            <img alt='img' className='cursor-pointer w-full h-full object-fit-cover rounded-[1.4Vmin]' src={img.url} />
                          </Box>
                        )) : 'no photos here'}
                      </> : <>
                        {filterImages.length !== 0 ?
                        filterImages.map((img, i) => (
                          <div
                            onClick={() => {
                              onSelect(img.url);
                            }}
                            key={i}
                            style={{ margin: '1Vmin auto', borderRadius: '1.4Vmin', 
                              // transition: 'background .1s ease-in' 
                            }}
                            className={`cursor-pointer w-[24vmin] h-[19Vmin] `}
                          >
                            <img className='w-full h-full object-cover rounded-[2.5Vmin]' src={img.url} />
                          </div>
                        )) : 'no photos here'}
                      </>
                    }
                  </Box>
                </Box>
                : null}

            </Box>
          </div>}
          
            {images ? <div className='h-auto w-full bg-[#F0F1F3] flex justify-center'>
              <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'center', padding: '.8Vmin 0', alignItems:'center', alignContent:'center',width:'fit-content' }}>
                <div className={`${files.length > 0 ? 'bg-[transparent] mx-[1vmin] hover:bg-[#0867FC] text-[#0867FC] hover:text-[#fff]' : 'bg-[transparent] text-[#989191]'} cursor-pointer transition-all ease-in-out duration-300 `}
                  onClick={() => setFiles([])}
                  style={{ border: files.length > 0 ? '.25Vmin solid #0867FC' : '.25Vmin solid #989191', width: '13Vmin', height: '4Vmin', display: 'flex', borderRadius: '3Vmin' }}>
                  <div className='text-[2Vmin]' style={{ fontWeight: '600', margin: 'auto auto' }}>Reset</div>
                </div>
                <div className={`bg-[#0867FC] cursor-pointer mx-[1vmin] hover:bg-[#0156DC] text-[#FFF] transition-all ease-in-out duration-300`} style={{ width: '15Vmin', height: '4Vmin', display: 'flex', borderRadius: '3Vmin' }}>
                  <div className='text-[2Vmin]'
                    onClick={() => {
                      if (files.length > 0) {
                        pushImgToExArray();
                      }
                    }}
                    style={{ fontWeight: '600', margin: 'auto auto' }}>Save</div>
                </div>
              </div>
            </div> : null}
        </Box>

      </Modal>
    </>
  );
};

export default SelectionBox;
