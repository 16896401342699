import { useState } from "react";
import SelectionBox from "../../components/editor/Viewport/Pop-up/PopUp";
import { category } from "../../components/selectors/basic/Image/ImageSettings";
import { EyeIcon } from "../../components/editor/Viewport/EditorSvg";
import { PenEditIcon } from "../../components/editor/Viewport/Pop-up/svgArray";

const SmallWidgets = ({data, setData, check}) => {
    const [tempTask, setTempTask] = useState({ name: '', icon: {}, event: '' });
    const [iconsSquare, setIconsSquare] = useState(false)
    const [selectedIcon, setSelectedIcon] = useState({});
    const [editLayers, setEditLayers] = useState(false)
    const [openLayer, setOpenLayer] = useState(null)

    const toggleSelection = (name) => {
            setData(prevItems =>
                prevItems.map(item =>
                    item.name === name
                        ? { ...item, selected: !item.selected }
                        : item
                )
            )
    };

    const handleIconImageSelect = (selectedUrl) => {

        if (Array.isArray(selectedUrl) && selectedUrl.length) {
            setSelectedIcon(selectedUrl);
            handleInputChange(null, selectedUrl, 'icon')
        } else {
            setSelectedIcon(selectedUrl)
            handleInputChange(null, selectedUrl, 'icon')
        }
        setIconsSquare(false);

    };

    const handleTaskClick = (index) => {
        setTempTask(data[index]);
        setEditLayers(true)
        setOpenLayer(index);
    };

    const handleInputChange = (e, value, field) => {

        let checkValue = e !== null ? e.target.value : value

        setTempTask(prev => ({
            ...prev,
            [field]: checkValue
        }));

        console.log(tempTask)
    };

    const handleToggleVisibility = (widgetdisplay) => {
        const newVisibility = {
            icon: widgetdisplay === 'icon' ? !tempTask.widgetdisplay.includes('icon') : tempTask.widgetdisplay.includes('icon'),
            name: widgetdisplay === 'name' ? !tempTask.widgetdisplay.includes('name') : tempTask.widgetdisplay.includes('name')
          };

          // Determine new widgetdisplay value
          const newWidgetDisplay = 
            (newVisibility.icon && newVisibility.name) ? 'both' :
            !newVisibility.icon ? 'name' :
            !newVisibility.name ? 'icon' : '';

        setTempTask(prev => ({
                ...prev,
                widgetdisplay: newWidgetDisplay,
                })) 

        }
          
    const handleDone = () => {
        setData(prevTasks =>
            prevTasks.map((task, index) =>
                index === openLayer
                    ? { ...task, ...tempTask }
                    : task
            )
        );
        setEditLayers(false)
        setSelectedIcon({})
        setOpenLayer(null);
    };

    const handleClosePopup = () => {
        setEditLayers(false)
        setOpenLayer(null);
    };

    let currentWidget = data[openLayer] !== null ? data[openLayer] : null

    return (
        <>
            <ol style={{ listStyle: 'outside', fontSize: '1.5vmin', width: '100%', padding:0, display:'flex', justifyContent:'center', alignContent:'center'}}>
                <div className='flex flex-column justify-between w-[100%]'>
                    <div className='relative w-full'>
                        <ol style={{ listStyle: 'outside', padding:'0 4vmin', height:'100%', width:'100%', margin:'0 auto', }} className='h-full max-h-[33vmin] overflow-y-scroll flex flex-col pl-[1.5vmin] list-decimal'>
                            {data.map((widget, index) => (
                                <div key={index} className='flex flex-row justify-between w-full'>
                                    <li style={{ textTransform: 'capitalize' }} className='w-full p-[.25vmin]'>
                                        {widget.name.replace('Vis', '')} Widget
                                    </li>
                                    <div className='flex flex-row justify-center text-[#0867FC] px-[1vmin] rounded'>
                                        <div
                                            style={{ opacity: !widget.selected && '0', pointerEvents: !widget.selected && 'none' }}
                                            className='mx-[1vmin] cursor-pointer'
                                            onClick={() => handleTaskClick(index)}
                                        ><PenEditIcon fill='#0867FC60' /></div>
                                        <div
                                            className='cursor-pointer'
                                            onClick={() => toggleSelection(widget.name)}
                                        >{widget.selected ? 'ON' : 'OFF'}</div>
                                    </div>
                                </div>
                            ))}
                        </ol>

                        {editLayers && currentWidget !== null && <div
                            className='absolute top-0 left-[-4%] bg-[#FFFFFF] py-[2vmin] border-2 w-[fit-content] h-[fit-content] shadow-md rounded-[1.5vmin] px-[3vmin]'
                            >
                            <div className='w-full h-full flex flex-column'>
                                <div className='w-full text-[#fff] text-center bg-[#0867FC] rounded-[.7vmin] py-[.5vmin]' style={{textTransform:'capitalize'}}>
                                    Edit '{currentWidget.name.replace('Vis', '')}' widget
                                </div>
                                <div className='flex flex-column w-full h-full'>
                                {check === 'bottom' && <div className='my-[4%] w-full flex flex-row justify-between items-center p-[2%]'>
                                        <div className='w-[30%]'>Url</div>
                                        <div className='w-[60%] flex justify-center'>
                                            <input
                                                type='text'
                                                placeholder="Add The URL Here"
                                                value={tempTask.url}
                                                onChange={(e) => handleInputChange(e, null, 'url')}
                                                style={{padding:'4%'}}
                                                className='rounded-[1vmin] border text-[1.3vmin] bg-[transparent] text-[#000]'
                                            />
                                        </div>
                                    </div>}
                                    {check !== 'bottom' && currentWidget.name === 'mapVis' && <div className='d-none my-[4%] w-full flex flex-row justify-between items-center p-[2%]'>
                                        try to make the user add any location he want
                                    </div>}
                                    <div className='my-[4%] w-full flex flex-row justify-between items-center ' >
                                        <div className='w-[30%] flex justify-around'>
                                            <div className="mx-[.5vmin]">{currentWidget.name === 'searchVis' ? 'Input':'Name'}</div>
                                            <div onClick={() => handleToggleVisibility('name')}><EyeIcon fill={tempTask.widgetdisplay === 'name' || tempTask.widgetdisplay === 'both'? '#0867FC' : '#989191'}/></div>
                                        </div>
                                        <div className='w-[60%] flex justify-center '>
                                            { currentWidget.name === 'searchVis' ? (
                                            <input
                                                type='text'
                                                placeholder={currentWidget?.placeholder}
                                                value={tempTask.placeholder}
                                                onChange={(e) => handleInputChange(e, null, 'placeholder')}
                                                style={{padding:'4%'}}

                                                className='rounded-[1vmin] border text-[1.3vmin] bg-[transparent] text-[#000]'
                                            />
                                                ) : (
                                                <input
                                                type='text'
                                                placeholder={currentWidget?.name.replace('Vis', '')}
                                                value={tempTask.name.replace('Vis', '')}
                                                onChange={(e) => handleInputChange(e, null, 'name')}
                                                style={{padding:'4%'}}

                                                className='rounded-[1vmin] border text-[1.3vmin] bg-[transparent] text-[#000]'
                                            />
                                            )}
                                        </div>
                                    </div>
                                    <div className='my-[4%] w-full flex flex-column '>
                                       <div className="w-full flex flex-row">
                                       <div className='w-[30%] flex justify-around'>
                                            <div className="mx-[.5vmin]">Icon</div>
                                            <div onClick={() => handleToggleVisibility('icon')}><EyeIcon fill={tempTask.widgetdisplay === 'icon' || tempTask.widgetdisplay === 'both'? '#0867FC' : '#989191'}/></div>
                                        </div>
                                        <div className='w-[100%] text-center flex justify-center'>
                                            <div
                                                style={{ color: '#0867FC', cursor: 'pointer' }}
                                                onClick={() => setIconsSquare(true)}
                                            >
                                                Select an Icon
                                            </div>
                                            {iconsSquare && (
                                                <SelectionBox
                                                    category={category}
                                                    setIconsSquare={setIconsSquare}
                                                    iconsSquare={iconsSquare}
                                                    onSelect={handleIconImageSelect}
                                                />
                                            )}
                                        </div>
                                       </div>
                                        <div className="flex justify-end pr-[27%]">
                                            <svg
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                aria-hidden="true"
                                                focusable="false"
                                                role="img"
                                                viewBox={selectedIcon.viewBox}
                                                width={'4vmin'}
                                                height={'4vmin'}
                                                fill={selectedIcon.fill || '#0867FC'}
                                            >
                                                <path d={selectedIcon.path} />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full flex flex-row justify-around mt-[4vmin]'>
                                    <div
                                        onClick={handleDone}
                                        className='text-center cursor-pointer px-[2vmin] py-[1%] bg-[#0867FC] text-white rounded'
                                    >
                                        Done
                                    </div>
                                    <div
                                        onClick={handleClosePopup}
                                        className='text-center cursor-pointer px-[2vmin] py-[1%] bg-[#e9e9e9] text-[#161617] rounded'
                                    >
                                        Cancel
                                    </div>
                                </div>
                            </div>

                        </div>}

                    </div>
                </div>
            </ol>
        </>
    )
}

export default SmallWidgets