import { useState } from "react";
import GradientSettings from "../../components/editor/Toolbar/GradientColor";
import { imagesArr } from "../../components/editor/Viewport/Pop-up/ImageArray";
import SelectionBox from "../../components/editor/Viewport/Pop-up/PopUp";
import { imgsCate } from "../../components/selectors/basic/Image/ImageSettings";
import { Circle4 } from "../iconsSvg";
import NextButton from "./NextButton";

const BackgroundStep = ({select, setStart, setAppSettings, setProp, setStepper, stepper, setWalksFor}) => {
    const [selected, setSelected] = useState(null);
    const [iconsSquare, setIconsSquare] = useState(false)
    const [imagesSquare, setImagesSquare] = useState(false)
    const [selectedBackground, setSelectedBackground] = useState(null);
    const [images, setImages] = useState(imagesArr)
    const [imgsCategory, setImgsCategory] = useState(imgsCate)

    const [expandedSections, setExpandedSections] = useState({
        library: true,
        color: false,
        gradient: false,
    });


    const prevColors = [
        '#e83030',
        '#5f2b2b',
        '#c31313',
        '#0867fc',
        '#f84173',
        '#ebebeb',
        '#111f3e',
        '#5558b1',
    ];

    
    const checkSelected = () => {

        if (selected) {
            if (typeof selected !== 'string') {
                return selected;
            }

            if (selected.startsWith('#')) {
                return selected;
            }

            if (selected.startsWith('tr')) {
                return 'Transparent';
            }

            return 'Gradient';
        }
    };


    const handleAppBackground = (property) => {
        setStepper(null);
        setSelectedBackground(null);
        setAppSettings((prev) => ({
            ...prev,
            background: { value: property }
        }));
        setProp("ROOT", (props) => {
            props["background"] = property;
        });
    };

    
    const handleColorAndBackground = (value) => {
        setAppSettings((prev) => ({
            ...prev,
            background: { value: value }
        }));
        console.log(value)
        setProp("ROOT", (props) => {
            props["background"] = value;
        });
    };


    const handleImagesSelected = (selectedUrl) => {

        if (Array.isArray(selectedUrl) && selectedUrl.length) {
            setSelectedBackground(selectedUrl[0])
            setAppSettings((prev) => ({
                ...prev,
                background: { value: selectedUrl[0] }
            }));
            setProp("ROOT", (props) => {
                props["background"] = selectedUrl[0];
            });
        } else {
            setSelectedBackground(selectedUrl)
            setAppSettings((prev) => ({
                ...prev,
                background: { value: selectedUrl }
            }));
            setProp("ROOT", (props) => {
                props["background"] = selectedUrl;
            });
        }

        setImagesSquare(false);
    };

    const toggleSection = (section) => {
        setExpandedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };


    return (
        <div className='relative flex flex-column h-[100%]'>
            <div className="space-y-4 overflow-scroll h-[80%] max-h-[50vmin]" style={{ scrollbarWidth: 'none'}}>
                {/* General styles */}
                <div>
                    <div
                        className="w-full text-left flex justify-between items-center text-left  p-[.5vmin] font-semibold"
                    >
                        <div
                            className={` cursor-pointer text-[#161717]`}
                            style={{ fontSize: '2.5Vmin', paddingLeft: "2%", fontWeight: '500', paddingBottom: '1vmin', fontFamily: 'Inter', width: 'fit-content', height: '100%', alignItems: 'center', alignContent: 'center', lineHeight: '100%' }}
                            id="ProjectName"
                        >
                            You Can Set The Background With These Options:
                        </div>
                    </div>
                    <div className='h-full flex flex-col p-[1vmin]'  >
                        <div className='flex flex-col'>
                        <div className='flex flex-col w-full justify-between'>
                            {/* Section for Image Upload */}
                        <div className='shadow-md my-[1vmin]' style={{border: '.25vmin solid #e9e9e9', borderRadius: '1vmin', overflow: 'hidden' }}>
                            <div 
                                className={`bg-[#f0f0f0] hover:text-[#0867FC] text-[#0867FC90]`}
                                onClick={() => toggleSection('library')} 
                                style={{ cursor: 'pointer', padding: '1vmin', borderBottom: '.25vmin solid #e9e9e9', }}
                            >
                                <div style={{ margin: 0 , fontSize:'3vmin', textAlign:'center', fontWeight:'600'}}>Images library</div>
                            </div>                               
                            {expandedSections.library && 
                            <>
                            <div
                                style={{ color: '#0867FC', padding:'2vmin', fontWeight:'600', fontSize: '1.7vmin', cursor: 'pointer', width: '100%', textAlign: 'center' }}
                                onClick={() => setImagesSquare(true)}
                                >
                                Select an Image
                                </div>
                                {imagesSquare && (
                                <SelectionBox
                                    pozition='absolute top-0 right-0'
                                    isImages={true}
                                    images={images}
                                    setImgsCategory={setImgsCategory}
                                    imgsCategory={imgsCategory}
                                    onSelect={handleImagesSelected}
                                    setImages={setImages}
                                    iconsSquare={imagesSquare}
                                    setIconsSquare={setImagesSquare}
                                />
                                )}
                                {selectedBackground !== null && (
                                <div className='mt-[1vmin] my-[.5vmin] flex justify-center items-center flex-col'>
                                    <img
                                    src={selectedBackground}
                                    alt="Preview"
                                    style={{ maxWidth: '100%', borderRadius: '.5vmin', maxHeight: '10vmin', margin: '0 auto' }}
                                    />
                                </div>
                                )}
                                </>
                                }
                            </div>

                            {/* Section for Color Picker */}
                            
                            <div className='shadow-md my-[1vmin]' style={{border: '.25vmin solid #e9e9e9', borderRadius: '1vmin', overflow: 'hidden' }}>
                            <div 
                                className={`bg-[#f0f0f0] hover:text-[#0867FC] text-[#0867FC90]`}
                                onClick={() => toggleSection('color')} 
                                style={{ cursor: 'pointer', padding: '1vmin', borderBottom: '.25vmin solid #e9e9e9', }}
                            >
                                <div style={{ margin: 0 , fontSize:'3vmin', textAlign:'center', fontWeight:'600'}}>Color Preset</div>
                            </div> 
                                {expandedSections.color && 
                                <>
                                <div className='relative my-[1vmin] min-w-[100%] h-[7vmin] flex justify-center items-center rounded-[1vmin] overflow-hidden'>

                                <input
                                style={{ cursor: 'pointer', minWidth: '150%', height: '130%' }}
                                type='color'
                                value={selected}
                                onChange={(e) => {
                                    const background = e.target.value;
                                    handleAppBackground(background);
                                    setSelected(background);
                                }}
                                />

                                <div style={{ pointerEvents: 'none' }} className='absolute top-0 left-0 text-[#fff] text-[2.5vmin] w-full h-full flex items-center justify-center text-center'>
                                <div>{checkSelected() || 'Click Here.'}</div>
                                </div>


                            </div>
                            
                            <div className='my-[1vmin] flex flex-col items-center'>
                                <ul style={{ width: 'fit-content', padding: '0', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', margin: 'auto auto' }}>
                                <li
                                    className='cursor-pointer'
                                    style={{ background: "url(https://i.pinimg.com/originals/40/8e/28/408e28da9e2773e4b65c9bba40307167.png) center", margin: '.35vmin', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '3vmin', height: '3vmin', borderRadius: '50%', border: '.25vmin solid #0867FC30' }}
                                    onClick={() => {
                                    handleAppBackground('transparent');
                                    setSelected('transparent');
                                    }}
                                />
                                {prevColors.map((color, index) => (
                                    <li
                                    key={index}
                                    onClick={() => {
                                        handleAppBackground(color);
                                        setSelected(color);
                                    }}
                                    style={{ cursor: 'pointer', margin: '.35vmin', width: '3vmin', height: '3vmin', borderRadius: '50%', border: '.25vmin solid #e9e9e9', background: color }}
                                    />
                                ))}
                                </ul>
                            </div>
                            </>
                            }

                            </div>
                            <div className='shadow-md my-[1vmin]' style={{border: '.25vmin solid #e9e9e9', borderRadius: '1vmin', overflow: 'hidden' }}>
                            <div 
                                className={`bg-[#f0f0f0] hover:text-[#0867FC] text-[#0867FC90]`}
                                onClick={() => toggleSection('gradient')} 
                                style={{ cursor: 'pointer', padding: '1vmin', borderBottom: '.25vmin solid #e9e9e9', }}
                            >
                                <div style={{ margin: 0 , fontSize:'3vmin', textAlign:'center', fontWeight:'600'}}>Gradient background</div>
                            </div> 
                            {expandedSections.gradient && (
                                <div className="p-[2vmin] text-[2vmin]">
                                    <GradientSettings preview onChange={handleColorAndBackground} />
                                </div>
                            )}
                            </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <NextButton setStart={setStart}
            onClick={() => {
                setStepper(4)
                setWalksFor((prev) => ({
                    ...prev,
                    main: { value: 1 },
                }))
            }}
            />
            {select !== null && <div className='absolute top-[0] right-[-5vmin]'>
                <Circle4 fill={stepper >= 4 ? 'green' : ''} />
            </div>}
        </div>
    )
}

export default BackgroundStep;