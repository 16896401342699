import React, { createContext, useState } from 'react';

const WidthContext = createContext();

export const WidthProvider = ({ children }) => {
  const [width, setWidth] = useState("100%");

  return (
    <WidthContext.Provider value={{ width, setWidth }}>
      {children}
    </WidthContext.Provider>
  );
};

export default WidthContext;
