/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useEffect } from 'react'
import { IconPerson } from '../../../editor/Viewport/Pop-up/svgArray';
import './newFooter.css'
import { Element, useNode } from '@craftjs/core';

import { useState } from 'react'
import { useRef } from 'react'
import { useOptionsContext } from '../../../../OptionsContext'
import { useActiveIdContext } from '../../../../ActiveIdContext'
import { useDarkMode } from '../../../../DarkModeContext'
import { Location } from '../../../editor/Viewport/Pop-up/svgArray';

export default function NewFooter({ navigationSide, setNavigationSide, enabled, setSettings, currentStyleIndex, stylesOn, setStylesOn, logoText, logoIcon, socialIcons }) {

    const { isDarkMode } = useDarkMode();
    const [childrenArray, setChildrenArray] = useState(false)


    const { state: idState, dispatch: idDispatch } = useOptionsContext();
    const { options: treeDatas } = idState;

    const { state, dispatch: setActiveId } = useActiveIdContext();
    const { activeId: activeId } = state;

    const [selectedItem, setSelectedItem] = useState('homeItem');

    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const homeRef = useRef(null)
    const aboutusRef = useRef(null)
    const pricingRef = useRef(null)
    const faqRef = useRef(null)
    const contactRef = useRef(null)
    const componentRef = useRef(null);


    const items = [
        { ref: homeRef, id: 'homeItem' },
        { ref: aboutusRef, id: 'aboutusItem' },
        { ref: pricingRef, id: 'pricingItem' },
        { ref: faqRef, id: 'faqItem' },
        { ref: contactRef, id: 'contactItem' },
    ];

    //   useEffect(() => {
    //     const handleInteraction = (event) => {
    //         if (
    //           componentRef.current &&
    //           !componentRef.current.contains(event.target) &&
    //           event.type !== 'keydown' // Exclude the Escape key
    //         ) {
    //           // Interaction occurred outside the component, disable the functionality
    //           setNavigationSide(false);
    //         }
    //       };

    //       // Add event listener for both mouse and touch interactions
    //       document.addEventListener('mousedown', handleInteraction);
    //       document.addEventListener('touchstart', handleInteraction);
    //       document.addEventListener('keydown', handleInteraction); // Handle Escape key press

    //       // Remove event listeners when the component unmounts
    //       return () => {
    //         document.removeEventListener('mousedown', handleInteraction);
    //         document.removeEventListener('touchstart', handleInteraction);
    //         document.removeEventListener('keydown', handleInteraction);
    //       };
    //     }, []);

    useEffect(() => {
        return () => {
            setSelectedItem(homeRef);
        };
    }, []);

    const handleItemClick = (ref) => {

        setSelectedItem(ref);
        items.forEach((item) => {
            document.getElementById(item.id).style.color = item.ref === ref ? '#100103' : 'inherit';
        });
    };

    const renderChildren = () => {
        if (activeId && activeId.children && activeId.children.length > 0) {
            return (
                <div>
                    {activeId.children.map(child => (
                        <div
                            key={child._id}>{child.name}</div>
                        // Adjust the rendering of child properties as needed
                    ))}
                </div>
            );
        } else {
            return;
        }
    }

    const footer = [
        <div className="footer w-full h-[fit-content] py-10" style={{ borderTop: '1px solid rgba(178, 3, 18, 0.20)' }}>
            <div className="container flex flex-column h-full">
                <div className="w-full footer-wrapper">
                    <div className='left-footer'>
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#510B11'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content mt-2'>{logoText || 'Logo'}</div>
                    </div>
                    <div className='item-list-footer mt-4'>

                        {treeDatas.map((x, key) => (
                            <div key={key} className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}>
                                <div className='item-f'
                                    _id='sidebarItemx'
                                    val={x.name}
                                    style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#100103' : 'inherit', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setActiveId(x)
                                    }}>{x.name}
                                </div>

                                <div>
                                    {x.children.map(child => (
                                        <div
                                            _id='sidebarItemx'
                                            val={child.name}
                                            key={child._id} className='mx-auto my-2 flex justify-start align-center cursor-pointer'>
                                            <div
                                                className='item-f'
                                                style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#100103' : 'inherit', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setActiveId(child)
                                                }}>{child.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        ))}

                    </div>
                    <div className='right-footer'>
                    {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#510B11' : icon?.fill ? icon?.fill : '#510B11'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#510B11' : icon?.fill ? icon?.fill : '#510B11'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                    </div>
                </div>
                <div className='items-hidden'
                >
                    {treeDatas.map(x => (
                        <div className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}

                            onMouseOver={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(true)
                                }
                            }}
                            onMouseOutCapture={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(false)
                                }
                            }}
                        >
                            <div className='item-f m-4 '
                                _id='sidebarItemx'
                                val={x.name}
                                style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#100103' : 'inherit', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setActiveId(x)
                                }}>{x.name}
                            </div>
                            {childrenArray && activeId?._id === x._id && renderChildren(x._id) && (
                                <div className='flex flex-col'>
                                    <div className='my-2 border-1 bg-[#100103a1] text-[#fff] rounded-6 flex align-items justify-center' onClick={() => setActiveId(renderChildren(x._id))}>{renderChildren(x._id)}</div>
                                </div>
                            )}
                        </div>

                    ))}

                </div>
                <div style={{ opacity: '20%', background: '#B20312', width: '100%', height: '1px', margin: '2% 0' }}></div>
                <div className='under-footer mx-auto text-center'>Copyright © 2024. All Rights Reserved</div>

            </div>
        </div>,


        <div className="footer w-full h-[fit-content] py-10" style={{ borderTop: '1px solid #4BDBA2', background: '#121826' }}>
            <div className="container flex flex-column h-full">
                <div className="w-full footer-wrapper">
                    <div className='flex-row flex-wrap flex w-full'>
                        <div className='left-footer'>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#4BDBA2'}><path d={logoIcon?.path} /></svg>
                        </div>
                            <div className='logo-content mt-2' style={{ color: '#4BDBA2' }}>{logoText || 'Logo'}</div>
                        </div>
                        <div className='item-list-footer mt-4'>
                            {treeDatas.map((x, key) => (
                                <div key={key} className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}


                                >
                                    <div className='item-f'
                                        _id='sidebarItemx'
                                        val={x.name}
                                        style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#fff' : '#fff', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(x)
                                        }}>{x.name}
                                    </div>

                                    <div>
                                        {x.children.map(child => (
                                            <div
                                                _id='sidebarItemx'
                                                val={child.name}
                                                key={child._id} className='mx-auto my-2 flex justify-start align-center cursor-pointer'>
                                                <div
                                                    className='item-f'
                                                    style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#fff' : '#fff', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setActiveId(child)
                                                    }}>{child.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            ))}

                        </div>
                    </div>
                    <div className='right-footer '>
                    {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#4bdba2' : icon?.fill ? icon?.fill : '#4bdba2'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#4bdba2' : icon?.fill ? icon?.fill : '#4bdba2'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                    </div>
                </div>
                <div className='items-hidden'
                >
                    {treeDatas.map(x => (
                        <div className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}
                            onMouseOver={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(true)
                                }
                            }}
                            onMouseOutCapture={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(false)
                                }
                            }}
                        >
                            <div className='item-f m-4 '
                                _id='sidebarItemx'
                                val={x.name}
                                style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#fff' : '#fff', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setActiveId(x)
                                }}>{x.name}
                            </div>
                            {childrenArray && activeId?._id === x._id && renderChildren(x._id) && (
                                <div className='flex flex-col'>
                                    <div className='my-2 border-1 bg-[#100103a1] text-[#fff] rounded-6 flex align-items justify-center'
                                        style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#fff' : '#fff', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={() => setActiveId(renderChildren(x._id))}>{renderChildren(x._id)}</div>
                                </div>
                            )}
                        </div>

                    ))}
                </div>
                <div style={{ opacity: '20%', background: '#0078D3', width: '100%', height: '1px', margin: '2% 0' }}></div>
                <div className='under-footer mx-auto text-center' style={{ color: '#fff', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2024. All Rights Reserved</div>

            </div>
        </div>,



        <div className="footer w-full h-[fit-content] py-10" style={{ borderTop: '0px -4px 10px 0px rgba(255, 255, 255, 0.06)', borderRadius: '10px 10px 0px 0px', background: '#213156' }}>
            <div className="container flex flex-column h-full">
                <div className="w-full footer-wrapper">
                    <div className='left-footer'>
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#DD6AEA'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content mt-2' style={{ color: '#EA6EF4' }}>{logoText || 'Logo'}</div>
                    </div>
                    <div className='item-list-footer mt-4'>
                        {treeDatas.map((x, key) => (
                            <div key={key} className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}


                            >
                                <div className='item-f'
                                    _id='sidebarItemx'
                                    val={x.name}
                                    style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#EA6EF4' : '#fff', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setActiveId(x)
                                    }}>{x.name}
                                </div>

                                <div>
                                    {x.children.map(child => (
                                        <div
                                            _id='sidebarItemx'
                                            val={child.name}
                                            key={child._id} className='mx-auto my-2 flex justify-start align-center cursor-pointer'>
                                            <div
                                                className='item-f'
                                                style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#EA6EF4' : '#fff', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setActiveId(child)
                                                }}>{child.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        ))}
                    </div>

                    <div className='right-footer'>
                    {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#EA6EA4' : icon?.fill ? icon?.fill : '#EA6EA4'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#EA6EA4' : icon?.fill ? icon?.fill : '#EA6EA4'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                    </div>

                </div>
                <div className='items-hidden'
                >
                    {treeDatas.map(x => (
                        <div className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}
                            onMouseOver={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(true)
                                }
                            }}
                            onMouseOutCapture={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(false)
                                }
                            }}
                        >
                            <div className='item-f m-4 '
                                _id='sidebarItemx'
                                val={x.name}
                                style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#EA6EF4' : '#FFF', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setActiveId(x)
                                }}>{x.name}
                            </div>
                            {childrenArray && activeId?._id === x._id && renderChildren(x._id) && (
                                <div className='flex flex-col'>
                                    <div className='my-2 border-1 bg-[#100103a1] text-[#fff] rounded-6 flex align-items justify-center'
                                        style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#EA6EF4' : '#FFF', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={() => setActiveId(renderChildren(x._id))}>{renderChildren(x._id)}</div>
                                </div>
                            )}
                        </div>

                    ))}</div>
                <div style={{ opacity: '20%', background: '#EA6EF4', width: '100%', height: '1px', margin: '2% 0' }}></div>
                <div className='under-footer mx-auto text-center' style={{ color: '#fff', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2024. All Rights Reserved</div>

            </div>
        </div>,




        <div className="footer w-full h-[fit-content] py-10" style={{ borderTop: '1px solid rgba(178, 3, 18, 0.20)', background: '#DFFFF9', borderRadius: '10px 10px 0px 0px', boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.02)' }}>
            <div className="container flex flex-column h-full">
                <div className="w-full footer-wrapper">
                    <div className='left-footer'>
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#31A993'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content mt-2' style={{ color: '#31A993' }}>{logoText || 'Logo'}</div>
                    </div>
                    <div className='item-list-footer mt-4'>
                        {treeDatas.map((x, key) => (
                            <div key={key} className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}


                            >
                                <div className='item-f'
                                    _id='sidebarItemx'
                                    val={x.name}
                                    style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#31A993' : '#100103', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setActiveId(x)
                                    }}>{x.name}
                                </div>

                                <div>
                                    {x.children.map(child => (
                                        <div
                                            _id='sidebarItemx'
                                            val={child.name}
                                            key={child._id} className='mx-auto my-2 flex justify-start align-center cursor-pointer'>
                                            <div
                                                className='item-f'
                                                style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#31A993' : '#100103', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setActiveId(child)
                                                }}>{child.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        ))}

                    </div>
                    <div className='right-footer'>
                    {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#31A993' : icon?.fill ? icon?.fill : '#31A993'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#31A993' : icon?.fill ? icon?.fill : '#31A993'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                    </div>
                </div>
                <div className='items-hidden'
                >
                    {treeDatas.map(x => (
                        <div className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}
                            onMouseOver={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(true)
                                }
                            }}
                            onMouseOutCapture={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(false)
                                }
                            }}
                        >
                            <div className='item-f m-4 '
                                _id='sidebarItemx'
                                val={x.name}
                                style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#31A993' : '#100103', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setActiveId(x)
                                }}>{x.name}
                            </div>
                            {childrenArray && activeId?._id === x._id && renderChildren(x._id) && (
                                <div className='flex flex-col'>
                                    <div className='my-2 border-1 bg-[#100103a1] text-[#fff] rounded-6 flex align-items justify-center'
                                        style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#31A993' : '#100103', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={() => setActiveId(renderChildren(x._id))}>{renderChildren(x._id)}</div>
                                </div>
                            )}
                        </div>
                    ))}</div>
                <div style={{ opacity: '20%', background: '#31A993', width: '100%', height: '1px', margin: '2% 0' }}></div>
                <div className='under-footer mx-auto text-center' style={{ color: '#100103', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2024. All Rights Reserved</div>

            </div>
        </div>,


        <div className="footer w-full h-[fit-content] py-10" style={{ borderTop: '1px solid #0078D3', background: '#fff', borderRadius: '10px 10px 0px 0px', boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.02)' }}>
            <div className="container flex flex-column h-full">
                <div className="w-full footer-wrapper">
                    <div className='left-footer'>
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#0078D3'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content mt-2' style={{ color: '#0078D3' }}>{logoText || 'Logo'}</div>
                    </div>
                    <div className='item-list-footer mt-4'>
                        {treeDatas.map((x, key) => (
                            <div key={key} className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}


                            >
                                <div className='item-f'
                                    _id='sidebarItemx'
                                    val={x.name}
                                    style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#100103' : '#100103', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setActiveId(x)
                                    }}>{x.name}
                                </div>

                                <div>
                                    {x.children.map(child => (
                                        <div
                                            _id='sidebarItemx'
                                            val={child.name}
                                            key={child._id} className='mx-auto my-2 flex justify-start align-center cursor-pointer'>
                                            <div
                                                className='item-f'
                                                style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#100103' : '#100103', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setActiveId(child)
                                                }}>{child.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        ))}
                    </div>
                    <div className='right-footer'>
                    {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#007BD3' : icon?.fill ? icon?.fill : '#007BD3'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#007BD3' : icon?.fill ? icon?.fill : '#007BD3'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                    </div>
                </div>
                <div className='items-hidden'
                >
                    {treeDatas.map(x => (
                        <div className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}
                            onMouseOver={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(true)
                                }
                            }}
                            onMouseOutCapture={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(false)
                                }
                            }}
                        >
                            <div className='item-f m-4 '
                                _id='sidebarItemx'
                                val={x.name}
                                style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#100103' : '#100103', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setActiveId(x)
                                }}>{x.name}
                            </div>
                            {childrenArray && activeId?._id === x._id && renderChildren(x._id) && (
                                <div className='flex flex-col'>
                                    <div className='my-2 border-1 bg-[#100103a1] text-[#fff] rounded-6 flex align-items justify-center'
                                        style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#100103' : '#100103', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={() => setActiveId(renderChildren(x._id))}>{renderChildren(x._id)}</div>
                                </div>
                            )}
                        </div>

                    ))}</div>
                <div style={{ opacity: '20%', background: '#0078D3', width: '100%', height: '1px', margin: '2% 0' }}></div>
                <div className='under-footer mx-auto text-center' style={{ color: '#100103', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2024. All Rights Reserved</div>

            </div>
        </div>,



        <div className="footer w-full h-[fit-content] py-10" style={{ background: '#404046' }}>
            <div className="container flex flex-column h-full">
                <div className="w-full footer-wrapper">
                    <div className='left-footer'>
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#FFF'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content mt-2' style={{ color: '#FFFFFF' }}>{logoText || 'Logo'}</div>
                    </div>
                    <div className='item-list-footer mt-4'>
                        {treeDatas.map((x, key) => (
                            <div key={key} className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}


                            >
                                <div className='item-f'
                                    _id='sidebarItemx'
                                    val={x.name}
                                    style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#ffffff' : '#ffffff', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setActiveId(x)
                                    }}>{x.name}
                                </div>

                                <div>
                                    {x.children.map(child => (
                                        <div
                                            _id='sidebarItemx'
                                            val={child.name}
                                            key={child._id} className='mx-auto my-2 flex justify-start align-center cursor-pointer'>
                                            <div
                                                className='item-f'
                                                style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#ffffff' : '#ffffff', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setActiveId(child)
                                                }}>{child.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        ))}
                    </div>
                    <div className='right-footer'>
                    {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#FFF' : icon?.fill ? icon?.fill : '#FFF'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#FFF' : icon?.fill ? icon?.fill : '#FFF'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                    </div>
                </div>
                <div className='items-hidden'
                >
                    {treeDatas.map(x => (
                        <div className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}
                            onMouseOver={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(true)
                                }
                            }}
                            onMouseOutCapture={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(false)
                                }
                            }}
                        >
                            <div className='item-f m-4 '
                                _id='sidebarItemx'
                                val={x.name}
                                style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#FFFFFF' : '#FFFFFF', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setActiveId(x)
                                }}>{x.name}
                            </div>
                            {childrenArray && activeId?._id === x._id && renderChildren(x._id) && (
                                <div className='flex flex-col'>
                                    <div className='my-2 border-1 bg-[#100103a1] text-[#fff] rounded-6 flex align-items justify-center'
                                        style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#FFFFFF' : '#FFFFFF', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={() => setActiveId(renderChildren(x._id))}>{renderChildren(x._id)}</div>
                                </div>
                            )}
                        </div>


                    ))}</div>
                <div style={{ opacity: '20%', background: '#FFFFFF', width: '100%', height: '1px', margin: '2% 0' }}></div>
                <div className='under-footer mx-auto text-center' style={{ color: '#FFFFFF', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2024. All Rights Reserved</div>

            </div>
        </div>,



        <div className="footer w-full h-[fit-content] py-10" style={{ background: '#FBFAFF', borderRadius: '40px 40px 0px 0px', borderTop: '1px solid #6750A4' }}>
            <div className="container flex flex-column h-full">
                <div className="w-full footer-wrapper">
                    <div className='left-footer'>
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#6750A4'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content mt-2' style={{ color: '#6750A4' }}>{logoText || 'Logo'}</div>
                    </div>
                    <div className='item-list-footer mt-4'>
                        {treeDatas.map((x, key) => (
                            <div key={key} className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}>
                                <div className='item-f'
                                    _id='sidebarItemx'
                                    val={x.name}
                                    style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#6750A4' : '#100103', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setActiveId(x)
                                    }}>{x.name}
                                </div>

                                <div>
                                    {x.children.map(child => (
                                        <div
                                            _id='sidebarItemx'
                                            val={child.name}
                                            key={child._id} className='mx-auto my-2 flex justify-start align-center cursor-pointer'>
                                            <div
                                                className='item-f'
                                                style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#6750A4' : '#100103', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setActiveId(child)
                                                }}>{child.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        ))}
                    </div>
                    <div className='right-footer'>
                    {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#FFF' : icon?.fill ? icon?.fill : '#FFF'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#FFF' : icon?.fill ? icon?.fill : '#FFF'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                    </div>
                </div>
                <div className='items-hidden'
                >
                    {treeDatas.map(x => (
                        <div className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}
                            onMouseOver={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(true)
                                }
                            }}
                            onMouseOutCapture={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(false)
                                }
                            }}
                        >
                            <div className='item-f m-4 '
                                _id='sidebarItemx'
                                val={x.name}
                                style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#6750A4' : '#100103', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setActiveId(x)
                                }}>{x.name}
                            </div>
                            {childrenArray && activeId?._id === x._id && renderChildren(x._id) && (
                                <div className='flex flex-col'>
                                    <div className='my-2 border-1 bg-[#100103a1] text-[#fff] rounded-6 flex align-items justify-center'
                                        style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#6750A4' : '#100103', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={() => setActiveId(renderChildren(x._id))}>{renderChildren(x._id)}</div>
                                </div>
                            )}
                        </div>
                    ))}</div>
                <div className='under-footer mx-auto text-center mt-[4%]' style={{ color: '#100103', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2024. All Rights Reserved</div>

            </div>
        </div>,

        <div className="footer w-full h-[fit-content] py-10" style={{ background: '#2A2A2A', borderRadius: '10px 10px 0px 0px', borderTop: '1px solid #FFD600' }}>
            <div className="container flex flex-column h-full">
                <div className="w-full footer-wrapper">
                    <div className='left-footer'>
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#FFD600'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content mt-2' style={{ color: '#FFD600' }}>{logoText || 'Logo'}</div>
                    </div>
                    <div className='item-list-footer mt-4'>
                        {treeDatas.map((x, key) => (
                            <div key={key} className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}


                            >
                                <div className='item-f'
                                    _id='sidebarItemx'
                                    val={x.name}
                                    style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#ffffff' : '#ffffff', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setActiveId(x)
                                    }}>{x.name}
                                </div>

                                <div>
                                    {x.children.map(child => (
                                        <div
                                            _id='sidebarItemx'
                                            val={child.name}
                                            key={child._id} className='mx-auto my-2 flex justify-start align-center cursor-pointer'>
                                            <div
                                                className='item-f'
                                                style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#ffffff' : '#ffffff', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setActiveId(child)
                                                }}>{child.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        ))}
                    </div>
                    <div className='right-footer'>
                    {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#FFd600' : icon?.fill ? icon?.fill : '#FFd600'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#FFd600' : icon?.fill ? icon?.fill : '#FFd600'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                    </div>
                </div>
                <div className='items-hidden'
                >
                    {treeDatas.map(x => (
                        <div className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}
                            onMouseOver={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(true)
                                }
                            }}
                            onMouseOutCapture={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(false)
                                }
                            }}
                        >
                            <div className='item-f m-4 '
                                _id='sidebarItemx'
                                val={x.name}
                                style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#FFFFFF' : '#FFFFFF', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setActiveId(x)
                                }}>{x.name}
                            </div>
                            {childrenArray && activeId?._id === x._id && renderChildren(x._id) && (
                                <div className='flex flex-col'>
                                    <div className='my-2 border-1 bg-[#100103a1] text-[#fff] rounded-6 flex align-items justify-center'
                                        style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#FFFFFF' : '#FFFFFF', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={() => setActiveId(renderChildren(x._id))}>{renderChildren(x._id)}</div>
                                </div>
                            )}
                        </div>

                    ))}</div>
                <div style={{ opacity: '20%', background: '#FFD600', width: '100%', height: '1px', margin: '2% 0' }}></div>
                <div className='under-footer mx-auto text-center' style={{ color: '#FFFFFF', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2024. All Rights Reserved</div>

            </div>
        </div>,

        <div className="footer w-full h-[fit-content] py-10" style={{ background: '#F7FBFE', borderTop: '1px solid #6064D2', borderRadius: '10px 10px 0 0' }}>
            <div className="container flex flex-column h-full">
                <div className="w-full footer-wrapper">
                    <div className='left-footer'>
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#B20312'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content mt-2' style={{ color: '#B20312' }}>{logoText || 'Logo'}</div>
                    </div>
                    <div className='item-list-footer mt-4'>
                        {treeDatas.map((x, key) => (
                            <div key={key} className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}


                            >
                                <div className='item-f'
                                    _id='sidebarItemx'
                                    val={x.name}
                                    style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#000' : '#000', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setActiveId(x)
                                    }}>{x.name}
                                </div>

                                <div>
                                    {x.children.map(child => (
                                        <div
                                            _id='sidebarItemx'
                                            val={child.name}
                                            key={child._id} className='mx-auto my-2 flex justify-start align-center cursor-pointer'>
                                            <div
                                                className='item-f'
                                                style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#000' : '#000', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setActiveId(child)
                                                }}>{child.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        ))}
                    </div>
                    <div className='right-footer'>
                    {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#FFF' : icon?.fill ? icon?.fill : '#FFF'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#FFF' : icon?.fill ? icon?.fill : '#FFF'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                    </div>

                </div>
                <div className='items-hidden'
                >
                    {treeDatas.map(x => (
                        <div className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}
                            onMouseOver={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(true)
                                }
                            }}
                            onMouseOutCapture={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(false)
                                }
                            }}
                        >
                            <div className='item-f m-4 '
                                _id='sidebarItemx'
                                val={x.name}
                                style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#000' : '#000', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setActiveId(x)
                                }}>{x.name}
                            </div>
                            {childrenArray && activeId?._id === x._id && renderChildren(x._id) && (
                                <div className='flex flex-col'>
                                    <div className='my-2 border-1 bg-[#100103a1] text-[#fff] rounded-6 flex align-items justify-center'
                                        style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#000' : '#000', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={() => setActiveId(renderChildren(x._id))}>{renderChildren(x._id)}</div>
                                </div>
                            )}
                        </div>


                    ))}</div>
                <div style={{ opacity: '20%', background: '#000', width: '100%', height: '1px', margin: '2% 0' }}></div>
                <div className='under-footer mx-auto text-center' style={{ color: '#000', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2024. All Rights Reserved</div>

            </div>
        </div>,


        <div className="footer w-full h-[fit-content] py-10" style={{ background: '#A25738', borderRadius: '10px 10px 0 0' }}>
            <div className="container flex flex-column h-full">
                <div className="w-full footer-wrapper">
                    <div className='left-footer'>
                    <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center w-auto h-auto'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#FEFBF7'}><path d={logoIcon?.path} /></svg>
                        </div>
                        <div className='logo-content mt-2' style={{ color: '#FFFFFF' }}>{logoText || 'Logo'}</div>
                    </div>
                    <div className='item-list-footer mt-4'>
                        {treeDatas.map((x, key) => (
                            <div key={key} className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}


                            >
                                <div className='item-f'
                                    _id='sidebarItemx'
                                    val={x.name}
                                    style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#ffffff' : '#ffffff', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setActiveId(x)
                                    }}>{x.name}
                                </div>

                                <div>
                                    {x.children.map(child => (
                                        <div
                                            _id='sidebarItemx'
                                            val={child.name}
                                            key={child._id} className='mx-auto my-2 flex justify-start align-center cursor-pointer'>
                                            <div
                                                className='item-f'
                                                style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#ffffff' : '#ffffff', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setActiveId(child)
                                                }}>{child.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        ))}
                    </div>
                    <div className='right-footer'>
                    {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#FFF' : icon?.fill ? icon?.fill : '#FFF'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#FFF' : icon?.fill ? icon?.fill : '#FFF'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                    </div>
                </div>
                <div className='items-hidden'
                >
                    {treeDatas.map(x => (
                        <div className='flex flex-col align-items mx-4 min-w-[90px] justify-start' style={{ alignItems: 'flex-start' }}
                            onMouseOver={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(true)
                                }
                            }}
                            onMouseOutCapture={() => {
                                if (activeId?._id === x._id && renderChildren(x._id)) {
                                    setChildrenArray(false)
                                }
                            }}
                        >
                            <div className='item-f m-4 '
                                _id='sidebarItemx'
                                val={x.name}
                                style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#FFFFFF' : '#FFFFFF', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setActiveId(x)
                                }}>{x.name}
                            </div>
                            {childrenArray && activeId?._id === x._id && renderChildren(x._id) && (
                                <div className='flex flex-col'>
                                    <div className='my-2 border-1 bg-[#100103a1] text-[#fff] rounded-6 flex align-items justify-center'
                                        style={{ textDecoration: 'none', color: activeId?._id === x._id ? '#FFFFFF' : '#FFFFFF', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={() => setActiveId(renderChildren(x._id))}>{renderChildren(x._id)}</div>
                                </div>
                            )}
                        </div>


                    ))}</div>
                <div style={{ opacity: '20%', background: '#FFFFFF', width: '100%', height: '1px', margin: '2% 0' }}></div>
                <div className='under-footer mx-auto text-center' style={{ color: '#FFFFFF', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2024. All Rights Reserved</div>

            </div>
        </div>,

        <div className="footer w-full h-[fit-content] py-10" style={{ background: '#2479FD' }}>
            <div className="container flex flex-column h-full">
                <div className='w-full h-full flex-row flex flex-wrap' >
                    <div className='w-[100%]' style={{ flex: '60%', width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap-reverse' }}>
                        <div className='w-[100%] min-w-[250px]' style={{ flex: '50%', flexDirection: 'row', flexWrap: 'wrap', display: 'flex' }}>
                            {treeDatas.map((x, key) => (
                                <div cla key={key} className='w-[100px] flex mx-auto'>
                                    <div className='item-f text-center items-start justify-start'
                                        _id='sidebarItemx'
                                        val={x.name}
                                        style={{ textDecoration: 'none', fontFamily: 'Poppins', borderBottom: activeId?._id === x._id ? '0px solid #FFC92E' : null, color: activeId?._id === x._id ? '#ffffff' : '#ffffff', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(x)
                                        }}>{x.name}
                                    </div>

                                    {/* <div>
                        {x.children.map(child => (
                            <div
                                _id='sidebarItemx'
                        val={child.name}
                                key={child._id} className='mx-auto my-2 flex justify-start align-center cursor-pointer'>
                                         <div
                                        className='item-f text-center'
                                         style={{ textDecoration: 'none', color:  activeId?._id == child?._id   ? '#ffffff' : '#ffffff', fontWeight:  activeId?._id == child?._id   ? '700' : '400' }}

                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(child)}}>{child.name}</div>
                                    </div>
                                ))}
                        </div> */}
                                </div>

                            ))}
                        </div>
                        <div className='w-[50%] min-w-[200px]' style={{ display: 'flex', flexDirection: 'row', margin: '0 auto', justifyItems: 'center', justifyContent: 'center' }}>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#FFC92E'}><path d={logoIcon?.path} /></svg>

                        </div>
                            <div style={{ fontSize: '22px', fontWeight: 'bold', fontFamily: 'Poppins', color: '#FFC92E' }}>{logoText || 'Logo'}</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: '30%', width: '100%', justifyContent: 'center', justifyItems: 'center' }}>
                        <div className='d-none mx-auto uppercase flex bg-[#FFC92E] hover:bg-[#FFF5D7] border-1 hover:border-[#FFB800] cursor-pointer' style={{ width: '226px', height: '60px', borderRadius: '14px', fontFamily: 'Poppins', }}>
                            <div className='mx-auto my-auto text-[#000] text-[22px] font-bold'> Try For Free </div>
                        </div>

                        <div className='w-full flex flex-row justify-center align-items-center mx-auto mt-[10px]'>
                        {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#FFC92E' : icon?.fill ? icon?.fill : '#FFC92E'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#FFC92E' : icon?.fill ? icon?.fill : '#FFC92E'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: '16px', color: '#FFF', fontWeight: '500', 
fontFamily: 'Jost', opacity: '60%', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2023. All Rights Reserved</div>
            </div>
        </div>,

        <div className="footer w-full h-[fit-content] py-10" style={{ background: '#FF0060' }}>
            <div className="container flex flex-column h-full">
                <div className='w-full h-full flex-row flex flex-wrap' >
                    <div className='w-[100%]' style={{ flex: '60%', width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap-reverse' }}>
                        <div className='w-[100%] min-w-[250px]' style={{ flex: '50%', flexDirection: 'row', flexWrap: 'wrap', display: 'flex' }}>
                            {treeDatas.map((x, key) => (
                                <div cla key={key} className='w-[100px] flex mx-auto'>
                                    <div className='item-f text-center'
                                        _id='sidebarItemx'
                                        val={x.name}
                                        style={{ textDecoration: 'none', fontFamily: 'Prompt', borderBottom: activeId?._id === x._id ? '0px solid #F6FA70' : null, color: activeId?._id === x._id ? '#ffffff' : '#ffffff', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(x)
                                        }}>{x.name}
                                    </div>

                                    {/* <div>
                        {x.children.map(child => (
                            <div
                                _id='sidebarItemx'
                        val={child.name}
                                key={child._id} className='mx-auto my-2 flex justify-start align-center cursor-pointer'>
                                         <div
                                        className='item-f text-center'
                                         style={{ textDecoration: 'none', color:  activeId?._id == child?._id   ? '#ffffff' : '#ffffff', fontWeight:  activeId?._id == child?._id   ? '700' : '400' }}

                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(child)}}>{child.name}</div>
                                    </div>
                                ))}
                        </div> */}
                                </div>

                            ))}
                        </div>
                        <div className='w-[50%] min-w-[200px]' style={{ display: 'flex', flexDirection: 'row', margin: '0 auto', justifyItems: 'center', justifyContent: 'center' }}>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#F6FA70'}><path d={logoIcon?.path} /></svg>

                        </div>
                            <div style={{ fontSize: '22px', fontWeight: 'bold', fontFamily: 'Prompt', color: '#F6FA70' }}>{logoText || 'Logo'}</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: '30%', width: '100%', justifyContent: 'center', justifyItems: 'center' }}>
                        <div className='d-none mx-auto uppercase flex hover:bg-[#C20049] border-1 border-[#F6FA70] cursor-pointer' style={{ width: '280px', height: '60px', borderRadius: '30px' }}>
                            <div className='mx-auto my-auto text-[#fff] text-[22px] font-bold' style={{ fontFamily: 'Prompt' }}> Try For Free </div>
                        </div>

                        <div className='w-full flex flex-row justify-center align-items-center mx-auto mt-[10px]'>
                        {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#F6FA70' : icon?.fill ? icon?.fill : '#F6FA70'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#F6FA70' : icon?.fill ? icon?.fill : '#F6FA70'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: '16px', color: '#FFF', fontWeight: '500', 
opacity: '60%', fontFamily: 'Jost', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2023. All Rights Reserved</div>
            </div>
        </div>,

        <div className="footer w-full h-[fit-content] py-10" style={{ background: '#FFB7B7' }}>
            <div className="container flex flex-column h-full">
                <div className='w-full h-full flex-row flex flex-wrap' >
                    <div className='w-[100%]' style={{ flex: '60%', width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap-reverse' }}>
                        <div className='w-[100%] min-w-[250px]' style={{ flex: '50%', flexDirection: 'row', flexWrap: 'wrap', display: 'flex' }}>
                            {treeDatas.map((x, key) => (
                                <div cla key={key} className='w-[100px] flex mx-auto'>
                                    <div className='item-f text-center'
                                        _id='sidebarItemx'
                                        val={x.name}
                                        style={{ textDecoration: 'none', fontFamily: 'Almarai', borderBottom: activeId?._id === x._id ? '0px solid #F31559' : null, color: activeId?._id === x._id ? '#01060E' : '#01060E', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(x)
                                        }}>{x.name}
                                    </div>

                                    {/* <div>
                        {x.children.map(child => (
                            <div
                                _id='sidebarItemx'
                        val={child.name}
                                key={child._id} className='mx-auto my-2 flex justify-start align-center cursor-pointer'>
                                         <div
                                        className='item-f text-center'
                                         style={{ textDecoration: 'none', color:  activeId?._id == child?._id   ? '#ffffff' : '#ffffff', fontWeight:  activeId?._id == child?._id   ? '700' : '400' }}

                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(child)}}>{child.name}</div>
                                    </div>
                                ))}
                        </div> */}
                                </div>

                            ))}
                        </div>
                        <div className='w-[50%] min-w-[200px]' style={{ display: 'flex', flexDirection: 'row', margin: '0 auto', justifyItems: 'center', justifyContent: 'center' }}>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#F31559'}><path d={logoIcon?.path} /></svg>

                        </div>
                            <div style={{ fontSize: '22px', fontWeight: 'bold', fontFamily: 'Almarai', color: '#F31559' }}>{logoText || 'Logo'}</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: '30%', width: '100%', justifyContent: 'center', justifyItems: 'center' }}>
                        <div className='d-none mx-auto uppercase flex w-[280px] h-[60px] bg-[#F31559] hover:bg-[#FF5B8D] cursor-pointer' style={{ borderRadius: '8px' }}>
                            <div className='mx-auto my-auto text-[#fff] text-[22px] font-bold' style={{ fontFamily: 'Almarai' }}> Try For Free </div>
                        </div>
 
                        <div className='w-full flex flex-row justify-center align-items-center mx-auto mt-[10px]'>
                        {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#F6FA70' : icon?.fill ? icon?.fill : '#F6FA70'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#F6FA70' : icon?.fill ? icon?.fill : '#F6FA70'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: '16px', color: '#000000', fontWeight: '500', 
opacity: '60%', fontFamily: 'Jost', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2023. All Rights Reserved</div>
            </div>
        </div>,


        <div className="footer w-full h-[fit-content] py-10" style={{ background: '#0C134F' }}>
            <div className="container flex flex-column h-full">
                <div className='w-full h-full flex-row flex flex-wrap' >
                    <div className='w-[100%]' style={{ flex: '60%', width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap-reverse' }}>
                        <div className='w-[100%] min-w-[250px]' style={{ flex: '50%', flexDirection: 'row', flexWrap: 'wrap', display: 'flex' }}>
                            {treeDatas.map((x, key) => (
                                <div cla key={key} className='w-[100px] flex mx-auto'>
                                    <div className='item-f text-center'
                                        _id='sidebarItemx'
                                        val={x.name}
                                        style={{ textDecoration: 'none', fontFamily: 'Rubik', borderBottom: activeId?._id === x._id ? '0px solid #D4ADFC' : null, color: activeId?._id === x._id ? '#FFFFFF' : '#FFFFFF', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(x)
                                        }}>{x.name}
                                    </div>

                                    {/* <div>
                        {x.children.map(child => (
                            <div
                                _id='sidebarItemx'
                        val={child.name}
                                key={child._id} className='mx-auto my-2 flex justify-start align-center cursor-pointer'>
                                         <div
                                        className='item-f text-center'
                                         style={{ textDecoration: 'none', color:  activeId?._id == child?._id   ? '#ffffff' : '#ffffff', fontWeight:  activeId?._id == child?._id   ? '700' : '400' }}

                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(child)}}>{child.name}</div>
                                    </div>
                                ))}
                        </div> */}
                                </div>

                            ))}
                        </div>
                        <div className='w-[50%] min-w-[200px]' style={{ display: 'flex', flexDirection: 'row', margin: '0 auto', justifyItems: 'center', justifyContent: 'center' }}>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#FFFFFF'}><path d={logoIcon?.path} /></svg>

                        </div>
                            <div style={{ fontSize: '22px', fontWeight: 'bold', fontFamily: 'Rubik', color: '#D4ADFC' }}>{logoText || 'Logo'}</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: '30%', width: '100%', justifyContent: 'center', justifyItems: 'center' }}>
                        <div className='d-none mx-auto uppercase flex w-[280px] h-[60px] bg-[#D4ADFC] hover:bg-[#C68EFF]  cursor-pointer' style={{ borderRadius: '8px' }}>
                            <div className='mx-auto my-auto text-[#0C134F] text-[22px] font-bold' style={{ fontFamily: 'Rubik' }}> Try For Free </div>
                        </div>

                        <div className='w-full flex flex-row justify-center align-items-center mx-auto mt-[10px]'>
                        {socialIcons?.map((icon, index) => (
                                <div className='flex justify-center  align-items-center' style={{alignContent:'center', alignItems:'center'}}>
                                    {!enabled ? <a className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center' href={icon.href} target={"_blank"} rel="noopener noreferrer" >
                                    <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#F6FA70' : icon?.fill ? icon?.fill : '#F6FA70'}><path d={icon?.path}></path></svg>
                                </a> : <div className='w-[34px] h-[34px] mr-[.5Vmin] flex justify-center align-items-center'>
                                <svg aria-hidden="true" focusable="false" role="img" viewBox={icon?.viewBox} width={icon.width} height={icon.height} fill={!isDarkMode ? icon?.fill ? icon?.fill : '#F6FA70' : icon?.fill ? icon?.fill : '#F6FA70'}><path d={icon?.path}></path></svg>
                                </div>}
                                </div>
                            ))}
                        </div>


                    </div>
                </div>
                <div style={{ fontSize: '16px', color: '#FFF', fontWeight: '500', 
opacity: '60%', fontFamily: 'Jost', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2023. All Rights Reserved</div>
            </div>
        </div>,

        <div className='w-full h-full bg-[#E5E5E5] py-10'>
            <div className='container flex flex-column h-full'>
                <div className='w-full flex-row flex flex-wrap'>
                    <div className='min-w-[250px]' style={{ flex: '70%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ margin: '0 10px' }}>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                        </div>
                        <div className='w-[90%] mx-auto h-auto pt-4 pb-8 flex sm:flex-col flex-row flex-wrap justify-start items-start'>
                            {treeDatas.map((x, key) => (
                                <div key={key} className='w-[100px] justify-center items-center flex flex-col '>
                                    <div className='item-f text-center  '
                                        _id='sidebarItemx'
                                        val={x.name}
                                        style={{ textDecoration: 'none', fontFamily: 'Rubik', color: activeId?._id === x._id ? '#000' : '#989191', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(x)
                                        }}>{x.name}
                                    </div>

                                    <div>
                                        {x.children.map(child => (
                                            <div
                                                _id='sidebarItemx'
                                                val={child.name}
                                                key={child._id} className='mx-auto my-2     flex justify-start align-start cursor-pointer'>
                                                <div
                                                    className='item-f text-center'
                                                    style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#000' : '#989191', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setActiveId(child)
                                                    }}>{child.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    <div style={{ flex: '30%', display: 'flex', flexDirection: 'column', margin: '0 auto' }}>
                        <div className='button-full mx-auto' style={{ width: '204px', height: '46px', borderRadius: '33px' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Contact</div>
                        </div>
                        <div className='d-none max-w-[210px] my-4 mx-auto flex flex-row justify-betwen'>
                            <div className='w-auto px-2'>
                                <Location fill='#000' />
                            </div>
                            <div className='w-auto'>
                                <div className='text-[16px] text-[#000]' style={{ fontFamily: 'Inter', fontWeight: '700' }}>Belfast</div>
                                <div className='text-[16px] text-[#000]' style={{ fontFamily: 'Inter', fontWeight: '500' }}>
                                    {`70 Railroad
                                    AvenueOlive Branch,
                                    MS 38654`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: '16px', color: '#000000', fontWeight: '500', 
opacity: '60%', fontFamily: 'Inter' , textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2023. All Rights Reserved</div>
            </div>
        </div>,


        <div className='w-full h-full bg-[#424146] py-10'>
            <div className='container flex flex-column h-full'>
                <div className='w-full flex-row flex flex-wrap'>
                    <div className='min-w-[250px]' style={{ flex: '70%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ margin: '0 10px' }}>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#FFFFFF'}><path d={logoIcon?.path} /></svg>
                        </div>
                        </div>
                        <div className='w-[90%] mx-auto h-auto pt-4 pb-8 flex sm:flex-col flex-row flex-wrap justify-start items-start'>
                            {treeDatas.map((x, key) => (
                                <div key={key} className='w-[100px] justify-center items-center flex flex-col '>
                                    <div className='item-f text-center  '
                                        _id='sidebarItemx'
                                        val={x.name}
                                        style={{ textDecoration: 'none', fontFamily: 'Rubik', color: activeId?._id === x._id ? '#FFFFFF' : '#989191', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(x)
                                        }}>{x.name}
                                    </div>

                                    <div>
                                        {x.children.map(child => (
                                            <div
                                                _id='sidebarItemx'
                                                val={child.name}
                                                key={child._id} className='mx-auto my-2 flex justify-start align-start cursor-pointer'>
                                                <div
                                                    className='item-f text-center'
                                                    style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#FFFFFF' : '#989191', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setActiveId(child)
                                                    }}>{child.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    <div style={{ flex: '30%', display: 'flex', flexDirection: 'column', margin: '0 auto' }}>
                        <div className='button-full mx-auto' style={{ width: '204px', height: '46px', borderRadius: '33px', background: '#9007D2' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Contact</div>
                        </div>
                        <div className='d-none max-w-[210px] my-4 mx-auto flex flex-row justify-betwen'>
                            <div className='w-auto px-2'>
                                <Location fill='#FFFFFF' />
                            </div>
                            <div className='w-auto'>
                                <div className='text-[16px] text-[#FFFFFF]' style={{ fontFamily: 'Inter', fontWeight: '700' }}>Belfast</div>
                                <div className='text-[16px] text-[#FFFFFF]' style={{ fontFamily: 'Inter', fontWeight: '500' }}>
                                    {`70 Railroad
                        AvenueOlive Branch,
                        MS 38654`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: '16px', color: '#FFFFFF', fontWeight: '500', 
opacity: '60%', fontFamily: 'Inter', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2023. All Rights Reserved</div>
            </div>
        </div>,

        <div className='w-full h-full bg-[#DDEDFB] py-10'>
            <div className='container flex flex-column h-full'>
                <div className='w-full flex-row flex flex-wrap'>
                    <div className='min-w-[250px]' style={{ flex: '70%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ margin: '0 10px' }}>
                           
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                        </div>
                        <div className='w-[90%] mx-auto h-auto pt-4 pb-8 flex sm:flex-col flex-row flex-wrap justify-start items-start'>
                            {treeDatas.map((x, key) => (
                                <div key={key} className='w-[100px] justify-center items-center flex flex-col '>
                                    <div className='item-f text-center  '
                                        _id='sidebarItemx'
                                        val={x.name}
                                        style={{ textDecoration: 'none', fontFamily: 'Rubik', color: activeId?._id === x._id ? '#000000' : '#989191', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(x)
                                        }}>{x.name}
                                    </div>

                                    <div>
                                        {x.children.map(child => (
                                            <div
                                                _id='sidebarItemx'
                                                val={child.name}
                                                key={child._id} className='mx-auto my-2     flex justify-start align-start cursor-pointer'>
                                                <div
                                                    className='item-f text-center'
                                                    style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#000000' : '#989191', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setActiveId(child)
                                                    }}>{child.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    <div style={{ flex: '30%', display: 'flex', flexDirection: 'column', margin: '0 auto' }}>
                        <div className='button-full mx-auto' style={{ width: '204px', height: '46px', borderRadius: '33px', background: '#035CFB' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Contact</div>
                        </div>
                        <div className='d-none max-w-[210px] my-4 mx-auto flex flex-row justify-betwen'>
                            <div className='w-auto px-2'>
                                <Location fill='#000' />
                            </div>
                            <div className='w-auto'>
                                <div className='text-[16px] text-[#000]' style={{ fontFamily: 'Inter', fontWeight: '700' }}>Belfast</div>
                                <div className='text-[16px] text-[#000]' style={{ fontFamily: 'Inter', fontWeight: '500' }}>
                                    {`70 Railroad
                        AvenueOlive Branch,
                        MS 38654`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: '16px', color: '#000', fontWeight: '500', 
opacity: '60%', fontFamily: 'Inter', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2023. All Rights Reserved</div>
            </div>
        </div>,


        <div className='w-full h-full bg-[#CADAD4] py-10'>
            <div className='container flex flex-column h-full'>
                <div className='w-full flex-row flex flex-wrap'>
                    <div className='min-w-[250px]' style={{ flex: '70%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ margin: '0 10px' }}>
                          
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                        </div>
                        <div className='w-[90%] mx-auto h-auto pt-4 pb-8 flex sm:flex-col flex-row flex-wrap justify-start items-start'>
                            {treeDatas.map((x, key) => (
                                <div key={key} className='w-[100px] justify-center items-center flex flex-col '>
                                    <div className='item-f text-center  '
                                        _id='sidebarItemx'
                                        val={x.name}
                                        style={{ textDecoration: 'none', fontFamily: 'Rubik', color: activeId?._id === x._id ? '#000000' : '#989191', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(x)
                                        }}>{x.name}
                                    </div>

                                    <div>
                                        {x.children.map(child => (
                                            <div
                                                _id='sidebarItemx'
                                                val={child.name}
                                                key={child._id} className='mx-auto my-2     flex justify-start align-start cursor-pointer'>
                                                <div
                                                    className='item-f text-center'
                                                    style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#000000' : '#989191', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setActiveId(child)
                                                    }}>{child.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    <div style={{ flex: '30%', display: 'flex', flexDirection: 'column', margin: '0 auto' }}>
                        <div className='button-full mx-auto' style={{ width: '204px', height: '46px', borderRadius: '33px', background: '#3DA169' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Contact</div>
                        </div>
                        <div className='d-none max-w-[210px] my-4 mx-auto flex flex-row justify-betwen'>
                            <div className='w-auto px-2'>
                                <Location fill='#000' />
                            </div>
                            <div className='w-auto'>
                                <div className='text-[16px] text-[#000]' style={{ fontFamily: 'Inter', fontWeight: '700' }}>Belfast</div>
                                <div className='text-[16px] text-[#000]' style={{ fontFamily: 'Inter', fontWeight: '500' }}>
                                    {`70 Railroad
                        AvenueOlive Branch,
                        MS 38654`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: '16px', color: '#000', fontWeight: '500', 
opacity: '60%', fontFamily: 'Inter', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2023. All Rights Reserved</div>
            </div>
        </div>,


        <div className='w-full h-full bg-[#FFFFFF] py-10'>
            <div className='container flex flex-column h-full'>
                <div className='w-full flex-row flex flex-wrap'>
                    <div className='min-w-[250px]' style={{ flex: '70%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ margin: '0 10px' }}>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                        </div>
                        <div className='w-[90%] mx-auto h-auto pt-4 pb-8 flex sm:flex-col flex-row flex-wrap justify-start items-start'>
                            {treeDatas.map((x, key) => (
                                <div key={key} className='w-[100px] justify-center items-center flex flex-col '>
                                    <div className='item-f text-center  '
                                        _id='sidebarItemx'
                                        val={x.name}
                                        style={{ textDecoration: 'none', fontFamily: 'Rubik', color: activeId?._id === x._id ? '#000000' : '#989191', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(x)
                                        }}>{x.name}
                                    </div>

                                    <div>
                                        {x.children.map(child => (
                                            <div
                                                _id='sidebarItemx'
                                                val={child.name}
                                                key={child._id} className='mx-auto my-2     flex justify-start align-start cursor-pointer'>
                                                <div
                                                    className='item-f text-center'
                                                    style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#000000' : '#989191', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setActiveId(child)
                                                    }}>{child.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    <div style={{ flex: '30%', display: 'flex', flexDirection: 'column', margin: '0 auto' }}>
                        <div className='button-full mx-auto' style={{ width: '204px', height: '46px', borderRadius: '33px', background: '#FF0560' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Contact</div>
                        </div>
                        <div className='d-none max-w-[210px] my-4 mx-auto flex flex-row justify-betwen'>
                            <div className='w-auto px-2'>
                                <Location fill='#000' />
                            </div>
                            <div className='w-auto'>
                                <div className='text-[16px] text-[#000]' style={{ fontFamily: 'Inter', fontWeight: '700' }}>Belfast</div>
                                <div className='text-[16px] text-[#000]' style={{ fontFamily: 'Inter', fontWeight: '500' }}>
                                    {`70 Railroad
                        AvenueOlive Branch,
                        MS 38654`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: '16px', color: '#000', fontWeight: '500', 
opacity: '60%', fontFamily: 'Inter', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2023. All Rights Reserved</div>
            </div>
        </div>,


        <div className='w-full h-full bg-[#4C3856] py-10'>
            <div className='container flex flex-column h-full'>
                <div className='w-full flex-row flex flex-wrap'>
                    <div className='min-w-[250px]' style={{ flex: '70%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ margin: '0 10px' }}>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#FFFFFF'}><path d={logoIcon?.path} /></svg>
                        </div>
                        </div>
                        <div className='w-[90%] mx-auto h-auto pt-4 pb-8 flex sm:flex-col flex-row flex-wrap justify-start items-start'>
                            {treeDatas.map((x, key) => (
                                <div key={key} className='w-[100px] justify-center items-center flex flex-col '>
                                    <div className='item-f text-center  '
                                        _id='sidebarItemx'
                                        val={x.name}
                                        style={{ textDecoration: 'none', fontFamily: 'Rubik', color: activeId?._id === x._id ? '#FFFFFF' : '#989191', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(x)
                                        }}>{x.name}
                                    </div>

                                    <div>
                                        {x.children.map(child => (
                                            <div
                                                _id='sidebarItemx'
                                                val={child.name}
                                                key={child._id} className='mx-auto my-2     flex justify-start align-start cursor-pointer'>
                                                <div
                                                    className='item-f text-center'
                                                    style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#FFFFFF' : '#989191', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setActiveId(child)
                                                    }}>{child.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    <div style={{ flex: '30%', display: 'flex', flexDirection: 'column', margin: '0 auto' }}>
                        <div className='button-full mx-auto' style={{ width: '204px', height: '46px', borderRadius: '33px', background: '#FF0560' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Contact</div>
                        </div>
                        <div className='d-none max-w-[210px] my-4 mx-auto flex flex-row justify-betwen'>
                            <div className='w-auto px-2'>
                                <Location fill='#FFFFFF' />
                            </div>
                            <div className='w-auto'>
                                <div className='text-[16px] text-[#FFFFFF]' style={{ fontFamily: 'Inter', fontWeight: '700' }}>Belfast</div>
                                <div className='text-[16px] text-[#FFFFFF]' style={{ fontFamily: 'Inter', fontWeight: '500' }}>
                                    {`70 Railroad
                        AvenueOlive Branch,
                        MS 38654`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: '16px', color: '#FFFFFF', fontWeight: '500', 
opacity: '60%', fontFamily: 'Inter', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2023. All Rights Reserved</div>
            </div>
        </div>,


        <div className='w-full h-full bg-[#CCF2FA] py-10'>
            <div className='container flex flex-column h-full'>
                <div className='w-full flex-row flex flex-wrap'>
                    <div className='min-w-[250px]' style={{ flex: '70%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ margin: '0 10px' }}>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                        </div>
                        <div className='w-[90%] mx-auto h-auto pt-4 pb-8 flex sm:flex-col flex-row flex-wrap justify-start items-start'>
                            {treeDatas.map((x, key) => (
                                <div key={key} className='w-[100px] justify-center items-center flex flex-col '>
                                    <div className='item-f text-center  '
                                        _id='sidebarItemx'
                                        val={x.name}
                                        style={{ textDecoration: 'none', fontFamily: 'Rubik', color: activeId?._id === x._id ? '#000000' : '#989191', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(x)
                                        }}>{x.name}
                                    </div>

                                    <div>
                                        {x.children.map(child => (
                                            <div
                                                _id='sidebarItemx'
                                                val={child.name}
                                                key={child._id} className='mx-auto my-2     flex justify-start align-start cursor-pointer'>
                                                <div
                                                    className='item-f text-center'
                                                    style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#000000' : '#989191', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setActiveId(child)
                                                    }}>{child.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    <div style={{ flex: '30%', display: 'flex', flexDirection: 'column', margin: '0 auto' }}>
                        <div className='button-full mx-auto' style={{ width: '204px', height: '46px', borderRadius: '33px', background: '#FE490B' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Contact</div>
                        </div>
                        <div className='d-none max-w-[210px] my-4 mx-auto flex flex-row justify-betwen'>
                            <div className='w-auto px-2'>
                                <Location fill='#000000' />
                            </div>
                            <div className='w-auto'>
                                <div className='text-[16px] text-[#000000]' style={{ fontFamily: 'Inter', fontWeight: '700' }}>Belfast</div>
                                <div className='text-[16px] text-[#000000]' style={{ fontFamily: 'Inter', fontWeight: '500' }}>
                                    {`70 Railroad
                        AvenueOlive Branch,
                        MS 38654`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: '16px', color: '#000000', fontWeight: '500', 
opacity: '60%', fontFamily: 'Inter', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2023. All Rights Reserved</div>
            </div>
        </div>,


        <div className='w-full h-full bg-[#311043] py-10'>
            <div className='container flex flex-column h-full'>
                <div className='w-full flex-row flex flex-wrap'>
                    <div className='min-w-[250px]' style={{ flex: '70%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ margin: '0 10px' }}>
                           
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#FFFFFF'}><path d={logoIcon?.path} /></svg>
                        </div>
                        </div>
                        <div className='w-[90%] mx-auto h-auto pt-4 pb-8 flex sm:flex-col flex-row flex-wrap justify-start items-start'>
                            {treeDatas.map((x, key) => (
                                <div key={key} className='w-[100px] justify-center items-center flex flex-col '>
                                    <div className='item-f text-center  '
                                        _id='sidebarItemx'
                                        val={x.name}
                                        style={{ textDecoration: 'none', fontFamily: 'Rubik', color: activeId?._id === x._id ? '#FFFFFF' : '#989191', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(x)
                                        }}>{x.name}
                                    </div>

                                    <div>
                                        {x.children.map(child => (
                                            <div
                                                _id='sidebarItemx'
                                                val={child.name}
                                                key={child._id} className='mx-auto my-2     flex justify-start align-start cursor-pointer'>
                                                <div
                                                    className='item-f text-center'
                                                    style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#FFFFFF' : '#989191', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setActiveId(child)
                                                    }}>{child.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    <div style={{ flex: '30%', display: 'flex', flexDirection: 'column', margin: '0 auto' }}>
                        <div className='button-full mx-auto' style={{ width: '204px', height: '46px', borderRadius: '33px', background: '#871A77' }}>
                            <div><IconPerson fill='#fff' /></div>
                            <div className='button-txt' style={{ color: '#fff' }}>Contact</div>
                        </div>
                        <div className='d-none max-w-[210px] my-4 mx-auto flex flex-row justify-betwen'>
                            <div className='w-auto px-2'>
                                <Location fill='#FFFFFF' />
                            </div>
                            <div className='w-auto'>
                                <div className='text-[16px] text-[#FFFFFF]' style={{ fontFamily: 'Inter', fontWeight: '700' }}>Belfast</div>
                                <div className='text-[16px] text-[#FFFFFF]' style={{ fontFamily: 'Inter', fontWeight: '500' }}>
                                    {`70 Railroad
                        AvenueOlive Branch,
                        MS 38654`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: '16px', color: '#FFFFFF', fontWeight: '500', 
opacity: '60%', fontFamily: 'Inter', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2023. All Rights Reserved</div>
            </div>
        </div>,


        <div className='w-full h-full bg-[#BFF0F0] py-10'>
            <div className='container flex flex-column h-full'>
                <div className='w-full flex-row flex flex-wrap'>
                    <div className='min-w-[250px]' style={{ flex: '70%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ margin: '0 10px' }}>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#000000'}><path d={logoIcon?.path} /></svg>
                        </div>
                        </div>
                        <div className='w-[90%] mx-auto h-auto pt-4 pb-8 flex sm:flex-col flex-row flex-wrap justify-start items-start'>
                            {treeDatas.map((x, key) => (
                                <div key={key} className='w-[100px] justify-center items-center flex flex-col '>
                                    <div className='item-f text-center  '
                                        _id='sidebarItemx'
                                        val={x.name}
                                        style={{ textDecoration: 'none', fontFamily: 'Rubik', color: activeId?._id === x._id ? '#000000' : '#989191', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(x)
                                        }}>{x.name}
                                    </div>

                                    <div>
                                        {x.children.map(child => (
                                            <div
                                                _id='sidebarItemx'
                                                val={child.name}
                                                key={child._id} className='mx-auto my-2     flex justify-start align-start cursor-pointer'>
                                                <div
                                                    className='item-f text-center'
                                                    style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#000000' : '#989191', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setActiveId(child)
                                                    }}>{child.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    <div style={{ flex: '30%', display: 'flex', flexDirection: 'column', margin: '0 auto' }}>
                        <div className='button-full mx-auto' style={{ width: '204px', height: '46px', borderRadius: '33px', background: '#02C8B4' }}>
                            <div><IconPerson fill='#000000' /></div>
                            <div className='button-txt' style={{ color: '#000000' }}>Contact</div>
                        </div>
                        <div className='d-none max-w-[210px] my-4 mx-auto flex flex-row justify-betwen'>
                            <div className='w-auto px-2'>
                                <Location fill='#000000' />
                            </div>
                            <div className='w-auto'>
                                <div className='text-[16px] text-[#000000]' style={{ fontFamily: 'Inter', fontWeight: '700' }}>Belfast</div>
                                <div className='text-[16px] text-[#000000]' style={{ fontFamily: 'Inter', fontWeight: '500' }}>
                                    {`70 Railroad
                        AvenueOlive Branch,
                        MS 38654`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: '16px', color: '#000000', fontWeight: '500', 
opacity: '60%', fontFamily: 'Inter', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2023. All Rights Reserved</div>
            </div>
        </div>,


        <div className='w-full h-full bg-[#78838B] py-10'>
            <div className='container flex flex-column h-full'>
                <div className='w-full flex-row flex flex-wrap'>
                    <div className='min-w-[250px]' style={{ flex: '70%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ margin: '0 10px' }}>
                        <div className='w-[40px] h-[40px] mr-[.5Vmin] flex justify-center align-items-center'>
                            <svg aria-hidden="true" focusable="false" role="img" viewBox={logoIcon?.viewBox} width={logoIcon?.width} height={logoIcon?.height} fill={logoIcon?.fill? logoIcon.fill : '#FFFFFF'}><path d={logoIcon?.path} /></svg>
                        </div>
                        </div>
                        <div className='w-[90%] mx-auto h-auto pt-4 pb-8 flex sm:flex-col flex-row flex-wrap justify-start items-start'>
                            {treeDatas.map((x, key) => (
                                <div key={key} className='w-[100px] justify-center items-center flex flex-col '>
                                    <div className='item-f text-center  '
                                        _id='sidebarItemx'
                                        val={x.name}
                                        style={{ textDecoration: 'none', fontFamily: 'Rubik', color: activeId?._id === x._id ? '#FFFFFF' : '#e9e9e930', fontWeight: activeId?._id === x._id ? '700' : '400' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setActiveId(x)
                                        }}>{x.name}
                                    </div>

                                    <div>
                                        {x.children.map(child => (
                                            <div
                                                _id='sidebarItemx'
                                                val={child.name}
                                                key={child._id} className='mx-auto my-2     flex justify-start align-start cursor-pointer'>
                                                <div
                                                    className='item-f text-center'
                                                    style={{ textDecoration: 'none', color: activeId?._id == child?._id ? '#FFFFFF' : '#e9e9e930', fontWeight: activeId?._id == child?._id ? '700' : '400' }}

                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setActiveId(child)
                                                    }}>{child.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    <div style={{ flex: '30%', display: 'flex', flexDirection: 'column', margin: '0 auto' }}>
                        <div className='button-full mx-auto' style={{ width: '204px', height: '46px', borderRadius: '33px', background: '#C7D9E3' }}>
                            <div><IconPerson fill='#000000' /></div>
                            <div className='button-txt' style={{ color: '#000000' }}>Contact</div>
                        </div>
                        <div className='d-none max-w-[210px] my-4 mx-auto flex flex-row justify-betwen'>
                            <div className='w-auto px-2'>
                                <Location fill='#FFFFFF' />
                            </div>
                            <div className='w-auto'>
                                <div className='text-[16px] text-[#FFFFFF]' style={{ fontFamily: 'Inter', fontWeight: '700' }}>Belfast</div>
                                <div className='text-[16px] text-[#FFFFFF]' style={{ fontFamily: 'Inter', fontWeight: '500' }}>
                                    {`70 Railroad
                                    AvenueOlive Branch,
                                    MS 38654`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: '16px', color: '#FFFFFF', fontWeight: '500', 
                opacity: '60%', fontFamily: 'Inter', textAlign:'center', margin:'2vmin auto', width:'100%'}}>Copyright © 2023. All Rights Reserved</div>
            </div>
        </div>

    ]


    return (
        <div className={`w-full h-full autowhen ${navigationSide && enabled ? "bordered" : ""}`} ref={componentRef} onClick={(e) => {
            e.stopPropagation()
            setNavigationSide(true)
        }}>
            {footer.map((content, index) => (
                <div
                    className='h-[100%]'
                    key={index}
                    style={{ display: index === currentStyleIndex ? 'block' : 'none' }}
                >
                    {content}
                </div>
            ))}
        </div>
    )
}