const ToolbarSelectDrop = ({
    isDarkMode,
    props,
    noinput,
    optionsDrop,
    value,
    onChange,
    setProp,
    node,
    propKey,
    mainProps
}) => {

    return (
        <div className={`${isDarkMode ? 'text-[#fff]' : 'text-[#000]'} flex justify-between flex-wrap items-center`} style={{ padding: noinput ? '' : '1vmin 0' }}>
            {props.label === 'Transition' ? <div style={{display:'inline-block'}} className='text-[#989191] w-full text-[2vmin] px-[.25Vmin]'>You can add a transition</div> : props.label === 'Display' ? <div style={{display:'inline-block', }} className='text-[#989191] w-full text-[2vmin] px-[.25Vmin]'>Flexbox layout mode</div> : props.label === 'Direction' ? <div style={{display:'inline-block', }} className='text-[#989191] w-full text-[2vmin] px-[.25Vmin]'>Set the layout direction</div> : null}
            <div className="text-left text-[#e9e9e9] w-[fit-content] text-center flex-1 items-center" style={{ fontSize: '1.6vmin', fontWeight: '500', fontFamily: 'Inter'}}>{props.label}</div>
            <select
              style={{
                alignContent: 'center',
                alignItems: 'center',
                display: 'flex',
                padding: '.7vmin 1.4vmin',
                fontFamily: 'Inter, sans-serif',
                fontWeight: '400',
                fontSize: '1.5vmin',
                color: isDarkMode? '#FFF' : '#e9e9e980',
                backgroundColor: 'transparent',
                border: '.25vmin solid #e9e9e9',
                borderRadius: '2vmin',
                outline: 'none',
                maxWidth:'50%',
                // transition: 'border-color 0.2s ease, box-shadow 0.2s ease',
              }}

              value={value || ''}

              onFocus={(e) => {
                e.target.style.borderColor = '#007bff';
                e.target.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.5)';
            }}
            onBlur={(e) => {
                e.target.style.borderColor = '#ccc';
                e.target.style.boxShadow = 'none';
            }}
              onChange={(e) => {
                const selectedValue = e.target.value;
                onChange ? onChange(selectedValue) : 
                setProp(node.id, (props) => {
                  if(mainProps) {
                  props[propKey] = onChange ? onChange(selectedValue) : selectedValue;

                  }
                  props[propKey] = onChange ? onChange(selectedValue) : selectedValue;
                });


              }}
            >
              <option value="">default</option>
              {optionsDrop.map((option) => (
                <option
                  style={{
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    fontSize: '2.2Vmin',
                    color: 'black',
                    lineHeight: 'auto'
                  }}
                  key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
    )
}

export default ToolbarSelectDrop;