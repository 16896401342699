/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';
import { ToolbarSection , ToolbarItem} from '../../../editor';
import { TabOption } from '../../../editor/Viewport/Sidebar/TabOption';
import { ToolbarRadio } from '../../../editor/Toolbar/ToolbarRadio';

export const VideoSettings = () => {
  const [activeTab, setActiveTab] = useState("Style Manager");

  return (
    <div className='flex flex-col' style={{ display: "flex", height: "100%" }}>
      <div className='w-full'>
        <TabOption activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>

      {activeTab === "Navigation Manager" ? (
        <div className='flex flex-col w-full h-full'>
          <div className='flex'>
            <ToolbarSection expanded={true} title="Navigation">
              <ToolbarItem
                full={true}
                propKey="href"
                type="select"
                label="href"
              />
            </ToolbarSection>
          </div>
        </div>
      ) : null}

      {activeTab === "Style Manager" ? (
        <div className='w-full h-full flex flex-col'>
          <div>
            <ToolbarSection title="Source" expanded={true}>
              <div className='w-full justify-center text-center items-center mx-auto'>
                <ToolbarItem
                  full={true}
                  propKey="src"
                  type="text"
                  placeholder='Enter video URL'
                />
              </div>
            </ToolbarSection>


      <ToolbarSection
      expanded={true}
        title="Dimensions"
        props={['height']}
        summary={({ height }) => {
          return `${height || 0}`;
        }}
      >
        <ToolbarItem propKey="height" type="text" label="Height" />
      </ToolbarSection> 
            <ToolbarSection
              expanded={true}
              title="Controls"
            >

              <ToolbarItem
                full={true}
                propKey="controls"
                type="radio"
                label="Show controls"
              >

          <ToolbarRadio value={true} label="Yes" />
          <ToolbarRadio value={false} label="No" />

              </ToolbarItem>

            </ToolbarSection>

            <ToolbarSection
              expanded={true}
              title="Additional Settings"
            >
              <ToolbarItem
                propKey="autoplay"
                type="radio"
                label="Autoplay"
                >

                <ToolbarRadio value={true} label="Yes" />
                <ToolbarRadio value={false} label="No" />
      
                    </ToolbarItem>
              <ToolbarItem
                propKey="loop"
                type="radio"
                label="Loop"
                >

                <ToolbarRadio value={true} label="Yes" />
                <ToolbarRadio value={false} label="No" />
      
                    </ToolbarItem>
              <ToolbarItem
                propKey="muted"
                type="radio"
                label="Muted"
                >

                <ToolbarRadio value={true} label="Yes" />
                <ToolbarRadio value={false} label="No" />
      
                    </ToolbarItem>
            </ToolbarSection>
          </div>
        </div>
      ) : null}
    </div>
  );
};