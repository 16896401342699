import React from 'react';

export const Widget_Nav = ({
    props,
    setProp,
    propKey,
    node,
    value,
    options,
    activeId,
    propValue,
}) => {

    let style = {
        margin:'1vmin 1vmin',
        cursor: 'pointer',
        padding:'.7vmin',
        border:'.25vmin solid #e9e9e9',
        width:'10vmin',
        height:'10vmin',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        fontSize:'2.2vmin',
        borderRadius:'1.5vmin',
    }

    const handleClick = (name) => {
        setProp(node.id, (props) => {
            if (props && props[propKey]) {
                props[propKey][name] = true;
            }
        });
    };

    const handleReset = (name, e) => {
        e.stopPropagation()
        setProp(node.id, (props) => {
            if (props && props[propKey]) {
                props[propKey][name] = false;
            }
        });
    };
    
    const incrementCount = () => {

        setProp(node.id, (props) => {

            const updatedTextCount = [
                ...props[propKey]['textCount'], 
                'Item'
            ];

            const limitedTextCount = updatedTextCount.slice(0, 6);

            if (props && props[propKey]) {                
                props[propKey]['textCount'] = limitedTextCount
            }
        });

    };
    const decrementCount = () => {

        setProp(node.id, (props) => {

            const updatedTextCount = [
                ...props[propKey]['textCount']
            ];

            updatedTextCount.pop(); 

            if (props && props[propKey]) {                
                props[propKey]['textCount'] = updatedTextCount
            }
        });
    };
    

    return (
            <div style={{display:'flex', flexDirection:'column', width:'100%', justifyContent:'center', alignContent:'center'}}>
           <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', width:'100%', justifyContent:'center', alignContent:'center'}}>
           <div style={style} className='shadow-md relative' onClick={(e) => handleClick('textVis', e)}>
            <div>List</div>
            {options[propKey]['textVis'] && <div className='absolute top-[-.5vmin] left-[-.5vmin] hover:scale-150 transform-gpu' onClick={(e) => handleReset('textVis', e)}>❌</div>}
            <div className='absolute bottom-0 left-0 right-0 flex justify-around'>
                {options[propKey]['textCount'].length !== 0 && <div onClick={decrementCount}>-</div>}
                {options[propKey]['textCount'].length !== 6 && <div onClick={incrementCount}>+</div>}
            </div>
           </div>
            <div style={style} className='shadow-md relative' onClick={(e) => handleClick('alertVis', e)}>
                <div>Alert</div>
            {options[propKey]['alertVis'] && <div className='absolute top-[-.5vmin] left-[-.5vmin] hover:scale-150 transform-gpu' onClick={(e) => handleReset('alertVis', e)}>❌</div>}

            </div>
            <div style={style} className='shadow-md relative' onClick={(e) => handleClick('logoVis', e)}>
                <div>Logo</div>
            {options[propKey]['logoVis'] && <div className='absolute top-[-.5vmin] left-[-.5vmin] hover:scale-150 transform-gpu' onClick={(e) => handleReset('logoVis', e)}>❌</div>}
                
            </div>
            <div style={style} className='shadow-md relative' onClick={(e) => handleClick('menuVis', e)}>
                <div>Menu</div>
            {options[propKey]['menuVis'] && <div className='absolute top-[-.5vmin] left-[-.5vmin] hover:scale-150 transform-gpu' onClick={(e) => handleReset('menuVis', e)}>❌</div>}
                
            </div>
            <div style={style} className='shadow-md relative' onClick={(e) => handleClick('userVis', e)}>
                <div>User</div>
            {options[propKey]['userVis'] && <div className='absolute top-[-.5vmin] left-[-.5vmin] hover:scale-150 transform-gpu' onClick={(e) => handleReset('userVis', e)}>❌</div>}
                
            </div>
           </div>
        </div>
    )
}
