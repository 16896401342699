/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, { useState } from 'react';

import { useNode } from '@craftjs/core';
import { NewBannerSettings } from './newBannerSetting';

export const Div = ({ selected, children }) => {
  const { 
    connectors: {
      connect
    } 
  } = useNode();

  return (
    <div ref={connect}>
       {children}
    </div>
  );
};

Div.craft = {
  displayName: "Div",
  props: {
    selected: 1, // here is the default value of the selected style
  },
  rules: {
    canDrag: () => true,
    canDrop: () => true,
  },
  related: {
    toolbar: NewBannerSettings,
  },
};
