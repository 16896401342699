/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import { gql } from "apollo-boost";

const addFeedbackMutation = gql`
  mutation(
    $rate: String
    $type: String
    $email: String
    $content: String
    $device: String
    $browser: String
    $ip: String
  ) {
    addFeedback(
    type: $type
    email: $email
    content: $content
    rate: $rate
    device: $device
    browser: $browser
    ip: $ip
    ) {
    _id
    type
    email
    content
    rate
    device
    browser
    ip
    }
  }
`;

export default addFeedbackMutation;
