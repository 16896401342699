/****************************
* Copyright from 2019 AllYouCanCloud by Carlo Di Michele - Pescara (Italy). 
* Original Project Name: LoStudio. 
* use this file except in compliance with the License. You may obtain a copy
* of the License contacting Carlo Di Michele at:
*
* carlo.dimichele@gmail.com 
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
* WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
* License for the specific language governing permissions and limitations under
* the License.
****************************/


import React, {useState, useEffect, useRef} from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import Text from '../../basic/Text';

import { NewCtaSettings } from './newCtaSetting';
import './CTA.css'
import Button  from '../../basic/Button';

import { Parent } from '../../Parent/index.js';
import { Wrapper } from '../../wrapper/index.js';
import { BounceLoader } from 'react-spinners';
import { Input } from '../../basic/Input/index.js';

export const CTA_1 = ({selected}) => {
 
    const { 
        actions: { setProp },
          connectors: {
            connect
          } 
      } = useNode();
    
      
      const {
        enabled,
      } = useEditor((state, query) => ({
        enabled: state.options.enabled,
     
      }));
    

    const styles = [
        <Element canvas is={Parent} id='parentId100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId200' className="w-[100%] h-auto" row={true} >
            <Wrapper canvas id='random-id-400' is='parent' className='w-[100%] h-full bg-[white]'>
            <Wrapper canvas id='random-id-401' is='container' className='w-full h-full justify-center'>

                <Wrapper canvas id='random-id-402' is='wrapper' className='bg-[#B20312] h-full flex flex-row flex-wrap'>
                        <Wrapper canvas style={{display:'inline-block', flex:'50%', fontWeight:'700', fontFamily:'Rubik', color:'#FFFFFF'}} className={`w-auto flex justify-center mx-auto my-auto w-full text-center px-10 py-10 text-[46px]`}  id='random-id-1' is='div'>
                            <Text text='Join our newsletter!'/>
                        </Wrapper>
                    <Wrapper canvas id='random-id-403' is='div' className='flex mx-auto justify-center min-w-[400px] bg-[#FFFFFF]' style={{flex:'50%'}}>
                    <Wrapper canvas id='random-id-404' is='div' className='w-full h-full flex flex-row flex-wrap align-items-center p-10 mx-auto my-auto'>
                    <Wrapper canvas id='random-id-405' is='div' className='mx-auto min-w-[200px]' style={{flex:'20%'}}>
                    <Input className="input form-control bg-transparent text-white flex" style={{border: '1px solid #B2031233', height:'70px', padding:'20px', fontSize:'18px'}}  name='name' type='text' value={'#'} placeholder="Enter your name"/>
                    </Wrapper>
                    <Wrapper canvas id='random-id-20000' is='div' className='mx-auto my-4' >
                        <Button  classn='block' class='w-[220px] h-[70px] flex cursor-pointer bg-[#B20312] hover:bg-[#87000C] text-[#FFF] uppercase' style={{ boxShadow: '0px 4px 10px 0px #0000001A'}}>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'20px', fontFamily:'Rubik', fontWeight:'600'}} className='mx-auto my-auto' id='random-id-2' is='div'>
                            <Text text='Subscribe'/>
                        </Wrapper>
                        </Button>
                    </Wrapper>
                    </Wrapper> 
                </Wrapper>
                </Wrapper>  
                  
            </Wrapper>
        </Wrapper></Element>
        </Element>
        ,
        <Element canvas is={Parent} id='parentId300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-406' is='parent'  className='w-[100%] h-full bg-[#121826]'>
            <Wrapper canvas id='random-id-407' is='container'  className='w-full h-full justify-center'>

                <Wrapper canvas id='random-id-408' is='wrapper'  className='bgbg h-full flex flex-row flex-wrap'>
                        <Wrapper canvas style={{display:'inline-block', flex:'50%', fontWeight:'bold', fontFamily:'Rubik', color:'#121826'}} className={`w-auto flex justify-center mx-auto my-auto w-full text-center px-10 py-10 text-[46px]`}  id='random-id-3' is='div'>
                            <Text text='Join our newsletter!'/>
                        </Wrapper>
                    <Wrapper canvas id='random-id-409' is='div'  className='flex mx-auto justify-center min-w-[400px] bg-[#213156]' style={{flex:'50%'}}>
                    <Wrapper canvas id='random-id-410' is='div'  className='w-full h-full flex flex-row flex-wrap align-items-center p-10 mx-auto my-auto'>
                    <Wrapper canvas id='random-id-411' is='div'  className='mx-auto min-w-[200px]' style={{flex:'20%'}}>
                    <Input className="input-1 form-control bg-transparent text-white flex" style={{border: '1px solid #3265E366', height:'70px', padding:'20px', fontSize:'18px'}}  name='name' type='text' value={'#'} placeholder="Enter your name"/>
                    </Wrapper>
                    <Wrapper canvas id='random-id-201' is='div' className='my-4 mx-auto'>
                        <Button  classn='block' class='w-[220px] h-[70px] flex cursor-pointer text-[#FFF] uppercase bg-[#3265E3] hover:bg-[#0049FF]' style={{ boxShadow: '0px 4px 10px 0px #0000001A'}}>
                            <Wrapper canvas style={{display:'inline-block', fontSize:'20px', fontFamily:'Rubik', fontWeight:'600',}} className='mx-auto my-auto' id='random-id-4' is='div'>
                            <Text text='Subscribe'/>
                        </Wrapper>
                        </Button>
                    </Wrapper>
                    </Wrapper> 
                </Wrapper>
                </Wrapper>  
                  
            </Wrapper>
        </Wrapper></Element>
        </Element>,
<Element canvas is={Parent} id='parentId500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-412' is='parent'  className='w-[100%] h-full bg-[white]'>
<Wrapper canvas id='random-id-413' is='container'  className='w-full h-full justify-center'>

    <Wrapper canvas id='random-id-414' is='wrapper'  className='bg-[#EA6EF4] h-full flex flex-row flex-wrap'>
            <Wrapper canvas style={{display:'inline-block', flex:'50%', fontWeight:'700', fontFamily:'Times New Roman', color:'#FFFFFF'}} className={`w-auto flex justify-center mx-auto my-auto w-full text-center px-10 py-10 text-[46px]`}  id='random-id-5' is='div'>
                <Text text='Join our newsletter!'/>
            </Wrapper>
        <Wrapper canvas id='random-id-415' is='div'  className='flex mx-auto justify-center min-w-[400px] bg-[#213156]' style={{flex:'50%'}}>
        <Wrapper canvas id='random-id-416' is='div'  className='w-full h-full flex flex-row flex-wrap align-items-center p-10 mx-auto my-auto'>
        <Wrapper canvas id='random-id-417' is='div'  className='mx-auto min-w-[200px]' style={{flex:'20%'}}>
        <Input className="input-1 form-control bg-transparent text-white flex" style={{border: '1px solid #EA6EF466', height:'70px', padding:'20px', fontSize:'18px', borderRadius:'30px'}}  name='name' type='text' value={'#'} placeholder="Enter your name"/>
        </Wrapper>
        <Wrapper canvas id='random-id-202' is='div' className='my-4 mx-auto'>
            <Button  classn='block' class='w-[220px] h-[70px] flex cursor-pointer bg-[#EA6EF4] hover:bg-[#E111F2] text-[#FFF] uppercase rounded-[30px]' style={{ borderRadius:'30px'}}>
                <Wrapper canvas style={{display:'inline-block', fontSize:'20px', fontFamily:'Open Sans', fontWeight:'600',}} className='mx-auto my-auto' id='random-id-6' is='div'>
                <Text text='Subscribe'/>
            </Wrapper>
            </Button>
        </Wrapper>
        </Wrapper> 
    </Wrapper>
    </Wrapper>  
      
</Wrapper>
</Wrapper></Element>
        </Element>
        ,
        <Element canvas is={Parent} id='parentId700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-418' is='parent'  className='w-[100%] h-full bg-[white]'>
        <Wrapper canvas id='random-id-419' is='container'  className='w-full h-full justify-center'>
            <Wrapper canvas id='random-id-420' is='wrapper' className='bg-[#31A993] h-full flex flex-row flex-wrap'>
                    <Wrapper canvas style={{display:'inline-block', flex:'50%', fontWeight:'700', fontFamily:'Zing Script Rust SemiBold Demo', color:'#FFFFFF' ,fontStyle:'italic'}} className={`w-auto flex justify-center mx-auto my-auto w-full text-center px-10 py-10 text-[46px]`} id='random-id-7' is='div'>
                        <Text text='Join our newsletter!'/>
                    </Wrapper>
                <Wrapper canvas id='random-id-421' is='div'  className='flex mx-auto justify-center min-w-[400px] bg-[#DFFFF9]' style={{flex:'50%'}}>
                <Wrapper canvas id='random-id-422' is='div'  className='w-full h-full flex flex-row flex-wrap align-items-center p-10 mx-auto my-auto'>
                <Wrapper canvas id='random-id-423' is='div'  className='mx-auto min-w-[200px]' style={{flex:'20%'}}>
                <Input className="input form-control bg-transparent text-[#00000099] flex " style={{border: '1px solid #31A99366', height:'70px', padding:'20px', fontSize:'18px', borderRadius:'30px'}}  name='name' type='text' value={'#'} placeholder="Enter your name"/>
                </Wrapper>
                <Wrapper canvas id='random-id-203' className='my-4 mx-auto'>
                    <Button  classn='block' class='w-[220px] h-[70px] flex text-[#FFF] cursor-pointer bg-[#31A993] hover:bg-[#11816C] uppercase rounded-[30px]' style={{ borderRadius:'30px'}}>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'20px', fontFamily:'Open Sans', fontWeight:'600',}} className='mx-auto my-auto' id='random-id-8' is='div'>
                        <Text text='Subscribe'/>
                    </Wrapper>
                    </Button>
                </Wrapper>
                </Wrapper> 
            </Wrapper>
            </Wrapper>  
              
        </Wrapper>
        </Wrapper></Element>
        </Element>,

     
        <Element canvas is={Parent} id='parentId900' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-424' is='parent'  className='w-[100%] h-full bg-[white]'>
            <Wrapper canvas id='random-id-425' is='container'  className='w-full h-full justify-center'>

                <Wrapper canvas id='random-id-426' is='wrapper'  className='bg-[#0078D3] h-full flex flex-row flex-wrap'>
                        <Wrapper canvas style={{display:'inline-block', flex:'50%', fontWeight:'700', fontFamily:'Inter', color:'#FFFFFF'}} className={`w-auto flex justify-center mx-auto my-auto w-full text-center px-10 py-10 text-[46px]`} id='random-id-9' is='div'>
                            <Text text='Join our newsletter!'/>
                        </Wrapper>
                    <Wrapper canvas id='random-id-427' is='div'  className='flex mx-auto justify-center min-w-[400px] bg-[#FFFFFF]' style={{flex:'50%'}}>
                    <Wrapper canvas id='random-id-428' is='div'  className='w-full h-full flex flex-row flex-wrap align-items-center p-10 mx-auto my-auto'>
                    <Wrapper canvas id='random-id-429' is='div'  className='mx-auto min-w-[200px]' style={{flex:'20%'}}>
                    <Input className="input form-control bg-transparent text-white flex" style={{border: '1px solid #0078D3', height:'70px', padding:'20px', fontSize:'18px'}}  name='name' type='text' value={'#'} placeholder="Enter your name"/>
                    </Wrapper>
                    <Wrapper canvas id='random-id-204' className='my-4 mx-auto' style={{ boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)'}}>
                        <Button  classn='block' class='w-[220px] h-[70px] flex cursor-pointer bg-[#0078D3] hover:bg-[#11578B] text-[#FFF] uppercase' >
                            <Wrapper canvas style={{display:'inline-block', fontSize:'20px', fontFamily:'Inter', fontWeight:'600'}} className='mx-auto my-auto' id='random-id-10' is='div'>
                            <Text text='Subscribe'/>
                        </Wrapper>
                        </Button>
                    </Wrapper>
                    </Wrapper> 
                </Wrapper>
                </Wrapper>  
                  
            </Wrapper>
        </Wrapper></Element>
        </Element>,
          <Element canvas is={Parent} id='parentId1100' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1200' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-430' is='parent'  className='w-[100%] h-full bg-[white]'>
          <Wrapper canvas id='random-id-431' is='container'  className='w-full h-full justify-center'>

              <Wrapper canvas id='random-id-432' is='wrapper'  className='bg-[#F8F8F8] h-full flex flex-row flex-wrap'>
                      <Wrapper canvas style={{display:'inline-block', flex:'50%', fontWeight:'400', fontFamily:'Inter', color:'#02021' }} className={`w-auto flex justify-center uppercase mx-auto my-auto w-full text-center px-10 py-10 text-[46px]`} id='random-id-11' is='div'>
                          <Text text='Join our newsletter!'/>
                      </Wrapper>
                  <Wrapper canvas id='random-id-433' is='div'  className='flex mx-auto justify-center min-w-[400px] bg-[#020212]' style={{flex:'50%'}}>
                  <Wrapper canvas id='random-id-434' is='div'  className='w-full h-full flex flex-row flex-wrap align-items-center p-10 mx-auto my-auto'>
                  <Wrapper canvas id='random-id-435' is='div'  className='mx-auto min-w-[200px]' style={{flex:'20%'}}>
                  <Input className="input-1 form-control bg-transparent text-white flex" style={{border: '1px solid #FFF', height:'70px', padding:'20px', fontSize:'18px', borderRadius:'8px', opacity:'60%'}}  name='name' type='text' value={'#'} placeholder="Enter your name"/>
                  </Wrapper>
                  <Wrapper canvas id='random-id-205' is='div'>
                      <Button  classn='block' className='flex w-[220px] h-[70px] flex cursor-pointer border-1 bg-[#020212] hover:bg-[#FFFFFF] text-[#FFFFFF] hover:text-[#020212] uppercase' style={{ borderRadius:'8px'}}>
                        <Wrapper canvas style={{display:'inline-block', fontSize:'20px', fontFamily:'Inter', fontWeight:'600'}} className='mx-auto my-auto' id='random-id-12' is='div'>
                            <Text text='Subscribe'/>
                        </Wrapper>
                      </Button>
                  </Wrapper>
                  </Wrapper> 
              </Wrapper>
              </Wrapper>  
                
          </Wrapper>
      </Wrapper></Element>
        </Element>,

<Element canvas is={Parent} id='parentId1300' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1400' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-436' is='parent'  className='w-[100%] h-full bg-[white]'>
<Wrapper canvas id='random-id-437' is='container'  className='w-full h-full justify-center'>

    <Wrapper canvas id='random-id-438' is='wrapper' className='bg-[#6750A4] h-full flex flex-row flex-wrap'>
            <Wrapper canvas style={{display:'inline-block', flex:'50%', fontWeight:'400', fontFamily:'Roboto', color:'#FFFFFF'}} className={`w-auto flex justify-center mx-auto my-auto w-full text-center px-10 py-10 text-[46px]`} id='random-id-13' is='div'>
                <Text text='Join our newsletter!'/>
            </Wrapper>
        <Wrapper canvas id='random-id-439' is='div'  className='flex mx-auto justify-center bg-[#FFFFFF] min-w-[358px]' style={{flex:'50%'}}>
        <Wrapper canvas id='random-id-440' is='div'  className='w-full h-full flex flex-row flex-wrap align-items-center p-10 mx-auto my-auto justify-center items-center'>
        <Wrapper canvas id='random-id-441' is='div'  className=' min-w-[220px] ' style={{flex:'20%'}}>
            <Input className="input form-control bg-transparent text-white flex" style={{border: '1px solid #D0BCFF', height:'70px', padding:'20px', fontSize:'18px', borderRadius:'30px'}}  name='name' type='text' value={'#'} placeholder="Enter your name"/>
        </Wrapper>
        <Wrapper canvas id='random-id-206' is='div' className='my-4 mx-auto' >
                <Button  classn='block' class='w-[220px] min-w-[220px] h-[70px] flex cursor-pointer bg-[#D0BCFF] border-1 hover:border-[#381E72] text-[#381E72] uppercase' style={{ borderRadius:'30px'}}>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'20px', fontFamily:'Roboto', fontWeight:'600'}} className='mx-auto my-auto' id='random-id-14' is='div'>
                    <Text text='Subscribe'/>
                </Wrapper>
                </Button>
        </Wrapper>
        </Wrapper> 
    </Wrapper>
    </Wrapper>  
      
</Wrapper>
</Wrapper></Element>
        </Element>,


<Element canvas is={Parent} id='parentId1500' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1600' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-442' is='parent'  className='w-[100%] h-full bg-[white]'>
<Wrapper canvas id='random-id-443' is='container'  className='w-full h-full justify-center'>

    <Wrapper canvas id='random-id-444' is='wrapper'  className='bg-[#FFD600] h-full flex flex-row flex-wrap'>
            <Wrapper canvas style={{display:'inline-block', flex:'50%', fontWeight:'400', fontFamily:'Roboto', color:'#2A2A2A'}} className={`w-auto flex justify-center mx-auto my-auto w-full text-center px-10 py-10 text-[46px]`} id='random-id-15' is='div'>
                <Text text='Join our newsletter!'/>
            </Wrapper>
        <Wrapper canvas id='random-id-445' is='div'  className='flex mx-auto justify-center min-w-[400px] bg-[#2A2A2A]' style={{flex:'50%'}}>
        <Wrapper canvas id='random-id-446' is='div'  className='w-full h-full flex flex-row flex-wrap align-items-center p-10 mx-auto my-auto'>
        <Wrapper canvas id='random-id-447' is='div'  className='mx-auto min-w-[200px]' style={{flex:'20%'}}>
            <Input className="input-1 form-control bg-transparent text-white flex" style={{border: '1px solid #FFD60099', height:'70px', padding:'20px', fontSize:'18px', borderRadius:'14px'}}  name='name' type='text' value={'#'} placeholder="Enter your name"/>
        </Wrapper>
        <Wrapper canvas id='random-id-207' is='div' className='my-4 mx-auto' >
                <Button  classn='block' class='w-[220px] h-[70px] flex cursor-pointer bg-[#FFD600] hover:bg-[#EECC18] text-[#2A2A2A] uppercase' style={{ borderRadius:'14px'}}>
                    <Wrapper canvas style={{display:'inline-block', fontSize:'20px', fontFamily:'Roboto', fontWeight:'600'}} className='mx-auto my-auto' id='random-id-16' is='div'>
                    <Text text='Subscribe'/>
                </Wrapper>
                </Button>
            </Wrapper>
        </Wrapper> 
    </Wrapper>
    </Wrapper>  
      
</Wrapper>
</Wrapper></Element>
        </Element>,
 
<Element canvas is={Parent} id='parentId1700' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId1800' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-900' is='parent' className='w-[100%] h-full bg-[#F7FBFE]'>
 <Wrapper canvas id='random-id-901' is='container' className='w-full h-full justify-center'>

     <Wrapper canvas id='random-id-902' is='wrapper' className='bg-[#6064D2] h-full flex flex-row flex-wrap'>
             <Wrapper canvas style={{display:'inline-block', flex:'50%', fontWeight:'700', fontFamily:'Roboto', color:'#FFFFFF'}} className={`w-auto flex justify-center mx-auto my-auto w-full text-center px-10 py-10 text-[46px]`}  id='random-id-907' is='div'>
                 <Text text='Join our newsletter!'/>
             </Wrapper>
         <Wrapper canvas id='random-id-903' is='div' className='flex mx-auto justify-center min-w-[400px] bg-[#FFFFFF]' style={{flex:'50%'}}>
         <Wrapper canvas id='random-id-904' is='div' className='w-full h-full flex flex-row flex-wrap align-items-center p-10 mx-auto my-auto'>
         <Wrapper canvas id='random-id-905' is='div' className='mx-auto min-w-[200px]' style={{flex:'20%'}}>
         <Input className="input form-control bg-transparent text-white flex" style={{height:'70px', padding:'20px', fontSize:'18px'}}  name='name' type='text' value={'#'} placeholder="Enter your name"/>
         </Wrapper>
         <Wrapper canvas id='random-id-906' is='div' className='mx-auto my-4' style={{ boxShadow: '0px 4px 10px 0px #0000001A'}}>
             <Button  classn='block' class='w-[220px] h-[70px] flex cursor-pointer bg-[#B20312] hover:bg-[#87000C] text-[#FFF] uppercase' >
                 <Wrapper canvas style={{display:'inline-block', fontSize:'20px', fontFamily:'Roboto', fontWeight:'600'}} className='mx-auto my-auto' id='random-id-2' is='div'>
                 <Text text='Subscribe'/>
             </Wrapper>
             </Button>
         </Wrapper>
         </Wrapper> 
     </Wrapper>
     </Wrapper>  
       
 </Wrapper>
</Wrapper></Element>
        </Element>
,

<Element canvas is={Parent} id='parentId1900' className="w-[100%] h-auto" >
        <Element canvas is={Parent} id='parentId2000' className="w-[100%] h-auto" row={true} ><Wrapper canvas id='random-id-908' is='parent' className='w-[100%] h-full bg-[#FEFBF7]'>
<Wrapper canvas id='random-id-909' is='container' className='w-full h-full justify-center'>

    <Wrapper canvas id='random-id-910' is='wrapper' className='bg-[#A25738] h-full flex flex-row flex-wrap' style={{borderRadius:'0px 0px 30px 0px'}}>
            <Wrapper canvas style={{display:'inline-block', flex:'50%', fontWeight:'700', fontFamily:'Roboto', color:'#FFFFFF'}} className={`w-auto flex justify-center mx-auto my-auto w-full text-center px-10 py-10 text-[46px]`}  id='random-id-914' is='div'>
                <Text text='Join our newsletter!'/>
            </Wrapper>
        <Wrapper canvas id='random-id-911' is='div' className='flex mx-auto justify-center min-w-[400px] bg-[#FFFFFF]' style={{flex:'50%'}}>
        <Wrapper canvas id='random-id-912' is='div' className='w-full h-full flex flex-row flex-wrap align-items-center p-10 mx-auto my-auto'>
        <Wrapper canvas id='random-id-913' is='div' className='mx-auto min-w-[200px]' style={{flex:'20%'}}>
        <Input className="input form-control bg-transparent text-white flex" style={{border: '1px solid #B2031233', height:'70px', padding:'20px', fontSize:'18px'}}  name='name' type='text' value={'#'} placeholder="Enter your name"/>
        </Wrapper>
        <Wrapper canvas id='random-id-20026' is='div' className='mx-auto my-4' style={{ boxShadow: '0px 4px 10px 0px #0000001A'}}>
            <Button  classn='block' class='w-[220px] h-[70px] flex cursor-pointer bg-[#A25738] text-[#FFF] uppercase' >
                <Wrapper canvas style={{display:'inline-block', fontSize:'20px', fontFamily:'Sansation', fontWeight:'600'}} className='mx-auto my-auto' id='random-id-2' is='div'>
                <Text text='Subscribe'/>
            </Wrapper>
            </Button>
        </Wrapper>
        </Wrapper> 
    </Wrapper>
    </Wrapper>  
      
</Wrapper>
</Wrapper></Element>
        </Element>,
    ]

    
const [sel, setSel] = useState(selected)

function makeOdd(number) {
  setProp((prop) => {
    setSel(100);
  }, 1000);

  setTimeout(() => {
    setProp((prop) => {
      setSel(number)
    }, 1000);
  }, 100);


  
}

useEffect(() => {
if(selected || selected >= 0) {
  makeOdd(selected);
}
},[selected])

 

    
return (
    <div style={{display:"flex", width:"100%", height:'100%', justifyContent:"center", alignItems:"center"}} ref={connect}>
        <div style={{display:"flex", width:"100%", height:'100%', justifyContent:"center", alignItems:"center"}}>
        <div style={{ padding: enabled? "10px" : "0px", display:"flex", justifyContent:"center", alignItems:"center", width:'100%', height:'100%'}} ref={connect}>
        {sel == 100 ? (
          <div style={{height:"85vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
          <BounceLoader
                  size={100}
                  color={"white"}
                  loading={true}
            />
          </div>
         ) : (
          styles[sel]
         )} 
    </div>
        </div>
      </div>
);
}

CTA_1.craft = {
    displayName: "CTA 1",
    props: {
    selected: 1, // here is the default value of the selected style
    length:9,
    isBlock: true
    },
    rules: {
        canDrag: () => true,
        canDrop: () => true,
    },
    related: {
        toolbar: NewCtaSettings,
    },
};
