const updateEmailContents = {
  headerUpdateEmail: {
    content: 'Update Email',
    background:{value:null, type:'bg'},
    fontSize:{value:'24px', type:'Number'},
    lineHeight:{value:null, type:'Number'},

    fontWeight :{value:null, type:'String'},
    textDecoration :{value:null, type:'String'}, 
    fontFamily :{value:null, type:'String'},
    textTransform :{value:null, type:'String'},

    textAlign :{value:'center', type:'String'},
    color: {value:null, type:'color'},

    width:{value:'null', type:'Number'},
    height:{value:'null', type:'Number'},
    marginTop:{value:null, type:'Number'},
    marginRight:{value:null, type:'Number'},
    marginBottom:{value:null, type:'Number'},
    marginLeft:{value:null, type:'Number'},
    paddingTop:{value:null, type:'Number'},
    paddingRight:{value:null, type:'Number'},
    paddingBottom:{value:null, type:'Number'},
    paddingLeft:{value:null, type:'Number'},

    shadow:{value:null, type:'Number'},

  },

  emailInput: {
    content: 'Input Email',

    placeholder: 'Enter your email',
    background:{value:'transparent', type:'bg'},
    borderRadius: {value:'16px', type:'Number'},

    fontSize:{value:null, type:'Number'},
    color: {value:null, type:'color'},

    width:{value:'null', type:'Number'},
    height:{value:'null', type:'Number'},

    fontWeight :{value:null, type:'String'},
    textDecoration :{value:null, type:'String'}, 
    fontFamily :{value:null, type:'String'},
    textTransform :{value:null, type:'String'},

    textAlign :{value:'left', type:'String'},
    width:{value:null, type:'Number'},
    marginTop:{value:null, type:'Number'},
    marginRight:{value:null, type:'Number'},
    marginBottom:{value:null, type:'Number'},
    marginLeft:{value:null, type:'Number'},
    paddingTop:{value:null, type:'Number'},
    paddingRight:{value:null, type:'Number'},
    paddingBottom:{value:null, type:'Number'},
    paddingLeft:{value:null, type:'Number'},

    shadow:{value:null, type:'Number'},
  },

  emailLabel: {
    content: 'Email',
    background:{value:'transparent', type:'bg'},
      fontSize:{value:null, type:'Number'},
      lineHeight:{value:null, type:'Number'},

      fontWeight :{value:null, type:'String'},
      textDecoration :{value:null, type:'String'}, 
      fontFamily :{value:null, type:'String'},
      textTransform :{value:null, type:'String'},

      textAlign :{value:'left', type:'String'},
      color: {value:null, type:'color'},
      borderRadius: {value:'16px', type:'Number'},

      width:{value:'null', type:'Number'},
      height:{value:'null', type:'Number'},
      marginTop:{value:null, type:'Number'},
      marginRight:{value:null, type:'Number'},
      marginBottom:{value:null, type:'Number'},
      marginLeft:{value:null, type:'Number'},
      paddingTop:{value:null, type:'Number'},
      paddingRight:{value:null, type:'Number'},
      paddingBottom:{value:null, type:'Number'},
      paddingLeft:{value:null, type:'Number'},

      shadow:{value:null, type:'Number'},
  },

  button: {
    content: 'Submit',
    background:{value:'#0867FC', type:'bg'},
    fontSize:{value:null, type:'Number'},
    lineHeight:{value:null, type:'Number'},
    fontWeight :{value:null, type:'String'},
    textDecoration :{value:null, type:'String'}, 
    fontFamily :{value:null, type:'String'},
    textTransform :{value:null, type:'String'},
    textAlign :{value:'center', type:'String'},
    borderRadius: {value:'16px', type:'Number'},
    color: {value:'#fff', type:'color'},
    width:{value:'25vmin', type:'Number'},
height:{value:null, type:'Number'},
    marginTop:{value:null, type:'Number'},
    marginRight:{value:null, type:'Number'},
    marginBottom:{value:null, type:'Number'},
    marginLeft:{value:null, type:'Number'},
    paddingTop:{value:null, type:'Number'},
    paddingRight:{value:null, type:'Number'},
    paddingBottom:{value:null, type:'Number'},
    paddingLeft:{value:null, type:'Number'},
    shadow:{value:null, type:'Number'},
  },
};

export default updateEmailContents;